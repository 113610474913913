import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'

const FirmaFooter = ({ save, dinamicClass, openModal = null, isDisabled = false, call_back, onSaveText }) => {
  return (
    <Fragment>
      <Row className="center-align margin-t-40">
        {/* <p><i>Sarà possibile firmare in modalità digitale o cartacea</i></p> */}
        <p>
          <i>{onSaveText}</i>
        </p>
        <Col s={12}>
          <button
            type="button"
            className="btnFirma"
            disabled={isDisabled}
            onClick={() => {
              call_back(false)
              openModal(false)
              //  save(true)
            }}>
            Firma
          </button>
        </Col>
      </Row>
      <Row className="center-align margin-t-20">
        <Col s={12}>
          <a
            onClick={() => {
              if (isDisabled) return null
              else openModal(true)
              call_back(true)
            }}
            className={`torna-pratiche${isDisabled ? ' disabled' : ''} ${dinamicClass}`}>
            <i className="icon-arrow-back" /> Salva e firma dopo
          </a>
        </Col>
      </Row>
    </Fragment>
  )
}

export default FirmaFooter

FirmaFooter.propTypes = {
  openModal: PropTypes.func,
  call_back: PropTypes.func,
  save: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  dinamicClass: PropTypes.string,
  onSaveText: PropTypes.string
}
