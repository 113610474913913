import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-materialize'
import ImageTools from '../utils'

class ImgUploader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image_uploaded: false,
      fileOriginal: null,
      fileSmall: null
    }
    this.addRemoveImg = this.addRemoveImg.bind(this)
    this.removeImg = this.removeImg.bind(this)
    this.handleFilesInput = this.handleFilesInput.bind(this)
    this.resizeImage = this.resizeImage.bind(this)
    this.checkResizeCompleted = this.checkResizeCompleted.bind(this)
  }

  componentDidMount() {
    if (this.props.fileIn) {
      this.setState({ image_uploaded: true, fileOriginal: this.props.fileIn })
    }
  }

  resizeImage(fileIn, maxWidth, maxHeight, stateProps) {
    const context = this
    ImageTools.resize(
      fileIn,
      {
        width: maxWidth, // maximum width original file
        height: maxHeight // maximum height original file
      },
      function(blob, didItResize) {
        // didItResize will be true if it managed to resize it, otherwise false (and
        // will return the original file as 'blob')
        let data = (document.getElementById(context.props.idImg).src = window.URL.createObjectURL(blob))
        context.getBase64FromBlobUrl(data, function(dataUrl) {
          const newProps = {}
          newProps[stateProps] = dataUrl
          const newState = Object.assign({}, context.state, newProps)
          context.setState({ ...newState })
          context.checkResizeCompleted()
        })
        // you can also now upload this blob using an XHR.
      }
    )
  }

  checkResizeCompleted() {
    if (this.state.fileOriginal && this.state.fileSmall && this.props.onChange) {
      this.setState({ image_uploaded: true })
      this.props.onChange(this.state.fileOriginal, this.state.fileSmall)
    }
  }

  handleFilesInput() {
    const file = this.fileInput.files[0]
    this.fileInput.value = ''
    this.resizeImage(file, 1000, 750, 'fileOriginal')
    this.resizeImage(file, 200, 150, 'fileSmall')
  }

  getBase64FromBlobUrl(url, callback) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function() {
      var reader = new FileReader()
      reader.onloadend = function() {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  removeImg() {
    this.setState({ image_uploaded: false, fileOriginal: '', fileSmall: '' })

    if (this.props.onChange) {
      this.props.onChange('', '')
    }
  }

  addRemoveImg() {
    let context = this

    if (this.state.image_uploaded) {
      this.removeImg()
    } else {
      this.fileInput.click()

      this.fileInput.onchange = function(e) {
        context.handleFilesInput()
      }
    }
  }

  render() {
    const { readOnly = false, label, idImg } = this.props
    let imageUploadedClassName = 'box-image'

    if (this.state.image_uploaded) {
      imageUploadedClassName += ' img-loaded'
    }

    return (
      <Fragment>
        <Row className="box-image-container">
          <div s={12} className={imageUploadedClassName}>
            {!readOnly && (
              <div style={{ width: '70px', height: '70px' }} onClick={this.addRemoveImg}>
                <button type="button" className="box-image-button">
                  <i className="icon-plus" />
                </button>
              </div>
            )}
            <div id="form1" className="former-form" encType="multipart/form-data">
              <input
                disabled={this.props.disabled}
                type="file"
                ref={input => {
                  this.fileInput = input
                }}
                accept=".jpg, .png, .jpeg, .bmp, .tif, .tiff|images/*"
                capture="camera"
              />
            </div>
            <img className="img-document" src={this.state.fileOriginal} />
          </div>
          <p className="box-image-suggestion-label">{label}</p>
          <img id={idImg} className="img-for-create-url" />
        </Row>
      </Fragment>
    )
  }
}
export default ImgUploader

ImgUploader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  fileIn: PropTypes.string,
  idImg: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  // flagImage: PropTypes.bool,
  disabled: PropTypes.bool
}
