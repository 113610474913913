import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Input, Button } from 'react-materialize'
import UserCardFirma from '../../../components/UserCardFirma'
import PdfFile from './PdfFile'
import staticDataFirma from '../../../static-data/data-firma.json'
import ModalGeneric from '../../../components/ModalGeneric'
import ModalePopup from '../../../components/ModalePopup'
// import { getToken } from '../../ConsulenzaBaseContainerLayout/utils'
import {
  getPromotore,
  module_type,
  module_flow,
  getQueryParams,
  getRandomStr
} from '../../ConsulenzaBaseContainerLayout/utils'

class FirmaComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tokenNamirial: null,
      iframeDOM: null,
      utilityRender: null,
      checkboxOtp: false,
      firmato: false,
      showModalFirma: false,
      showModalConfirm: false,
      modalConfirmTitle: '',
      modalConfirmText: '',
      signRoom: false
    }
    this.openIframeNamirial = this.openIframeNamirial.bind(this)
    this.openOrGetIframeNamirial = this.openOrGetIframeNamirial.bind(this)
    this.changeCheckBoxOtp = this.changeCheckBoxOtp.bind(this)
    this.sendDataDocument = this.sendDataDocument.bind(this)
    this.getDraftDocs = this.getDraftDocs.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.confirm = this.confirm.bind(this)
  }

  componentDidMount() {
    this.gotoParentDocument()
    // this.props.getFirma(this.props.match.params.id, getPromotore())
    this.props.getFirma(this.props.match.params.id, getPromotore())
  }

  componentDidUpdate(prevProps) {
    if (prevProps.utilityGetFirmaRequest.fetching && !this.props.utilityGetFirmaRequest.fetching) {
      const { listaClienti } = this.props
      this.setState({
        utilityRender: true,
        firmato: listaClienti && listaClienti.length > 0 ? !listaClienti.some(c => c.stato !== 'FIRMATO') : false
      })
    }

    if (
      this.props.utilitySaveDocumentRequest &&
      prevProps.utilitySaveDocumentRequest &&
      prevProps.utilitySaveDocumentRequest.fetching &&
      !this.props.utilitySaveDocumentRequest.fetching &&
      this.props.utilitySaveDocumentRequest.error === null
    ) {
      this.backHome()
    }

    if (
      this.props.utilityGetNamirialUrlRequest &&
      prevProps.utilityGetNamirialUrlRequest &&
      prevProps.utilityGetNamirialUrlRequest.fetching &&
      !this.props.utilityGetNamirialUrlRequest.fetching &&
      this.props.utilityGetNamirialUrlRequest.error === null
    ) {
      this.openIframeNamirial(this.props.namirialUrl)
    }

    if (
      this.props.updatePraticaDraftRequest &&
      prevProps.updatePraticaDraftRequest &&
      prevProps.updatePraticaDraftRequest.fetching &&
      !this.props.updatePraticaDraftRequest.fetching &&
      this.props.updatePraticaDraftRequest.error === null
    ) {
      const { pratica, history } = this.props
      history.push(`/aggiornamento-modulistica/compilazione-antiriciclaggio/${pratica.idPratica}`)
    }
  }

  backHome() {
    this.props.history.push('/')
  }

  gotoParentDocument() {
    const urlMatches = /tokenNamirial=([a-zA-Z0-9_.\-~]*)(&.*)*$/.exec(document.location.href)
    if (urlMatches && urlMatches[1] && urlMatches[1].length) {
      this.setState({ iframeDOM: null, tokenNamirial: urlMatches[1] })
    }
  }

  openIframeNamirial(link) {
    if (link) {
      const src = `${link}&token=${getPromotore()}&idIncarico=${this.props.match.params.id}`
      this.setState({
        iframeDOM: (
          <iframe
            id="namirialFrame"
            src={src}
            width="100%"
            height="680"
            sandbox="allow-forms allow-scripts allow-modals allow-same-origin allow-popups allow-pointer-lock allow-top-navigation allow-popups-to-escape-sandbox"
          />
        )
      })
    } else {
      this.setState({ utilityRender: true, showModalFirma: true })
    }
  }

  openOrGetIframeNamirial(link) {
    if (link) {
      this.openIframeNamirial(link)
    } else {
      this.props.getNamirialUrl(this.props.match.params.id)
    }

    // let idIncarico = this.props.match.params.id
    // this.props.setSigned({ idPratica: idIncarico, currentState: 'DA_FIRMARE' })
  }

  nextStep(type) {
    const {
      match: {
        params: { id }
      },
      updatePraticaDraft
    } = this.props
    switch (type) {
      case module_flow.sign_room:
        return this.setState({
          signRoom: true,
          showModalConfirm: true,
          modalConfirmTitle: staticDataFirma.modalConfirm.sign_room.title,
          modalConfirmText: staticDataFirma.modalConfirm.sign_room.text
        })
      case module_flow.modifica:
        return updatePraticaDraft(id)
      case module_flow.richiama:
        return this.setState({
          signRoom: false,
          showModalConfirm: true,
          modalConfirmTitle: staticDataFirma.modalConfirm.richiama.title,
          modalConfirmText: staticDataFirma.modalConfirm.richiama.text
        })
    }
  }

  sendDataDocument() {
    const {
      match: { params },
      saveDocument
    } = this.props
    saveDocument(params.id)
    // const {  Per la POC
    //   match: { params },
    //   updateStatoPratica
    // } = this.props
    // updateStatoPratica(params.id, 3)
  }

  changeCheckBoxOtp() {
    this.setState({ checkboxOtp: !this.state.checkboxOtp })
  }

  getDraftDocs() {
    this.props.downloadDraftDocs(this.props.match.params.id)
  }

  toggleModal() {
    this.setState({ showModalConfirm: !this.state.showModalConfirm })
  }

  confirm() {
    const {
      match: {
        params: { id }
      },
      richiamaPratica,
      praticaToSignRoom
    } = this.props
    this.toggleModal()
    if (this.state.signRoom) {
      praticaToSignRoom(id)
    } else {
      richiamaPratica(id, getRandomStr())
    }
  }

  render() {
    // this.props.updateStatoPratica(this.props.match.params.id, 2, this.props.tipoFirma)

    if (!this.state.utilityRender) return null

    const {
      listaDocumenti,
      listaClienti = [],
      location: { search }
    } = this.props
    const queryParams = getQueryParams(search)
    const moduleType = queryParams.get('module')

    return (
      <Row className="container-home paddingless marginless">
        {this.state.showModalFirma && this.state.utilityRender && (
          <ModalGeneric
            show={this.state.showModalFirma && this.state.utilityRender}
            text="Il carimento dei documenti è attualmente in corso, riprova a breve."
            handler={() => this.setState({ showModalFirma: false })}
          />
        )}
        <ModalePopup
          visible={this.state.showModalConfirm}
          title={this.state.modalConfirmTitle}
          text={this.state.modalConfirmText}
          primaryActionLabel="Continua"
          secondaryActionLabel="Annulla"
          secondaryAction={this.toggleModal}
          primaryAction={this.confirm}
        />
        <Col s={12} className="container-home-left">
          <section className="main-body no-aside">
            <header>
              <a onClick={() => this.backHome()} className="torna-pratiche">
                <i className="icon-arrow-back" /> Torna a elenco pratiche
              </a>
              <h1 className="title-macrosezione">Firma</h1>
            </header>
            <article className="page-left-content">
              <div className="background_pattern" />
              {this.state.iframeDOM}
              {this.state.iframeDOM === null && (
                <Fragment>
                  <div className="box-vertical-container ">
                    <div className="box-vertical">
                      <p>
                        {moduleType === module_type.ar
                          ? staticDataFirma.description_file_sign_room
                          : staticDataFirma.description_file_list}
                      </p>
                      <ul>
                        {listaDocumenti &&
                          listaDocumenti.map((documentoPdf, i) => <PdfFile key={i} documentoPdf={documentoPdf} />)}
                      </ul>
                      {moduleType === module_type.ar && (
                        <Button
                          className="right"
                          flat
                          waves="light"
                          onClick={() => this.getDraftDocs()}
                          disabled={listaClienti && listaClienti.some(c => c.stato === 'CONDIVISA')}>
                          {staticDataFirma.download_draft_docs}
                        </Button>
                      )}
                    </div>
                    <UserCardFirma
                      moduleType={moduleType}
                      callback={moduleType === module_type.ar ? this.nextStep : this.openOrGetIframeNamirial}
                      staticDataFirma={staticDataFirma}
                    />
                  </div>
                  {moduleType !== module_type.ar && (
                    <Row className="action-send-file">
                      <Col s={12}>
                        <Input
                          name="group1-vertical"
                          type="checkbox"
                          value={this.state.checkboxOtp}
                          disabled={!this.state.firmato}
                          onChange={this.changeCheckBoxOtp}
                          label={staticDataFirma.otp_label}
                          className="filled-in"
                        />
                        <br />
                        <Button
                          disabled={!(this.state.checkboxOtp && this.state.firmato)}
                          flat
                          waves="light"
                          onClick={() => this.sendDataDocument()}>
                          {staticDataFirma.invia}
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Fragment>
              )}
            </article>
          </section>
        </Col>
      </Row>
    )
  }
}

FirmaComponent.propTypes = {
  history: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object
  }),
  listaClienti: PropTypes.any,
  listaDocumenti: PropTypes.any,
  getFirma: PropTypes.func,
  utilityGetFirmaRequest: PropTypes.object,
  utilitySaveDocumentRequest: PropTypes.object,
  saveDocument: PropTypes.func,
  // setSigned: PropTypes.func,
  utilityGetNamirialUrlRequest: PropTypes.object,
  getNamirialUrl: PropTypes.func,
  namirialUrl: PropTypes.object,
  updatePraticaDraft: PropTypes.func,
  richiamaPratica: PropTypes.func,
  downloadDraftDocs: PropTypes.func,
  updatePraticaDraftRequest: PropTypes.object,
  pratica: PropTypes.object,
  location: PropTypes.object,
  praticaToSignRoom: PropTypes.func
}

export default FirmaComponent
