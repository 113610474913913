import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import DerogaCommissionaleComponent from './DerogaCommissionaleComponent'
import { getDeroghe, postDeroga } from '../../../store/modules/derogaCommissionale/actions'
import { dominioActionCreator } from '../../../store/modules/dominio/actions'
import { saveFormFieldsActionCreator } from '../../../store/modules/forms/actions'
import { getFormValues, getFormSyncErrors } from 'redux-form'
import { FORM_ADD_DEROGA } from '../../../store/modules/forms/constants'

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getDeroghe, postDeroga, dominioActionCreator, saveFormFieldsActionCreator }, dispatch)

const mapStateToProps = state => {
  return {
    formValues: getFormValues(FORM_ADD_DEROGA)(state),
    formErrors: getFormSyncErrors(FORM_ADD_DEROGA)(state),
    form: state.form,
    forms: state.forms,
    domini: state.dominio.domini,
    listaDeroghe: state.derogaCommissionale.listaDeroghe,
    apiRequest: state.derogaCommissionale.apiRequest,
    respPostDeroga: state.derogaCommissionale.respPostDeroga,
    intestatari: state.derogaCommissionale.intestatari
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DerogaCommissionaleComponent)
