import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'react-materialize'

const DropdownField = ({ input, meta: { touched, error }, s = 12, options, ...props }) => (
  <Input {...input} {...props} s={s} type="select" error={touched && error ? error : null}>
    {options}
  </Input>
)
export default DropdownField

DropdownField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any
  }).isRequired,
  s: PropTypes.number,
  options: PropTypes.array.isRequired
}
