import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import { Col, Row } from 'react-materialize'
import ReadOnlyInput from '../../../../../components/ReadOnlyInput'
import InputText from '../../../../../components/InputText'
import { renderSelect, renderCheckbox } from '../../../../../components/Forms/renderFields'
import { mapDominiToOptions, getDominio } from '../../../../ConsulenzaBaseContainerLayout/utils'

const regex = /^(?:[1-9]\d*)$/

const ModalitaPagamento = ({ fields, domini, formValues, isDisabled, change, importoRequired, origineRequired }) => (
  <Fragment>
    <h2 className="collapsible-section-title">Modalità di sottoscrizione</h2>
    <Row className="m-top10">
      {fields.FLAG_BONIFICO.readOnly && (
        <Col s={12}>
          <ReadOnlyInput label="Mezzo di pagamento" value="Bonifico" />
        </Col>
      )}
      {!fields.FLAG_BONIFICO.readOnly && (
        <Col s={12}>
          <ReadOnlyInput label="Mezzo di pagamento" />
        </Col>
      )}
    </Row>
    {!fields.FLAG_BONIFICO.readOnly && (
      <Row className="m-top10">
        <Field
          name={fields.FLAG_BONIFICO.id}
          label={fields.FLAG_BONIFICO.label}
          className="filled-in"
          onChange={(ev, value) => {
            if (!value && (!fields.FLAG_ASSEGNO || !formValues[fields.FLAG_ASSEGNO.id])) {
              change(fields.IMPORTO.id, '')
              change(fields.ORIGINE.id, '')
            }
          }}
          component={renderCheckbox}
        />
        {fields.FLAG_ASSEGNO && (
          <Field
            name={fields.FLAG_ASSEGNO.id}
            label={fields.FLAG_ASSEGNO.label}
            className="filled-in"
            onChange={(ev, value) => {
              if (!value && !formValues[fields.FLAG_BONIFICO.id]) {
                change(fields.IMPORTO.id, '')
                change(fields.ORIGINE.id, '')
                change(fields.ABI.id, '')
                change(fields.CAB.id, '')
                change(fields.NUM_CONTO.id, '')
                change(fields.NUM_ASSEGNO.id, '')
                change(fields.IMG.id, null)
                change(fields.IMG_THUMB.id, null)
              }
            }}
            component={renderCheckbox}
          />
        )}
      </Row>
    )}
    <Row className="m-top10">
      <InputText
        id={fields.IMPORTO.id}
        s={6}
        type="text"
        maxLength={9}
        onInput={ev => {
          const {
            target: { value }
          } = ev
          if (value && !regex.test(value)) {
            ev.target.value = formValues[fields.IMPORTO.id]
          } else {
            change(fields.IMPORTO.id, parseFloat(value) || '')
            if (!value) change(fields.ORIGINE.id, '')
          }
        }}
        label={`${fields.IMPORTO.label}${importoRequired ? '*' : ''}`}
        value={formValues[fields.IMPORTO.id]}
        disabled={isDisabled(fields.IMPORTO.id)}
        change={() => {}}
      />
      <Field
        name={fields.ORIGINE.id}
        size={6}
        label={`${fields.ORIGINE.label}${origineRequired ? '*' : ''}`}
        options={mapDominiToOptions(getDominio(domini, fields.ORIGINE.dominio), true)}
        disabled={isDisabled(fields.ORIGINE.id)}
        component={renderSelect}
      />
    </Row>
  </Fragment>
)

ModalitaPagamento.propTypes = {
  fields: PropTypes.shape({
    IMPORTO: PropTypes.object.isRequired,
    ORIGINE: PropTypes.object.isRequired,
    FLAG_BONIFICO: PropTypes.object,
    FLAG_ASSEGNO: PropTypes.object
  }),
  domini: PropTypes.object,
  formValues: PropTypes.object,
  isDisabled: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  importoRequired: PropTypes.bool,
  origineRequired: PropTypes.bool
}

export default ModalitaPagamento
