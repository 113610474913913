import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'
import SurveyRadio from '../../../components/SurveyRadio'
import Title from '../../../components/Title'

const SubRadio = ({
  formValues = {},
  template: {
    testoDomanda,
    strutturaInternaDomande: { domande }
  }
}) => {
  return (
    <React.Fragment>
      <div>
        <Title>{testoDomanda}</Title>
      </div>
      <Row>
        {domande.map(domanda => (
          <Col s={6} key={domanda.idDomanda}>
            <SurveyRadio template={domanda} formValues={formValues} />
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}
export default SubRadio

SubRadio.propTypes = {
  formValues: PropTypes.object,
  template: PropTypes.shape({
    idDomanda: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    testoDomanda: PropTypes.string,
    strutturaInternaDomande: PropTypes.shape({
      domande: PropTypes.array,
      listaDominiRisposte: PropTypes.array
    })
  }).isRequired
}
