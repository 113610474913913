import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, isDirty, Field } from 'redux-form'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../../components/Collapsible'
import AccordionHeader from '../../../../../components/AccordionHeader'
import { renderAutocomplete } from '../../../../../components/Forms/renderFields'
import { APERTURA_INFO, INFORMAZIONI_PERSONALI, RESIDENZA } from '../../../../../store/modules/forms/constants'
import allFields from '../../../fields'
import censimentoFields from '../../../../ConsulenzaBaseContainerLayout/fields'
import {
  loadDefaultFields,
  getInFormErrors,
  notBelongToDominio,
  getDominio,
  getAutocompleteDefaultOption,
  mapDominiToAutocomplete
} from '../../../../ConsulenzaBaseContainerLayout/utils'
import ResidenzaForm from '../../../../../components/Forms/Residenza/ResidenzaForm'
import Title from '../../../../../components/Title'
import ButtonSubmit from '../../../../../components/ButtonSubmit'

const fields = allFields[APERTURA_INFO]
const defaultFields = loadDefaultFields(fields)
const selector = formValueSelector(APERTURA_INFO)

const loadInitialFields = (pratica = {}, dataRegistry = {}) => {
  const { COMUNE_FIRMA, REGIME, FLAG_INDIRIZZO } = fields
  const result = {
    [REGIME.id]: '' + (pratica[REGIME.id] || REGIME.defaultValue),
    [COMUNE_FIRMA.id]: (pratica[COMUNE_FIRMA.id] || {}).chiave || COMUNE_FIRMA.defaultValue,
    [FLAG_INDIRIZZO.id]: !!pratica[FLAG_INDIRIZZO.id]
  }
  if (pratica.corrispondenza) {
    return Object.keys(fields).reduce((res, cur) => {
      if (fields[cur].isNotCorrispondenza) return res
      if (!pratica.corrispondenza[fields[cur].id]) return res
      return {
        ...res,
        [fields[cur].id]: fields[cur].dominio
          ? pratica.corrispondenza[fields[cur].id].chiave
          : pratica.corrispondenza[fields[cur].id]
      }
    }, result)
  } else {
    const result = {}
    loadDataRegistry(dataRegistry, (key, value) => (result[key] = value))
    return result
  }
}

const loadDataRegistry = (dataRegistry, callback) => {
  Object.keys(fields).forEach(k => {
    if (fields[k].isNotCorrispondenza) return
    if (k === 'NOME' || k === 'COGNOME') {
      callback(fields[k].id, dataRegistry[censimentoFields[1][INFORMAZIONI_PERSONALI][k].id])
    } else {
      if (fields[k].dominio) {
        callback(fields[k].id, (dataRegistry[censimentoFields[1][RESIDENZA][k].id] || {}).chiave || '')
      } else {
        callback(fields[k].id, dataRegistry[censimentoFields[1][RESIDENZA][k].id])
      }
    }
  })
}

const validate = (values, props = {}) => {
  const { NOME, COGNOME, FLAG_INDIRIZZO, NAZIONE, PROVINCIA, COMUNE, COMUNE_FIRMA, CAP } = fields
  const { domini = {} } = props
  const errors = {}
  if (!!values[COMUNE_FIRMA.id] && notBelongToDominio(domini, COMUNE_FIRMA.dominio, values[COMUNE_FIRMA.id])) {
    errors[COMUNE_FIRMA.id] = 'Comune non riconosciuto'
  }
  if (values[FLAG_INDIRIZZO.id]) {
    if (!!values[NOME.id] && !new RegExp(NOME.validazione).test(values[NOME.id])) {
      errors[NOME.id] = 'Il nome può contenere solo caratteri alfabetici'
    }
    if (!!values[COGNOME.id] && !new RegExp(COGNOME.validazione).test(values[COGNOME.id])) {
      errors[COGNOME.id] = 'Il cognome può contenere solo caratteri alfabetici'
    }
    if (!!values[NAZIONE.id] && notBelongToDominio(domini, NAZIONE.dominio, values[NAZIONE.id])) {
      errors[NAZIONE.id] = 'Nazione non riconosciuta'
    }
    if (!!values[PROVINCIA.id] && notBelongToDominio(domini, PROVINCIA.dominio, values[PROVINCIA.id])) {
      errors[PROVINCIA.id] = 'Provincia non riconosciuta'
    }
    if (
      !!values[PROVINCIA.id] &&
      !!values[COMUNE.id] &&
      notBelongToDominio(domini, COMUNE.dominio, values[COMUNE.id])
    ) {
      errors[COMUNE.id] = 'Comune non riconosciuto'
    }
    if (!!values[CAP.id] && notBelongToDominio(domini, CAP.dominio, values[CAP.id])) {
      errors[CAP.id] = 'CAP non riconosciuto'
    }
    // if (CIVICO && !!values[CIVICO.id] && !(new RegExp(CIVICO.validazione)).test(values[CIVICO.id])) {
    //   errors[CIVICO.id] = 'Numero civico non valido'
    // }
  }

  return errors
}

class InformazioniGenerali extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      waitingDomini: false
    }
    this.addResidenzaToggle = this.addResidenzaToggle.bind(this)
    this.submit = this.submit.bind(this)
  }

  // mapDominiToAutocomplete(getDominio(domini, NAZIONE.dominio))

  componentDidMount() {
    // console.log(this.props.isPG)
    if (this.props.isPG === false) {
      // this.props.change(fields.REGIME.id, '1')
      this.props.initialValues.idRegimeAppartenenza = '1'
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && !this.props.apiError && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(APERTURA_INFO)
      this.setState({ saved: true })
    }

    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && this.state.waitingDomini) {
      const { change, formValues } = this.props
      change(fields.FLAG_INDIRIZZO.id, !formValues[fields.FLAG_INDIRIZZO.id])
      this.setState({ waitingDomini: false })
    }
  }

  addResidenzaToggle() {
    const { change, formValues, dataRegistry, updateDomini, isPG } = this.props

    if (!formValues[fields.FLAG_INDIRIZZO.id]) {
      if (!isPG) {
        loadDataRegistry(dataRegistry, change)
        updateDomini(
          ['COMUNE', 'CAP'].reduce((res, cur) => {
            return [
              ...res,
              {
                idDominio: fields[cur].dominio,
                filtro: {
                  codice: formValues[fields[cur].dominioDep]
                }
              }
            ]
          }, [])
        )
        this.setState({ waitingDomini: true })
      } else {
        change(fields.FLAG_INDIRIZZO.id, true)
      }
    } else {
      change(fields.FLAG_INDIRIZZO.id, !formValues[fields.FLAG_INDIRIZZO.id])
    }
  }

  submit(values) {
    const {
      onSave,
      pratica: { idPratica },
      initialValues
    } = this.props
    onSave(
      idPratica,
      Object.keys(fields).reduce((res, cur) => {
        const field = fields[cur]
        if (field.isNotCorrispondenza) {
          if (field.dominio) {
            return {
              ...res,
              [field.id]: {
                codiceDominio: field.dominio,
                chiave: values[field.id]
              }
            }
          }
          if (field.skip || (!values[field.id] && values[field.id] !== false)) {
            return res
          }
          return {
            ...res,
            [field.id]: values[field.id]
          }
        }
        if (field.skip || (!values[field.id] && values[field.id] !== false)) {
          return res
        }
        const chiave = values[fields.FLAG_INDIRIZZO.id] ? values[field.id] : initialValues[field.id]
        return {
          ...res,
          corrispondenza: {
            ...res.corrispondenza,
            [field.id]: field.dominio ? { codiceDominio: field.dominio, chiave } : chiave
          }
        }
      }, {})
    )
  }

  render() {
    const {
      serviceData: { servizio_14 },
      handleSubmit,
      submitting,
      isCompleted,
      isDirty,
      invalid,
      domini,
      formValues,
      change,
      isFetching
    } = this.props
    const { COMUNE_FIRMA, REGIME, FLAG_INDIRIZZO } = fields
    const errori = getInFormErrors(validate(formValues, { domini }), fields)

    return (
      <CollapsibleItem
        itemKey={APERTURA_INFO}
        header="Informazioni Generali"
        icon="keyboard_arrow_down"
        disabled={this.props.disabled}
        labelInfo={AccordionHeader(isDirty, this.state.saved, !isDirty && isCompleted ? 'COMPLETATA' : 'BOZZA')}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {!servizio_14 && (
            <Row>
              <Col s={12}>
                <Title>Dichiarazione del regime di appartenenza*</Title>
                <div className="radio-horizontal">
                  <p>
                    <Field
                      name={REGIME.id}
                      className="with-gap"
                      type="radio"
                      id="risparmio-amministrato"
                      value="1"
                      component="input"
                      disabled
                    />
                    <label htmlFor="risparmio-amministrato">RISPARMIO AMMINISTRATO</label>
                  </p>
                  <p>
                    <Field
                      name={REGIME.id}
                      className="with-gap"
                      type="radio"
                      id="dichiarazione"
                      value="2"
                      component="input"
                      disabled
                    />
                    <label htmlFor={'dichiarazione'}>DICHIARAZIONE</label>
                  </p>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col s={6}>
              <Field
                name={COMUNE_FIRMA.id}
                s={12}
                threshold={0}
                label={COMUNE_FIRMA.label}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  COMUNE_FIRMA.dominio,
                  formValues[COMUNE_FIRMA.id]
                )}
                options={mapDominiToAutocomplete(getDominio(this.props.domini, fields.COMUNE_FIRMA.dominio))}
                handleChange={(field, value) => change(field, value)}
                component={renderAutocomplete}
              />
            </Col>
          </Row>
          {!servizio_14 && (
            <Row>
              <Col s={12}>
                <hr />
                {!formValues[FLAG_INDIRIZZO.id] && (
                  <span className="indirizzo-alternativo">
                    <b>Indirizzo per comunicazione:</b> uguale a indirizzo residenza intestatario
                  </span>
                )}
                <a onClick={this.addResidenzaToggle} className="indirizzo-alternativo-button">
                  {!formValues[FLAG_INDIRIZZO.id] && 'MODIFICA'}
                  {formValues[FLAG_INDIRIZZO.id] && 'ANNULLA MODIFICHE'}
                </a>
              </Col>
              <Col s={12}>{formValues[FLAG_INDIRIZZO.id] && <ResidenzaForm fields={fields} {...this.props} />}</Col>
            </Row>
          )}
          <ButtonSubmit
            disabled={submitting || !isDirty || invalid || isFetching || Object.keys(errori).some(e => !!errori[e])}
          />
        </form>
      </CollapsibleItem>
    )
  }
}

InformazioniGenerali.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  formValues: PropTypes.object,
  initialValues: PropTypes.object,
  serviceData: PropTypes.object,
  domini: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  updateDominio: PropTypes.func,
  updateDomini: PropTypes.func,
  dominioRequest: PropTypes.object,
  pratica: PropTypes.object,
  dataRegistry: PropTypes.object,
  isCompleted: PropTypes.bool,
  disabled: PropTypes.bool,
  isPG: PropTypes.bool
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadInitialFields(state.pratica.pratica, state.customers.dataRegistry),
    idRegimeAppartenenza: '1'
  },
  formValues: {
    ...defaultFields,
    ...loadInitialFields(state.pratica.pratica, state.customers.dataRegistry),
    ...selector(state, ...Object.keys(defaultFields).reduce((res, cur) => [...res, cur], [])),
    idRegimeAppartenenza: '1'
  },
  isDirty: isDirty(APERTURA_INFO)(state),
  isFetching: state.pratica.updatePraticaRequest.fetching,
  apiError: state.pratica.updatePraticaRequest.error
}))(
  reduxForm({
    form: APERTURA_INFO,
    validate,
    enableReinitialize: true
  })(InformazioniGenerali)
)
