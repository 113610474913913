import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Button, Row, Col } from 'react-materialize'
import { Field } from 'redux-form'
import { renderAutocomplete, renderDatePicker, renderSelect } from '../renderFields'
import {
  getAutocompleteDefaultOption,
  mapDominiToAutocomplete,
  getDominio
} from '../../../routes/ConsulenzaBaseContainerLayout/utils'
import { checkInvalidBirthdate } from '../../../utils'

const CalcoloCodiceFiscale = props => {
  const {
    formValues,
    domini,
    change,
    notVerified,
    ulterioriDatiChange,
    fields,
    computeCFstate,
    onClick,
    checkNazione,
    checkProvincia,
    computeCFRequest,
    formWarnings,
    handleGetCF,
    updateDominio,
    formErrors
  } = props
  return (
    <Fragment>
      <Row className={`m-top10${computeCFstate ? '' : ' m-bottom50'}`}>
        <Col s={12} className="hr">
          <hr />
          <a
            onClick={() => {
              ulterioriDatiChange()
              onClick()
            }}
            className="indirizzo-alternativo-button">
            {`${computeCFstate ? 'Nascondi calcolo' : 'Calcola'} codice fiscale`}
            <i className="material-icons">{`expand_${computeCFstate ? 'less' : 'more'}`}</i>
          </a>
        </Col>
      </Row>
      <div className={`calcolo-cf${computeCFstate ? ' calcolo-cf-active' : ''}`}>
        <div>
          <Row>
            <Field
              name={fields.SESSO.id}
              size={6}
              label={fields.SESSO.label}
              options={[
                <option key={-1} value="" disabled>
                  -
                </option>,
                <option key={1} value="M">
                  Maschio
                </option>,
                <option key={2} value="F">
                  Femmina
                </option>
              ]}
              component={renderSelect}
            />
            <Field
              name={fields.DATA_NASCITA.id}
              s={6}
              openDirection="down"
              anchorDirection="right"
              label={fields.DATA_NASCITA.label}
              readOnly
              placeholder=""
              isOutsideRange={date => checkInvalidBirthdate(date)}
              defaultDate={formValues[fields.DATA_NASCITA.id]}
              onDateChange={date => change(fields.DATA_NASCITA.id, date.format())}
              component={renderDatePicker}
            />
          </Row>
          <Row>
            <Col s={6}>
              <Field
                name={fields.NAZIONE_NASCITA.id}
                s={12}
                label={fields.NAZIONE_NASCITA.label}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.NAZIONE_NASCITA.dominio,
                  fields.NAZIONE_NASCITA.italiaId
                )}
                options={mapDominiToAutocomplete(getDominio(domini, fields.NAZIONE_NASCITA.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[fields.PROVINCIA_NASCITA.id]) {
                    change(fields.PROVINCIA_NASCITA.id, '')
                  }
                  if (formValues[fields.COMUNE_NASCITA.id]) {
                    change(fields.COMUNE_NASCITA.id, '')
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
            <Col s={6}>
              <Field
                name={fields.PROVINCIA_NASCITA.id}
                s={12}
                label={fields.PROVINCIA_NASCITA.label}
                threshold={0}
                disabled={!checkNazione(true)}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.PROVINCIA_NASCITA.dominio,
                  formValues[fields.PROVINCIA_NASCITA.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, fields.PROVINCIA_NASCITA.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[fields.COMUNE_NASCITA.id]) {
                    change(fields.COMUNE_NASCITA.id, '')
                  }
                  if (checkProvincia(value)) {
                    updateDominio(fields.COMUNE_NASCITA.dominio, value)
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
          </Row>
          <Row>
            <Col s={6}>
              <Field
                name={fields.COMUNE_NASCITA.id}
                s={12}
                threshold={0}
                label={fields.COMUNE_NASCITA.label}
                disabled={!checkProvincia(formValues[fields.PROVINCIA_NASCITA.id])}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.COMUNE_NASCITA.dominio,
                  formValues[fields.COMUNE_NASCITA.id]
                )}
                options={
                  formValues[fields.PROVINCIA_NASCITA.id]
                    ? mapDominiToAutocomplete(getDominio(domini, fields.COMUNE_NASCITA.dominio))
                    : []
                }
                handleChange={(field, value) => change(field, value)}
                component={renderAutocomplete}
              />
            </Col>
          </Row>
          <Row className="center-button m-top20">
            <Button
              type="button"
              disabled={
                computeCFRequest || formValues.nationBirthCode === 'ITA'
                  ? Object.keys(formWarnings).length > 0
                  : formValues.nationBirthCode === undefined || formValues.nationBirthCode === ''
                  ? true
                  : false || formErrors[fields.NAZIONE_NASCITA.id]
                // formErrors[fields.PROVINCIA_NASCITA.id] ||
                // formErrors[fields.COMUNE_NASCITA.id]
              }
              onClick={() => {
                notVerified()
                handleGetCF()
              }}>
              Calcola codice fiscale
            </Button>
          </Row>
        </div>
      </div>
    </Fragment>
  )
}
export default CalcoloCodiceFiscale

CalcoloCodiceFiscale.propTypes = {
  computeCFstate: PropTypes.bool,
  computeCFRequest: PropTypes.bool,
  domini: PropTypes.object,
  change: PropTypes.func,
  formValues: PropTypes.object,
  formWarnings: PropTypes.object,
  fields: PropTypes.object,
  onClick: PropTypes.func,
  checkNazione: PropTypes.func,
  checkProvincia: PropTypes.func,
  handleGetCF: PropTypes.func,
  updateDominio: PropTypes.func,
  formErrors: PropTypes.any,
  notVerified: PropTypes.any,
  ulterioriDatiChange: PropTypes.any
}
