import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { CENSIMENTO_PROCURATORE } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly/index'
// import InputImage from '../../../../components/InputImage'
import InputImage from '../../../../components/Inputs/Image'

import { getValueDominioFromKey } from '../../utils'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'

const fields = allFields[0][CENSIMENTO_PROCURATORE]

class ProcuratoreReview extends Component {
  render() {
    const {
      data,
      domini: { listaDomini }
    } = this.props
    const {
      NOME,
      COGNOME,
      CODICE_FISCALE,
      RESIDENZA_ANAGRAFICA,
      CF_FRONTE,
      CF_RETRO,
      PROVINCIA_RESIDENZA,
      COMUNE_RESIDENZA,
      INDIRIZZO,
      CAP
    } = fields
    return (
      <CollapsibleItem
        header={dataSB.acquisizione.procuratore.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={CENSIMENTO_PROCURATORE}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={4}>
            <ReadOnlyInput label={NOME.label} value={data[NOME.id]} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={COGNOME.label} value={data[COGNOME.id]} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={CODICE_FISCALE.label} value={data[CODICE_FISCALE.id]} />
          </Col>
          {/* <Col s={2}>
            {data.flagIsBlacklist ? (
              <span className="privacyAccepted blacklist-align">
                <Icon className="blacklist-alert">warning</Icon>
                CF presente in Blacklist
              </span>
            ) : (
              <span className="privacyAccepted blacklist-align">
                <i className="icon-check-codice-fiscale-success" />
                CF non presente in Blacklist
              </span>
            )}
          </Col> */}
        </Row>
        <Row>
          <Col s={4}>
            <ReadOnlyInput
              label={RESIDENZA_ANAGRAFICA.label}
              value={getValueDominioFromKey(
                listaDomini,
                RESIDENZA_ANAGRAFICA.dominio,
                data[RESIDENZA_ANAGRAFICA.id] || {} // .chiave
              )}
            />
          </Col>
          <Col s={4}>
            <ReadOnlyInput
              label={PROVINCIA_RESIDENZA.label}
              value={getValueDominioFromKey(
                listaDomini,
                PROVINCIA_RESIDENZA.dominio,
                data[PROVINCIA_RESIDENZA.id] || {} // .chiave
              )}
            />
          </Col>
          <Col s={4}>
            <ReadOnlyInput
              label={COMUNE_RESIDENZA.label}
              value={getValueDominioFromKey(
                listaDomini,
                COMUNE_RESIDENZA.dominio,
                data[COMUNE_RESIDENZA.id] || {} // .chiave
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col s={4}>
            <ReadOnlyInput label={INDIRIZZO.label} value={data[INDIRIZZO.id]} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput
              label={CAP.label}
              value={getValueDominioFromKey(
                listaDomini,
                CAP.dominio,
                data[CAP.id] || {} // .chiave
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col s={6}>
            <InputImage
              readOnly
              label={
                <span>
                  <b>FRONTE</b> documento*
                </span>
              }
              fileIn={data[CF_FRONTE.id]}
            />
          </Col>
          <Col s={6}>
            <InputImage
              readOnly
              label={
                <span>
                  <b>RETRO</b> documento*
                </span>
              }
              fileIn={data[CF_RETRO.id]}
            />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}

ProcuratoreReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object
}

export default ProcuratoreReview
