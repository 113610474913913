import React from 'react'
import { PropTypes } from 'prop-types'

const Title = ({ children, cssClass = '' }) => (
  <p className={cssClass}>
    <b>{children}</b>
  </p>
)
export default Title

Title.propTypes = {
  children: PropTypes.any.isRequired,
  cssClass: PropTypes.string
}
