import React, { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import { DerogaCommissionale } from './DerogaCommissionale'
import { DerogaIntestatari } from './DerogaIntestatari'
import ModaleDirtyForm from '../../components/Modals/DirtyForm'
import { Row, Col } from 'react-materialize'

class DerogaProcessComponent extends Component {
  componentDidMount() {
    console.log(this.props.match)
  }

  render() {
    return (
      <Row className="container-home paddingless marginless">
        <ModaleDirtyForm history={this.props.history} />
        <Col s={12} className="container-home-left">
          {
            <section className="main-body no-aside">
              <Row className="thule-header valign-wrapper">
                <Col s={6} className="left-align">
                  <p className="thule-header-title">Deroga Commisionale</p>
                  <a onClick={() => this.props.history.push('/')} className="torna-pratiche">
                    <i className="icon-arrow-back" /> Torna a elenco pratiche
                  </a>
                </Col>
                <Col s={6} className="right-align" />
              </Row>
              <Fragment>
                <Switch>
                  <Route
                    path={`${this.props.match.path}/commissionale`}
                    idCustomer={this.props.match.params.idCustomer}
                    component={DerogaCommissionale}
                  />
                  <Route
                    path={`${this.props.match.path}/intestatari`}
                    idCustomer={this.props.match.params.idCustomer}
                    component={DerogaIntestatari}
                  />
                </Switch>
              </Fragment>
            </section>
          }
        </Col>
      </Row>
    )
  }
}

DerogaProcessComponent.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object
}

export default DerogaProcessComponent
