import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, isDirty, getFormInitialValues, getFormSyncErrors } from 'redux-form'
import { CODICE_FISCALE_PF } from '../../../../store/modules/forms/constants'
import { loadDefaultFields, loadDocumentsData } from '../../utils'
import allFields from '../../fields'
import CodiceFiscaleGiuridiche from './CodiceFiscaleGiuridicheComponent'
import { inBlacklistCF, getDataRegistry } from '../../../../store/modules/customers/actions'

const fieldsPG = allFields[6][CODICE_FISCALE_PF]
const defaultFields = loadDefaultFields(fieldsPG)

const validate = (values, props = {}) => {
  const errors = {}

  const { CODICE_FISCALE } = fieldsPG

  if (values[CODICE_FISCALE.id]) {
    if (values[CODICE_FISCALE.id].length < CODICE_FISCALE.minLength) {
      errors[CODICE_FISCALE.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CODICE_FISCALE.validazione).test(values[CODICE_FISCALE.id])) {
      errors[CODICE_FISCALE.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      inBlacklistCF,
      getDataRegistry
    },
    dispatch
  )

const mapStateToProps = (state, ownProps) => {
  const commonStateToProps = {
    customer: { ...state.customers.customer.dataRegistry, ...state.customers.customer },
    formValues: getFormValues(CODICE_FISCALE_PF)(state),
    currentInitial: getFormInitialValues(CODICE_FISCALE_PF)(state),
    isDirty: isDirty(CODICE_FISCALE_PF)(state),
    formErrors: getFormSyncErrors(CODICE_FISCALE_PF)(state)
  }
  if (ownProps.isProspectPersonaGiuridica) {
    commonStateToProps.isFetching = state.anagraficaPersonaGiuridicaCliente.postDocIDPGRequest.fetching
    commonStateToProps.apiError = state.anagraficaPersonaGiuridicaCliente.postDocIDPGRequest.error
    commonStateToProps.flagCustomerIsBlacklist = state.anagraficaPersonaGiuridicaCliente.docIDPF.flagCustomerIsBlacklist
    commonStateToProps.isEnabledCF = state.anagraficaPersonaGiuridicaCliente.docIDPF.isEnabledCF
    commonStateToProps.dataVerificaClienti = state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti
    commonStateToProps.currentForm = state.anagraficaPersonaGiuridicaCliente.currentForm
    commonStateToProps.initialValues = {
      ...loadDocumentsData(fieldsPG, state.anagraficaPersonaGiuridicaCliente.docIDPF),
      taxCode: state.anagraficaPersonaGiuridicaCliente.docIDPF.taxCode
    }
    commonStateToProps.fields = fieldsPG
  } else {
    commonStateToProps.isFetching = state.customers.postDataAllegatiCFRequest.fetching
    commonStateToProps.apiError = state.customers.postDataAllegatiCFRequest.error
    commonStateToProps.flagCustomerIsBlacklist = state.customers.dataRegistry.flagCustomerIsBlacklist
    commonStateToProps.initialValues = {
      ...defaultFields,
      ...loadDocumentsData(fieldsPG, state.customers.documentsData),
      [fieldsPG.CODICE_FISCALE_PF.id]:
        state.customers.dataRegistry.codiceFiscale ||
        state.customers.customer.codiceFiscale ||
        state.customers.documentsData.taxCode
    }
    commonStateToProps.fields = fieldsPG
  }
  return commonStateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: CODICE_FISCALE_PF,
    enableReinitialize: true,
    validate,
    shouldError: () => isDirty(CODICE_FISCALE_PF)
  })(CodiceFiscaleGiuridiche)
)
