import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { DOCUMENTO_IDENTITA_ALLEGATO } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import InputImage from '../../../../components/Inputs/Image'
import { getValueDominioFromKey } from '../../utils'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'

const fields = allFields[0][DOCUMENTO_IDENTITA_ALLEGATO]

class DocumentiReview extends Component {
  render() {
    const {
      data,
      domini: { listaDomini }
    } = this.props
    const {
      TIPO_DOCUMENTO,
      NAZIONE_RILASCIO,
      PROVINCIA_RILASCIO,
      COMUNE_RILASCIO,
      COMUNE_ESTERO_RILASCIO,
      NUMERO_DOCUMENTO,
      DATA_RILASCIO,
      DATA_SCADENZA
    } = fields

    return (
      <CollapsibleItem
        header={dataSB.acquisizione.documento_identita.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={DOCUMENTO_IDENTITA_ALLEGATO}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={4}>
            <ReadOnlyInput
              label={TIPO_DOCUMENTO.label}
              value={getValueDominioFromKey(
                listaDomini,
                TIPO_DOCUMENTO.dominio,
                (data[TIPO_DOCUMENTO.id] || {}).chiave
              )}
            />
          </Col>
          <Col s={4}>
            <ReadOnlyInput
              label={NAZIONE_RILASCIO.label}
              value={getValueDominioFromKey(
                listaDomini,
                NAZIONE_RILASCIO.dominio,
                (data[NAZIONE_RILASCIO.id] || {}).chiave
              )}
            />
          </Col>
          <Col s={4}>
            {(data[PROVINCIA_RILASCIO.id] || {}).chiave && (
              <ReadOnlyInput
                label={PROVINCIA_RILASCIO.label}
                value={getValueDominioFromKey(
                  listaDomini,
                  PROVINCIA_RILASCIO.dominio,
                  data[PROVINCIA_RILASCIO.id].chiave
                )}
              />
            )}
            {!(data[PROVINCIA_RILASCIO.id] || {}).chiave && (
              <ReadOnlyInput label={COMUNE_ESTERO_RILASCIO.label} value={data[COMUNE_ESTERO_RILASCIO.id]} />
            )}
          </Col>
        </Row>
        <Row>
          <Col s={4}>
            <ReadOnlyInput label={NUMERO_DOCUMENTO.label} value={data[NUMERO_DOCUMENTO.id]} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={DATA_RILASCIO.label} value={moment(data[DATA_RILASCIO.id]).format('DD/MM/YYYY')} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={DATA_SCADENZA.label} value={moment(data[DATA_SCADENZA.id]).format('DD/MM/YYYY')} />
          </Col>
        </Row>
        <Row>
          <Col s={4}>
            {(data[PROVINCIA_RILASCIO.id] || {}).chiave && (
              <ReadOnlyInput
                label={COMUNE_RILASCIO.label}
                value={getValueDominioFromKey(
                  listaDomini,
                  COMUNE_RILASCIO.dominio,
                  (data[COMUNE_RILASCIO.id] || {}).chiave
                )}
              />
            )}
          </Col>
          <Col s={4}>
            <InputImage
              readOnly
              label={
                <span>
                  <b>FRONTE</b> documento*
                </span>
              }
              fileIn={data.imgDOCFronte}
            />
          </Col>
          <Col s={4}>
            <InputImage
              readOnly
              label={
                <span>
                  <b>RETRO</b> documento*
                </span>
              }
              fileIn={data.imgDOCRetro}
            />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}
export default DocumentiReview

DocumentiReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object
}
