export const SURVEY_MIFID_FETCH_START = 'SURVEY_MIFID_FETCH_START'
export const SURVEY_MIFID_FETCH_SUCCESS = 'SURVEY_MIFID_FETCH_SUCCESS'
export const SURVEY_MIFID_FETCH_ERROR = 'SURVEY_MIFID_FETCH_ERROR'

export const SURVEY_MIFID_SAVE_START = 'SURVEY_MIFID_SAVE_START'
export const SURVEY_MIFID_SAVE_ERROR = 'SURVEY_MIFID_SAVE_ERROR'
export const SURVEY_MIFID_SAVE_SUCCESS = 'SURVEY_MIFID_SAVE_SUCCESS'

export const SURVEY_AML_FETCH_START = 'SURVEY_AML_FETCH_START'
export const SURVEY_AML_FETCH_SUCCESS = 'SURVEY_AML_FETCH_SUCCESS'
export const SURVEY_AML_FETCH_ERROR = 'SURVEY_AML_FETCH_ERROR'

export const SURVEY_AML_SAVE_START = 'SURVEY_AML_SAVE_START'
export const SURVEY_AML_SAVE_SUCCESS = 'SURVEY_AML_SAVE_SUCCESS'
export const SURVEY_AML_SAVE_ERROR = 'SURVEY_AML_SAVE_ERROR'

export const GET_ADEGUATA_VERIFICA_TEMPLATE_START = 'GET_ADEGUATA_VERIFICA_TEMPLATE_START'
export const GET_ADEGUATA_VERIFICA_TEMPLATE_ERROR = 'GET_ADEGUATA_VERIFICA_TEMPLATE_ERROR'
export const GET_ADEGUATA_VERIFICA_TEMPLATE_SUCCESS = 'GET_ADEGUATA_VERIFICA_TEMPLATE_SUCCESS'

export const GET_ADEGUATA_VERIFICA_TEMPLATE_PG_START = 'GET_ADEGUATA_VERIFICA_TEMPLATE_PG_START'
export const GET_ADEGUATA_VERIFICA_TEMPLATE_PG_ERROR = 'GET_ADEGUATA_VERIFICA_TEMPLATE_PG_ERROR'
export const GET_ADEGUATA_VERIFICA_TEMPLATE_PG_SUCCESS = 'GET_ADEGUATA_VERIFICA_TEMPLATE_PG_SUCCESS'

export const SAVE_ADEGUATA_VERIFICA_START = 'SAVE_ADEGUATA_VERIFICA_START'
export const SAVE_ADEGUATA_VERIFICA_ERROR = 'SAVE_ADEGUATA_VERIFICA_ERROR'
export const SAVE_ADEGUATA_VERIFICA_SUCCESS = 'SAVE_ADEGUATA_VERIFICA_SUCCESS'

export const PROFESSIONALE_START = 'PROFESSIONALE_START'
export const PROFESSIONALE_ERROR = 'PROFESSIONALE_ERROR'
export const PROFESSIONALE_SUCCESS = 'PROFESSIONALE_SUCCESS'

export const SURVEY_AML_PG_FETCH_START = 'SURVEY_AML_PG_FETCH_START'
export const SURVEY_AML_PG_FETCH_SUCCESS = 'SURVEY_AML_PG_FETCH_SUCCESS'
export const SURVEY_AML_PG_FETCH_ERROR = 'SURVEY_AML_PG_FETCH_ERROR'

export const SURVEY_AML_PG_SAVE_START = 'SURVEY_AML_PG_SAVE_START'
export const SURVEY_AML_PG_SAVE_SUCCESS = 'SURVEY_AML_PG_SAVE_SUCCESS'
export const SURVEY_AML_PG_SAVE_ERROR = 'SURVEY_AML_PG_SAVE_ERROR'

export const POST_PROFESSIONALE_PG_START = 'POST_PROFESSIONALE_PG_START'
export const POST_PROFESSIONALE_PG_ERROR = 'POST_PROFESSIONALE_PG_ERROR'
export const POST_PROFESSIONALE_PG_SUCCESS = 'POST_PROFESSIONALE_PG_SUCCESS'

export const GET_PROFESSIONALE_PG_START = 'GET_PROFESSIONALE_PG_START'
export const GET_PROFESSIONALE_PG_ERROR = 'GET_PROFESSIONALE_PG_ERROR'
export const GET_PROFESSIONALE_PG_SUCCESS = 'GET_PROFESSIONALE_PG_SUCCESS'

export const GET_SURVEY_EXT_START = 'GET_SURVEY_EXT_START'
export const GET_SURVEY_EXT_SUCCESS = 'GET_SURVEY_EXT_SUCCESS'
export const GET_SURVEY_EXT_ERROR = 'GET_SURVEY_EXT_ERROR'

export const IMPORT_SURVEY_EXT_START = 'IMPORT_SURVEY_EXT_START'
export const IMPORT_SURVEY_EXT_SUCCESS = 'IMPORT_SURVEY_EXT_SUCCESS'
export const IMPORT_SURVEY_EXT_ERROR = 'IMPORT_SURVEY_EXT_ERROR'

export const SURVEY_AGG_AML_SAVE_START = 'SURVEY_AGG_AML_SAVE_START'
export const SURVEY_AGG_AML_SAVE_SUCCESS = 'SURVEY_AGG_AML_SAVE_SUCCESS'
export const SURVEY_AGG_AML_SAVE_ERROR = 'SURVEY_AGG_AML_SAVE_ERROR'

export const SURVEY_AGG_AML_PG_SAVE_START = 'SURVEY_AGG_AML_PG_SAVE_START'
export const SURVEY_AGG_AML_PG_SAVE_SUCCESS = 'SURVEY_AGG_AML_PG_SAVE_SUCCESS'
export const SURVEY_AGG_AML_PG_SAVE_ERROR = 'SURVEY_AGG_AML_PG_SAVE_ERROR'

export const IMPORT_SURVEY_EXT_AV_START = 'IMPORT_SURVEY_EXT_AV_START'
export const IMPORT_SURVEY_EXT_AV_SUCCESS = 'IMPORT_SURVEY_EXT_AV_SUCCESS'
export const IMPORT_SURVEY_EXT_AV_ERROR = 'IMPORT_SURVEY_EXT_AV_ERROR'
