import React, { Component, Fragment } from 'react'
import SmallMaterializeCard from '../../components/Cards/SmallMaterializeCard'
import LargeMaterializeCard from '../../components/Cards/LargeMaterializeCard'
import { Row } from 'react-materialize'
import handshake from '../../styles/icons/handshake.svg'
import prospect from '../../styles/icons/prospect.svg'
import piechart from '../../styles/icons/piechart.svg'
import PropTypes from 'prop-types'
// import { Field } from 'redux-form'

// import {
//   renderSelect
//   // renderCheckboxRadio
// } from '../../components/Forms/renderFields'

// const subjectType = {
//   'PHYSICAL_SUBJECT': 'Persone fisiche',
//   'LEGAL_PERSON': 'Persone giuridiche'
// }
// const clientType = {
//   'CONS_BASE': 'Clienti Consulenza Base',
//   'INDIVIDUO': 'Individuo ',
//   'FAMIGLIA': 'Famiglia ',
//   'IMPRENDITORE': 'Imprenditore'
// }
// const profile = {
//   'MANAGER': 'Area Manager',
//   'NO': 'Area Backoffice '
// }

// const timespan = {
//   '1': '1 mese',
//   '6': '6 mesi',
//   '12':'1 anno'
// }

class DashboardComponent extends Component {
  constructor(props) {
    super(props)
    // intialize state
    this.state = {
      initialValues: {
        prospectCount: '',
        prospectToCustomerPercentage: '',
        prospectPercentage: '',
        investmentServiceOverall: '',
        prospectIpOverall: ''
      }
    }
    this.submit = this.submit.bind(this)
  }

  componentDidMount(prevProps, prevState) {
    this.props.getProspectCountRequest({
      timespan: 0,
      subjectType: 'PHYSICAL_SUBJECT',
      profile: 'string',
      clientType: 'CONS_BASE'
    })
    this.props.getProspectToCustomerPercentageRequest({
      timespan: 0,
      subjectType: 'PHYSICAL_SUBJECT',
      profile: 'string',
      clientType: 'CONS_BASE'
    })
    this.props.getProspectPercentageRequest({
      timespan: 0,
      subjectType: 'PHYSICAL_SUBJECT',
      profile: 'string',
      clientType: 'CONS_BASE'
    })
    this.props.getInvestmentServiceOverallRequest({
      timespan: 0,
      subjectType: 'PHYSICAL_SUBJECT',
      profile: 'string',
      clientType: 'CONS_BASE'
    })
    this.props.getProspectIpOverallRequest({
      timespan: 0,
      subjectType: 'PHYSICAL_SUBJECT',
      profile: 'string',
      clientType: 'CONS_BASE'
    })
  }
  submit(submittedValues) {}

  render() {
    const {
      prospectCount,
      prospectToCustomerPercentage,
      prospectPercentage,
      investmentServiceOverall,
      prospectIpOverall
      // fields,
      // handleSubmit,
      // formValues
    } = this.props

    // const {
    //   SUBJECT,
    //   CLIENT,
    //   PROFILE,
    //   TIMESPAN
    //   // DOC_CHECK
    // } = fields

    if (
      prospectCount !== undefined &&
      prospectToCustomerPercentage !== undefined &&
      prospectPercentage !== undefined &&
      investmentServiceOverall !== undefined &&
      prospectIpOverall !== undefined &&
      prospectCount.fetching === false &&
      prospectToCustomerPercentage.fetching === false &&
      prospectPercentage.fetching === false &&
      investmentServiceOverall.fetching === false &&
      prospectIpOverall.fetching === false
    ) {
      return (
        <Fragment>
          <Row>
            <SmallMaterializeCard
              className="smallCard smallCard--first"
              textClassName="textWhite"
              title="Total prospect"
              actions="asd"
              value={prospectCount.payload.totalProspects}
              icon={prospect}
              previous={prospectCount.payload.previousMonthProspects}
              s="4"
            />
            <SmallMaterializeCard
              className="smallCard smallCard--second"
              textClassName="textWhite"
              title="Totale prospect diventati clienti"
              actions="asd"
              value={prospectToCustomerPercentage.payload.totalProspectToCustomerPercentage}
              icon={handshake}
              previous={prospectToCustomerPercentage.payload.previousMonthProspectToCustomerPercentage}
              s="4"
            />
            <SmallMaterializeCard
              className="smallCard smallCard--third"
              textClassName="textWhite"
              title="Percentuale prospect su clienti totali"
              actions="asd"
              value={prospectPercentage.payload.totalProspectPercentage}
              icon={piechart}
              previous={prospectPercentage.payload.previousMonthProspectPercentage}
              s="4"
            />
          </Row>

          {/* <form onSubmit={handleSubmit(this.submit)} noValidate>
            <Row s={10} className="align-center">
              <Field
                name={TIMESPAN.id}
                size={3}
                label={TIMESPAN.label}
                options={[
                  <option key={-1} value="" disabled>
                    -
                  </option>,
                  <option key={1} value="1">
                    1 mese
                  </option>,
                  <option key={2} value="6">
                    6 mesi
                  </option>,
                  <option key={2} value="12">
                    1 anno
                  </option>
                ]}
                component={renderSelect}
              />
              <Field
                name={SUBJECT.id}
                size={3}
                label={SUBJECT.label}
                options={[
                  <option key={-1} value="" disabled>
                    -
                  </option>,
                  <option key={1} value="PHYSICAL_SUBJECT">
                    Persone fisiche
                  </option>,
                  <option key={2} value="LEGAL_PERSON">
                    Persone giuridiche
                  </option>
                ]}
                component={renderSelect}
              />

              <Field
                name={CLIENT.id}
                size={3}
                label={CLIENT.label}
                disabled={!(formValues[SUBJECT.id] === 'PHYSICAL_SUBJECT')}
                options={[
                  <option key={-1} value="" disabled>
                    -
                  </option>,
                  <option key={1} value="CONS_BASE">
                    Clienti Consulenza Base
                  </option>,
                  <option key={2} value="INDIVIDUO">
                    Individuo
                  </option>,
                  <option key={2} value="FAMIGLIA">
                    Famiglia
                  </option>,
                  <option key={2} value="IMPRENDITORE">
                    Imprenditore
                  </option>
                ]}
                component={renderSelect}
              />

              <Field
                name={PROFILE.id}
                size={3}
                label={PROFILE.label}
                options={[
                  <option key={-1} value="" disabled>
                    -
                  </option>,
                  <option key={1} value="MANAGER">
                    Area Manager
                  </option>,
                  <option key={2} value="BO">
                    Area Backoffice
                  </option>
                ]}
                component={renderSelect}
              />
            </Row>
          </form> */}

          <Row>
            {investmentServiceOverall.payload.data.length > 0 && (
              <LargeMaterializeCard
                className="largeCard"
                textClassName="textWhite"
                title="Servizi attivati su tutti i prospect"
                actions="asd"
                chartType="barchart"
                s="4"
                data={investmentServiceOverall.payload}
              />
            )}
            {/* <LargeMaterializeCard
              className="largeCard"
              textClassName="textWhite"
              title="IP effettuati"
              actions="asd"
              chartType="piechart"
              s="4"
              data={prospectIpOverall.payload}
            /> */}
          </Row>
        </Fragment>
      )
    } else {
      return null
    }
  }
}

DashboardComponent.propTypes = {
  // formValues: PropTypes.object,
  // handleSubmit: PropTypes.func,
  prospectCount: PropTypes.object,
  prospectToCustomerPercentage: PropTypes.object,
  prospectPercentage: PropTypes.object,
  investmentServiceOverall: PropTypes.object,
  prospectIpOverall: PropTypes.object,
  getProspectCountRequest: PropTypes.func,
  getProspectToCustomerPercentageRequest: PropTypes.func,
  getProspectPercentageRequest: PropTypes.func,
  getInvestmentServiceOverallRequest: PropTypes.func,
  getProspectIpOverallRequest: PropTypes.func
  // fields: PropTypes.object
}

DashboardComponent.defaultProps = {
  todos: []
}

export default DashboardComponent
