import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import InformazioniPersonali from './InformazioniPersonaliComponent'
import { INFORMAZIONI_PERSONALI } from '../../../../store/modules/forms/constants'
import { notBelongToDominio, loadDataRegistry, loadDefaultFields } from '../../utils'
import allFields from '../../fields'

const fields = allFields[1][INFORMAZIONI_PERSONALI]
const defaultFields = loadDefaultFields(fields)

const loadCFInfo = customer => {
  return {
    [fields.NAZIONE_NASCITA.id]: (customer.codNazioneNascita || {}).chiave,
    [fields.PROVINCIA_NASCITA.id]: (customer.codProvinciaNascita || {}).chiave,
    [fields.COMUNE_NASCITA.id]: (customer.codComuneNascita || {}).chiave,
    [fields.DATA_NASCITA.id]: customer.dataNascita
  }
}

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const { NOME, COGNOME, NAZIONE_NASCITA, PROVINCIA_NASCITA, COMUNE_NASCITA, CITTADINANZA, CITTADINANZA_ALTRO } = fields

  if (!!values[NOME.id] && !new RegExp(NOME.validazione).test(values[NOME.id])) {
    errors[NOME.id] = 'Il nome può contenere solo caratteri alfabetici'
  }
  if (!!values[COGNOME.id] && !new RegExp(COGNOME.validazione).test(values[COGNOME.id])) {
    errors[COGNOME.id] = 'Il cognome può contenere solo caratteri alfabetici'
  }
  if (!!values[NAZIONE_NASCITA.id] && notBelongToDominio(domini, NAZIONE_NASCITA.dominio, values[NAZIONE_NASCITA.id])) {
    errors[NAZIONE_NASCITA.id] = 'Nessun risultato trovato'
  }
  if (
    !!values[PROVINCIA_NASCITA.id] &&
    notBelongToDominio(domini, PROVINCIA_NASCITA.dominio, values[PROVINCIA_NASCITA.id])
  ) {
    errors[PROVINCIA_NASCITA.id] = 'Nessun risultato trovato'
  }
  if (
    !!values[PROVINCIA_NASCITA.id] &&
    !!values[COMUNE_NASCITA.id] &&
    notBelongToDominio(domini, COMUNE_NASCITA.dominio, values[COMUNE_NASCITA.id])
  ) {
    errors[COMUNE_NASCITA.id] = 'Nessun risultato trovato'
  }
  if (!!values[CITTADINANZA.id] && notBelongToDominio(domini, CITTADINANZA.dominio, values[CITTADINANZA.id])) {
    errors[CITTADINANZA.id] = 'Nessun risultato trovato'
  }
  if (
    !!values[CITTADINANZA_ALTRO.id] &&
    notBelongToDominio(domini, CITTADINANZA.dominio, values[CITTADINANZA_ALTRO.id])
  ) {
    errors[CITTADINANZA_ALTRO.id] = 'Nessun risultato trovato'
  }

  if (values[NOME.id] && values[COGNOME.id]) {
    let maxLength = values[NOME.id].length + values[COGNOME.id].length
    if (maxLength > 50) {
      errors[COGNOME.id] = ' '
      errors[NOME.id] = 'La lunghezza dei campi Nome e Cognome non deve superare i 50 caratteri'
    }
  }

  return errors
}

const loadInitialValues = state => {
  if (!state.customers.dataRegistry.dataNascita) {
    return {
      ...defaultFields,
      ...loadDataRegistry(state.customers.dataRegistry, fields),
      ...loadCFInfo(state.customers.customer)
    }
  } else {
    return {
      ...defaultFields,
      ...loadDataRegistry(state.customers.dataRegistry, fields)
    }
  }
}

export default connect(state => ({
  initialValues: loadInitialValues(state),
  formValues: getFormValues(INFORMAZIONI_PERSONALI)(state),
  formErrors: getFormSyncErrors(INFORMAZIONI_PERSONALI)(state),
  isDirty: isDirty(INFORMAZIONI_PERSONALI)(state),
  fields,

  isFetching: state.customers.postDataRegistryRequest.fetching,
  apiError: state.customers.postDataRegistryRequest.error,
  customer: state.customers.customer,
  flagCustomerIsBlacklist: state.customers.dataRegistry.flagCustomerIsBlacklist,
  isDataFromCalculateCF: state.customers.dataFromCF
}))(
  reduxForm({
    form: INFORMAZIONI_PERSONALI,
    validate,
    enableReinitialize: true,
    shouldError: () => isDirty(INFORMAZIONI_PERSONALI)
  })(InformazioniPersonali)
)
