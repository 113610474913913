import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import FatcaGiuridiche from './FatcaGiuridicheComponent'
import { FATCA_GIURIDICHE } from '../../../../store/modules/forms/constants'
import { getInitialValues, loadDefaultFields } from '../../utils' // notBelongToDominio
import allFields from '../../fields'

const fields = allFields[1][FATCA_GIURIDICHE]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const errors = {}
  const {
    CP1_NAME,
    CP1_SURNAME,
    CP2_NAME,
    CP2_SURNAME,
    CP1_CF_RESIDENZA_FISCALE_1,
    CP1_CF_RESIDENZA_FISCALE_2,
    CP1_CF_RESIDENZA_FISCALE_3,
    CP2_CF_RESIDENZA_FISCALE_1,
    CP2_CF_RESIDENZA_FISCALE_2,
    CP2_CF_RESIDENZA_FISCALE_3,
    CRS_RESFISCDET1_CF,
    CRS_RESFISCDET2_CF,
    CRS_RESFISCDET3_CF,
    CA_DESCRIZIONE1,
    SPONSORED
  } = fields

  const E = 'Codice GIIN non valido'
  if (values[CA_DESCRIZIONE1.id]) {
    if (values[CA_DESCRIZIONE1.id].length !== 19) {
      errors[CA_DESCRIZIONE1.id] = E
    }
    if (!!values[CA_DESCRIZIONE1.id] && !new RegExp(CA_DESCRIZIONE1.validazione).test(values[CA_DESCRIZIONE1.id])) {
      errors[CA_DESCRIZIONE1.id] = E
    }
  }

  if (values[SPONSORED.id]) {
    if (values[SPONSORED.id].length !== 19) {
      errors[SPONSORED.id] = E
    }
    if (!!values[SPONSORED.id] && !new RegExp(SPONSORED.validazione).test(values[SPONSORED.id])) {
      errors[SPONSORED.id] = E
    }
  }

  if (!!values[CP1_NAME.id] && !new RegExp(CP1_NAME.validazione).test(values[CP1_NAME.id])) {
    errors[CP1_NAME.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (!!values[CP1_SURNAME.id] && !new RegExp(CP1_SURNAME.validazione).test(values[CP1_SURNAME.id])) {
    errors[CP1_SURNAME.id] = 'è possibile inserire solo caratteri alfanumerici'
  }

  if (!!values[CP2_NAME.id] && !new RegExp(CP2_NAME.validazione).test(values[CP2_NAME.id])) {
    errors[CP2_NAME.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (!!values[CP2_SURNAME.id] && !new RegExp(CP2_SURNAME.validazione).test(values[CP2_SURNAME.id])) {
    errors[CP2_SURNAME.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  //, , , CP2_CF_RESIDENZA_FISCALE_1, CP2_CF_RESIDENZA_FISCALE_2, CP2_CF_RESIDENZA_FISCALE_3
  if (values[CP2_CF_RESIDENZA_FISCALE_1.id]) {
    if (values[CP2_CF_RESIDENZA_FISCALE_1.id].length < 16) {
      errors[CP2_CF_RESIDENZA_FISCALE_1.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CP2_CF_RESIDENZA_FISCALE_1.validazione).test(values[CP2_CF_RESIDENZA_FISCALE_1.id])) {
      errors[CP2_CF_RESIDENZA_FISCALE_1.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (values[CP2_CF_RESIDENZA_FISCALE_2.id]) {
    if (values[CP2_CF_RESIDENZA_FISCALE_2.id].length < 16) {
      errors[CP2_CF_RESIDENZA_FISCALE_2.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CP2_CF_RESIDENZA_FISCALE_2.validazione).test(values[CP2_CF_RESIDENZA_FISCALE_2.id])) {
      errors[CP2_CF_RESIDENZA_FISCALE_2.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (values[CP2_CF_RESIDENZA_FISCALE_3.id]) {
    if (values[CP2_CF_RESIDENZA_FISCALE_3.id].length < 16) {
      errors[CP2_CF_RESIDENZA_FISCALE_3.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CP2_CF_RESIDENZA_FISCALE_3.validazione).test(values[CP2_CF_RESIDENZA_FISCALE_3.id])) {
      errors[CP2_CF_RESIDENZA_FISCALE_3.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }

  if (values[CP1_CF_RESIDENZA_FISCALE_1.id]) {
    if (values[CP1_CF_RESIDENZA_FISCALE_1.id].length < 16) {
      errors[CP1_CF_RESIDENZA_FISCALE_1.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CP1_CF_RESIDENZA_FISCALE_1.validazione).test(values[CP1_CF_RESIDENZA_FISCALE_1.id])) {
      errors[CP1_CF_RESIDENZA_FISCALE_1.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (values[CP1_CF_RESIDENZA_FISCALE_2.id]) {
    if (values[CP1_CF_RESIDENZA_FISCALE_2.id].length < 16) {
      errors[CP1_CF_RESIDENZA_FISCALE_2.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CP1_CF_RESIDENZA_FISCALE_2.validazione).test(values[CP1_CF_RESIDENZA_FISCALE_2.id])) {
      errors[CP1_CF_RESIDENZA_FISCALE_2.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (values[CP1_CF_RESIDENZA_FISCALE_3.id]) {
    if (values[CP1_CF_RESIDENZA_FISCALE_3.id].length < 16) {
      errors[CP1_CF_RESIDENZA_FISCALE_3.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CP1_CF_RESIDENZA_FISCALE_3.validazione).test(values[CP1_CF_RESIDENZA_FISCALE_3.id])) {
      errors[CP1_CF_RESIDENZA_FISCALE_3.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (values[CRS_RESFISCDET1_CF.id]) {
    if (values[CRS_RESFISCDET1_CF.id].length < 16) {
      errors[CRS_RESFISCDET1_CF.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CRS_RESFISCDET1_CF.validazione).test(values[CRS_RESFISCDET1_CF.id])) {
      errors[CRS_RESFISCDET1_CF.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (values[CRS_RESFISCDET2_CF.id]) {
    if (values[CRS_RESFISCDET2_CF.id].length < 16) {
      errors[CRS_RESFISCDET2_CF.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CRS_RESFISCDET2_CF.validazione).test(values[CRS_RESFISCDET2_CF.id])) {
      errors[CRS_RESFISCDET2_CF.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (values[CRS_RESFISCDET3_CF.id]) {
    if (values[CRS_RESFISCDET3_CF.id].length < 16) {
      errors[CRS_RESFISCDET3_CF.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CRS_RESFISCDET3_CF.validazione).test(values[CRS_RESFISCDET3_CF.id])) {
      errors[CRS_RESFISCDET3_CF.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }

  return errors
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...getInitialValues(fields, state.anagraficaPersonaGiuridicaCliente.dataFatcaPG)
  },
  formValues: getFormValues(FATCA_GIURIDICHE)(state),
  formErrors: getFormSyncErrors(FATCA_GIURIDICHE)(state),
  fields,
  isDirty: isDirty(FATCA_GIURIDICHE)(state),
  isFetching: state.anagraficaPersonaGiuridicaCliente.postFatcaPGReques.fetching,
  apiError: state.anagraficaPersonaGiuridicaCliente.postFatcaPGReques.error
}))(
  reduxForm({
    form: FATCA_GIURIDICHE,
    enableReinitialize: true,
    validate,
    shouldError: () => isDirty(FATCA_GIURIDICHE)
  })(FatcaGiuridiche)
)
