import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, isDirty, getFormValues, getFormSyncErrors, getFormInitialValues } from 'redux-form'
import { DASHBOARD } from '../../store/modules/forms/constants'
import allFields from './fields'
import { loadDefaultFields } from './utils'

import DashboardComponent from './DashboardComponent'
import {
  getProspectCountRequest,
  getProspectToCustomerPercentageRequest,
  getProspectPercentageRequest,
  getInvestmentServiceOverallRequest,
  getProspectIpOverallRequest
} from '../../store/modules/dashboard/actions'

const fields = allFields[DASHBOARD]
const defaultFields = loadDefaultFields(fields)

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProspectCountRequest,
      getProspectToCustomerPercentageRequest,
      getProspectPercentageRequest,
      getInvestmentServiceOverallRequest,
      getProspectIpOverallRequest
    },
    dispatch
  )
const mapStateToProps = state => {
  return {
    formValues: getFormValues(DASHBOARD)(state),
    formErrors: getFormSyncErrors(DASHBOARD)(state),
    initialValues: {
      ...defaultFields
    },
    currentInitial: getFormInitialValues(DASHBOARD)(state),
    prospectCount: state.dashboard.getProspectCount,
    prospectToCustomerPercentage: state.dashboard.getProspectToCustomerPercentage,
    prospectPercentage: state.dashboard.getProspectPercentage,
    investmentServiceOverall: state.dashboard.getInvestmentServiceOverall,
    prospectIpOverall: state.dashboard.getProspectIpOverall,
    fields,
    isDirty: isDirty(DASHBOARD)(state)
  }
}
// const mapStateToProps = state => ({
//   // reduxForms: Object.keys(DASHBOARD).reduce((res, form) => ({ ...res, [form]: isDirty(DASHBOARD[form])(state) }), {}),
//   formValues: getFormValues(DASHBOARD)(state),
//   formErrors: getFormSyncErrors(DASHBOARD)(state),
//   currentInitial: getFormInitialValues(DASHBOARD)(state),
//   prospectCount: state.dashboard.getProspectCount,
//   prospectToCustomerPercentage: state.dashboard.getProspectToCustomerPercentage,
//   prospectPercentage: state.dashboard.getProspectPercentage,
//   investmentServiceOverall: state.dashboard.getInvestmentServiceOverall,
//   prospectIpOverall: state.dashboard.getProspectIpOverall,
//   fields,
//   isDirty: isDirty(DASHBOARD)(state),
// })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: DASHBOARD
  })(DashboardComponent)
)
