import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'
import { FooterModal } from '../Common/Footer'

const ModalFirma = ({
  handler,
  title = 'Attenzione',
  text,
  children,
  typeFirma,
  url,
  idPratica,
  customer,
  idIncarico,
  later,
  save,
  updateStatoPratica,
  resetSelectedState,
  nextStep,
  proseguiDisabled,
  flag,
  push
}) => {
  return (
    <div className="onboarding-modale">
      <div className="onboarding-modale-content">
        <Row>
          <Col s={12} className="center-align" style={{ marginTop: '-20px' }}>
            {title}
          </Col>
          <Col s={12}>{text}</Col>
          <Col s={12} className="center-align">
            {children}
          </Col>
          <Col s={12} style={{ marginTop: '30px' }}>
            <FooterModal
              modalHandler={handler}
              route={typeFirma}
              flag={flag}
              url={url}
              idPratica={idPratica}
              id={(customer || {}).idIncarico || idIncarico}
              later={later}
              save={save}
              updateStatoPratica={updateStatoPratica}
              nextStep={nextStep}
              proseguiDisabled={proseguiDisabled}
              resetSelectedState={resetSelectedState}
              push={push}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default ModalFirma

ModalFirma.propTypes = {
  children: PropTypes.node,
  typeFirma: PropTypes.string,
  customer: PropTypes.object,
  handler: PropTypes.func.isRequired,
  title: PropTypes.object,
  text: PropTypes.object,
  url: PropTypes.any,
  idPratica: PropTypes.any,
  later: PropTypes.bool,
  save: PropTypes.func,
  updateStatoPratica: PropTypes.func,
  nextStep: PropTypes.func,
  resetSelectedState: PropTypes.func,
  flag: PropTypes.any,
  idIncarico: PropTypes.string,
  proseguiDisabled: PropTypes.bool,
  push: PropTypes.any
}
