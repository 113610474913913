import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isDirty, getFormInitialValues, untouch } from 'redux-form'
import * as forms from '../../../store/modules/forms/constants'
import CensimentoAnagrafico from './CensimentoAnagraficoComponent'
import {
  getDataRegistry,
  postDataRegistry,
  saveFatca,
  getClientiCorrelatiList,
  searchSoggettiCorrelati,
  searchSoggettiCorrelatiGiuridiche,
  inviaRichiestaSoggettiCorrelati,
  inviaRichiestaSoggettiCorrelatiGiuridiche,
  getSoggettiGiaCorrelati,
  getSoggettiGiaCorrelatiGiuridiche,
  getDocumentsData
} from '../../../store/modules/customers/actions'
import {
  getFatcaPG,
  postFatcaPG,
  getDocumentIdPGActionCreator
} from '../../../store/modules/personaGiuridica/client/actions'
import {
  getDataGatheringPGActionCreator,
  getDataSummaryLightPGActionCreator
} from '../../../store/modules/personaGiuridica/prospect/actions'

import { dominio } from '../../../store/modules/dominio/actions'
import { fetchSurveyAml, fetchSurveyAmlPg, saveAml, saveAmlPg } from '../../../store/modules/survey/actions'
import { validationCreateOtp } from '../../../store/modules/validation/actions'

const mapDispatchToProps = (dispatch, ownProps) => {
  const bindendActionCreator = {
    getDataRegistry,
    fetchSurveyAml,
    fetchSurveyAmlPg,
    validationCreateOtp,
    postDataRegistry,
    saveAml,
    saveAmlPg,
    saveFatca,
    getClientiCorrelatiList,
    searchSoggettiCorrelati,
    searchSoggettiCorrelatiGiuridiche,
    getSoggettiGiaCorrelati,
    getSoggettiGiaCorrelatiGiuridiche,
    inviaRichiestaSoggettiCorrelati,
    inviaRichiestaSoggettiCorrelatiGiuridiche,
    retrieveDominio: (...params) => dominio(...params),
    getDocumentsData,
    getFatcaPG,
    postFatcaPG,
    untouch,
    getDataGatheringPGActionCreator,
    getDataSummaryLightPGActionCreator,
    getDocumentIdPGActionCreator
  }
  return bindActionCreators(bindendActionCreator, dispatch)
}

const mapStateToProps = (state, ownProps) => {
  let commonstateToProps = {
    customer: state.customers.customer,
    dataGathering: state.prospectPersonaGiuridica.dataGathering,
    clientiCorrelatiList: state.customers.clientiCorrelatiList,
    users: state.customers.users,
    soggettiCorrelatiListData: state.customers.soggettiCorrelatiListData,
    soggettiGiaCorrelatiListData: state.customers.soggettiGiaCorrelatiListData,
    file: state.customers.file,
    getClientiCorrelatiRequest: state.customers.getClientiCorrelatiRequest,
    soggettiCorrelatiRequest: state.customers.soggettiCorrelatiRequest,
    inviaRichiestaRequest: state.customers.inviaRichiestaRequest,
    inviaRichiestaGiuridicheRequest: state.customers.inviaRichiestaGiuridicheRequest,
    domini: state.dominio.domini,
    dominioRequest: state.dominio.dominioRequest,
    templateAml: state.survey.templateAml,
    templateAmlPg: state.survey.templateAmlPg,
    amlPgRequest: state.survey.amlPgRequest,
    dataRegistry: state.customers.dataRegistry,
    getDataRegistryRequest: state.customers.getDataRegistryRequest,
    amlPreviousForm: state.survey.amlPreviousForm,
    amlPgPreviousForm: state.survey.amlPgPreviousForm,
    reduxForms: Object.keys(forms).reduce((res, form) => ({ ...res, [form]: isDirty(forms[form])(state) }), {}),
    // initialFormDataRegistry: {
    //   ...getFormInitialValues(forms.INFORMAZIONI_PERSONALI)(state),
    //   ...getFormInitialValues(forms.RESIDENZA)(state),
    //   ...getFormInitialValues(forms.CONTATTI)(state)
    // },
    isProspectPersonaGiuridica: ownProps.isProspectPersonaGiuridica
  }

  if (ownProps.isProspectPersonaGiuridica) {
    commonstateToProps = {
      ...commonstateToProps,
      summaryData: state.prospectPersonaGiuridica.summaryData,
      dataFatcaPG: state.anagraficaPersonaGiuridicaCliente.dataFatcaPG,
      getFatcaPGRequest: state.anagraficaPersonaGiuridicaCliente.getFatcaPGRequest,
      postFatcaPGRequest: state.anagraficaPersonaGiuridicaCliente.postFatcaPGReques,
      idPratica: state.pratica.idPratica,
      amlPgSaveRequest: state.survey.amlPgSaveRequest
      // formValuesFatca(forms.FATCA_GIURIDICHE)(state),
    }
  } else {
    commonstateToProps = {
      ...commonstateToProps,
      summaryData: state.customers.summaryData,
      idProcurator: state.customers.summaryData.idProcurator || state.procuratore.idProcurator,
      initialFormDataRegistry: {
        ...getFormInitialValues(forms.INFORMAZIONI_PERSONALI)(state),
        ...getFormInitialValues(forms.RESIDENZA)(state),
        ...getFormInitialValues(forms.CONTATTI)(state)
      }
    }
  }
  return commonstateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CensimentoAnagrafico)
