import { connect } from 'react-redux'
import { loadMifidFields, getMifidByCategory, MIFID_CONST } from '../../utils'
import { reduxForm, formValueSelector, isDirty, getFormInitialValues, getFormValues } from 'redux-form'
import allFields from '../../fields'
import EducazioneFinanziaria from './EducazioneFinanziariaComponent'

let MIFID_ACTIVE = null
let fields = null
let selector = null

const loadMifidData = (previousForm, template) => {
  if (!previousForm || !previousForm.listaDomande || !Array.isArray(previousForm.listaDomande)) {
    return {}
  }
  const checkboxArray = Object.keys(fields)
    .filter(k => fields[k].isCheckbox)
    .map(k => fields[k].templateId)
  return template.listaDomande.reduce((res, cur) => {
    // Si/No o Vero/Falso (hanno la domanda padre a cui non si risponde)
    let _strutturaInterna = {}
    if (cur.strutturaInternaDomande) {
      cur.strutturaInternaDomande.domande.forEach(item => {
        let _deep = previousForm.listaDomande.find(el => el.idDomanda === item.idDomanda)
        if (_deep && _deep.listaRisposte.length > 0) {
          _strutturaInterna[`field_${item.idDomanda}`] = '' + _deep.listaRisposte[0].idRisposta
        } else {
          _strutturaInterna[`field_${item.idDomanda}`] = ''
        }
      })
    }
    const prevDomanda = previousForm.listaDomande.find(el => el.idDomanda === cur.idDomanda)
    // Domande Vuote (check su struttura interna dato che la domanda padre del vero/falso, si/no, non ha risposta)
    if (!prevDomanda && Object.keys(_strutturaInterna).length === 0) {
      return { ...res, [`field_${cur.idDomanda}`]: '' }
    }

    // Checkbox type
    if (prevDomanda && checkboxArray.some(el => el === prevDomanda.idDomanda)) {
      return {
        ...res,
        ...prevDomanda.listaRisposte.reduce(
          (r, c) => ({ ...r, [`field_${prevDomanda.idDomanda}_${c.idRisposta}`]: true }),
          {}
        )
      }
    }

    // Ho superato la domanda vuota e restituisco le risposte dei figli della domanda padre
    return !prevDomanda
      ? {
          ...res,
          ..._strutturaInterna
        }
      : {
          ...res,
          [`field_${cur.idDomanda}`]: '' + prevDomanda.listaRisposte[0].idRisposta
        }
  }, {})
}

export default connect(state => {
  MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.EDUCAZIONE)
  fields = allFields[2][MIFID_ACTIVE]
  selector = formValueSelector(MIFID_CONST.EDUCAZIONE)

  const template = state.survey.template.sezioni.find(sezione => sezione.idSezione === MIFID_ACTIVE)
  return {
    initialValues: {
      ...loadMifidFields(fields, template),
      ...loadMifidData(state.survey.previousForm, template)
    },
    formValues: {
      ...loadMifidFields(fields, template),
      ...selector(state, ...Object.keys(loadMifidFields(fields, template)).reduce((res, cur) => [...res, cur], []))
    },
    currentFormValues: getFormValues(MIFID_CONST.EDUCAZIONE)(state),
    currentInitial: getFormInitialValues(MIFID_CONST.EDUCAZIONE)(state),
    isDirty: isDirty(MIFID_CONST.EDUCAZIONE)(state),
    isFetching: state.survey.mifidSaveRequest.fetching,
    apiError: state.survey.mifidSaveRequest.error
  }
})(
  reduxForm({
    form: MIFID_CONST.EDUCAZIONE
  })(EducazioneFinanziaria)
)
