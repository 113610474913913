import React from 'react'
import { PropTypes } from 'prop-types'
import { Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import allFields from '../../fields'
import AccordionHeader from '../../../../components/AccordionHeader'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import { renderSelect } from '../../../../components/Forms/renderFields'
import { Field } from 'redux-form'
import { mapDominiToOptions, MIFID_CONST, getMifidByCategory } from '../../utils'
import { setValidators, setRisposte, isSaveDisabled, getFormValues } from '../mifidValidationUtils'

let MIFID_ACTIVE = null
let fields = null

class Anagrafica extends React.Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, values: {} }
    this.submit = this.submit.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.ANAGRAFICA)
    fields = allFields[2][MIFID_ACTIVE]
    // Imposto i validators in modo da poter fare i controlli (sul padre) tra sezioni diverse
    setValidators(this.props.template.listaDomande)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.state.values)
      this.props.resetForm(MIFID_ACTIVE)
      this.setState({ saved: true, values: {} })
    }
    setRisposte(getFormValues(this.props.currentFormValues))
  }

  submit(values) {
    const { onSave } = this.props
    const submittedValues = Object.keys(values).reduce((res, cur) => {
      return { ...res, [cur.replace('field_', '')]: values[cur] }
    }, {})
    this.setState({ values })
    onSave(
      Object.keys(submittedValues).reduce((res, cur) => {
        if (cur.includes('_')) {
          const [idDomanda, idRisposta] = cur.split('_')
          const curRisp = res.find(r => r.idDomanda === idDomanda)
          let listaRisposte = []
          if (submittedValues[cur]) {
            listaRisposte = [{ idRisposta }]
          }
          if (curRisp) {
            listaRisposte = [...curRisp.listaRisposte, ...listaRisposte]
            return [...res.filter(r => r.idDomanda !== idDomanda), { idDomanda, listaRisposte }]
          }
          return [...res, { idDomanda, listaRisposte }]
        } else {
          return [
            ...res,
            {
              idDomanda: cur,
              listaRisposte: [{ idRisposta: submittedValues[cur], descRisposta: submittedValues[cur] }]
            }
          ]
        }
      }, [])
    )
  }

  getDominio(dominioList) {
    let res = {}
    dominioList.forEach(dom => (res[dom.idRisposta] = dom.descRisposta))
    return res
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      status,
      template: { descSezione, listaDomande },
      isFetching
    } = this.props

    return (
      <CollapsibleItem
        header={descSezione}
        itemKey={MIFID_ACTIVE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {/* NUOVA MIFID ANAGRAFICA PF */}
          <Row>
            <div
              className={this.props.fieldsWithErrors.includes(fields.STATO_CIVILE.templateId) ? 'fieldHasErrors' : ''}>
              <Field
                name={`field_${fields.STATO_CIVILE.templateId}`}
                size={6}
                label={listaDomande.find(c => c.idDomanda === fields.STATO_CIVILE.templateId).testoDomanda}
                options={mapDominiToOptions(
                  this.getDominio(listaDomande.find(c => c.idDomanda === fields.STATO_CIVILE.templateId).listaRisposte),
                  true
                )}
                component={renderSelect}
              />
            </div>
            <div
              className={
                this.props.fieldsWithErrors.includes(fields.NUCLEO_FAMILIARE.templateId) ? 'fieldHasErrors' : ''
              }>
              <Field
                name={`field_${fields.NUCLEO_FAMILIARE.templateId}`}
                size={6}
                label={listaDomande.find(c => c.idDomanda === fields.NUCLEO_FAMILIARE.templateId).testoDomanda}
                options={mapDominiToOptions(
                  this.getDominio(
                    listaDomande.find(c => c.idDomanda === fields.NUCLEO_FAMILIARE.templateId).listaRisposte
                  ),
                  true
                )}
                component={renderSelect}
              />
            </div>
          </Row>
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching || isSaveDisabled(listaDomande)} />
        </form>
      </CollapsibleItem>
    )
  }
}

Anagrafica.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  template: PropTypes.object,
  onSave: PropTypes.func,
  currentFormValues: PropTypes.object,
  fieldsWithErrors: PropTypes.array
}

export default Anagrafica
