import React from 'react'
import { CardPanel, Col, Row } from 'react-materialize'
import PropTypes from 'prop-types'
import './_largeMaterializeCard.scss'
import Donut from '../../Charts/Donut'
import BarChart from '../../Charts/Bar'

// import dataJSON from '../../Charts/Bar/data.json'

const LargeMaterializeCard = ({ className, title, chartType, s, data }) => {
  if (chartType === 'piechart') {
    return (
      <Col s={s}>
        <CardPanel className={className}>
          <Row className={className + ' ' + className + '__row'}>
            <Col s={12}>
              <div className={className + ' ' + className + '__title'}>{title}</div>
            </Col>
            <Donut data={data} />
          </Row>
        </CardPanel>
      </Col>
    )
  } else if (chartType === 'barchart') {
    return (
      <Col s={s}>
        <CardPanel className={className}>
          <Row className={className + ' ' + className + '__row'}>
            <Col s={12}>
              <div className={className + ' ' + className + '__title'}>{title}</div>
            </Col>
            <BarChart data={data.data} />
          </Row>
        </CardPanel>
      </Col>
    )
  }
}

export default LargeMaterializeCard

LargeMaterializeCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  chartType: PropTypes.string,
  s: PropTypes.number
}
