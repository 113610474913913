import React from 'react'
import { PropTypes } from 'prop-types'
import { Row } from 'react-materialize'
import { Field } from 'redux-form'
import { renderYesNo } from '../../../components/Forms/renderFields'
import Title from '../../../components/Title'

const YesNo = ({ listaDomande, field, formValues, change }) => {
  const {
    strutturaInternaDomande: { listaDominiRisposte, domande },
    testoDomanda
  } = listaDomande.find(d => d.idDomanda === field.templateId)
  return (
    <React.Fragment>
      <div>
        <Title>
          <span dangerouslySetInnerHTML={{ __html: testoDomanda }} />
        </Title>
      </div>
      {domande.map(domanda => (
        <Row className="yes-no-row" key={`YesNo-${domanda.idDomanda}`}>
          <Field
            name={`field_${domanda.idDomanda}`}
            domanda={domanda}
            disabled={domanda.listaDipendenze.some(dip =>
              Object.keys(formValues)
                .filter(k => k.startsWith(`field_${dip.idDomandaDipendenza}_conoscenza`))
                .reduce((res, cur) => {
                  if (formValues[cur] && '' + formValues[cur] !== '' + dip.idRispostaDipendenza) {
                    return false
                  }
                  return res
                }, true)
            )}
            handlerFn={value => change(`field_${domanda.idDomanda}`, value)}
            options={listaDominiRisposte[0].risposte}
            component={renderYesNo}
          />
        </Row>
      ))}
    </React.Fragment>
  )
}

YesNo.propTypes = {
  listaDomande: PropTypes.array,
  field: PropTypes.object,
  change: PropTypes.func,
  formValues: PropTypes.object
}

export default YesNo
