class Answer {
  constructor(args, value) {
    this.id = Number(args.idRisposta)
    this.value = value
  }

  getId() {
    return this.id
  }

  getValue() {
    return this.value
  }
}

class Question {
  constructor(args) {
    this.id = Number(args.idDomanda)
    this.inputType = args.inputType
    this.text = args.testoDomanda
    this.number = args.numDomanda

    this.subQuestion = []
    if (
      typeof args.strutturaInternaDomande === 'object' &&
      args.strutturaInternaDomande != null &&
      Array.isArray(args.strutturaInternaDomande.domande)
    ) {
      this.subQuestion = args.strutturaInternaDomande.domande.map(
        question => new Question({ ...question, inputType: this.inputType })
      )
    }
    this.answers = []
    this.validators = []
    if (Array.isArray(args.validators)) {
      let validatorsMap = {}
      args.validators.forEach(validator => {
        const key =
          validator.idRisposta.toString() + validator.idDomandaDipendenza.toString() + validator.tipoDipendenza
        if (validatorsMap.hasOwnProperty(key)) {
          return
        }
        validatorsMap[key] = true

        const answers = args.validators
          .filter(_validator => {
            return (
              _validator.idDomandaDipendenza === validator.idDomandaDipendenza &&
              _validator.tipoDipendenza === validator.tipoDipendenza
            )
          })
          .map(_validator => _validator.idRispostaDipendenza)

        this.validators.push({
          idDomandaDipendenza: validator.idDomandaDipendenza,
          idRispostaDipendenza: answers,
          idRispostaEsternaDipendenza: validator.idRispostaEsternaDipendenza,
          tipoDipendenza: validator.tipoDipendenza,
          idRisposta: validator.idRisposta
        })
      })
    }
  }

  setAnswers(fields, values) {
    const fieldsLinked = Object.keys(fields)
      .filter(key => fields[key].templateId === this.id)
      .map(key => fields[key])

    if (this.inputType === 'CHECKBOX') {
      this.answers = fieldsLinked.map(
        field =>
          new Answer(
            {
              idRisposta: field.id.split('_')[2]
            },
            values[field.id]
          )
      )
    } else {
      this.answers = fieldsLinked.map(
        field =>
          new Answer(
            {
              idRisposta: values[field.id]
            },
            true
          )
      )
    }

    this.getSubQuestions().forEach(question => question.setAnswers(fields, values))
    return this
  }

  getId() {
    return this.id
  }

  getText() {
    return this.text
  }

  getNumber() {
    return this.number
  }

  getSubQuestions() {
    return this.subQuestion
  }

  getAnswers() {
    return this.answers
  }

  getAnswersFilled() {
    const isFilled = {
      CHECKBOX: value => value === true
    }

    return this.getAnswers().filter(answer => {
      if (isFilled.hasOwnProperty(this.inputType)) {
        return isFilled[this.inputType](answer.getValue())
      }
      return true
    })
  }

  isInvalid(questions) {
    const findAnswer = (validator, question) =>
      question.getAnswersFilled().some(answer => validator.idRispostaDipendenza.includes(answer.getId()))
    const findQuestion = (validator, question) =>
      question.getId() === validator.idDomandaDipendenza && findAnswer(validator, question)
    const findQuestions = (validator, _questions) =>
      _questions.some(
        question => findQuestion(validator, question) || findQuestions(validator, question.getSubQuestions())
      )

    const validators = this.validators.filter(validator =>
      this.getAnswersFilled().some(answer => answer.getId() === validator.idRisposta)
    )
    const isNeedCheckEveryValidators = validators.some(validator => validator.tipoDipendenza === 'validate_ko_and')

    if (isNeedCheckEveryValidators) {
      return validators.every(validator => findQuestions(validator, questions))
    }
    return validators.some(validator => findQuestions(validator, questions))
  }

  getInvalidQuestions(questions) {
    const findAnswer = (validator, question) =>
      question.getAnswersFilled().some(answer => validator.idRispostaDipendenza.includes(answer.getId()))
    const findQuestion = (validator, question) =>
      question.getId() === validator.idDomandaDipendenza && findAnswer(validator, question)
    const findQuestions = (validator, _questions) =>
      _questions.some(
        question => findQuestion(validator, question) || findQuestions(validator, question.getSubQuestions())
      )

    const validators = this.validators.filter(validator =>
      this.getAnswersFilled().some(answer => answer.getId() === validator.idRisposta)
    )

    return questions.filter(question =>
      validators.some(
        validator => findQuestion(validator, question) || findQuestions(validator, question.getSubQuestions())
      )
    )
  }
}

export function checkValidationErrors(listaDomande, values, fields) {
  let questions = listaDomande.map(question => new Question(question).setAnswers(fields, values))

  let results = new Map()
  questions.forEach(question => {
    if (question.isInvalid(questions)) {
      results.set(question.getId(), question)
      question
        .getInvalidQuestions(questions)
        .forEach(invalidQuestion => results.set(invalidQuestion.getId(), invalidQuestion))
    }
  })

  return Array.from(results.values())
}

export function getErrorMessage(questions) {
  let texts = questions.map(error => {
    let number = error.getNumber()
    if (number != null && number !== '') {
      return `"${number}"`
    }
    return `"${error.getText()}"`
  })
  let firstQuestion = texts.pop()
  return `La risposta fornita alla domanda ${firstQuestion} risulta incoerente con quella fornita alla domanda ${texts.join(
    ','
  )}`
}
