import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Icon } from 'react-materialize'
import AccordionHeader from '../../../../components/AccordionHeader'
import { CollapsibleItem } from '../../../../components/Collapsible'
// import dataSB from '../../../../static-data/data-servizio-base.json'
import { SCHEDA_DATI_TITOLARE } from '../../../../store/modules/forms/constants'
import SchedaDatiTitolareItem from './SchedaDatiTitolareItem'

// import {
//   getDominio,
//   getAutocompleteDefaultOption,
//   mapDominiToAutocomplete,
//   mapDominiToOptions,
//   getValueDominioFromKey

// } from '../../utils'

import ButtonSubmit from '../../../../components/Buttons/Submit'

// const documenti = dataSB.acquisizione.documento_identita

class SchedaDatiTitolare extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      test: true,
      counter: 0,
      firstTime: false,
      addressCorrispNotMatch: false
    }
    this.submit = this.submit.bind(this)
    this.renderSchedaDatiTitolare = this.renderSchedaDatiTitolare.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
  }

  componentDidMount() {
    // let index = this.props.position
    const {
      NAZIONE_NASCITA_SCHEDA_DATI_0,
      PROVINCIA_NASCITA_SCHEDA_DATI_0,
      COMUNE_NASCITA_SCHEDA_DATI_0,
      CITTADINANZA_0,
      NAZIONE_SCHEDA_DATI_0,
      PROVINCIA_SCHEDA_DATI_0,
      TIPO_DOCUMENTO_0,
      COMUNE_RILASCIO_0,

      NAZIONE_NASCITA_SCHEDA_DATI_1,
      PROVINCIA_NASCITA_SCHEDA_DATI_1,
      CITTADINANZA_1,
      NAZIONE_SCHEDA_DATI_1,
      PROVINCIA_SCHEDA_DATI_1,
      TIPO_DOCUMENTO_1,
      COMUNE_RILASCIO_1,

      NAZIONE_NASCITA_SCHEDA_DATI_2,
      PROVINCIA_NASCITA_SCHEDA_DATI_2,
      CITTADINANZA_2,
      NAZIONE_SCHEDA_DATI_2,
      PROVINCIA_SCHEDA_DATI_2,
      TIPO_DOCUMENTO_2,
      COMUNE_RILASCIO_2,

      NAZIONE_NASCITA_SCHEDA_DATI_3,
      PROVINCIA_NASCITA_SCHEDA_DATI_3,
      CITTADINANZA_3,
      NAZIONE_SCHEDA_DATI_3,
      PROVINCIA_SCHEDA_DATI_3,
      TIPO_DOCUMENTO_3,
      COMUNE_RILASCIO_3,

      NAZIONE_NASCITA_SCHEDA_DATI_4,
      PROVINCIA_NASCITA_SCHEDA_DATI_4,
      CITTADINANZA_4,
      NAZIONE_SCHEDA_DATI_4,
      PROVINCIA_SCHEDA_DATI_4,
      TIPO_DOCUMENTO_4,
      COMUNE_RILASCIO_4,

      NAZIONE_POSTAL_0,
      PROVINCIA_POSTAL_0,

      NAZIONE_POSTAL_1,
      PROVINCIA_POSTAL_1,

      NAZIONE_POSTAL_2,
      PROVINCIA_POSTAL_2,

      NAZIONE_POSTAL_3,
      PROVINCIA_POSTAL_3,

      NAZIONE_POSTAL_4,
      PROVINCIA_POSTAL_4
    } = this.props.fields
    const payload = {
      listaDomini: [
        { idDominio: NAZIONE_POSTAL_0.dominio },
        { idDominio: PROVINCIA_POSTAL_0.dominio },

        { idDominio: NAZIONE_POSTAL_1.dominio },
        { idDominio: PROVINCIA_POSTAL_1.dominio },

        { idDominio: NAZIONE_POSTAL_2.dominio },
        { idDominio: PROVINCIA_POSTAL_2.dominio },

        { idDominio: NAZIONE_POSTAL_3.dominio },
        { idDominio: PROVINCIA_POSTAL_3.dominio },

        { idDominio: NAZIONE_POSTAL_4.dominio },
        { idDominio: PROVINCIA_POSTAL_4.dominio },

        {
          idDominio: NAZIONE_NASCITA_SCHEDA_DATI_0.dominio
        },
        {
          idDominio: COMUNE_NASCITA_SCHEDA_DATI_0.dominio
        },
        {
          idDominio: PROVINCIA_NASCITA_SCHEDA_DATI_0.dominio
        },
        {
          idDominio: CITTADINANZA_0.dominio
        },
        {
          idDominio: NAZIONE_SCHEDA_DATI_0.dominio
        },
        {
          idDominio: PROVINCIA_SCHEDA_DATI_0.dominio
        },
        {
          idDominio: TIPO_DOCUMENTO_0.dominio
        },
        {
          idDominio: COMUNE_RILASCIO_0.dominio
        },
        {
          idDominio: NAZIONE_NASCITA_SCHEDA_DATI_1.dominio
        },
        {
          idDominio: PROVINCIA_NASCITA_SCHEDA_DATI_1.dominio
        },
        {
          idDominio: CITTADINANZA_1.dominio
        },
        {
          idDominio: NAZIONE_SCHEDA_DATI_1.dominio
        },
        {
          idDominio: PROVINCIA_SCHEDA_DATI_1.dominio
        },
        {
          idDominio: TIPO_DOCUMENTO_1.dominio
        },
        {
          idDominio: COMUNE_RILASCIO_1.dominio
        },
        {
          idDominio: NAZIONE_NASCITA_SCHEDA_DATI_2.dominio
        },
        {
          idDominio: PROVINCIA_NASCITA_SCHEDA_DATI_2.dominio
        },
        {
          idDominio: CITTADINANZA_2.dominio
        },
        {
          idDominio: NAZIONE_SCHEDA_DATI_2.dominio
        },
        {
          idDominio: PROVINCIA_SCHEDA_DATI_2.dominio
        },
        {
          idDominio: TIPO_DOCUMENTO_2.dominio
        },
        {
          idDominio: COMUNE_RILASCIO_2.dominio
        },
        {
          idDominio: NAZIONE_NASCITA_SCHEDA_DATI_3.dominio
        },
        {
          idDominio: PROVINCIA_NASCITA_SCHEDA_DATI_3.dominio
        },
        {
          idDominio: CITTADINANZA_3.dominio
        },
        {
          idDominio: NAZIONE_SCHEDA_DATI_3.dominio
        },
        {
          idDominio: PROVINCIA_SCHEDA_DATI_3.dominio
        },
        {
          idDominio: TIPO_DOCUMENTO_3.dominio
        },
        {
          idDominio: COMUNE_RILASCIO_3.dominio
        },
        {
          idDominio: NAZIONE_NASCITA_SCHEDA_DATI_4.dominio
        },
        {
          idDominio: PROVINCIA_NASCITA_SCHEDA_DATI_4.dominio
        },
        {
          idDominio: CITTADINANZA_4.dominio
        },
        {
          idDominio: NAZIONE_SCHEDA_DATI_4.dominio
        },
        {
          idDominio: PROVINCIA_SCHEDA_DATI_4.dominio
        },
        {
          idDominio: TIPO_DOCUMENTO_4.dominio
        },
        {
          idDominio: COMUNE_RILASCIO_4.dominio
        }
      ]
    }

    this.setState({ counter: (this.props.additionalOwnerList || []).length })
    this.props.retrieveDominio(payload)
  }
  counterTitolare() {
    let counter = this.state.counter
    counter++
    if (this.state.counter < 4) {
      this.setState({ counter: counter })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.additionalOwnerList && !this.state.firstTime) {
      this.setState({
        counter:
          this.props.additionalOwnerList.length === 0
            ? this.props.additionalOwnerList.length
            : this.props.additionalOwnerList.length - 1
      })
      this.setState({ firstTime: true })
    }

    if (
      this.props.getAdditionalOwnerRequest !== undefined &&
      this.props.getAdditionalOwnerRequest.fetching !== undefined &&
      prevProps.getAdditionalOwnerRequest.fetching &&
      !this.props.getAdditionalOwnerRequest.fetching &&
      !this.props.getAdditionalOwnerRequest.error
    ) {
      this.setState({
        counter:
          this.props.additionalOwnerList.length === 0
            ? this.props.additionalOwnerList.length
            : this.props.additionalOwnerList.length - 1
      })
    }
    // this.props.initialize(this.props.formValues)
  }

  updateDominio(idDominio, codice) {
    return this.props.retrieveDominio({
      listaDomini: [{ idDominio, filtro: { codice } }]
    })
  }
  getObjAdapterp(obj, index) {
    let objTosend = {
      idAddictionalOwner: index,
      name: (obj || '')[`name_${index}`],
      surname: (obj || '')[`surname_${index}`],
      taxCode: (obj || '')[`taxCode_${index}`],
      birthDate: (obj || '')[`birthDate_${index}`],
      cityBirthCode: (obj || '')[`cityBirthCode_${index}`],
      cityResidenceCode: (obj || '')[`cityResidenceCode_${index}`],
      provinceResidenceCode: (obj || '')[`provinceResidenceCode_${index}`],
      provinceBirthCode: (obj || '')[`provinceBirthCode_${index}`],
      nationResidenceCode: (obj || '')[`nationResidenceCode_${index}`],
      // cityBirthCode: (obj || '')[`placeAbroadCode_${index}`],
      nationBirthCode: (obj || '')[`nationBirthCode_${index}`],
      residence: (obj || '')[`residence_${index}`],
      cap: (obj || '')[`cap_${index}`],
      citizenship: (obj || '')[`citizenship_${index}`],
      mail: (obj || '')[`mail_${index}`],
      phone: (obj || '')[`phone_${index}`],
      documentType: (obj || '')[`documentType_${index}`],
      documentNumber: (obj || '')[`documentNumber_${index}`],
      placeRelease: (obj || '')[`placeRelease_${index}`],
      releaseDate: (obj || '')[`releaseDate_${index}`],
      expireDate: (obj || '')[`expireDate_${index}`],
      pepType: (obj || '')[`pepType_${index}`],
      relationshipType: (obj || '')[`relationshipType_${index}`],
      relationshipOtherDesc: (obj || '')[`relationshipOtherDesc_${index}`],
      pepInfo: {
        isIntestatario: (obj || '')[`isIntestatario_${index}`],
        // confermaDiEssereDescrizione: (obj || '')[`confermaDiEssereDescrizione_${index}`],
        tipologiaCliente: (obj || '')[`tipologiaCliente_${index}`],
        pepInQuanto: (obj || '')[`pepInQuanto_${index}`],
        // pepRelazione: (obj || '')[`pepRelazione_${index}`],
        // tipoFasciaRedditoPep: (obj || '')[`tipoFasciaRedditoPep_${index}`],
        // pepPartecipazioni: (obj || '')[`pepPartecipazioni_${index}`],
        //  pepProcedimenti: (obj || '')[`pepProcedimenti_${index}`],
        isTitolareEffettivoPEP: (obj || '')[`isTitolareEffettivoPEP_${index}`]
      },
      namePostalInfo: (obj || '')[`namePostalInfo_${index}`],
      surnamePostalInfo: (obj || '')[`surnamePostalInfo_${index}`],
      nationCodePostalInfo: (obj || '')[`nationCodePostalInfo_${index}`],
      provinceCodePostalInfo: (obj || '')[`provinceCodePostalInfo_${index}`],
      cityCodePostalInfo: (obj || '')[`cityCodePostalInfo_${index}`],
      placePostalInfo: (obj || '')[`placePostalInfo_${index}`],
      capPostalInfo: (obj || '')[`capPostalInfo_${index}`],
      residencePostalInfo: (obj || '')[`residencePostalInfo_${index}`],
      flagPostalInfo: (obj || '')[`flagPostalInfo_${index}`]
    }
    return objTosend
  }
  submit(values, runTimeValue = null) {
    const { initialize, formValues } = this.props
    let Arra = []
    for (var i = 0; i < this.state.counter + 1; i++) {
      let tempValue
      if (Object.keys(runTimeValue).length > 0) {
        tempValue = runTimeValue
      } else {
        tempValue = formValues
      }

      Arra.push(this.getObjAdapterp(tempValue || values, i))
    }

    initialize(values || formValues)
    this.setState({ saved: true })
    return this.props.postAddictionalOwner(this.props.idPhysicalPersonReference, Arra).then(function() {
      // _this.props.resetForm(SCHEDA_DATI_TITOLARE)
    })
  }

  // isCompiled(v) {
  //   const { formValues = {} } = this.props
  //   return typeof formValues[v.id] !== 'undefined' && formValues[v.id] !== null && formValues[v.id] !== ''
  // }
  renderSchedaDatiTitolare() {
    let htmlToRender = []

    if (this.props.additionalOwnerList && !this.state.firstTime) {
      this.setState({
        counter:
          this.props.additionalOwnerList.length === 0
            ? this.props.additionalOwnerList.length
            : this.props.additionalOwnerList.length - 1
      })
      this.setState({ firstTime: true })
    }

    for (let i = 0; i <= this.state.counter; i++) {
      htmlToRender.push(
        <SchedaDatiTitolareItem
          showComp={!this.state.unicoTitolare}
          counter={i}
          {...this.props}
          updateDominio={this.updateDominio}
        />
      )
    }
    return htmlToRender
  }

  render() {
    const { handleSubmit, submitting, isDirty, anyTouched, invalid, status, formErrors, isFetching } = this.props

    return (
      <CollapsibleItem
        disabled={
          !(
            (this.props.titolareMulti === false && this.props.titolare === null) ||
            (this.props.titolareMulti === true && this.props.titolare === false)
          )
        }
        header={'Scheda di raccolta dati per la verifica della clientela Titolare Effettivo'}
        itemKey={SCHEDA_DATI_TITOLARE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {/* {this.renderSchedaDatiTitolare()} */}
          {this.state.counter >= 0 && (
            <SchedaDatiTitolareItem
              showComp={!this.state.unicoTitolare}
              counter={0}
              {...this.props}
              updateDominio={this.updateDominio}
              submit={this.submit}
              idAddictionalOwner={(this.props.additionalOwnerList[0] || []).idAddictionalOwner || null}
            />
          )}
          {this.state.counter >= 1 && !this.props.titolare && (
            <SchedaDatiTitolareItem
              showComp={!this.state.unicoTitolare}
              counter={1}
              {...this.props}
              updateDominio={this.updateDominio}
              submit={this.submit}
              idAddictionalOwner={(this.props.additionalOwnerList[1] || []).idAddictionalOwner || null}
            />
          )}
          {this.state.counter >= 2 && !this.props.titolare && (
            <SchedaDatiTitolareItem
              showComp={!this.state.unicoTitolare}
              counter={2}
              {...this.props}
              updateDominio={this.updateDominio}
              submit={this.submit}
              idAddictionalOwner={(this.props.additionalOwnerList[2] || []).idAddictionalOwner || null}
            />
          )}
          {this.state.counter >= 3 && !this.props.titolare && (
            <SchedaDatiTitolareItem
              showComp={!this.state.unicoTitolare}
              counter={3}
              {...this.props}
              updateDominio={this.updateDominio}
              submit={this.submit}
              idAddictionalOwner={(this.props.additionalOwnerList[3] || []).idAddictionalOwner || null}
            />
          )}
          {this.state.counter >= 4 && !this.props.titolare && (
            <SchedaDatiTitolareItem
              showComp={!this.state.unicoTitolare}
              counter={4}
              {...this.props}
              updateDominio={this.updateDominio}
              submit={this.submit}
              idAddictionalOwner={(this.props.additionalOwnerList[4] || []).idAddictionalOwner || null}
            />
          )}

          <Row className="residenza-buttons">
            <Col>
              <a
                className={`add-titolare ${this.state.counter >= 4 || this.props.titolare ? ' disabled' : ''}`}
                onClick={() => {
                  if (this.state.counter < 4) {
                    this.setState({ titolareeffettivo: true })
                    this.counterTitolare()
                  }
                }}>
                <Icon small>add_circle</Icon>
                <div>Aggiungi titolare effettivo</div>
              </a>
            </Col>
          </Row>

          <Row>
            <Col s={12}>
              <ButtonSubmit
                disabled={
                  submitting ||
                  (!isDirty && !anyTouched) ||
                  invalid ||
                  isFetching ||
                  Object.keys(formErrors).some(e => formErrors[e])
                  // !formWasCompiled
                }
              />
            </Col>
          </Row>
        </form>
      </CollapsibleItem>
    )
  }
}

SchedaDatiTitolare.propTypes = {
  // submitHandler: PropTypes.func,
  titolare: PropTypes.bool,
  titolareMulti: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  // apiError: PropTypes.object,
  initialize: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,
  resetForm: PropTypes.func,
  // currentInitial: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  domini: PropTypes.object,
  // dominioCustomRequest: PropTypes.func,
  // submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  anyTouched: PropTypes.bool,
  // dataNascitaCF: PropTypes.any,
  fields: PropTypes.object,
  dominioCustomRequest: PropTypes.func,
  // flagCustomerIsBlacklist: PropTypes.any
  retrieveDominio: PropTypes.func,
  postAddictionalOwner: PropTypes.func,
  idPhysicalPersonReference: PropTypes.any,
  additionalOwnerList: PropTypes.any,
  getAdditionalOwnerRequest: PropTypes.object
}

SchedaDatiTitolare.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default SchedaDatiTitolare
