import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { MIFID_CONOSCENZA, MIFID_CONOSCENZA_PF, MIFID_CONOSCENZA_PG } from '../../../../store/modules/forms/constants'
import { Field } from 'redux-form'
import allFields from '../../fields'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import AccordionHeader from '../../../../components/AccordionHeader'
import { renderYesNoQuestion } from '../../../../components/Forms/renderFields'
import Strumenti from './Strumenti'
import VeroFalso from './VeroFalso'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import SurveyRadio from '../../../../components/SurveyRadio'
import YesNo from '../YesNo'
import SurveyHr from '../../../../components/SurveyHr'
import { setValidators, setRisposte, isSaveDisabled, getFormValues } from '../mifidValidationUtils'

let MIFID_ACTIVE = null
let fields = null

class ConoscenzaEsperienza extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      totalCheck: 0,
      error: false
    }
    this.onCheckboxChange = this.onCheckboxChange.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.CONOSCENZA)
    fields = allFields[2][MIFID_ACTIVE]
    // Imposto i validators in modo da poter fare i controlli (sul padre) tra sezioni diverse
    setValidators(this.props.template.listaDomande)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(MIFID_ACTIVE)
      this.setState({ saved: true, error: false })
    }
    const { formValues } = this.props
    const totalCheck = Object.keys(formValues)
      .filter(el => el.endsWith('_check'))
      .reduce((res, cur) => (formValues[cur] ? res + 1 : res), 0)

    if (totalCheck > this.state.totalCheck) {
      this.setState({ totalCheck })
    } else if (totalCheck < this.state.totalCheck) {
      this.setState({ totalCheck, error: false })
    }
    setRisposte(getFormValues(this.props.currentFormValues))
  }

  onCheckboxChange(field, value) {
    const { change } = this.props
    if (this.state.totalCheck < 2 || !value) {
      change(field, value)
      this.setState({ error: false })
    } else {
      this.setState({ error: true })
    }
  }

  submit(values) {
    const {
      currentInitial,
      template: { listaDomande },
      onSave
    } = this.props
    const submittedValues = Object.keys(values)
      .filter(key => values[key] !== currentInitial[key])
      .reduce((res, cur) => ({ ...res, [cur.replace('field_', '')]: values[cur] }), {})
    const primoLivello = Object.keys(submittedValues).reduce((res, cur) => {
      return [
        ...res,
        { idDomanda: cur, listaRisposte: [{ idRisposta: submittedValues[cur], descRisposta: submittedValues[cur] }] }
      ]
    }, [])
    const strumenti = Object.keys(fields)
      .filter(k => fields[k].desinenze)
      .reduce(
        (res, cur) => [
          ...res,
          ...listaDomande
            .find(d => d.idDomanda === fields[cur].templateId)
            .strutturaInternaDomande.domande.reduce((r, d) => {
              const array = primoLivello.filter(f => f.idDomanda.startsWith(d.idDomanda))
              if (array.length > 0) {
                return [
                  ...r,
                  {
                    idDomanda: d.idDomanda,
                    listaRisposte: fields[cur].desinenze.reduce((s, t) => {
                      if (values[`field_${d.idDomanda}${t}`]) {
                        return [...s, { idRisposta: values[`field_${d.idDomanda}${t}`] }]
                      }
                      return s
                    }, [])
                  }
                ]
              }
              return r
            }, [])
        ],
        []
      )
    onSave([
      ...primoLivello.filter(
        d => !d.idDomanda.includes('_') && !strumenti.find(e => e.idDomanda === parseInt(d.idDomanda))
      ),
      ...strumenti
    ])
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      status,
      template: { descSezione, listaDomande },
      formValues,
      change,
      isFetching
    } = this.props

    return (
      <CollapsibleItem
        header={descSezione}
        itemKey={MIFID_ACTIVE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {/* VECCHIA MIFID CONOSCENZA */}
          {MIFID_ACTIVE === MIFID_CONOSCENZA && (
            <React.Fragment>
              {/* <Row> */}
              {/*   <Field */}
              {/*     name={`field_${fields.TITOLO_STUDIO.templateId}`} */}
              {/*     size={6} */}
              {/*     label={listaDomande.find(c => c.idDomanda === fields.TITOLO_STUDIO.templateId).testoDomanda} */}
              {/*     options={mapDominiToOptions(getDominio(domini, fields.TITOLO_STUDIO.dominio), true)} */}
              {/*     component={renderSelect} */}
              {/*   /> */}
              {/*   <Field */}
              {/*     name={`field_${fields.SETTORE_OCCUPAZIONE.templateId}`} */}
              {/*     size={6} */}
              {/*     label={listaDomande.find(c => c.idDomanda === fields.SETTORE_OCCUPAZIONE.templateId).testoDomanda} */}
              {/*     options={mapDominiToOptions(getDominio(domini, fields.SETTORE_OCCUPAZIONE.dominio), true)} */}
              {/*     component={renderSelect} */}
              {/*   /> */}
              {/* </Row> */}
              {/* <Row> */}
              {/*   <Field */}
              {/*     name={`field_${fields.SETTORE_PROFESSIONE.templateId}`} */}
              {/*     size={6} */}
              {/*     label={listaDomande.find(c => c.idDomanda === fields.SETTORE_PROFESSIONE.templateId).testoDomanda} */}
              {/*     options={mapDominiToOptions(getDominio(domini, fields.SETTORE_PROFESSIONE.dominio), true)} */}
              {/*     component={renderSelect} */}
              {/*   /> */}
              {/* </Row> */}
              <Row>
                <Col s={12}>
                  <Field
                    name={`field_${fields.ALTRI_CONTRATTI_CONSULENZA.templateId}`}
                    label={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_CONSULENZA.templateId).testoDomanda
                    }
                    handlerFn={value => change(`field_${fields.ALTRI_CONTRATTI_CONSULENZA.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_CONSULENZA.templateId).listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col s={12}>
                  <Field
                    name={`field_${fields.ALTRI_CONTRATTI_COLLOCAMENTO.templateId}`}
                    label={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_COLLOCAMENTO.templateId)
                        .testoDomanda
                    }
                    handlerFn={value => change(`field_${fields.ALTRI_CONTRATTI_COLLOCAMENTO.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_COLLOCAMENTO.templateId)
                        .listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={4}>
                  <h2 className="collapsible-section-title">Strumenti</h2>
                </Col>
                <Col s={8}>
                  {this.state.error && (
                    <div className="error-message-percentuale">
                      È possibile selezionare un massimo di due strumenti per un importo superiore al 50%
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Row>
                  <Strumenti
                    field={fields.STRUMENTI}
                    fieldDipendenze={fields.VERO_FALSO}
                    listaDomande={listaDomande}
                    formValues={formValues}
                    change={change}
                    onCheckboxChange={this.onCheckboxChange}
                  />
                </Row>
              </Row>
              <h2 className="collapsible-section-title">Vero o falso?</h2>
              <Row>
                <VeroFalso
                  field={fields.VERO_FALSO}
                  listaDomande={listaDomande}
                  formValues={formValues}
                  change={change}
                />
              </Row>
            </React.Fragment>
          )}
          {/* NUOVA MIFID PF */}
          {MIFID_ACTIVE === MIFID_CONOSCENZA_PF && (
            <React.Fragment>
              {/* <Row> */}
              {/*   <div */}
              {/*     className={ */}
              {/*       this.props.fieldsWithErrors.includes(fields.TITOLO_STUDIO.templateId) ? 'fieldHasErrors' : '' */}
              {/*     }> */}
              {/*     <Field */}
              {/*       name={`field_${fields.TITOLO_STUDIO.templateId}`} */}
              {/*       size={6} */}
              {/*       label={listaDomande.find(c => c.idDomanda === fields.TITOLO_STUDIO.templateId).testoDomanda} */}
              {/*       options={mapDominiToOptions(getDominio(domini, fields.TITOLO_STUDIO.dominio), true)} */}
              {/*       component={renderSelect} */}
              {/*     /> */}
              {/*   </div> */}
              {/*   <div */}
              {/*     className={ */}
              {/*       this.props.fieldsWithErrors.includes(fields.SETTORE_OCCUPAZIONE.templateId) ? 'fieldHasErrors' : '' */}
              {/*     }> */}
              {/*     <Field */}
              {/*       name={`field_${fields.SETTORE_OCCUPAZIONE.templateId}`} */}
              {/*       size={6} */}
              {/*       label={listaDomande.find(c => c.idDomanda === fields.SETTORE_OCCUPAZIONE.templateId).testoDomanda} */}
              {/*       options={mapDominiToOptions(getDominio(domini, fields.SETTORE_OCCUPAZIONE.dominio), true)} */}
              {/*       component={renderSelect} */}
              {/*     /> */}
              {/*   </div> */}
              {/* </Row> */}
              {/* <Row> */}
              {/*   <div */}
              {/*     className={ */}
              {/*       this.props.fieldsWithErrors.includes(fields.SETTORE_PROFESSIONE.templateId) ? 'fieldHasErrors' : '' */}
              {/*     }> */}
              {/*     <Field */}
              {/*       name={`field_${fields.SETTORE_PROFESSIONE.templateId}`} */}
              {/*       size={6} */}
              {/*       label={listaDomande.find(c => c.idDomanda === fields.SETTORE_PROFESSIONE.templateId).testoDomanda} */}
              {/*       options={mapDominiToOptions(getDominio(domini, fields.SETTORE_PROFESSIONE.dominio), true)} */}
              {/*       component={renderSelect} */}
              {/*     /> */}
              {/*   </div> */}
              {/* </Row> */}
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.ALTRI_CONTRATTI_CONSULENZA.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <Field
                    name={`field_${fields.ALTRI_CONTRATTI_CONSULENZA.templateId}`}
                    label={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_CONSULENZA.templateId).testoDomanda
                    }
                    handlerFn={value => change(`field_${fields.ALTRI_CONTRATTI_CONSULENZA.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_CONSULENZA.templateId).listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <Field
                    name={`field_${fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId}`}
                    label={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId)
                        .testoDomanda
                    }
                    handlerFn={value => change(`field_${fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId)
                        .listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.RISCHIO_RENDIMENTO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.RISCHIO_RENDIMENTO.templateId)}
                    formValues={formValues}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.DIVERSIFICAZIONE_RISCHIO.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.DIVERSIFICAZIONE_RISCHIO.templateId)}
                    formValues={formValues}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.ORIZZONTE_TEMPORALE.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.ORIZZONTE_TEMPORALE.templateId)}
                    formValues={formValues}
                  />
                </Col>
              </Row>
              <SurveyHr hideHr />
              <h2 className="collapsible-section-title">Vero o falso?</h2>
              <Row
                className={this.props.fieldsWithErrors.includes(fields.VERO_FALSO.templateId) ? 'fieldHasErrors' : ''}>
                <VeroFalso
                  field={fields.VERO_FALSO}
                  listaDomande={listaDomande}
                  formValues={formValues}
                  change={change}
                />
              </Row>
              <SurveyHr hideHr />
              <h2 className="collapsible-section-title">Si o no?</h2>
              <Row className={this.props.fieldsWithErrors.includes(fields.SI_NO.templateId) ? 'fieldHasErrors' : ''}>
                <YesNo field={fields.SI_NO} listaDomande={listaDomande} formValues={formValues} change={change} />
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.INVESTIMENTO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.INVESTIMENTO.templateId)}
                    formValues={formValues}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
          {/* NUOVA MIFID PG */}
          {MIFID_ACTIVE === MIFID_CONOSCENZA_PG && (
            <React.Fragment>
              {/* <Row> */}
              {/*   <div */}
              {/*     className={ */}
              {/*       this.props.fieldsWithErrors.includes(fields.TITOLO_STUDIO.templateId) ? 'fieldHasErrors' : '' */}
              {/*     }> */}
              {/*     <Field */}
              {/*       name={`field_${fields.TITOLO_STUDIO.templateId}`} */}
              {/*       size={6} */}
              {/*       label={listaDomande.find(c => c.idDomanda === fields.TITOLO_STUDIO.templateId).testoDomanda} */}
              {/*       options={mapDominiToOptions(getDominio(domini, fields.TITOLO_STUDIO.dominio), true)} */}
              {/*       component={renderSelect} */}
              {/*     /> */}
              {/*   </div> */}
              {/*   <div */}
              {/*     className={ */}
              {/*       this.props.fieldsWithErrors.includes(fields.SETTORE_OCCUPAZIONE.templateId) ? 'fieldHasErrors' : '' */}
              {/*     }> */}
              {/*     <Field */}
              {/*       name={`field_${fields.SETTORE_OCCUPAZIONE.templateId}`} */}
              {/*       size={6} */}
              {/*       label={listaDomande.find(c => c.idDomanda === fields.SETTORE_OCCUPAZIONE.templateId).testoDomanda} */}
              {/*       options={mapDominiToOptions(getDominio(domini, fields.SETTORE_OCCUPAZIONE.dominio), true)} */}
              {/*       component={renderSelect} */}
              {/*     /> */}
              {/*   </div> */}
              {/* </Row> */}
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.ALTRI_CONTRATTI_CONSULENZA.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <Field
                    name={`field_${fields.ALTRI_CONTRATTI_CONSULENZA.templateId}`}
                    label={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_CONSULENZA.templateId).testoDomanda
                    }
                    handlerFn={value => change(`field_${fields.ALTRI_CONTRATTI_CONSULENZA.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_CONSULENZA.templateId).listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <Field
                    name={`field_${fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId}`}
                    label={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId)
                        .testoDomanda
                    }
                    handlerFn={value => change(`field_${fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE.templateId)
                        .listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.RISCHIO_RENDIMENTO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.RISCHIO_RENDIMENTO.templateId)}
                    formValues={formValues}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.DIVERSIFICAZIONE_RISCHIO.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.DIVERSIFICAZIONE_RISCHIO.templateId)}
                    formValues={formValues}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.ORIZZONTE_TEMPORALE.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.ORIZZONTE_TEMPORALE.templateId)}
                    formValues={formValues}
                  />
                </Col>
              </Row>
              <SurveyHr hideHr />
              <h2 className="collapsible-section-title">Vero o falso?</h2>
              <Row
                className={this.props.fieldsWithErrors.includes(fields.VERO_FALSO.templateId) ? 'fieldHasErrors' : ''}>
                <VeroFalso
                  field={fields.VERO_FALSO}
                  listaDomande={listaDomande}
                  formValues={formValues}
                  change={change}
                />
              </Row>
              <SurveyHr hideHr />
              <h2 className="collapsible-section-title">Si o no?</h2>
              <Row className={this.props.fieldsWithErrors.includes(fields.SI_NO.templateId) ? 'fieldHasErrors' : ''}>
                <YesNo field={fields.SI_NO} listaDomande={listaDomande} formValues={formValues} change={change} />
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.INVESTIMENTO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.INVESTIMENTO.templateId)}
                    formValues={formValues}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching || isSaveDisabled(listaDomande)} />
        </form>
      </CollapsibleItem>
    )
  }
}

ConoscenzaEsperienza.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  change: PropTypes.func,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  domini: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  template: PropTypes.object,
  onSave: PropTypes.func,
  currentInitial: PropTypes.object,
  currentFormValues: PropTypes.object,
  fieldsWithErrors: PropTypes.array
}
export default ConoscenzaEsperienza
