import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-materialize'

const Fab = ({ modalHandler, icon = 'add' }) => (
  <Button waves="light" onClick={() => modalHandler()} className="openModal">
    Richiesta Deroga Commissionale
  </Button>
)

Fab.propTypes = {
  icon: PropTypes.string,
  modalHandler: PropTypes.func.isRequired
}

export default Fab
