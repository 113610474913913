import React from 'react'
import PropTypes from 'prop-types'

const InputPlusMinusValuta = ({
  label,
  value = 0,
  parsedValue = parseFloat(value) ? `${value}€` : '',
  stepValue,
  minValue,
  maxValue,
  onChange,
  disabled
}) => (
  <div key={label}>
    <p s={12} className="label-plus-minus">
      {label}
    </p>
    <div s={12} className="box-input-plus-minus">
      <button
        className="button-step button-minus"
        type="button"
        disabled={value <= minValue || disabled}
        onClick={() => onChange(parseFloat(value) - stepValue)}>
        -
      </button>
      <input type="text" value={parsedValue} />
      <button
        className="button-step button-plus"
        type="button"
        disabled={value >= maxValue || disabled}
        onClick={() => onChange(parseFloat(value) + stepValue)}>
        +
      </button>
    </div>
  </div>
)
export default InputPlusMinusValuta

InputPlusMinusValuta.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  parsedValue: PropTypes.any,
  stepValue: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
