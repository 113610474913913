import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'
import { Route, Switch, Link } from 'react-router-dom'
import ModaleDirtyForm from '../../components/Modals/DirtyForm'
import { ListaClientiAntiriciclaggio } from './ListaClientiAntiriciclaggio'
import { CompilazioneAntiriciclaggio } from './CompilazioneAntiriciclaggio'
import staticData from '../../static-data/data-aggiornamento-modulistica.json'

const _dashboardItems = [
  {
    icon: 'icon-clipboard-edit1',
    label: staticData.dashboard.aggiornamento_antiriciclaggio,
    route: '/aggiornamento-modulistica/lista-antiriciclaggio'
  }
  // {
  //   icon: 'icon-address-card-o',
  //   label: staticData.dashboard.aggiornamento_documento,
  //   route: '#'
  // },
  // {
  //   icon: 'icon-document-text1',
  //   label: staticData.dashboard.aggiornamento_mifid,
  //   route: '#'
  // }
]

class AggiornamentoModulisticaContainerLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: window.innerWidth <= 992
    }

    this.getDashboard = this.getDashboard.bind(this)
    this.renderUserInfo = this.renderUserInfo.bind(this)
  }

  componentDidMount() {
    window.addEventListener(
      'resize',
      () => {
        this.setState({
          isMobile: window.innerWidth <= 992
        })
      },
      false
    )
  }

  getDashboard() {
    let _class = this.state.isMobile ? 'dashboard-modulistica' : 'dashboard-modulistica valign-wrapper'

    return (
      <div className={_class}>
        <Row>
          {_dashboardItems.map((item, index) => (
            <Col s={12} m={12} l={4} key={index}>
              <Link to={item.route}>
                <div className="dashboard-item center-align valign-wrapper">
                  <div>
                    <i className={item.icon} />
                    <p>{item.label}</p>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    )
  }

  renderUserInfo() {
    const {
      pratica,
      location: { pathname }
    } = this.props

    if (pathname.search('compilazione-antiriciclaggio') === -1) return
    if (!pratica.idPratica) return

    const _intestatario = pratica.intestatari[0]
    if (pratica.flagIntestatarioPG) {
      return (
        <div className="info-cliente">
          <i className="icon-user" />
          <div className="info-container">
            <p className="label-user">Ragione Sociale</p>
            <h4 className="value-cf-small">{_intestatario.cognome}</h4>
          </div>
        </div>
      )
    }
    return (
      <div className="info-cliente">
        <i className="icon-user" />
        <div className="info-container">
          <p className="label-user">Nome Cognome</p>
          <h4 className="value-user">{`${_intestatario.nome} ${_intestatario.cognome}`}</h4>
          <h5 className="value-cf-small">{_intestatario.codiceFiscale}</h5>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Row className="container-home paddingless marginless aggiornamento-modulistica">
        <ModaleDirtyForm history={this.props.history} />
        <Col s={12} className="container-home-left">
          {
            <section className="main-body no-aside">
              <Row className="thule-header valign-wrapper">
                <Col s={6} m={4} className="left-align">
                  <p className="thule-header-title">{staticData.title}</p>
                  <a onClick={() => this.props.history.push('/')} className="torna-pratiche">
                    <i className="icon-arrow-back" /> {staticData.button_home}
                  </a>
                </Col>
                <Col s={6} m={8} className="right-align">
                  {this.renderUserInfo()}
                </Col>
              </Row>
              <Switch>
                <Route path={`${this.props.match.url}/lista-antiriciclaggio`} component={ListaClientiAntiriciclaggio} />
                <Route
                  path={`${this.props.match.url}/compilazione-antiriciclaggio/:idPratica`}
                  component={CompilazioneAntiriciclaggio}
                />
                <Route path={`${this.props.match.url}`} component={this.getDashboard} />
              </Switch>
            </section>
          }
        </Col>
      </Row>
    )
  }
}

AggiornamentoModulisticaContainerLayout.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  pratica: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = state => ({
  pratica: state.pratica.pratica
})

export default connect(mapStateToProps)(AggiornamentoModulisticaContainerLayout)
