import React from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import { Row, Col, Button } from 'react-materialize'
import { Collapsible, CollapsibleItem } from '../../components/Collapsible'
import InputText from '../../components/Inputs/Text'
import ModalePopup from '../../components/Modals/Popup'
import staticData from '../../static-data/data-adeguata-verifica.json'
import fields from './fields'
import { connect } from 'react-redux'
import { isNil } from 'lodash'
import {
  loadData,
  loadDefaultFields,
  reduceQuestionsAdeguata,
  module_type,
  getQueryParams
} from '../ConsulenzaBaseContainerLayout/utils'
import { ADEGUATA_VERIFICA } from '../../store/modules/forms/constants'
import { reduxForm, Field, formValueSelector, isDirty } from 'redux-form'
import { renderHtmlInput, renderYesNoQuestion, renderSlider } from '../../components/Forms/renderFields'
import Title from '../../components/Title'
import ModalGeneric from '../../components/ModalGeneric'

const validate = (values, props) => {
  const errors = {}
  const queryParams = getQueryParams(props.location.search)
  const moduleType = queryParams.get('module')
  // solo su AML
  if (moduleType === module_type.ar) {
    if (values[fields.SLIDER_ANNI_CONOSCENZA.id] === 0) {
      errors[fields.SLIDER_ANNI_CONOSCENZA.id] = 'Selezionare almeno un anno di conoscenza'
    }
  }

  return errors
}

// Checkes all the fields, finds the untouched ones
const enableSendButton = formValues => {
  for (const fieldName in formValues) {
    if (formValues.hasOwnProperty(fieldName)) {
      const fieldValue = formValues[fieldName]

      if (isNil(fieldValue)) {
        return false
      }

      if (typeof fieldValue === 'string' && fieldValue.length <= 0) {
        if (fieldName === fields.TEXTAREA_MOTIVAZIONI_RISCHIO.id) {
          if (formValues[fields.RADIO_RISCHIO_RICICLAGGIO.id] !== fields.RADIO_RISCHIO_RICICLAGGIO.defaultValue) {
            return false
          }
        } else {
          return false
        }
      }
    }
  }

  return true
}

const selector = formValueSelector(ADEGUATA_VERIFICA)
const defaultFields = loadDefaultFields(fields)

class AdeguataVerificaComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      openCollapsible: 'conoscenza-cliente',
      minNumeroComponenti: 0,
      textModal: '',
      showModalGeneric: false,
      printed: false,
      onlyPrint: true
    }

    this.handleOpenCollapsibleChange = this.handleOpenCollapsibleChange.bind(this)
    this.sendAdegVer = this.sendAdegVer.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.backHome = this.backHome.bind(this)
    this.backHomeNoSave = this.backHomeNoSave.bind(this)
    this.stampaVer = this.stampaVer.bind(this)
    this.checkSlider = this.checkSlider.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      praticaRequest,
      adegVerSaveRequest,
      location: { search },
      importSurveyExtAV,
      getAdegVerTemplate,
      idPratica,
      intestatari,
      codicePromoter,
      getPromoterCustomerHimself,
      getPromoterCustomerHimselfRequest,
      isPromoterCustomerHimself,
      downloadZipRequest,
      importSurveyExtRequest,
      amlPreviousForm
    } = this.props

    if (prevProps.praticaRequest.fetching && !praticaRequest.fetching && !praticaRequest.error) {
      getAdegVerTemplate()
      const queryParams = getQueryParams(search)
      const moduleType = queryParams.get('module')
      if (moduleType === module_type.ar) {
        importSurveyExtAV({
          idPratica: idPratica,
          idCliente: intestatari[0].id,
          idCustomerExt: intestatari[0].codiceClienteEsterno,
          subjectType: 'PHYSICAL_SUBJECT'
        })
        // controllo se il customer è cliente di se stesso
        getPromoterCustomerHimself(codicePromoter, intestatari[0].codiceFiscale)
      }
    }

    // check cliente di se stesso
    if (
      prevProps.getPromoterCustomerHimselfRequest.fetching &&
      !getPromoterCustomerHimselfRequest.fetching &&
      !getPromoterCustomerHimselfRequest.error
    ) {
      if (isPromoterCustomerHimself) {
        this.setState({ textModal: 'self', showModalGeneric: true })
      }
    }

    if (prevProps.adegVerSaveRequest.fetching && !adegVerSaveRequest.fetching && !adegVerSaveRequest.error) {
      this.backHomeNoSave()
    }

    if (prevState.openCollapsible !== this.state.openCollapsible && this.state.openCollapsible) {
      setTimeout(
        () => $('.container-home-left').scrollTop($('.container-home-left').scrollTop() + $('.open').offset().top - 50),
        200
      )
    }
    if (
      prevProps.downloadZipRequest.fetching &&
      !downloadZipRequest.fetching &&
      !downloadZipRequest.error &&
      !this.state.printed
    ) {
      this.setState({ printed: true })
    }

    if (
      prevProps.importSurveyExtRequest.fetching &&
      !importSurveyExtRequest.fetching &&
      !importSurveyExtRequest.error
    ) {
      const yes_no = amlPreviousForm.listaDomande.filter(d => {
        return d.idDomanda === fields.YES_NO_CONTESTO_FAMILIARE.templateId
      })[0]

      if (yes_no && yes_no.listaRisposte[0]) {
        this.checkSlider(yes_no.listaRisposte[0].idRisposta)
      }
    }
  }

  backHome() {
    this.setState({ showModal: true })
  }

  backHomeNoSave() {
    this.props.history.push('/')
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  handleOpenCollapsibleChange(itemKey) {
    this.setState({ openCollapsible: itemKey })
  }

  sendAdegVer(values) {
    if (!this.state.onlyPrint) {
      const {
        customer: { idCliente, idIncarico }
      } = this.props
      const reducedQuestions = reduceQuestionsAdeguata(fields, {
        ...values
      })
      this.props.saveAdegVer(idCliente, idIncarico, reducedQuestions)
    }
    this.setState({ onlyPrint: false })
  }

  stampaVer(values) {
    this.setState({ onlyPrint: true })
    const {
      customer: { idCliente, idIncarico }
    } = this.props
    const listaDomande = reduceQuestionsAdeguata(fields, {
      ...values
    })
    this.props.downloadPDF(idCliente, idIncarico, listaDomande)
  }

  checkSlider(value) {
    if (value === fields.YES_NO_CONTESTO_FAMILIARE.yesId) {
      this.setState({ minNumeroComponenti: 1 })
      if (this.props.formValues[fields.SLIDER_NUMERO_COMPONENTI.id] < 1) {
        this.props.change(fields.SLIDER_NUMERO_COMPONENTI.id, 1)
      }
    } else {
      this.setState({ minNumeroComponenti: 0 })
    }
  }

  render() {
    if (this.props.template === null) {
      return null
    }

    const staticAV = staticData.adeguata_verifica
    const staticConoscenza = staticAV.conoscenza_cliente
    const staticContesto = staticAV.info_contesto
    const { change, touch, handleSubmit, submitting, invalid, formValues, isPromoterCustomerHimself } = this.props

    // const { servizi } = this.props
    // const desc =
    //   servizi.length && servizi[0].descTipoServizio
    //     ? servizi[0].descTipoServizio.toUpperCase()
    //     : 'CONSULENZA E COLLOCAMENTO'
    const risposteRischioRiciclaggio = this.props.template.sezioni[1].listaDomande.find(
      c => c.idDomanda === fields.RADIO_RISCHIO_RICICLAGGIO.templateId
    ).listaRisposte

    const queryParams = getQueryParams(this.props.location.search)
    const moduleType = queryParams.get('module')

    return (
      <Row className="container-home paddingless marginless">
        <Col s={12} className="container-home-left">
          <section className="main-body no-aside">
            <ModalGeneric
              show={this.state.showModalGeneric}
              handler={() => {
                this.setState({ showModalGeneric: false })
              }}
              title="Attenzione!"
              text={staticData.adeguata_verifica[this.state.textModal]}
            />
            <ModalePopup
              visible={this.state.showModal}
              title="Attenzione!"
              text="Le modifiche non sono state salvate. Se prosegui perderai i dati inseriti"
              primaryActionLabel="Continua"
              secondaryActionLabel="Annulla"
              secondaryAction={this.toggleModal}
              primaryAction={this.backHomeNoSave}
            />
            <header>
              {/* <Link onClick={this.backHome} to="#" className="torna-pratiche">
                <i className="icon-arrow-back" /> {staticData.button_back}
              </Link> */}
              <a onClick={() => this.backHome()} className="torna-pratiche">
                <i className="icon-arrow-back" /> Torna a elenco pratiche
              </a>
              <h1 className="title-macrosezione">{staticData.title}</h1>
            </header>
            <article className="page-left-content adeguata-verifica">
              <div className="background_pattern" />
              <form onSubmit={handleSubmit(this.sendAdegVer)}>
                <Row>
                  <Col s={12}>
                    <Collapsible open={this.state.openCollapsible} onChange={this.handleOpenCollapsibleChange}>
                      <CollapsibleItem
                        itemKey="conoscenza-cliente"
                        header={staticConoscenza.title_accordion}
                        icon="keyboard_arrow_down"
                        labelInfo="">
                        <Row>
                          <Col s={12}>
                            <Title>{staticConoscenza.input_anni.title}</Title>
                            <Field
                              name={fields.SLIDER_ANNI_CONOSCENZA.id}
                              className="with-gap"
                              min={0}
                              max={99}
                              component={renderSlider}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col s={12}>
                            <Title>{staticConoscenza.radio_primo_contatto.title}</Title>
                            <div className="radio-horizontal">
                              {this.props.template.sezioni[0].listaDomande
                                .find(d => d.idDomanda === fields.RADIO_PRIMO_CONTATTO.templateId)
                                .listaRisposte.map((risposta, index) => (
                                  <p key={`dati-personali-${index}`}>
                                    <Field
                                      name={fields.RADIO_PRIMO_CONTATTO.id}
                                      className="with-gap"
                                      type="radio"
                                      id={`dati-personali-i-${index}`}
                                      value={'' + risposta.idRisposta}
                                      component="input"
                                    />
                                    <label htmlFor={`dati-personali-i-${index}`}>{risposta.descRisposta}</label>
                                  </p>
                                ))}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Field
                            name={fields.YES_NO_CONTESTO_FAMILIARE.id}
                            label={
                              this.props.template.sezioni[0].listaDomande.find(
                                c => c.idDomanda === fields.YES_NO_CONTESTO_FAMILIARE.templateId
                              ).testoDomanda
                            }
                            handlerFn={value => {
                              change(fields.YES_NO_CONTESTO_FAMILIARE.id, value)
                              // Controllo sullo slider
                              this.checkSlider(value)
                            }}
                            options={
                              this.props.template.sezioni[0].listaDomande.find(
                                c => c.idDomanda === fields.YES_NO_CONTESTO_FAMILIARE.templateId
                              ).listaRisposte
                            }
                            component={renderYesNoQuestion}
                          />
                        </Row>
                        <Row>
                          <Col s={12}>
                            <Title>{staticConoscenza.input_num_componenti.title}</Title>
                            <Field
                              name={fields.SLIDER_NUMERO_COMPONENTI.id}
                              className="with-gap"
                              min={this.state.minNumeroComponenti}
                              max={99}
                              component={renderSlider}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col s={12}>
                            <Title>{staticConoscenza.radio_tenore_vita.title}</Title>
                            <div className="big-radio">
                              {this.props.template.sezioni[0].listaDomande
                                .find(c => c.idDomanda === fields.RADIO_TENORE_VITA.templateId)
                                .listaRisposte.map((risposta, index) => (
                                  <div key={index}>
                                    <Field
                                      name={fields.RADIO_TENORE_VITA.id}
                                      className="with-gap"
                                      type="radio"
                                      id={`type-${index}`}
                                      value={'' + risposta.idRisposta}
                                      component={renderHtmlInput}
                                    />
                                    <label htmlFor={`type-${index}`}>{risposta.descRisposta}</label>
                                  </div>
                                ))}
                            </div>
                          </Col>
                        </Row>
                      </CollapsibleItem>
                      <CollapsibleItem
                        itemKey="info-contesto"
                        header={staticContesto.title_accordion}
                        icon="keyboard_arrow_down"
                        labelInfo="">
                        <Row>
                          <Col s={12}>
                            <Title>{staticContesto.radio_ragionevolezza_attivita.title}</Title>
                            <div className="radio-horizontal">
                              {this.props.template.sezioni[1].listaDomande
                                .find(d => d.idDomanda === fields.RAGIONEVOLEZZA_ATTIVITA.templateId)
                                .listaRisposte.map((risposta, index) => (
                                  <p key={`dati-personali-${index}`}>
                                    <Field
                                      name={fields.RAGIONEVOLEZZA_ATTIVITA.id}
                                      className="with-gap"
                                      type="radio"
                                      id={`dati-personali-i-${fields.RAGIONEVOLEZZA_ATTIVITA.id}-${index}`}
                                      value={'' + risposta.idRisposta}
                                      component="input"
                                    />
                                    <label htmlFor={`dati-personali-i-${fields.RAGIONEVOLEZZA_ATTIVITA.id}-${index}`}>
                                      {risposta.descRisposta}
                                    </label>
                                  </p>
                                ))}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col s={12}>
                            <Title>{staticContesto.radio_ragionevolezza_residenza.title}</Title>
                            <div className="radio-horizontal">
                              {this.props.template.sezioni[1].listaDomande
                                .find(d => d.idDomanda === fields.RAGIONEVOLEZZA_RESIDENZA.templateId)
                                .listaRisposte.map((risposta, index) => (
                                  <p key={`dati-personali-${index}`}>
                                    <Field
                                      name={fields.RAGIONEVOLEZZA_RESIDENZA.id}
                                      className="with-gap"
                                      type="radio"
                                      id={`dati-personali-i-${fields.RAGIONEVOLEZZA_RESIDENZA.id}-${index}`}
                                      value={'' + risposta.idRisposta}
                                      component="input"
                                    />
                                    <label htmlFor={`dati-personali-i-${fields.RAGIONEVOLEZZA_RESIDENZA.id}-${index}`}>
                                      {risposta.descRisposta}
                                    </label>
                                  </p>
                                ))}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col s={12}>
                            <Title>{staticContesto.radio_comportamento.title}</Title>
                            <div className="radio-horizontal">
                              {this.props.template.sezioni[1].listaDomande
                                .find(d => d.idDomanda === fields.COMPORTAMENTO.templateId)
                                .listaRisposte.map((risposta, index) => (
                                  <p key={`dati-personali-${index}`}>
                                    <Field
                                      name={fields.COMPORTAMENTO.id}
                                      className="with-gap"
                                      type="radio"
                                      id={`dati-personali-i-${fields.COMPORTAMENTO.id}-${index}`}
                                      value={'' + risposta.idRisposta}
                                      component="input"
                                    />
                                    <label htmlFor={`dati-personali-i-${fields.COMPORTAMENTO.id}-${index}`}>
                                      {risposta.descRisposta}
                                    </label>
                                  </p>
                                ))}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Field
                            name={fields.YES_NO_SOGGETTI_TERZI.id}
                            label={
                              this.props.template.sezioni[1].listaDomande.find(
                                c => c.idDomanda === fields.YES_NO_SOGGETTI_TERZI.templateId
                              ).testoDomanda
                            }
                            handlerFn={value => change(fields.YES_NO_SOGGETTI_TERZI.id, value)}
                            options={
                              this.props.template.sezioni[1].listaDomande.find(
                                c => c.idDomanda === fields.YES_NO_SOGGETTI_TERZI.templateId
                              ).listaRisposte
                            }
                            component={renderYesNoQuestion}
                          />
                          <Field
                            name={fields.YES_NO_INFORMAZIONI_ATTENDIBILI.id}
                            label={
                              this.props.template.sezioni[1].listaDomande.find(
                                c => c.idDomanda === fields.YES_NO_INFORMAZIONI_ATTENDIBILI.templateId
                              ).testoDomanda
                            }
                            handlerFn={value => change(fields.YES_NO_INFORMAZIONI_ATTENDIBILI.id, value)}
                            options={
                              this.props.template.sezioni[1].listaDomande.find(
                                c => c.idDomanda === fields.YES_NO_INFORMAZIONI_ATTENDIBILI.templateId
                              ).listaRisposte
                            }
                            component={renderYesNoQuestion}
                          />
                          <Field
                            name={fields.YES_NO_ELEMENTI_SOSPETTOSITA.id}
                            label={
                              this.props.template.sezioni[1].listaDomande.find(
                                c => c.idDomanda === fields.YES_NO_ELEMENTI_SOSPETTOSITA.templateId
                              ).testoDomanda
                            }
                            handlerFn={value => change(fields.YES_NO_ELEMENTI_SOSPETTOSITA.id, value)}
                            options={
                              this.props.template.sezioni[1].listaDomande.find(
                                c => c.idDomanda === fields.YES_NO_ELEMENTI_SOSPETTOSITA.templateId
                              ).listaRisposte
                            }
                            component={renderYesNoQuestion}
                          />
                        </Row>
                        <Row>
                          <Col s={12}>
                            <Title>{staticContesto.radio_rischio_riciclaggio.title}</Title>
                            <div className="big-radio">
                              {risposteRischioRiciclaggio.map((risposta, index) => (
                                <div key={index}>
                                  <Field
                                    name={fields.RADIO_RISCHIO_RICICLAGGIO.id}
                                    className="with-gap"
                                    type="radio"
                                    id={`type-${index}`}
                                    value={'' + risposta.idRisposta}
                                    onChange={() => {
                                      if (index === 2) {
                                        change(fields.TEXTAREA_MOTIVAZIONI_RISCHIO.id, '')
                                      }
                                    }}
                                    component={renderHtmlInput}
                                  />
                                  <label htmlFor={`type-${index}`}>{risposta.descRisposta}</label>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Field
                            name={fields.TEXTAREA_MOTIVAZIONI_RISCHIO.id}
                            s={12}
                            maxLength={40}
                            value={formValues[fields.TEXTAREA_MOTIVAZIONI_RISCHIO.id]}
                            label={staticContesto.input_textarea_motivazioni.label}
                            change={change}
                            touch={touch}
                            disabled={
                              !formValues.radioRischioRiciclaggio ||
                              parseInt(formValues.radioRischioRiciclaggio) ===
                                risposteRischioRiciclaggio[risposteRischioRiciclaggio.length - 1].idRisposta
                            }
                            component={InputText}
                          />
                          <Col s={12}>
                            <span className="suggestion-textarea">
                              {staticContesto.input_textarea_motivazioni.help_text}
                            </span>
                          </Col>
                        </Row>
                      </CollapsibleItem>
                    </Collapsible>
                    <Row className="center-align">
                      <Col s={12}>
                        {/* solo AML e cliente di se stesso */}
                        {moduleType === module_type.ar && isPromoterCustomerHimself && (
                          <Button
                            className="back-button"
                            onClick={() => {
                              this.stampaVer(formValues)
                              return false
                            }}
                            disabled={submitting || !isDirty || invalid || !enableSendButton(formValues)}>
                            {staticData.button_stampa}
                          </Button>
                        )}
                        {/* ### */}
                        <Button
                          className="margin-20"
                          waves="light"
                          type="submit"
                          disabled={
                            (moduleType === module_type.ar && isPromoterCustomerHimself && !this.state.printed) ||
                            submitting ||
                            !isDirty ||
                            invalid ||
                            !enableSendButton(formValues)
                          }>
                          {moduleType === module_type.ar ? staticData.button_salva : staticData.button_invia}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col s={3} className="paddingless">
                    <aside className="aside-adeguata-verifica">
                      <div className="info-cliente">
                        <i className="icon-user" />
                        <div>
                          <p className="label-user">Nome Cognome</p>
                          <h4 className="value-user">
                            {intestatari.length && intestatari[0].nome} {intestatari.length && intestatari[0].cognome}{' '}
                          </h4>
                          <h6 className="value-cf">{intestatari.length && intestatari[0].codiceFiscale}</h6>
                        </div>
                      </div>
                      <div className="contratto-box-info">
                        <p>{desc}</p>
                        <p className="margin-t-5">
                          <span>
                            {intestatari.length && intestatari[0].nome} {intestatari.length && intestatari[0].cognome}
                          </span>
                        </p>
                      </div>
                    </aside>
                  </Col> */}
                </Row>
              </form>
            </article>
          </section>
        </Col>
      </Row>
    )
  }
}

AdeguataVerificaComponent.propTypes = {
  adegVerSaveRequest: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  customer: PropTypes.object,
  formValues: PropTypes.object,
  getAdegVerTemplate: PropTypes.func,
  handleSubmit: PropTypes.func,
  history: PropTypes.object,
  invalid: PropTypes.bool,
  saveAdegVer: PropTypes.func,
  submitting: PropTypes.bool,
  template: PropTypes.object,
  intestatari: PropTypes.array,
  // servizi: PropTypes.object,
  praticaRequest: PropTypes.object,
  location: PropTypes.object,
  idPratica: PropTypes.any,
  importSurveyExtAV: PropTypes.func,
  codicePromoter: PropTypes.any,
  getPromoterCustomerHimself: PropTypes.func,
  isPromoterCustomerHimself: PropTypes.bool,
  getPromoterCustomerHimselfRequest: PropTypes.object,
  downloadPDF: PropTypes.func,
  downloadZipRequest: PropTypes.object,
  importSurveyExtRequest: PropTypes.object,
  amlPreviousForm: PropTypes.object
}

export default connect((state, ownProps) => {
  const {
    location: { search }
  } = ownProps
  const queryParams = getQueryParams(search)
  const moduleType = queryParams.get('module')
  let _defaultFields = { ...defaultFields }
  if (moduleType === module_type.ar) {
    _defaultFields[fields.SLIDER_ANNI_CONOSCENZA.id] = 0
  }

  return {
    initialValues: {
      ..._defaultFields,
      ...loadData(moduleType === module_type.ar ? state.survey.amlPreviousForm : {}, fields)
    },
    formValues: {
      ..._defaultFields,
      ...loadData(moduleType === module_type.ar ? state.survey.amlPreviousForm : {}, fields),
      ...selector(state, ...Object.keys(_defaultFields).reduce((res, cur) => [...res, cur], []))
    },
    isDirty: isDirty(ADEGUATA_VERIFICA)(state),
    isFetching: state.survey.amlSaveRequest.fetching,
    apiError: state.survey.amlSaveRequest.error,
    apiSuccess: state.survey.amlSent
  }
})(
  reduxForm({
    form: ADEGUATA_VERIFICA,
    validate,
    enableReinitialize: true
  })(AdeguataVerificaComponent)
)
