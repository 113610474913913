import { connect } from 'react-redux'
import { getFormSyncErrors, getFormValues, isDirty, reduxForm } from 'redux-form'
import allFields from '../../fields'
import { loadData, loadDefaultFields } from '../../utils'
import ConsensiPrivacy from './ConsensiPrivacyComponent'
import { CONSENSI_PRIVACY } from '../../../../store/modules/forms/constants'

const fields = allFields[1][CONSENSI_PRIVACY]
const defaultFields = loadDefaultFields(fields)

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadData(state.survey.amlPreviousForm, fields)
  },
  formValues: getFormValues(CONSENSI_PRIVACY)(state),
  formErrors: getFormSyncErrors(CONSENSI_PRIVACY)(state),
  fields,
  isDirty: isDirty(CONSENSI_PRIVACY)(state),
  isFetching: state.survey.amlSaveRequest.fetching,
  apiError: state.survey.amlSaveRequest.error,
  apiSuccess: state.survey.amlSent
}))(
  reduxForm({
    form: CONSENSI_PRIVACY,
    shouldError: () => isDirty(CONSENSI_PRIVACY)
  })(ConsensiPrivacy)
)
