import React from 'react'
import { CardPanel, Col, Row } from 'react-materialize'
import PropTypes from 'prop-types'
import './_smallMaterializeCard.scss'

const SmallMaterializeCard = ({ className, title, value, icon, previous, s }) => (
  <Col s={s}>
    <CardPanel className={className}>
      <Row className="smallCard smallCard__row">
        <Col s={12}>
          <div className="smallCard smallCard__title">{title}</div>
        </Col>
        <Col s={6}>
          <div className="smallCard smallCard__amount">{value}</div>
        </Col>
        <Col s={6}>
          <img className="smallCard smallCard__img" src={icon} />
        </Col>
        <Col s={12}>
          <hr className="smallCard smallCard__hr" />
        </Col>
        <Col s={12}>
          <div className="smallCard smallCard__previous">
            Mese Precedente <span className="smallCard smallCard__previous--previousAmount">{previous}</span>
          </div>
        </Col>
      </Row>
    </CardPanel>
  </Col>
)
export default SmallMaterializeCard

SmallMaterializeCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  previous: PropTypes.string,
  s: PropTypes.number
}
