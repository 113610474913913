import * as Forms from '../store/modules/forms/constants'

export default [
  {
    [Forms.FORM_ADD_DEROGA]: {
      TIPOLOGIA_SERVIZIO: {
        id: 'tipologia_servizio',
        label: 'Tipologia di Servizio',
        dominio: 'DOM_TIPOLOGIA_SERVIZIO'
      },
      FASCIA_INVESTIMENTO: {
        id: 'fascia_investimento',
        label: 'Fascia di investimento',
        dominio: 'DOM_FASCIA_INVESTIMENTO'
      },
      FASCIA_DEROGA: {
        id: 'fascia_deroga',
        label: 'Deroga*',
        dominio: 'DOM_LIVELLO_DEROGA'
      },
      TIPOLOGIA_STRUM_FINANZIARIO: {
        id: 'strumento_finanziario',
        label: 'Tipologia Titolo/ strumento finanziario',
        dominio: 'DOMINIO_TIPOLOGIA_STRUM_FINANZIARIO'
      },
      CLUSTER: {
        id: 'cluster',
        label: 'Cluster',
        dominio: 'DOM_CLUSTER'
      }
    }
  }
]
