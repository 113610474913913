import { connect } from 'react-redux'
import { getFormSyncErrors, getFormValues, isDirty, reduxForm } from 'redux-form'
import allFields from '../../fields'
import { loadData, loadDefaultFields } from '../../utils'
import ConsensiPrivacyGiuridiche from './ConsensiPrivacyGiuridicheComponent'
import { CONSENSI_PRIVACY_GIURIDICHE } from '../../../../store/modules/forms/constants'

const fields = allFields[1][CONSENSI_PRIVACY_GIURIDICHE]
const defaultFields = loadDefaultFields(fields)

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadData(state.survey.amlPgPreviousForm, fields)
  },
  formValues: getFormValues(CONSENSI_PRIVACY_GIURIDICHE)(state),
  formErrors: getFormSyncErrors(CONSENSI_PRIVACY_GIURIDICHE)(state),
  fields,
  isDirty: isDirty(CONSENSI_PRIVACY_GIURIDICHE)(state),
  isFetching: state.survey.amlPgSaveRequest.fetching,
  apiError: state.survey.amlPgSaveRequest.error,
  apiSuccess: state.survey.amlPgSent,
  isSummaryLightPGFetching: state.prospectPersonaGiuridica.getDataSummaryLight.fetching,
  isSummaryLightPGApiError: state.prospectPersonaGiuridica.getDataSummaryLight.error
}))(
  reduxForm({
    form: CONSENSI_PRIVACY_GIURIDICHE,
    shouldError: () => isDirty(CONSENSI_PRIVACY_GIURIDICHE)
  })(ConsensiPrivacyGiuridiche)
)
