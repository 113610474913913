import React from 'react'
import PropTypes from 'prop-types'
import { CollapsibleContext } from './CollapsibleContext'

class Collapsible extends React.Component {
  constructor(props) {
    super(props)
    this.handleItemChange = this.handleItemChange.bind(this)
  }

  handleItemChange(i) {
    if (this.props.onChange) {
      this.props.onChange(i)
    }
  }

  render() {
    const { children, open = null, autoToggle = false } = this.props
    return (
      <div className={`collapsible`}>
        <CollapsibleContext.Provider
          value={{
            openItem: open,
            onChange: this.handleItemChange,
            autoToggle: autoToggle
          }}>
          {children}
        </CollapsibleContext.Provider>
      </div>
    )
  }
}
export default Collapsible

Collapsible.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  autoToggle: PropTypes.bool
}
