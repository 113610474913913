import * as actionTypes from './constants'

const initialState = {
  listaDeroghe: [],
  respPostDeroga: {},
  apiRequest: {
    getDeroghe: {
      fetching: false,
      error: null
    },
    postDeroga: {
      fetching: false,
      error: null
    }
  },
  setIntestatariDerogaRequest: {
    fetching: false,
    dataSuccessFlags: null,
    error: null
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DEROGHE_START:
      return {
        ...state,
        apiRequest: {
          ...state.apiRequest,
          getDeroghe: {
            fetching: true,
            error: null
          }
        }
      }

    case actionTypes.GET_DEROGHE_SUCCESS:
      return {
        ...state,
        apiRequest: {
          ...state.apiRequest,
          getDeroghe: {
            fetching: false,
            error: null
          }
        },
        listaDeroghe: action.listaDeroghe
      }
    case actionTypes.GET_DEROGHE_ERROR:
      return {
        ...state,
        apiRequest: {
          ...state.apiRequest,
          getDeroghe: {
            fetching: false,
            error: action.errorApi
          }
        }
      }

    case actionTypes.SET_DEROGA_START:
      return {
        ...state,
        apiRequest: {
          ...state.apiRequest,
          postDeroga: {
            fetching: true,
            error: null
          }
        }
      }

    case actionTypes.SET_DEROGA_SUCCESS:
      return {
        ...state,
        apiRequest: {
          ...state.apiRequest,
          postDeroga: {
            fetching: false,
            error: null
          }
        },
        respPostDeroga: action.respPostDeroga
      }
    case actionTypes.SET_DEROGA_ERROR:
      return {
        ...state,
        apiRequest: {
          ...state.apiRequest,
          postDeroga: {
            fetching: false,
            error: action.errorApi
          }
        }
      }
    case actionTypes.SET_DEROGA_INTESTATARI_START:
      return {
        ...state,
        setIntestatariDerogaRequest: {
          fetching: true,
          error: null
        }
      }

    case actionTypes.SET_DEROGA_INTESTATARI_SUCCESS:
      return {
        ...state,
        setIntestatariDerogaRequest: {
          fetching: false,
          error: null
        },
        intestatari: action.intestatari
      }
    case actionTypes.SET_DEROGA_INTESTATARI_ERROR:
      return {
        ...state,
        setIntestatariDerogaRequest: {
          fetching: false,
          error: action.errorApi
        }
      }

    default:
      return state
  }
}
