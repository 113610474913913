import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Row, Col, Button } from 'react-materialize'
import { renderInputPdf } from '../../../Forms/renderFields'

class Cartaceo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      notChecked: true
    }

    this.toggleChange = this.toggleChange.bind(this)
    this.uploadFileName = this.uploadFileName.bind(this)
  }

  uploadFileName() {
    const {
      fields: { PRIVACY_DOC }
    } = this.props

    let fullPath = document.getElementById(PRIVACY_DOC.id).value
    if (fullPath) {
      let startIndex = fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/')
      let filename = fullPath.substring(startIndex)
      if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
        filename = filename.substring(1)
      }
    }
  }
  toggleChange() {
    this.setState({
      notChecked: !this.state.notChecked
    })
  }
  get canProceed() {
    const { notChecked } = this.state
    return !notChecked
  }

  render() {
    const {
      fields: { PRIVACY_DOC },
      title,
      text,
      detail,
      pdf,
      backHandler,
      continueHandler,
      change,
      formValues = {}
    } = this.props

    let renderUpload
    let bool = location.pathname.indexOf('-PG') >= 0
    if (!bool) {
      renderUpload = (
        <Field
          name={PRIVACY_DOC.id}
          idImg={PRIVACY_DOC.id}
          fileIn={formValues[PRIVACY_DOC.id]}
          label={'Documento privacy'}
          onChangeHandler={(file, fileThumb) => {
            change(PRIVACY_DOC.id, file)
            change(PRIVACY_DOC.id, fileThumb)
          }}
          component={renderInputPdf}
        />
      )
    }

    return (
      <div className="onboarding-modale">
        <div className="onboarding-modale-content">
          <Row>
            <Col s={12} style={{ marginTop: '-20px' }}>
              {title}
            </Col>
            <Col s={12}>{text}</Col>
            <Col s={12}>{detail}</Col>
            <Col s={12}>{pdf}</Col>
            <Col s={12}>{renderUpload}</Col>
            <Col s={12} className="center-align marginTop20">
              <input type="checkbox" value="privacyAccepted" id="privacyAccepted" onChange={this.toggleChange} />
              <label htmlFor="privacyAccepted">Il Prospect ha accettato la Privacy Policy</label>
            </Col>
            <Col s={6} style={{ marginTop: '30px' }}>
              <Button className="custom-btn primary-reverse transparent" onClick={backHandler}>
                Annulla
              </Button>
            </Col>
            <Col s={6} className="right-align" style={{ marginTop: '30px' }}>
              <Button className="btn custom-btn primary" disabled={!this.canProceed} onClick={continueHandler}>
                Prosegui
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
export default Cartaceo

Cartaceo.propTypes = {
  title: PropTypes.object,
  text: PropTypes.node,
  detail: PropTypes.node,
  pdf: PropTypes.node,
  change: PropTypes.func,
  fields: PropTypes.object,
  formValues: PropTypes.object,
  backHandler: PropTypes.func,
  continueHandler: PropTypes.func
  // isProspectPG: PropTypes.bool
}
