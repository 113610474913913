import * as actionTypes from './constants'
import axios from 'axios'

export function getDataGatheringPGActionCreator(idLegalPerson) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DATA_GATHERING_PG_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/${idLegalPerson}`)
      dispatch({
        type: actionTypes.GET_DATA_GATHERING_PG_SUCCESS,
        responseApi: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DATA_GATHERING_PG_ERROR,
        error
      })
    }
  }
}

export function getDataGatheringPGUpdateStatus(objectBody) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_STATUS_PG_START
    })
    try {
      const response = await axios.post('/v2/legal-person/update-status-legal-person/', objectBody)
      dispatch({
        type: actionTypes.POST_STATUS_PG_SUCCESS,
        responseStatus: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_STATUS_PG_ERROR,
        error
      })
    }
  }
}

export function postDataGatheringPGActionCreator(objectBody) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_DATA_GATHERING_PG_START
    })
    try {
      const response = await axios.post('/v2/legal-person/', objectBody)
      dispatch({
        type: actionTypes.POST_DATA_GATHERING_PG_SUCCESS,
        responseApi: response.data,
        requestApi: objectBody
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_DATA_GATHERING_PG_ERROR,
        error
      })
    }
  }
}

export function legalPersonCreatePF(objectBody) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.LEGAL_PERSON_CREATE_PF_START
    })
    try {
      const response = await axios.post('/v2/legal-person/reference/create', objectBody)
      dispatch({
        type: actionTypes.LEGAL_PERSON_CREATE_PF_SUCCESS,
        legalPersonPG: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.LEGAL_PERSON_CREATE_PF_ERROR,
        error
      })
    }
  }
}

export function checkProspectPGActionCreator(objectBody) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PROSPECT_CHECK_PG_START
    })
    try {
      const response = await axios.post('/v2/legal-person/check-legal-person', objectBody)
      dispatch({
        type: actionTypes.PROSPECT_CHECK_PG_SUCCESS,
        isProspectPGAvailable: response.data.code
      })
    } catch (error) {
      dispatch({
        type: actionTypes.PROSPECT_CHECK_PG_ERROR,
        error
      })
    }
  }
}

// let dataSL = {
//   percentuale: 89,
//   statusEndPoint: [
//     { name: 'idCardData', active: false },
//     { name: 'dataRegistry', active: false },
//     { name: 'fatcacrs', active: false },
//     { name: 'aml', active: false },
//     { name: 'servdig', active: false },
//     { name: 'mifid', active: false }
//   ],
//   sezioni: [
//     {
//       nomeSezione: 'ACQUISIZIONE DOCUMENTI',
//       stato: 'NUOVA',
//       codSezione: 'DOCUMENTI_IDENTITA',
//       sottoSezioni: [
//         { nomeSezione: 'CODICE_FISCALE', stato: 'NUOVA', codSezione: 'CODICE_FISCALE', sottoSezioni: null },
//         {
//           nomeSezione: 'DOCUMENTO_IDENTITA_ALLEGATO',
//           stato: 'NUOVA',
//           codSezione: 'DOCUMENTO_IDENTITA_ALLEGATO',
//           sottoSezioni: null
//         },
//         {
//           nomeSezione: 'CENSIMENTO_PROCURATORE',
//           stato: 'NUOVA',
//           codSezione: 'CENSIMENTO_PROCURATORE',
//           sottoSezioni: null
//         },
//         { nomeSezione: 'PROCURATORE_ID', stato: 'NUOVA', codSezione: 'PROCURATORE_ID', sottoSezioni: null },
//         { nomeSezione: 'DOCUMENTO_PROCURA', stato: 'NUOVA', codSezione: 'DOCUMENTO_PROCURA', sottoSezioni: null },
//         {
//           nomeSezione: 'CLIENTI_PROFESSIONALI',
//           stato: 'NUOVA',
//           codSezione: 'CLIENTI_PROFESSIONALI',
//           sottoSezioni: null
//         }
//       ]
//     },
//     {
//       nomeSezione: 'CENSIMENTO ANAGRAFICO',
//       stato: 'NUOVA',
//       codSezione: 'CENSIMENTO_ANAGRAFICO',
//       sottoSezioni: [
//         { nomeSezione: 'INFO_PERSONALI', stato: 'NUOVA', codSezione: 'INFO_PERSONALI', sottoSezioni: null },
//         { nomeSezione: 'RESIDENZA', stato: 'NUOVA', codSezione: 'RESIDENZA', sottoSezioni: null },
//         { nomeSezione: 'CONTATTI', stato: 'NUOVA', codSezione: 'CONTATTI', sottoSezioni: null },
//         { nomeSezione: 'SOGGETTI_CORRELATI', stato: 'NUOVA', codSezione: 'SOGGETTI_CORRELATI', sottoSezioni: null },
//         { nomeSezione: 'FATCA', stato: 'NUOVA', codSezione: 'FATCA', sottoSezioni: null },
//         { nomeSezione: 'ANTI_RICICLAGGIO', stato: 'NUOVA', codSezione: 'ANTI_RICICLAGGIO', sottoSezioni: null },
//         { nomeSezione: 'PRIVACY', stato: 'NUOVA', codSezione: 'PRIVACY', sottoSezioni: null }
//       ]
//     },
//     {
//       nomeSezione: 'QUESTIONARIO MIFID',
//       stato: 'NUOVA',
//       codSezione: 'MIFID',
//       sottoSezioni: [
//         { nomeSezione: 'INVESTIMENTO', stato: 'NUOVA', codSezione: 'INVESTIMENTO', sottoSezioni: null },
//         { nomeSezione: 'ALTRO', stato: 'NUOVA', codSezione: 'ALTRO', sottoSezioni: null }
//       ]
//     },
//     {
//       nomeSezione: 'SOTTOSCRIZIONE SERVIZI',
//       stato: 'COMPLETATA',
//       codSezione: 'SERVIZI_DIGITALI',
//       sottoSezioni: [
//         { nomeSezione: 'SERVIZI_DIGITALI', stato: 'COMPLETATA', codSezione: 'SERVIZI_DIGITALI', sottoSezioni: null }
//       ]
//     },
//     { nomeSezione: 'RIEPILOGO CLIENTE', stato: null, codSezione: 'RIEPILOGO', sottoSezioni: null }
//   ],
//   statusResponse: { code: '0', description: 'OK' },
//   profiloRischio: null,
//   admin: false,
//   idProcurator: null
// }

// nuova
// let dataSL = {
// 	"percentuale": 0,
// 	"statusEndPoint": [],
// 	"sezioni": [{
// 			"nomeSezione": "ACQUISIZIONE DOCUMENTI",
// 			"stato": null,
// 			"codSezione": "DOCUMENTI_IDENTITA",
// 			"sottoSezioni": [{
// 					"nomeSezione": "Codice fiscale persona fisica di riferimento",
// 					"stato": "NUOVA",
// 					"codSezione": "CODICE_FISCALE_PF",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Documento d'identità persona fisica di riferimento",
// 					"stato": "NUOVA",
// 					"codSezione": "DOCUMENTO_IDENTITA_PF",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Documenti persona giuridica",
// 					"stato": "NUOVA",
// 					"codSezione": "DOCUMENTO_PG",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Scheda di raccolta dati per la verifica della clientela",
// 					"stato": "NUOVA",
// 					"codSezione": "SCHEDA_RACCOLTA_DATI_PF_RIF",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Scheda di raccolta dati per la verifica della clientela - Titolare Effettivo",
// 					"stato": "NUOVA",
// 					"codSezione": "SCHEDA_RACCOLTA_DATI_TITOLARI",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Upload documenti - Titolari effettivi",
// 					"stato": "NUOVA",
// 					"codSezione": "UPLOAD_DOCUMENTO_TITOLARE_EFFETTIVO",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Clienti Professionali persona fisica di riferimento",
// 					"stato": "NUOVA",
// 					"codSezione": "CLIENTE_PROFESSIONALE",
// 					"sottoSezioni": null
// 				}
// 			]
// 		}, {
// 			"nomeSezione": "CENSIMENTO ANAGRAFICO",
// 			"stato": null,
// 			"codSezione": "CENSIMENTO_ANAGRAFICO",
// 			"sottoSezioni": [{
// 					"nomeSezione": "Anagrafica",
// 					"stato": "NUOVA",
// 					"codSezione": "ANAGRAFICA",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Soggetti correlati",
// 					"stato": "NUOVA",
// 					"codSezione": "SOGGETTI_CORRELATI",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "FATCA e CRS",
// 					"stato": "NUOVA",
// 					"codSezione": "FATCA",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Raccolta dati ai fine antiriciclaggio",
// 					"stato": "NUOVA",
// 					"codSezione": "ANTI_RICICLAGGIO",
// 					"sottoSezioni": null
// 				}
// 			]
// 		}, {
// 			"nomeSezione": "QUESTIONARIO MIFID",
// 			"stato": null,
// 			"codSezione": "MIFID",
// 			"sottoSezioni": [{
// 					"nomeSezione": "Conoscenza ed Esperienza",
// 					"stato": "NUOVA",
// 					"codSezione": "CONOSCENZA_ESPERIENZA",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Obiettivi di investimento",
// 					"stato": "NUOVA",
// 					"codSezione": "INVESTIMENTO",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Situazione finanziaria",
// 					"stato": "NUOVA",
// 					"codSezione": "FINANZIARIA",
// 					"sottoSezioni": null
// 				}, {
// 					"nomeSezione": "Altre informazioni aggiuntive (opzionale)",
// 					"stato": "NUOVA",
// 					"codSezione": "ALTRO",
// 					"sottoSezioni": null
// 				}
// 			]
// 		}, {
// 			"nomeSezione": "SOTTOSCRIZIONE SERVIZI",
// 			"stato": null,
// 			"codSezione": "SERVIZI_DIGITALI",
// 			"sottoSezioni": [{
// 					"nomeSezione": "Sottoscrizione servizi digitali",
// 					"stato": "NUOVA",
// 					"codSezione": "SERVIZI_DIGITALI",
// 					"sottoSezioni": null
// 				}
// 			]
// 		}, {
// 			"nomeSezione": "RIEPILOGO CLIENTE",
// 			"stato": null,
// 			"codSezione": "RIEPILOGO",
// 			"sottoSezioni": null
// 		}
// 	],
// 	"profiloRischio": null,
// 	"idPhysicalPersonReference": null
// }

export function retrieveDataSummaryLightPG(idLegalPerson, idPractice) {
  try {
    return axios.get(`/v2/legal-person/data-summary-lite/${idLegalPerson}?idPractice=${idPractice}`)
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'dataSummaryLite'
    throw newError
  }
}
/**
 * Il seguente servizio REST permette di ricevere alcune informazioni (Light version) in merito alla persona giuridica richiesta
 *
 * @param {string} idCliente
 * @param {string} idIncarico
 */
export function getDataSummaryLightPGActionCreator(idLegalPerson, idPractice) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DATA_SUMMARY_LIGHT_PG_START
    })
    try {
      const response = await retrieveDataSummaryLightPG(idLegalPerson, idPractice)
      dispatch({
        type: actionTypes.GET_DATA_SUMMARY_LIGHT_PG_SUCCESS,
        responseApi: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DATA_SUMMARY_LIGHT_PG_ERROR,
        error: error
      })
    }
  }
}
