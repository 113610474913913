import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import {
  checkStatusService,
  getSectionStatus,
  setRandomStr,
  mapDominiToAutocomplete,
  getDominio,
  getAutocompleteDefaultOption
} from '../../ConsulenzaBaseContainerLayout/utils'
import dataSB from '../../../static-data/data-servizio-base.json'
import { Collapsible, CollapsibleItem } from '../../../components/Collapsible'
import { Row, Col, Icon } from 'react-materialize'
import AntiRiciclaggio from '../../ConsulenzaBaseContainerLayout/CensimentoAnagrafico/AntiRiciclaggio'
import AntiRiciclaggioGiuridiche from '../../ConsulenzaBaseContainerLayout/CensimentoAnagrafico/AntiRiciclaggioGiuridiche'
import * as forms from '../../../store/modules/forms/constants'
import fields from '../../ConsulenzaBaseContainerLayout/fields'
import ModalFirma from '../../../components/Modals/Firma'
import ModalGeneric from '../../../components/ModalGeneric'
import FirmaFooter from '../../../components/FirmaFooter'
import dataMain from '../../../static-data/data-main.json'
import AccordionHeader from '../../../components/AccordionHeader'
import { Field } from 'redux-form'
import { renderAutocomplete } from '../../../components/Forms/renderFields'
import ButtonSubmit from '../../../components/Buttons/Submit'

const allFields = fields[7][forms.INFO_GENERALI]

export class CompilazioneAntiriciclaggio extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openCollapsible: forms.INFO_GENERALI,
      loadingComplete: false,
      waitingDomini: false,
      fetchingData: true,
      firmaOra: false,
      showModal: false,
      typeFirma: '',
      later: false,
      showModalGeneric: false,
      textModal: '',
      sectionCompleted: false,
      saved: false,
      isExternal: false
    }

    this.handleSaveAml = this.handleSaveAml.bind(this)
    this.handleSaveAmlPg = this.handleSaveAmlPg.bind(this)
    this.handleOpenCollapsibleChange = this.handleOpenCollapsibleChange.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
    this.updateDomini = this.updateDomini.bind(this)
    this.getSectionStatus = this.getSectionStatus.bind(this)
    this.getOpenCollapsible = this.getOpenCollapsible.bind(this)
    this.setLater = this.setLater.bind(this)
    this.salva = this.salva.bind(this)
    this.modalHandler = this.modalHandler.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.chooseFirma = this.chooseFirma.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.submit = this.submit.bind(this)
    this.isFirmaDisabled = this.isFirmaDisabled.bind(this)
  }

  componentDidMount() {
    const {
      getStatoPratica,
      match: {
        params: { idPratica }
      },
      getPratica
    } = this.props
    getStatoPratica(idPratica).then(() => getPratica(idPratica))
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fetchSurveyAml,
      fetchSurveyAmlPg,
      amlPgSaveRequest,
      statoPratica,
      amlSaveRequest,
      getStatoPratica,
      updatePraticaRequest,
      flag,
      praticaRequest,
      match: {
        params: { idPratica }
      }
    } = this.props
    if (
      praticaRequest &&
      prevProps.praticaRequest &&
      prevProps.praticaRequest.fetching === true &&
      praticaRequest.fetching === false &&
      praticaRequest.error === null &&
      !this.state.loadingComplete
    ) {
      const {
        pratica: { flagIntestatarioPG, intestatari, codicePromoter },
        getFeqAbilitata,
        checkTipoFirmaIntestatari,
        getPromoterCustomerHimself
      } = this.props

      // Modal di warning compilazione
      this.setState({ textModal: 'warning', showModalGeneric: true })

      if (statoPratica.statusEndPoint && !flagIntestatarioPG) {
        fetchSurveyAml(intestatari[0].id, idPratica, checkStatusService(statoPratica.statusEndPoint, 'aml'))
      } else if (flagIntestatarioPG) {
        fetchSurveyAmlPg(intestatari[0].id, idPratica, checkStatusService(statoPratica.statusEndPoint, 'aml'))
      } else {
        this.setState({ loadingComplete: true })
      }

      let _fields = flagIntestatarioPG ? fields[1].ANTI_RICICLAGGIO_PG : fields[1].ANTI_RICICLAGGIO
      _fields = { ..._fields, ...allFields }
      this.updateDomini(
        Object.keys(_fields).reduce((res, cur) => {
          if (_fields[cur].dominio) {
            return [
              ...res,
              {
                idDominio: _fields[cur].dominio
              }
            ]
          }
          return res
        }, [])
      )

      if (/_MC|_MD/.test(intestatari[0].id)) {
        getFeqAbilitata(intestatari[0].codiceClienteEsterno)
        this.setState({ isExternal: true })
      } else {
        checkTipoFirmaIntestatari(idPratica)
        this.setState({ isExternal: false })
      }

      // controllo se il customer è cliente di se stesso
      getPromoterCustomerHimself(codicePromoter, intestatari[0].codiceFiscale)

      this.setState({ waitingDomini: true })
      this.getOpenCollapsible()
    }

    if (
      amlPgSaveRequest &&
      prevProps.amlPgSaveRequest &&
      prevProps.amlPgSaveRequest.fetching === true &&
      amlPgSaveRequest.fetching === false &&
      amlPgSaveRequest.error === null
    ) {
      getStatoPratica(idPratica).then(() => this.getOpenCollapsible())
    }

    if (
      updatePraticaRequest &&
      prevProps.updatePraticaRequest &&
      prevProps.updatePraticaRequest.fetching === true &&
      updatePraticaRequest.fetching === false &&
      updatePraticaRequest.error === null
    ) {
      getStatoPratica(idPratica).then(() => this.getOpenCollapsible())
    }

    if (
      amlSaveRequest &&
      prevProps.amlSaveRequest &&
      prevProps.amlSaveRequest.fetching === true &&
      amlSaveRequest.fetching === false &&
      amlSaveRequest.error === null
    ) {
      getStatoPratica(idPratica).then(() => this.getOpenCollapsible())
    }

    if (
      this.props.amlPgRequest &&
      prevProps.amlPgRequest.fetching &&
      !this.props.amlPgRequest.fetching &&
      this.props.amlPgRequest.error === null
    ) {
      this.setState({ loadingComplete: true })
    }

    if (
      this.props.amlRequest &&
      prevProps.amlRequest.fetching &&
      !this.props.amlRequest.fetching &&
      this.props.amlRequest.error === null
    ) {
      this.setState({ loadingComplete: true })
    }

    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && this.state.waitingDomini) {
      this.setState({ waitingDomini: false })
    }

    if (prevState.openCollapsible !== this.state.openCollapsible && this.state.openCollapsible) {
      setTimeout(
        () =>
          $('.container-home-left').scrollTop(
            $('.container-home-left').scrollTop() +
              ($('.open').offset() !== undefined ? $('.open').offset().top - 50 : 0)
          ),
        100
      )
    }

    if (this.state.firmaOra) {
      setRandomStr()
      if (flag) {
        this.props.history.push(`/firma-cartacea/${idPratica}?module=ar`)
      } else {
        this.props.history.push(`/firma-digitale/${idPratica}?module=ar`)
      }
    }
  }

  updateDominio(idDominio, codice) {
    this.props.retrieveDominio({
      listaDomini: [{ idDominio, filtro: { codice } }]
    })
  }

  updateDomini(listaDomini) {
    this.props.retrieveDominio({ listaDomini })
  }

  handleOpenCollapsibleChange(openCollapsible) {
    this.setState({ openCollapsible })
  }

  handleSaveAml(payload) {
    const {
      pratica: { intestatari, idPratica },
      saveAggAml,
      statoPratica: { statusEndPoint }
    } = this.props
    saveAggAml(intestatari[0].id, idPratica, [...payload], checkStatusService(statusEndPoint, 'aml'))
  }

  handleSaveAmlPg(payload) {
    const {
      pratica: { intestatari, idPratica },
      saveAggAmlPg
    } = this.props
    return saveAggAmlPg(intestatari[0].id, idPratica, [...payload], false)
  }

  getSectionStatus(index) {
    return getSectionStatus(this.props.statoPratica, 0, index)
  }

  getOpenCollapsible() {
    const {
      statoPratica: { sezioni },
      templateAml
    } = this.props
    let openCollapsible = ''
    for (let i = 0; i < sezioni[0].sottoSezioni.length; i++) {
      if (this.getSectionStatus(i) !== 'COMPLETATA') {
        openCollapsible = sezioni[0].sottoSezioni[i].codSezione
        break
      }
    }
    if (openCollapsible === 'AGGIORNAMENTO_AML') {
      openCollapsible = templateAml ? forms.ANTIRICICLAGGIO : forms.ANTIRICICLAGGIO_GIURIDICHE
    }
    this.setState({ openCollapsible, sectionCompleted: !openCollapsible })
  }

  setLater(value) {
    this.setState({ later: value })
  }

  async salva(firmaOra = false) {
    const {
      statoPratica: { admin },
      pratica: { idPratica },
      updateStatoPratica,
      flag
    } = this.props
    if (admin) {
      this.setState({ textModal: 'firma', showModalGeneric: true })
    } else {
      await updateStatoPratica(idPratica, 2, flag)
      this.setState({ firmaOra })
      this.props.history.push('/')
    }
  }

  modalHandler() {
    const {
      statoPratica: { admin }
    } = this.props
    if (admin) {
      this.setState({ textModal: 'firma', showModalGeneric: true })
    } else {
      this.setState({ typeFirma: '' })
      this.setState({ showModal: !this.state.showModal })
    }
  }

  closeModal() {
    this.setState({ showModalGeneric: false })
  }

  chooseFirma(selected, unselected) {
    this.setState({ typeFirma: selected })
    if (selected === 'firma') this.props.tipoFirma(false)
    else this.props.tipoFirma(true)

    // border box
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[0].style.border = '2px solid #083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[0].style.border = '2px solid #dadada'
    // icon
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[1].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[1].style.color = '#616166'
    // title
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[2].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[2].style.color = '#616166'
  }

  async nextStep() {
    const {
      pratica: { idPratica },
      updatePratica,
      flag
    } = this.props
    await updatePratica(idPratica, { flagFirmaCartacea: flag })
    this.setState({ firmaOra: true })
  }

  submit() {
    const {
      pratica: { idPratica },
      formValues,
      updatePratica
    } = this.props

    const { COMUNE_FIRMA } = allFields
    updatePratica(idPratica, {
      codiceComuneFirma: { chiave: formValues[COMUNE_FIRMA.id], codiceDominio: COMUNE_FIRMA.dominio },
      idTipoPratica: 4,
      idStatoPratica: 1
    })
    this.setState({ saved: true })
  }

  isFirmaDisabled() {
    const { feqAbilitata, flagDigitalSignatureEnabled, isPromoterCustomerHimself } = this.props
    if (this.state.isExternal) {
      return !feqAbilitata || isPromoterCustomerHimself
    }
    return !flagDigitalSignatureEnabled || isPromoterCustomerHimself
  }

  render() {
    const {
      templateAml,
      templateAmlPg,
      domini,
      pratica: { flagIntestatarioPG, idPratica },
      updateStatoPratica,
      match,
      flag,
      dominioRequest,
      amlPreviousForm,
      amlPgPreviousForm,
      untouch,
      isDirty,
      change,
      submitting,
      isFetching,
      invalid,
      handleSubmit,
      pratica: { codiceComuneFirma = {} },
      isPromoterCustomerHimself
    } = this.props
    const { COMUNE_FIRMA } = allFields

    if (this.state.waitingDomini || !this.state.loadingComplete) {
      return null
    }
    return (
      <section className="main-body consulenza-base compilazione-antiriciclaggio">
        {this.state.showModalGeneric && (
          <ModalGeneric
            show={this.state.showModalGeneric && this.state.loadingComplete}
            handler={this.closeModal}
            title="Attenzione!"
            text={dataMain.check_intestatari[this.state.textModal]}
          />
        )}
        {this.state.showModal && (
          <ModalFirma
            later={this.state.later}
            handler={this.modalHandler}
            save={this.salva}
            updateStatoPratica={updateStatoPratica}
            nextStep={this.nextStep}
            title={<p className="onboarding-modale-content__title">Area di firma</p>}
            text={
              <p className="onboarding-modale-content__subtitle">
                Scegli tra <b>Firma Digitale</b> e <b>Firma Cartacea.</b> Seleziona la modalità desiderata.
              </p>
            }
            url={match.url}
            idPratica={idPratica}
            typeFirma={this.state.typeFirma}
            flag={flag}
            {...this.props}>
            <Row>
              <Col s={6} className="center-align">
                <div
                  disabled={this.isFirmaDisabled()}
                  className="onboarding-modale-icon onboarding-modale-icon__firma"
                  onClick={() => {
                    if (!this.isFirmaDisabled()) {
                      this.chooseFirma('firma', 'firma-cartacea')
                    }
                  }}>
                  <Icon className="onboarding-modale-icon__firma icon-icone-ips_sv-spesa" medium>
                    &#xe917;
                  </Icon>
                  <p className="onboarding-modale-icon__firma onboarding-modale-icon__title">FIRMA DIGITALE</p>
                </div>
              </Col>
              <Col s={6} className="center-align">
                <div
                  className="onboarding-modale-icon onboarding-modale-icon__firma-cartacea"
                  onClick={() => this.chooseFirma('firma-cartacea', 'firma')}>
                  <Icon className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa" medium>
                    &#xe918;
                  </Icon>
                  <p className="onboarding-modale-icon__firma-cartacea onboarding-modale-icon__title">FIRMA CARTACEA</p>
                </div>
              </Col>
            </Row>
          </ModalFirma>
        )}
        <article className="page-left-content">
          <Collapsible open={this.state.openCollapsible} onChange={this.handleOpenCollapsibleChange}>
            <CollapsibleItem
              itemKey={forms.INFO_GENERALI}
              header="Informazioni Generali"
              icon="keyboard_arrow_down"
              labelInfo={AccordionHeader(isDirty, this.state.saved, this.getSectionStatus(0))}>
              <form onSubmit={handleSubmit(this.submit)} noValidate>
                <Row>
                  <Col s={6}>
                    <Field
                      name={COMUNE_FIRMA.id}
                      s={12}
                      threshold={0}
                      label={COMUNE_FIRMA.label}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        COMUNE_FIRMA.dominio,
                        codiceComuneFirma.chiave
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, allFields.COMUNE_FIRMA.dominio))}
                      handleChange={(field, value) => change(field, value)}
                      component={renderAutocomplete}
                    />
                  </Col>
                </Row>
                <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching} />
              </form>
            </CollapsibleItem>
            {flagIntestatarioPG && (
              <Fragment>
                <AntiRiciclaggioGiuridiche
                  status={this.getSectionStatus(1)}
                  template={templateAmlPg.sezioni[0]}
                  domini={domini}
                  dominioRequest={dominioRequest}
                  onSave={this.handleSaveAmlPg}
                  previousForm={amlPgPreviousForm}
                  resetForm={() => {}}
                  untouch={untouch}
                />
              </Fragment>
            )}
            {!flagIntestatarioPG && (
              <Fragment>
                <AntiRiciclaggio
                  status={this.getSectionStatus(1)}
                  template={templateAml.sezioni[0]}
                  domini={domini}
                  dominioRequest={dominioRequest}
                  onSave={this.handleSaveAml}
                  previousForm={amlPreviousForm}
                  resetForm={() => {}}
                />
              </Fragment>
            )}
          </Collapsible>
          <FirmaFooter
            onSaveText={dataSB.footer_firma}
            save={this.salva}
            openModal={() => {
              // check cliente di se stesso
              if (isPromoterCustomerHimself) {
                this.setState({ textModal: 'self', showModalGeneric: true })
              }
              return this.modalHandler()
            }}
            call_back={this.setLater}
            dinamicClass="colorBlue"
            isDisabled={!this.state.sectionCompleted}
          />
        </article>
      </section>
    )
  }
}

CompilazioneAntiriciclaggio.propTypes = {
  retrieveDominio: PropTypes.func,
  dominioRequest: PropTypes.object,
  customer: PropTypes.object,
  fetchSurveyAml: PropTypes.func,
  templateAml: PropTypes.object,
  templateAmlPg: PropTypes.object,
  domini: PropTypes.object,
  amlPreviousForm: PropTypes.object,
  amlPgPreviousForm: PropTypes.object,
  saveAggAml: PropTypes.func,
  saveAggAmlPg: PropTypes.func,
  fetchSurveyAmlPg: PropTypes.func,
  amlPgRequest: PropTypes.object,
  amlPgSaveRequest: PropTypes.object,
  idPratica: PropTypes.object,
  untouch: PropTypes.func,
  getStatoPratica: PropTypes.func,
  history: PropTypes.object,
  statoPratica: PropTypes.object,
  flag: PropTypes.bool,
  amlSaveRequest: PropTypes.object,
  amlRequest: PropTypes.object,
  tipoFirma: PropTypes.func,
  updateStatoPratica: PropTypes.func,
  match: PropTypes.object,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  updatePraticaRequest: PropTypes.object,
  getPratica: PropTypes.func,
  formValues: PropTypes.object,
  updatePratica: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  pratica: PropTypes.object,
  praticaRequest: PropTypes.object,
  flagDigitalSignatureEnabled: PropTypes.bool,
  checkTipoFirmaIntestatari: PropTypes.func,
  getFeqAbilitata: PropTypes.func,
  feqAbilitata: PropTypes.bool,
  getPromoterCustomerHimself: PropTypes.func,
  isPromoterCustomerHimself: PropTypes.bool
}

export default CompilazioneAntiriciclaggio
