import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty, getFormInitialValues } from 'redux-form'
import { SCHEDA_DATI } from '../../../../store/modules/forms/constants'
import { setEnabledTitolare, setMultidTitolare } from '../../../../store/modules/utility/actions'
import {
  getDataVerificaClienti,
  postDataVerificaClienti
} from '../../../../store/modules/personaGiuridica/client/actions'

import { getInitialValues, notBelongToDominio } from '../../utils'
import SchedaDati from './SchedaDatiComponent'
import allFields from '../../fields'

const fields = allFields[0][SCHEDA_DATI]

// const defaultFields = loadDefaultFields(fields)
// const fieldsPep = {
//   NOME_0,
// COGNOME_0,
// CODICE_FISCALE_0,
// TITOLARE_EFFETTIVO_PEP_0,
// RADIO_NO_PEP_0,

// TIPO_CLIENTE_0,
// INTESTATARIO_0,
// RAGIONE_0,
// CONFERMA_ESSERE_0,
// TIPO_CLIENTE_1_0,
// PEP_IN_QUANTO_0,
// PEP_FAMILIARE_0,
// PEP_REDDITO_0,
// PEP_PARTECIPAZIONI_0,
// PEP_PROCEDIMENTI_0
// } = fields

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const {
    ANNO_INIZIO,
    PROFESSIONE_ATTUALE,
    INDIRIZZO_RESIDENZA,
    NAZIONE_NASCITA_SCHEDA_DATI,
    PROVINCIA_NASCITA_SCHEDA_DATI,
    NAZIONE_SCHEDA_DATI,
    PROVINCIA_SCHEDA_DATI,
    COMUNE_SCHEDA_DATI,
    CAP_SCHEDA_DATI,
    CITTADINANZA_SCHEDA_DATI,
    LUOGO_RILASCIO,
    NAZIONE_ATTIVITA,
    PROVINCIA_ATTIVITA
  } = fields

  if (
    !!values[NAZIONE_NASCITA_SCHEDA_DATI.id] &&
    notBelongToDominio(domini, NAZIONE_NASCITA_SCHEDA_DATI.dominio, values[NAZIONE_NASCITA_SCHEDA_DATI.id])
  ) {
    errors[NAZIONE_NASCITA_SCHEDA_DATI.id] = 'Nazione non riconosciuta'
  }

  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI.id] &&
    notBelongToDominio(domini, PROVINCIA_NASCITA_SCHEDA_DATI.dominio, values[PROVINCIA_NASCITA_SCHEDA_DATI.id])
  ) {
    errors[PROVINCIA_NASCITA_SCHEDA_DATI.id] = 'Luogo non riconosciuto'
  }

  if (
    !!values[NAZIONE_SCHEDA_DATI.id] &&
    notBelongToDominio(domini, NAZIONE_SCHEDA_DATI.dominio, values[NAZIONE_SCHEDA_DATI.id])
  ) {
    errors[NAZIONE_SCHEDA_DATI.id] = 'Nazione non riconosciuta'
  }

  if (
    !!values[PROVINCIA_SCHEDA_DATI.id] &&
    notBelongToDominio(domini, PROVINCIA_SCHEDA_DATI.dominio, values[PROVINCIA_SCHEDA_DATI.id])
  ) {
    errors[PROVINCIA_SCHEDA_DATI.id] = 'Provincia non riconosciuta'
  }

  if (
    !!values[COMUNE_SCHEDA_DATI.id] &&
    notBelongToDominio(domini, COMUNE_SCHEDA_DATI.dominio, values[COMUNE_SCHEDA_DATI.id])
  ) {
    errors[COMUNE_SCHEDA_DATI.id] = 'Comune non riconosciuto'
  }

  if (!!values[CAP_SCHEDA_DATI.id] && notBelongToDominio(domini, CAP_SCHEDA_DATI.dominio, values[CAP_SCHEDA_DATI.id])) {
    errors[CAP_SCHEDA_DATI.id] = 'CAP non riconosciuto'
  }

  if (
    !!values[CITTADINANZA_SCHEDA_DATI.id] &&
    notBelongToDominio(domini, CITTADINANZA_SCHEDA_DATI.dominio, values[CITTADINANZA_SCHEDA_DATI.id])
  ) {
    errors[CITTADINANZA_SCHEDA_DATI.id] = 'Cittadinanza non riconosciuta'
  }

  if (!!values[LUOGO_RILASCIO.id] && notBelongToDominio(domini, LUOGO_RILASCIO.dominio, values[LUOGO_RILASCIO.id])) {
    errors[LUOGO_RILASCIO.id] = 'Luogo non riconosciuto'
  }

  if (
    !!values[NAZIONE_ATTIVITA.id] &&
    notBelongToDominio(domini, NAZIONE_ATTIVITA.dominio, values[NAZIONE_ATTIVITA.id])
  ) {
    errors[NAZIONE_ATTIVITA.id] = 'Nazione non riconosciuta'
  }

  if (
    !!values[PROVINCIA_ATTIVITA.id] &&
    notBelongToDominio(domini, PROVINCIA_ATTIVITA.dominio, values[PROVINCIA_ATTIVITA.id])
  ) {
    errors[PROVINCIA_ATTIVITA.id] = 'Provincia non riconosciuta'
  }

  if (values[PROFESSIONE_ATTUALE.id] === fields.PROFESSIONE_ATTUALE.liberoProfId) {
    if (!!values[ANNO_INIZIO.id] && !new RegExp(ANNO_INIZIO.validazione).test(values[ANNO_INIZIO.id])) {
      errors[ANNO_INIZIO.id] = 'Formato data non valida'
    }
    if (values[ANNO_INIZIO.id].length !== 4) {
      errors[ANNO_INIZIO.id] = 'Formato data non valida'
    }
    if (parseInt(values[ANNO_INIZIO.id]) > new Date().getFullYear()) {
      errors[ANNO_INIZIO.id] = "L'anno inserito è successivo alla data odierna"
    }
  }

  if (
    !!values[INDIRIZZO_RESIDENZA.id] &&
    !new RegExp(INDIRIZZO_RESIDENZA.validazione).test(values[INDIRIZZO_RESIDENZA.id])
  ) {
    errors[INDIRIZZO_RESIDENZA.id] = 'è possibile inserire solo caratteri alfanumerici'
  }

  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEnabledTitolare,
      setMultidTitolare,
      getDataVerificaClienti,
      postDataVerificaClienti
    },
    dispatch
  )
const loadData = (fields, obj) => {
  let objToSend = getInitialValues(fields, obj)

  // setMultidTitolare( obj.isUniqueOwner )
  // setEnabledTitolare( obj.isOwner )
  objToSend.isOwner = obj.isOwner
  objToSend.isUniqueOwner = obj.isUniqueOwner

  return objToSend
}

// const loadDataPep = (fields, obj) => {
//   if (obj === null){
//     return
//   }
//   let objToSend = getInitialValuesPep(fields, obj)
//   console.log(objToSend)
//   return objToSend
// }

const loadInitialValues = state => {
  return {
    ...loadData(fields, state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti),
    [fields.TITOLARE_EFFETTIVO_PEP_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .isTitolareEffettivoPEP,
    [fields.TIPO_CLIENTE_PEP_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .tipologiaCliente,
    [fields.INTESTATARIO_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .isIntestatario,
    [fields.CONFERMA_ESSERE_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .confermaDiEssereDescrizione,
    [fields.PEP_IN_QUANTO_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .pepInQuanto,
    [fields.PEP_FAMILIARE_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .pepRelazione,
    [fields.PEP_PROCEDIMENTI_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .pepProcedimenti,
    [fields.PEP_PARTECIPAZIONI_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .pepPartecipazioni,
    [fields.PEP_REDDITO_0.id]: (state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pepInfo || {})
      .tipoFasciaRedditoPep
  }
}

const mapStateToProps = state => {
  return {
    initialValues: {
      ...loadInitialValues(state)
      // ...getData(fields, state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti.pep)
    },
    formValues: getFormValues(SCHEDA_DATI)(state),
    formErrors: getFormSyncErrors(SCHEDA_DATI)(state),
    dataNascitaCF: state.customers.dataRegistry.dataNascita || state.customers.customer.dataNascita,
    currentInitial: getFormInitialValues(SCHEDA_DATI)(state),
    fields,
    isDirty: isDirty(SCHEDA_DATI)(state),
    dataVerificaClienti: state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti,
    domini: state.dominio.domini,
    isFetching: state.anagraficaPersonaGiuridicaCliente.getDataVerificaClientiRequest.fetching,
    titolare: state.utility.titolare,
    apiError: state.customers.postDataAllegatiDOCRequest.error,
    idPhysicalPersonReference: state.prospectPersonaGiuridica.summaryData.idPhysicalPersonReference,
    dominioRequest: state.dominio.dominioRequest,
    customer: state.customers.customer

    // flagCustomerIsBlacklist:
    //   state.customers.documentsData.flagCustomerIsBlacklist === false ||
    //   state.customers.documentsData.flagCustomerIsBlacklist === true
    //     ? state.customers.documentsData.flagCustomerIsBlacklist
    //     : state.customers.flagCustomerIsBlacklist
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: SCHEDA_DATI,
    enableReinitialize: true,
    validate,
    shouldError: () => isDirty(SCHEDA_DATI)
  })(SchedaDati)
)
