export const POST_CF_PF_START = 'POST_CF_PF_START'
export const POST_CF_PF_SUCCESS = 'POST_CF_PF_SUCCESS'
export const POST_CF_PF_ERROR = 'POST_CF_PF_ERROR'

export const POST_DOC_ID_PF_START = 'POST_DOC_ID_PF_START'
export const POST_DOC_ID_PF_SUCCESS = 'POST_DOC_ID_PF_SUCCESS'
export const POST_DOC_ID_PF_ERROR = 'POST_DOC_ID_PF_ERROR'

export const GET_DOC_ID_PF_START = 'GET_DOC_ID_PF_START'
export const GET_DOC_ID_PF_SUCCESS = 'GET_DOC_ID_PF_SUCCESS'
export const GET_DOC_ID_PF_ERROR = 'GET_DOC_ID_PF_ERROR'

export const GET_DOC_ID_PG_START = 'GET_DOC_ID_PG_START'
export const GET_DOC_ID_PG_SUCCESS = 'GET_DOC_ID_PG_SUCCESS'
export const GET_DOC_ID_PG_ERROR = 'GET_DOC_ID_PG_ERROR'

export const GET_ANAGRAFICA_PF_START = 'GET_ANAGRAFICA_PF_START'
export const GET_ANAGRAFICA_PF_SUCCESS = 'GET_ANAGRAFICA_PF_SUCCESS'
export const GET_ANAGRAFICA_PF_ERROR = 'GET_ANAGRAFICA_PF_ERROR'

export const GET_ANAGRAFICA_PG_START = 'GET_ANAGRAFICA_PG_START'
export const GET_ANAGRAFICA_PG_SUCCESS = 'GET_ANAGRAFICA_PG_SUCCESS'
export const GET_ANAGRAFICA_PG_ERROR = 'GET_ANAGRAFICA_PG_ERROR'

export const POST_ANAGRAFICA_PF_START = 'POST_ANAGRAFICA_PF_START'
export const POST_ANAGRAFICA_PF_SUCCESS = 'POST_ANAGRAFICA_PF_SUCCESS'
export const POST_ANAGRAFICA_PF_ERROR = 'POST_ANAGRAFICA_PF_ERROR'

export const POST_DOC_ID_PG_START = 'POST_DOC_ID_PG_START'
export const POST_DOC_ID_PG_SUCCESS = 'POST_DOC_ID_PG_SUCCESS'
export const POST_DOC_ID_PG_ERROR = 'POST_DOC_ID_PG_ERROR'

export const POST_DOC_ID_PERSONE_G_START = 'POST_DOC_ID_PERSONE_G_START'
export const POST_DOC_ID_PERSONE_G_SUCCESS = 'POST_DOC_ID_PERSONE_G_SUCCESS'
export const POST_DOC_ID_PERSONE_G_ERROR = 'POST_DOC_ID_PERSONE_G_ERROR'

export const POST_FATCA_START = 'POST_FATCA_START'
export const POST_FATCA_SUCCESS = 'POST_FATCA_SUCCESS'
export const POST_FATCA_ERROR = 'POST_FATCA_ERROR'

export const GET_FATCA_START = 'GET_FATCA_START'
export const GET_FATCA_SUCCESS = 'GET_FATCA_SUCCESS'
export const GET_FATCA_ERROR = 'GET_FATCA_ERROR'

export const POST_DATA_VERIFICA_CLIENTI_START = 'POST_DATA_VERIFICA_CLIENTI_START'
export const POST_DATA_VERIFICA_CLIENTI_SUCCESS = 'POST_DATA_VERIFICA_CLIENTI_SUCCESS'
export const POST_DATA_VERIFICA_CLIENTI_ERROR = 'POST_DATA_VERIFICA_CLIENTI_ERROR'

export const GET_DATA_VERIFICA_CLIENTI_START = 'GET_DATA_VERIFICA_CLIENTI_START'
export const GET_DATA_VERIFICA_CLIENTI_SUCCESS = 'GET_DATA_VERIFICA_CLIENTI_SUCCESS'
export const GET_DATA_VERIFICA_CLIENTI_ERROR = 'GET_DATA_VERIFICA_CLIENTI_ERROR'

export const SET_CURRENT_FORM = 'SET_CURRENT_FORM'

export const GET_DATA_REGISTRY_PG_START = 'GET_DATA_REGISTRY_PG_START'
export const GET_DATA_REGISTRY_PG_SUCCESS = 'GET_DATA_REGISTRY_PG_SUCCESS'
export const GET_DATA_REGISTRY_PG_ERROR = 'GET_DATA_REGISTRY_PG_ERROR'
