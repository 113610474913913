import React from 'react'
import PropTypes from 'prop-types'

const ReadOnlyInput = ({ label, value }) => (
  <div className="read-only-input">
    <label>{label}</label>
    <div>{value}</div>
  </div>
)

ReadOnlyInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default ReadOnlyInput
