import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-rangeslider'
import { Row, Col } from 'react-materialize'

class SliderComponent extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      value: 1
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(value) {
    this.props.onChange(value)
  }

  render() {
    const { value, error } = this.props

    if (!value && value !== 0) {
      return null
    }

    return (
      <div className="slider">
        <Row>
          <Col s={2}>
            <input type="text" value={value} onBlur={this.handleInputBlur} onChange={() => {}} />
          </Col>
          <Col s={8}>
            <Slider min={this.props.min} max={this.props.max} value={value} onChange={this.handleChange} />
          </Col>
        </Row>
        {error && <span className="error-msg">{error}</span>}
      </div>
    )
  }
}
export default SliderComponent

SliderComponent.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string
}
