import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { Field } from 'redux-form'
import {
  mapDominiToAutocomplete,
  getAutocompleteDefaultOption,
  getDominio,
  getValueDominioFromKey
} from '../../../routes/ConsulenzaBaseContainerLayout/utils'
import { renderAutocomplete } from '../renderFields'
import InputText from '../../Inputs/Text'

class DatiGiuridici extends Component {
  constructor(props) {
    super(props)
    this.state = { waitingCap: false }
  }

  componentDidUpdate(prevProps) {
    const {
      fields: { CAP_PG },
      change,
      dominioRequest,
      domini
    } = this.props
    if (prevProps.dominioRequest.fetching && !dominioRequest.fetching && this.state.waitingCap) {
      const capDom = domini.listaDomini.find(d => d.idDominio === CAP_PG.dominio)
      if (capDom && Object.keys(capDom.valoriDominio).length === 1) {
        change(CAP_PG.id, Object.keys(capDom.valoriDominio)[0])
      }
      this.setState({ waitingCap: false })
    }
  }

  checkNazione(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { NAZIONE_PG }
    } = this.props
    if (isItalia) {
      return formValues[NAZIONE_PG.id] === NAZIONE_PG.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, NAZIONE_PG.dominio, formValues[NAZIONE_PG.id])
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA_PG }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, PROVINCIA_PG.dominio, value)
  }

  checkComune(value) {
    const {
      domini: { listaDomini },
      fields: { CITTA_PG }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, CITTA_PG.dominio, value)
  }

  checkCap() {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA_PG, CITTA_PG, CAP_PG },
      formValues
    } = this.props
    return (
      !this.checkNazione(true) ||
      !this.checkProvincia(formValues[PROVINCIA_PG.id]) ||
      !this.checkComune(formValues[CITTA_PG.id]) ||
      Object.keys((listaDomini.find(d => d.idDominio === CAP_PG.dominio) || {}).valoriDominio || {}).length === 1
    )
  }

  render() {
    const {
      domini,
      updateDominio,
      formValues,
      formErrors,
      nameDisabled = false,
      fields: {
        NAZIONE_PG,
        PROVINCIA_PG,
        CITTA_PG,
        SEDE_LEGALE_PG,
        CAP_PG,
        CIVICO,
        NOME,
        RAGIONE_SOCIALE_PG,
        CITTA_ESTERO_PG
      },
      change,
      touch
    } = this.props
    return (
      <Fragment>
        {NOME && RAGIONE_SOCIALE_PG && (
          <Row>
            <Field
              name={NOME.id}
              s={6}
              disabled={nameDisabled}
              label={NOME.label}
              error={formErrors[NOME.id]}
              value={formValues[NOME.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
            <Field
              name={RAGIONE_SOCIALE_PG.id}
              s={6}
              disabled={nameDisabled}
              label={RAGIONE_SOCIALE_PG.label}
              error={formErrors[RAGIONE_SOCIALE_PG.id]}
              value={formValues[RAGIONE_SOCIALE_PG.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          </Row>
        )}
        <Row>
          <Field
            name={SEDE_LEGALE_PG.id}
            s={6}
            maxLength={40}
            label={SEDE_LEGALE_PG.label}
            error={formErrors[SEDE_LEGALE_PG.id]}
            value={formValues[SEDE_LEGALE_PG.id]}
            change={change}
            touch={touch}
            component={InputText}
          />
          {CIVICO && (
            <Field
              name={CIVICO.id}
              s={6}
              maxLength={40}
              label={CIVICO.label}
              error={formErrors[CIVICO.id]}
              value={formValues[CIVICO.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          )}
        </Row>
        <Row>
          <Col s={6}>
            <Field
              name={NAZIONE_PG.id}
              s={12}
              label={NAZIONE_PG.label}
              threshold={0}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                NAZIONE_PG.dominio,
                formValues[NAZIONE_PG.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_PG.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[PROVINCIA_PG.id]) {
                  change(PROVINCIA_PG.id, '')
                }
                if (formValues[CITTA_PG.id]) {
                  change(CITTA_PG.id, '')
                }
                if (formValues[CITTA_ESTERO_PG.id]) {
                  change(CITTA_ESTERO_PG.id, '')
                }
                if (formValues[CAP_PG.id]) {
                  change(CAP_PG.id, '')
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
          <Col s={6}>
            <Field
              name={PROVINCIA_PG.id}
              s={12}
              label={PROVINCIA_PG.label}
              threshold={0}
              disabled={!this.checkNazione(true)}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                PROVINCIA_PG.dominio,
                formValues[PROVINCIA_PG.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_PG.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[CITTA_PG.id]) {
                  change(CITTA_PG.id, '')
                }
                if (formValues[CAP_PG.id]) {
                  change(CAP_PG.id, '')
                }
                if (this.checkProvincia(value)) {
                  updateDominio(CITTA_PG.dominio, value)
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
        <Row>
          {this.checkNazione(true) && (
            <Col s={6}>
              <Field
                name={CITTA_PG.id}
                s={12}
                threshold={0}
                label={CITTA_PG.label}
                disabled={!this.checkProvincia(formValues[PROVINCIA_PG.id])}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  CITTA_PG.dominio,
                  formValues[CITTA_PG.id]
                )}
                options={
                  formValues[PROVINCIA_PG.id] ? mapDominiToAutocomplete(getDominio(domini, CITTA_PG.dominio)) : []
                }
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[CAP_PG.id]) {
                    change(CAP_PG.id, '')
                  }
                  if (this.checkComune(value)) {
                    this.setState({ waitingCap: true })
                    updateDominio(CAP_PG.dominio, value)
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
          )}
          {!this.checkNazione(true) && (
            <Field
              name={CITTA_ESTERO_PG.id}
              s={6}
              maxLength={50}
              disabled={!this.checkNazione()}
              label={CITTA_ESTERO_PG.label}
              error={formErrors[CITTA_ESTERO_PG.id]}
              value={formValues[CITTA_ESTERO_PG.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          )}

          <Col s={3} offset="s3">
            <Field
              name={CAP_PG.id}
              s={12}
              threshold={0}
              label={CAP_PG.label}
              disabled={this.checkCap()}
              defaultValue={getAutocompleteDefaultOption(domini.listaDomini, CAP_PG.dominio, formValues[CAP_PG.id])}
              options={formValues[CITTA_PG.id] ? mapDominiToAutocomplete(getDominio(domini, CAP_PG.dominio)) : []}
              handleChange={change}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }
}
export default DatiGiuridici

DatiGiuridici.propTypes = {
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  nameDisabled: PropTypes.bool,
  domini: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  updateDominio: PropTypes.func,
  dominioRequest: PropTypes.object,
  fields: PropTypes.shape({
    NOME: PropTypes.object,
    RAGIONE_SOCIALE_PG: PropTypes.object,
    SEDE_LEGALE_PG: PropTypes.object.isRequired,
    NAZIONE_PG: PropTypes.object.isRequired,
    PROVINCIA_PG: PropTypes.object.isRequired,
    CITTA_PG: PropTypes.object.isRequired,
    CAP_PG: PropTypes.object.isRequired
  })
}
