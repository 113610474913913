export const PRIVACY_PROCURATORE_CHECK_START = 'PRIVACY_PROCURATORE_CHECK_START'
export const PRIVACY_PROCURATORE_CHECK_SUCCESS = 'PRIVACY_PROCURATORE_CHECK_SUCCESS'
export const PRIVACY_PROCURATORE_CHECK_ERROR = 'PRIVACY_PROCURATORE_CHECK_ERROR'

export const PRIVACY_PROCURATORE_SEND_START = 'PRIVACY_PROCURATORE_SEND_START'
export const PRIVACY_PROCURATORE_SEND_SUCCESS = 'PRIVACY_PROCURATORE_SEND_SUCCESS'
export const PRIVACY_PROCURATORE_SEND_ERROR = 'PRIVACY_PROCURATORE_SEND_ERROR'

export const CONFIRM_PAPER_PRIVACY_PROCURATORE_START = 'CONFIRM_PAPER_PRIVACY_PROCURATORE_START'
export const CONFIRM_PAPER_PRIVACY_PROCURATORE_SUCCESS = 'CONFIRM_PAPER_PRIVACY_PROCURATORE_SUCCESS'
export const CONFIRM_PAPER_PRIVACY_PROCURATORE_ERROR = 'CONFIRM_PAPER_PRIVACY_PROCURATORE_ERROR'

export const SAVE_DATA_PROCURATOR_START = 'SAVE_DATA_PROCURATOR_START'
export const SAVE_DATA_PROCURATOR_SUCCESS = 'SAVE_DATA_PROCURATOR_SUCCESS'
export const SAVE_DATA_PROCURATOR_ERROR = 'SAVE_DATA_PROCURATOR_ERROR'

export const MODULO_PROCURA_GET_START = 'MODULO_PROCURA_GET_START'
export const MODULO_PROCURA_GET_SUCCESS = 'MODULO_PROCURA_GET_SUCCESS'
export const MODULO_PROCURA_GET_ERROR = 'MODULO_PROCURA_GET_ERROR'

export const PROCURATOR_CHECK_CF_START = 'PROCURATOR_CHECK_CF_START'
export const PROCURATOR_CHECK_CF_SUCCESS = 'PROCURATOR_CHECK_CF_SUCCESS'
export const PROCURATOR_CHECK_CF_ERROR = 'PROCURATOR_CHECK_CF_ERROR'

export const OVERRIDE_PROCURATOR_CHECK_CF_START = 'OVERRIDE_PROCURATOR_CHECK_CF_START'
export const OVERRIDE_PROCURATOR_CHECK_CF_SUCCESS = 'OVERRIDE_PROCURATOR_CHECK_CF_SUCCESS'
export const OVERRIDE_PROCURATOR_CHECK_CF_ERROR = 'OVERRIDE_PROCURATOR_CHECK_CF_ERROR'

export const DELETE_PROCURATORE = 'DELETE_PROCURATORE'
