import moment from 'moment'

export const KEYS = {
  ENTER: 'Enter'
}

export const checkInvalidBirthdate = date => {
  return date.isAfter(moment().subtract(18, 'y')) || date.isBefore(moment().subtract(500, 'y'))
}

export const checkInvalidDocumentDate = date => {
  return date.isBefore(moment().subtract(500, 'y'))
}
