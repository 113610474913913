export default {
  NOME: {
    id: 'nome',
    label: 'Nome*',
    defaultValue: '',
    maxLength: 25,
    validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
  },
  COGNOME: {
    id: 'cognome',
    label: 'Cognome*',
    defaultValue: '',
    maxLength: 50,
    validazione: /^[a-zA-ZÀÁÈÉÌÍÒÓÙÚà-ž\s'’-]*$/g
  },
  CODICE_FISCALE: {
    id: 'codiceFiscale',
    label: 'Codice Fiscale*',
    defaultValue: '',
    minLength: 16,
    maxLength: 16,
    validazione: /^[a-zA-Z0-9]*$/g
  },
  SESSO: {
    id: 'sesso',
    label: 'Sesso',
    defaultValue: ''
  },
  EMAIL: {
    id: 'email',
    label: 'Email*',
    defaultValue: '',
    validazione: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
  },
  NAZIONE_NASCITA: {
    id: 'nationBirthCode',
    label: 'Nazione di nascita*',
    defaultValue: '',
    dominio: 'DOM_NAZIONE_NASCITA',
    dominioDep: null,
    italiaId: 'ITA',
    statiUnitiId: 'USA',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  PROVINCIA_NASCITA: {
    id: 'provinceBirthCode',
    label: 'Provincia di nascita*',
    defaultValue: '',
    dominio: 'DOM_PROVINCE_NASCITA',
    dominioDep: null,
    validazione: /^[a-zA-Z0-9]*$/g
  },
  COMUNE_NASCITA: {
    id: 'cityBirthCode',
    label: 'Comune di nascita*',
    defaultValue: '',
    dominio: 'DOM_COMUNE_NASCITA',
    dominioDep: 'provinceBirthCode',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  DATA_NASCITA: {
    id: 'dataNascita',
    label: 'Data di nascita*',
    defaultValue: ''
  },
  ULTERIORI_DATI: {
    id: 'ulterioriDati',
    defaultValue: false
  },
  NAZIONALITA_ULTERIORI_DATI: {
    id: 'codNazionalitaUlterioriDati',
    label: 'Nazionalità*',
    defaultValue: '',
    dominio: 'DOM_CITTADINANZE',
    dominioDep: null,
    // altroId dovrebbe essere ITA ma al momento non abbiamo segnalazioni a riguardo
    altroId: '4',
    statiUnitiId: 'USA',
    italiaId: 'ITA',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  RESIDENZA_ANAGRAFICA_ULTERIORI_DATI: {
    id: 'codResidenzaAnagraficaUlterioriDati',
    label: 'Residenza Anagrafica*',
    defaultValue: 'ITA',
    dominio: 'DOM_NAZIONE_RESIDENZA',
    dominioDep: null,
    italiaId: 'ITA',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  COMUNE_NASCITA_ULTERIORI_DATI: {
    id: 'codComuneNascitaUlterioriDati',
    label: 'Comune di nascita*',
    defaultValue: '',
    dominio: 'DOM_COMUNE_NASCITA_TE1',
    dominioDep: 'provinceBirthCode',
    validazione: /^[a-zA-Z0-9]*$/g
  },
  COMUNE_NASCITA_ESTERO_ULTERIORI_DATI: {
    id: 'comuneEsteroNascitaUlterioriDati',
    label: 'Comune di nascita Estero*',
    defaultValue: '',
    validazione: /^[a-zA-Z\s]*$/g
  },
  DATA_NASCITA_ULTERIORI_DATI: {
    id: 'dataNascitaUlterioriDati',
    label: 'Data di nascita*',
    defaultValue: ''
  },
  PRIVACY_DOC: {
    id: 'privacyDoc',
    defaultValue: null,
    isImage: true,
    tipoAllegato: 'PRIVACY_DOC'
  },
  PRIVACY_DOC_THUMB: {
    id: 'privacyDocThumb',
    defaultValue: null,
    isThumb: true
  },
  SESSO_ULTERIORI_DATI: {
    id: 'sessoUlterioriDati',
    label: 'Sesso',
    defaultValue: ''
  },
  TIPO_INSERIMENTO: {
    id: 'tipoInserimento',
    label: 'Tipo inserimento*',
    defaultValue: 'codice-fiscale'
  },
  RAGIONE_SOCIALE: {
    id: 'ragioneSociale',
    label: 'Ragione sociale*',
    maxLength: 50,
    defaultValue: ''
  },
  CODICE_FISCALE_PG: {
    id: 'codiceFiscalePg',
    label: 'Codice Fiscale*',
    defaultValue: '',
    minLength: 11,
    maxLength: 11,
    validazione: /^[a-zA-Z0-9]*$/g
  },
  PARTITA_IVA_PG: {
    id: 'vatCode',
    label: 'Partita IVA*',
    defaultValue: '',
    minLength: 11,
    maxLength: 11,
    validazione: /^[0-9]*$/g
  },
  SAVED_PG_FLAG: {
    id: 'flagPg'
  },
  SAVE_FLAG: {
    id: 'flag'
  }
}
