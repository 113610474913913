import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import {
  MIFID_FINANZIARIA,
  MIFID_FINANZIARIA_PF,
  MIFID_FINANZIARIA_PG,
  MIFID_FINANZIARIA_PD
} from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import allFields from '../../fields'
import Title from '../../../../components/Title'
import { MIFID_CONST, getMifidByCategory } from '../../utils'

let MIFID_ACTIVE = null
let fields = null

class SituazioneFinanziariaReview extends Component {
  constructor(props) {
    super(props)

    this.getRisposta = this.getRisposta.bind(this)
    this.mapRisposta = this.mapRisposta.bind(this)
    this.mapCheckbox = this.mapCheckbox.bind(this)
    this.getLabel = this.getLabel.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.FINANZIARIA)
    fields = allFields[2][MIFID_ACTIVE]
  }

  getLabel({ templateId }) {
    const { template } = this.props
    if (template) {
      return template.listaDomande.find(d => d.idDomanda === templateId).testoDomanda
    } else {
      return null
    }
  }

  getRisposta({ templateId }) {
    const {
      data: { listaDomande }
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    return domanda.listaRisposte[0].idRisposta
  }

  mapRisposta({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    const idRisposta = domanda.listaRisposte[0].idRisposta
    return template
      ? template.listaDomande.find(d => d.idDomanda === templateId).listaRisposte.find(r => r.idRisposta === idRisposta)
          .descRisposta
      : []
  }

  mapCheckbox({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    const domandaTemplate = template ? template.listaDomande.find(d => d.idDomanda === templateId) : {}
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    return domanda.listaRisposte.map(({ idRisposta }) => {
      return domandaTemplate && domandaTemplate.listaRisposte
        ? domandaTemplate.listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
        : ''
    })
  }

  render() {
    const {
      template,
      data: { listaDomande }
    } = this.props

    let siNoTrue = []
    let strutturaInternaDomandeSiNo =
      template && MIFID_ACTIVE === MIFID_FINANZIARIA_PF
        ? template.listaDomande.find(t => t.idDomanda === fields.INVESTIMENTI_IMMOBILIARI.templateId)
            .strutturaInternaDomande
        : ''

    if (strutturaInternaDomandeSiNo) {
      siNoTrue = strutturaInternaDomandeSiNo.listaDominiRisposte[0].risposte[0]
    }
    return (
      <CollapsibleItem
        header={template ? template.descSezione : ''}
        icon="keyboard_arrow_down"
        itemKey={MIFID_ACTIVE}
        labelInfo={this.props.accordionHeader}>
        {/* VECCHIA MIFID */}
        {MIFID_ACTIVE === MIFID_FINANZIARIA && (
          <Fragment>
            <Row>
              <Col s={6}>
                <Row>
                  <Col s={12}>
                    <Title>{this.getLabel(fields.FONTE_REDDITO)}</Title>
                  </Col>
                  <Col s={12}>
                    <ReadOnlyInput value={this.mapRisposta(fields.FONTE_REDDITO)} />
                  </Col>
                </Row>
              </Col>
              {this.getRisposta(fields.FONTE_REDDITO_ALTRO) && (
                <Col s={6}>
                  <Row>
                    <Col s={12}>
                      <Title>{this.getLabel(fields.FONTE_REDDITO_ALTRO)}</Title>
                    </Col>
                    <Col s={12}>
                      <ReadOnlyInput value={this.mapRisposta(fields.FONTE_REDDITO_ALTRO)} />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.REDDITO_FUTURO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.REDDITO_FUTURO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PATRIMONIO_FINANZIARIO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_FINANZIARIO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.INVESTIMENTI_IMMOBILIARI)}</Title>
              </Col>
              <Col s={12}>
                {(this.mapCheckbox(fields.INVESTIMENTI_IMMOBILIARI) || []).map((r, i) => (
                  <ReadOnlyInput key={i} value={r} />
                ))}
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.IMPEGNI_FINANZIARI_MENSILI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.IMPEGNI_FINANZIARI_MENSILI)} />
              </Col>
            </Row>
          </Fragment>
        )}
        {/* NUOVA MIFID PF */}
        {MIFID_ACTIVE === MIFID_FINANZIARIA_PF && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.FONTE_REDDITO)}</Title>
              </Col>
              <Col s={12}>
                {(this.mapCheckbox(fields.FONTE_REDDITO) || []).map((r, i) => (
                  <ReadOnlyInput key={i} value={r} />
                ))}
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.FONTE_REDDITO_MEDIO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.FONTE_REDDITO_MEDIO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.REDDITO_FUTURO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.REDDITO_FUTURO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PATRIMONIO_FINANZIARIO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_FINANZIARIO)} />
              </Col>
            </Row>
            <h2 className="collapsible-section-title">Si o no?</h2>
            <div>
              <Title>{this.getLabel(fields.INVESTIMENTI_IMMOBILIARI)}</Title>
            </div>
            <Row>
              {strutturaInternaDomandeSiNo &&
                strutturaInternaDomandeSiNo.domande &&
                strutturaInternaDomandeSiNo.domande.map(domanda => {
                  const listaRisp =
                    (listaDomande.find(t => t.idDomanda === domanda.idDomanda) || {}).listaRisposte || []
                  const yes = listaRisp.some(r => r.idRisposta === siNoTrue.idRisposta)
                  return (
                    <Col s={12} className="true-false-row" key={`col_${domanda.idDomanda}`}>
                      <div className="true-false-question disabled">
                        <p className="title">{domanda.testoDomanda}</p>
                        <p className="subtitle">{domanda.subTestoDomanda}</p>
                        {listaRisp.length > 0 && yes && (
                          <div className="box-input yes-selected">
                            <button type="button" className="yes-button si-btn" disabled>
                              SI
                            </button>
                          </div>
                        )}
                        {listaRisp.length > 0 && !yes && (
                          <div className="box-input no-selected">
                            <button type="button" className="no-button no-btn" disabled>
                              NO
                            </button>
                          </div>
                        )}
                        {listaRisp.length === 0 && <div className="box-input" />}
                      </div>
                    </Col>
                  )
                })}
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PATRIMONIO_IMMOBILIARE)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_IMMOBILIARE)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.IMPEGNI_FINANZIARI_MENSILI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.IMPEGNI_FINANZIARI_MENSILI)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RISPARMIO_MENSILE)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RISPARMIO_MENSILE)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PERSONE_DIPENDENTI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PERSONE_DIPENDENTI)} />
              </Col>
            </Row>
          </Fragment>
        )}
        {/* NUOVA MIFID PG */}
        {MIFID_ACTIVE === MIFID_FINANZIARIA_PG && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RICAVI_MEDI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RICAVI_MEDI)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RICAVI_FUTURO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RICAVI_FUTURO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.ATTIVO_BILANCIO_MEDIO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.ATTIVO_BILANCIO_MEDIO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PATRIMONIO_NETTO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_NETTO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO)} />
              </Col>
            </Row>
          </Fragment>
        )}
        {/* NUOVA MIFID PD */}
        {MIFID_ACTIVE === MIFID_FINANZIARIA_PD && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RICAVI_MEDI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RICAVI_MEDI)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RICAVI_FUTURO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RICAVI_FUTURO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.ATTIVO_BILANCIO_MEDIO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.ATTIVO_BILANCIO_MEDIO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PATRIMONIO_NETTO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_NETTO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO)} />
              </Col>
            </Row>
          </Fragment>
        )}
      </CollapsibleItem>
    )
  }
}

SituazioneFinanziariaReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  template: PropTypes.shape({
    descSezione: PropTypes.string,
    listaDomande: PropTypes.array
  })
}

export default SituazioneFinanziariaReview
