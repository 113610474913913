import React from 'react'
import { reduxForm } from 'redux-form'
import ProspectGiuridiche from './ProspectGiuridicheComponent'
import { CREA_PRATICA } from '../../../store/modules/forms/constants'
import fields from '../../../routes/ConsulenzaBase/fields'

const validate = values => {
  const errors = {}
  const {
    NOME,
    COGNOME,
    CODICE_FISCALE,
    ULTERIORI_DATI,
    NAZIONALITA_ULTERIORI_DATI,
    RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
    COMUNE_NASCITA_ULTERIORI_DATI,
    COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
    DATA_NASCITA_ULTERIORI_DATI,
    EMAIL,
    SAVE_FLAG
  } = fields
  // Validate PG form
  const { CODICE_FISCALE_PG, PARTITA_IVA_PG, TIPO_INSERIMENTO, RAGIONE_SOCIALE, SAVED_PG_FLAG } = fields
  if (values[TIPO_INSERIMENTO.id] === 'codice-fiscale') {
    if (!values[CODICE_FISCALE_PG.id]) {
      errors[CODICE_FISCALE_PG.id] = 'Il campo Codice Fiscale è obbligatorio'
    }
  } else if (values[TIPO_INSERIMENTO.id] === 'partita-iva') {
    if (!values[PARTITA_IVA_PG.id]) {
      errors[PARTITA_IVA_PG.id] = 'Il campo Partita IVA è obbligatorio'
    }
  }
  if (!values[RAGIONE_SOCIALE.id]) {
    errors[RAGIONE_SOCIALE.id] = 'Il campo Ragione Sociale è obbligatorio'
  }
  if (values[TIPO_INSERIMENTO.id] === 'codice-fiscale') {
    if (values[CODICE_FISCALE_PG.id]) {
      if (values[CODICE_FISCALE_PG.id].length < CODICE_FISCALE_PG.minLength) {
        errors[CODICE_FISCALE_PG.id] = 'Il codice fiscale deve avere 16 caratteri'
      } else if (values[CODICE_FISCALE_PG.id] === 'Codice fiscale non valido') {
        errors[CODICE_FISCALE_PG.id] = 'Si è verificato un errore nella codifica del codice fiscale'
      } else if (!new RegExp(CODICE_FISCALE_PG.validazione).test(values[CODICE_FISCALE_PG.id])) {
        errors[CODICE_FISCALE_PG.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
      } else if (values[CODICE_FISCALE_PG.id].length > CODICE_FISCALE_PG.maxLength || !CODICE_FISCALE_PG.validazione) {
        errors[CODICE_FISCALE_PG.id] = 'Il codice fiscale inserito non esiste'
      }
    }
  } else if (values[TIPO_INSERIMENTO.id] === 'partita-iva') {
    if (values[PARTITA_IVA_PG.id]) {
      if (values[PARTITA_IVA_PG.id].length < PARTITA_IVA_PG.minLength) {
        errors[PARTITA_IVA_PG.id] = 'La Partita IVA deve avere 11 caratteri'
      } else if (values[PARTITA_IVA_PG.id] === 'Partita IVA non valida') {
        errors[PARTITA_IVA_PG.id] = 'Si è verificato un errore nella codifica della Partita IVA'
      } else if (!new RegExp(PARTITA_IVA_PG.validazione).test(values[PARTITA_IVA_PG.id])) {
        errors[PARTITA_IVA_PG.id] = 'La partita IVA può contenere solo caratteri numerici'
      } else if (values[PARTITA_IVA_PG.id].length > PARTITA_IVA_PG.maxLength || !PARTITA_IVA_PG.validazione) {
        errors[PARTITA_IVA_PG.id] = 'La PArtita IVA inserita non esiste'
      }
    }
  }
  if (!values[SAVED_PG_FLAG.id]) return errors
  // Form section 2 validation only enabled when form 1 is saved
  if (values[ULTERIORI_DATI.id]) {
    if (!values[SAVE_FLAG.id]) {
      if (!values[NAZIONALITA_ULTERIORI_DATI.id]) {
        errors[NAZIONALITA_ULTERIORI_DATI.id] = 'Il campo Nazionalità è obbligatorio'
      }
      if (!values[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id]) {
        errors[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] = 'Il campo Residenza Anagrafica è obbligatorio'
      }
      if (!values[COMUNE_NASCITA_ULTERIORI_DATI.id]) {
        errors[COMUNE_NASCITA_ULTERIORI_DATI.id] = 'Il campo Comune di Nascita è obbligatorio'
      }
      if (!values[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id]) {
        errors[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id] = 'Il campo Comune di Nascita Estero è obbligatorio'
      }
      if (!values[DATA_NASCITA_ULTERIORI_DATI.id]) {
        errors[DATA_NASCITA_ULTERIORI_DATI.id] = 'Il campo Data di nascita è obbligatorio'
      }
    }
  } else {
    if (!values[CODICE_FISCALE.id]) {
      errors[CODICE_FISCALE.id] = 'Il campo Codice Fiscale è obbligatorio'
    }
  }
  if (!values[NOME.id]) {
    errors[NOME.id] = 'Il campo Nome è obbligatorio'
  } else if (!new RegExp(NOME.validazione).test(values[NOME.id])) {
    errors[NOME.id] = 'Il nome può contenere solo caratteri alfabetici'
  }
  if (!values[COGNOME.id]) {
    errors[COGNOME.id] = 'Il campo Cognome è obbligatorio'
  } else if (!new RegExp(COGNOME.validazione).test(values[COGNOME.id])) {
    errors[COGNOME.id] = 'Il cognome può contenere solo caratteri alfabetici'
  }
  if (values[CODICE_FISCALE.id]) {
    if (values[CODICE_FISCALE.id].length < CODICE_FISCALE.minLength) {
      errors[CODICE_FISCALE.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (values[CODICE_FISCALE.id] === 'Codice fiscale non valido') {
      errors[CODICE_FISCALE.id] = 'Si è verificato un errore nella codifica del codice fiscale'
    } else if (!new RegExp(CODICE_FISCALE.validazione).test(values[CODICE_FISCALE.id])) {
      errors[CODICE_FISCALE.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    } else if (values[CODICE_FISCALE.id].length > CODICE_FISCALE.maxLength || !CODICE_FISCALE.validazione) {
      errors[CODICE_FISCALE.id] = 'Il codice fiscale inserito non esiste'
    }
  }
  if (!!values[EMAIL.id] && !new RegExp(EMAIL.validazione).test(values[EMAIL.id])) {
    errors[EMAIL.id] = "L'indirizzo e-mail non è valido"
  }
  console.log(errors)
  return errors
}

const warn = values => {
  const warn = {}
  const { NOME, COGNOME, DATA_NASCITA, SESSO, NAZIONE_NASCITA, PROVINCIA_NASCITA, COMUNE_NASCITA } = fields

  if (!values[NOME.id]) {
    warn[NOME.id] = 'Il campo Nome è obbligatorio'
  }
  if (!values[COGNOME.id]) {
    warn[COGNOME.id] = 'Il campo Cognome è obbligatorio'
  }
  if (!values[DATA_NASCITA.id]) {
    warn[DATA_NASCITA.id] = 'Il campo Data di Nascita è obbligatorio'
  }
  if (!values[SESSO.id]) {
    warn[SESSO.id] = 'Il campo Sesso è obbligatorio'
  }
  if (!values[NAZIONE_NASCITA.id]) {
    warn[NAZIONE_NASCITA.id] = 'Il campo Nazione di Nascita è obbligatorio'
  }
  if (!values[PROVINCIA_NASCITA.id]) {
    warn[PROVINCIA_NASCITA.id] = 'Il campo Provincia di Nascita è obbligatorio'
  }
  if (!values[COMUNE_NASCITA.id]) {
    warn[COMUNE_NASCITA.id] = 'Il campo Comune di Nascita è obbligatorio'
  }
  return warn
}

const ConnectedComponent = reduxForm({
  form: CREA_PRATICA,
  validate,
  warn
})(ProspectGiuridiche)

export default React.forwardRef((props, ref) => <ConnectedComponent innerRef={ref} {...props} />)
