import React from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import { CollectionItem, Collection } from 'react-materialize'
import { Tooltip } from 'react-tippy'

class Autocomplete extends React.Component {
  constructor(props) {
    super(props)
    const { maxSuggestions = 5 } = props
    this.state = {
      focus: false,
      loseFocus: false,
      maxSuggestions,
      interval: 5,
      toBeScrolled: false
    }

    this._handleChange = this._handleChange.bind(this)
    this._handleSelect = this._handleSelect.bind(this)
    this._handleLoadMore = this._handleLoadMore.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const { maxSuggestions = 5 } = this.props
      this.setState({ maxSuggestions })
    }
    if (prevState.maxSuggestions < this.state.maxSuggestions) {
      this.setState({ toBeScrolled: true })
    }
    if (!prevState.toBeScrolled && this.state.toBeScrolled) {
      const { id } = this.props
      const list = $(`#${id.replace('[', '\\[').replace(']', '\\]')}-suggestions ul`)
      list.scrollTop((list[0] || {}).scrollHeight)
      this.setState({ toBeScrolled: false })
    }
  }

  _handleChange({ target: { value } }) {
    const { suggestions, onChange } = this.props
    const matchingSuggestion = suggestions.find(({ key }) => key === value)
    onChange(value, matchingSuggestion || null)
  }

  _handleSelect(suggestion) {
    const { onChange } = this.props
    onChange(suggestion.label, suggestion)
    this.setState({ loseFocus: true })
  }

  _handleLoadMore() {
    this.setState({
      maxSuggestions: this.state.maxSuggestions + this.state.interval,
      focus: true
    })
    $(`#${this.props.id}`).focus()
  }

  render() {
    const {
      disabled = false,
      label = '',
      placeholder = '',
      suggestions = [],
      threshold = 3,
      value = '',
      error,
      id,
      hideIcon = false,
      input = {},
      searchByValue,
      iconCallback = () => {},
      tooltipTitle = '',
      suppressFallbackError = false
    } = this.props

    let suggestionsElement = null
    let preSliceSuggestions = null
    let filteredSuggestions = null
    let invalid = false
    let labelClassName = ''

    if (value !== null && value.length >= threshold && suggestions) {
      // Workaround per cercare sul valore invece che sulla label
      if (searchByValue) {
        preSliceSuggestions = suggestions.filter(suggestion =>
          suggestion.valueToSearch ? suggestion.valueToSearch.toLowerCase().indexOf(value.toLowerCase()) >= 0 : ''
        )
        filteredSuggestions = preSliceSuggestions.slice(0, this.state.maxSuggestions).map(suggestion => {
          return (
            <CollectionItem key={suggestion.key} onClick={() => this._handleSelect(suggestion)}>
              {suggestion.label}
            </CollectionItem>
          )
        })
      } else {
        preSliceSuggestions = suggestions.filter(suggestion =>
          suggestion.label ? suggestion.label.toLowerCase().indexOf(value.toLowerCase()) >= 0 : ''
        )
        filteredSuggestions = preSliceSuggestions.slice(0, this.state.maxSuggestions).map(suggestion => {
          const prefixEnd = suggestion.label.toLowerCase().indexOf(value.toLowerCase())
          const suffixStart = prefixEnd + value.length
          const prefix = suggestion.label.slice(0, prefixEnd)
          const infix = suggestion.label.slice(prefixEnd, suffixStart)
          const suffix = suggestion.label.slice(suffixStart, suggestion.label.length)

          return (
            <CollectionItem key={suggestion.key} onClick={() => this._handleSelect(suggestion)}>
              {prefix}
              <b>{infix}</b>
              {suffix}
            </CollectionItem>
          )
        })
      }
      if (preSliceSuggestions.length > this.state.maxSuggestions) {
        filteredSuggestions.push(
          <CollectionItem key="expand-dropdown" onClick={this._handleLoadMore}>
            <span>...</span>
          </CollectionItem>
        )
      }
    }

    if (filteredSuggestions && filteredSuggestions.length > 0 && !this.state.loseFocus) {
      const scrollable = filteredSuggestions.length > this.state.maxSuggestions ? ' scrollable' : ''
      suggestionsElement = (
        <div className={`suggestions${scrollable}`} id={`${id}-suggestions`}>
          <Collection>{filteredSuggestions}</Collection>
        </div>
      )
    }

    if (
      (filteredSuggestions && filteredSuggestions.length === 0 && !disabled && suggestions.length > 0) ||
      (error && !this.state.focus)
    ) {
      invalid = true
    }

    if (value && value.length > 0) {
      labelClassName = 'active'
    } else if (!this.state.focus) {
      labelClassName = 'not-active'
    }

    let autocompleteClass = 'autocomplete'
    if (invalid) {
      autocompleteClass += ' invalid'
    }
    if (disabled) {
      autocompleteClass += ' disabled'
    }
    if (value && value.length) {
      autocompleteClass += ' filled'
    }
    if (!hideIcon) {
      autocompleteClass += ' with-icon'
    }

    const renderIcon = () => {
      if (!hideIcon) {
        return tooltipTitle ? (
          <Tooltip theme="dark" title={tooltipTitle}>
            <i className="icon-cerca" onClick={iconCallback} />
          </Tooltip>
        ) : (
          <i className="icon-cerca" onClick={iconCallback} />
        )
      }
    }

    const getError = () => {
      if (suppressFallbackError) {
        return error || ' '
      }
      return error || 'Nessuna corrispondenza'
    }

    return (
      <div className={autocompleteClass}>
        <div className="input-field">
          <label className={labelClassName} error={getError()} htmlFor={id}>
            {label}
          </label>
          <input
            id={id}
            type="text"
            placeholder={placeholder}
            label={label}
            value={value}
            onChange={this._handleChange}
            onFocus={() => {
              this.setState({ focus: true, loseFocus: false })
              if (typeof input.onFocus === 'function') input.onFocus()
            }}
            onBlur={() => {
              this.setState({ focus: false })
              if (typeof input.onBlur === 'function') input.onBlur()
            }}
            disabled={disabled}
            autoComplete="off"
          />
          {suggestionsElement}
          {renderIcon()}
        </div>
      </div>
    )
  }
}
export default Autocomplete

Autocomplete.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  maxSuggestions: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  threshold: PropTypes.number,
  value: PropTypes.any,
  error: PropTypes.string,
  hideIcon: PropTypes.bool,
  input: PropTypes.object,
  searchByValue: PropTypes.bool,
  iconCallback: PropTypes.func,
  tooltipTitle: PropTypes.string,
  suppressFallbackError: PropTypes.bool
}
