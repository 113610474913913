import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import allFields from '../../fields'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import ReadOnlyYesNo from '../../../../components/Inputs/ReadOnly/YesNo'
import { MIFID_EDUCAZIONE_PF, MIFID_EDUCAZIONE_PG } from '../../../../store/modules/forms/constants'
import Title from '../../../../components/Title'

let MIFID_ACTIVE = null
let fields = null

class EducazioneFinanziariaReview extends Component {
  constructor(props) {
    super(props)

    this.getRisposta = this.getRisposta.bind(this)
    this.mapRisposta = this.mapRisposta.bind(this)
    this.getLabel = this.getLabel.bind(this)
    this.getSubRadioDomande = this.getSubRadioDomande.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.EDUCAZIONE)
    fields = allFields[2][MIFID_ACTIVE]
  }

  getLabel({ templateId }) {
    const { template } = this.props
    if (template) {
      return template.listaDomande.find(d => d.idDomanda === templateId).testoDomanda
    } else {
      return null
    }
  }

  getSubRadioDomande({ templateId }) {
    const { template } = this.props
    const strutturaInternaDomande = template.listaDomande.find(d => d.idDomanda === templateId).strutturaInternaDomande
    return strutturaInternaDomande.domande
  }

  getRisposta({ templateId }) {
    const {
      data: { listaDomande }
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    return domanda.listaRisposte[0].idRisposta
  }

  mapRisposta({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    const idRisposta = domanda.listaRisposte[0].idRisposta
    return template.listaDomande
      .find(d => d.idDomanda === templateId)
      .listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
  }

  render() {
    const {
      template,
      data: { listaDomande }
    } = this.props

    const strutturaInternaDomandeSiNo =
      template && MIFID_ACTIVE === MIFID_EDUCAZIONE_PF
        ? template.listaDomande.find(t => t.idDomanda === fields.CRISI_BANCARIE.templateId).strutturaInternaDomande
        : ''

    let siNoTrue = []
    if (strutturaInternaDomandeSiNo) {
      siNoTrue = strutturaInternaDomandeSiNo.listaDominiRisposte[0].risposte[0]
    }
    return (
      <CollapsibleItem
        header={template ? template.descSezione : ''}
        icon="keyboard_arrow_down"
        itemKey={MIFID_ACTIVE}
        labelInfo={this.props.accordionHeader}>
        {/* NUOVA MIFID PF */}
        {MIFID_ACTIVE === MIFID_EDUCAZIONE_PF && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.MERCATI_FINANZIARI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.MERCATI_FINANZIARI)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.AGGIORNAMENTO_MERCATI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.AGGIORNAMENTO_MERCATI)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.INTERESSE_INVESTIMENTI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.INTERESSE_INVESTIMENTI)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.PENSIONAMENTO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.PENSIONAMENTO)} />
              </Col>
            </Row>
            <h2 className="collapsible-section-title">Si o no?</h2>
            <div>
              <Title>{this.getLabel(fields.CRISI_BANCARIE)}</Title>
            </div>
            <Row>
              {strutturaInternaDomandeSiNo &&
                strutturaInternaDomandeSiNo.domande &&
                strutturaInternaDomandeSiNo.domande.map(domanda => {
                  const listaRisp =
                    (listaDomande.find(t => t.idDomanda === domanda.idDomanda) || {}).listaRisposte || []
                  const yes = listaRisp.some(r => r.idRisposta === siNoTrue.idRisposta)
                  return (
                    <Col s={12} className="true-false-row" key={`col_${domanda.idDomanda}`}>
                      <div className="true-false-question disabled">
                        <p className="title">{domanda.testoDomanda}</p>
                        <p className="subtitle">{domanda.subTestoDomanda}</p>
                        {listaRisp.length > 0 && yes && (
                          <div className="box-input yes-selected">
                            <button type="button" className="yes-button si-btn" disabled>
                              SI
                            </button>
                          </div>
                        )}
                        {listaRisp.length > 0 && !yes && (
                          <div className="box-input no-selected">
                            <button type="button" className="no-button no-btn" disabled>
                              NO
                            </button>
                          </div>
                        )}
                        {listaRisp.length === 0 && <div className="box-input" />}
                      </div>
                    </Col>
                  )
                })}
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.GRAFICO_PORTAFOGLIO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.GRAFICO_PORTAFOGLIO)} />
              </Col>
            </Row>
          </Fragment>
        )}
        {/* NUOVA MIFID PG */}
        {MIFID_ACTIVE === MIFID_EDUCAZIONE_PG && (
          <Fragment>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo label={this.getLabel(fields.DIPENDENTI)} value={this.mapRisposta(fields.DIPENDENTI)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo label={this.getLabel(fields.WELFARE)} value={this.mapRisposta(fields.WELFARE)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo label={this.getLabel(fields.KEY_MAN)} value={this.mapRisposta(fields.KEY_MAN)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.POLIZZE_ASSICURATIVE)}
                  value={this.mapRisposta(fields.POLIZZE_ASSICURATIVE)}
                />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.FATTURATO)}</Title>
              </Col>
              <Col s={12}>
                {this.getSubRadioDomande(fields.FATTURATO).map(domanda => (
                  <Row key={domanda.idDomanda}>
                    {/* da testare */}
                    <Col s={12}>
                      <Title>{domanda.testoDomanda}</Title>
                    </Col>
                    <Col s={12}>
                      <ReadOnlyInput value={this.mapRisposta(domanda.idDomanda)} />
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.ATTIVITA_SOSTENIBILI)}
                  value={this.mapRisposta(fields.ATTIVITA_SOSTENIBILI)}
                />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.BILANCIO_SOCIALE)}
                  value={this.mapRisposta(fields.BILANCIO_SOCIALE)}
                />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.FONDO_PENSIONE_CATEGORIA)}
                  value={this.mapRisposta(fields.FONDO_PENSIONE_CATEGORIA)}
                />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.FONDO_PENSIONE_APERTO)}
                  value={this.mapRisposta(fields.FONDO_PENSIONE_APERTO)}
                />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.UTILIZZO_TESORERIA)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.UTILIZZO_TESORERIA)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.SCADENZA_DEBITO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.SCADENZA_DEBITO)} />
              </Col>
            </Row>
          </Fragment>
        )}
        {/* NUOVA MIFID PD */}
        {/* {MIFID_ACTIVE === MIFID_EDUCAZIONE_PD && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RICAVI_MEDI)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RICAVI_MEDI)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RICAVI_FUTURO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RICAVI_FUTURO)} />
              </Col>
            </Row>
          </Fragment>
        )} */}
      </CollapsibleItem>
    )
  }
}

EducazioneFinanziariaReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  template: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default EducazioneFinanziariaReview
