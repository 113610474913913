/* CENSIMENTO PROSPECT */
export const CREA_PRATICA = 'CREA_PRATICA'
export const DATA_GATHERING = 'DATA_GATHERING'

/* ACQUISIZIONE DOCUMENTI */
export const CODICE_FISCALE = 'CODICE_FISCALE'
export const CODICE_FISCALE_PF = 'CODICE_FISCALE_PF'
export const DOCUMENTO_IDENTITA_ALLEGATO = 'DOCUMENTO_IDENTITA_ALLEGATO'
export const DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO = 'DOCUMENTO_IDENTITA_PF'
export const CENSIMENTO_PROCURATORE = 'CENSIMENTO_PROCURATORE'
export const DOCUMENTI_GIURIDICHE = 'DOCUMENTO_PG'
export const INFORMAZIONI_PERSONALI_GIURIDICHE = 'ANAGRAFICA_PG'
export const PROCURATORE_ID = 'PROCURATORE_ID'
export const DOCUMENTO_PROCURA = 'DOCUMENTO_PROCURA'
export const SCHEDA_DATI = 'SCHEDA_RACCOLTA_DATI_PF_RIF'
export const SCHEDA_DATI_TITOLARE = 'SCHEDA_RACCOLTA_DATI_TITOLARI'
export const CLIENTI_PROFESSIONALI = 'CLIENTE_PROFESSIONALE'
export const SCHEDA_DATI_PEP = 'UPLOAD_DOCUMENTO_TITOLARE_EFFETTIVO'

/* CENSIMENTO ANAGRAFICO */
export const INFORMAZIONI_PERSONALI = 'INFO_PERSONALI'
export const RESIDENZA = 'RESIDENZA'
export const CONTATTI = 'CONTATTI'
export const FATCA = 'FATCA'
export const FATCA_GIURIDICHE = 'FATCA_PG'
export const ANTIRICICLAGGIO = 'ANTI_RICICLAGGIO'
export const ANTIRICICLAGGIO_GIURIDICHE = 'ANTI_RICICLAGGIO_PG'
export const CONSENSI_PRIVACY = 'PRIVACY'
export const CONSENSI_PRIVACY_GIURIDICHE = 'PRIVACY_PG'

/* VECCHIA MIFID */
export const MIFID_CONOSCENZA = 'CONOSCENZA_ESPERIENZA'
export const MIFID_OBIETTIVI = 'INVESTIMENTO'
export const MIFID_OBIETTIVI_LIGHT = 'INVESTIMENTO_LIGHT'
export const MIFID_FINANZIARIA = 'FINANZIARIA'
export const MIFID_ALTRO = 'ALTRO'
export const MIFID_ALTRO_LIGHT = 'ALTRO_LIGHT'
export const SERVIZI_DIGITALI = 'servizi'
export const ADEGUATA_VERIFICA = 'ADEGUATA_VERIFICA'
export const ADEGUATA_VERIFICA_PG = 'ADEGUATA_VERIFICA_PG'
export const MODAL_PRIVACY = 'MODAL_PRIVACY'
export const SOGGETTI_CORRELATI = 'SOGGETTI_CORRELATI'
export const SOGGETTI_CORRELATI_GIURIDICHE = 'SOGGETTI_CORRELATI_GIURIDICHE'

/* NUOVA MIFID PF */
export const MIFID_ANAGRAFICA_PF = 'ANAGRAFICA'
export const MIFID_CONOSCENZA_PF = 'CONOSCENZA_ESPERIENZA_PF'
export const MIFID_OBIETTIVI_PF = 'INVESTIMENTO_PF'
export const MIFID_FINANZIARIA_PF = 'FINANZIARIA_PF'
export const MIFID_TOLLERANZA_PF = 'TOLLERANZA_OSCILLAZIONE'
export const MIFID_EDUCAZIONE_PF = 'EDUCAZIONE_FINANZIARIA'
export const MIFID_INVESTIMENTI_ESG_PF = 'INVESTIMENTI_ESG_PF'

/* NUOVA MIFID PG */
export const MIFID_CONOSCENZA_PG = 'CONOSCENZA_ESPERIENZA_PG'
export const MIFID_OBIETTIVI_PG = 'INVESTIMENTO_PG'
export const MIFID_FINANZIARIA_PG = 'FINANZIARIA_PG'
export const MIFID_TOLLERANZA_PG = 'TOLLERANZA_OSCILLAZIONE_PG'
export const MIFID_EDUCAZIONE_PG = 'EDUCAZIONE_FINANZIARIA_PG'
export const MIFID_INVESTIMENTI_ESG_PG = 'INVESTIMENTI_ESG_PG'

/* NUOVA MIFID PD */
export const MIFID_OBIETTIVI_PD = 'INVESTIMENTO_PD'
export const MIFID_FINANZIARIA_PD = 'FINANZIARIA_PD'
export const MIFID_TOLLERANZA_PD = 'TOLLERANZA_OSCILLAZIONE_PD'
export const MIFID_INVESTIMENTI_ESG_PD = 'INVESTIMENTI_ESG_PD'
// export const MIFID_EDUCAZIONE_PD = 'EDUCAZIONE_FINANZIARIA_PD'

/* COSTANTI NOMI FORM MIFID (per isDirty e check redux form) */
export const ANAGRAFICA = 'ANAGRAFICA'
export const CONOSCENZA = 'CONOSCENZA'
export const OBIETTIVI = 'OBIETTIVI'
export const FINANZIARIA = 'FINANZIARIA'
export const TOLLERANZA = 'TOLLERANZA'
export const EDUCAZIONE = 'EDUCAZIONE'
export const INVESTIMENTI_ESG = 'INVESTIMENTI_ESG'

// apertura-servizi
export const APERTURA_INTESTATARI = 'aperturaIntestatari'
export const APERTURA_INFO = 'aperturaInfo'
export const APERTURA_INFO_GIURIDICHE = 'aperturaInfoGiuridiche'
export const APERTURA_MAX = 'aperturaMax'
export const APERTURA_GOAL_BASED_PLAN = 'goalBasedPlan'
export const APERTURA_MAX_FUND = 'aperturaMaxFund'
export const APERTURA_RTO_BPM = 'aperturaRtoBpm'
export const APERTURA_RTO_UBS = 'aperturaRtoUbs'
export const APERTURA_RTO_IMI = 'aperturaRtoImi'
export const APERTURA_CONTO_DEPOSITO_LIQUIDITA = 'aperturaContoDepositoLiquidita'
export const APERTURA_PIR = 'aperturaPIR'
export const CREATE_TODO = 'CREATE_TODO'
export const FORM_ADD_DEROGA = 'FORM_ADD_DEROGA'
export const SAVE_FIELDS_DEROGA = 'SAVE_FIELDS_DEROGA'
export const APERTURA_MAX_BNP = 'aperturaMaxBnp'
export const APERTURA_MAX_FUND_BNP = 'aperturaMaxFundBnp'

export const DASHBOARD = 'DASHBOARD'

// AGGIORNAMENTO ANTIRICICLAGGIO
export const AGGIORNAMENTO_ANTIRICICLAGGIO = 'AGGIORNAMENTO_ANTIRICICLAGGIO'
export const ANTI_RICICLAGGIO = 'ANTI_RICICLAGGIO'
export const AGGIORNAMENTO_AML = 'AGGIORNAMENTO_AML'
export const INFO_GENERALI = 'INFO_GENERALI'
