export const PRIVACY_CHECK_START = 'PRIVACY_CHECK_START'
export const PRIVACY_CHECK_SUCCESS = 'PRIVACY_CHECK_SUCCESS'
export const PRIVACY_CHECK_ERROR = 'PRIVACY_CHECK_ERROR'

export const MODULE_CHECK_START = 'MODULE_CHECK_START'
export const MODULE_CHECK_SUCCESS = 'MODULE_CHECK_SUCCESS'
export const MODULE_CHECK_ERROR = 'MODULE_CHECK_ERROR'

export const PRIVACY_SEND_START = 'PRIVACY_SEND_START'
export const PRIVACY_SEND_SUCCESS = 'PRIVACY_SEND_SUCCESS'
export const PRIVACY_SEND_ERROR = 'PRIVACY_SEND_ERROR'

export const SET_PROCURATORE = 'SET_PROCURATORE'
export const CONFIRM_PAPER_PRIVACY_PROCURATORE_TRUE = 'CONFIRM_PAPER_PRIVACY_PROCURATORE_TRUE'
export const CONFIRM_PAPER_PRIVACY_PROCURATORE_FALSE = 'CONFIRM_PAPER_PRIVACY_PROCURATORE_FALSE'

export const CONFIRM_PAPER_PRIVACY_START = 'CONFIRM_PAPER_PRIVACY_START'
export const CONFIRM_PAPER_PRIVACY_SUCCESS = 'CONFIRM_PAPER_PRIVACY_SUCCESS'
export const CONFIRM_PAPER_PRIVACY_ERROR = 'CONFIRM_PAPER_PRIVACY_ERROR'

export const PRIVACY_RESET = 'PRIVACY_RESET'
