import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { FATCA } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import { getValueDominioFromKey } from '../../utils'
import allFields from '../../fields'
import * as dataSB from '../../../../static-data/data-servizio-base.json'
import Title from '../../../../components/Title'

const fatcaCrs = dataSB.censimento_anagrafica.fatca_crs
const fields = allFields[1][FATCA]

class FatcaReview extends Component {
  render() {
    const {
      data,
      domini: { listaDomini }
    } = this.props
    const {
      RESIDENZA_USA,
      TIN,
      LISTA: {
        id,
        lista: { STATO, CODICE_FISCALE }
      }
    } = fields

    return (
      <CollapsibleItem
        header={fatcaCrs.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={FATCA}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={12}>
            <Title>{fatcaCrs.label_dichiarazione_fatca}</Title>
          </Col>
          <Col s={12}>
            <ReadOnlyInput value={`Fiscalmente ${data[RESIDENZA_USA.id] ? '' : ' non'} residente negli USA`} />
          </Col>
        </Row>
        {data[TIN.id] && (
          <Row>
            <Col s={12}>
              <ReadOnlyInput label={TIN.label} value={data[TIN.id]} />
            </Col>
          </Row>
        )}
        {data[id] &&
          data[id].map((residenza, index) => (
            <Row key={index}>
              <Col s={6}>
                <ReadOnlyInput
                  label={STATO.label}
                  value={getValueDominioFromKey(listaDomini, STATO.dominio, (residenza[STATO.id] || {}).chiave)}
                />
              </Col>
              <Col s={6}>
                <ReadOnlyInput label={CODICE_FISCALE.label} value={residenza[CODICE_FISCALE.id]} />
              </Col>
            </Row>
          ))}
      </CollapsibleItem>
    )
  }
}
export default FatcaReview

FatcaReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object
}
