import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Button } from 'react-materialize'
import { Field } from 'redux-form'
import { mapDominiToOptions, getDominio } from '../../utils'
import SoggettiCorrelatiTable from '../../../../components/Tables/SoggettiCorrelati/SoggettiCorrelatiTable'
import SoggettiGiaCorrelatiTable from '../../../../components/Tables/SoggettiCorrelati/SoggettiGiaCorrelatiTable'
import { SOGGETTI_CORRELATI } from '../../../../store/modules/forms/constants'
import InputText from '../../../../components/Inputs/Text'
import { renderSelect } from '../../../../components/Forms/renderFields'
import allFields from '../../../ConsulenzaBaseContainerLayout/fields'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import moment from 'moment'

import ModalGeneric from '../../../../components/ModalGeneric'

const fields = allFields[1][SOGGETTI_CORRELATI]

class SoggettiCorrelati extends Component {
  constructor(props) {
    super(props)
    this.state = {
      personaFisica: null,
      personaGiuridica: null,
      personAdded: null,
      cont: -1,
      soggGiaCorr: true,
      numPage: 1,
      showModalAlert: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setContatoreSoggetti = this.setContatoreSoggetti.bind(this)
    this.giaCorrelati = this.giaCorrelati.bind(this)
  }
  componentDidMount() {
    this.giaCorrelati()
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.inviaRichiestaRequest &&
      prevProps.inviaRichiestaRequest &&
      prevProps.inviaRichiestaRequest.fetching === true &&
      this.props.inviaRichiestaRequest.fetching === false &&
      this.props.inviaRichiestaRequest.error === null
    ) {
      this.giaCorrelati()
    }
  }

  giaCorrelati() {
    this.props.getSoggettiGiaCorrelati(this.props.customer.idCliente)
    /* this.props.getSoggettiGiaCorrelatiGiuridiche(this.props.customer.idCliente) */
  }

  handleSubmit() {
    const { formValues = {}, searchSoggettiCorrelati } = this.props
    const { RICERCA } = fields
    const data = {
      querySearch: formValues[RICERCA.id],
      customerSubjectType: 'PF',
      itemxpage: 10,
      page: 1
    }
    searchSoggettiCorrelati(data)
    this.setState({ personaFisica: true })
    this.setState({ soggGiaCorr: false })
  }
  setContatoreSoggetti(value) {
    const { soggettiCorrelatiListData } = this.props

    if (soggettiCorrelatiListData[value].emailAddress) {
      this.setState({ cont: value })
    } else {
      this.setState({ showModalAlert: true })
    }
  }

  render() {
    const {
      soggettiCorrelatiRequest,
      inviaRichiestaRequest,
      handleSubmit,
      formValues = {},
      formErrors = {},
      domini,
      change,
      touch,
      soggettiCorrelatiListData,
      totalpage,
      soggettiGiaCorrelatiListData,
      // soggettiCorrelatiListDataGiuridiche,
      // soggettiGiaCorrelatiListDataGiuridiche,
      inviaRichiestaSoggettiCorrelati,
      invalid
    } = this.props
    const { RICERCA } = fields
    const { personaFisica, cont, soggGiaCorr } = this.state
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        {this.state.showModalAlert && (
          <Fragment>
            <ModalGeneric
              show={this.state.showModalAlert}
              text="Non è possibile effettuare il collegamento poichè non è stata censita la mail del Cliente selezionato"
              handler={() => this.setState({ showModalAlert: false })}
            />
          </Fragment>
        )}

        {cont === -1 && (
          <Fragment>
            <Row>
              {soggGiaCorr && (
                <Col s={12}>
                  <SoggettiGiaCorrelatiTable
                    list={soggettiGiaCorrelatiListData}
                    totalpage={totalpage}
                    {...this.props}
                  />
                </Col>
              )}
              <Col s={9}>
                <Field
                  name={RICERCA.id}
                  label={RICERCA.label}
                  s={12}
                  error={formErrors[RICERCA.id]}
                  formValues={formValues[RICERCA.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
              <Col s={3} style={{ marginTop: '20px' }}>
                <Button disabled={invalid} className="right soggetiCorrelati">
                  <i className="material-icons">search</i>
                  CERCA
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}

        {personaFisica && !soggGiaCorr && !soggettiCorrelatiRequest.fetching && !soggettiCorrelatiRequest.error && (
          <Row>
            <Col s={12}>
              {cont === -1 && (
                <SoggettiCorrelatiTable
                  list={soggettiCorrelatiListData}
                  contatore={cont => this.setContatoreSoggetti(cont)}
                  fields={fields}
                  incrementa={() => this.setState({ numPage: this.state.numPage + 1 })}
                  decrementa={() => this.setState({ numPage: this.state.numPage - 1 })}
                  numPage={this.state.numPage}
                  {...this.props}
                />
              )}
              {cont >= 0 && (
                <div className="soggetti-correlati-user">
                  <Row>
                    <Col s={5}>
                      <ReadOnlyInput
                        label="Nome Cognome, Luogo e Data di nascita"
                        value={`${soggettiCorrelatiListData[cont].name} ${soggettiCorrelatiListData[cont].surname}, ${
                          soggettiCorrelatiListData[cont].birthcity
                        },  ${moment(soggettiCorrelatiListData[cont].birthdate).format('DD/MM/YYYY')}
                          `}
                      />
                    </Col>
                    <Col s={3}>
                      <Field
                        s={12}
                        name={fields.RELAZIONE.id}
                        label={fields.RELAZIONE.label}
                        options={mapDominiToOptions(getDominio(domini, fields.RELAZIONE.dominio), true)}
                        component={renderSelect}
                      />
                    </Col>
                    <Col s={4}>
                      {!soggGiaCorr && (
                        <Button
                          type="button"
                          className="invia"
                          onClick={() => {
                            inviaRichiestaSoggettiCorrelati({
                              idCustomer: this.props.customer.idCliente,
                              idCustomerRelated: soggettiCorrelatiListData[cont].idCustomer,
                              role: formValues[fields.RELAZIONE.id],
                              mailCustomerRelated: soggettiCorrelatiListData[cont].emailAddress,
                              name: soggettiCorrelatiListData[cont].name,
                              surname: soggettiCorrelatiListData[cont].surname,
                              birthdate: soggettiCorrelatiListData[cont].birthdate,
                              taxcode: soggettiCorrelatiListData[cont].taxcode,
                              birthcity: soggettiCorrelatiListData[cont].birthcity
                            })
                            this.setState({ cont: -1, soggGiaCorr: !soggGiaCorr })
                          }}>
                          Invia richiesta
                        </Button>
                      )}

                      {/* {!inviaRichiestaRequest.fetching &&
                          !soggGiaCorr &&
                          inviaRichiestaRequest.error &&
                          !inviaRichiestaRequest.success && (
                            <Fragment>
                              <Attesa className="m-top20 ">
                                <Red text="Impossibile inviare la richiesta">
                                  <i className="material-icons">error</i>
                                </Red>
                              </Attesa>
                            </Fragment>
                          )}
                        {!inviaRichiestaRequest.fetching &&
                          !soggGiaCorr &&
                          !inviaRichiestaRequest.error &&
                          inviaRichiestaRequest.success && (
                            <Fragment>
                              <Attesa className="m-top20 " text="Richiesta inviata in attesa di conferma">
                                <Orange>
                                  <i className="material-icons">access_time</i>
                                </Orange>
                              </Attesa>
                            </Fragment>
                          )} */}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1rem' }}>
                    <Col s={12}>
                      <button
                        type="button"
                        className="btn waves-effect waves-light btn-flat btn-flat2 btnRichiesta"
                        onClick={() => {
                          this.setState({ cont: -1 })
                          inviaRichiestaRequest.success = false
                          inviaRichiestaRequest.error = false
                        }}>
                        <i className="material-icons">add_circle_outline</i>{' '}
                        <span style={{ verticalAlign: 'super' }}>
                          <b>Aggiungi soggetto da correlare</b>
                        </span>
                      </button>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        )}
      </form>
    )
  }
}

SoggettiCorrelati.propTypes = {
  getSoggettiGiaCorrelati: PropTypes.func,
  getSoggettiGiaCorrelatiGiuridiche: PropTypes.func,
  customer: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  touch: PropTypes.func,
  change: PropTypes.func,
  url: PropTypes.string,
  idPratica: PropTypes.string,
  inviaRichiestaSoggettiCorrelati: PropTypes.func,
  searchSoggettiCorrelati: PropTypes.func,
  searchSoggettiCorrelatiGiuridiche: PropTypes.func,
  soggettiCorrelatiRequest: PropTypes.object,
  inviaRichiestaRequest: PropTypes.object,
  handleSubmit: PropTypes.func,
  domini: PropTypes.object,
  soggettiCorrelatiListData: PropTypes.array,
  soggettiGiaCorrelatiListData: PropTypes.array,
  totalpage: PropTypes.any,
  invalid: PropTypes.bool
}
export default SoggettiCorrelati
