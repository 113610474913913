import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import moment from 'moment'
import { Col, Row } from 'react-materialize'
import { getDominio, mapDominiToOptions, reduceQuestions } from '../../utils'
import AccordionHeader from '../../../../components/AccordionHeader'
import { CollapsibleItem } from '../../../../components/Collapsible'
import InputText from '../../../../components/Inputs/Text'
import { ANTIRICICLAGGIO_GIURIDICHE } from '../../../../store/modules/forms/constants'
import { renderSelect, renderYesNoQuestion } from '../../../../components/Forms/renderFields'
import ButtonSubmit from '../../../../components/Buttons/Submit'

class AntiRiciclaggioGiuridiche extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saved: false,
      nazione: []
    }
    this.isOutsideRange = this.isOutsideRange.bind(this)
    this.getOrderedNazioni = this.getOrderedNazioni.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    this.setState({ nazione: this.getOrderedNazioni(this.props.domini) })
  }

  componentDidUpdate(prevProps) {
    if (this.props.requestFromAntiriciclaggio) {
      if (!prevProps.apiSuccess && this.props.apiSuccess) {
        this.props.initialize(this.props.formValues)
        this.setState({ saved: true })
      }

      if (
        this.state.saved &&
        prevProps.isSummaryLightPGFetching &&
        !this.props.isSummaryLightPGFetching &&
        !this.props.isSummaryLightPGApiError
      ) {
        this.props.resetForm(ANTIRICICLAGGIO_GIURIDICHE)
      }
    }
    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && !this.props.dominioRequest.error) {
      this.setState({ nazione: this.getOrderedNazioni(this.props.domini) })
    }
  }

  getOrderedNazioni(domini) {
    // const { fields } = this.props
    // const {
    //   NAZIONE_INSEDIAMENTO: { dominio, italiaId }
    // } = fields
    // const nazioni = mapDominiToAutocomplete(getDominio(domini, dominio))
    // const itIndex = nazioni.findIndex(n => n.value === italiaId)
    // if (itIndex > -1) {
    //   return [nazioni[itIndex], ...nazioni.slice(0, itIndex), ...nazioni.slice(itIndex + 1)]
    // }
    // return nazioni
  }

  isOutsideRange(date) {
    const { dataNascitaCF } = this.props
    if (!dataNascitaCF) return date.isAfter(moment(), 'day')
    return date.isAfter(moment(), 'day') || date.isBefore(moment(dataNascitaCF), 'day')
  }

  checkIfDislayTitolariEffettivi() {
    if (Object.keys(this.props.previousForm).length === 0) {
      return false
    } else {
      const questionTitolariEffettivi = this.props.previousForm.listaDomande.find(
        c => c.idDomanda === this.props.fields.TITOLARI_EFFETTIVI.templateId
      )
      return questionTitolariEffettivi != null && questionTitolariEffettivi.disable === true
    }
  }

  submit(values) {
    const { onSave, fields } = this.props
    if (onSave) {
      this.setState({ saved: false })
      let obj = reduceQuestions(fields, values)
      onSave(obj, true)
    }
  }

  render() {
    const {
      template,
      // previousForm,
      domini,
      handleSubmit,
      isDirty,
      formValues,
      fields,
      status,
      change,
      untouch,
      submitting,
      isFetching,
      invalid,
      touch
    } = this.props
    const { listaDomande } = template
    // const radioPepRisposte = listaDomande
    //   .find(d => d.idDomanda === fields.RADIO_PEP.templateId)
    //   .listaRisposte.map(r => '' + r.idRisposta)

    let checkVisibility = (parent, childs) => {
      if (String(formValues[parent]) === '99') {
        return true
      } else if (String(formValues[parent]) === '100') {
        for (var i = 0; i < childs.length; i++) {
          change(childs[i], '')
          untouch(childs[i])
        }
        return false
      }
    }

    return (
      <CollapsibleItem
        header={'Raccolta dati ai fini antiriciclaggio'}
        icon="keyboard_arrow_down"
        itemKey={ANTIRICICLAGGIO_GIURIDICHE}
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate className="anti-riciclaggio">
          <Row className="m-top20">
            <Col s={12}>
              <Field
                name={fields.ATTIVITA_EXTRA_EU.id}
                label={listaDomande.find(c => c.idDomanda === fields.ATTIVITA_EXTRA_EU.templateId).testoDomanda + '*'}
                handlerFn={value => {
                  change(fields.ATTIVITA_EXTRA_EU.id, value)
                  // if (String(formValues[fields.ATTIVITA_EXTRA_EU.id]) ===  '100'){
                  //   change(fields.QUALI_ATTIVITA_EXTRA_EU.id, '')
                  // }
                }}
                options={listaDomande.find(c => c.idDomanda === fields.ATTIVITA_EXTRA_EU.templateId).listaRisposte}
                component={renderYesNoQuestion}
              />
            </Col>
            {checkVisibility(fields.ATTIVITA_EXTRA_EU.id, [fields.QUALI_ATTIVITA_EXTRA_EU.id]) && (
              <Fragment>
                {/* <Field
                    name={fields.QUALI_ATTIVITA_EXTRA_EU.id}
                    s={12}
                    threshold={0}
                    // maxLength={50}
                    label={
                      listaDomande.find(c => c.idDomanda === fields.QUALI_ATTIVITA_EXTRA_EU.templateId).testoDomanda +
                      '*'
                    }
                    // value={formValues[fields.QUALI_ATTIVITA_EXTRA_EU.id]}
                    // change={change}
                    handleChange={change}
                    touch={touch}
                    defaultValue={getAutocompleteDefaultOption(
                      domini.listaDomini,
                      fields.QUALI_ATTIVITA_EXTRA_EU.dominio,
                      formValues[fields.QUALI_ATTIVITA_EXTRA_EU.id]
                    )}
                    options={mapDominiToAutocomplete(getDominio(domini, fields.QUALI_ATTIVITA_EXTRA_EU.dominio), true)}
                    component={renderAutocomplete}
                  /> */}

                <Field
                  key={2}
                  name={fields.QUALI_ATTIVITA_EXTRA_EU.id}
                  s={6}
                  maxLength={100}
                  label={
                    listaDomande.find(c => c.idDomanda === fields.QUALI_ATTIVITA_EXTRA_EU.templateId).testoDomanda + '*'
                  }
                  value={formValues[fields.QUALI_ATTIVITA_EXTRA_EU.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Fragment>
            )}
          </Row>
          <Row>
            <Field
              key={1}
              name={fields.NATURA_RAPPORTO.id}
              size={6}
              label={listaDomande.find(c => c.idDomanda === fields.NATURA_RAPPORTO.templateId).testoDomanda + '*'}
              options={mapDominiToOptions(getDominio(domini, fields.NATURA_RAPPORTO.dominio), true)}
              onChange={value => {
                if (value !== fields.NATURA_RAPPORTO.altroId && formValues[fields.NATURA_RAPPORTO_ALTRO.id]) {
                  change(fields.NATURA_RAPPORTO_ALTRO.id, '')
                }
              }}
              component={renderSelect}
            />
            <Field
              key={2}
              name={fields.NATURA_RAPPORTO_ALTRO.id}
              s={6}
              maxLength={20}
              label={listaDomande.find(c => c.idDomanda === fields.NATURA_RAPPORTO_ALTRO.templateId).testoDomanda + '*'}
              value={formValues[fields.NATURA_RAPPORTO_ALTRO.id]}
              disabled={'' + formValues[fields.NATURA_RAPPORTO.id] !== fields.NATURA_RAPPORTO.altroId}
              change={change}
              touch={touch}
              component={InputText}
            />
          </Row>
          <Row>
            <Col s={12}>
              <Field
                name={fields.TITOLARI_EFFETTIVI.id}
                label={listaDomande.find(c => c.idDomanda === fields.TITOLARI_EFFETTIVI.templateId).testoDomanda + '*'}
                handlerFn={value => change(fields.TITOLARI_EFFETTIVI.id, value)}
                options={listaDomande.find(c => c.idDomanda === fields.TITOLARI_EFFETTIVI.templateId).listaRisposte}
                component={renderYesNoQuestion}
                disabled={this.checkIfDislayTitolariEffettivi()}
              />
            </Col>
          </Row>
          <Row>
            <Field
              key={1}
              name={fields.SETTORE_OCCUPAZIONE.id}
              size={6}
              label={listaDomande.find(c => c.idDomanda === fields.SETTORE_OCCUPAZIONE.templateId).testoDomanda + '*'}
              options={mapDominiToOptions(getDominio(domini, fields.SETTORE_OCCUPAZIONE.dominio), true)}
              onChange={value => {
                if (
                  value !== fields.SETTORE_OCCUPAZIONE_ALTRO.altroId &&
                  formValues[fields.SETTORE_OCCUPAZIONE_ALTRO.id]
                ) {
                  change(fields.SETTORE_OCCUPAZIONE_ALTRO.id, '')
                }
              }}
              component={renderSelect}
            />
            <Field
              key={2}
              name={fields.SETTORE_OCCUPAZIONE_ALTRO.id}
              size={6}
              label={
                listaDomande.find(c => c.idDomanda === fields.SETTORE_OCCUPAZIONE_ALTRO.templateId).testoDomanda + '*'
              }
              options={mapDominiToOptions(getDominio(domini, fields.SETTORE_OCCUPAZIONE_ALTRO.dominio), true)}
              disabled={'' + formValues[fields.SETTORE_OCCUPAZIONE.id] !== fields.SETTORE_OCCUPAZIONE.altroId}
              component={renderSelect}
            />
          </Row>
          <Row>
            <Col s={12}>
              <Field
                name={fields.OPERARE_SETTORI.id}
                label={listaDomande.find(c => c.idDomanda === fields.OPERARE_SETTORI.templateId).testoDomanda + '*'}
                handlerFn={value => change(fields.OPERARE_SETTORI.id, value)}
                options={listaDomande.find(c => c.idDomanda === fields.OPERARE_SETTORI.templateId).listaRisposte}
                component={renderYesNoQuestion}
              />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Field
                name={fields.OPERARE_AFFARI.id}
                label={listaDomande.find(c => c.idDomanda === fields.OPERARE_AFFARI.templateId).testoDomanda + '*'}
                handlerFn={value => change(fields.OPERARE_AFFARI.id, value)}
                options={listaDomande.find(c => c.idDomanda === fields.OPERARE_AFFARI.templateId).listaRisposte}
                component={renderYesNoQuestion}
                change={change}
              />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Field
                name={fields.ORGANIZZAZIONE_NO_PROFIT.id}
                label={
                  listaDomande.find(c => c.idDomanda === fields.ORGANIZZAZIONE_NO_PROFIT.templateId).testoDomanda + '*'
                }
                handlerFn={value => {
                  change(fields.ORGANIZZAZIONE_NO_PROFIT.id, value)
                }}
                options={
                  listaDomande.find(c => c.idDomanda === fields.ORGANIZZAZIONE_NO_PROFIT.templateId).listaRisposte
                }
                component={renderYesNoQuestion}
              />
            </Col>
          </Row>

          {checkVisibility(fields.ORGANIZZAZIONE_NO_PROFIT.id, [
            fields.FONTE_PROVENIENZA.id,
            fields.CLASSE_BENEFICIARI.id
          ]) && (
            <Fragment>
              <Row>
                <Field
                  name={fields.CLASSE_BENEFICIARI.id}
                  s={6}
                  maxLength={50}
                  label={
                    listaDomande.find(c => c.idDomanda === fields.CLASSE_BENEFICIARI.templateId).testoDomanda + '*'
                  }
                  value={formValues[fields.CLASSE_BENEFICIARI.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
                <Field
                  name={fields.FONTE_PROVENIENZA.id}
                  s={6}
                  maxLength={50}
                  label={listaDomande.find(c => c.idDomanda === fields.FONTE_PROVENIENZA.templateId).testoDomanda + '*'}
                  value={formValues[fields.FONTE_PROVENIENZA.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Row>
            </Fragment>
          )}
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching} />
        </form>
      </CollapsibleItem>
    )
  }
}

AntiRiciclaggioGiuridiche.propTypes = {
  onSave: PropTypes.func,
  domini: PropTypes.object,
  resetForm: PropTypes.func,
  dominioRequest: PropTypes.object,
  template: PropTypes.object,
  previousForm: PropTypes.object,
  handleSubmit: PropTypes.func,
  isDirty: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  change: PropTypes.func,
  touch: PropTypes.func,
  untouch: PropTypes.func,
  initialize: PropTypes.func,
  apiSuccess: PropTypes.bool,
  isSummaryLightPGFetching: PropTypes.bool,
  isSummaryLightPGApiError: PropTypes.bool,
  requestFromAntiriciclaggio: PropTypes.bool,
  formValues: PropTypes.object,
  fields: PropTypes.object,
  status: PropTypes.any,
  dataNascitaCF: PropTypes.any
}

AntiRiciclaggioGiuridiche.defaultProps = {
  formValues: {}
}

export default AntiRiciclaggioGiuridiche
