import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { Field } from 'redux-form'
import { INFORMAZIONI_PERSONALI } from '../../../../store/modules/forms/constants'
import AccordionHeader from '../../../../components/AccordionHeader'
import InputText from '../../../../components/Inputs/Text'
import {
  capitalizeString,
  fieldSpreader,
  getAutocompleteDefaultOption,
  getDominio,
  getValueDominioFromKey,
  mapDominiToAutocomplete
} from '../../utils'
import { renderAutocomplete, renderDatePicker } from '../../../../components/Forms/renderFields'
import * as dataSB from '../../../../static-data/data-servizio-base.json'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import { checkInvalidBirthdate } from '../../../../utils'

const infoPersonali = dataSB.censimento_anagrafica.info_personali

class InformazioniPersonali extends Component {
  constructor(props) {
    super(props)
    this.state = { saved: false }

    this.submit = this.submit.bind(this)
    this.checkNazione = this.checkNazione.bind(this)
    this.checkProvincia = this.checkProvincia.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { formValues, initialize, resetForm } = this.props

    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      initialize(formValues)
      resetForm(INFORMAZIONI_PERSONALI)
      this.setState({ saved: true })
    }
  }

  checkNazione(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields
    } = this.props
    if (isItalia) {
      return formValues[fields.NAZIONE_NASCITA.id] === fields.NAZIONE_NASCITA.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, fields.NAZIONE_NASCITA.dominio, formValues[fields.NAZIONE_NASCITA.id])
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      fields
    } = this.props
    return !!getValueDominioFromKey(listaDomini, fields.PROVINCIA_NASCITA.dominio, value)
  }

  submit(values) {
    const { onSave, initialValues, fields } = this.props
    const spreadFields = fieldSpreader(fields)
    const { NOME, COGNOME } = fields
    const isFullNameChanged =
      initialValues[NOME.id] !== values[NOME.id] || initialValues[COGNOME.id] !== values[COGNOME.id]

    onSave(
      Object.keys(values).reduce(
        (res, cur) => {
          const { dominio } = spreadFields.find(el => el.id === cur)
          if (dominio && (/^[a-zA-Z0-9]*$/g.test(values[cur]) || values[cur] === '')) {
            return {
              ...res,
              [cur]: { codiceDominio: dominio, chiave: values[cur] }
            }
          }
          if (isFullNameChanged && (NOME.id === cur || COGNOME.id === cur)) {
            return { ...res, [cur]: capitalizeString(values[cur]) }
          }
          return { ...res, [cur]: values[cur] }
        },
        { isFullNameChanged }
      )
    )
    // getDataRegistry('', this.props.idCliente, this.props.idIncarico, true, false)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      status,
      domini,
      updateDominio,
      formValues,
      formErrors,
      fields,
      change,
      touch,
      isFetching,
      isDataFromCalculateCF
    } = this.props

    return (
      <CollapsibleItem
        itemKey={INFORMAZIONI_PERSONALI}
        header={infoPersonali.title_accordion}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <Row>
            <Field
              name={fields.NOME.id}
              s={6}
              maxLength={fields.NOME.maxLength}
              label={fields.NOME.label}
              error={formErrors[fields.NOME.id]}
              value={formValues[fields.NOME.id]}
              change={change}
              touch={touch}
              disabled={isDataFromCalculateCF}
              component={InputText}
            />
            <Field
              name={fields.COGNOME.id}
              s={6}
              maxLength={fields.COGNOME.maxLength}
              label={fields.COGNOME.label}
              error={formErrors[fields.COGNOME.id]}
              value={formValues[fields.COGNOME.id]}
              change={change}
              touch={touch}
              disabled={isDataFromCalculateCF}
              component={InputText}
            />
          </Row>
          <Row>
            <Col s={6}>
              <Field
                name={fields.NAZIONE_NASCITA.id}
                s={12}
                label={fields.NAZIONE_NASCITA.label}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.NAZIONE_NASCITA.dominio,
                  formValues[fields.NAZIONE_NASCITA.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, fields.NAZIONE_NASCITA.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[fields.PROVINCIA_NASCITA.id]) {
                    change(fields.PROVINCIA_NASCITA.id, '')
                  }
                  if (formValues[fields.COMUNE_ESTERO_NASCITA.id]) {
                    change(fields.COMUNE_ESTERO_NASCITA.id, '')
                  }
                  if (formValues[fields.COMUNE_NASCITA.id]) {
                    change(fields.COMUNE_NASCITA.id, '')
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
            <Col s={6}>
              <Field
                name={fields.PROVINCIA_NASCITA.id}
                s={12}
                label={fields.PROVINCIA_NASCITA.label}
                threshold={0}
                disabled={!this.checkNazione(true)}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.PROVINCIA_NASCITA.dominio,
                  formValues[fields.PROVINCIA_NASCITA.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, fields.PROVINCIA_NASCITA.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[fields.COMUNE_NASCITA.id]) {
                    change(fields.COMUNE_NASCITA.id, '')
                  }
                  if (this.checkProvincia(value)) {
                    updateDominio(fields.COMUNE_NASCITA.dominio, value)
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
          </Row>
          <Row>
            {this.checkNazione(true) && (
              <Col s={6}>
                <Field
                  name={fields.COMUNE_NASCITA.id}
                  s={12}
                  threshold={0}
                  label={fields.COMUNE_NASCITA.label}
                  disabled={!this.checkProvincia(formValues[fields.PROVINCIA_NASCITA.id])}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    fields.COMUNE_NASCITA.dominio,
                    formValues[fields.COMUNE_NASCITA.id]
                  )}
                  options={
                    formValues[fields.PROVINCIA_NASCITA.id]
                      ? mapDominiToAutocomplete(getDominio(domini, fields.COMUNE_NASCITA.dominio))
                      : []
                  }
                  handleChange={(field, value) => {
                    change(field, value)
                  }}
                  component={renderAutocomplete}
                />
              </Col>
            )}
            {!this.checkNazione(true) && (
              <Field
                name={fields.COMUNE_ESTERO_NASCITA.id}
                s={6}
                maxLength={50}
                label={fields.COMUNE_ESTERO_NASCITA.label}
                error={formErrors[fields.COMUNE_ESTERO_NASCITA.id]}
                value={formValues[fields.COMUNE_ESTERO_NASCITA.id]}
                disabled={!this.checkNazione()}
                change={change}
                touch={touch}
                component={InputText}
              />
            )}

            <Field
              name={fields.DATA_NASCITA.id}
              s={6}
              openDirection="down"
              anchorDirection="right"
              label={fields.DATA_NASCITA.label}
              readOnly
              placeholder=""
              isOutsideRange={date => checkInvalidBirthdate(date)}
              defaultDate={formValues[fields.DATA_NASCITA.id]}
              onDateChange={date => change(fields.DATA_NASCITA.id, date.format())}
              disabled={isDataFromCalculateCF}
              component={renderDatePicker}
            />
          </Row>
          <Row>
            <Col s={6}>
              <Field
                name={fields.CITTADINANZA.id}
                size={12}
                label={fields.CITTADINANZA.label}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.CITTADINANZA.dominio,
                  formValues[fields.CITTADINANZA.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, fields.CITTADINANZA.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  if (value && value === formValues[fields.CITTADINANZA_ALTRO.id]) {
                    change(fields.CITTADINANZA_ALTRO.id, '')
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
          </Row>
          <ButtonSubmit
            disabled={
              submitting || !isDirty || invalid || isFetching || Object.keys(formErrors).some(e => formErrors[e])
            }
          />
        </form>
      </CollapsibleItem>
    )
  }
}

InformazioniPersonali.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  initialValues: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  fields: PropTypes.object,
  domini: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  change: PropTypes.func,
  touch: PropTypes.func,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  updateDominio: PropTypes.func,
  status: PropTypes.any,
  // getDataRegistry: PropTypes.any,
  // idCliente: PropTypes.any,
  // idIncarico: PropTypes.any,
  isDataFromCalculateCF: PropTypes.bool
}

InformazioniPersonali.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default InformazioniPersonali
