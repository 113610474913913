import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { SOGGETTI_CORRELATI } from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'
import SoggettiGiaCorrelatiTable from '../../../../components/Tables/SoggettiCorrelati/SoggettiGiaCorrelatiTable'
import Title from '../../../../components/Title'

const fields = allFields[1][SOGGETTI_CORRELATI]

class SoggettiCorrelatiReview extends Component {
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }
  render() {
    /* const {
      data,
      domini: { listaDomini }
    } = this.props */
    const { TABELLA } = fields
    const da = this.props.data
    var isEmpty = Object.keys(da).length === 0 && da.constructor === Object
    if (!isEmpty) {
      return (
        <CollapsibleItem
          header={dataSB.censimento_anagrafica.soggettiCorrelati.title_accordion}
          icon="keyboard_arrow_down"
          itemKey={SOGGETTI_CORRELATI}
          labelInfo={this.props.accordionHeader}>
          <Row>
            <Col s={12}>
              <Title>{TABELLA.label}</Title>
              <br />
              <SoggettiGiaCorrelatiTable list={this.props.data} {...this.props} />
            </Col>
          </Row>
        </CollapsibleItem>
      )
    }
    return null
  }
}

SoggettiCorrelatiReview.propTypes = {
  accordionHeader: PropTypes.object,
  // data: PropTypes.object,
  // domini: PropTypes.object,
  // soggettiCorrelatiListData: PropTypes.array
  data: PropTypes.any
}

export default SoggettiCorrelatiReview
