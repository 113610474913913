import * as actionTypes from './constants'
import { setPromotore } from '../../../routes/ConsulenzaBaseContainerLayout/utils'

const initialState = {
  services: [],
  clienti: [],
  totServizi: null,
  flagAperturaServizi: false,
  admin: false,
  idPromotore: '',
  query: {
    pageBy: {
      pageRequest: 1,
      pageSize: 20
    },
    sortBy: [
      {
        field: 'insertionDate',
        type: 'asc'
      }
    ],
    conditions: []
  },
  serviceSearchRequest: {
    fetching: false,
    error: null
  },
  postServizioInfoRequest: {
    fetching: false,
    error: null
  },
  postServiziRequest: {
    fetching: false,
    error: null
  },
  getServiceInfoRequest: {
    fetching: false,
    error: null
  },
  serviceData: {},
  percentualiCommissionali: [],
  domainServiceList: [],
  getDomainServiceRequest: {
    fetching: false,
    error: null
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SERVICE_SEARCH_START:
      return {
        ...state,
        query: action.query,
        serviceSearchRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SET_TOT_SERVIZI:
      return Object.assign({}, state, { totServizi: action.totServizi })
    case actionTypes.SET_ID_PROMOTORE:
      setPromotore(state.idPromotore)
      return state
    case actionTypes.SERVICE_SEARCH_SUCCESS:
      return {
        ...state,
        // TODO  set page size [limit pagination]
        services: action.services,
        clienti: action.clienti,
        totServizi: action.totServizi,
        idPromotore: action.idPromotore,
        admin: action.admin,
        flagAperturaServizi: action.flagAperturaServizi,
        serviceSearchRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.SERVICE_SEARCH_ERROR:
      return {
        ...state,
        serviceSearchRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.SERVICE_SAVE_START:
      return {
        ...state,
        postServizioInfoRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SERVICE_SAVE_SUCCESS:
      return {
        ...state,
        postServizioInfoRequest: {
          fetching: false,
          error: null
        },
        serviceData: {
          ...state.serviceData,
          [`servizio_${action.payload.idTipoServizio.chiave}`]: {
            ...state.serviceData[`servizio_${action.payload.idTipoServizio.chiave}`],
            ...action.payload
          }
        }
      }
    case actionTypes.SERVICE_SAVE_ERROR:
      return {
        ...state,
        postServizioInfoRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_SERVICE_INFO_START:
      return {
        ...state,
        getServiceInfoRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_SERVICE_INFO_SUCCESS:
      return {
        ...state,
        serviceData: action.serviceData,
        percentualiCommissionali: action.percentualiCommissionali,
        getServiceInfoRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_SERVICE_INFO_ERROR:
      return {
        ...state,
        getServiceInfoRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.SERVICE_SAVE_MULTIPLE_START:
      return {
        ...state,
        postServiziRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SERVICE_SAVE_MULTIPLE_SUCCESS:
      return {
        ...state,
        postServiziRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.SERVICE_SAVE_MULTIPLE_ERROR:
      return {
        ...state,
        postServiziRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_DOMAIN_SERVICE_START:
      return {
        ...state,
        getDomainServiceRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DOMAIN_SERVICE_SUCCESS:
      return {
        ...state,
        domainServiceList: action.domainServiceList,
        getDomainServiceRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DOMAIN_SERVICE_ERROR:
      return {
        ...state,
        getDomainServiceRequest: {
          fetching: false,
          error: action.error
        }
      }
  }

  return state
}
