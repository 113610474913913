import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-materialize'

const Confirm = ({ text = '', className = '', error = false, children }) => (
  <Row className={`confirm ${className}${error ? ' error' : ''}`}>
    {children}
    {text}
  </Row>
)
export default Confirm

Confirm.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.object
}
