import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'
import { renderCheckbox } from '../../../components/Forms/renderFields'
import Title from '../../../components/Title'
import { Field } from 'redux-form'

const SubRadioCheckbox = ({
  formValues = {},
  template: {
    flagDipendenza,
    listaDipendenze,
    strutturaInternaDomande: {
      domande,
      listaDominiRisposte: [{ risposte: listaRisposte }]
    }
  },
  changeCallback,
  disabled = false,
  isValidAllSelected = true,
  singleSelection = false,
  validate,
  onChangeCallbackRadio = () => {}
}) => {
  // Variabile d'appoggio che swappa tra true/false per triggerare lo useEffect (evitando il loop)
  let [swapVariable, setSwapVariable] = useState(true)

  const doSwap = () => setSwapVariable(!swapVariable)

  useEffect(() => {
    let _value = formValues[`field_${domande[0].idDomanda}`]
    if (_value && _value !== '' + listaCheckbox[0].listaDipendenze[0].idRispostaDipendenza) {
      const list = listaCheckbox.map(v => `field_${domande[0].idDomanda}_${v.idRisposta}`)
      const isSomeoneSelected = list.some(k => formValues[k])
      let isError
      if (singleSelection) {
        isError = list.filter(k => formValues[k]).length !== 1
      } else if (isValidAllSelected) {
        isError = !isSomeoneSelected
      } else {
        const isEveryoneSelected = list.every(k => formValues[k])
        isError = !isSomeoneSelected || isEveryoneSelected
      }
      validate(isError)
    } else {
      validate(false)
    }
  }, [swapVariable])

  const isHidden = risposta => {
    if (!formValues[`field_${domande[0].idDomanda}`]) {
      return true
    }
    if (risposta.flagDipendenza && Array.isArray(risposta.listaDipendenze)) {
      if (
        risposta.listaDipendenze.some(
          dip =>
            formValues[`field_${dip.idDomandaDipendenza}`] === '' + dip.idRispostaDipendenza &&
            dip.tipoDipendenza === 'hidden'
        )
      ) {
        formValues[`field_${domande[0].idDomanda}_${risposta.idRisposta}`] = false
        return true
      }
    }
    return false
  }

  const isDisabled = risposta => {
    if (disabled) return true
    if (risposta.flagDipendenza && Array.isArray(risposta.listaDipendenze)) {
      return !risposta.listaDipendenze.some(
        dip => formValues[`field_${dip.idDomandaDipendenza}`] === '' + dip.idRispostaDipendenza
      )
    }
    // Check su padre (se true sbianca la riposta dipendente, deve essere impostata la onChangeCallback)
    if (flagDipendenza && Array.isArray(listaDipendenze)) {
      const res = listaDipendenze.some(
        dip =>
          dip.tipoDipendenza === 'disable' &&
          (formValues[`field_${dip.idDomandaDipendenza}`] == null ||
            formValues[`field_${dip.idDomandaDipendenza}`] === '' ||
            formValues[`field_${dip.idDomandaDipendenza}`] === '' + dip.idRispostaDipendenza)
      )
      return res
    }
    return false
  }

  const uncheckActive = event => {
    onChangeCallbackRadio(event)
    listaCheckbox.forEach(risposta => {
      let field = `field_${domande[0].idDomanda}_${risposta.idRisposta}`
      if (
        formValues[field] &&
        risposta.listaDipendenze.some(
          dip => event.target.value === '' + dip.idRispostaDipendenza && dip.tipoDipendenza === 'hidden'
        )
      ) {
        changeCallback(field, false)
      }
    })
    doSwap()
  }

  const uncheckOtherAnswers = event => {
    listaCheckbox.forEach(risposta => {
      let field = `field_${domande[0].idDomanda}_${risposta.idRisposta}`
      changeCallback(field, event.target.id === risposta.idRisposta)
    })
    doSwap()
  }

  const splitListaRisposte = () =>
    listaRisposte.reduce(
      (acc, val) => {
        let res = acc
        if (!val.flagDipendenza) {
          res[0].push(val)
        } else {
          res[1].push(val)
        }
        return [...res]
      },
      [[], []]
    )

  const domanda = domande[0]
  const [listaRadio, listaCheckbox] = splitListaRisposte()
  return (
    <React.Fragment>
      <div>
        <Title>{domanda.testoDomanda}</Title>
      </div>
      <Row>
        {/* Radio */}
        <Col s={6}>
          {listaRadio.map(risposta => (
            <Row key={risposta.idRisposta}>
              <Col>
                <div className="radio-vertical">
                  <p>
                    <Field
                      name={`field_${domanda.idDomanda}`}
                      className="with-gap"
                      type="radio"
                      id={`${domanda.idDomanda}-${risposta.idRisposta}`}
                      value={'' + risposta.idRisposta}
                      component="input"
                      onChange={uncheckActive}
                      disabled={isDisabled(risposta)}
                    />
                    <label htmlFor={`${domanda.idDomanda}-${risposta.idRisposta}`}>
                      {risposta.descRisposta}
                      {risposta.descSubRisposta && <span className="subtitle">{risposta.descSubRisposta}</span>}
                    </label>
                  </p>
                </div>
              </Col>
            </Row>
          ))}
        </Col>
        {/* Checkbox */}
        {!singleSelection && (
          <Col s={6}>
            {listaCheckbox.map(risposta => (
              <Row key={risposta.idRisposta}>
                <Col className={isHidden(risposta) ? 'col-hidden' : ''}>
                  <div className="radio-vertical sub-radio-checkbox">
                    <p key={risposta.idRisposta}>
                      <Field
                        name={`field_${domanda.idDomanda}_${risposta.idRisposta}`}
                        type="checkbox"
                        label={risposta.descRisposta}
                        className={
                          formValues[`field_${domanda.idDomanda}_${risposta.idRisposta}`]
                            ? 'filled-in active'
                            : 'filled-in'
                        }
                        component={renderCheckbox}
                        onChange={doSwap}
                      />
                    </p>
                  </div>
                </Col>
              </Row>
            ))}
          </Col>
        )}
        {/* Radiobutton */}
        {singleSelection && (
          <Col s={6}>
            {listaCheckbox.map(risposta => (
              <Row key={risposta.idRisposta}>
                <Col className={isHidden(risposta) ? 'col-hidden' : ''}>
                  <div className="radio-vertical">
                    <p key={risposta.idRisposta}>
                      <Field
                        name={`field_${domanda.idDomanda}_${risposta.idRisposta}`}
                        type="radio"
                        className="with-gap"
                        component="input"
                        onChange={uncheckOtherAnswers}
                        value={'' + risposta.idRisposta}
                        id={`${domanda.idDomanda}-${risposta.idRisposta}`}
                        props={{
                          name: `field_${domanda.idDomanda}_sub_answers`
                        }}
                        checked={formValues[`field_${domanda.idDomanda}_${risposta.idRisposta}`]}
                        parse={(value, name) => true}
                      />
                      <label htmlFor={`${domanda.idDomanda}-${risposta.idRisposta}`}>
                        {risposta.descRisposta}
                        {risposta.descSubRisposta && <span className="subtitle">{risposta.descSubRisposta}</span>}
                      </label>
                    </p>
                  </div>
                </Col>
              </Row>
            ))}
          </Col>
        )}
      </Row>
    </React.Fragment>
  )
}
export default SubRadioCheckbox

SubRadioCheckbox.propTypes = {
  formValues: PropTypes.object,
  template: PropTypes.shape({
    strutturaInternaDomande: PropTypes.shape({
      domande: PropTypes.array,
      listaDominiRisposte: PropTypes.array
    })
  }).isRequired,
  changeCallback: PropTypes.func,
  onChangeCallbackRadio: PropTypes.func,
  validate: PropTypes.func,
  disabled: PropTypes.bool,
  isValidAllSelected: PropTypes.bool,
  singleSelection: PropTypes.bool
}
