import React from 'react'
import PropTypes from 'prop-types'
import { checkPromotore } from '../ConsulenzaBaseContainerLayout/utils'

class FirmaWrapperComponent extends React.Component {
  constructor(props) {
    super(props)
    this.gotoParentDocument = this.gotoParentDocument.bind(this)
  }

  componentDidMount() {
    this.gotoParentDocument()
  }

  backHome() {
    this.props.history.push('/')
  }

  gotoParentDocument() {
    const fakeToken = (/token=([a-zA-Z0-9_.\-~]*)(&.*)*$/.exec(document.location.href) || [])[1]
    const idIncarico = (/idIncarico=([a-zA-Z0-9_.\-~]*)(&.*)*$/.exec(document.location.href) || [])[1]
    const idCliente = (/idCliente=([a-zA-Z0-9_.\-~]*)(&.*)*$/.exec(document.location.href) || [])[1]
    const basePath = (/basePath=([a-zA-Z0-9_./:\-~]*)(&.*)*$/.exec(document.location.href) || [])[1]

    if (fakeToken && idIncarico && basePath && checkPromotore(fakeToken)) {
      const { randomToken } = localStorage
      parent.document.location.href = `${basePath}/firma-digitale/${idIncarico}?token=${randomToken}&idCliente=${idCliente}`
    }
  }

  render() {
    return (
      <div className="namirial-frame-body">
        <div className="spinnerWrapper">
          <div className="loader-inner ball-spin-fade-loader">
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <i className="icon-check-codice-fiscale-success" />
        <p>Grazie per aver firmato!</p>
        {/* <Button flat waves="light" onClick={this.gotoParentDocument}>concludi</Button> */}
      </div>
    )
  }
}

FirmaWrapperComponent.propTypes = {
  history: PropTypes.object
}

export default FirmaWrapperComponent
