import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, isDirty } from 'redux-form'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../../components/Collapsible'
import AccordionHeader from '../../../../../components/AccordionHeader'
import ModalitaPagamento from '../common/ModalitaPagamento'
import InputPlusMinus from '../../../../../components/InputPlusMinus'
import SpeseButton from '../common/SpeseButton'
import Assegno from '../common/Assegno'
import TransferTitoli from '../common/TransferTitoli'
import { APERTURA_RTO_BPM } from '../../../../../store/modules/forms/constants'
import allFields from '../../../fields'
import { loadDefaultFields } from '../../../../ConsulenzaBaseContainerLayout/utils'
import { createPayload, loadServiceData, getIdServizio } from '../../utils'
import dataSB from '../../../../../static-data/data-servizio-complesso.json'
import ButtonSubmit from '../../../../../components/ButtonSubmit'
import TipologiaContoForm from '../common/TipologiaContoForm'

const fields = allFields[APERTURA_RTO_BPM]
const defaultFields = loadDefaultFields(fields)
const selector = formValueSelector(APERTURA_RTO_BPM)
const idServizio = getIdServizio(APERTURA_RTO_BPM)

const validate = values => {
  const errors = {}
  const { ABI, CAB, NUM_ASSEGNO, NUM_CONTO } = fields

  if (!!values[ABI.id] && !new RegExp(ABI.validazione).test(values[ABI.id])) {
    errors[ABI.id] = 'Il campo deve avere 5 numeri'
  }
  if (!!values[CAB.id] && !new RegExp(CAB.validazione).test(values[CAB.id])) {
    errors[CAB.id] = 'Il campo deve avere 5 numeri'
  }
  if (!!values[NUM_ASSEGNO.id] && !new RegExp(NUM_ASSEGNO.validazione).test(values[NUM_ASSEGNO.id])) {
    errors[NUM_ASSEGNO.id] = 'Il campo deve avere 13 numeri'
  }
  if (!!values[NUM_CONTO.id] && !new RegExp(NUM_CONTO.validazione).test(values[NUM_CONTO.id])) {
    errors[NUM_CONTO.id] = 'Il campo deve avere 8 numeri'
  }

  return errors
}

class RtoBpm extends Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, expanded: false }
    this.isDisabled = this.isDisabled.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && !this.props.apiError && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(APERTURA_RTO_BPM)
      this.setState({ saved: true })
    }
  }

  isDisabled() {
    const { FLAG_ASSEGNO, FLAG_BONIFICO } = fields
    const { formValues } = this.props
    return !formValues[FLAG_ASSEGNO.id] && !formValues[FLAG_BONIFICO.id]
  }

  submit(values) {
    const { serviceData, onSave } = this.props

    let obTosend = createPayload(fields, values, serviceData)
    if (obTosend.idTipologiaConto === null) {
      obTosend.idTipologiaConto = '1'
    }
    onSave(serviceData.idServizio, obTosend)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      getStatus,
      isDirty,
      invalid,
      domini,
      formValues,
      percentualiCommissionali,
      change,
      isFetching,
      serviceInformation
    } = this.props
    const { COMM_AZIONI, COMM_OBBLIGAZIONI, FLAG_ASSEGNO } = fields
    const percComms = percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    const percCommAzioni = percComms.find(p => p.codiceCommissione === 4)
    const percCommObbligazioni = percComms.find(p => p.codiceCommissione === 5)

    return (
      <CollapsibleItem
        itemKey={APERTURA_RTO_BPM}
        header={serviceInformation[0].descTipoServizio}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, getStatus(this.state.saved))}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalitaPagamento
            fields={fields}
            domini={domini}
            formValues={formValues}
            change={change}
            origineRequired={!this.isDisabled()}
            importoRequired={!this.isDisabled()}
            isDisabled={this.isDisabled}
          />
          {formValues[FLAG_ASSEGNO.id] && (
            <Row>
              <Assegno fields={fields} formValues={formValues} change={change} />
            </Row>
          )}
          <TransferTitoli fields={fields} label={dataSB.configurazione.mezzi_pagamento.transfer_titoli_bpm} />
          <Row className="m-top10">
            <Col s={12} className="spese-button">
              <SpeseButton
                expanded={this.state.expanded}
                handler={() => this.setState({ expanded: !this.state.expanded })}
              />
            </Col>
          </Row>
          {this.state.expanded && (
            <Row>
              <Col
                s={6}
                label={COMM_AZIONI.label}
                value={formValues[COMM_AZIONI.id]}
                stepValue={percCommAzioni.percentualeIntervallo}
                minValue={percCommAzioni.percentualeMinima || percCommAzioni.percentualeDefault}
                maxValue={percCommAzioni.percentualeMassima || percCommAzioni.percentualeDefault}
                onChange={value => change(COMM_AZIONI.id, value)}
              />
              <Col s={6}>
                <InputPlusMinus
                  label={COMM_OBBLIGAZIONI.label}
                  value={formValues[COMM_OBBLIGAZIONI.id]}
                  stepValue={percCommObbligazioni.percentualeIntervallo}
                  minValue={percCommObbligazioni.percentualeMinima || percCommObbligazioni.percentualeDefault}
                  maxValue={percCommObbligazioni.percentualeMassima || percCommObbligazioni.percentualeDefault}
                  onChange={value => change(COMM_OBBLIGAZIONI.id, value)}
                />
              </Col>
            </Row>
          )}
          <TipologiaContoForm fields={fields} tipoServizio="BPM" formValues={formValues} {...this.props} />
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching} />
        </form>
      </CollapsibleItem>
    )
  }
}

RtoBpm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  formValues: PropTypes.object,
  domini: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  serviceData: PropTypes.object,
  percentualiCommissionali: PropTypes.array,
  getStatus: PropTypes.func,
  serviceInformation: PropTypes.array
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData.servizio_3,
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    )
  },
  formValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData.servizio_3,
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    ),
    ...selector(state, ...Object.keys(defaultFields).reduce((res, cur) => [...res, cur], []))
  },
  isDirty: isDirty(APERTURA_RTO_BPM)(state),
  isFetching: state.services.postServizioInfoRequest.fetching,
  apiError: state.services.postServizioInfoRequest.error,
  serviceInformation: state.pratica.pratica.servizi.filter(item => item.idTipoServizio === 3)
}))(
  reduxForm({
    form: APERTURA_RTO_BPM,
    validate
  })(RtoBpm)
)
