import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { Field } from 'redux-form'
import { Row, Col } from 'react-materialize'

import InputText from '../../../../components/Inputs/Text'
// import dataSB from '../../../../static-data/data-servizio-base.json'

import SchedaDatiPepTitolare from '../SchedaDatiPepTitolare/SchedaDatiPepTitolareComponent'

import {
  getDominio,
  getAutocompleteDefaultOption,
  mapDominiToAutocomplete,
  getValueDominioFromKey,
  mapDominiToOptions
} from '../../utils'

import {
  // renderInputImage,
  // renderInputPdf,
  renderDatePicker,
  renderAutocomplete,
  renderSelect,
  renderCheckbox
} from '../../../../components/Forms/renderFields'

// const documenti = dataSB.acquisizione.documento_identita

class SchedaDatiTitolareItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      test: true,
      personaEsposta: null,
      pepEstero: null,
      pepNazionale: null,
      unicoTitolare: null,
      unTitolare: null,
      titolareeffettivo: false,
      counter: 0,
      addressCorrispNotMatch: false,
      refresh: false,
      visible: true
    }
    // this.submit = this.submit.bind(this)
    // this.props.updateDominio = this.props.updateDominio.bind(this)
    // this.checkNazione = this.checkNazione.bind(this)
    this.saveandGetdata = this.saveandGetdata.bind(this)
  }

  componentDidUpdate(prevProps) {
    // if (
    //   prevProps.getDataFromTaxCodeRequest &&
    //   this.props.getDataFromTaxCodeRequest &&
    //   prevProps.getDataFromTaxCodeRequest.fetching &&
    //   !this.props.getDataFromTaxCodeRequest.fetching &&
    //   !this.props.getDataFromTaxCodeRequest.error
    // ) {
    //   const { formValues, submit, counter, dataFromTaxCode } = this.props
    //   submit({ ...formValues, ...dataFromTaxCode[counter] }, { ...formValues, ...dataFromTaxCode[counter] })
    //   this.setState({ visible: false })
    //   let _this = this
    //   setTimeout(function() {
    //     _this.setState({ visible: true })
    //   }, 0)
    // }
  }

  checkNazionePostal(isItalia) {
    let bool = false
    const {
      formValues,
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let NAZIONE_POSTAL = fields[`NAZIONE_POSTAL_${counter}`]
    if (isItalia) {
      bool = formValues[NAZIONE_POSTAL.id] === NAZIONE_POSTAL.italiaId
      return bool
    }
    bool = getValueDominioFromKey(listaDomini, NAZIONE_POSTAL.dominio, formValues[NAZIONE_POSTAL.id])
    bool = !!bool
    return bool
  }

  checkProvinciaPostal(value) {
    const {
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let PROVINCIA_POSTAL = fields[`PROVINCIA_POSTAL_${counter}`]
    return !!getValueDominioFromKey(listaDomini, PROVINCIA_POSTAL.dominio, value)
  }

  checkComunePostal(value) {
    const {
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let COMUNE_POSTAL = fields[`COMUNE_POSTAL_${counter}`]
    return !!getValueDominioFromKey(listaDomini, COMUNE_POSTAL.dominio, value)
  }

  checkCapPostal() {
    const {
      domini: { listaDomini },
      counter,
      fields,
      formValues
    } = this.props
    let COMUNE_POSTAL = fields[`COMUNE_POSTAL_${counter}`]
    return !!getValueDominioFromKey(listaDomini, COMUNE_POSTAL.dominio, formValues[COMUNE_POSTAL.id])
  }

  //  checkCapPostal() {
  //    const {
  //      domini: { listaDomini },
  //      fields,
  //      formValues,
  //      counter
  //    } = this.props
  //    let PROVINCIA_POSTAL = fields[`PROVINCIA_POSTAL_${counter}`];
  //    let COMUNE_POSTAL = fields[`COMUNE_POSTAL_${counter}`];
  //    let CAP_POSTAL = fields[`CAP_POSTAL_${counter}`]
  //    return (
  //      !this.checkNazionePostal(true) ||
  //      !this.checkProvinciaPostal(formValues[PROVINCIA_POSTAL.id]) ||
  //      !this.checkComunePostal(formValues[COMUNE_POSTAL.id]) ||
  //      Object.keys((listaDomini.find(d => d.idDominio === CAP_POSTAL.dominio) || {}).valoriDominio || {}).length ===
  //        1
  //    )
  //  }

  checkNazione(isItalia) {
    let bool = false
    const {
      formValues,
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let NAZIONE_SCHEDA_DATI = fields[`NAZIONE_NASCITA_SCHEDA_DATI_${counter}`]
    if (isItalia) {
      bool = formValues[NAZIONE_SCHEDA_DATI.id] === NAZIONE_SCHEDA_DATI.italiaId
      return bool
    }
    bool = getValueDominioFromKey(listaDomini, NAZIONE_SCHEDA_DATI.dominio, formValues[NAZIONE_SCHEDA_DATI.id])
    bool = !!bool
    return bool
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let PROVINCIA_SCHEDA_DATI = fields[`PROVINCIA_NASCITA_SCHEDA_DATI_${counter}`]
    return !!getValueDominioFromKey(listaDomini, PROVINCIA_SCHEDA_DATI.dominio, value)
  }

  checkComune(value) {
    const {
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let COMUNE_SCHEDA_DATI = fields[`COMUNE_NASCITA_SCHEDA_DATI_${counter}`]
    return !!getValueDominioFromKey(listaDomini, COMUNE_SCHEDA_DATI.dominio, value)
  }

  checkComuneResidenza(value) {
    const {
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let COMUNE_SCHEDA_DATI = fields[`COMUNE_SCHEDA_DATI_${counter}`]
    return !!getValueDominioFromKey(listaDomini, COMUNE_SCHEDA_DATI.dominio, value)
  }

  checkNazioneResidenza(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let NAZIONE_SCHEDA_DATI = fields[`NAZIONE_SCHEDA_DATI_${counter}`]
    if (isItalia) {
      return formValues[NAZIONE_SCHEDA_DATI.id] === NAZIONE_SCHEDA_DATI.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, NAZIONE_SCHEDA_DATI.dominio, formValues[NAZIONE_SCHEDA_DATI.id])
  }
  checkProvinciaResidenza(value) {
    const {
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let PROVINCIA_SCHEDA_DATI = fields[`PROVINCIA_SCHEDA_DATI_${counter}`]
    return !!getValueDominioFromKey(listaDomini, PROVINCIA_SCHEDA_DATI.dominio, value)
  }

  // checkComuneResidenza(value) {
  //   const {
  //     domini: { listaDomini },
  //     counter,
  //     fields
  //   } = this.props
  //   let COMUNE_SCHEDA_DATI = fields[`COMUNE_SCHEDA_DATI_${counter}`]
  //   return !!getValueDominioFromKey(listaDomini, COMUNE_SCHEDA_DATI.dominio, value)
  // }

  checkCap() {
    const {
      domini: { listaDomini },
      counter,
      fields,
      formValues
    } = this.props
    let COMUNE_SCHEDA_DATI = fields[`COMUNE_SCHEDA_DATI_${counter}`]
    return !!getValueDominioFromKey(listaDomini, COMUNE_SCHEDA_DATI.dominio, formValues[COMUNE_SCHEDA_DATI.id])
  }

  // submit(values) {
  //   //   const { submitHandler, currentInitial, fields, flagCustomerIsBlacklist } = this.props
  //   //   const { values, listaAllegati } = parseDocumentsData(fields, submittedValues, currentInitial)
  //   //   Object.assign(values, { flagCustomerIsBlacklist: !!flagCustomerIsBlacklist })
  //   //   submitHandler(values, listaAllegati)
  //   // }
  //   console.log('Salva item')

  //   let obj
  //   Object.keys(values).reduce((res, cur) => {
  //     obj = { ...res, [cur]: values[cur] }
  //     return obj
  //   }, {})
  //   console.log(obj)
  // }

  isCompiled(v) {
    const { formValues = {} } = this.props
    return typeof formValues[v.id] !== 'undefined' && formValues[v.id] !== null && formValues[v.id] !== ''
  }

  showCorrispAddress() {
    this.setState({ addressCorrispNotMatch: !this.state.addressCorrispNotMatch })
  }
  saveandGetdata(counter) {
    const { formValues, fields, getDataFromTaxCode } = this.props
    let CODICE_FISCALE = fields[`CODICE_FISCALE_${counter}`]
    getDataFromTaxCode(formValues[CODICE_FISCALE.id].toUpperCase(), counter).then(() => {
      if (
        this.props.getDataFromTaxCodeRequest &&
        !this.props.getDataFromTaxCodeRequest.fetching &&
        !this.props.getDataFromTaxCodeRequest.error
      ) {
        const { submit, counter, dataFromTaxCode } = this.props

        submit({ ...formValues, ...dataFromTaxCode[counter] }, { ...formValues, ...dataFromTaxCode[counter] })

        this.setState({ visible: false })
        let _this = this
        setTimeout(function() {
          _this.setState({ visible: true })
        }, 0)
      }
    })
  }

  render() {
    // this.props.counter
    const {
      domini,
      formValues,
      formErrors,
      change,
      touch,
      counter,
      updateDominio,
      fields,
      idAddictionalOwner,
      deleteAddictionalOwner
    } = this.props

    /*


    */

    let NOME = fields[`NOME_${counter}`]
    let COGNOME = fields[`COGNOME_${counter}`]
    let CODICE_FISCALE = fields[`CODICE_FISCALE_${counter}`]
    let DATA_NASCITA = fields[`DATA_NASCITA_${counter}`]
    let NAZIONE_NASCITA_SCHEDA_DATI = fields[`NAZIONE_NASCITA_SCHEDA_DATI_${counter}`]
    let PROVINCIA_NASCITA_SCHEDA_DATI = fields[`PROVINCIA_NASCITA_SCHEDA_DATI_${counter}`]
    let COMUNE_NASCITA_SCHEDA_DATI = fields[`COMUNE_NASCITA_SCHEDA_DATI_${counter}`]
    let CAP_SCHEDA_DATI = fields[`CAP_SCHEDA_DATI_${counter}`]
    let COMUNE_NASCITA_ESTERO_SCHEDA_DATI = fields[`COMUNE_NASCITA_ESTERO_SCHEDA_DATI_${counter}`]
    let CITTADINANZA = fields[`CITTADINANZA_${counter}`]

    let NAZIONE_SCHEDA_DATI = fields[`NAZIONE_SCHEDA_DATI_${counter}`]
    let PROVINCIA_SCHEDA_DATI = fields[`PROVINCIA_SCHEDA_DATI_${counter}`]
    let COMUNE_SCHEDA_DATI = fields[`COMUNE_SCHEDA_DATI_${counter}`]
    let COMUNE_ESTERO_SCHEDA_DATI = fields[`COMUNE_ESTERO_SCHEDA_DATI_${counter}`]
    let INDIRIZZO = fields[`INDIRIZZO_${counter}`]
    let EMAIL = fields[`EMAIL_${counter}`]
    let CELLULARE = fields[`CELLULARE_${counter}`]
    let NUMERO_DOCUMENTO = fields[`NUMERO_DOCUMENTO_${counter}`]
    let TIPO_DOCUMENTO = fields[`TIPO_DOCUMENTO_${counter}`]
    let COMUNE_RILASCIO = fields[`COMUNE_RILASCIO_${counter}`]
    let DATA_RILASCIO = fields[`DATA_RILASCIO_${counter}`]
    let DATA_SCADENZA = fields[`DATA_SCADENZA_${counter}`]
    let TIPO_RELAZIONE = fields[`TIPO_RELAZIONE_${counter}`]
    let TIPO_RELAZIONE_ALTRO = fields[`TIPO_RELAZIONE_ALTRO_${counter}`]

    let NOME_POSTAL = fields[`NOME_POSTAL_${counter}`]
    let COGNOME_POSTAL = fields[`COGNOME_POSTAL_${counter}`]
    let NAZIONE_POSTAL = fields[`NAZIONE_POSTAL_${counter}`]
    let PROVINCIA_POSTAL = fields[`PROVINCIA_POSTAL_${counter}`]
    let COMUNE_POSTAL = fields[`COMUNE_POSTAL_${counter}`]
    let COMUNE_ESTERO_POSTAL = fields[`COMUNE_ESTERO_POSTAL_${counter}`]
    let CAP_POSTAL = fields[`CAP_POSTAL_${counter}`]
    let INDIRIZZO_POSTAL = fields[`INDIRIZZO_POSTAL_${counter}`]
    let FLAG_POSTAL = fields[`FLAG_POSTAL_${counter}`]

    return (
      <Fragment>
        <div
          style={{
            fontSize: 'large',
            fontWeight: '700',
            borderBottom: '1px solid #ccc',
            paddingBottom: '20px',
            marginBottom: '30px',
            position: 'relative'
          }}>
          Titolare effettivo n.{counter + 1}
          {idAddictionalOwner !== null && (
            <a className="delete" onClick={() => deleteAddictionalOwner(idAddictionalOwner)}>
              <span>Elimina il titolare</span>
              <i className="material-icons">delete_outline</i>
            </a>
          )}
        </div>

        <Row>
          <Field
            name={NOME.id}
            s={6}
            maxLength={20}
            error={formErrors[NOME.id]}
            label={NOME.label}
            value={formValues[NOME.id]}
            change={change}
            touch={touch}
            component={InputText}
          />
          <Field
            name={COGNOME.id}
            s={6}
            maxLength={20}
            error={formErrors[COGNOME.id]}
            label={COGNOME.label}
            value={formValues[COGNOME.id]}
            change={change}
            touch={touch}
            component={InputText}
          />
        </Row>
        <Row>
          <Field
            name={CODICE_FISCALE.id}
            s={4}
            minLength={CODICE_FISCALE.minLength}
            maxLength={CODICE_FISCALE.maxLength}
            error={formErrors[CODICE_FISCALE.id]}
            label={CODICE_FISCALE.label}
            value={formValues[CODICE_FISCALE.id]}
            change={change}
            touch={touch}
            component={InputText}
            formatter={value => (value ? value.toUpperCase() : '')}
          />
          <Col s={2}>
            <button
              disabled={
                Object.keys(formErrors[CODICE_FISCALE.id] || {}).length ||
                (formValues[CODICE_FISCALE.id] === '' || formValues[CODICE_FISCALE.id] === null)
              }
              type="button"
              className="btn btnCF"
              onClick={() => this.saveandGetdata(counter)}>
              recupera dati
            </button>
          </Col>
          {this.state.visible && (
            <Field
              name={DATA_NASCITA.id}
              s={6}
              openDirection="down"
              anchorDirection="right"
              label={DATA_NASCITA.label}
              readOnly
              placeholder=""
              isOutsideRange={date => date.isAfter(moment().subtract(18, 'y'), 'day')}
              defaultDate={formValues[DATA_NASCITA.id]}
              onDateChange={date => change(DATA_NASCITA.id, date.format())}
              component={renderDatePicker}
            />
          )}
        </Row>

        <Row>
          <Col s={6}>
            <Field
              name={NAZIONE_NASCITA_SCHEDA_DATI.id}
              s={12}
              label={NAZIONE_NASCITA_SCHEDA_DATI.label}
              threshold={0}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                NAZIONE_NASCITA_SCHEDA_DATI.dominio,
                formValues[NAZIONE_NASCITA_SCHEDA_DATI.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_NASCITA_SCHEDA_DATI.dominio))}
              handleChange={(field, value) => {
                change(field, value)

                if (formValues[COMUNE_NASCITA_SCHEDA_DATI.id]) {
                  change(COMUNE_NASCITA_SCHEDA_DATI.id, '')
                }
                if (formValues[COMUNE_NASCITA_ESTERO_SCHEDA_DATI.id]) {
                  change(COMUNE_NASCITA_ESTERO_SCHEDA_DATI.id, '')
                }
                if (formValues[CAP_SCHEDA_DATI.id]) {
                  change(CAP_SCHEDA_DATI.id, '')
                }
                if (formValues[PROVINCIA_NASCITA_SCHEDA_DATI.id]) {
                  change(PROVINCIA_NASCITA_SCHEDA_DATI.id, '')
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
          <Col s={6}>
            <Field
              name={PROVINCIA_NASCITA_SCHEDA_DATI.id}
              s={12}
              label={PROVINCIA_NASCITA_SCHEDA_DATI.label}
              threshold={0}
              disabled={!this.checkNazione(true)}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                PROVINCIA_NASCITA_SCHEDA_DATI.dominio,
                formValues[PROVINCIA_NASCITA_SCHEDA_DATI.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_NASCITA_SCHEDA_DATI.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[COMUNE_NASCITA_SCHEDA_DATI.id]) {
                  change(COMUNE_NASCITA_SCHEDA_DATI.id, '')
                }
                if (this.checkProvincia(value)) {
                  updateDominio(COMUNE_NASCITA_SCHEDA_DATI.dominio, value)
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
        <Row>
          {this.checkNazione(true) && (
            <Col s={6}>
              <Field
                name={COMUNE_NASCITA_SCHEDA_DATI.id}
                s={12}
                threshold={0}
                label={COMUNE_NASCITA_SCHEDA_DATI.label}
                disabled={!this.checkNazione(true)}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  COMUNE_NASCITA_SCHEDA_DATI.dominio,
                  formValues[COMUNE_NASCITA_SCHEDA_DATI.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, COMUNE_NASCITA_SCHEDA_DATI.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                }}
                component={renderAutocomplete}
              />
            </Col>
          )}
          {!this.checkNazione(true) && (
            <Field
              name={COMUNE_NASCITA_ESTERO_SCHEDA_DATI.id}
              s={6}
              maxLength={50}
              disabled={!this.checkNazione()}
              label={COMUNE_NASCITA_ESTERO_SCHEDA_DATI.label}
              error={formErrors[COMUNE_NASCITA_ESTERO_SCHEDA_DATI.id]}
              value={formValues[COMUNE_NASCITA_ESTERO_SCHEDA_DATI.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          )}

          <Col s={6}>
            <Field
              name={CITTADINANZA.id}
              s={12}
              threshold={0}
              label={CITTADINANZA.label}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                CITTADINANZA.dominio,
                formValues[CITTADINANZA.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, CITTADINANZA.dominio))}
              handleChange={(field, value) => {
                change(field, value)
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <Field
              name={NAZIONE_SCHEDA_DATI.id}
              s={12}
              label={NAZIONE_SCHEDA_DATI.label}
              threshold={0}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                NAZIONE_SCHEDA_DATI.dominio,
                formValues[NAZIONE_SCHEDA_DATI.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_SCHEDA_DATI.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[PROVINCIA_SCHEDA_DATI.id]) {
                  change(PROVINCIA_SCHEDA_DATI.id, '')
                }
                if (formValues[COMUNE_SCHEDA_DATI.id]) {
                  change(COMUNE_SCHEDA_DATI.id, '')
                }
                if (formValues[COMUNE_ESTERO_SCHEDA_DATI.id]) {
                  change(COMUNE_ESTERO_SCHEDA_DATI.id, '')
                }
                if (formValues[CAP_SCHEDA_DATI.id]) {
                  change(CAP_SCHEDA_DATI.id, '')
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
          <Col s={6}>
            <Field
              name={PROVINCIA_SCHEDA_DATI.id}
              s={12}
              label={PROVINCIA_SCHEDA_DATI.label}
              threshold={0}
              disabled={!this.checkNazioneResidenza(true)}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                PROVINCIA_SCHEDA_DATI.dominio,
                formValues[PROVINCIA_SCHEDA_DATI.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_SCHEDA_DATI.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (this.checkProvincia(value)) {
                  updateDominio(COMUNE_SCHEDA_DATI.dominio, value)
                }
                if (formValues[COMUNE_SCHEDA_DATI.id]) {
                  change(COMUNE_SCHEDA_DATI.id, '')
                }
                if (formValues[CAP_SCHEDA_DATI.id]) {
                  change(CAP_SCHEDA_DATI.id, '')
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>

        <Row>
          {this.checkNazioneResidenza(true) && (
            <Col s={6}>
              <Field
                name={COMUNE_SCHEDA_DATI.id}
                s={12}
                threshold={0}
                label={COMUNE_SCHEDA_DATI.label}
                disabled={false}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  COMUNE_SCHEDA_DATI.dominio,
                  formValues[COMUNE_SCHEDA_DATI.id]
                )}
                options={
                  formValues[PROVINCIA_SCHEDA_DATI.id]
                    ? mapDominiToAutocomplete(getDominio(domini, COMUNE_SCHEDA_DATI.dominio))
                    : []
                }
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[CAP_SCHEDA_DATI.id]) {
                    change(CAP_SCHEDA_DATI.id, '')
                  }
                  if (this.checkComuneResidenza(value)) {
                    this.setState({ waitingCap: true })
                    updateDominio(CAP_SCHEDA_DATI.dominio, value)
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
          )}

          {!this.checkNazioneResidenza(true) && (
            <Field
              name={COMUNE_ESTERO_SCHEDA_DATI.id}
              s={6}
              maxLength={50}
              disabled={!this.checkNazioneResidenza()}
              label={COMUNE_ESTERO_SCHEDA_DATI.label}
              error={formErrors[COMUNE_ESTERO_SCHEDA_DATI.id]}
              value={formValues[COMUNE_ESTERO_SCHEDA_DATI.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          )}

          <Col s={6}>
            <Field
              name={CAP_SCHEDA_DATI.id}
              s={12}
              threshold={0}
              label={CAP_SCHEDA_DATI.label}
              disabled={!this.checkCap(formValues[COMUNE_SCHEDA_DATI.id])}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                CAP_SCHEDA_DATI.dominio,
                formValues[CAP_SCHEDA_DATI.id]
              )}
              options={
                formValues[COMUNE_SCHEDA_DATI.id]
                  ? mapDominiToAutocomplete(getDominio(domini, CAP_SCHEDA_DATI.dominio))
                  : []
              }
              handleChange={change}
              component={renderAutocomplete}
            />
          </Col>
        </Row>

        <Row>
          <Field
            name={INDIRIZZO.id}
            s={6}
            maxLength={50}
            error={formErrors[INDIRIZZO.id]}
            label={INDIRIZZO.label}
            value={formValues[INDIRIZZO.id]}
            change={change}
            touch={touch}
            component={InputText}
          />

          <Field
            name={EMAIL.id}
            s={6}
            maxLength={50}
            error={formErrors[EMAIL.id]}
            label={EMAIL.label}
            value={formValues[EMAIL.id]}
            change={change}
            touch={touch}
            component={InputText}
          />
        </Row>

        <Row>
          <Field
            name={CELLULARE.id}
            s={6}
            // maxLength={10}
            error={formErrors[CELLULARE.id]}
            label={CELLULARE.label}
            value={formValues[CELLULARE.id]}
            change={change}
            touch={touch}
            component={InputText}
          />

          <Field
            name={TIPO_DOCUMENTO.id}
            size={6}
            label={TIPO_DOCUMENTO.label}
            options={mapDominiToOptions(getDominio(domini, TIPO_DOCUMENTO.dominio), true)}
            component={renderSelect}
          />
        </Row>

        <Row>
          <Field
            name={NUMERO_DOCUMENTO.id}
            s={6}
            maxLength={10}
            error={formErrors[NUMERO_DOCUMENTO.id]}
            label={NUMERO_DOCUMENTO.label}
            value={formValues[NUMERO_DOCUMENTO.id]}
            change={change}
            touch={touch}
            component={InputText}
          />
          <Col s={6}>
            <Field
              name={COMUNE_RILASCIO.id}
              s={12}
              label={COMUNE_RILASCIO.label}
              threshold={0}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                COMUNE_RILASCIO.dominio,
                formValues[COMUNE_RILASCIO.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, COMUNE_RILASCIO.dominio))}
              handleChange={(field, value) => {
                change(field, value)
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
        <Row>
          <Field
            name={DATA_RILASCIO.id}
            s={6}
            openDirection="down"
            label={DATA_RILASCIO.label}
            isDayBlocked={date => date.day() === 0}
            readOnly
            placeholder=""
            isOutsideRange={date => date.isAfter(moment().subtract(0, 'y'), 'day')}
            defaultDate={formValues[DATA_RILASCIO.id]}
            onDateChange={date => change(DATA_RILASCIO.id, date.format())}
            component={renderDatePicker}
          />

          <Field
            name={DATA_SCADENZA.id}
            s={6}
            openDirection="down"
            // anchorDirection="right"
            label={DATA_SCADENZA.label}
            readOnly
            placeholder=""
            isOutsideRange={date =>
              date.isBefore(moment().add(1, 'day'), 'day') ||
              date.isAfter(
                moment()
                  .add(11, 'y')
                  .add(1, 'day'),
                'day'
              )
            }
            defaultDate={formValues[DATA_SCADENZA.id]}
            onDateChange={date => change(DATA_SCADENZA.id, date.format())}
            component={renderDatePicker}
          />
        </Row>

        {/* <Row>
          <Col s={12}>
            <Field
              name={FLAG_POSTAL.id}
              checked={formValues[FLAG_POSTAL.id]}
              value={formValues[FLAG_POSTAL.id]}
              label={FLAG_POSTAL.label}
              onClick={() => this.showCorrispAddress()}
              change={change}
              touch={touch}
              type="checkbox"
              component={renderCheckboxRadio}
            />
          </Col>
        </Row> */}

        <Row>
          <Col s={12} className="m-top20">
            <Field name={FLAG_POSTAL.id} label={FLAG_POSTAL.label} component={renderCheckbox} />
          </Col>
        </Row>

        {// this.state.addressCorrispNotMatch && (

        formValues[FLAG_POSTAL.id] ? (
          <Fragment>
            {NOME_POSTAL && COGNOME_POSTAL && (
              <Row>
                <Field
                  name={NOME_POSTAL.id}
                  s={6}
                  // disabled={nameDisabled}
                  maxLength={20}
                  label={NOME_POSTAL.label}
                  error={formErrors[NOME_POSTAL.id]}
                  value={formValues[NOME_POSTAL.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
                <Field
                  name={COGNOME_POSTAL.id}
                  s={6}
                  // disabled={nameDisabled}
                  maxLength={20}
                  label={COGNOME_POSTAL.label}
                  error={formErrors[COGNOME_POSTAL.id]}
                  value={formValues[COGNOME_POSTAL.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Row>
            )}
            <Row>
              <Field
                name={INDIRIZZO_POSTAL.id}
                s={6}
                maxLength={40}
                label={INDIRIZZO_POSTAL.label}
                error={formErrors[INDIRIZZO_POSTAL.id]}
                value={formValues[INDIRIZZO_POSTAL.id]}
                change={change}
                touch={touch}
                component={InputText}
              />
              {/* {CIVICO && (
            <Field
              name={CIVICO.id}
              s={6}
              maxLength={40}
              label={CIVICO.label}
              error={formErrors[CIVICO.id]}
              value={formValues[CIVICO.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          )} */}
            </Row>

            {/*


        */}

            <Row>
              <Col s={6}>
                <Field
                  name={NAZIONE_POSTAL.id}
                  s={12}
                  label={NAZIONE_POSTAL.label}
                  threshold={0}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    NAZIONE_POSTAL.dominio,
                    formValues[NAZIONE_POSTAL.id]
                  )}
                  options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_POSTAL.dominio))}
                  handleChange={(field, value) => {
                    change(field, value)
                    if (formValues[PROVINCIA_POSTAL.id]) {
                      change(PROVINCIA_POSTAL.id, '')
                    }
                    if (formValues[COMUNE_POSTAL.id]) {
                      change(COMUNE_POSTAL.id, '')
                    }
                    if (formValues[COMUNE_ESTERO_POSTAL.id]) {
                      change(COMUNE_ESTERO_POSTAL.id, '')
                    }
                    if (formValues[CAP_POSTAL.id]) {
                      change(CAP_POSTAL.id, '')
                    }
                  }}
                  component={renderAutocomplete}
                />
              </Col>
              <Col s={6}>
                <Field
                  name={PROVINCIA_POSTAL.id}
                  s={12}
                  label={PROVINCIA_POSTAL.label}
                  threshold={0}
                  disabled={!this.checkNazionePostal(true)}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    PROVINCIA_POSTAL.dominio,
                    formValues[PROVINCIA_POSTAL.id]
                  )}
                  options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_POSTAL.dominio))}
                  handleChange={(field, value) => {
                    change(field, value)
                    if (formValues[COMUNE_POSTAL.id]) {
                      change(COMUNE_POSTAL.id, '')
                    }
                    if (formValues[CAP_POSTAL.id]) {
                      change(CAP_POSTAL.id, '')
                    }
                    if (this.checkProvinciaPostal(value)) {
                      this.setState({ waitingCap: true })
                      updateDominio(COMUNE_POSTAL.dominio, value)
                    }
                  }}
                  component={renderAutocomplete}
                />
              </Col>
            </Row>
            <Row>
              {this.checkNazionePostal(true) && (
                <Col s={6}>
                  <Field
                    name={COMUNE_POSTAL.id}
                    s={12}
                    threshold={0}
                    label={COMUNE_POSTAL.label}
                    disabled={!this.checkProvinciaPostal(formValues[PROVINCIA_POSTAL.id])}
                    defaultValue={getAutocompleteDefaultOption(
                      domini.listaDomini,
                      COMUNE_POSTAL.dominio,
                      formValues[COMUNE_POSTAL.id]
                    )}
                    options={
                      formValues[PROVINCIA_POSTAL.id]
                        ? mapDominiToAutocomplete(getDominio(domini, COMUNE_POSTAL.dominio))
                        : []
                    }
                    handleChange={(field, value) => {
                      change(field, value)
                      if (formValues[CAP_POSTAL.id]) {
                        change(CAP_POSTAL.id, '')
                      }
                      if (this.checkComunePostal(value)) {
                        this.setState({ waitingCap: true })
                        updateDominio(CAP_POSTAL.dominio, value)
                      }
                    }}
                    component={renderAutocomplete}
                  />
                </Col>
              )}
              {!this.checkNazionePostal(true) && (
                <Field
                  name={COMUNE_ESTERO_POSTAL.id}
                  s={6}
                  maxLength={50}
                  disabled={!this.checkNazionePostal()}
                  label={COMUNE_ESTERO_POSTAL.label}
                  error={formErrors[COMUNE_ESTERO_POSTAL.id]}
                  value={formValues[COMUNE_ESTERO_POSTAL.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              )}

              <Col s={3} offset="s3">
                <Field
                  name={CAP_POSTAL.id}
                  s={12}
                  threshold={0}
                  label={CAP_POSTAL.label}
                  disabled={!this.checkCapPostal()}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    CAP_POSTAL.dominio,
                    formValues[CAP_POSTAL.id]
                  )}
                  options={
                    formValues[COMUNE_POSTAL.id] ? mapDominiToAutocomplete(getDominio(domini, CAP_POSTAL.dominio)) : []
                  }
                  handleChange={change}
                  component={renderAutocomplete}
                />
              </Col>
            </Row>
          </Fragment>
        ) : null}

        <Row s={12} className="tipoReplazione">
          <Col>
            <div
              style={{
                fontSize: 'medium'
              }}>
              {TIPO_RELAZIONE.label}
            </div>
          </Col>
        </Row>
        <Row
          s={12}
          style={{
            display: 'flex',
            alignItems: 'center'
          }}>
          <Col s={2}>
            <Field
              name={TIPO_RELAZIONE.id}
              className="with-gap"
              type="radio"
              id={`FAMILY-${counter}`}
              value="FAMILY"
              component="input"
              onChange={() => change(TIPO_RELAZIONE_ALTRO.id, '')}
            />

            <label htmlFor={`FAMILY-${counter}`}>Familiare</label>
          </Col>
          <Col s={2}>
            <Field
              name={TIPO_RELAZIONE.id}
              className="with-gap"
              type="radio"
              id={`PROFESSIONAL-${counter}`}
              value="PROFESSIONAL"
              component="input"
              onChange={() => change(TIPO_RELAZIONE_ALTRO.id, '')}
            />

            <label htmlFor={`PROFESSIONAL-${counter}`}>Professionale</label>
          </Col>
          <Col s={2}>
            <Field
              name={TIPO_RELAZIONE.id}
              className="with-gap"
              type="radio"
              id={`OTHER-${counter}`}
              value="OTHER"
              component="input"
            />
            <label htmlFor={`OTHER-${counter}`}>Altro</label>
          </Col>
          <Col s={6}>
            <Field
              s={12}
              name={TIPO_RELAZIONE_ALTRO.id}
              // className="with-gap"
              label={TIPO_RELAZIONE_ALTRO.label}
              value={formValues[TIPO_RELAZIONE_ALTRO.id]}
              disabled={formValues[TIPO_RELAZIONE.id] !== 'OTHER'}
              change={change}
              touch={touch}
              component={InputText}
            />
          </Col>
        </Row>

        <Row>
          <br />
          <Col>
            <div
              style={{
                fontSize: 'medium',
                marginBottom: '1rem'
              }}>
              Il cliente dichiara inoltre che in quanto Titolare Effettivo è:
            </div>
          </Col>
        </Row>
        <Row>
          <SchedaDatiPepTitolare
            counter={counter}
            {...this.props}
            style={{
              marginBottom: '4rem!important'
            }}
          />
        </Row>
      </Fragment>
    )
  }
}

SchedaDatiTitolareItem.propTypes = {
  getDataFromTaxCodeRequest: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  getDataFromTaxCode: PropTypes.func,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  domini: PropTypes.object,
  fields: PropTypes.object,
  counter: PropTypes.number,
  updateDominio: PropTypes.func,
  submit: PropTypes.func,
  dataFromTaxCode: PropTypes.array,
  idAddictionalOwner: PropTypes.number,
  deleteAddictionalOwner: PropTypes.func
}

SchedaDatiTitolareItem.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default SchedaDatiTitolareItem
