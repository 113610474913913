import { DIRTY_FORM_GO_TO, DIRTY_FORM_FORCE_GO_TO, DIRTY_FORM_RESET_FORCE_GO_TO } from './constants'

export const goToWhenSaved = (path, isPrevious) => {
  return {
    type: DIRTY_FORM_GO_TO,
    path,
    isPrevious
  }
}

export const goToForced = (path, accordion) => {
  return {
    type: DIRTY_FORM_FORCE_GO_TO,
    path,
    accordion
  }
}

export const resetGoToForced = () => {
  return {
    type: DIRTY_FORM_RESET_FORCE_GO_TO
  }
}
