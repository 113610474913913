import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'

const Selezione = ({ title, text, children }) => {
  return (
    <div className="onboarding-modale">
      <div className="onboarding-modale-content">
        <Row>
          <Col s={12} style={{ marginTop: '-20px' }}>
            {title}
          </Col>
          <Col s={12}>{text}</Col>
          <Col s={12} style={{ marginTop: '40px' }}>
            {children}
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default Selezione

Selezione.propTypes = {
  title: PropTypes.object,
  text: PropTypes.object,
  children: PropTypes.node
}
