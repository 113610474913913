import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import RiepilogoCliente from './RiepilogoClienteComponent'
import {
  getDataSummaryFull,
  getProfessionalSubject,
  getDataRegistry,
  getDocumentsData
} from '../../../store/modules/customers/actions'
import { dominio } from '../../../store/modules/dominio/actions'
import { goToForced } from '../../../store/modules/dirtyForm/actions'
import { updateStatoPratica, tipoFirma } from '../../../store/modules/pratica/actions'
import fields from '../fields'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataSummaryFull,
      retrieveDominio: (...params) => dominio(...params),
      goToForced,
      updateStatoPratica,
      getProfessionalSubject,
      getDocumentsData,
      getDataRegistry,
      tipoFirma
    },
    dispatch
  )

const mapStateToProps = state => {
  return {
    customer: state.customers.customer,
    summaryData: state.customers.summaryData,
    summaryDataFull: state.customers.summaryDataFull,
    getDataSummaryFullRequest: state.customers.getDataSummaryFullRequest,
    getDataSummaryRequest: state.customers.getDataSummaryRequest,
    domini: state.dominio.domini,
    isAdmin: state.customers.summaryData.admin,
    dominioRequest: state.dominio.dominioRequest,
    templateAml: state.survey.templateAml,
    template: state.survey.template,
    updatePraticaRequest: state.pratica.updatePraticaRequest,
    confirmPraticaRequest: state.pratica.confirmPraticaRequest,
    fields,
    professionalSubject: state.customers.professionalSubject,
    idPratica: state.pratica.idPratica,
    flag: state.pratica.pratica.tipoFirma
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiepilogoCliente)
