import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import { Col, Row } from 'react-materialize'
import { renderAssegnoField, renderInputImage } from '../../../../../components/Forms/renderFields'
import './Assegno.scss'

const regExp = new RegExp(/^(\s*|\d+)$/)

const Assegno = ({ fields, formValues, change }) => (
  <Fragment>
    <Row>
      <Col s={12}>
        <p className="padding-l-10">Estremi assegno*</p>
      </Col>
      <Col s={12} className="inputs-assegno m-top10">
        <Col s={12}>
          <Field
            name={fields.ABI.id}
            size={2}
            max={5}
            field={fields.ABI}
            formValue={formValues[fields.ABI.id]}
            regExp={regExp}
            change={change}
            component={renderAssegnoField}
          />
          <Field
            name={fields.CAB.id}
            size={2}
            max={5}
            field={fields.CAB}
            formValue={formValues[fields.CAB.id]}
            regExp={regExp}
            change={change}
            component={renderAssegnoField}
          />
          <Field
            name={fields.NUM_ASSEGNO.id}
            size={4}
            max={13}
            field={fields.NUM_ASSEGNO}
            formValue={formValues[fields.NUM_ASSEGNO.id]}
            regExp={regExp}
            change={change}
            component={renderAssegnoField}
          />
          <Field
            name={fields.NUM_CONTO.id}
            size={4}
            max={8}
            field={fields.NUM_CONTO}
            formValue={formValues[fields.NUM_CONTO.id]}
            regExp={regExp}
            change={change}
            component={renderAssegnoField}
          />
        </Col>
      </Col>
    </Row>
    <Row className="m-top30">
      <Col s={6} offset={'s3'}>
        <Field
          name={fields.IMG.id}
          label={fields.IMG.label}
          idImg={fields.IMG.id}
          fileIn={formValues[fields.IMG_THUMB.id]}
          onChangeHandler={(file, fileThumb) => {
            change(fields.IMG.id, file)
            change(fields.IMG_THUMB.id, fileThumb)
          }}
          component={renderInputImage}
        />
      </Col>
    </Row>
  </Fragment>
)

Assegno.propTypes = {
  fields: PropTypes.shape({
    ABI: PropTypes.object.isRequired,
    CAB: PropTypes.object.isRequired,
    NUM_ASSEGNO: PropTypes.object,
    NUM_CONTO: PropTypes.object
  }),
  formValues: PropTypes.object,
  change: PropTypes.func.isRequired
}

export default Assegno
