import React from 'react'
import PropTypes from 'prop-types'
import { Row } from 'react-materialize'

const SurveyHr = ({ hideHr }) => <Row className="survey-hr">{!hideHr && <hr />}</Row>
export default SurveyHr

SurveyHr.propTypes = {
  hideHr: PropTypes.bool
}
