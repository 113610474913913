export default class PromisesState {
  constructor(promisesName) {
    this.promisesName = promisesName
    this.state = this.getInitialState()
  }

  getInitialState() {
    return this.promisesName.reduce(
      (promises, name) => ({
        ...promises,
        [name]: null
      }),
      {}
    )
  }

  setPending(promiseName) {
    this.state[promiseName] = 'pending'
    return this.state
  }

  setFulfilled(promiseName) {
    this.state[promiseName] = 'fulfilled'
    return this.state
  }

  setRejected(promiseName) {
    this.state[promiseName] = 'rejected'
    return this.state
  }

  isNull(promiseName) {
    return this.state[promiseName] === null
  }

  isPending(promiseName) {
    return this.state[promiseName] === 'pending'
  }

  isFulfilled(promiseName) {
    return this.state[promiseName] === 'fulfilled'
  }

  isRejected(promiseName) {
    return this.state[promiseName] === 'rejected'
  }
}
