import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-materialize'

export const Orange = ({ text = '', className = '', error = false, children }) => (
  <Col className={`colorOrange ${className}${error ? ' error' : ''}`}>
    {children}
    {text}
  </Col>
)
Orange.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.object
}

export const Red = ({ text = '', className = '', error = false, children }) => (
  <Col className={`colorRed ${className}${error ? ' error' : ''}`}>
    {children}
    {text}
  </Col>
)
Red.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.object
}

export const Green = ({ text = '', className = '', error = false, children }) => (
  <Col className={`colorGreen ${className}${error ? ' error' : ''}`}>
    {children}
    {text}
  </Col>
)
Green.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.object
}
