import axios from 'axios'

let token = ''
let s = ''
let p = ''
const urlMatches = /token=([a-zA-Z0-9_.\-~]*)(&.*)*$/.exec(document.location.href)
const urlMatchesS = /s=([a-zA-Z0-9_.\-~]*)(&.*)*$/.exec(document.location.href)
const urlMatchesP = /p=([a-zA-Z0-9_.\-~]*)(&.*)*$/.exec(document.location.href)
if (urlMatches && urlMatches[1] && urlMatches[1].length) {
  token = urlMatches[1]
} else {
  // axios.get('/token/').then(function (Response) {
  //     token = Response.data.token
  //   })
}

// axios.defaults.baseURL = '/api'
axios.defaults.baseURL = '/api' // nuovo URL
// axios.defaults.baseURL = 'http://onboarding.zenith-dev.azimut.it/api' // nuovo URL

// axios.defaults.baseURL = 'http://localhost:8080'
// axios.defaults.baseURL = 'http://onboarding.zenith-dev.azimut.it/api' // nuovo URL
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json, text/plain, */*'
axios.defaults.headers.common['Pragma'] = 'no-cache'
axios.defaults.headers.common['Cache-Control'] = 'no-cache'
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = ''
axios.defaults.headers.common['Access-Control-Allow-Origin'] = ''
axios.defaults.headers.common['token'] = token

if (urlMatchesS && urlMatchesS[1] && urlMatchesS[1].length) {
  s = urlMatchesS[1]
  axios.defaults.headers.common['s'] = `${s}`
}
if (urlMatchesP && urlMatchesP[1] && urlMatchesP[1].length) {
  p = urlMatchesP[1]
  axios.defaults.headers.common['p'] = `${p}`
}
