import React from 'react'
import { PropTypes } from 'prop-types'
import { Button, Row } from 'react-materialize'

const ButtonSubmit = ({ disabled, label = 'Salva' }) => (
  <Row className="right-align submit-collapsible-item">
    <Button flat waves="light" type="submit" disabled={disabled}>
      {label}
    </Button>
  </Row>
)

ButtonSubmit.propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string
}

export default ButtonSubmit
