import { connect } from 'react-redux'
import { isDirty } from 'redux-form'
import ConfigurazioneServiziView from '../components/ConfigurazioneServizi'
import * as forms from '../../../../store/modules/forms/constants'
import { dominio } from '../../../../store/modules/dominio/actions'
import {
  getPratica,
  getPraticaIntestatari,
  updateStatoPratica,
  updatePratica,
  checkTipoFirmaIntestatari,
  getPraticaPG,
  updatePraticaPG
} from '../../../../store/modules/pratica/actions'
import {
  getDataRegistry,
  resetConsulenza,
  getDataRegistryExt,
  getIdCardData
} from '../../../../store/modules/customers/actions'

import {
  getDocumentIdPGActionCreator,
  getDataRegistryPGExt
} from '../../../../store/modules/personaGiuridica/client/actions'
import {
  getServizioInfo,
  postServizioInfo,
  postServizi,
  setIdPromotore
} from '../../../../store/modules/services/actions'

const mapDispatchToProps = dispatch => {
  return {
    dominio: queryParam => dispatch(dominio(queryParam)),
    getPratica: value => dispatch(getPratica(value)),
    updatePratica: (idPratica, payload) => dispatch(updatePratica(idPratica, payload)),
    getServizioInfo: servizi => dispatch(getServizioInfo(servizi)),
    postServizioInfo: (idServizio, payload) => dispatch(postServizioInfo(idServizio, payload)),
    postServizi: servizi => dispatch(postServizi(servizi)),
    updateStatoPratica: (idPratica, idStatoPratica, flag) =>
      dispatch(updateStatoPratica(idPratica, idStatoPratica, flag)),
    getDataRegistry: (idCliente, idIncarico) => dispatch(getDataRegistry(null, idCliente, idIncarico, true)),
    resetConsulenza: () => dispatch(resetConsulenza()),
    getPraticaIntestatari: idPratica => dispatch(getPraticaIntestatari(idPratica)),
    setIdPromotore: () => dispatch(setIdPromotore()),
    checkTipoFirmaIntestatari: idPratica => dispatch(checkTipoFirmaIntestatari(idPratica)),
    getDocumentIdPGActionCreator: idCustomerPG => dispatch(getDocumentIdPGActionCreator(idCustomerPG)),
    getPraticaPG: value => dispatch(getPraticaPG(value)),
    updatePraticaPG: (idPratica, payload) => dispatch(updatePraticaPG(idPratica, payload)),
    getDataRegistryExt: idCustomerExt => dispatch(getDataRegistryExt(idCustomerExt)),
    getDataRegistryPGExt: idCustomerPG => dispatch(getDataRegistryPGExt(idCustomerPG)),
    getIdCardData: (idCliente, idIncarico) => dispatch(getIdCardData(idCliente, idIncarico))
  }
}

const mapStateToProps = state => ({
  dominioRequest: state.dominio.dominioRequest,
  praticaRequest: state.pratica.praticaRequest,
  getPraticaPGRequest: state.pratica.getPraticaPGRequest,
  putPraticaPGRequest: state.pratica.putPraticaPGRequest,
  getServiceInfoRequest: state.services.getServiceInfoRequest,
  updateStatoPraticaRequest: state.pratica.updatePraticaRequest,
  postServiziRequest: state.services.postServiziRequest,
  getDataRegistryRequest: state.customers.getDataRegistryRequest,
  dataRegistry: state.customers.dataRegistry,
  anagraficaPG: state.anagraficaPersonaGiuridicaCliente.docIDPG,
  getDocIDPGRequest: state.anagraficaPersonaGiuridicaCliente.getDocIDPGRequest,
  domini: state.dominio.domini,
  pratica: state.pratica.pratica,
  intestatarioPG: state.pratica.pratica.intestatari ? state.pratica.pratica.intestatari[0] : [],
  idPratica: state.pratica.pratica.idPratica,
  serviceData: state.services.serviceData,
  percentualiCommissionali: state.services.percentualiCommissionali,
  checkForms: () => Object.keys(forms).some(form => isDirty(forms[form])(state)),
  praticaIntestatariRequest: state.pratica.praticaIntestatariRequest,
  flagFirmaPraticaInvestimento: state.pratica.flagFirmaPraticaInvestimento,
  flagDigitalSignatureEnabled: state.pratica.pratica.flagDigitalSignatureEnabled,
  getDataRegistryExtRequest: state.customers.getDataRegistryExtRequest,
  getDataRegistryPGExtRequest: state.anagraficaPersonaGiuridicaCliente.getDataRegistryPGExtRequest,
  idCardData: state.customers.idCardData,
  getIdCardDataRequest: state.customers.getIdCardDataRequest
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurazioneServiziView)
