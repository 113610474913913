import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
// import moment from 'moment'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { DOCUMENTO_PROCURA } from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'

const fields = allFields[0][DOCUMENTO_PROCURA]

class ProcuratoreDocumentiReview extends Component {
  render() {
    const { data /*,
       domini: { listaDomini } */ } = this.props
    const { ATTORNEY_DOC_FLAG } = fields
    if (data[ATTORNEY_DOC_FLAG.id]) {
      return (
        <CollapsibleItem
          header={dataSB.acquisizione.documentoProcura.title_accordion}
          icon="keyboard_arrow_down"
          itemKey={DOCUMENTO_PROCURA}
          labelInfo={this.props.accordionHeader}>
          <Row>
            <Fragment>
              <Row>
                <Col s={12}>
                  <i className="large uploaded-icon material-icons">insert_drive_file</i>
                </Col>
              </Row>
              <Row id={'uploadDocCompletato'}>
                <Col s={12}>
                  <b>
                    <i className="icon-check-codice-fiscale-success" />
                    &nbsp;Documenti caricati
                  </b>
                </Col>
              </Row>
            </Fragment>
          </Row>
        </CollapsibleItem>
      )
    }
    return (
      <CollapsibleItem
        header={dataSB.acquisizione.documentoProcura.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={DOCUMENTO_PROCURA}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Fragment>
            <Row>
              <Col s={12}>
                <i className="large uploaded-icon material-icons">insert_drive_file</i>
              </Col>
            </Row>
            <Row id={'uploadDocCompletato'}>
              <Col s={12}>
                <b>&nbsp;Documenti non caricati</b>
              </Col>
            </Row>
          </Fragment>
        </Row>
      </CollapsibleItem>
    )
  }
}

ProcuratoreDocumentiReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object /* ,
  domini: PropTypes.object */
}

export default ProcuratoreDocumentiReview
