export const SERVICE_SEARCH_START = 'SERVICE_SEARCH_START'
export const SERVICE_SEARCH_SUCCESS = 'SERVICE_SEARCH_SUCCESS'
export const SERVICE_SEARCH_ERROR = 'SERVICE_SEARCH_ERROR'

export const SERVICE_SAVE_START = 'SERVICE_SAVE_START'
export const SERVICE_SAVE_SUCCESS = 'SERVICE_SAVE_SUCCESS'
export const SERVICE_SAVE_ERROR = 'SERVICE_SAVE_ERROR'

export const GET_SERVICE_INFO_START = 'GET_SERVICE_INFO_START'
export const GET_SERVICE_INFO_SUCCESS = 'GET_SERVICE_INFO_SUCCESS'
export const GET_SERVICE_INFO_ERROR = 'GET_SERVICE_INFO_ERROR'

export const SERVICE_SAVE_MULTIPLE_START = 'SERVICE_SAVE_MULTIPLE_START'
export const SERVICE_SAVE_MULTIPLE_SUCCESS = 'SERVICE_SAVE_MULTIPLE_SUCCESS'
export const SERVICE_SAVE_MULTIPLE_ERROR = 'SERVICE_SAVE_MULTIPLE_ERROR'

export const SET_TOT_SERVIZI = 'SET_TOT_SERVIZI'
export const SET_ID_PROMOTORE = 'SET_ID_PROMOTORE'

export const GET_DOMAIN_SERVICE_START = 'GET_DOMAIN_SERVICE_START'
export const GET_DOMAIN_SERVICE_SUCCESS = 'GET_DOMAIN_SERVICE_SUCCESS'
export const GET_DOMAIN_SERVICE_ERROR = 'GET_DOMAIN_SERVICE_ERROR'
