import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import { loadData, loadDefaultFields } from '../../utils'
import { ANTIRICICLAGGIO_GIURIDICHE } from '../../../../store/modules/forms/constants'
import AntiRiciclaggioGiuridiche from './AntiRiciclaggioGiuridicheComponent'
import allFields from '../../fields'

const fields = allFields[1][ANTIRICICLAGGIO_GIURIDICHE]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const errors = {}
  const { CLASSE_BENEFICIARI, FONTE_PROVENIENZA } = fields
  if (
    !!values[CLASSE_BENEFICIARI.id] &&
    !new RegExp(CLASSE_BENEFICIARI.validazione).test(values[CLASSE_BENEFICIARI.id])
  ) {
    errors[CLASSE_BENEFICIARI.id] = 'è possibile inserire solo caratteri alfanumerici'
  }

  if (!!values[FONTE_PROVENIENZA.id] && !new RegExp(FONTE_PROVENIENZA.validazione).test(values[FONTE_PROVENIENZA.id])) {
    errors[FONTE_PROVENIENZA.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  return errors
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadData(state.survey.amlPgPreviousForm, fields)
  },
  formValues: getFormValues(ANTIRICICLAGGIO_GIURIDICHE)(state),
  formErrors: getFormSyncErrors(ANTIRICICLAGGIO_GIURIDICHE)(state),
  fields,
  dataNascitaCF: state.customers.customer.dataNascita || state.customers.dataRegistry.dataNascita,
  isDirty: isDirty(ANTIRICICLAGGIO_GIURIDICHE)(state),
  isFetching: state.survey.amlPgSaveRequest.fetching,
  apiError: state.survey.amlPgSaveRequest.error,
  apiSuccess: state.survey.amlPgSent,
  isSummaryLightPGFetching: state.prospectPersonaGiuridica.getDataSummaryLight.fetching,
  isSummaryLightPGApiError: state.prospectPersonaGiuridica.getDataSummaryLight.error
}))(
  reduxForm({
    form: ANTIRICICLAGGIO_GIURIDICHE,
    validate,
    shouldError: () => isDirty(ANTIRICICLAGGIO_GIURIDICHE)
  })(AntiRiciclaggioGiuridiche)
)
