import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../../components/Collapsible'
import { APERTURA_PIR } from '../../../../../store/modules/forms/constants'
import allFields from '../../../fields'
import ReadOnlyInput from '../../../../../components/ReadOnlyInput'
import { getValueDominioFromKey } from '../../../../ConsulenzaBaseContainerLayout/utils'

const fields = allFields[APERTURA_PIR]

class PIR extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dominiUpdated: false
    }
  }

  componentDidUpdate() {
    const { updateDomini, getIdCardDataRequest, data } = this.props

    if (!this.state.dominiUpdated && !getIdCardDataRequest.fetching && !getIdCardDataRequest.error) {
      updateDomini(
        ['COMUNE_NASCITA', 'COMUNE_RESIDENZA', 'COMUNE_RILASCIO'].reduce((res, cur) => {
          return [
            ...res,
            {
              idDominio: fields[cur].dominio,
              filtro: {
                codice: data[fields[cur].dominioDep].chiave
              }
            }
          ]
        }, [])
      )
      this.setState({ dominiUpdated: true })
    }
  }

  render() {
    const {
      data,
      domini: { listaDomini },
      serviceData,
      accordionHeader
    } = this.props

    const {
      NAZIONE_NASCITA,
      PROVINCIA_NASCITA,
      COMUNE_NASCITA,
      DATA_NASCITA,
      CITTADINANZA,
      INDIRIZZO_RESIDENZA,
      NAZIONE_RESIDENZA,
      PROVINCIA_RESIDENZA,
      COMUNE_RESIDENZA,
      TIPO_DOCUMENTO,
      NAZIONE_RILASCIO,
      PROVINCIA_RILASCIO,
      COMUNE_RILASCIO,
      NUMERO_DOCUMENTO,
      DATA_RILASCIO,
      DATA_SCADENZA
    } = fields

    return (
      <CollapsibleItem
        itemKey={APERTURA_PIR}
        header={serviceData.descTipoServizio}
        icon="keyboard_arrow_down"
        labelInfo={accordionHeader}>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={NAZIONE_NASCITA.label}
              value={getValueDominioFromKey(
                listaDomini,
                NAZIONE_NASCITA.dominio,
                (data[NAZIONE_NASCITA.id] || {}).chiave
              )}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput
              label={PROVINCIA_NASCITA.label}
              value={getValueDominioFromKey(
                listaDomini,
                PROVINCIA_NASCITA.dominio,
                (data[PROVINCIA_NASCITA.id] || {}).chiave
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={COMUNE_NASCITA.label}
              value={getValueDominioFromKey(
                listaDomini,
                COMUNE_NASCITA.dominio,
                (data[COMUNE_NASCITA.id] || {}).chiave
              )}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput label={DATA_NASCITA.label} value={moment(data[DATA_NASCITA.id]).format('DD/MM/YYYY')} />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={CITTADINANZA.label}
              value={getValueDominioFromKey(listaDomini, CITTADINANZA.dominio, (data[CITTADINANZA.id] || {}).chiave)}
            />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput label={INDIRIZZO_RESIDENZA.label} value={data[INDIRIZZO_RESIDENZA.id]} />
          </Col>
          <Col s={6}>
            <ReadOnlyInput
              label={NAZIONE_RESIDENZA.label}
              value={getValueDominioFromKey(
                listaDomini,
                NAZIONE_RESIDENZA.dominio,
                (data[NAZIONE_RESIDENZA.id] || {}).chiave
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={PROVINCIA_RESIDENZA.label}
              value={getValueDominioFromKey(
                listaDomini,
                PROVINCIA_RESIDENZA.dominio,
                (data[PROVINCIA_RESIDENZA.id] || {}).chiave
              )}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput
              label={COMUNE_RESIDENZA.label}
              value={getValueDominioFromKey(
                listaDomini,
                COMUNE_RESIDENZA.dominio,
                (data[COMUNE_RESIDENZA.id] || {}).chiave
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={TIPO_DOCUMENTO.label}
              value={getValueDominioFromKey(
                listaDomini,
                TIPO_DOCUMENTO.dominio,
                (data[TIPO_DOCUMENTO.id] || {}).chiave
              )}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput label={NUMERO_DOCUMENTO.label} value={data[NUMERO_DOCUMENTO.id]} />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={NAZIONE_RILASCIO.label}
              value={getValueDominioFromKey(
                listaDomini,
                NAZIONE_RILASCIO.dominio,
                (data[NAZIONE_RILASCIO.id] || {}).chiave
              )}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput
              label={PROVINCIA_RILASCIO.label}
              value={getValueDominioFromKey(
                listaDomini,
                PROVINCIA_RILASCIO.dominio,
                (data[PROVINCIA_RILASCIO.id] || {}).chiave
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={COMUNE_RILASCIO.label}
              value={getValueDominioFromKey(
                listaDomini,
                COMUNE_RILASCIO.dominio,
                (data[COMUNE_RILASCIO.id] || {}).chiave
              )}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput label={DATA_RILASCIO.label} value={moment(data[DATA_RILASCIO.id]).format('DD/MM/YYYY')} />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput label={DATA_SCADENZA.label} value={moment(data[DATA_SCADENZA.id]).format('DD/MM/YYYY')} />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}

export default PIR

PIR.propTypes = {
  data: PropTypes.object,
  domini: PropTypes.object,
  serviceData: PropTypes.object,
  accordionHeader: PropTypes.object,
  getIdCardDataRequest: PropTypes.object,
  updateDomini: PropTypes.func
}
