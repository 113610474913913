import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Input, Col } from 'react-materialize'
import Autocomplete from '../Autocomplete'
import DatePicker from '../DatePicker/DatePicker'
import InputImage from '../Inputs/Image'
import ImgUploader from '../Inputs/ImgUploader'
import InputPdf from '../Inputs/Pdf'
import SliderComponent from '../Slider/SliderComponent'
import Title from '../Title'

export const renderDatePicker = ({ input, ...props }) => <DatePicker {...input} {...props} />
renderDatePicker.propTypes = {
  input: PropTypes.object
}

export const renderInputImage = ({ input, fileIn, label, onChangeHandler, disabled }) => (
  <InputImage
    {...input}
    disabled={disabled}
    idImg={input.name}
    label={label}
    fileIn={fileIn}
    onChange={onChangeHandler}
  />
)
renderInputImage.propTypes = {
  input: PropTypes.object,
  fileIn: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

export const renderImgUploader = ({ input, fileIn, label, onChangeHandler, disabled }) => (
  <ImgUploader
    {...input}
    disabled={disabled}
    idImg={input.name}
    label={label}
    fileIn={fileIn}
    onChange={onChangeHandler}
  />
)
renderImgUploader.propTypes = {
  input: PropTypes.object,
  fileIn: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

export const renderInputPdf = ({ input, fileIn, label, onChangeHandler, disabled }) => (
  <InputPdf
    {...input}
    disabled={disabled}
    idImg={input.name}
    label={label}
    fileIn={fileIn}
    onChange={onChangeHandler}
  />
)
renderInputPdf.propTypes = {
  input: PropTypes.object,
  fileIn: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onChangeHandler: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

export const renderYesNoQuestion = ({ input, label, handlerFn, options, disabled }) => (
  <div className={`yes-no-question ${disabled ? 'disabled' : ''}`}>
    <div>
      <Title>{label}</Title>
    </div>
    <div
      className={`box-input ${
        input.value !== '' ? ('' + input.value === '' + options[0].idRisposta ? 'yes-selected' : 'no-selected') : ''
      }`}>
      <button disabled={disabled} type="button" className="yes-button" onClick={() => handlerFn(options[0].idRisposta)}>
        SI
      </button>
      <button disabled={disabled} type="button" className="no-button" onClick={() => handlerFn(options[1].idRisposta)}>
        NO
      </button>
    </div>
  </div>
)
renderYesNoQuestion.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  handlerFn: PropTypes.func,
  options: PropTypes.array,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}

export const renderTrueFalse = ({ input, disabled, domanda, handlerFn, options }) => (
  <div className={`true-false-question ${disabled ? 'disabled' : ''}`}>
    <p className="title">{domanda.testoDomanda}</p>
    <p className="subtitle">{domanda.subTestoDomanda}</p>
    <div
      className={`box-input ${
        input.value !== '' ? ('' + input.value === '' + options[0].idRisposta ? 'yes-selected' : 'no-selected') : ''
      }`}>
      <button
        type="button"
        className="yes-button"
        disabled={disabled}
        onClick={() => handlerFn('' + options[0].idRisposta)}>
        V
      </button>
      <button
        type="button"
        className="no-button"
        disabled={disabled}
        onClick={() => handlerFn('' + options[1].idRisposta)}>
        F
      </button>
    </div>
  </div>
)
renderTrueFalse.propTypes = {
  input: PropTypes.object,
  domanda: PropTypes.object,
  handlerFn: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  options: PropTypes.array
}

export const renderYesNo = ({ input, disabled, domanda, handlerFn, options }) => (
  <div className={`yes-no-question ${disabled ? 'disabled' : ''}`}>
    <p className="title">{domanda.testoDomanda}</p>
    <p className="subtitle">{domanda.subTestoDomanda}</p>
    <div
      className={`box-input ${
        input.value !== '' ? ('' + input.value === '' + options[0].idRisposta ? 'yes-selected' : 'no-selected') : ''
      }`}>
      <button
        type="button"
        className="yes-button"
        disabled={disabled}
        onClick={() => handlerFn('' + options[0].idRisposta)}>
        SI
      </button>
      <button
        type="button"
        className="no-button"
        disabled={disabled}
        onClick={() => handlerFn('' + options[1].idRisposta)}>
        NO
      </button>
    </div>
  </div>
)
renderYesNo.propTypes = {
  input: PropTypes.object,
  domanda: PropTypes.object,
  handlerFn: PropTypes.func,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  options: PropTypes.array
}

export const renderHtmlInput = ({ input, ...props }) => <input {...input} {...props} />
renderHtmlInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  input: PropTypes.object
}

export const renderInputText = ({ input, meta, ...props }) => {
  const { s, m, l, label, placeholder, onChange, disabled } = props
  let className = ''
  let labelClassName = ''
  if (s) className += ` s${s}`
  if (m) className += ` m${m}`
  if (l) className += ` l${l}`
  if (meta.invalid) className += ' invalid'
  if (meta.initial && meta.pristine) labelClassName += ' label-active'
  if (!input.value && meta.touched) labelClassName += ' label-force-inactive'

  return (
    <div className={`input-field input-text-field col${className}${labelClassName}`}>
      <label htmlFor={`input_text_${input.name}`} error={meta.error}>
        {label}
      </label>
      <input
        id={`input_text_${input.name}`}
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        {...input}
      />
    </div>
  )
}
renderInputText.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  s: PropTypes.number,
  m: PropTypes.number,
  l: PropTypes.number,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}

export const renderInput = ({
  size,
  label,
  input,
  minLength,
  maxLength,
  onChange,
  disabled = false,
  inputType,
  className = ''
}) => (
  <Fragment>
    <Input
      s={size}
      disabled={disabled}
      onChange={onChange}
      minLength={minLength}
      maxLength={maxLength}
      label={label}
      type={inputType || 'text'}
      className={className}
      {...input}
    />
    {/* {meta.invalid && meta.touched && meta.error && (
      <span className="text-input-error">{meta.error}</span>
    )} */}
  </Fragment>
)
renderInput.propTypes = {
  size: PropTypes.number,
  label: PropTypes.string,
  inputType: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  onChange: PropTypes.func,
  input: PropTypes.object,
  minLength: PropTypes.number,
  maxLength: PropTypes.number
}

export const renderCheckbox = ({ size, label, input, disabled = false, className = '', type = 'checkbox' }) => {
  const newPropInput = {
    ...input,
    value: String(input.value) // necessary because Input doesn't accept boolean as type for the value prop
  }
  return (
    <Input
      s={size}
      disabled={disabled}
      label={label}
      type={type}
      className={className}
      checked={input.value}
      {...newPropInput}
    />
  )
}
renderCheckbox.propTypes = {
  size: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  input: PropTypes.object,
  type: PropTypes.any
}

export class renderCheckboxRadio extends Input {
  componentWillReceiveProps(nextProps) {
    if (this.state.checked !== nextProps.checked) {
      this.setState({
        checked: nextProps.checked
      })
    }
  }

  _onChange(ev) {
    super._onChange(ev)
    const {
      change,
      input: { name },
      touch,
      handleChange
    } = this.props
    touch(name)
    change(name, ev.target.checked)
    if (handleChange) {
      handleChange()
    }
  }
}

renderCheckboxRadio.propTypes = {
  size: PropTypes.number,
  label: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  input: PropTypes.object,
  type: PropTypes.any,
  checked: PropTypes.bool,
  multiple: PropTypes.any,
  defaultValue: PropTypes.any,
  browserDefault: PropTypes.any
}

export const renderServizioCheckbox = ({ title = ' ', input, disabled = false, className = '' }) => (
  <Fragment>
    <input {...input} id={input.name} type="checkbox" disabled={disabled} className={className} checked={input.value} />
    <label htmlFor={input.name} className={input.value ? 'active' : ''}>
      <div className={`button-servizio-check${input.value ? ' checked' : ''}`}>
        <i className="icon-prodotti-action-bar" />
        <p className="">{title}</p>
      </div>
    </label>
  </Fragment>
)
renderServizioCheckbox.propTypes = {
  size: PropTypes.number,
  title: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  input: PropTypes.object
}

export const renderSelect = ({ options = [], size, disabled = false, label, input, meta: { touched, error } }) => {
  const handler = value => {
    if (input.onChange && typeof input.onChange === 'function') {
      input.onChange(value)
    }
  }
  return (
    <div className="input-select-field">
      <Input
        {...input}
        s={size}
        type="select"
        label={label}
        error={touched && error ? error : null}
        onChange={handler}
        disabled={disabled}>
        {options}
      </Input>
    </div>
  )
}
renderSelect.propTypes = {
  options: PropTypes.array,
  size: PropTypes.number,
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
}
renderSelect.defaultProps = {
  meta: {
    touched: false,
    error: ''
  }
}

export class renderAutocomplete extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.defaultValue || ''
    }
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value && !this.props.input.value) {
      this.setState({ value: '' })
    }

    if (prevProps.defaultValue !== this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue })
    }
  }

  handleSelect(value, isMatched) {
    const { handleChange, input } = this.props
    handleChange(input.name, isMatched ? isMatched.value : value)
    this.setState({ value })
  }

  render() {
    const {
      size,
      label,
      hideIcon,
      threshold,
      meta: { error, form, touched },
      input,
      options,
      disabled,
      iconCallback,
      tooltipTitle,
      suppressFallbackError,
      searchByValue
    } = this.props
    return (
      <Autocomplete
        s={size}
        label={label}
        threshold={threshold}
        onChange={this.handleSelect}
        value={this.state.value}
        id={`${form}_${input.name}`}
        hideIcon={hideIcon}
        input={input}
        error={(touched && error) || (suppressFallbackError && error) ? error : ''}
        suggestions={options}
        disabled={disabled}
        iconCallback={iconCallback}
        tooltipTitle={tooltipTitle}
        suppressFallbackError={suppressFallbackError}
        searchByValue={searchByValue}
      />
    )
  }
}
renderAutocomplete.propTypes = {
  options: PropTypes.array,
  size: PropTypes.number,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  input: PropTypes.object,
  defaultValue: PropTypes.any,
  threshold: PropTypes.number,
  handleChange: PropTypes.func,
  meta: PropTypes.object,
  hideIcon: PropTypes.bool,
  iconCallback: PropTypes.func,
  tooltipTitle: PropTypes.string,
  suppressFallbackError: PropTypes.bool,
  searchByValue: PropTypes.bool
}

export const renderSlider = ({ label, input, min, max, error }) => {
  return (
    <SliderComponent title={label} min={min} max={max} onChange={input.onChange} value={input.value} error={error} />
  )
}
renderSlider.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.string
}

export const renderAssegnoField = ({ meta: { dirty, error }, max, size, field, formValue, regExp, change }) => (
  <Col s={size} /* style={{ borderBottom: '1px solid grey' }} */>
    <Input
      s={12}
      className={dirty && error ? 'error' : ''}
      label={field.label}
      type="text"
      value={formValue}
      onInput={({ target: { value } }) => {
        if (regExp.test(value) && value.length <= max) {
          change(field.id, value)
        }
      }}
      style={{ borderRight: '1px solid grey' }}
    />
    {dirty && error && <div className="error-msg">{error}</div>}
  </Col>
)
renderAssegnoField.propTypes = {
  meta: PropTypes.object,
  max: PropTypes.number.isRequired,
  size: PropTypes.number,
  field: PropTypes.object,
  formValue: PropTypes.any,
  regExp: PropTypes.any,
  change: PropTypes.func
}
