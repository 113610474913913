import React from 'react'
import allFields from '../fields'
import * as Forms from '../../../store/modules/forms/constants'

let fieldsWithErrors = []
let listaRisposte = []
let validators = {}
let mapNumDomande = {}

// Se tutte le validazioni per il singolo campo sono fallite allora sollevo l'errore (di base sono in "OR")
const VALIDATE_KO_AND = 'validate_ko_and'
const VALIDATE_KO_AND_IS_FALSE = 'validate_ko_and_if'
let isValidateAnd = false

const findIdDomandaPadre = (id, listaDomande) => {
  for (let i = 0; i < listaDomande.length; i++) {
    let _domanda = listaDomande[i]
    if (_domanda.strutturaInternaDomande && _domanda.strutturaInternaDomande.domande) {
      for (let j = 0; j < _domanda.strutturaInternaDomande.domande.length; j++) {
        let _domandaFiglia = _domanda.strutturaInternaDomande.domande[j]
        if (_domandaFiglia.idDomanda === id) {
          return _domanda.idDomanda
        }
      }
    }
  }
  return null
}

export const isSaveDisabled = listaDomande => {
  const idDomande = listaDomande.map(d => d.idDomanda)
  let errors = fieldsWithErrors.filter(d => idDomande.indexOf(d) > -1)
  return errors.length > 0
}

function intersect(a, b) {
  const setB = new Set(b)
  return [...new Set(a)].filter(x => setB.has(x))
}

const labelNumDomandeEsgPd = ['4.3', '4.1']
const labelNumDomandeEsgPfPg = ['5.4', '5.2']

export const buildErrorsTemplate = mifidType => {
  const mifidFields = allFields[2]
  const interessiEsg = [
    mifidFields[Forms.MIFID_INVESTIMENTI_ESG_PF].INTERESSE.templateId,
    mifidFields[Forms.MIFID_INVESTIMENTI_ESG_PD].INTERESSE.templateId,
    mifidFields[Forms.MIFID_INVESTIMENTI_ESG_PG].INTERESSE.templateId
  ]

  let _errors = fieldsWithErrors
    .map(id => {
      return mapNumDomande[id]
    })
    .reverse()

  // Le preferenze di sostenibilità espresse nella domanda 5.4 non sono coerenti con quelle manifestate nella domanda 5.2
  if (intersect(interessiEsg, fieldsWithErrors).length) {
    const esgLabel = mifidType === 'MIFID_PD' ? labelNumDomandeEsgPd : labelNumDomandeEsgPfPg

    return (
      <React.Fragment>
        <p>
          Le preferenze di sostenibilità espresse nella domanda {'' + esgLabel[0]} non sono coerenti con quelle
          manifestate nella domanda {esgLabel[1]}
        </p>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <p>
        La risposta fornita alla domanda {_errors[0]} risulta incoerente con quella fornita alla domanda
        {_errors.map((numDomanda, index) => {
          if (index === 0) {
            return
          }
          if (index !== _errors.length - 1) {
            return ' ' + numDomanda + ','
          }
          return ' ' + numDomanda
        })}
      </p>
    </React.Fragment>
  )
}
let isValidateAndIsFalse
export const checkValidationErrors = () => {
  let _fieldsWithErrors = []
  Object.keys(validators).forEach(idDomanda => {
    const _v = validators[idDomanda]
    let groups = Array.from(new Set(_v.map(v => v.idGroup)))

    groups.forEach(g => {
      let _hasErrors = []
      let _dependencyErrors = []
      _v.filter(v => v.idGroup === g).forEach(v => {
        // Se true i validatori vanno in AND, quindi devono essere veri tutti per scatenare l'errore
        isValidateAnd = v.tipoDipendenza === VALIDATE_KO_AND
        isValidateAndIsFalse = v.tipoDipendenza === VALIDATE_KO_AND_IS_FALSE
        if (listaRisposte[idDomanda] && listaRisposte[idDomanda].includes(v.idRisposta)) {
          if (isValidateAndIsFalse) {
            if (
              listaRisposte[v.idDomandaDipendenza] &&
              !listaRisposte[v.idDomandaDipendenza].includes(v.idRispostaDipendenza)
            ) {
              _hasErrors.push(true)
            } else {
              _hasErrors.push(false)
            }
          } else {
            if (
              listaRisposte[v.idDomandaDipendenza] &&
              listaRisposte[v.idDomandaDipendenza].includes(v.idRispostaDipendenza)
            ) {
              // Se ho idDomandaPadre vuol dire che mi trovo in una domanda figlia e devo recuperare il padre (per segnalare l'errore su front)
              if (v.idDomandaPadre) {
                _dependencyErrors.push(v.idDomandaPadre)
              }
              _hasErrors.push(true)
            } else {
              _hasErrors.push(false)
            }
          }
        }
      })
      // todo -> rework, fare pulizia codice
      // Se isValidateAnd === true allora devo controllare che dentro _hasErrors siano tutti true (se c'è anche un solo false non scateno l'errore)
      if (_hasErrors.length > 0) {
        if (
          (!(isValidateAnd || isValidateAndIsFalse) && _hasErrors.includes(true)) ||
          ((isValidateAnd || isValidateAndIsFalse) && !_hasErrors.includes(false))
        ) {
          _fieldsWithErrors = [..._fieldsWithErrors, parseInt(idDomanda), ..._dependencyErrors]
        }
      }
    })
  })
  // Rimuovo eventuali doppioni (dato che devo stampare la modale in cui non devono esserci doppioni)
  _fieldsWithErrors = _fieldsWithErrors.reduce((acc, curr) => {
    return acc.includes(curr) ? acc : [...acc, curr]
  }, [])
  fieldsWithErrors = _fieldsWithErrors
  return fieldsWithErrors
}

export const setValidators = listaDomande => {
  let _m = {}

  const getNumberQuestion = number => {
    return number
  }

  let _v = listaDomande.reduce((acc, d) => {
    _m[d.idDomanda] = getNumberQuestion(d.numDomanda)
    if (d.validators.length > 0) {
      let _v = d.validators.map(validator => {
        validator.idDomandaPadre = findIdDomandaPadre(validator.idDomandaDipendenza, listaDomande)
        return validator
      })

      if (!acc[d.idDomanda]) {
        acc[d.idDomanda] = []
      }
      acc[d.idDomanda] = [...acc[d.idDomanda], ..._v]
    } else if (d.strutturaInternaDomande && d.strutturaInternaDomande.domande.length !== 0) {
      _m[d.idDomanda] = getNumberQuestion(d.strutturaInternaDomande.domande[0].numDomanda)
      _m[d.strutturaInternaDomande.domande[0].idDomanda] = getNumberQuestion(
        d.strutturaInternaDomande.domande[0].numDomanda
      )
      let _v = d.strutturaInternaDomande.domande[0].validators.map(validator => {
        validator.idDomandaPadre = findIdDomandaPadre(validator.idDomandaDipendenza, listaDomande)
        return validator
      })

      if (!acc[d.strutturaInternaDomande.domande[0].idDomanda]) {
        acc[d.strutturaInternaDomande.domande[0].idDomanda] = []
      }
      acc[d.strutturaInternaDomande.domande[0].idDomanda] = [
        ...acc[d.strutturaInternaDomande.domande[0].idDomanda],
        ..._v
      ]
    }
    return acc
  }, {})

  validators = { ...validators, ..._v }
  mapNumDomande = { ...mapNumDomande, ..._m }
}

export const getFormValuesESG = currentFormValues => {
  const res = {}
  Object.keys(currentFormValues).forEach(v => {
    let key = v.replace('field_', '')
    if (key.includes('_')) {
      // Checkbox
      let [idDomanda, idRisposta] = key.split('_')
      // Checkbox a true
      if (currentFormValues[v]) {
        if (!res[idDomanda]) {
          res[idDomanda] = []
          res[idDomanda].push(parseInt(idRisposta))
        } else {
          res[idDomanda].push(parseInt(idRisposta))
        }
      }
    } else {
      // Tutti gli altri campi diversi da checkbox
      res[key] = [currentFormValues[v] ? parseInt(currentFormValues[v]) : '']
    }
  })
  return res
}

export const getFormValues = currentFormValues => {
  const res = {}
  Object.keys(currentFormValues).forEach(v => {
    let key = v.replace('field_', '')
    if (key.includes('_')) {
      // Checkbox
      let [idDomanda, idRisposta] = key.split('_')
      // Checkbox a true
      if (currentFormValues[v]) {
        if (!res[idDomanda]) {
          res[idDomanda] = []
          res[idDomanda].push(parseInt(idRisposta))
        } else {
          res[idDomanda].push(parseInt(idRisposta))
        }
      } else {
        // Checkbox a false (va sovrascritto il valore altrimenti rimane l'errore di validazione)
        res[idDomanda] = ['']
      }
    } else {
      // Tutti gli altri campi diversi da checkbox
      res[key] = [currentFormValues[v] ? parseInt(currentFormValues[v]) : '']
    }
  })
  return res
}

export const setRisposte = risposte => {
  listaRisposte = { ...listaRisposte, ...risposte }
}

export const resetValidations = () => {
  fieldsWithErrors = []
  listaRisposte = []
  validators = {}
  mapNumDomande = {}
}
