/* eslint-disable react/jsx-indent */
import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Row, Col } from 'react-materialize'
import InputText from '../../Inputs/Text'
import DomicilioForm from '../../../components/Forms/Residenza/DomicilioForm'
import {
  renderDatePicker,
  renderSelect,
  renderInputPdf,
  renderAutocomplete,
  renderCheckboxRadio
  // renderCheckbox
} from '../renderFields'
import moment from 'moment'
import {
  getDominio,
  getAutocompleteDefaultOption,
  mapDominiToAutocomplete,
  getValueDominioFromKey,
  mapDominiToOptions
} from '../../../routes/ConsulenzaBaseContainerLayout/utils'

class DatiPersonaGiuridica extends Component {
  constructor(props) {
    super(props)

    this.state = { notChecked: true }

    this.toggleChange = this.toggleChange.bind(this)
  }
  checkNazionePG(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { NAZIONE_PG }
    } = this.props
    if (isItalia) {
      return formValues[NAZIONE_PG.id] === NAZIONE_PG.italiaId
    }

    return !!getValueDominioFromKey(listaDomini, NAZIONE_PG.dominio, formValues[NAZIONE_PG.id])
  }
  checkProvinciaPG(value) {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA_PG }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, PROVINCIA_PG.dominio, value)
  }

  checkComunePG(value) {
    const {
      domini: { listaDomini },
      fields: { CITTA_PG }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, CITTA_PG.dominio, value)
  }

  checkCapPG() {
    const {
      formValues,
      domini: { listaDomini },
      fields: { CITTA_PG }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, CITTA_PG.dominio, formValues[CITTA_PG.id])
  }

  updateDominio(idDominio, codice) {
    this.props.dominioCustomRequest(idDominio, codice)
    // { idDominio: 'DOM_COMUNE_RESIDENZA_PROC', filtro: { codice: sezioneProcuratore.residenceProvince } }
  }

  componentDidMount() {
    // const {  fields, domini } = this.props
    // const {
    //   FORMA_ASSOCIATIVA_PG
    // } = fields
    // let dom = getDominio(domini, FORMA_ASSOCIATIVA_PG.dominio)
    // let map = mapDominiToOptions(dom, true)
    // console.log(dom, map)
  }

  toggleChange() {
    this.setState({
      notChecked: !this.state.notChecked
    })
  }
  get canProceed() {
    const { notChecked } = this.state
    return !notChecked
  }

  flagCheckModuleHandler() {
    this.setState({
      flagModuloFirmato: !this.state.flagModuloFirmato
    })
  }

  render() {
    const {
      change,
      formValues = {},
      touch,
      fields,
      domini,
      updateDominio,
      dominioRequest = {},
      formErrors,
      isDirty
    } = this.props

    const {
      CODICE_FISCALE_PG,
      RAGIONE_SOCIALE_PG,
      FORMA_ASSOCIATIVA_PG,
      SEDE_LEGALE_PG,
      NAZIONE_PG,
      PROVINCIA_PG,
      CITTA_PG,
      CITTA_ESTERO_PG,
      CAP_PG,
      DATA_COSTITUZIONE_PG,
      NAZIONE_PG_COSTITUZIONE,
      PROVINCIA_PG_COSTITUZIONE,
      CITTA_PG_COSTITUZIONE,
      ATTIVITA_ESERCITATA_PG,
      CODICE_ATECO_PG,
      PARTITA_IVA_PG,
      STATUTO,
      ATTO_COSTITUTIVO,
      VISURA_CAMERALE,
      CELLULARE_PG,
      EMAIL_PG
      // HA_DOMICILIO
    } = fields

    let bool = location.pathname.indexOf('-PG') >= 0
    return (
      <Fragment>
        <Row>
          <Field
            name={CODICE_FISCALE_PG.id}
            s={6}
            maxLength={CODICE_FISCALE_PG.maxLength}
            label={CODICE_FISCALE_PG.label}
            change={change}
            touch={touch}
            disabled
            component={InputText}
            formatter={value => (value ? value.toUpperCase() : '')}
          />
          <Field
            name={PARTITA_IVA_PG.id}
            s={6}
            maxLength={PARTITA_IVA_PG.maxLength}
            label={PARTITA_IVA_PG.label}
            change={change}
            touch={touch}
            disabled
            component={InputText}
          />
        </Row>

        <Row>
          <Field
            name={CELLULARE_PG.id}
            s={6}
            label={CELLULARE_PG.label}
            change={change}
            touch={touch}
            disabled={STATUTO}
            component={InputText}
          />
          <Field
            name={EMAIL_PG.id}
            s={6}
            label={EMAIL_PG.label}
            change={change}
            touch={touch}
            disabled={STATUTO}
            component={InputText}
          />
        </Row>

        <Row>
          <Field
            name={FORMA_ASSOCIATIVA_PG.id}
            size={6}
            label={FORMA_ASSOCIATIVA_PG.label + '*'}
            options={mapDominiToOptions(getDominio(domini, FORMA_ASSOCIATIVA_PG.dominio), true)}
            component={renderSelect}
          />
          <Field
            name={RAGIONE_SOCIALE_PG.id}
            s={6}
            maxLength={RAGIONE_SOCIALE_PG.maxLength}
            label={RAGIONE_SOCIALE_PG.label}
            change={change}
            touch={touch}
            disabled
            component={InputText}
          />
        </Row>
        <Row>
          <Col s={6}>
            <Field
              name={NAZIONE_PG.id}
              s={12}
              label={NAZIONE_PG.label}
              threshold={0}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                NAZIONE_PG.dominio,
                formValues[NAZIONE_PG.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_PG.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[PROVINCIA_PG.id]) {
                  change(PROVINCIA_PG.id, '')
                }
                if (formValues[CITTA_PG.id]) {
                  change(CITTA_PG.id, '')
                }
                if (formValues[CITTA_ESTERO_PG.id]) {
                  change(CITTA_ESTERO_PG.id, '')
                }
                if (formValues[CAP_PG.id]) {
                  change(CAP_PG.id, '')
                }
              }}
              component={renderAutocomplete}
            />
          </Col>

          <Col s={6}>
            <Field
              name={PROVINCIA_PG.id}
              s={12}
              label={PROVINCIA_PG.label}
              threshold={0}
              disabled={!this.checkNazionePG(true)}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                PROVINCIA_PG.dominio,
                formValues[PROVINCIA_PG.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_PG.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[CITTA_PG.id]) {
                  change(CITTA_PG.id, '')
                }

                this.props.updateDominio(CITTA_PG.dominio, value)
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>

        <Row>
          {this.checkNazionePG(true) && (
            <Col s={6}>
              <Field
                name={CITTA_PG.id}
                s={12}
                threshold={0}
                label={CITTA_PG.label}
                disabled={!this.checkProvinciaPG(formValues[PROVINCIA_PG.id])}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  CITTA_PG.dominio,
                  formValues[CITTA_PG.id]
                )}
                options={
                  formValues[PROVINCIA_PG.id] ? mapDominiToAutocomplete(getDominio(domini, CITTA_PG.dominio)) : []
                }
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[CAP_PG.id]) {
                    change(CAP_PG.id, '')
                  }
                  if (this.checkComunePG(value)) {
                    this.setState({ waitingCap: true })
                    updateDominio(CAP_PG.dominio, value)
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
          )}

          {!this.checkNazionePG(true) && (
            <Field
              name={CITTA_ESTERO_PG.id}
              s={6}
              maxLength={50}
              disabled={!this.checkNazionePG()}
              label={CITTA_ESTERO_PG.label}
              // error={formErrors[CITTA_ESTERO_PG.id]}
              value={formValues[CITTA_ESTERO_PG.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          )}

          <Col s={6}>
            <Field
              name={CAP_PG.id}
              s={12}
              threshold={0}
              label={CAP_PG.label}
              disabled={!this.checkCapPG()}
              defaultValue={getAutocompleteDefaultOption(domini.listaDomini, CAP_PG.dominio, formValues[CAP_PG.id])}
              options={formValues[CITTA_PG.id] ? mapDominiToAutocomplete(getDominio(domini, CAP_PG.dominio)) : []}
              handleChange={change}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
        <Row>
          <Field
            name={SEDE_LEGALE_PG.id}
            s={6}
            maxLength={SEDE_LEGALE_PG.maxLength}
            label={SEDE_LEGALE_PG.label}
            change={change}
            touch={touch}
            component={InputText}
          />
          <Field
            name={DATA_COSTITUZIONE_PG.id}
            s={6}
            openDirection="down"
            anchorDirection="right"
            label={fields.DATA_COSTITUZIONE_PG.label}
            placeholder=""
            readOnly
            isOutsideRange={date => date.isAfter(moment().subtract(0, 'y'), 'day')}
            defaultDate={formValues[fields.DATA_COSTITUZIONE_PG.id]}
            onDateChange={date => change(fields.DATA_COSTITUZIONE_PG.id, date.format())}
            component={renderDatePicker}
          />
        </Row>

        <Row>
          <Col s={6}>
            <Field
              name={NAZIONE_PG_COSTITUZIONE.id}
              s={12}
              label={NAZIONE_PG_COSTITUZIONE.label}
              threshold={0}
              defaultValue="ITALIA"
              disabled
              /* defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                NAZIONE_PG_COSTITUZIONE.dominio,
                formValues[NAZIONE_PG_COSTITUZIONE.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_PG_COSTITUZIONE.dominio))} */
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[PROVINCIA_PG_COSTITUZIONE.id]) {
                  change(PROVINCIA_PG_COSTITUZIONE.id, '')
                }
                if (formValues[CITTA_PG.id]) {
                  change(CITTA_PG_COSTITUZIONE.id, '')
                }
              }}
              component={renderAutocomplete}
            />
          </Col>

          <Col s={6}>
            <Field
              name={PROVINCIA_PG_COSTITUZIONE.id}
              s={12}
              label={PROVINCIA_PG_COSTITUZIONE.label}
              threshold={0}
              // disabled={!this.checkNazionePG(true)}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                PROVINCIA_PG_COSTITUZIONE.dominio,
                formValues[PROVINCIA_PG_COSTITUZIONE.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_PG_COSTITUZIONE.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[CITTA_PG_COSTITUZIONE.id]) {
                  change(CITTA_PG_COSTITUZIONE.id, '')
                }

                this.props.updateDominio(CITTA_PG_COSTITUZIONE.dominio, value)
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>

        <Row>
          <Col s={6}>
            <Field
              name={CITTA_PG_COSTITUZIONE.id}
              s={12}
              threshold={0}
              label={CITTA_PG_COSTITUZIONE.label}
              disabled={!this.checkProvinciaPG(formValues[PROVINCIA_PG_COSTITUZIONE.id])}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                CITTA_PG_COSTITUZIONE.dominio,
                formValues[CITTA_PG_COSTITUZIONE.id]
              )}
              options={
                formValues[PROVINCIA_PG_COSTITUZIONE.id]
                  ? mapDominiToAutocomplete(getDominio(domini, CITTA_PG_COSTITUZIONE.dominio))
                  : []
              }
              handleChange={(field, value) => {
                change(field, value)
              }}
              component={renderAutocomplete}
            />
          </Col>
          <Field
            name={CODICE_ATECO_PG.id}
            s={6}
            maxLength={CODICE_ATECO_PG.maxLength}
            label={CODICE_ATECO_PG.label}
            change={change}
            touch={touch}
            component={InputText}
          />
        </Row>

        <Row>
          {/* non eliminare attività esercitata */}
          <Field
            name={ATTIVITA_ESERCITATA_PG.id}
            size={6}
            label={ATTIVITA_ESERCITATA_PG.label}
            options={mapDominiToOptions(getDominio(domini, ATTIVITA_ESERCITATA_PG.dominio), true)}
            component={renderSelect}
            handleChange={(field, value) => {
              change(field, value)
            }}
          />
        </Row>

        <DomicilioForm
          fields={fields}
          change={change}
          touch={touch}
          updateDominio={updateDominio}
          dominioRequest={dominioRequest}
          domini={domini}
          formErrors={formErrors}
          formValues={formValues}
          isDirty={isDirty}
        />

        {STATUTO && (
          <Row className="estraMarginTop40">
            {!bool && (
              <Fragment>
                <Col s={12}>Upload documenti</Col>
                <Col s={12} className="subtitle margin-b-20">
                  Documenti necessari per procedere con l'onboarding: Statuto, Atto Costitutivo, Visuara camerale
                </Col>
                <Col s={4} className="padding-r-20">
                  <Field
                    name={STATUTO.id}
                    idImg={STATUTO.id}
                    fileIn={formValues[STATUTO.id]}
                    label={'Documento di Statuto'}
                    onChangeHandler={(file, fileThumb) => {
                      change(STATUTO.id, file)
                      change(STATUTO.id, fileThumb)
                    }}
                    component={renderInputPdf}
                  />
                </Col>
              </Fragment>
            )}
            {bool && (
              <Col s={4}>
                <Field
                  name={STATUTO.id}
                  checked={formValues[STATUTO.id]}
                  value={formValues[STATUTO.id]}
                  label="Documento di statuto acquisito"
                  onClick={() => this.flagCheckModuleHandler()}
                  change={change}
                  touch={touch}
                  type="checkbox"
                  component={renderCheckboxRadio}
                />
              </Col>
            )}

            {!bool && (
              <Col s={4} className="padding-l-20 padding-r-20">
                <Field
                  name={ATTO_COSTITUTIVO.id}
                  idImg={ATTO_COSTITUTIVO.id}
                  fileIn={formValues[ATTO_COSTITUTIVO.id]}
                  label={'Documento di Atto Costitutivo'}
                  onChangeHandler={(file, fileThumb) => {
                    change(ATTO_COSTITUTIVO.id, file)
                    change(ATTO_COSTITUTIVO.id, fileThumb)
                  }}
                  component={renderInputPdf}
                />
              </Col>
            )}
            {bool && (
              <Col s={4}>
                <Field
                  name={ATTO_COSTITUTIVO.id}
                  checked={formValues[ATTO_COSTITUTIVO.id]}
                  value={formValues[ATTO_COSTITUTIVO.idd]}
                  label={'Documento di Atto Costitutivo acquisito'}
                  onClick={() => this.flagCheckModuleHandler()}
                  change={change}
                  touch={touch}
                  type="checkbox"
                  component={renderCheckboxRadio}
                />
              </Col>
            )}

            {!bool && (
              <Col s={4} className="padding-l-20">
                <Field
                  name={VISURA_CAMERALE.id}
                  idImg={VISURA_CAMERALE.id}
                  fileIn={formValues[VISURA_CAMERALE.id]}
                  label={'Documento di Visuara Camerale'}
                  onChangeHandler={(file, fileThumb) => {
                    change(VISURA_CAMERALE.id, file)
                    change(VISURA_CAMERALE.id, fileThumb)
                  }}
                  component={renderInputPdf}
                />
              </Col>
            )}
            {bool && (
              <Col s={4}>
                <Field
                  name={VISURA_CAMERALE.id}
                  checked={formValues[VISURA_CAMERALE.id]}
                  value={formValues[VISURA_CAMERALE.id]}
                  label={'Documento di Visura Camerale acquisito'}
                  onClick={() => this.flagCheckModuleHandler()}
                  change={change}
                  touch={touch}
                  type="checkbox"
                  component={renderCheckboxRadio}
                />
              </Col>
            )}
          </Row>
        )}
      </Fragment>
    )
  }
}

DatiPersonaGiuridica.propTypes = {
  dominioRequest: PropTypes.object,
  formErrors: PropTypes.any,
  isDirty: PropTypes.any,
  change: PropTypes.func,
  formValues: PropTypes.object,
  touch: PropTypes.func,
  fields: PropTypes.object,
  domini: PropTypes.any,
  dominioCustomRequest: PropTypes.func,
  updateDominio: PropTypes.func
}

export default DatiPersonaGiuridica
