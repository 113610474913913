import * as actionTypes from './constants'
import axios from 'axios'
import { retrieveDataSummaryLight } from '../customers/actions'
import { retrieveDataSummaryLightPG } from '../personaGiuridica/prospect/actions'

export function validationCreateOtp(type, queryParam) {
  return async function(dispatch) {
    const query = {
      ...queryParam
    }

    dispatch({
      type: actionTypes.VALIDATION_CREATE_OTP_START,
      query: query
    })
    try {
      // axios.post(`/v2/validation/${type}/send`, queryParam)
      const response = await axios({
        method: 'POST',
        url: `/v2/validation/${type}/send`,
        data: queryParam
      })
      /* axios.post(`/v2/validation/${type}/send`, queryParam) */
      dispatch({
        type: actionTypes.VALIDATION_CREATE_OTP_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.VALIDATION_CREATE_OTP_ERROR,
        error: error
      })
    }
  }
}

export function validationVerifyOtp(type, queryParam, active, saveCallback, isProspectPGAvailable) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.VALIDATION_VERIFY_OTP_START,
      query: queryParam
    })
    let data = null
    axios({
      method: 'POST',
      url: `/v2/validation/${type}/verify`,
      data: queryParam
    })
      // axios({
      //   method: active ? 'PUT' : 'POST',
      //   url: `/v2/validation/${type}/verify`,
      //   data: queryParam
      // })
      .then(response => {
        data = response.data
        if (queryParam.subjectType === 'LEGAL_PERSON') {
          return retrieveDataSummaryLightPG(queryParam.idCliente, queryParam.idIncarico)
        } else {
          return retrieveDataSummaryLight(queryParam.idCliente, queryParam.idIncarico)
        }
      })
      .then(response => {
        dispatch({
          type: actionTypes.VALIDATION_VERIFY_OTP_SUCCESS,
          data,
          summaryLight: response.data
        })
      })
      .then(_ => {
        if (saveCallback && data && data.verified) {
          if (queryParam.subjectType !== 'LEGAL_PERSON') {
            saveCallback()
          }
        }
      })
      .catch(error => {
        dispatch({
          type: actionTypes.VALIDATION_VERIFY_OTP_ERROR,
          error
        })
      })
  }
}
