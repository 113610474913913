import * as actionTypes from './constants'

const initialState = {
  dataLoadingState: 'spinner',
  pageSizeValue: 20
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_PAGE_SIZE:
      return {
        ...state,
        pageSizeValue: action.pageSizeValue
      }
    case actionTypes.SET_DATA_LOADING:
      return {
        ...state,
        dataLoadingState: action.dataLoadingValue
      }
  }
  return state
}
