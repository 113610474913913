import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Button } from 'react-materialize'
import { Field } from 'redux-form'
import { mapDominiToOptions, getDominio } from '../../utils'
import SoggettiCorrelatiTable from '../../../../components/Tables/SoggettiCorrelati/SoggettiCorrelatiTableGiuridiche'
import SoggettiGiaCorrelatiTable from '../../../../components/Tables/SoggettiCorrelati/SoggettiGiaCorrelatiTableGiuridiche'
import { SOGGETTI_CORRELATI_GIURIDICHE } from '../../../../store/modules/forms/constants'
import InputText from '../../../../components/Inputs/Text'
import { renderSelect } from '../../../../components/Forms/renderFields'
import allFields from '../../../ConsulenzaBaseContainerLayout/fields'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'

const fields = allFields[1][SOGGETTI_CORRELATI_GIURIDICHE]

class SoggettiCorrelatiGiuridiche extends Component {
  constructor(props) {
    super(props)
    this.state = {
      personaFisica: null,
      personaGiuridica: null,
      personAdded: null,
      cont: -1,
      soggGiaCorr: true,
      numPage: 1
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  componentDidMount() {
    this.giaCorrelati()
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.inviaRichiestaGiuridicheRequest &&
      prevProps.inviaRichiestaGiuridicheRequest &&
      prevProps.inviaRichiestaGiuridicheRequest.fetching === true &&
      this.props.inviaRichiestaGiuridicheRequest.fetching === false &&
      this.props.inviaRichiestaGiuridicheRequest.error === null
    ) {
      this.giaCorrelati()
    }
  }

  giaCorrelati() {
    this.props.getSoggettiGiaCorrelati(this.props.customer.idCliente)
    /* this.props.getSoggettiGiaCorrelatiGiuridiche(this.props.customer.idCliente) */
  }

  handleSubmit() {
    const { formValues = {}, searchSoggettiCorrelati } = this.props
    const { RICERCA_PG } = fields
    const data = {
      querySearch: formValues[RICERCA_PG.id],
      customerSubjectType: 'PG',
      itemxpage: 10,
      page: 1
    }
    searchSoggettiCorrelati(data)
    this.setState({ personaFisica: true })
    this.setState({ soggGiaCorr: false })
  }

  render() {
    const {
      soggettiCorrelatiRequest,
      inviaRichiestaGiuridicheRequest,
      handleSubmit,
      formValues = {},
      formErrors = {},
      domini,
      change,
      touch,
      soggettiCorrelatiListDataGiuridiche,
      totalpage,
      soggettiGiaCorrelatiListData,
      invalid,
      // soggettiCorrelatiListDataGiuridicheGiuridiche,
      // soggettiGiaCorrelatiListDataGiuridiche,
      inviaRichiestaSoggettiCorrelati
    } = this.props
    const { RICERCA_PG } = fields
    const { personaFisica, cont, soggGiaCorr } = this.state
    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        {cont === -1 && (
          <Fragment>
            <Row>
              {soggGiaCorr && (
                <Col s={12}>
                  <SoggettiGiaCorrelatiTable
                    list={soggettiGiaCorrelatiListData}
                    totalpage={totalpage}
                    {...this.props}
                  />
                </Col>
              )}
              {/* <Col s={9}>
                <Field
                  name={RICERCA.id}
                  label={RICERCA.label}
                  s={12}
                  error={formErrors[RICERCA.id]}
                  formValues={formValues[RICERCA.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
              <Col s={3} style={{ marginTop: '20px' }}>
                <Button disabled={formValues[RICERCA.id] === ''} className="right soggetiCorrelati">
                  <i className="material-icons">search</i>
                  CERCA
                </Button>
              </Col> */}
              <Col s={9}>
                <Field
                  name={RICERCA_PG.id}
                  label={RICERCA_PG.label}
                  s={12}
                  error={formErrors[RICERCA_PG.id]}
                  formValues={formValues[RICERCA_PG.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
              <Col s={3} style={{ marginTop: '20px' }}>
                <Button disabled={invalid} className="right soggetiCorrelati">
                  <i className="material-icons">search</i>
                  CERCA
                </Button>
              </Col>
            </Row>
          </Fragment>
        )}

        {personaFisica && !soggGiaCorr && !soggettiCorrelatiRequest.fetching && !soggettiCorrelatiRequest.error && (
          <Row>
            <Col s={12}>
              {cont === -1 && (
                <SoggettiCorrelatiTable
                  list={soggettiCorrelatiListDataGiuridiche.listRelatedSubjects}
                  contatore={cont => this.setState({ cont })}
                  fields={fields}
                  incrementa={() => this.setState({ numPage: this.state.numPage + 1 })}
                  decrementa={() => this.setState({ numPage: this.state.numPage - 1 })}
                  numPage={this.state.numPage}
                  {...this.props}
                />
              )}
              {cont >= 0 && (
                <div className="soggetti-correlati-user">
                  <Row>
                    <Col s={5}>
                      <ReadOnlyInput
                        label="Ragione sociale, sede e codice fiscale"
                        value={`${soggettiCorrelatiListDataGiuridiche.listRelatedSubjects[cont].businessName}, ${
                          soggettiCorrelatiListDataGiuridiche.listRelatedSubjects[cont].registeredOfficeAddress
                        },  ${soggettiCorrelatiListDataGiuridiche.listRelatedSubjects[cont].taxCode}
                          `}
                      />
                    </Col>
                    <Col s={3}>
                      <Field
                        s={12}
                        name={fields.RELAZIONE.id}
                        label={fields.RELAZIONE.label}
                        options={mapDominiToOptions(getDominio(domini, fields.RELAZIONE.dominio), true)}
                        component={renderSelect}
                      />
                    </Col>
                    <Col s={4}>
                      {!soggGiaCorr && (
                        <Button
                          type="button"
                          className="invia"
                          onClick={() => {
                            inviaRichiestaSoggettiCorrelati({
                              idLegalPerson: this.props.customer.idCliente,
                              idLegalPersonRelated:
                                (soggettiCorrelatiListDataGiuridiche.listRelatedSubjects[cont] || {})
                                  .idLegalPersonRelated || null,
                              role: formValues[fields.RELAZIONE.id],
                              taxCode: soggettiCorrelatiListDataGiuridiche.listRelatedSubjects[cont].taxCode,
                              email:
                                (soggettiCorrelatiListDataGiuridiche.listRelatedSubjects[cont] || {}).email || null,
                              registeredOfficeAddress:
                                (soggettiCorrelatiListDataGiuridiche.listRelatedSubjects[cont] || {})
                                  .registeredOfficeAddress || null,
                              businessName:
                                (soggettiCorrelatiListDataGiuridiche.listRelatedSubjects[cont] || {}).businessName ||
                                null
                            })
                            this.setState({ cont: -1, soggGiaCorr: !soggGiaCorr })
                            // this.giaCorrelati()
                          }}>
                          Invia richiesta
                        </Button>
                      )}

                      {/* {!inviaRichiestaGiuridicheRequest.fetching &&
                          !soggGiaCorr &&
                          inviaRichiestaGiuridicheRequest.error &&
                          !inviaRichiestaGiuridicheRequest.success && (
                            <Fragment>
                              <Attesa className="m-top20 ">
                                <Red text="Impossibile inviare la richiesta">
                                  <i className="material-icons">error</i>
                                </Red>
                              </Attesa>
                            </Fragment>
                          )}
                        {!inviaRichiestaGiuridicheRequest.fetching &&
                          !soggGiaCorr &&
                          !inviaRichiestaGiuridicheRequest.error &&
                          inviaRichiestaGiuridicheRequest.success && (
                            <Fragment>
                              <Attesa className="m-top20 " text="Richiesta inviata in attesa di conferma">
                                <Orange>
                                  <i className="material-icons">access_time</i>
                                </Orange>
                              </Attesa>
                            </Fragment>
                          )} */}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1rem' }}>
                    <Col s={12}>
                      <button
                        type="button"
                        className="btn waves-effect waves-light btn-flat btn-flat2 btnRichiesta"
                        onClick={() => {
                          this.setState({ cont: -1 })
                          inviaRichiestaGiuridicheRequest.success = false
                          inviaRichiestaGiuridicheRequest.error = false
                        }}>
                        <i className="material-icons">add_circle_outline</i>{' '}
                        <span style={{ verticalAlign: 'super' }}>
                          <b>Aggiungi soggetto da correlare</b>
                        </span>
                      </button>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        )}
      </form>
    )
  }
}

SoggettiCorrelatiGiuridiche.propTypes = {
  getSoggettiGiaCorrelati: PropTypes.func,
  getSoggettiGiaCorrelatiGiuridiche: PropTypes.func,
  customer: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  touch: PropTypes.func,
  change: PropTypes.func,
  url: PropTypes.string,
  idPratica: PropTypes.string,
  inviaRichiestaSoggettiCorrelati: PropTypes.func,
  searchSoggettiCorrelati: PropTypes.func,
  searchSoggettiCorrelatiGiuridiche: PropTypes.func,
  soggettiCorrelatiRequest: PropTypes.object,
  inviaRichiestaGiuridicheRequest: PropTypes.object,
  handleSubmit: PropTypes.func,
  domini: PropTypes.object,
  soggettiCorrelatiListDataGiuridiche: PropTypes.array,
  soggettiGiaCorrelatiListData: PropTypes.array,
  totalpage: PropTypes.any,
  invalid: PropTypes.bool
}
export default SoggettiCorrelatiGiuridiche
