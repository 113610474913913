import * as actionTypes from './constants'
// import { unionBy } from 'lodash'

const requestTmpl = { fetching: false, error: null }

const initialState = {
  additionalOwnerList: [],
  dataFromTaxCode: [],
  listaDocumenti: null,
  isSend: '',
  listaClienti: null,
  namirialUrl: null,
  statusComplessivo: null,
  utilityDocFirmaSignRequest: requestTmpl,
  utilityGetFirmaRequest: requestTmpl,
  checkDownload: false,
  utilitySaveDocumentRequest: {
    fetching: false,
    error: null
  },
  utilityGetNamirialUrlRequest: {
    fetching: false,
    error: null
  },
  utilitySetFirmaRequest: {
    fetching: false,
    error: null
  },
  RichiestaInSedeRequest: {
    fetching: false,
    error: null
  },
  downloadZipRequest: {
    fetching: false,
    error: null
  },
  checkDownloadZipRequest: {
    fetching: false,
    error: null
  },
  getAdditionalOwnerList: {
    fetching: false,
    error: null
  },
  deleteAdditionalOwnerList: {
    fetching: false,
    error: null
  },
  postAdditionalOwnerRequest: {
    fetching: false,
    error: null
  },
  getIdProspectByIdProcessRequest: {
    fetching: false,
    error: null
  },
  getDataFromTaxCodeRequest: {
    fetching: false,
    error: null
  },
  downloadDocsRequest: {
    fetching: false,
    error: null
  },
  richiamaPraticaRequest: {
    fetching: false,
    error: null
  },
  praticaToSignRoomRequest: {
    fetching: false,
    error: null
  },
  titolareMulti: null,
  titolare: null,
  downloadSignModuleRequest: {
    fetching: false,
    error: null
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.RESET_DOWNLOADED: {
      return {
        ...state,
        downloaded: !state.downloaded
      }
    }
    case actionTypes.SET_TITOLARE_MULTI: {
      return {
        ...state,
        titolareMulti: action.titolareMulti
      }
    }

    case actionTypes.SET_TITOLARE: {
      return {
        ...state,
        titolare: action.titolare
      }
    }

    case actionTypes.SET_PEP_MULTI:
      return Object.assign({}, state, { pepMulti: action.pep })

    case actionTypes.SET_PEP:
      return Object.assign({}, state, { pep: action.pep })

    //
    case actionTypes.CHECK_DOWNLOAD_ZIP_START:
      return {
        ...state,
        checkDownloadZipRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.CHECK_DOWNLOAD_ZIP_SUCCESS:
      return {
        ...state,
        checkDownload: action.checkDownload,
        checkDownloadZipRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.CHECK_DOWNLOAD_ZIP_ERROR:
      return {
        ...state,
        checkDownloadZipRequest: {
          fetching: false,
          error: action.error
        }
      }
    //
    case actionTypes.GET_DATA_FROM_TAX_CODE_START:
      return {
        ...state,
        getDataFromTaxCodeRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DATA_FROM_TAX_CODE_SUCCESS:
      // let add = state.additionalOwnerList
      if (state.additionalOwnerList.length > action.counter) {
        state.additionalOwnerList[action.counter]['nationBirthCode'] = action.dataFromTaxCode.codNazioneNascita.chiave
        state.additionalOwnerList[action.counter]['provinceBirthCode'] =
          action.dataFromTaxCode.codProvinciaNascita.chiave
        state.additionalOwnerList[action.counter]['cityBirthCode'] = action.dataFromTaxCode.codComuneNascita.chiave
        state.additionalOwnerList[action.counter]['birthDate'] = action.dataFromTaxCode.dataNascita
        state.additionalOwnerList[action.counter]['taxCode'] = action.cf
      } else {
        state.additionalOwnerList.push({
          nationBirthCode: action.dataFromTaxCode.codNazioneNascita.chiave,
          provinceBirthCode: action.dataFromTaxCode.codProvinciaNascita.chiave,
          cityBirthCode: action.dataFromTaxCode.codComuneNascita.chiave,
          birthDate: action.dataFromTaxCode.dataNascita,
          taxCode: action.cf
        })
      }

      if (state.dataFromTaxCode.length > action.counter) {
        state.dataFromTaxCode[action.counter]['nationBirthCode_' + action.counter] =
          action.dataFromTaxCode.codNazioneNascita.chiave
        state.dataFromTaxCode[action.counter]['provinceBirthCode_' + action.counter] =
          action.dataFromTaxCode.codProvinciaNascita.chiave
        state.dataFromTaxCode[action.counter]['cityBirthCode_' + action.counter] =
          action.dataFromTaxCode.codComuneNascita.chiave
        state.dataFromTaxCode[action.counter]['birthDate_' + action.counter] = action.dataFromTaxCode.dataNascita
        state.dataFromTaxCode[action.counter]['taxCode_' + action.counter] = action.cf
      } else {
        let nationBirthCode = `nationBirthCode_${action.counter}`
        let provinceBirthCode = `provinceBirthCode_${action.counter}`
        let cityBirthCode = `cityBirthCode_${action.counter}`
        let birthDate = `birthDate_${action.counter}`
        let taxCode = `taxCode_${action.counter}`
        state.dataFromTaxCode.push({
          [nationBirthCode]: action.dataFromTaxCode.codNazioneNascita.chiave,
          [provinceBirthCode]: action.dataFromTaxCode.codProvinciaNascita.chiave,
          [cityBirthCode]: action.dataFromTaxCode.codComuneNascita.chiave,
          [birthDate]: action.dataFromTaxCode.dataNascita,
          [taxCode]: action.cf
        })
      }

      return {
        ...state,
        getDataFromTaxCodeRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DATA_FROM_TAX_CODE_ERROR:
      return {
        ...state,
        getDataFromTaxCodeRequest: {
          fetching: false,
          error: action.error
        }
      }
    //

    case actionTypes.GET_ID_PROSPECT_BY_ID_PROCESS_START:
      return {
        ...state,
        getIdProspectByIdProcessRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_ID_PROSPECT_BY_ID_PROCESS_SUCCESS:
      return {
        ...state,
        pDob: action.pDob,
        getIdProspectByIdProcessRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_ID_PROSPECT_BY_ID_PROCESS_ERROR:
      return {
        ...state,
        getIdProspectByIdProcessRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.DOWNLOAD_ZIP_START:
      return {
        ...state,
        downloadZipRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.DOWNLOAD_ZIP_SUCCESS:
      return {
        ...state,
        downloadZipRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.DOWNLOAD_ZIP_ERROR:
      return {
        ...state,
        downloadZipRequest: {
          fetching: false,
          error: action.error
        }
      }

    //
    case actionTypes.GET_ADDITIONAL_OWNER_START:
      return {
        ...state,
        getAdditionalOwnerList: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_ADDITIONAL_OWNER_SUCCESS:
      return {
        ...state,
        additionalOwnerList: action.additionalOwnerList,
        getAdditionalOwnerList: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_ADDITIONAL_OWNER_ERROR:
      return {
        ...state,
        getAdditionalOwnerList: {
          fetching: false,
          error: action.error
        }
      }

    //
    case actionTypes.DELETE_ADDITIONAL_OWNER_START:
      return {
        ...state,
        deleteAdditionalOwnerList: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.DELETE_ADDITIONAL_OWNER_SUCCESS:
      return {
        ...state,
        // additionalOwnerList: action.additionalOwnerList,
        deleteAdditionalOwnerList: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.DELETE_ADDITIONAL_OWNER_ERROR:
      return {
        ...state,
        deleteAdditionalOwnerList: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.POST_ADDITIONAL_OWNER_START:
      return {
        ...state,
        postAdditionalOwnerRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.POST_ADDITIONAL_OWNER_SUCCESS:
      return {
        ...state,
        postAdditionalOwnerRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.POST_ADDITIONAL_OWNER_ERROR:
      return {
        ...state,
        postAdditionalOwnerRequest: {
          fetching: false,
          error: action.error
        }
      }
    //
    case actionTypes.PRATICA_CORRENTE_FIRMA:
      return {
        ...state,
        idIncarico: action.idIncarico
      }
    case actionTypes.UTILITY_GET_FIRMA_START:
      return {
        ...state,
        listaDocumenti: null,
        listaClienti: null,
        utilityGetFirmaRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.UTILITY_GET_FIRMA_SUCCESS:
      return {
        ...state,
        listaDocumenti: action.data.listaDocumenti,
        listaClienti: action.data.listaClienti,
        utilityGetFirmaRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.UTILITY_GET_FIRMA_ERROR:
      return {
        ...state,
        utilityGetFirmaRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.UTILITY_SET_SIGNED_START:
      return {
        ...state,
        utilitySetFirmaRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.UTILITY_SET_SIGNED_SUCCESS:
      return {
        ...state,
        listaClienti: state.listaClienti.map(c => ({
          ...c,
          stato: 'FIRMATO'
        })),
        utilitySetFirmaRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.UTILITY_SET_SIGNED_ERROR:
      return {
        ...state,
        utilitySetFirmaRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.UTILITY_GET_NAMIRIAL_URL_START:
      return {
        ...state,
        namirialUrl: '',
        utilityGetNamirialUrlRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.UTILITY_GET_NAMIRIAL_URL_SUCCESS:
      return {
        ...state,
        namirialUrl: action.data,
        utilityGetNamirialUrlRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.UTILITY_GET_NAMIRIAL_URL_ERROR: {
      return {
        ...state,
        utilityGetNamirialUrlRequest: {
          fetching: false,
          error: action.error
        }
      }
    }
    case actionTypes.UTILITY_SAVE_DOC_START:
      return {
        ...state,
        utilitySaveDocumentRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.UTILITY_SAVE_DOC_SUCCESS:
      return {
        ...state,
        utilitySaveDocumentRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.UTILITY_SAVE_DOC_ERROR: {
      return {
        ...state,
        utilitySaveDocumentRequest: {
          fetching: false,
          error: action.error
        }
      }
    }

    case actionTypes.RICHIESTA_IN_SEDE_START:
      return Object.assign({}, state, {
        RichiestaInSedeRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.RICHIESTA_IN_SEDE_SUCCESS:
      return Object.assign({}, state, {
        isSend: action.isSend,
        RichiestaInSedeRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.RICHIESTA_IN_SEDE_ERROR:
      return Object.assign({}, state, {
        RichiestaInSedeRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.DOWNLOAD_DOCS_START:
      return {
        ...state,
        downloadDocsRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.DOWNLOAD_DOCS_SUCCESS:
      return {
        ...state,
        downloadDocsRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.DOWNLOAD_DOCS_ERROR:
      return {
        ...state,
        downloadDocsRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.RICHIAMA_PRATICA_START:
      return {
        ...state,
        richiamaPraticaRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.RICHIAMA_PRATICA_SUCCESS:
      return {
        ...state,
        listaDocumenti: action.data.listaDocumenti,
        listaClienti: action.data.listaClienti,
        richiamaPraticaRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.RICHIAMA_PRATICA_ERROR:
      return {
        ...state,
        richiamaPraticaRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.PRATICA_TO_SIGN_ROOM_START:
      return {
        ...state,
        praticaToSignRoomRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRATICA_TO_SIGN_ROOM_SUCCESS:
      return {
        ...state,
        listaDocumenti: action.data.listaDocumenti,
        listaClienti: action.data.listaClienti,
        praticaToSignRoomRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRATICA_TO_SIGN_ROOM_ERROR:
      return {
        ...state,
        praticaToSignRoomRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.DOWNLOAD_SIGN_MODULE_START:
      return {
        ...state,
        downloadSignModuleRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.DOWNLOAD_SIGN_MODULE_SUCCESS:
      return {
        ...state,
        downloadSignModuleRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.DOWNLOAD_SIGN_MODULE_ERROR:
      return {
        ...state,
        downloadSignModuleRequest: {
          fetching: false,
          error: action.error
        }
      }
  }
  return state
}
