import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Row, Col, Icon } from 'react-materialize'
import { renderAutocomplete } from '../../../../components/Forms/renderFields'
import InputText from '../../../../components/Inputs/Text'

const renderResidenze = ({
  fields,
  lista: {
    id,
    lista: { STATO, CODICE_FISCALE }
  },
  isDisabled,
  getDefaultValue,
  getDefaultAutocompleteValue,
  getOptions,
  change,
  touch,
  errori = { array: [] }
}) => {
  const isButtonDisabled = () => {
    if (fields.length < 3) {
      if (!fields.getAll().some(e => !e[CODICE_FISCALE.id])) {
        return false
      }
    }
    return true
  }

  return (
    <Fragment>
      {fields.map((residenza, index) => (
        <Row key={`${index}_${fields.length}`}>
          <Col s={6}>
            <Field
              name={`${residenza}${STATO.id}`}
              s={12}
              label={STATO.label}
              threshold={0}
              defaultValue={getDefaultAutocompleteValue(STATO.id, index)}
              options={getOptions(index)}
              handleChange={(field, value) => {
                change(field, value)
                change(field.replace(STATO.id, CODICE_FISCALE.id), '')
              }}
              component={renderAutocomplete}
            />
          </Col>
          <Col s={6}>
            <Field
              name={`${id}[${index}]${CODICE_FISCALE.id}`}
              s={12}
              maxLength={16}
              label={CODICE_FISCALE.label}
              error={errori[index] ? errori[index][CODICE_FISCALE.id] : null}
              disabled={isDisabled(index)}
              value={getDefaultValue(CODICE_FISCALE.id, index)}
              change={change}
              touch={touch}
              component={InputText}
              formatter={value => (value ? value.toUpperCase() : '')}
            />
          </Col>
          {index > 0 && (
            <Col>
              <a className="remove-residenza" onClick={() => fields.remove(index)}>
                <Icon small>remove_circle</Icon>
                <span>Elimina residenza fiscale</span>
              </a>
            </Col>
          )}
        </Row>
      ))}
      <Row className="residenza-buttons">
        <Col>
          <a
            className={`add-residenza${isButtonDisabled() ? ' disabled' : ''}`}
            onClick={() => {
              if (!isButtonDisabled()) fields.push({})
            }}>
            <Icon small>add_circle</Icon>
            <div>Inserisci altra residenza fiscale</div>
          </a>
        </Col>
      </Row>
    </Fragment>
  )
}

renderResidenze.propTypes = {
  fields: PropTypes.object,
  lista: PropTypes.object,
  isDisabled: PropTypes.bool,
  getDefaultValue: PropTypes.func,
  getDefaultAutocompleteValue: PropTypes.func,
  getOptions: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,
  errori: PropTypes.object
}

export default renderResidenze
