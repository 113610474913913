import React, { Component } from 'react'
// import { Field } from 'redux-form'
// import { Row, Col } from 'react-materialize'
import { PropTypes } from 'prop-types'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { RESIDENZA } from '../../../../store/modules/forms/constants'
import AccordionHeader from '../../../../components/AccordionHeader'
import { getValueDominioFromKey, fieldSpreader } from '../../utils'
import * as dataSB from '../../../../static-data/data-servizio-base.json'
import ResidenzaForm from '../../../../components/Forms/Residenza/ResidenzaForm'
// import DomicilioForm from '../../../../components/Forms/Residenza/DomicilioForm'
import ButtonSubmit from '../../../../components/Buttons/Submit'
// import { renderCheckbox } from '../../../../components/Forms/renderFields'

const residenza = dataSB.censimento_anagrafica.residenza

class Residenza extends Component {
  constructor(props) {
    super(props)
    this.state = { saved: false }

    this.submit = this.submit.bind(this)
    this.checkNazione = this.checkNazione.bind(this)
    this.checkProvincia = this.checkProvincia.bind(this)
    this.checkComune = this.checkComune.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(RESIDENZA)
      this.setState({ saved: true })
    }
  }

  checkNazione(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields
    } = this.props
    if (isItalia) {
      return formValues[fields.NAZIONE.id] === fields.NAZIONE.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, fields.NAZIONE.dominio, formValues[fields.NAZIONE.id])
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      fields
    } = this.props
    return !!getValueDominioFromKey(listaDomini, fields.PROVINCIA.dominio, value)
  }

  checkComune(value) {
    const {
      domini: { listaDomini },
      fields
    } = this.props
    return !!getValueDominioFromKey(listaDomini, fields.COMUNE.dominio, value)
  }

  submit(values) {
    const { onSave, fields } = this.props
    const spreadFields = fieldSpreader(fields)
    onSave(
      Object.keys(values).reduce((res, cur) => {
        const { dominio } = spreadFields.find(el => el.id === cur)
        if (dominio && (/^[a-zA-Z0-9]*$/g.test(values[cur]) || values[cur] === '')) {
          return {
            ...res,
            [cur]: { codiceDominio: dominio, chiave: values[cur] }
          }
        }
        return { ...res, [cur]: values[cur] }
      }, {})
    )
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      domini,
      updateDominio,
      dominioRequest = {},
      formValues = {},
      formErrors,
      status,
      change,
      touch,
      fields,
      isFetching
    } = this.props

    // const { HA_DOMICILIO } = fields

    return (
      <CollapsibleItem
        itemKey={RESIDENZA}
        header={residenza.title_accordion}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <ResidenzaForm
            fields={fields}
            change={change}
            touch={touch}
            updateDominio={updateDominio}
            dominioRequest={dominioRequest}
            domini={domini}
            formErrors={formErrors}
            formValues={formValues}
            isDirty={isDirty}
          />

          {/*
          da capire perchè in persona fisica si spacca 
          riferito ai fields, Cannot read property 'id' of undefined
           */}

          {/* <DomicilioForm
            fields={fields}
            change={change}
            touch={touch}
            updateDominio={updateDominio}
            dominioRequest={dominioRequest}
            domini={domini}
            formErrors={formErrors}
            formValues={formValues}
            isDirty={isDirty}
            /> */}

          <ButtonSubmit
            disabled={
              submitting || !isDirty || invalid || isFetching || Object.keys(formErrors).some(e => formErrors[e])
            }
          />
        </form>
      </CollapsibleItem>
    )
  }
}

Residenza.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  fields: PropTypes.object,
  domini: PropTypes.object,
  dominioRequest: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  change: PropTypes.func,
  touch: PropTypes.func,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  updateDominio: PropTypes.func,
  status: PropTypes.any
}

Residenza.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default Residenza
