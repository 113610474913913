import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ListaClientiAntiriciclaggioComponent from './ListaClientiAntiriciclaggioComponent'
import { getListAML, getCustomersAML, setListAMLPageSize } from '../../../store/modules/customers/actions'
import { importSurveyExt } from '../../../store/modules/survey/actions'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getListAML,
      getCustomersAML,
      setListAMLPageSize,
      importSurveyExt
    },
    dispatch
  )

const mapStateToProps = state => {
  return {
    clienti: state.customers.listAML,
    toggleBlur: state.toggleBlur.toggleBlur,
    totClienti: state.customers.totListAML,
    query: state.customers.queryListAML,
    pageSize: state.customers.listAMLPageSize,
    customersAML: state.customers.customersAML,
    getCustomersAMLRequest: state.customers.getCustomersAMLRequest
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListaClientiAntiriciclaggioComponent)
