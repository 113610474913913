import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import {
  MIFID_FINANZIARIA_PF,
  MIFID_FINANZIARIA_PG,
  MIFID_FINANZIARIA,
  MIFID_FINANZIARIA_PD
} from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import AccordionHeader from '../../../../components/AccordionHeader'
import SurveyRadio from '../../../../components/SurveyRadio'
import SurveyCheckbox from '../../../../components/SurveyCheckbox'
import SurveyHr from '../../../../components/SurveyHr'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import YesNo from '../YesNo'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import { setValidators, setRisposte, isSaveDisabled, getFormValues } from '../mifidValidationUtils'

let MIFID_ACTIVE = null
let fields = null

class SituazioneFinanziaria extends React.Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, values: {} }
    this.submit = this.submit.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.FINANZIARIA)
    fields = allFields[2][MIFID_ACTIVE]
    // Imposto i validators in modo da poter fare i controlli (sul padre) tra sezioni diverse
    setValidators(this.props.template.listaDomande)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.state.values)
      this.props.resetForm(MIFID_ACTIVE)
      this.setState({ saved: true, values: {} })
    }
    setRisposte(getFormValues(this.props.currentFormValues))
  }

  submit(values) {
    const { onSave } = this.props
    const submittedValues = Object.keys(values).reduce((res, cur) => {
      return { ...res, [cur.replace('field_', '')]: values[cur] }
    }, {})
    this.setState({ values })
    onSave(
      Object.keys(submittedValues).reduce((res, cur) => {
        if (cur.includes('_')) {
          const [idDomanda, idRisposta] = cur.split('_')
          const curRisp = res.find(r => r.idDomanda === idDomanda)
          let listaRisposte = []
          if (submittedValues[cur]) {
            listaRisposte = [{ idRisposta }]
          }
          if (curRisp) {
            listaRisposte = [...curRisp.listaRisposte, ...listaRisposte]
            return [...res.filter(r => r.idDomanda !== idDomanda), { idDomanda, listaRisposte }]
          }
          return [...res, { idDomanda, listaRisposte }]
        } else {
          return [...res, { idDomanda: cur, listaRisposte: [{ idRisposta: submittedValues[cur] }] }]
        }
      }, [])
    )
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      formValues,
      change,
      status,
      template: { descSezione, listaDomande },
      isFetching
    } = this.props

    return (
      <CollapsibleItem
        header={descSezione}
        itemKey={MIFID_ACTIVE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {/* VECCHIA MIFID FINANZIARIA */}
          {MIFID_ACTIVE === MIFID_FINANZIARIA && (
            <React.Fragment>
              <Row>
                <Col s={6}>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.FONTE_REDDITO.templateId)}
                    onChangeCallback={() => change(`field_${fields.FONTE_REDDITO_ALTRO.templateId}`, '')}
                  />
                </Col>
                <Col s={6}>
                  {formValues[`field_${fields.FONTE_REDDITO.templateId}`] === fields.FONTE_REDDITO.altroId && (
                    <SurveyRadio
                      template={listaDomande.find(d => d.idDomanda === fields.FONTE_REDDITO_ALTRO.templateId)}
                      showSubTesto
                    />
                  )}
                  {formValues[`field_${fields.FONTE_REDDITO.templateId}`] === fields.FONTE_REDDITO.professioneId && (
                    <SurveyRadio
                      template={listaDomande.find(d => d.idDomanda === fields.FONTE_REDDITO_ALTRO.templateId)}
                    />
                  )}
                </Col>
              </Row>
              <SurveyHr hideHr />
              <SurveyRadio
                template={listaDomande.find(d => d.idDomanda === fields.REDDITO_FUTURO.templateId)}
                radioClass="radio-horizontal"
              />
              <SurveyHr />
              <SurveyRadio
                template={listaDomande.find(d => d.idDomanda === fields.PATRIMONIO_FINANZIARIO.templateId)}
              />
              <SurveyHr hideHr />
              <SurveyCheckbox
                template={listaDomande.find(d => d.idDomanda === fields.INVESTIMENTI_IMMOBILIARI.templateId)}
              />
              <SurveyHr hideHr />
              <SurveyRadio
                template={listaDomande.find(d => d.idDomanda === fields.IMPEGNI_FINANZIARI_MENSILI.templateId)}
              />
              <SurveyHr hideHr />
            </React.Fragment>
          )}
          {/* NUOVA MIFID PF */}
          {MIFID_ACTIVE === MIFID_FINANZIARIA_PF && (
            <React.Fragment>
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.FONTE_REDDITO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyCheckbox template={listaDomande.find(d => d.idDomanda === fields.FONTE_REDDITO.templateId)} />
                </Col>
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.FONTE_REDDITO_MEDIO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.FONTE_REDDITO_MEDIO.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.REDDITO_FUTURO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.REDDITO_FUTURO.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.PATRIMONIO_FINANZIARIO.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.PATRIMONIO_FINANZIARIO.templateId)}
                  />
                </Col>
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col s={12}>
                  <h2 className="collapsible-section-title">Si o no?</h2>
                  <Row
                    className={
                      this.props.fieldsWithErrors.includes(fields.INVESTIMENTI_IMMOBILIARI.templateId)
                        ? 'fieldHasErrors'
                        : ''
                    }>
                    <YesNo
                      field={fields.INVESTIMENTI_IMMOBILIARI}
                      listaDomande={listaDomande}
                      formValues={formValues}
                      change={change}
                    />
                  </Row>
                </Col>
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.PATRIMONIO_IMMOBILIARE.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.PATRIMONIO_IMMOBILIARE.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(
                      d => d.idDomanda === fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO.templateId
                    )}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.IMPEGNI_FINANZIARI_MENSILI.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.IMPEGNI_FINANZIARI_MENSILI.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.RISPARMIO_MENSILE.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.RISPARMIO_MENSILE.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.PERSONE_DIPENDENTI.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.PERSONE_DIPENDENTI.templateId)}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
          {/* NUOVA MIFID PG */}
          {MIFID_ACTIVE === MIFID_FINANZIARIA_PG && (
            <Row>
              <Col
                s={12}
                className={this.props.fieldsWithErrors.includes(fields.RICAVI_MEDI.templateId) ? 'fieldHasErrors' : ''}>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.RICAVI_MEDI.templateId)}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.RICAVI_FUTURO.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.RICAVI_FUTURO.templateId)}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.ATTIVO_BILANCIO_MEDIO.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.ATTIVO_BILANCIO_MEDIO.templateId)}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.PATRIMONIO_NETTO.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.PATRIMONIO_NETTO.templateId)}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO.templateId)
                    ? 'fieldHasErrors'
                    : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(
                    d => d.idDomanda === fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO.templateId
                  )}
                />
              </Col>
            </Row>
          )}
          {/* NUOVA MIFID PD */}
          {MIFID_ACTIVE === MIFID_FINANZIARIA_PD && (
            <Row>
              <Col
                s={12}
                className={this.props.fieldsWithErrors.includes(fields.RICAVI_MEDI.templateId) ? 'fieldHasErrors' : ''}>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.RICAVI_MEDI.templateId)}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.RICAVI_FUTURO.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.RICAVI_FUTURO.templateId)}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.ATTIVO_BILANCIO_MEDIO.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.ATTIVO_BILANCIO_MEDIO.templateId)}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.PATRIMONIO_NETTO.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.PATRIMONIO_NETTO.templateId)}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO.templateId)
                    ? 'fieldHasErrors'
                    : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(
                    d => d.idDomanda === fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO.templateId
                  )}
                />
              </Col>
            </Row>
          )}
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching || isSaveDisabled(listaDomande)} />
        </form>
      </CollapsibleItem>
    )
  }
}

SituazioneFinanziaria.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  change: PropTypes.func,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  template: PropTypes.object,
  onSave: PropTypes.func,
  currentFormValues: PropTypes.object,
  fieldsWithErrors: PropTypes.array
}

export default SituazioneFinanziaria
