import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import allFields from '../../fields'
import AccordionHeader from '../../../../components/AccordionHeader'
import SurveyRadio from '../../../../components/SurveyRadio'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import { setValidators, setRisposte, isSaveDisabled, getFormValuesESG as getFormValues } from '../mifidValidationUtils'
import SubRadioCheckbox from '../SubRadioCheckbox'
import * as Forms from '../../../../store/modules/forms/constants'

let MIFID_ACTIVE = null
let fields = null

class InvestimentiESG extends React.Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, values: {}, subRadioCheckboxError: false, subRadioCheckboxError2: false }
    this.submit = this.submit.bind(this)
    this.checkImpattiAmbiente = this.checkImpattiAmbiente.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.INVESTIMENTI_ESG)
    fields = allFields[2][MIFID_ACTIVE]
    // Imposto i validators in modo da poter fare i controlli (sul padre) tra sezioni diverse
    setValidators(this.props.template.listaDomande)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.state.values)
      this.props.resetForm(MIFID_ACTIVE)
      this.setState({ saved: true, values: {} })
    }

    setRisposte(getFormValues(this.props.currentFormValues))
    this.checkImpattiAmbiente()
  }

  submit(values) {
    const { onSave } = this.props
    const submittedValues = Object.keys(values).reduce((res, cur) => {
      return { ...res, [cur.replace('field_', '')]: values[cur] }
    }, {})
    this.setState({ values })
    onSave(
      Object.keys(submittedValues).reduce((res, cur) => {
        if (cur.includes('_')) {
          const [idDomanda, idRisposta] = cur.split('_')
          const curRisp = res.find(r => r.idDomanda === idDomanda)
          let listaRisposte = []
          if (submittedValues[cur]) {
            listaRisposte = [{ idRisposta }]
          }
          if (curRisp) {
            listaRisposte = [...curRisp.listaRisposte, ...listaRisposte]
            return [...res.filter(r => r.idDomanda !== idDomanda), { idDomanda, listaRisposte }]
          }
          return [...res, { idDomanda, listaRisposte }]
        } else {
          return [...res, { idDomanda: cur, listaRisposte: [{ idRisposta: submittedValues[cur] }] }]
        }
      }, [])
    )
  }

  checkImpattiAmbiente(currentValue) {
    const {
      template: { listaDomande },
      currentFormValues
    } = this.props
    let _currentValue =
      currentValue == null ? currentFormValues[`field_${fields.IMPATTI_AMBIENTE.templateId}`] : currentValue
    let template = listaDomande.find(d => d.idDomanda === fields.IMPATTI_AMBIENTE.templateId)
    if (template.flagDipendenza && Array.isArray(template.listaDipendenze)) {
      const res = template.listaDipendenze.some(
        dip =>
          dip.tipoDipendenza === 'disable' && (_currentValue == null || _currentValue === '' + dip.idRispostaDipendenza)
      )
      if (res) {
        this.props.change(`field_${fields.IMPATTI_AMBIENTE.templateId}`, '')
        this.props.change(`field_${fields.IMPATTI_SOCIALI_GOV.templateId}`, '')
        this.props.change(`field_${fields.STRUMENTI_SOCIALI_GOV.templateId}`, '')
        this.props.change(`field_${fields.STRUMENTI_AMBIENTE.templateId}`, '')
      }
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      formValues,
      status,
      template: { descSezione, listaDomande },
      isFetching,
      currentFormValues
    } = this.props

    return (
      <CollapsibleItem
        header={descSezione}
        itemKey={MIFID_ACTIVE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {/* NUOVA MIFID PF/PG/PD */}
          <React.Fragment>
            {(MIFID_ACTIVE === Forms.MIFID_INVESTIMENTI_ESG_PF || MIFID_ACTIVE === Forms.MIFID_INVESTIMENTI_ESG_PG) && (
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.CONOSCENZA_ESPERIENZA.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.CONOSCENZA_ESPERIENZA.templateId)}
                  />
                </Col>
              </Row>
            )}
            {/* <Row> */}
            {/*   <Col */}
            {/*     s={12} */}
            {/*     className={ */}
            {/*       this.props.fieldsWithErrors.includes(fields.QUOTA_PORTAFOGLIO.templateId) ? 'fieldHasErrors' : '' */}
            {/*     }> */}
            {/*     <SurveyRadio */}
            {/*       formValues={formValues} */}
            {/*       template={listaDomande.find(d => d.idDomanda === fields.QUOTA_PORTAFOGLIO.templateId)} */}
            {/*     /> */}
            {/*   </Col> */}
            {/* </Row> */}
            <Row>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.INTERESSE.templateId) ||
                  this.props.fieldsWithErrors.includes(fields.INTERESSE2.templateId)
                    ? 'fieldHasErrors'
                    : ''
                }>
                <SubRadioCheckbox
                  template={listaDomande.find(d => d.idDomanda === fields.INTERESSE.templateId)}
                  formValues={{ ...currentFormValues, ...formValues, ...this.state.values }}
                  changeCallback={this.props.change}
                  validate={hasError => this.setState({ subRadioCheckboxError: hasError })}
                />
                {this.state.subRadioCheckboxError && (
                  <Row className="subRadioCheckboxError">
                    <Col s={12}>
                      <small>Selezionare almeno un fattore.</small>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            <Row>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.QUOTA_PORTAFOGLIO.templateId) ? 'fieldHasErrors' : ''
                }>
                <SubRadioCheckbox
                  isValidAllSelected={false}
                  singleSelection
                  template={listaDomande.find(d => d.idDomanda === fields.QUOTA_PORTAFOGLIO.templateId)}
                  formValues={{ ...currentFormValues, ...formValues, ...this.state.values }}
                  changeCallback={this.props.change}
                  validate={hasError => this.setState({ subRadioCheckboxError2: hasError })}
                  onChangeCallbackRadio={event => {
                    // controllo aggiuntivo dato che dobbiamo aspettare il rendering del campo e non possiamo fare il trigger da SurveyRadio
                    if (event.currentTarget && event.currentTarget.value) {
                      this.checkImpattiAmbiente(event.currentTarget.value)
                    }
                  }}
                />
                {this.state.subRadioCheckboxError2 && (
                  <Row className="subRadioCheckboxError">
                    <Col s={12}>
                      <small>Indicare Misura dell’investimento.</small>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>

            {(MIFID_ACTIVE === Forms.MIFID_INVESTIMENTI_ESG_PF || MIFID_ACTIVE === Forms.MIFID_INVESTIMENTI_ESG_PG) && (
              <Row>
                <Col s={12}>
                  <p className="no-margin-bottom">
                    <b>
                      5.4 - Gli strumenti finanziari che contribuiranno alla parte del Suo portafoglio indicata nella
                      domanda precedente appartengono a una o più delle tre categorie di seguito elencate. Per ciascuna
                      di queste categorie, può indicare quali corrispondono alle Sue preferenze? (è sufficiente che lo
                      strumento finanziario soddisfi una delle seguenti preferenze)
                    </b>
                  </p>
                </Col>
              </Row>
            )}

            {MIFID_ACTIVE === Forms.MIFID_INVESTIMENTI_ESG_PD && (
              <Row>
                <Col s={12}>
                  <p className="no-margin-bottom">
                    <b>
                      4.3 - Gli strumenti finanziari che contribuiranno alla parte del Suo portafoglio indicata nella
                      domanda precedente appartengono a una o più delle tre categorie di seguito elencate. Per ciascuna
                      di queste categorie, può indicare quali corrispondono alle Sue preferenze? (è sufficiente che lo
                      strumento finanziario soddisfi una delle seguenti preferenze)
                    </b>
                  </p>
                </Col>
              </Row>
            )}

            <Row>
              <Col s={12}>
                <p className="no-margin-bottom">
                  <b>
                    a) Strumenti finanziari che tengono conto del loro impatto negativo sui fattori di sostenibilità,
                    ovvero tematiche ambientali, sociali e relative ai diritti umani, di governance
                  </b>
                </p>
              </Col>
            </Row>

            <Row style={{ paddingLeft: '0.75rem' }}>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.IMPATTI_AMBIENTE.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.IMPATTI_AMBIENTE.templateId)}
                  hideNumber
                />
              </Col>
            </Row>

            <Row style={{ paddingLeft: '0.75rem' }}>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.IMPATTI_SOCIALI_GOV.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.IMPATTI_SOCIALI_GOV.templateId)}
                  hideNumber
                />
              </Col>
            </Row>

            <Row>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.STRUMENTI_SOCIALI_GOV.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.STRUMENTI_SOCIALI_GOV.templateId)}
                  hideNumber
                />
              </Col>
            </Row>

            <Row>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.STRUMENTI_AMBIENTE.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.STRUMENTI_AMBIENTE.templateId)}
                  hideNumber
                />
              </Col>
            </Row>
          </React.Fragment>
          <ButtonSubmit
            disabled={
              submitting ||
              !isDirty ||
              invalid ||
              isFetching ||
              isSaveDisabled(listaDomande) ||
              this.state.subRadioCheckboxError ||
              this.state.subRadioCheckboxError2
            }
          />
        </form>
      </CollapsibleItem>
    )
  }
}

InvestimentiESG.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  template: PropTypes.object,
  onSave: PropTypes.func,
  change: PropTypes.func,
  currentFormValues: PropTypes.object,
  fieldsWithErrors: PropTypes.array
}

export default InvestimentiESG
