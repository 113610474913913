import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { CONTATTI, SERVIZI_DIGITALI } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import InputImage from '../../../../components/Inputs/Image'
import { getValueDominioFromKey } from '../../utils'
import dataSB from '../../../../static-data/data-servizio-base.json'
import allFields from '../../fields'
import Title from '../../../../components/Title'

const contattiFields = allFields[1][CONTATTI]
const fields = allFields[3][SERVIZI_DIGITALI]

class SottoscrizioneServiziReview extends Component {
  render() {
    const {
      data,
      domini: { listaDomini }
    } = this.props
    const { servizi } = dataSB
    const { SERVIZIO, COMUNE_FIRMA } = fields
    const { PREFISSO, CELLULARE, EMAIL } = contattiFields

    return (
      <CollapsibleItem
        header="Sottoscrizione Servizi"
        icon="keyboard_arrow_down"
        itemKey={SERVIZI_DIGITALI}
        labelInfo={this.props.accordionHeader}>
        <h2 className="collapsible-section-title">{servizi.servizi_investimento.title_subsection}</h2>
        <Row>
          <Col s={12}>
            <Title>{servizi.servizi_investimento.radio_tipologia_servizio.title}</Title>
          </Col>
          <Col s={12}>
            {data[SERVIZIO.id] && (
              <ReadOnlyInput value={servizi.servizi_investimento.radio_tipologia_servizio.options[0].label} />
            )}
          </Col>
        </Row>
        <h2 className="collapsible-section-title">{servizi.servizi_digitali.title_subsection}</h2>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={CELLULARE.label}
              value={`${getValueDominioFromKey(listaDomini, PREFISSO.dominio, data[PREFISSO.id])} ${
                data[CELLULARE.id]
              }`}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput label={EMAIL.label} value={data[EMAIL.id]} />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={COMUNE_FIRMA.label}
              value={getValueDominioFromKey(listaDomini, COMUNE_FIRMA.dominio, (data[COMUNE_FIRMA.id] || {}).chiave)}
            />
          </Col>
        </Row>
        <Row>
          <Col s={6} offset="s3">
            <InputImage
              readOnly
              label="Modulo di firma*"
              fileIn={data[fields.MODULO.id] || data[fields.MODULO_THUMB.id]}
            />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}

SottoscrizioneServiziReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object
}

export default SottoscrizioneServiziReview
