import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { Field } from 'redux-form' // FieldArray
import { FATCA_GIURIDICHE } from '../../../../store/modules/forms/constants'
import AccordionHeader from '../../../../components/AccordionHeader'
import renderResidenze from '../Fatca/renderResidenze'
import * as dataSB from '../../../../static-data/data-servizio-base.json'
import Title from '../../../../components/Title'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import InputText from '../../../../components/Inputs/Text'
import {
  renderAutocomplete,
  renderDatePicker,
  renderYesNoQuestion,
  renderHtmlInput
} from '../../../../components/Forms/renderFields'
import {
  getAutocompleteDefaultOption,
  getDominio,
  getKeyDominioFromValue,
  getValueDominioFromKey,
  mapDominiToAutocomplete
} from '../../utils'
import { checkInvalidBirthdate } from '../../../../utils'

const fatcaCrsGiuridiche = dataSB.censimento_anagrafica.fatca_crs_giuridiche
class FatcaGiuridiche extends Component {
  constructor(props) {
    super(props)
    this.state = { saved: false, waitingDomini: true }

    this.submit = this.submit.bind(this)
    this.checkNazione1 = this.checkNazione1.bind(this)
    this.checkNazione2 = this.checkNazione2.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
    this.checkIstituzioneFinanziaria = this.checkIstituzioneFinanziaria.bind(this)
    this.checkEntitaAttiva = this.checkEntitaAttiva.bind(this)
    this.checkEntitaPassiva = this.checkEntitaPassiva.bind(this)
    this.checkControllingPerson = this.checkControllingPerson.bind(this)
    this.checkControllingPerson1 = this.checkControllingPerson1.bind(this)
    this.checkControllingPerson2 = this.checkControllingPerson2.bind(this)
    this.checkCRSIstituzioneFinanziaria = this.checkCRSIstituzioneFinanziaria.bind(this)
    this.checkCRSNotIstituzioneFinanziaria = this.checkCRSNotIstituzioneFinanziaria.bind(this)
    this.checkCRSEntita = this.checkCRSEntita.bind(this)
    this.checkCRSEntitaAttiva = this.checkCRSEntitaAttiva.bind(this)
    this.checkCRSEntitaPassiva = this.checkCRSEntitaPassiva.bind(this)
    this.emptyGroup = this.emptyGroup.bind(this)
    this.emptyCP1 = this.emptyCP1.bind(this)
    this.emptyCP2 = this.emptyCP2.bind(this)
    this.emptyIstituta = this.emptyIstituta.bind(this)
    this.emptyCRSFinanziaria = this.emptyCRSFinanziaria.bind(this)
    this.emptyCRSEntity = this.emptyCRSEntity.bind(this)
    this.emptyEntity = this.emptyEntity.bind(this)
    this.emptyCRSEntita = this.emptyCRSEntita.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && !this.props.apiError) {
      // this.props.initialize(this.props.formValues)
      // this.props.resetForm(FATCA_GIURIDICHE)
      this.setState({ saved: true })
    }
    // this.emptyGroup()

    if (this.state.waitingDomini && this.props.dataFatcaPG) {
      const {
        fields: { CP1_PROVINCIA, CP1_COMUNE, CP2_PROVINCIA, CP2_COMUNE },
        dataFatcaPG,
        retrieveDominio
      } = this.props

      let _listaDomini = []
      if (this.checkProvincia1(dataFatcaPG[CP1_PROVINCIA.id])) {
        _listaDomini.push({
          idDominio: CP1_COMUNE.dominio,
          filtro: {
            codice: dataFatcaPG[CP1_PROVINCIA.id]
          }
        })
      }
      if (this.checkProvincia2(dataFatcaPG[CP2_PROVINCIA.id])) {
        _listaDomini.push({
          idDominio: CP2_COMUNE.dominio,
          filtro: {
            codice: dataFatcaPG[CP2_PROVINCIA.id]
          }
        })
      }
      retrieveDominio({
        listaDomini: _listaDomini
      })
      this.setState({ waitingDomini: false })
    }
  }

  checkComune1(value) {
    const {
      domini: { listaDomini },
      fields: { CP1_COMUNE }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, CP1_COMUNE.dominio, value)
  }

  checkComune2(value) {
    const {
      domini: { listaDomini },
      fields: { CP2_COMUNE }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, CP2_COMUNE.dominio, value)
  }

  dominioCustomRequest(codDominio, codDominioFilter, value, isAlreadyKey) {
    const { domini, retrieveDominio } = this.props
    retrieveDominio({
      listaDomini: [
        {
          idDominio: codDominio,
          filtro: {
            codice: isAlreadyKey ? value : getKeyDominioFromValue(domini.listaDomini, codDominioFilter, value)
          }
        }
      ]
    })
  }

  componentDidMount() {
    const { CP1_NAZIONE, CP2_NAZIONE } = this.props.fields
    const payload = {
      listaDomini: [
        {
          idDominio1: CP1_NAZIONE.dominio,
          idDominio2: CP2_NAZIONE.dominio
        }
      ]
    }
    this.dominioCustomRequest(payload)
    // this.props.initialize(this.props.formValues)
  }

  checkNazione1(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { CP1_NAZIONE }
    } = this.props
    if (isItalia) {
      return formValues[CP1_NAZIONE.id] === CP1_NAZIONE.italiaId
    }
    let boolean = !!getValueDominioFromKey(listaDomini, CP1_NAZIONE.dominio, formValues[CP1_NAZIONE.id])
    return boolean
  }

  checkNazione2(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { CP2_NAZIONE }
    } = this.props
    if (isItalia) {
      return formValues[CP2_NAZIONE.id] === CP2_NAZIONE.italiaId
    }
    let boolean = !!getValueDominioFromKey(listaDomini, CP2_NAZIONE.dominio, formValues[CP2_NAZIONE.id])
    return boolean
  }

  checkIstituzioneFinanziaria() {
    const {
      formValues,
      fields: { ISTITUZIONE_FINANZIARIA_NON_US }
    } = this.props
    let tmp = formValues[ISTITUZIONE_FINANZIARIA_NON_US.id] === ISTITUZIONE_FINANZIARIA_NON_US.itIs
    return tmp
  }
  checkNotIstituzioneFinanziaria() {
    const {
      formValues,
      fields: { ISTITUZIONE_FINANZIARIA_NON_US }
    } = this.props
    let tmp = formValues[ISTITUZIONE_FINANZIARIA_NON_US.id] === ISTITUZIONE_FINANZIARIA_NON_US.itIsNot
    return tmp
  }

  checkCRSIstituzioneFinanziaria() {
    const {
      formValues,
      fields: { CRS_ISTITUZIONE_FINANZIARIA }
    } = this.props
    let tmp = formValues[CRS_ISTITUZIONE_FINANZIARIA.id] === CRS_ISTITUZIONE_FINANZIARIA.itIs

    return tmp
  }
  checkCRSNotIstituzioneFinanziaria() {
    const {
      formValues,
      fields: { CRS_ISTITUZIONE_FINANZIARIA }
    } = this.props
    let tmp = formValues[CRS_ISTITUZIONE_FINANZIARIA.id] === CRS_ISTITUZIONE_FINANZIARIA.itIsNot

    return tmp
  }
  emptyCRSFinanziaria() {
    /*  */
    const {
      formValues,
      fields: {
        CRS_ISTITUZIONE_FINANZIARIA,
        CRS_ACTIVE,
        CRS_ACTIVE_TYPE,
        CRS_PASSIVE_TYPE,
        CRS_ENTITA,
        CRS_ENTITA_PERSONE,

        CP1_GROUP,
        CP2_GROUP
      },
      change
    } = this.props

    change(CP1_GROUP.id, false)
    change(CP2_GROUP.id, false)

    if (formValues[CRS_ISTITUZIONE_FINANZIARIA.id] !== 'IST_FIN') {
      change(CRS_ENTITA.id, '')
      change(CRS_ENTITA_PERSONE.id, null)
      change(CRS_PASSIVE_TYPE.id, null)
      change(CRS_ENTITA_PERSONE.id, null)
    } else if (formValues[CRS_ISTITUZIONE_FINANZIARIA.id] !== 'ENT_NO_FIN') {
      change(CRS_ACTIVE.id, '')
      change(CRS_ACTIVE_TYPE.id, '')
      change(CRS_PASSIVE_TYPE.id, null)
      change(CRS_ENTITA_PERSONE.id, null)
    }
  }

  checkEntitaAttiva() {
    const {
      formValues,
      fields: { ACTIVE_NFFE }
    } = this.props
    let tmp = formValues[ACTIVE_NFFE.id] === ACTIVE_NFFE.itIs
    return tmp
  }
  checkEntitaPassiva() {
    const {
      formValues,
      fields: { ACTIVE_NFFE }
    } = this.props
    let tmp = formValues[ACTIVE_NFFE.id] === ACTIVE_NFFE.itIsNot

    return tmp
  }

  checkControllingPerson() {
    const {
      formValues,
      fields: { PASSIVE_NFFE_1 }
    } = this.props
    let tmp = formValues[PASSIVE_NFFE_1.id] === PASSIVE_NFFE_1.itIs
    return tmp
  }

  checkCRSControllingPerson() {
    const {
      formValues,
      fields: { CRS_ENTITA_PERSONE }
    } = this.props
    let tmp = formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS'
    return tmp
  }
  /*  */
  emptyIstituta() {
    const {
      formValues,
      fields: {
        ISTITUZIONE_FINANZIARIA_NON_US,
        REPORTING_FI,
        CA_DESCRIZIONE1,
        ACTIVE_NFFE,
        ACTIVE_NFFE_1,
        SPONSORED,
        PASSIVE_NFFE_1
      },
      change
    } = this.props
    if (formValues[ISTITUZIONE_FINANZIARIA_NON_US.id] === ISTITUZIONE_FINANZIARIA_NON_US.itIs) {
      change(ACTIVE_NFFE.id, '')
      change(ACTIVE_NFFE_1.id, '')
      change(PASSIVE_NFFE_1.id, '')
      change(SPONSORED.id, '')

      // // formValues[CP1_GROUP.id] = ''
      // change(CP1_GROUP.id, false)
      // change(CP2_GROUP.id, false)
    } else if (formValues[ISTITUZIONE_FINANZIARIA_NON_US.id] === ISTITUZIONE_FINANZIARIA_NON_US.itIsNot) {
      change(REPORTING_FI.id, '')
      change(CA_DESCRIZIONE1.id, '')
      change(ACTIVE_NFFE.id, '')
      change(ACTIVE_NFFE_1.id, '')
      change(PASSIVE_NFFE_1.id, '')
      change(SPONSORED.id, '')

      // change(CP1_GROUP.id, false)
      // change(CP2_GROUP.id, false)
    }
  }

  emptyGroup(value, field) {
    //
    const {
      formValues,
      fields: { PASSIVE_NFFE_1, CRS_ENTITA_PERSONE, CRS_PASSIVE_TYPE, CP1_GROUP, CP2_GROUP },
      change
    } = this.props
    let condition = true

    if (field === CRS_PASSIVE_TYPE.id) {
      condition = !(
        formValues[PASSIVE_NFFE_1.id] === PASSIVE_NFFE_1.itIs ||
        formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS' ||
        value === 'PIU_PERS'
      )
    } else if (field === PASSIVE_NFFE_1.id) {
      condition = !(
        value === PASSIVE_NFFE_1.itIs ||
        formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS' ||
        formValues[CRS_PASSIVE_TYPE.id] === 'PIU_PERS'
      )
    } else if (field === CRS_PASSIVE_TYPE.id) {
      condition = !(
        formValues[PASSIVE_NFFE_1.id] === PASSIVE_NFFE_1.itIs ||
        formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS' ||
        value === 'PIU_PERS'
      )
    } else {
      condition = !(
        formValues[PASSIVE_NFFE_1.id] === PASSIVE_NFFE_1.itIs ||
        formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS' ||
        formValues[CRS_PASSIVE_TYPE.id] === 'PIU_PERS'
      )
    }
    if (condition) {
      // formValues[CP1_GROUP.id] = ''
      change(CP1_GROUP.id, false)
      change(CP2_GROUP.id, false)
      // this.checkControllingPerson2(false)
    }
  }

  emptyCP1(value) {
    const {
      fields: {
        CP1_TYPE_1,
        CP1_GROUP,
        CP1_NAME,
        CP1_SURNAME,
        CP1_DATA_NASCITA,
        CP1_STATO_RESIDENZA_FISCALE_1,
        CP1_STATO_RESIDENZA_FISCALE_2,
        CP1_STATO_RESIDENZA_FISCALE_3,
        CP1_RESIDENZA_FISCALE_USA,
        CP1_NAZIONE,
        CP1_PROVINCIA,
        CP1_COMUNE,
        CP1_COMUNE_ESTERO,
        CP1_CAP,
        CP1_CITTADINANZA,
        CP1_NAZIONE_RESIDENZA,
        CP1_RF1_TIN,
        CP1_RF1_NAZIONE,
        CP1_CF_RESIDENZA_FISCALE_1,
        CP1_RF2_NAZIONE,
        CP1_CF_RESIDENZA_FISCALE_2,
        CP1_RF3_NAZIONE,
        CP1_CF_RESIDENZA_FISCALE_3
      },
      change
    } = this.props

    // formValues[CP1_GROUP.id] = ''
    change(CP1_GROUP.id, false)
    change(CP1_TYPE_1.id, '')
    change(CP1_SURNAME.id, '')
    change(CP1_NAME.id, '')
    change(CP1_DATA_NASCITA.id, '')
    change(CP1_STATO_RESIDENZA_FISCALE_1.id, '')
    change(CP1_STATO_RESIDENZA_FISCALE_2.id, '')
    change(CP1_STATO_RESIDENZA_FISCALE_3.id, '')
    change(CP1_RESIDENZA_FISCALE_USA.id, null)
    change(CP1_NAZIONE.id, '')
    change(CP1_PROVINCIA.id, '')
    change(CP1_COMUNE.id, '')
    change(CP1_COMUNE_ESTERO.id, '')
    change(CP1_CAP.id, '')
    change(CP1_CITTADINANZA.id, '')
    change(CP1_NAZIONE_RESIDENZA.id, '')
    change(CP1_RF1_TIN.id, '')
    change(CP1_RF1_NAZIONE.id, '')
    change(CP1_CF_RESIDENZA_FISCALE_1.id, '')
    change(CP1_RF2_NAZIONE.id, '')
    change(CP1_CF_RESIDENZA_FISCALE_2.id, '')
    change(CP1_RF3_NAZIONE.id, '')
    change(CP1_CF_RESIDENZA_FISCALE_3.id, '')

    // CP1_CITTADINANZA
    // CP1_NAZIONE_RESIDENZA
    // CP1_RF1_TIN
    // CP1_RF1_NAZIONE
    // CP1_CF_RESIDENZA_FISCALE_1
    // CP1_RF2_NAZIONE
    // CP1_CF_RESIDENZA_FISCALE_2
    // CP1_RF3_NAZIONE
    // CP1_CF_RESIDENZA_FISCALE_3
    // this.checkControllingPerson1(false)
  }
  emptyCP2() {
    const {
      fields: {
        CP2_TYPE_1,
        CP2_GROUP,
        CP2_NAME,
        CP2_SURNAME,
        CP2_DATA_NASCITA,
        CP2_STATO_RESIDENZA_FISCALE_1,
        CP2_STATO_RESIDENZA_FISCALE_2,
        CP2_STATO_RESIDENZA_FISCALE_3,
        CP2_RESIDENZA_FISCALE_USA,
        CP2_NAZIONE,
        CP2_PROVINCIA,
        CP2_COMUNE,
        CP2_COMUNE_ESTERO,
        CP2_CAP,
        CP2_CITTADINANZA,
        CP2_NAZIONE_RESIDENZA,
        CP2_RF1_TIN,
        CP2_RF1_NAZIONE,
        CP2_CF_RESIDENZA_FISCALE_1,
        CP2_RF2_NAZIONE,
        CP2_CF_RESIDENZA_FISCALE_2,
        CP2_RF3_NAZIONE,
        CP2_CF_RESIDENZA_FISCALE_3
      },
      change
    } = this.props
    change(CP2_TYPE_1.id, '')
    change(CP2_GROUP.id, false)
    change(CP2_NAME.id, '')
    change(CP2_SURNAME.id, '')
    change(CP2_DATA_NASCITA.id, '')
    change(CP2_STATO_RESIDENZA_FISCALE_1.id, '')
    change(CP2_STATO_RESIDENZA_FISCALE_2.id, '')
    change(CP2_STATO_RESIDENZA_FISCALE_3.id, '')
    change(CP2_RESIDENZA_FISCALE_USA.id, null)
    change(CP2_NAZIONE.id, '')
    change(CP2_PROVINCIA.id, '')
    change(CP2_COMUNE.id, '')
    change(CP2_COMUNE_ESTERO.id, '')
    change(CP2_CAP.id, '')
    // this.checkControllingPerson2(false)

    change(CP2_CITTADINANZA.id, '')
    change(CP2_NAZIONE_RESIDENZA.id, '')
    change(CP2_RF1_TIN.id, '')
    change(CP2_RF1_NAZIONE.id, '')
    change(CP2_CF_RESIDENZA_FISCALE_1.id, '')
    change(CP2_RF2_NAZIONE.id, '')
    change(CP2_CF_RESIDENZA_FISCALE_2.id, '')
    change(CP2_RF3_NAZIONE.id, '')
    change(CP2_CF_RESIDENZA_FISCALE_3.id, '')
  }

  checkCRSNonFinControllingPerson() {
    const {
      formValues,
      fields: { CRS_PASSIVE_TYPE }
    } = this.props

    let tmp = formValues[CRS_PASSIVE_TYPE.id] === 'PIU_PERS'
    return tmp
  }

  checkControllingPerson1(value) {
    if (value !== null && value !== undefined) {
      return value
    }
    const {
      formValues,
      fields: { CP1_GROUP }
    } = this.props

    let tmp = String(formValues[CP1_GROUP.id]) !== String(CP1_GROUP.defaultValue)
    /* let tmp = (
      formValues[PASSIVE_NFFE_1.id] === PASSIVE_NFFE_1.itIs ||
      formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS' ||
      formValues[CRS_PASSIVE_TYPE.id] === 'PIU_PERS'
    ) */

    return tmp
  }

  checkControllingPerson2(value) {
    if (value !== null && value !== undefined) {
      return value
    }

    const {
      formValues,
      fields: { CP2_GROUP }
    } = this.props
    let tmp = String(formValues[CP2_GROUP.id]) !== String(CP2_GROUP.defaultValue)

    return tmp
  }

  checkProvincia1(value) {
    const {
      domini: { listaDomini },
      fields: { CP1_PROVINCIA }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, CP1_PROVINCIA.dominio, value)
  }

  checkProvincia2(value) {
    const {
      domini: { listaDomini },
      fields: { CP2_PROVINCIA }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, CP2_PROVINCIA.dominio, value)
  }

  checkCRSEntita(value) {
    const {
      formValues,
      fields: { CRS_ENTITA, CRS_ENTITA_PERSONE },
      change
    } = this.props
    let tmp = formValues[CRS_ENTITA.id] === CRS_ENTITA.itIs
    if (tmp !== true) {
      change(CRS_ENTITA_PERSONE.id, null)
    }

    return tmp
  }

  checkCRSEntitaAttiva() {
    const {
      formValues,
      fields: { CRS_ACTIVE, CRS_ACTIVE_TYPE },
      change
    } = this.props
    let tmp = formValues[CRS_ACTIVE.id] === CRS_ACTIVE.itIs
    if (tmp !== true) {
      change(CRS_ACTIVE_TYPE.id, '')
    }
    return tmp
  }
  checkCRSEntitaPassiva() {
    const {
      formValues,
      fields: { CRS_ACTIVE, CRS_PASSIVE_TYPE },
      change
    } = this.props
    let tmp = formValues[CRS_ACTIVE.id] === CRS_ACTIVE.itIsNot
    if (tmp !== true) {
      change(CRS_PASSIVE_TYPE.id, null)
    }
    return tmp
  }

  emptyCRSEntity(value) {
    const {
      fields: { CRS_PASSIVE_TYPE, CRS_ACTIVE_TYPE },
      change
    } = this.props
    if (value === CRS_ACTIVE_TYPE.itIs) {
      //   console.warn(
      //     !(formValues[PASSIVE_NFFE_1.id] === PASSIVE_NFFE_1.itIs || formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS')
      //   )
      change(CRS_PASSIVE_TYPE.id, '')
      // formValues[CP1_GROUP.id] = ''
      // formValues[CP2_GROUP.id] = ''
      //   if (
      //     !(formValues[PASSIVE_NFFE_1.id] === PASSIVE_NFFE_1.itIs || formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS')
      //   ) {
      //     console.warn('?')
      //     formValues[CP1_GROUP.id] = ''
      //     formValues[CP2_GROUP.id] = ''
    } else {
      change(CRS_ACTIVE_TYPE.id, '')
    }

    // this.emptyGroup(null, null)
  }

  emptyCRSEntita() {
    const {
      formValues,
      fields: { CRS_ENTITA, CRS_ENTITA_PERSONE },
      change
    } = this.props
    let tmp = formValues[CRS_ENTITA.id] === CRS_ENTITA.itIs
    if (tmp !== true) {
      change(CRS_ENTITA_PERSONE.id, null)
    }
    return tmp
  }

  emptyEntity(value) {
    const {
      fields: { ACTIVE_NFFE_1, PASSIVE_NFFE_1, ACTIVE_NFFE },
      change
    } = this.props
    if (value === ACTIVE_NFFE.itIs) {
      // formValues[PASSIVE_NFFE_1.id] = ''
      change(PASSIVE_NFFE_1.id, '')
      // disattiva controlling person
      // this.emptyGroup('SENZA_PERS', PASSIVE_NFFE_1.id)
    } else {
      // formValues[ACTIVE_NFFE_1.id] = ''
      change(ACTIVE_NFFE_1.id, '')
    }

    // this.emptyEntity('ENT_ATT')
  }

  disableCP() {
    const {
      change,
      fields: { CP1_GROUP, CP2_GROUP }
    } = this.props
    change(CP1_GROUP.id, false)
    change(CP2_GROUP.id, false)
  }

  submit() {
    let values = this.props.formValues
    let obj
    Object.keys(values).reduce((res, cur) => {
      if (values[cur] !== '' && values[cur] !== undefined && values[cur] !== null && values[cur] !== 'null')
        obj = { ...res, [cur]: values[cur] }
      return obj
    }, {})

    obj = {
      ...obj,
      idCliente: this.props.dataFatcaPG.idCliente
    }
    this.props.initialize(this.props.formValues)
    this.props.onSave(obj).then(function() {
      // _this.props.resetForm(FATCA_GIURIDICHE)
    })
  }

  updateDominio(idDominio, codice) {
    this.props.updateDominio(idDominio, codice)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      domini,
      formValues,
      formErrors,
      fields,
      change,
      touch,
      status,
      isFetching
    } = this.props
    const {
      ISTITUZIONE_FINANZIARIA_NON_US,
      REPORTING_FI,
      ACTIVE_NFFE,
      ACTIVE_NFFE_1,
      PASSIVE_NFFE_1,
      CP1_NAME,
      CP1_SURNAME,
      CP1_DATA_NASCITA,
      CP1_STATO_RESIDENZA_FISCALE_1,
      CP1_STATO_RESIDENZA_FISCALE_2,
      CP1_STATO_RESIDENZA_FISCALE_3,
      CP1_TYPE_1,
      CP2_NAME,
      CP2_SURNAME,
      CP2_DATA_NASCITA,
      CP2_RESIDENZA_FISCALE_USA,
      CP2_STATO_RESIDENZA_FISCALE_1,
      // CP2_STATO_RESIDENZA_FISCALE_2,
      // CP2_STATO_RESIDENZA_FISCALE_3,
      CP2_TYPE_1,
      CP1_RESIDENZA_FISCALE_USA,
      CA_DESCRIZIONE1,
      SPONSORED,
      CP1_NAZIONE,
      CP2_NAZIONE,
      CP1_NAZIONE_RESIDENZA,
      CP2_NAZIONE_RESIDENZA,
      CP1_PROVINCIA,
      CP2_PROVINCIA,
      CP1_COMUNE,
      CP2_COMUNE,
      CP1_CITTADINANZA,
      CP2_CITTADINANZA,
      CP1_COMUNE_ESTERO,
      CP2_COMUNE_ESTERO,
      CP1_CAP,
      CP2_CAP,
      CP1_GROUP,
      CP2_GROUP,
      CRS_ISTITUZIONE_FINANZIARIA,
      CRS_ENTITA,
      CRS_ENTITA_PERSONE,
      CRS_ACTIVE,
      CRS_ACTIVE_TYPE,
      CRS_PASSIVE_TYPE,
      CRS_STATO_RESIDENZA_FISCALE_1,
      CRS_STATO_RESIDENZA_FISCALE_2,
      CRS_STATO_RESIDENZA_FISCALE_3,
      CRS_RESFISCALEDET1_NAZIONE,
      CRS_RESFISCALEDET2_NAZIONE,
      CRS_RESFISCALEDET3_NAZIONE,
      CRS_RESFISCDET1_CF,
      CRS_RESFISCDET2_CF,
      CRS_RESFISCDET3_CF,
      CP1_CF_RESIDENZA_FISCALE_1,
      CP1_CF_RESIDENZA_FISCALE_2,
      CP1_CF_RESIDENZA_FISCALE_3,
      CP2_CF_RESIDENZA_FISCALE_1,
      CP2_CF_RESIDENZA_FISCALE_2,
      CP2_CF_RESIDENZA_FISCALE_3,
      CP1_RF1_NAZIONE,
      CP1_RF2_NAZIONE,
      CP1_RF3_NAZIONE,
      CP2_RF1_NAZIONE,
      CP2_RF2_NAZIONE,
      CP2_RF3_NAZIONE,
      CP1_RF1_TIN,
      CP2_RF1_TIN,
      CRS_ACTIVE_SOCIETA_QUOTATA,
      CRS_ACTIVE_SOCIETA_COLLEGATA,
      CRS_ACTIVE_SOCIETA_ALTRO
    } = fields

    return (
      <CollapsibleItem
        itemKey={FATCA_GIURIDICHE}
        header={fatcaCrsGiuridiche.title_accordion}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <Row>
          <Col s={12}>
            <h6 className="title-color">{fatcaCrsGiuridiche.main_title}</h6>
          </Col>
        </Row>
        <Row>
          <Col s={12}>
            <Title>{fatcaCrsGiuridiche.label_dichiarazione_fatca}</Title>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <Row className="residenza-first-row">
            <Col s={12} className="radioInput">
              <Field
                name={ISTITUZIONE_FINANZIARIA_NON_US.id}
                type="radio"
                className="with-gap"
                id="1"
                value="true"
                change={change}
                required
                component={renderHtmlInput}
                onChange={() => {
                  this.emptyIstituta()
                  change(CRS_ISTITUZIONE_FINANZIARIA.id, 'IST_FIN')
                  this.disableCP()
                }}
              />
              <label className="radio-vertical" htmlFor="1">
                di essere una <b>ISTITUZIONE FINANZIARIA NON RESIDENTE NEGLI US</b> <br />
                Indicare la categoria di appartenenza sulla base dello Status FATCA acquisto e fornire il GIIN se
                previsto (possibile una sola scelta){formValues[ISTITUZIONE_FINANZIARIA_NON_US.id] === 'true' && '*'}
              </label>
            </Col>
          </Row>
          {this.checkIstituzioneFinanziaria() && (
            <Fragment>
              <Row>
                <Col s={5} offset="s1" className="radioInput">
                  <Field
                    name={REPORTING_FI.id}
                    type="radio"
                    className="with-gap"
                    id="3"
                    change={change}
                    value="FI_FFI"
                    required
                    component={renderHtmlInput}
                    onChange={() => change(CA_DESCRIZIONE1.id, '')}
                  />
                  <label className="radio-vertical" htmlFor="3">
                    Reporting FI/Participating FFI
                  </label>
                </Col>
                <Col s={6} className={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione1 ? 'hide' : ''}>
                  <Field
                    name={CA_DESCRIZIONE1.id}
                    s={12}
                    maxLength={50}
                    label="GIIN*"
                    value={formValues[CA_DESCRIZIONE1.id]}
                    change={change}
                    touch={touch}
                    // disabled={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione1}
                    className={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione1 ? 'hide' : ''}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={5} offset="s1" className="radioInput">
                  <Field
                    name={REPORTING_FI.id}
                    type="radio"
                    className="with-gap"
                    id="4"
                    value="FFI"
                    change={change}
                    required
                    component={renderHtmlInput}
                    onChange={() => change(CA_DESCRIZIONE1.id, '')}
                  />
                  <label className="radio-vertical" htmlFor="4">
                    Registered Deemed Compliant FFI
                  </label>
                </Col>
                <Col s={6} className={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione2 ? 'hide' : ''}>
                  <Field
                    name={CA_DESCRIZIONE1.id}
                    s={12}
                    maxLength={50}
                    label="GIIN*"
                    value={formValues[CA_DESCRIZIONE1.id]}
                    change={change}
                    touch={touch}
                    component={InputText}
                    // disabled={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione2}
                    className={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione2 ? 'hide' : ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={5} offset="s1" className="radioInput">
                  <Field
                    name={REPORTING_FI.id}
                    type="radio"
                    className="with-gap"
                    id="5"
                    value="FFI_BO"
                    change={change}
                    required
                    component={renderHtmlInput}
                    onChange={() => change(CA_DESCRIZIONE1.id, '')}
                  />
                  <label className="radio-vertical" htmlFor="5">
                    Certified Deemed Compliant FFI/Exempt BO
                  </label>
                </Col>
                <Col s={6} className={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione3 ? 'hide' : ''}>
                  <Field
                    name={CA_DESCRIZIONE1.id}
                    s={12}
                    maxLength={50}
                    label="Tipologia*"
                    value={formValues[CA_DESCRIZIONE1.id]}
                    change={change}
                    touch={touch}
                    component={InputText}
                    // disabled={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione3}
                    className={'' + formValues[REPORTING_FI.id] !== REPORTING_FI.opzione3 ? 'hide' : ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={5} offset="s1" className="radioInput">
                  <Field
                    name={REPORTING_FI.id}
                    type="radio"
                    className="with-gap"
                    id="6"
                    value="NON_FFI"
                    change={change}
                    required
                    component={renderHtmlInput}
                    onChange={() => change(CA_DESCRIZIONE1.id, '')}
                  />
                  <label className="radio-vertical" htmlFor="6">
                    Non Participating FFI
                  </label>
                </Col>
              </Row>
            </Fragment>
          )}

          <Row>
            <Col s={12} className="radioInput">
              {/* cleaner2 */}
              <Field
                name={ISTITUZIONE_FINANZIARIA_NON_US.id}
                type="radio"
                className="with-gap"
                id="2"
                value="false"
                change={change}
                required
                onChange={() => {
                  this.emptyIstituta()

                  // change(CRS_ISTITUZIONE_FINANZIARIA.id, 'IST_FIN')
                  change(CRS_ISTITUZIONE_FINANZIARIA.id, 'ENT_NO_FIN')
                  change(REPORTING_FI.id, '')
                  change(CA_DESCRIZIONE1.id, '')
                  this.disableCP()
                }}
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="2">
                di essere una <b>ISTITUZIONE NON FINANZIARIA NON RESIDENTE NEGLI US</b> <br />
                Indicare di seguito la categoria di appartenenza sulla base dell'operatività svolta scegliendo
                alternativamente tra Entità Attiva ovvero Entità Passiva del seguente tipo (barrare una casella)
                {formValues[ISTITUZIONE_FINANZIARIA_NON_US.id] === 'false' && '*'}
              </label>
            </Col>
          </Row>

          {this.checkNotIstituzioneFinanziaria() && (
            <Fragment>
              <Row>
                <Col s={5} offset="s1" className="radioInput">
                  <Field
                    /* 1 */
                    name={ACTIVE_NFFE.id}
                    type="radio"
                    className="with-gap"
                    id="7"
                    value="ENT_ATT"
                    required
                    component={renderHtmlInput}
                    touch={touch}
                    change={change}
                    onChange={() => {
                      this.emptyEntity('ENT_ATT')
                      change(CRS_ACTIVE.id, 'ACTIVE_NFE')
                      this.disableCP()
                    }}
                  />
                  <label className="radio-vertical" htmlFor="7">
                    Entità Attiva (Active NFFE){formValues[ACTIVE_NFFE.id] === 'ENT_ATT' && '*'}
                  </label>
                </Col>
              </Row>
              {this.checkEntitaAttiva() && (
                <Fragment>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        change={change}
                        id="9"
                        value="ACTIVE_ENTITY_1"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="9">
                        Società che svolge in via principale attività produttiva
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        change={change}
                        id="10"
                        value="ACTIVE_ENTITY_2"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="10">
                        Società quotata in un mercato regolamentato
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="11"
                        change={change}
                        value="ACTIVE_ENTITY_3"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="11">
                        Società controllata da società quotata in un mercato regolamentato
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="12"
                        change={change}
                        value="ACTIVE_ENTITY_4"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="12">
                        Società che svolge attività di tesoreria solo all'interno di un gruppo non finanziario
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        change={change}
                        id="13"
                        value="ACTIVE_ENTITY_5"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="13">
                        Holding di un gruppo non finanziario
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="14"
                        change={change}
                        value="ACTIVE_ENTITY_6"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="14">
                        Società non finanziaria in liquidazione o in fase di riorganizzazione
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="15"
                        change={change}
                        value="ACTIVE_ENTITY_7"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="15">
                        No profit - Exempt BO
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="16"
                        value="ACTIVE_ENTITY_8"
                        change={change}
                        onChange={() => change(CRS_ACTIVE_TYPE.id, 'ENTITA_STATALE')}
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="16">
                        Governo/Pubblica amministrazione - Exempt BO
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="17"
                        value="ACTIVE_ENTITY_9"
                        change={change}
                        onChange={() => change(CRS_ACTIVE_TYPE.id, 'ORG_INTERNAZ')}
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="17">
                        Organizzazione Internazionale e/o eventuali società collegate - Exempt BO
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="18"
                        value="ACTIVE_ENTITY_10"
                        change={change}
                        onChange={() => change(CRS_ACTIVE_TYPE.id, 'BANCA_CENTRALE')}
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="18">
                        Banca centrale - Exempt BO
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="19"
                        change={change}
                        value="ACTIVE_ENTITY_11"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="19">
                        Fondi Pensione - Exempt BO
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="20"
                        value="ACTIVE_ENTITY_12"
                        change={change}
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="20">
                        Società interamente controllata da un Exempt BO
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="21"
                        change={change}
                        value="ACTIVE_ENTITY_13"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="21">
                        Territory Entity posseduta interamente da Bona Fide Residents
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={ACTIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="22"
                        change={change}
                        value="ACTIVE_ENTITY_14"
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="22">
                        Direct Reporting NFFE/Sponsored Direct Reporting NFFE
                      </label>
                    </Col>
                    <Col s={4}>
                      <Field
                        name={SPONSORED.id}
                        s={12}
                        maxLength={50}
                        label={'GIIN' + ('' + formValues[ACTIVE_NFFE_1.id] !== ACTIVE_NFFE_1.opzione1 ? '' : '*')}
                        value={formValues[SPONSORED.id]}
                        change={change}
                        touch={touch}
                        disabled={'' + formValues[ACTIVE_NFFE_1.id] !== ACTIVE_NFFE_1.opzione1}
                        component={InputText}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}

              <Row>
                <Col s={5} offset="s1" className="radioInput">
                  <Field
                    name={ACTIVE_NFFE.id}
                    type="radio"
                    className="with-gap"
                    id="8"
                    value="ENT_PASS"
                    required
                    component={renderHtmlInput}
                    touch={touch}
                    change={change}
                    onChange={() => {
                      this.emptyEntity()
                      change(CRS_ACTIVE.id, 'PASSIVE_NFE')
                    }}
                  />
                  <label className="radio-vertical" htmlFor="8">
                    Entità Passiva (Passive NFFE){formValues[ACTIVE_NFFE.id] === 'ENT_PASS' && '*'}
                  </label>
                </Col>
              </Row>
              {this.checkEntitaPassiva() && (
                <Fragment>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={PASSIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="23"
                        value="SENZA_PERS"
                        required
                        component={renderHtmlInput}
                        touch={touch}
                        change={change}
                        onChange={() => {
                          this.emptyGroup('SENZA_PERS', PASSIVE_NFFE_1.id)
                          change(CRS_PASSIVE_TYPE.id, 'SENZA_PERS')
                          this.disableCP()
                        }}
                      />
                      <label className="radio-vertical" htmlFor="23">
                        Senza una o più controlling persons (cd titolare effettivo)
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6} offset="s2" className="radioInput">
                      <Field
                        name={PASSIVE_NFFE_1.id}
                        type="radio"
                        className="with-gap"
                        id="24"
                        value="PIU_PERS"
                        required
                        component={renderHtmlInput}
                        touch={touch}
                        change={change}
                        onChange={() => {
                          this.emptyGroup('PIU_PERS', PASSIVE_NFFE_1.id)
                          change(CRS_PASSIVE_TYPE.id, 'PIU_PERS')
                        }}
                      />
                      <label className="radio-vertical" htmlFor="24">
                        Con una o più persone controlling persons (cd titolare effettivo)
                        {formValues[PASSIVE_NFFE_1.id] === 'PIU_PERS' && '*'}
                      </label>
                    </Col>
                  </Row>
                  {this.checkControllingPerson() && (
                    <Fragment>
                      <Row>
                        <Col s={6} offset="s3" className="radioInput">
                          <Field
                            name={CP1_GROUP.id}
                            type="checkbox"
                            id="25"
                            value={formValues[CP1_GROUP.id]}
                            required
                            change={change}
                            touch={touch}
                            component={renderHtmlInput}
                            onClick={value => this.emptyCP1(value)}
                          />
                          <label className="radio-vertical" htmlFor="25">
                            Controlling Person 1
                          </label>
                        </Col>
                      </Row>

                      <Row>
                        <Col s={6} offset="s3" className="radioInput">
                          <Field
                            name={CP2_GROUP.id}
                            type="checkbox"
                            id="50"
                            value={formValues[CP2_GROUP.id]}
                            required
                            component={renderHtmlInput}
                            change={change}
                            touch={touch}
                            onChange={() => {
                              this.emptyCP2()
                            }}
                          />
                          <label className="radio-vertical" htmlFor="50">
                            Controlling Person 2
                          </label>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}

          {/* *** CRS *** */}
          <Row>
            <Col s={12}>
              <h6 className="title-color">Dichiarazione ai fini CRS</h6>
            </Col>
          </Row>
          <Row>
            <p />
          </Row>
          <Row>
            <Col s={6}>
              {/* prima nazione crs */}
              <Field
                name={CRS_RESFISCALEDET1_NAZIONE.id}
                s={12}
                maxLength={50}
                label={CRS_STATO_RESIDENZA_FISCALE_1.label}
                // value={formValues[CRS_RESFISCALEDET1_NAZIONE.id]}
                // change={change}
                // touch={touch}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.CRS_RESFISCALEDET1_NAZIONE.dominio,
                  formValues[fields.CRS_RESFISCALEDET1_NAZIONE.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, fields.CRS_RESFISCALEDET1_NAZIONE.dominio)).filter(
                  x =>
                    x.value !== formValues[fields.CRS_RESFISCALEDET2_NAZIONE.id] &&
                    x.value !== formValues[fields.CRS_RESFISCALEDET3_NAZIONE.id]
                )}
                handleChange={(field, value) => {
                  if (value === '') {
                    change(CRS_RESFISCDET1_CF.id, '')
                    change(CRS_STATO_RESIDENZA_FISCALE_1.id, '')
                  }
                  change(field, value)
                }}
                component={renderAutocomplete}
              />
            </Col>
          </Row>
          {formValues[CRS_RESFISCALEDET1_NAZIONE.id] && (
            <Row>
              <Col>
                <div className="mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
              </Col>
            </Row>
          )}
          <Row>
            <Col s={6} className="radioInput">
              <Field
                disabled={!formValues[CRS_RESFISCALEDET1_NAZIONE.id]}
                name={CRS_STATO_RESIDENZA_FISCALE_1.id}
                type="radio"
                className="with-gap"
                id="crs_fis_1_true"
                change={change}
                value={'YES_CF'}
                required
                onChange={() => change(CRS_RESFISCDET1_CF.id, '')}
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="crs_fis_1_true">
                Il codice fiscale rilasciato dallo Stato residenza fiscale 1 è il seguente
              </label>
            </Col>

            {formValues[CRS_STATO_RESIDENZA_FISCALE_1.id] === 'YES_CF' && !!formValues[CRS_RESFISCALEDET1_NAZIONE.id] && (
              <Col s={6}>
                <Field
                  name={CRS_RESFISCDET1_CF.id}
                  s={12}
                  maxLength={20}
                  label="Codice Fiscale*"
                  value={formValues[CRS_STATO_RESIDENZA_FISCALE_1.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
            )}
          </Row>

          <Row>
            <Col s={6} className="radioInput">
              <Field
                disabled={!formValues[CRS_RESFISCALEDET1_NAZIONE.id]}
                name={CRS_STATO_RESIDENZA_FISCALE_1.id}
                type="radio"
                className="with-gap"
                id="crs_fis_1_false"
                value={!formValues[CRS_RESFISCALEDET1_NAZIONE.id] ? undefined : 'NO_CF'}
                change={change}
                onChange={() => change(CRS_RESFISCDET1_CF.id, '')}
                required
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="crs_fis_1_false">
                Lo Stato residenza fiscale 1 non rilascia codici fiscali ai propri residenti
              </label>
            </Col>
          </Row>
          <Row>
            <p />
          </Row>
          <Row>
            <Col s={6}>
              {/* seconda nazione crs */}
              <Field
                name={CRS_RESFISCALEDET2_NAZIONE.id}
                s={12}
                maxLength={50}
                label={CRS_STATO_RESIDENZA_FISCALE_2.label}
                // value={formValues[CRS_RESFISCALEDET2_NAZIONE.id]}
                // change={change}
                // touch={touch}
                // component={InputText}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.CRS_RESFISCALEDET2_NAZIONE.dominio,
                  formValues[fields.CRS_RESFISCALEDET2_NAZIONE.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, fields.CRS_RESFISCALEDET2_NAZIONE.dominio)).filter(
                  x =>
                    x.value !== formValues[fields.CRS_RESFISCALEDET1_NAZIONE.id] &&
                    x.value !== formValues[fields.CRS_RESFISCALEDET3_NAZIONE.id]
                )}
                handleChange={(field, value) => {
                  if (value === '') {
                    change(CRS_RESFISCDET2_CF.id, '')
                    change(CRS_STATO_RESIDENZA_FISCALE_2.id, '')
                  }
                  change(field, value)
                }}
                component={renderAutocomplete}
              />
            </Col>
          </Row>
          {formValues[CRS_RESFISCALEDET2_NAZIONE.id] && (
            <Row>
              <Col>
                <div className="mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
              </Col>
            </Row>
          )}
          <Row>
            <Col s={6} className="radioInput">
              <Field
                disabled={!formValues[CRS_RESFISCALEDET2_NAZIONE.id]}
                name={CRS_STATO_RESIDENZA_FISCALE_2.id}
                type="radio"
                className="with-gap"
                id="crs_fis_2_true"
                value={'YES_CF'}
                change={change}
                required
                onChange={() => change(CRS_RESFISCDET2_CF.id, '')}
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="crs_fis_2_true">
                Il codice fiscale rilasciato dallo Stato residenza fiscale 2 è il seguente
              </label>
            </Col>

            {formValues[CRS_STATO_RESIDENZA_FISCALE_2.id] === 'YES_CF' && !!formValues[CRS_RESFISCALEDET2_NAZIONE.id] && (
              <Col s={6}>
                <Field
                  name={CRS_RESFISCDET2_CF.id}
                  s={12}
                  maxLength={20}
                  label="Codice Fiscale*"
                  value={formValues[CRS_STATO_RESIDENZA_FISCALE_2.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col s={6} className="radioInput">
              <Field
                name={CRS_STATO_RESIDENZA_FISCALE_2.id}
                disabled={!formValues[CRS_RESFISCALEDET2_NAZIONE.id]}
                type="radio"
                className="with-gap"
                id="crs_fis_2_false"
                value={!formValues[CRS_RESFISCALEDET2_NAZIONE.id] ? undefined : 'CRS_FIS_2_FALSE'}
                change={change}
                onChange={() => change(CRS_RESFISCDET2_CF.id, '')}
                required
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="crs_fis_2_false">
                Lo Stato residenza fiscale 2 non rilascia codici fiscali ai propri residenti
              </label>
            </Col>
          </Row>
          <Row>
            <p />
          </Row>
          <Row>
            <Col s={6}>
              {/* terza nazione crs */}
              <Field
                name={CRS_RESFISCALEDET3_NAZIONE.id}
                s={12}
                maxLength={50}
                label={CRS_STATO_RESIDENZA_FISCALE_3.label}
                // value={formValues[CRS_RESFISCALEDET3_NAZIONE.id]}
                // change={change}
                // touch={touch}
                // component={InputText}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  fields.CRS_RESFISCALEDET3_NAZIONE.dominio,
                  formValues[fields.CRS_RESFISCALEDET3_NAZIONE.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, fields.CRS_RESFISCALEDET3_NAZIONE.dominio)).filter(
                  x =>
                    x.value !== formValues[fields.CRS_RESFISCALEDET1_NAZIONE.id] &&
                    x.value !== formValues[fields.CRS_RESFISCALEDET2_NAZIONE.id]
                )}
                handleChange={(field, value) => {
                  if (value === '') {
                    change(CRS_RESFISCDET3_CF.id, '')
                    change(CRS_STATO_RESIDENZA_FISCALE_3.id, '')
                    // change(CRS_RESFISCALEDET3_NAZIONE.id, false)
                  }
                  change(field, value)
                }}
                component={renderAutocomplete}
              />
            </Col>
          </Row>
          {formValues[CRS_RESFISCALEDET3_NAZIONE.id] && (
            <Row>
              <Col>
                <div className="mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
              </Col>
            </Row>
          )}
          <Row>
            <Col s={6} className="radioInput">
              <Field
                name={CRS_STATO_RESIDENZA_FISCALE_3.id}
                disabled={!formValues[CRS_RESFISCALEDET3_NAZIONE.id]}
                type="radio"
                className="with-gap"
                id="crs_fis_3_true"
                value={'YES_CF'}
                change={change}
                onChange={() => change(CRS_RESFISCDET3_CF.id, '')}
                required
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="crs_fis_3_true">
                Il codice fiscale rilasciato dallo Stato residenza fiscale 3 è il seguente
              </label>
            </Col>

            {formValues[CRS_STATO_RESIDENZA_FISCALE_3.id] === 'YES_CF' && !!formValues[CRS_RESFISCALEDET3_NAZIONE.id] && (
              <Col s={6}>
                <Field
                  name={CRS_RESFISCDET3_CF.id}
                  s={12}
                  maxLength={20}
                  label="Codice Fiscale*"
                  value={formValues[CRS_STATO_RESIDENZA_FISCALE_3.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col s={6} className="radioInput">
              <Field
                name={CRS_STATO_RESIDENZA_FISCALE_3.id}
                disabled={!formValues[CRS_RESFISCALEDET3_NAZIONE.id]}
                type="radio"
                className="with-gap"
                id="crs_fis_3_false"
                value={!formValues[CRS_RESFISCALEDET3_NAZIONE.id] ? undefined : 'NO_CF'}
                change={change}
                onChange={() => change(CRS_RESFISCDET3_CF.id, '')}
                required
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="crs_fis_3_false">
                Lo Stato residenza fiscale 3 non rilascia codici fiscali ai propri residenti
              </label>
            </Col>
          </Row>
          <Row>
            <p />
          </Row>

          {/* radio */}

          <Row>
            <Col s={5} className="radioInput">
              <Field
                name={CRS_ISTITUZIONE_FINANZIARIA.id}
                type="radio"
                disabled
                className="with-gap"
                id="crs1"
                value="IST_FIN"
                required
                change={change}
                onChange={() => {
                  this.emptyCRSFinanziaria()
                  change(ISTITUZIONE_FINANZIARIA_NON_US.id, true)
                }}
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="crs1">
                Dichiaro in qualità di ISTITUZIONE FINANZIARIA
                {formValues[CRS_ISTITUZIONE_FINANZIARIA.id] === 'IST_FIN' && '*'}
              </label>
            </Col>
          </Row>
          {this.checkCRSIstituzioneFinanziaria() && (
            <Fragment>
              <Row>
                <Col s={5} offset="s2" className="radioInput">
                  <Field
                    name={CRS_ENTITA.id}
                    type="radio"
                    className="with-gap"
                    id="crs3"
                    value="INV_GIURIS"
                    required
                    change={change}
                    onChange={() => {
                      this.emptyCRSEntita()
                    }}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="crs3">
                    Entità di investimento di giurisdizione non partecipante, gestita da altra istituzione finanziaria
                    {formValues[CRS_ENTITA.id] === 'INV_GIURIS' && '*'}
                  </label>
                </Col>
              </Row>
              {this.checkCRSEntita() && (
                <Fragment>
                  <Row>
                    <Col s={5} offset="s3" className="radioInput">
                      <Field
                        name={CRS_ENTITA_PERSONE.id}
                        type="radio"
                        className="with-gap"
                        id="crs4"
                        value="PIU_PERS"
                        required
                        change={change}
                        component={renderHtmlInput}
                        onChange={() => {
                          this.emptyGroup('PIU_PERS', CRS_ENTITA_PERSONE.id)
                          // change(PASSIVE_NFFE_1.id, 'PIU_PERS')
                        }}
                      />
                      <label className="radio-vertical" htmlFor="crs4">
                        con una o più persone che esercitano il controllo
                        {formValues[CRS_ENTITA_PERSONE.id] === 'PIU_PERS' && '*'}
                      </label>
                    </Col>
                  </Row>

                  {this.checkCRSControllingPerson() && (
                    <Fragment>
                      <Row>
                        <Col s={6} offset="s3" className="radioInput">
                          <Field
                            name={CP1_GROUP.id}
                            type="checkbox"
                            value={formValues[CP1_GROUP.id]}
                            id="a25"
                            required
                            change={change}
                            touch={touch}
                            component={renderHtmlInput}
                            onClick={value => this.emptyCP1(value)}
                          />
                          <label className="radio-vertical" htmlFor="a25">
                            Controlling Person 1
                          </label>
                        </Col>
                      </Row>

                      <Row>
                        <Col s={6} offset="s3" className="radioInput">
                          <Field
                            name={CP2_GROUP.id}
                            type="checkbox"
                            value={formValues[CP2_GROUP.id]}
                            required
                            id="a50"
                            component={renderHtmlInput}
                            change={change}
                            touch={touch}
                            onChange={() => {
                              this.emptyCP2()
                            }}
                          />
                          <label className="radio-vertical" htmlFor="a50">
                            Controlling Person 2
                          </label>
                        </Col>
                      </Row>
                    </Fragment>
                  )}

                  <Row>
                    <Col s={5} offset="s3" className="radioInput">
                      <Field
                        name={CRS_ENTITA_PERSONE.id}
                        type="radio"
                        className="with-gap"
                        id="crs5"
                        value="SENZA_PERS"
                        required
                        change={change}
                        component={renderHtmlInput}
                        onChange={() => {
                          this.emptyGroup('SENZA_PERS', CRS_ENTITA_PERSONE.id)
                        }}
                      />
                      <label className="radio-vertical" htmlFor="crs5">
                        Senza una o più persone che esercitano il controllo
                      </label>
                    </Col>
                  </Row>
                </Fragment>
              )}
              <Row>
                <Col s={5} offset="s2" className="radioInput">
                  <Field
                    name={CRS_ENTITA.id}
                    type="radio"
                    className="with-gap"
                    id="crs6"
                    value="ALTRA_ENTITA"
                    required
                    change={change}
                    onChange={() => {
                      this.emptyCRSEntita()
                    }}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="crs6">
                    Altra entità di investimento
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={5} offset="s2" className="radioInput">
                  <Field
                    name={CRS_ENTITA.id}
                    type="radio"
                    className="with-gap"
                    id="crs7"
                    value="ALTRA_ISTIT"
                    required
                    change={change}
                    onChange={() => {
                      this.emptyCRSEntita()
                    }}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="crs7">
                    Altra istituzione finanziaria
                  </label>
                </Col>
              </Row>
            </Fragment>
          )}

          <Row>
            <Col s={5} className="radioInput">
              <Field
                name={CRS_ISTITUZIONE_FINANZIARIA.id}
                type="radio"
                className="with-gap"
                id="crs2"
                value="ENT_NO_FIN"
                disabled
                required
                change={change}
                onChange={() => {
                  this.emptyCRSFinanziaria()
                  change(ISTITUZIONE_FINANZIARIA_NON_US.id, false)
                }}
                component={renderHtmlInput}
              />
              <label className="radio-vertical" htmlFor="crs2">
                Dichiaro in qualità di ENTITA' NON FINANZIARIA
                {formValues[CRS_ISTITUZIONE_FINANZIARIA.id] === 'ENT_NO_FIN' && '*'}
              </label>
            </Col>
          </Row>
          {this.checkCRSNotIstituzioneFinanziaria() && (
            <Fragment>
              <Row>
                <Col s={5} offset="s2" className="radioInput">
                  <Field
                    name={CRS_ACTIVE.id}
                    type="radio"
                    className="with-gap"
                    id="crs8"
                    value="ACTIVE_NFE"
                    required
                    change={change}
                    onChange={() => {
                      this.emptyCRSEntity('ENT_ATT')
                      change(ACTIVE_NFFE.id, 'ENT_ATT')
                      this.disableCP()
                      /* TODO: refactor! */
                    }}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="crs8">
                    Active NFFE
                    {formValues[CRS_ACTIVE.id] === 'ACTIVE_NFE' && '*'}
                  </label>
                </Col>
              </Row>
              {this.checkCRSEntitaAttiva() && (
                <Fragment>
                  <Row>
                    <Col s={3} offset="s3" className="radioInput">
                      <Field
                        name={CRS_ACTIVE_TYPE.id}
                        type="radio"
                        className="with-gap"
                        id="crs10"
                        value="SC_MERCATO"
                        change={change}
                        onChange={() => {
                          change(CRS_ACTIVE_SOCIETA_QUOTATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_COLLEGATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_ALTRO.id, '')
                        }}
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="crs10">
                        Società di capitali quotata su mercato regolamentato
                      </label>
                    </Col>
                    <Col s={3} className="noBottom">
                      <Field
                        s={12}
                        name={CRS_ACTIVE_SOCIETA_QUOTATA.id}
                        maxLength={50}
                        label={
                          CRS_ACTIVE_SOCIETA_QUOTATA.label + (formValues[CRS_ACTIVE_TYPE.id] !== 'SC_MERCATO')
                            ? ''
                            : '*'
                        }
                        value={formValues[CRS_ACTIVE_SOCIETA_QUOTATA.id]}
                        change={change}
                        touch={touch}
                        disabled={formValues[CRS_ACTIVE_TYPE.id] !== 'SC_MERCATO'}
                        component={InputText}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col s={3} offset="s3" className="radioInput">
                      <Field
                        name={CRS_ACTIVE_TYPE.id}
                        type="radio"
                        className="with-gap"
                        id="crs11"
                        value="SC_SQ"
                        change={change}
                        onChange={() => {
                          change(CRS_ACTIVE_SOCIETA_QUOTATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_COLLEGATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_ALTRO.id, '')
                        }}
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="crs11">
                        Società di capitali collegata ad una società quotata su mercato regolamentato
                      </label>
                    </Col>

                    <Col s={3} className="noBottom">
                      <Field
                        s={12}
                        name={CRS_ACTIVE_SOCIETA_COLLEGATA.id}
                        maxLength={50}
                        label={
                          CRS_ACTIVE_SOCIETA_COLLEGATA.label + (formValues[CRS_ACTIVE_TYPE.id] !== 'SC_SQ') ? '' : '*'
                        }
                        value={formValues[CRS_ACTIVE_SOCIETA_COLLEGATA.id]}
                        change={change}
                        touch={touch}
                        disabled={formValues[CRS_ACTIVE_TYPE.id] !== 'SC_SQ'}
                        component={InputText}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col s={5} offset="s3" className="radioInput">
                      <Field
                        name={CRS_ACTIVE_TYPE.id}
                        type="radio"
                        className="with-gap"
                        id="crs12"
                        change={change}
                        value="ENTITA_STATALE"
                        required
                        onChange={() => {
                          change(ACTIVE_NFFE_1.id, 'ACTIVE_ENTITY_8')
                          change(CRS_ACTIVE_SOCIETA_QUOTATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_COLLEGATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_ALTRO.id, '')
                        }}
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="crs12">
                        Entità statale
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={3} offset="s3" className="radioInput">
                      <Field
                        name={CRS_ACTIVE_TYPE.id}
                        type="radio"
                        className="with-gap"
                        change={change}
                        id="crs13"
                        value="ORG_INTERNAZ"
                        required
                        onChange={() => {
                          change(ACTIVE_NFFE_1.id, 'ACTIVE_ENTITY_9')
                          change(CRS_ACTIVE_SOCIETA_QUOTATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_COLLEGATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_ALTRO.id, '')
                        }}
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="crs13">
                        Organizzazione internazionale
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={5} offset="s3" className="radioInput">
                      <Field
                        name={CRS_ACTIVE_TYPE.id}
                        type="radio"
                        className="with-gap"
                        change={change}
                        id="crs14"
                        value="BANCA_CENTRALE"
                        required
                        onChange={() => {
                          change(ACTIVE_NFFE_1.id, 'ACTIVE_ENTITY_10')
                          change(CRS_ACTIVE_SOCIETA_QUOTATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_COLLEGATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_ALTRO.id, '')
                        }}
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="crs14">
                        Banca centrale
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={3} offset="s3" className="radioInput">
                      <Field
                        name={CRS_ACTIVE_TYPE.id}
                        type="radio"
                        className="with-gap"
                        id="crs15"
                        change={change}
                        value="ALTRO_ACTIVE"
                        required
                        onChange={() => {
                          change(CRS_ACTIVE_SOCIETA_QUOTATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_COLLEGATA.id, '')
                          change(CRS_ACTIVE_SOCIETA_ALTRO.id, '')
                        }}
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="crs15">
                        Altra tipologia di Active NFE
                      </label>
                    </Col>
                    <Col s={3} className="noBottom">
                      <Field
                        s={12}
                        name={CRS_ACTIVE_SOCIETA_ALTRO.id}
                        maxLength={50}
                        label={
                          CRS_ACTIVE_SOCIETA_ALTRO.label + (formValues[CRS_ACTIVE_TYPE.id] !== 'ALTRO_ACTIVE')
                            ? ''
                            : '*'
                        }
                        value={formValues[CRS_ACTIVE_SOCIETA_ALTRO.id]}
                        change={change}
                        touch={touch}
                        disabled={formValues[CRS_ACTIVE_TYPE.id] !== 'ALTRO_ACTIVE'}
                        component={InputText}
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
              <Row>
                <Col s={5} offset="s2" className="radioInput">
                  <Field
                    name={CRS_ACTIVE.id}
                    type="radio"
                    className="with-gap"
                    id="crs9"
                    value="PASSIVE_NFE"
                    change={change}
                    required
                    onChange={() => {
                      this.emptyCRSEntity(CRS_ACTIVE.id)
                      change(ACTIVE_NFFE.id, 'ENT_PASS')
                    }}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="crs9">
                    Passive NFFE
                    {formValues[CRS_ACTIVE.id] === 'PASSIVE_NFE' && '*'}
                  </label>
                </Col>
              </Row>
              {this.checkCRSEntitaPassiva() && (
                <Fragment>
                  <Row>
                    <Col s={5} offset="s3" className="radioInput">
                      <Field
                        name={CRS_PASSIVE_TYPE.id}
                        type="radio"
                        className="with-gap"
                        id="crs16"
                        change={change}
                        value="SENZA_PERS"
                        required
                        component={renderHtmlInput}
                        onChange={() => {
                          this.emptyGroup('SENZA_PERS', CRS_PASSIVE_TYPE.id)
                          change(PASSIVE_NFFE_1.id, 'SENZA_PERS')
                          this.disableCP()
                        }}
                      />
                      <label className="radio-vertical" htmlFor="crs16">
                        Senza una o più persone che esercitano il controllo
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={5} offset="s3" className="radioInput">
                      <Field
                        name={CRS_PASSIVE_TYPE.id}
                        type="radio"
                        className="with-gap"
                        change={change}
                        id="crs17"
                        value="PIU_PERS"
                        onChange={() => {
                          this.emptyGroup('PIU_PERS', CRS_PASSIVE_TYPE.id)
                          change(PASSIVE_NFFE_1.id, 'PIU_PERS')
                        }}
                        required
                        component={renderHtmlInput}
                      />
                      <label className="radio-vertical" htmlFor="crs17">
                        Con una o più persone che esercitano il controllo
                        {formValues[CRS_PASSIVE_TYPE.id] === 'PIU_PERS' && '*'}
                      </label>
                    </Col>
                  </Row>
                  {this.checkCRSNonFinControllingPerson() && (
                    <Fragment>
                      <Row>
                        <Col s={6} offset="s3" className="radioInput">
                          <Field
                            name={CP1_GROUP.id}
                            type="checkbox"
                            value={formValues[CP1_GROUP.id]}
                            id="b25"
                            required
                            change={change}
                            touch={touch}
                            component={renderHtmlInput}
                            onClick={value => this.emptyCP1(value)}
                          />
                          <label className="radio-vertical" htmlFor="b25">
                            Controlling Person 1
                          </label>
                        </Col>
                      </Row>

                      <Row>
                        <Col s={6} offset="s3" className="radioInput">
                          <Field
                            name={CP2_GROUP.id}
                            type="checkbox"
                            value={formValues[CP2_GROUP.id]}
                            id="b50"
                            required
                            component={renderHtmlInput}
                            change={change}
                            touch={touch}
                            onChange={() => {
                              this.emptyCP2()
                            }}
                          />
                          <label className="radio-vertical" htmlFor="b50">
                            Controlling Person 2
                          </label>
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}

          {this.checkControllingPerson1(formValues[CP1_GROUP.id]) && (
            <Fragment>
              <Row>
                <Col s={12}>
                  <h6 className="title-color">Controlling Person 1</h6>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <Field
                    name={CP1_NAME.id}
                    s={12}
                    maxLength={50}
                    label={CP1_NAME.label + '*'}
                    value={formValues[CP1_NAME.id]}
                    change={change}
                    touch={touch}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <Field
                    name={CP1_SURNAME.id}
                    s={12}
                    maxLength={50}
                    label={CP1_SURNAME.label + '*'}
                    value={formValues[CP1_SURNAME.id]}
                    change={change}
                    touch={touch}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <Field
                    name={CP1_DATA_NASCITA.id}
                    s={12}
                    maxLength={50}
                    placeholder=""
                    label={CP1_DATA_NASCITA.label + '*'}
                    value={formValues[CP1_DATA_NASCITA.id]}
                    isOutsideRange={date => checkInvalidBirthdate(date)}
                    onDateChange={date => change(fields.CP1_DATA_NASCITA.id, date.format())}
                    change={change}
                    touch={touch}
                    component={renderDatePicker}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      id="100"
                      name={CP1_NAZIONE.id}
                      s={12}
                      label={CP1_NAZIONE.label}
                      threshold={0}
                      change={change}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        CP1_NAZIONE.dominio,
                        formValues[CP1_NAZIONE.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, CP1_NAZIONE.dominio))}
                      handleChange={(field, value) => {
                        change(field, value)
                        if (formValues[CP1_PROVINCIA.id]) {
                          change(CP1_PROVINCIA.id, '')
                        }
                        if (formValues[CP1_COMUNE.id]) {
                          change(CP1_COMUNE.id, '')
                        }
                        if (formValues[CP1_COMUNE_ESTERO.id]) {
                          change(CP1_COMUNE_ESTERO.id, '')
                        }
                        if (formValues[CP1_CAP.id]) {
                          change(CP1_CAP.id, '')
                        }
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
                {!this.checkNazione1(true) && (
                  <Col s={6} offset="s4">
                    <div
                      className="col s12"
                      style={{
                        padding: '0'
                      }}>
                      <Field
                        id="95"
                        name={CP1_COMUNE_ESTERO.id}
                        s={12}
                        maxLength={10}
                        label={CP1_COMUNE_ESTERO.label}
                        value={formValues[CP1_COMUNE_ESTERO.id]}
                        change={change}
                        disable={!this.checkNazione1(false)}
                        component={InputText}
                        touch={touch}
                        error={formErrors[CP1_COMUNE_ESTERO.id]}
                      />
                    </div>
                  </Col>
                )}
                {this.checkNazione1(true) && (
                  <Col s={6} offset="s4">
                    <div className="col s12">
                      <Field
                        id="94"
                        name={CP1_PROVINCIA.id}
                        s={12}
                        label={CP1_PROVINCIA.label}
                        threshold={0}
                        change={change}
                        disabled={!this.checkNazione1(true)}
                        defaultValue={getAutocompleteDefaultOption(
                          domini.listaDomini,
                          CP1_PROVINCIA.dominio,
                          formValues[CP1_PROVINCIA.id]
                        )}
                        options={mapDominiToAutocomplete(getDominio(domini, CP1_PROVINCIA.dominio))}
                        handleChange={(field, value) => {
                          change(field, value)
                          if (formValues[CP1_COMUNE.id]) {
                            change(CP1_COMUNE.id, '')
                          }
                          if (formValues[CP1_CAP.id]) {
                            change(CP1_CAP.id, '')
                          }
                          if (this.checkProvincia1(value)) {
                            this.updateDominio(CP1_COMUNE.dominio, value)
                          }
                        }}
                        component={renderAutocomplete}
                      />
                    </div>
                  </Col>
                )}
                {this.checkNazione1(true) && (
                  <Col s={6} offset="s4">
                    <div className="col s12">
                      <Field
                        id="93"
                        name={CP1_COMUNE.id}
                        change={change}
                        s={12}
                        threshold={0}
                        label={CP1_COMUNE.label}
                        disabled={!this.checkProvincia1(formValues[CP1_PROVINCIA.id])}
                        defaultValue={getAutocompleteDefaultOption(
                          domini.listaDomini,
                          CP1_COMUNE.dominio,
                          formValues[CP1_COMUNE.id]
                        )}
                        options={
                          formValues[CP1_PROVINCIA.id]
                            ? mapDominiToAutocomplete(getDominio(domini, CP1_COMUNE.dominio))
                            : []
                        }
                        handleChange={(field, value) => {
                          change(field, value)
                          if (formValues[CP1_CAP.id]) {
                            change(CP1_CAP.id, '')
                          }
                          if (this.checkComune1(value)) {
                            this.setState({ waitingCap: true })
                            this.updateDominio(CP1_CAP.dominio, value)
                          }
                        }}
                        component={renderAutocomplete}
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP1_CITTADINANZA.id}
                      size={12}
                      label={CP1_CITTADINANZA.label}
                      value={formValues[CP1_CITTADINANZA.id]}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        CP1_CITTADINANZA.dominio,
                        formValues[CP1_CITTADINANZA.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, CP1_CITTADINANZA.dominio))}
                      handleChange={(field, value) => {
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP1_NAZIONE_RESIDENZA.id}
                      s={12}
                      label={CP1_NAZIONE_RESIDENZA.label}
                      threshold={0}
                      change={change}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        CP1_NAZIONE_RESIDENZA.dominio,
                        formValues[CP1_NAZIONE_RESIDENZA.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, CP1_NAZIONE_RESIDENZA.dominio))}
                      handleChange={(field, value) => {
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP1_RESIDENZA_FISCALE_USA.id}
                      label={CP1_RESIDENZA_FISCALE_USA.label + '*'}
                      // handlerFn={value => change(CP1_RESIDENZA_FISCALE_USA.id, value)}
                      handlerFn={value => {
                        if (value === false) {
                          change(CP1_RF1_TIN.id, '')
                        }
                        change(CP1_RESIDENZA_FISCALE_USA.id, value)
                      }}
                      options={CP1_RESIDENZA_FISCALE_USA.listaRisposte}
                      change={change}
                      component={renderYesNoQuestion}
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col s={6} offset="s4">
                  <Field
                    name={CP1_RF1_TIN.id}
                    s={12}
                    disabled={
                      !(
                        formValues[CP1_RESIDENZA_FISCALE_USA.id] === 'true' ||
                        formValues[CP1_RESIDENZA_FISCALE_USA.id] === true
                      )
                    }
                    maxLength={50}
                    label={(() => {
                      let label = CP1_RF1_TIN.label
                      if (
                        formValues[CP1_RESIDENZA_FISCALE_USA.id] === 'true' ||
                        formValues[CP1_RESIDENZA_FISCALE_USA.id] === true
                      ) {
                        label += '*'
                      }
                      return label
                    })()}
                    value={formValues[CP1_RF1_TIN.id]}
                    change={change}
                    touch={touch}
                    component={InputText}
                  />
                </Col>
              </Row>

              <Row>
                {/* questa è la PRIMA nazione della cont. pers. 1 */}
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP1_RF1_NAZIONE.id}
                      s={12}
                      label={CP1_STATO_RESIDENZA_FISCALE_1.label}
                      threshold={0}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        fields.CP1_RF1_NAZIONE.dominio,
                        formValues[fields.CP1_RF1_NAZIONE.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, fields.CP1_RF1_NAZIONE.dominio)).filter(
                        x =>
                          x.value !== formValues[fields.CP1_RF2_NAZIONE.id] &&
                          x.value !== formValues[fields.CP1_RF3_NAZIONE.id]
                      )}
                      handleChange={(field, value) => {
                        if (value === '') {
                          change(CP1_STATO_RESIDENZA_FISCALE_1.id, '')
                          change(CP1_CF_RESIDENZA_FISCALE_1.id, '')
                        }
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              {formValues[CP1_RF1_NAZIONE.id] && (
                <Row>
                  <Col s={6} offset="s4">
                    <div className="col s12 mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col s={3} offset="s4" className="radioInput">
                  <Field
                    name={CP1_STATO_RESIDENZA_FISCALE_1.id}
                    type="radio"
                    className="with-gap"
                    disabled={!(formValues[CP1_RF1_NAZIONE.id] !== '')}
                    id="contrPers1_resFiscDet1_sceltaCode_true"
                    value="YES_CF"
                    required
                    change={change}
                    onChange={() => change(CP1_CF_RESIDENZA_FISCALE_1.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers1_resFiscDet1_sceltaCode_true">
                    Il codice fiscale rilasciato dallo Stato residenza fiscale 1 è il seguente
                  </label>
                </Col>

                <Col s={3} className={'' + formValues[CP1_STATO_RESIDENZA_FISCALE_1.id] !== 'YES_CF' ? 'hide' : ''}>
                  <Field
                    name={CP1_CF_RESIDENZA_FISCALE_1.id}
                    s={12}
                    maxLength={20}
                    label="Codice Fiscale*"
                    // value={formValues['contrPers1_resFiscDet1_sceltaCode']}
                    change={change}
                    touch={touch}
                    className={'' + formValues[CP1_STATO_RESIDENZA_FISCALE_1.id] !== 'YES_CF' ? 'hide' : ''}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_STATO_RESIDENZA_FISCALE_1.id}
                    type="radio"
                    disabled={!(formValues[CP1_RF1_NAZIONE.id] !== '')}
                    className="with-gap"
                    id="contrPers1_resFiscDet1_sceltaCode_false"
                    value="NO_CF"
                    required
                    change={change}
                    onChange={() => change(CP1_CF_RESIDENZA_FISCALE_1.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers1_resFiscDet1_sceltaCode_false">
                    Lo Stato residenza fiscale 1 non rilascia codici fiscali ai propri residenti
                  </label>
                </Col>
              </Row>
              <Row>
                <p />
              </Row>

              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP1_RF2_NAZIONE.id}
                      s={12}
                      label={CP1_STATO_RESIDENZA_FISCALE_1.label}
                      threshold={0}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        fields.CP1_RF2_NAZIONE.dominio,
                        formValues[fields.CP1_RF2_NAZIONE.id]
                      )}
                      // no
                      options={mapDominiToAutocomplete(getDominio(domini, fields.CP1_RF2_NAZIONE.dominio)).filter(
                        x =>
                          x.value !== formValues[fields.CP1_RF1_NAZIONE.id] &&
                          x.value !== formValues[fields.CP1_RF3_NAZIONE.id]
                      )}
                      handleChange={(field, value) => {
                        if (value === '') {
                          change(CP1_STATO_RESIDENZA_FISCALE_2.id, '')
                          change(CP1_CF_RESIDENZA_FISCALE_2.id, '')
                        }
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              {formValues[CP1_RF2_NAZIONE.id] && (
                <Row>
                  <Col s={6} offset="s4">
                    <div className="col s12 mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col s={3} offset="s4" className="radioInput">
                  <Field
                    name={CP1_STATO_RESIDENZA_FISCALE_2.id}
                    type="radio"
                    disabled={!(formValues[CP1_RF2_NAZIONE.id] !== '')}
                    className="with-gap"
                    id="contrPers1_resFiscDet2_sceltaCode_true"
                    value="YES_CF"
                    change={change}
                    onChange={() => change(CP1_CF_RESIDENZA_FISCALE_2.id, '')}
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers1_resFiscDet2_sceltaCode_true">
                    Il codice fiscale rilasciato dallo Stato residenza fiscale 2 è il seguente
                  </label>
                </Col>

                <Col s={3} className={'' + formValues[CP1_STATO_RESIDENZA_FISCALE_2.id] !== 'YES_CF' ? 'hide' : ''}>
                  <Field
                    name={CP1_CF_RESIDENZA_FISCALE_2.id}
                    s={12}
                    maxLength={20}
                    label="Codice Fiscale*"
                    value={formValues[CP1_STATO_RESIDENZA_FISCALE_2.id]}
                    change={change}
                    touch={touch}
                    className={'' + formValues[CP1_STATO_RESIDENZA_FISCALE_2.id] !== 'YES_CF' ? 'hide' : ''}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_STATO_RESIDENZA_FISCALE_2.id}
                    type="radio"
                    disabled={!(formValues[CP1_RF2_NAZIONE.id] !== '')}
                    className="with-gap"
                    id="contrPers1_resFiscDet2_sceltaCode_false"
                    value="NO_CF"
                    required
                    change={change}
                    onChange={() => change(CP1_CF_RESIDENZA_FISCALE_2.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers1_resFiscDet2_sceltaCode_false">
                    Lo Stato residenza fiscale 2 non rilascia codici fiscali ai propri residenti
                  </label>
                </Col>
              </Row>
              <Row>
                <p />
              </Row>

              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP1_RF3_NAZIONE.id}
                      s={12}
                      label={CP1_STATO_RESIDENZA_FISCALE_1.label}
                      threshold={0}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        fields.CP1_RF3_NAZIONE.dominio,
                        formValues[fields.CP1_RF3_NAZIONE.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, fields.CP1_RF3_NAZIONE.dominio)).filter(
                        x =>
                          x.value !== formValues[fields.CP1_RF1_NAZIONE.id] &&
                          x.value !== formValues[fields.CP1_RF2_NAZIONE.id]
                      )}
                      handleChange={(field, value) => {
                        if (value === '') {
                          change(CP1_STATO_RESIDENZA_FISCALE_3.id, '')
                          change(CP1_CF_RESIDENZA_FISCALE_3.id, '')
                        }
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              {formValues[CP1_RF3_NAZIONE.id] && (
                <Row>
                  <Col s={6} offset="s4">
                    <div className="col s12 mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col s={3} offset="s4" className="radioInput">
                  <Field
                    name={CP1_STATO_RESIDENZA_FISCALE_3.id}
                    type="radio"
                    className="with-gap"
                    id="contrPers1_resFiscDet3_sceltaCode_true"
                    value="YES_CF"
                    change={change}
                    disabled={!(formValues[CP1_RF3_NAZIONE.id] !== '')}
                    required
                    onChange={() => change(CP1_CF_RESIDENZA_FISCALE_3.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers1_resFiscDet3_sceltaCode_true">
                    Il codice fiscale rilasciato dallo Stato residenza fiscale 3 è il seguente
                  </label>
                </Col>

                <Col s={3} className={'' + formValues[CP1_STATO_RESIDENZA_FISCALE_3.id] !== 'YES_CF' ? 'hide' : ''}>
                  <Field
                    name={CP1_CF_RESIDENZA_FISCALE_3.id}
                    s={12}
                    maxLength={20}
                    label="Codice Fiscale*"
                    value={formValues[CP1_STATO_RESIDENZA_FISCALE_3.id]}
                    change={change}
                    touch={touch}
                    className={'' + formValues[CP1_STATO_RESIDENZA_FISCALE_3.id] !== 'YES_CF' ? 'hide' : ''}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_STATO_RESIDENZA_FISCALE_3.id}
                    type="radio"
                    className="with-gap"
                    id="contrPers1_resFiscDet3_sceltaCode_false"
                    value="NO_CF"
                    required
                    disabled={!(formValues[CP1_RF3_NAZIONE.id] !== '')}
                    change={change}
                    onChange={() => change(CP1_CF_RESIDENZA_FISCALE_3.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers1_resFiscDet3_sceltaCode_false">
                    Lo Stato residenza fiscale 3 non rilascia codici fiscali ai propri residenti
                  </label>
                </Col>
              </Row>
              <Row>
                <Col offset="s4" s={6}>
                  <Title>Tipologia di persona che esercita il controllo:*</Title>
                </Col>
              </Row>

              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="55"
                    value="TYPE1"
                    required
                    change={change}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="55">
                    CP of legal person - ownership
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="56"
                    change={change}
                    value="TYPE2"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="56">
                    CP of legal person – other means
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    change={change}
                    id="51"
                    value="TYPE3"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="51">
                    CP senior manager official
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="26"
                    change={change}
                    value="TYPE4"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="26">
                    CP of legal arrangement – trust – settlor
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    change={change}
                    id="27"
                    value="TYPE5"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="27">
                    CP of legal arrangement – trust – trustee
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="28"
                    change={change}
                    value="TYPE6"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="28">
                    CP of legal arrangement – trust – protector
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="29"
                    value="TYPE7"
                    change={change}
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="29">
                    CP of legal arrangement – trust – beneficiary
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="30"
                    value="TYPE8"
                    change={change}
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="30">
                    CP of legal arrangement – trust – other
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="31"
                    change={change}
                    value="TYPE9"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="31">
                    CP of legal arrangement – other – settlor equivalent
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="32"
                    change={change}
                    value="TYPE10"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="32">
                    CP of legal arrangement – other – trustee equivalent
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="33"
                    change={change}
                    value="TYPE11"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="33">
                    CP of legal arrangement – other – protector equivalent
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="34"
                    value="TYPE12"
                    change={change}
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="34">
                    CP of legal arrangement – other – benefciary equivalent
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP1_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="35"
                    value="TYPE13"
                    change={change}
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="35">
                    CP of legal arrangement – other – other equivalent
                  </label>
                </Col>
              </Row>
            </Fragment>
          )}

          {this.checkControllingPerson2() && (
            <Fragment>
              <Row>
                <Col s={12}>
                  <h6 className="title-color">Controlling Person 2</h6>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <Field
                    name={CP2_NAME.id}
                    s={12}
                    maxLength={50}
                    label={CP2_NAME.label + '*'}
                    value={formValues[CP2_NAME.id]}
                    change={change}
                    touch={touch}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <Field
                    name={CP2_SURNAME.id}
                    s={12}
                    maxLength={50}
                    label={CP2_SURNAME.label + '*'}
                    value={formValues[CP2_SURNAME.id]}
                    change={change}
                    touch={touch}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <Field
                    name={CP2_DATA_NASCITA.id}
                    s={12}
                    maxLength={50}
                    placeholder=""
                    label={CP2_DATA_NASCITA.label + '*'}
                    value={formValues[CP2_DATA_NASCITA.id]}
                    isOutsideRange={date => checkInvalidBirthdate(date)}
                    onDateChange={date => change(fields.CP2_DATA_NASCITA.id, date.format())}
                    change={change}
                    touch={touch}
                    component={renderDatePicker}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP2_NAZIONE.id}
                      id="99"
                      s={12}
                      change={change}
                      label={CP2_NAZIONE.label}
                      threshold={0}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        CP2_NAZIONE.dominio,
                        formValues[CP2_NAZIONE.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, CP2_NAZIONE.dominio))}
                      handleChange={(field, value) => {
                        change(field, value)
                        if (formValues[CP2_PROVINCIA.id]) {
                          change(CP2_PROVINCIA.id, '')
                        }
                        if (formValues[CP2_COMUNE.id]) {
                          change(CP2_COMUNE.id, '')
                        }
                        if (formValues[CP2_COMUNE_ESTERO.id]) {
                          change(CP2_COMUNE_ESTERO.id, '')
                        }
                        if (formValues[CP2_CAP.id]) {
                          change(CP2_CAP.id, '')
                        }
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
                {!this.checkNazione2(true) && (
                  <Col s={6} offset="s4">
                    <div
                      className="col s12"
                      style={{
                        padding: '0'
                      }}>
                      <Field
                        id="98"
                        name={CP2_COMUNE_ESTERO.id}
                        s={6}
                        maxLength={10}
                        label={CP2_COMUNE_ESTERO.label}
                        value={formValues[CP2_COMUNE_ESTERO.id]}
                        change={change}
                        disable={!this.checkNazione2(false)}
                        component={InputText}
                        touch={touch}
                        error={formErrors[CP2_COMUNE_ESTERO.id]}
                      />
                    </div>
                  </Col>
                )}
                {this.checkNazione2(true) && (
                  <Col s={6} offset="s4">
                    <div className="col s12">
                      <Field
                        id="97"
                        name={CP2_PROVINCIA.id}
                        s={12}
                        label={CP2_PROVINCIA.label}
                        threshold={0}
                        change={change}
                        disabled={!this.checkNazione2(true)}
                        defaultValue={getAutocompleteDefaultOption(
                          domini.listaDomini,
                          CP2_PROVINCIA.dominio,
                          formValues[CP2_PROVINCIA.id]
                        )}
                        options={mapDominiToAutocomplete(getDominio(domini, CP2_PROVINCIA.dominio))}
                        handleChange={(field, value) => {
                          change(field, value)
                          if (formValues[CP2_COMUNE.id]) {
                            change(CP2_COMUNE.id, '')
                          }
                          if (formValues[CP2_CAP.id]) {
                            change(CP2_CAP.id, '')
                          }
                          if (this.checkProvincia2(value)) {
                            this.updateDominio(CP2_COMUNE.dominio, value)
                          }
                        }}
                        component={renderAutocomplete}
                      />
                    </div>
                  </Col>
                )}
                {this.checkNazione2(true) && (
                  <Col s={6} offset="s4">
                    <div className="col s12">
                      <Field
                        id="96"
                        name={CP2_COMUNE.id}
                        s={12}
                        threshold={0}
                        label={CP2_COMUNE.label}
                        change={change}
                        disabled={!this.checkProvincia2(formValues[CP2_PROVINCIA.id])}
                        defaultValue={getAutocompleteDefaultOption(
                          domini.listaDomini,
                          CP2_COMUNE.dominio,
                          formValues[CP2_COMUNE.id]
                        )}
                        options={
                          formValues[CP2_PROVINCIA.id]
                            ? mapDominiToAutocomplete(getDominio(domini, CP2_COMUNE.dominio))
                            : []
                        }
                        handleChange={(field, value) => {
                          change(field, value)
                          if (formValues[CP2_CAP.id]) {
                            change(CP2_CAP.id, '')
                          }
                          if (this.checkComune2(value)) {
                            this.setState({ waitingCap: true })
                            this.updateDominio(CP2_CAP.dominio, value)
                          }
                        }}
                        component={renderAutocomplete}
                      />
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP2_CITTADINANZA.id}
                      size={12}
                      label={CP2_CITTADINANZA.label}
                      value={formValues[CP2_CITTADINANZA.id]}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        CP2_CITTADINANZA.dominio,
                        formValues[CP2_CITTADINANZA.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, CP2_CITTADINANZA.dominio))}
                      handleChange={(field, value) => {
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP2_NAZIONE_RESIDENZA.id}
                      s={12}
                      label={CP2_NAZIONE_RESIDENZA.label}
                      threshold={0}
                      change={change}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        CP2_NAZIONE_RESIDENZA.dominio,
                        formValues[CP2_NAZIONE_RESIDENZA.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, CP2_NAZIONE_RESIDENZA.dominio))}
                      handleChange={(field, value) => {
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP2_RESIDENZA_FISCALE_USA.id}
                      label={CP2_RESIDENZA_FISCALE_USA.label + '*'}
                      handlerFn={value => {
                        if (value === false) {
                          change(CP2_RF1_TIN.id, '')
                        }
                        change(CP2_RESIDENZA_FISCALE_USA.id, value)
                      }}
                      options={CP2_RESIDENZA_FISCALE_USA.listaRisposte}
                      change={change}
                      component={renderYesNoQuestion}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4">
                  <Field
                    name={CP2_RF1_TIN.id}
                    s={12}
                    disabled={
                      !(
                        formValues[CP2_RESIDENZA_FISCALE_USA.id] === 'true' ||
                        formValues[CP2_RESIDENZA_FISCALE_USA.id] === true
                      )
                    }
                    maxLength={50}
                    label={(() => {
                      let label = CP2_RF1_TIN.label
                      if (
                        formValues[CP2_RESIDENZA_FISCALE_USA.id] === 'true' ||
                        formValues[CP2_RESIDENZA_FISCALE_USA.id] === true
                      ) {
                        label += '*'
                      }
                      return label
                    })()}
                    value={formValues[CP2_RF1_TIN.id]}
                    change={change}
                    touch={touch}
                    component={InputText}
                  />
                </Col>
              </Row>

              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP2_RF1_NAZIONE.id}
                      s={12}
                      label={CP1_STATO_RESIDENZA_FISCALE_1.label}
                      threshold={0}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        CP2_RF1_NAZIONE.dominio,
                        formValues[CP2_RF1_NAZIONE.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, CP2_RF1_NAZIONE.dominio)).filter(
                        x =>
                          x.value !== formValues[fields.CP2_RF2_NAZIONE.id] &&
                          x.value !== formValues[fields.CP2_RF3_NAZIONE.id]
                      )}
                      handleChange={(field, value) => {
                        if (value === '') {
                          change(CP2_STATO_RESIDENZA_FISCALE_1.id, '')
                          change(CP2_CF_RESIDENZA_FISCALE_1.id, '')
                        }
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              {formValues[CP2_RF1_NAZIONE.id] && (
                <Row>
                  <Col s={6} offset="s4">
                    <div className="col s12 mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col s={3} offset="s4" className="radioInput">
                  <Field
                    name={CP2_STATO_RESIDENZA_FISCALE_1.id}
                    type="radio"
                    change={change}
                    className="with-gap"
                    disabled={!(formValues[CP2_RF1_NAZIONE.id] !== '')}
                    id="contrPers2_resFiscDet1_sceltaCode_true"
                    value="YES_CF"
                    required
                    onChange={() => change(CP2_CF_RESIDENZA_FISCALE_1.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers2_resFiscDet1_sceltaCode_true">
                    Il codice fiscale rilasciato dallo Stato residenza fiscale 1 è il seguente
                  </label>
                </Col>

                <Col s={3} className={'' + formValues[CP2_STATO_RESIDENZA_FISCALE_1.id] !== 'YES_CF' ? 'hide' : ''}>
                  <Field
                    name={CP2_CF_RESIDENZA_FISCALE_1.id}
                    s={12}
                    maxLength={20}
                    label="Codice Fiscale*"
                    value={formValues[CP2_STATO_RESIDENZA_FISCALE_1.id]}
                    change={change}
                    touch={touch}
                    className={'' + formValues[CP2_STATO_RESIDENZA_FISCALE_1.id] !== 'YES_CF' ? 'hide' : ''}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_STATO_RESIDENZA_FISCALE_1.id}
                    type="radio"
                    change={change}
                    disabled={!(formValues[CP2_RF1_NAZIONE.id] !== '')}
                    className="with-gap"
                    id="contrPers2_resFiscDet1_sceltaCode_false"
                    value="NO_CF"
                    required
                    onChange={() => change(CP2_CF_RESIDENZA_FISCALE_1.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers2_resFiscDet1_sceltaCode_false">
                    Lo Stato residenza fiscale 1 non rilascia codici fiscali ai propri residenti
                  </label>
                </Col>
              </Row>
              <Row>
                <p />
              </Row>

              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP2_RF2_NAZIONE.id}
                      s={12}
                      label={CP1_STATO_RESIDENZA_FISCALE_1.label}
                      threshold={0}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        fields.CP2_RF2_NAZIONE.dominio,
                        formValues[fields.CP2_RF2_NAZIONE.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, fields.CP2_RF2_NAZIONE.dominio)).filter(
                        x =>
                          x.value !== formValues[fields.CP2_RF1_NAZIONE.id] &&
                          x.value !== formValues[fields.CP2_RF3_NAZIONE.id]
                      )}
                      handleChange={(field, value) => {
                        if (value === '') {
                          change('contrPers2_resFiscDet2_sceltaCode', '')
                          change(CP2_CF_RESIDENZA_FISCALE_2.id, '')
                        }
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              {formValues[CP2_RF2_NAZIONE.id] && (
                <Row>
                  <Col s={6} offset="s4">
                    <div className="col s12 mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col s={3} offset="s4" className="radioInput">
                  <Field
                    name="contrPers2_resFiscDet2_sceltaCode"
                    type="radio"
                    className="with-gap"
                    change={change}
                    disabled={!(formValues[CP2_RF2_NAZIONE.id] !== '')}
                    id="contrPers2_resFiscDet2_sceltaCode_true"
                    value="YES_CF"
                    required
                    onChange={() => change(CP2_CF_RESIDENZA_FISCALE_2.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers2_resFiscDet2_sceltaCode_true">
                    Il codice fiscale rilasciato dallo Stato residenza fiscale 2 è il seguente
                  </label>
                </Col>

                <Col s={3} className={'' + formValues['contrPers2_resFiscDet2_sceltaCode'] !== 'YES_CF' ? 'hide' : ''}>
                  <Field
                    name={CP2_CF_RESIDENZA_FISCALE_2.id}
                    s={12}
                    maxLength={20}
                    label="Codice Fiscale*"
                    value={formValues['contrPers2_resFiscDet2_sceltaCode']}
                    change={change}
                    touch={touch}
                    className={'' + formValues['contrPers2_resFiscDet2_sceltaCode'] !== 'YES_CF' ? 'hide' : ''}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name="contrPers2_resFiscDet2_sceltaCode"
                    type="radio"
                    className="with-gap"
                    change={change}
                    disabled={!(formValues[CP2_RF2_NAZIONE.id] !== '')}
                    id="contrPers2_resFiscDet2_sceltaCode_false"
                    value="NO_CF"
                    required
                    onChange={() => change(CP2_CF_RESIDENZA_FISCALE_2.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers2_resFiscDet2_sceltaCode_false">
                    Lo Stato residenza fiscale 2 non rilascia codici fiscali ai propri residenti
                  </label>
                </Col>
              </Row>
              <Row>
                <p />
              </Row>

              <Row>
                <Col s={6} offset="s4">
                  <div className="col s12">
                    <Field
                      name={CP2_RF3_NAZIONE.id}
                      s={12}
                      label={CP1_STATO_RESIDENZA_FISCALE_1.label}
                      threshold={0}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        fields.CP2_RF3_NAZIONE.dominio,
                        formValues[fields.CP2_RF3_NAZIONE.id]
                      )}
                      options={mapDominiToAutocomplete(getDominio(domini, fields.CP2_RF3_NAZIONE.dominio)).filter(
                        x =>
                          x.value !== formValues[fields.CP2_RF1_NAZIONE.id] &&
                          x.value !== formValues[fields.CP2_RF2_NAZIONE.id]
                      )}
                      handleChange={(field, value) => {
                        if (value === '') {
                          change('contrPers2_resFiscDet3_sceltaCode', '')
                          change(CP2_CF_RESIDENZA_FISCALE_3.id, '')
                        }
                        change(field, value)
                      }}
                      component={renderAutocomplete}
                    />
                  </div>
                </Col>
              </Row>
              {formValues[CP2_RF3_NAZIONE.id] && (
                <Row>
                  <Col s={6} offset="s4">
                    <div className="col s12 mandatory">{fatcaCrsGiuridiche.label_mandatory}</div>
                  </Col>
                </Row>
              )}
              <Row>
                <Col s={3} offset="s4" className="radioInput">
                  <Field
                    name="contrPers2_resFiscDet3_sceltaCode"
                    type="radio"
                    className="with-gap"
                    change={change}
                    disabled={!(formValues[CP2_RF3_NAZIONE.id] !== '')}
                    id="contrPers2_resFiscDet3_sceltaCode_true"
                    value="YES_CF"
                    required
                    onChange={() => change(CP2_CF_RESIDENZA_FISCALE_3.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers2_resFiscDet3_sceltaCode_true">
                    Il codice fiscale rilasciato dallo Stato residenza fiscale 3 è il seguente
                  </label>
                </Col>

                <Col s={3} className={'' + formValues['contrPers2_resFiscDet3_sceltaCode'] !== 'YES_CF' ? 'hide' : ''}>
                  <Field
                    name={CP2_CF_RESIDENZA_FISCALE_3.id}
                    s={12}
                    maxLength={20}
                    label="Codice Fiscale*"
                    value={formValues['contrPers2_resFiscDet3_sceltaCode']}
                    change={change}
                    touch={touch}
                    className={'' + formValues['contrPers2_resFiscDet3_sceltaCode'] !== 'YES_CF' ? 'hide' : ''}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name="contrPers2_resFiscDet3_sceltaCode"
                    type="radio"
                    className="with-gap"
                    change={change}
                    disabled={!(formValues[CP2_RF3_NAZIONE.id] !== '')}
                    id="contrPers2_resFiscDet3_sceltaCode_false"
                    value="NO_CF"
                    required
                    onChange={() => change(CP2_CF_RESIDENZA_FISCALE_3.id, '')}
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="contrPers2_resFiscDet3_sceltaCode_false">
                    Lo Stato residenza fiscale 3 non rilascia codici fiscali ai propri residenti
                  </label>
                </Col>
              </Row>
              <Row>
                <Col offset="s4" s={6}>
                  <Title>Tipologia di persona che esercita il controllo:*</Title>
                </Col>
              </Row>

              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="36"
                    change={change}
                    value="TYPE1"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="36">
                    CP of legal person - ownership
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="37"
                    value="TYPE2"
                    change={change}
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="37">
                    CP of legal person – other means
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="38"
                    value="TYPE3"
                    change={change}
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="38">
                    CP senior manager official
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="39"
                    change={change}
                    value="TYPE4"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="39">
                    CP of legal arrangement – trust – settlor
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="40"
                    change={change}
                    value="TYPE5"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="40">
                    CP of legal arrangement – trust – trustee
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="41"
                    change={change}
                    value="TYPE6"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="41">
                    CP of legal arrangement – trust – protector
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="42"
                    change={change}
                    value="TYPE7"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="42">
                    CP of legal arrangement – trust – beneficiary
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="43"
                    change={change}
                    value="TYPE8"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="43">
                    CP of legal arrangement – trust – other
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="44"
                    change={change}
                    value="TYPE9"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="44">
                    CP of legal arrangement – other – settlor equivalent
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="45"
                    change={change}
                    value="TYPE10"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="45">
                    CP of legal arrangement – other – trustee equivalent
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    id="46"
                    change={change}
                    value="TYPE11"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="46">
                    CP of legal arrangement – other – protector equivalent
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    change={change}
                    id="47"
                    value="TYPE12"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="47">
                    CP of legal arrangement – other – benefciary equivalent
                  </label>
                </Col>
              </Row>
              <Row>
                <Col s={6} offset="s4" className="radioInput">
                  <Field
                    name={CP2_TYPE_1.id}
                    type="radio"
                    className="with-gap"
                    change={change}
                    id="48"
                    value="TYPE13"
                    required
                    component={renderHtmlInput}
                  />
                  <label className="radio-vertical" htmlFor="48">
                    CP of legal arrangement – other – other equivalent
                  </label>
                </Col>
              </Row>
            </Fragment>
          )}

          <ButtonSubmit
            disabled={
              submitting || !isDirty || invalid || isFetching || Object.keys(formErrors).some(e => formErrors[e])
            }
          />
        </form>
      </CollapsibleItem>
    )
  }
}

renderResidenze.propTypes = {
  fields: PropTypes.object,
  lista: PropTypes.object,
  getDefaultValue: PropTypes.func,
  getDefaultAutocompleteValue: PropTypes.func,
  isDisabled: PropTypes.func,
  getOptions: PropTypes.func,
  handleChange: PropTypes.func,
  errori: PropTypes.array
}

FatcaGiuridiche.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  fields: PropTypes.object,
  domini: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  initialize: PropTypes.func,
  status: PropTypes.any,
  onSave: PropTypes.func,
  retrieveDominio: PropTypes.func,
  updateDominio: PropTypes.func,
  dataFatcaPG: PropTypes.any
}

FatcaGiuridiche.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default FatcaGiuridiche
