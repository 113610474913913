import React from 'react'
import PropTypes from 'prop-types'
// import { Icon } from 'react-materialize'

const PdfFile = ({ documentoPdf: { nomeFile }, cartaceo, caricato, cambiaCaricamento }) => {
  return (
    <li>
      <i className="icon-document-pdf" />
      <span className="description-pdfFile">{nomeFile}</span>
      {/* {cartaceo && (
        <span>
          {!caricato && (
            <span onClick={cambiaCaricamento}>
              <Icon className="material-icons right">file_upload</Icon>
            </span>
          )}
          {caricato && (
            <span onClick={cambiaCaricamento}>
              <Icon className="material-icons right">delete</Icon>
            </span>
          )}
        </span>
      )} */}
    </li>
  )
}

PdfFile.propTypes = {
  documentoPdf: PropTypes.any,
  cartaceo: PropTypes.any,
  caricato: PropTypes.any,
  cambiaCaricamento: PropTypes.any
}

export default PdfFile
