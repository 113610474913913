import { connect } from 'react-redux'
import IntestatariView from '../components/IntestatariView'
import { getCustomerList, getOWCustomerList, saveOWCustomers } from '../../../../store/modules/customers/actions'
import { saveAndProceed, setIdPratica, resetPratica } from '../../../../store/modules/pratica/actions'
import { getDomainService } from '../../../../store/modules/services/actions'

const mapDispatchToProps = dispatch => {
  return {
    getCustomerList: () => dispatch(getCustomerList()),
    saveAndProceed: paramObject => dispatch(saveAndProceed(paramObject)),
    setIdPratica: value => dispatch(setIdPratica(value)),
    resetPratica: () => dispatch(resetPratica()),
    getOWCustomerList: (paramObject, customerType) => dispatch(getOWCustomerList(paramObject, customerType)),
    saveOWCustomers: paramObject => dispatch(saveOWCustomers(paramObject)),
    getDomainService: serviceId => dispatch(getDomainService(serviceId))
  }
}

const mapStateToProps = state => ({
  users: state.customers.customers,
  getClientiListRequest: state.customers.getClientiListRequest,
  savePraticaRequest: state.pratica.savePraticaRequest,
  idPratica: state.pratica.idPratica || undefined,
  OWcustomers0: state.customers.OWcustomers0,
  OWcustomers1: state.customers.OWcustomers1,
  OWcustomers2: state.customers.OWcustomers2,
  getClientiOWListRequest: state.customers.getClientiOWListRequest,
  domainServiceList: state.services.domainServiceList,
  getDomainServiceRequest: state.services.getDomainServiceRequest
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntestatariView)
