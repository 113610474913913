import * as actionTypes from './constants'
import axios from 'axios'
// import { TOKEN } from '../../constants'

export const resetPratica = () => ({ type: actionTypes.RESET_PRATICA })

export function deletePratica(idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRATICA_DELETE_START
    })
    try {
      const response = await axios.delete(`/pratica/${idPratica}`, {})

      dispatch({
        type: actionTypes.PRATICA_DELETE_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.PRATICA_DELETE_ERROR,
        error: error
      })
    }
  }
}

export function fetchPraticaPG(idPratica) {
  try {
    return axios.get(`/pratica/servinvPG/${idPratica}`)
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = `fetchPratica ${idPratica}`
    throw newError
  }
}

export function fetchPratica(idPratica) {
  try {
    return axios.get(`/pratica/${idPratica}`)
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = `fetchPratica ${idPratica}`
    throw newError
  }
}

export function getPratica(idPratica, isPG) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRATICA_GET_START
    })
    try {
      const response = await fetchPratica(idPratica)
      dispatch({
        type: actionTypes.PRATICA_GET_SUCCESS,
        data: response.data,
        idPratica
      })
    } catch (error) {
      dispatch({
        type: actionTypes.PRATICA_GET_ERROR,
        error: error
      })
    }
  }
}

export function getPraticaPG(idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRATICA_PG_GET_START
    })
    try {
      const response = await fetchPraticaPG(idPratica)
      dispatch({
        type: actionTypes.PRATICA_PG_GET_SUCCESS,
        data: response.data,
        idPratica
      })
    } catch (error) {
      dispatch({
        type: actionTypes.PRATICA_PG_GET_ERROR,
        error: error
      })
    }
  }
}
export function updatePraticaPG(idPratica, payload) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.PRATICA_PG_UPDATE_START })
    try {
      const response = await axios.put(`/pratica/servinvPG/${idPratica}`, payload)
      dispatch({ type: actionTypes.PRATICA_PG_UPDATE_SUCCESS, data: response.data, query: payload })
    } catch (error) {
      dispatch({ type: actionTypes.PRATICA_PG_UPDATE_ERROR, error })
    }
  }
}

export function getPraticaIntestatari(idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRATICA_INTESTATARI_GET_START
    })
    try {
      const response = await axios.get(`/pratica/${idPratica}/checkStatoPraticaIntestatari`)
      dispatch({
        type: actionTypes.PRATICA_INTESTATARI_GET_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.PRATICA_INTESTATARI_GET_ERROR,
        error: error
      })
    }
  }
}

export function updateStatoPratica(idPratica, idStatoPratica, flagFirmaCartacea) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.PRATICA_UPDATE_START })
    try {
      await axios.put(`/pratica/${idPratica}`, /* { idStatoPratica }, */ { idStatoPratica, flagFirmaCartacea })
      dispatch({ type: actionTypes.PRATICA_UPDATE_SUCCESS })
    } catch (error) {
      dispatch({ type: actionTypes.PRATICA_UPDATE_ERROR, error })
    }
  }
}

export function checkTipoFirmaIntestatari(idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRATICA_CHECK_FIRMA_INTESTATARI_START
    })
    try {
      const response = await axios.get(`/pratica/${idPratica}/checkTipoFirmaIntestatari`)
      dispatch({
        type: actionTypes.PRATICA_CHECK_FIRMA_INTESTATARI_SUCCESS,
        flagDigitalSignatureEnabled: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.PRATICA_CHECK_FIRMA_INTESTATARI_ERROR,
        error: error
      })
    }
  }
}

export function updateTipologiaCliente(object) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.UPDATE_TIPOLOGIA_CLIENTE_START })
    try {
      const response = await axios.post('/v2/customer/updateTipologiaCliente', object)
      dispatch({ type: actionTypes.UPDATE_TIPOLOGIA_CLIENTE_SUCCESS, data: response.data, query: object })
    } catch (error) {
      dispatch({ type: actionTypes.UPDATE_TIPOLOGIA_CLIENTE_ERROR, error })
    }
  }
}

export function updatePratica(idPratica, payload) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.PRATICA_UPDATE_START })
    if (payload.codiceComuneFirma != null && payload.codiceComuneFirma.codiceDominio != null) {
      payload.codiceComuneFirma.tipoDominio = payload.codiceComuneFirma.codiceDominio
    }
    try {
      const response = await axios.put(`/pratica/${idPratica}`, payload)
      dispatch({ type: actionTypes.PRATICA_UPDATE_SUCCESS, data: response.data, query: payload })
    } catch (error) {
      dispatch({ type: actionTypes.PRATICA_UPDATE_ERROR, error })
    }
  }
}

export function tipoFirma(flag) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.UPDATE_TIPO_FIRMA, tipoFirma: flag })
  }
}

// funzione che salva la pratica e rimanda alla pagina successiva
export function saveAndProceed(query) {
  /* return async function(dispatch) {
    dispatch({
      type: actionTypes.PRATICA_SAVE_START,
      query
    })
    let idPratica = null
    axios
      .post('/pratica', query, {
        headers: { Authorization: 'Bearer ' + TOKEN }
      })
      .then(response => {
        idPratica = response.data.idPratica
        console.log('idPratica', idPratica)
        dispatch({
          type: actionTypes.PRATICA_SAVE_SUCCESS,
          data: { ...response.data.pratica, idPratica }
        })
        return fetchPratica(idPratica) */

  /* .then(response => {
            console.log('RESPONSE', response)
            dispatch({
              type: actionTypes.PRATICA_SAVE_SUCCESS,
              data: { ...response.data.pratica, idPratica }
            })
          })
          .catch(error => {
            dispatch({
              type: actionTypes.PRATICA_SAVE_ERROR,
              error
            })
          }) */
  /* }) */
  // }
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRATICA_SAVE_START,
      query
    })
    /* TEMPORANEO IN ATTESA DELLA CHIAMATA OK */
    /* query.intestatari[0].idCliente = 17
    query.intestatari[0].idRuolo = 5 */
    try {
      const response = await axios.post('/pratica', query)
      // let idPratica = response.data.idPratica
      dispatch({
        type: actionTypes.PRATICA_SAVE_SUCCESS,
        data: response.data,
        services: query
      })
      /* return fetchPratica(idPratica) */
    } catch (error) {
      dispatch({ type: actionTypes.PRATICA_SAVE_ERROR, error })
    }
  }
}

export function setIdPratica(idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_ID_PRATICA,
      idPratica: idPratica
    })
  }
}

export function setIdLegalPerson(obj) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_LEGAL_PERSON,
      idLegalPerson: obj.idLegalPerson,
      idIncarico: obj.idIncarico
    })
  }
}

export function getStatoPratica(idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_STATO_PRATICA_START
    })
    try {
      const response = await axios.get(`/pratica/${idPratica}/status`)
      dispatch({
        type: actionTypes.GET_STATO_PRATICA_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_STATO_PRATICA_ERROR,
        error: error
      })
    }
  }
}

export function updatePraticaDraft(idPratica) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.PRATICA_UPDATE_DRAFT_START })
    try {
      let res = await axios.get(`/utility/pratica/${idPratica}/draft`)
      dispatch({
        type: actionTypes.PRATICA_UPDATE_DRAFT_SUCCESS,
        data: res.data.pratica
      })
    } catch (error) {
      dispatch({ type: actionTypes.PRATICA_UPDATE_DRAFT_ERROR, error })
    }
  }
}
