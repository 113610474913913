import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import {
  MIFID_OBIETTIVI_LIGHT,
  MIFID_OBIETTIVI_PF,
  MIFID_OBIETTIVI_PG
} from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import allFields from '../../fields'
import Title from '../../../../components/Title'
import { getMifidByCategory, MIFID_CONST } from '../../utils'

let MIFID_ACTIVE = null
let fields = null
let NEW_MIFID = true

class ObiettiviInvestimentoReview extends Component {
  constructor(props) {
    super(props)

    this.mapRisposta = this.mapRisposta.bind(this)
    this.getLabel = this.getLabel.bind(this)
    this.getSubRadio = this.getSubRadio.bind(this)
    this.mapSubCheckbox = this.mapSubCheckbox.bind(this)
    this.getSubLabel = this.getSubLabel.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.OBIETTIVI)
    fields =
      this.props.isProfessionalSubjectDone && !NEW_MIFID
        ? allFields[2][MIFID_OBIETTIVI_LIGHT]
        : allFields[2][MIFID_ACTIVE]
  }

  getLabel(templateId) {
    const { template } = this.props
    return template ? template.listaDomande.find(d => d.idDomanda === templateId).testoDomanda : ''
  }

  mapRisposta(templateId) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    const idRisposta = domanda.listaRisposte[0].idRisposta
    return template
      ? template.listaDomande.find(d => d.idDomanda === templateId).listaRisposte.find(r => r.idRisposta === idRisposta)
          .descRisposta
      : []
  }

  getSubLabel(templateId) {
    const { template } = this.props
    const domanda = template.listaDomande.find(d => d.idDomanda === templateId)
    const {
      strutturaInternaDomande: { domande }
    } = domanda
    return template ? domande[0].testoDomanda : ''
  }

  getSubRadio(templateId) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = template.listaDomande.find(d => d.idDomanda === templateId)
    const {
      strutturaInternaDomande: { domande, listaDominiRisposte }
    } = domanda
    const subDomanda = listaDomande.find(d => d.idDomanda === domande[0].idDomanda)
    const idRisposta = subDomanda.listaRisposte[0].idRisposta
    return listaDominiRisposte
      ? listaDominiRisposte[0].risposte.find(d => d.idRisposta === idRisposta).descRisposta
      : []
  }

  mapSubCheckbox(templateId) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = template.listaDomande.find(d => d.idDomanda === templateId)
    const {
      strutturaInternaDomande: { domande, listaDominiRisposte }
    } = domanda
    const subDomanda = listaDomande.find(d => d.idDomanda === domande[0].idDomanda)
    const [, ...checkbox] = subDomanda.listaRisposte
    return checkbox
      ? checkbox.map(
          ({ idRisposta }) => listaDominiRisposte[0].risposte.find(d => d.idRisposta === idRisposta).descRisposta || ''
        )
      : []
  }

  mapCheckbox({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    const domandaTemplate = template ? template.listaDomande.find(d => d.idDomanda === templateId) : {}
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    return domanda.listaRisposte.map(({ idRisposta }) => {
      return domandaTemplate && domandaTemplate.listaRisposte
        ? domandaTemplate.listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
        : ''
    })
  }

  render() {
    const { template, isProfessionalSubjectDone } = this.props
    return (
      <CollapsibleItem
        header={template ? template.descSezione : ''}
        icon="keyboard_arrow_down"
        itemKey={isProfessionalSubjectDone && !NEW_MIFID ? MIFID_OBIETTIVI_LIGHT : MIFID_ACTIVE}
        labelInfo={this.props.accordionHeader}>
        {/* VECCHIA MIFID */}
        {!NEW_MIFID && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.OBIETTIVI.templateId)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.OBIETTIVI.templateId)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RISCHIO.templateId)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RISCHIO.templateId)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.ORIZZONTE_TEMPORALE.templateId)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.ORIZZONTE_TEMPORALE.templateId)} />
              </Col>
            </Row>
          </Fragment>
        )}
        {/* NUOVA MIFID PF/PG/PD */}
        {NEW_MIFID && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.OBIETTIVI_INVESTIMENTI.templateId)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.OBIETTIVI_INVESTIMENTI.templateId)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.ESIGENZE_LIQUIDITA.templateId)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.ESIGENZE_LIQUIDITA.templateId)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.LIMITAZIONI_DISINVESTIMENTO.templateId)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.LIMITAZIONI_DISINVESTIMENTO.templateId)} />
              </Col>
            </Row>
            {/* ASSENTE SULLA PD */}
            {[MIFID_OBIETTIVI_PF, MIFID_OBIETTIVI_PG].includes(MIFID_ACTIVE) && (
              <Row>
                <Col s={12}>
                  <Title>{this.getLabel(fields.PERCENTUALE_DISINVESTIMENTO.templateId)}</Title>
                </Col>
                <Col s={12}>
                  <ReadOnlyInput value={this.mapRisposta(fields.PERCENTUALE_DISINVESTIMENTO.templateId)} />
                </Col>
              </Row>
            )}
            {/* SOLO PER PF */}
            {MIFID_ACTIVE === MIFID_OBIETTIVI_PF && (
              <Row>
                <Col s={12}>
                  <Title>{this.getLabel(fields.ESTENSIONI_SUCCESSORIE.templateId)}</Title>
                </Col>
                <Col s={12}>
                  <ReadOnlyInput value={this.mapRisposta(fields.ESTENSIONI_SUCCESSORIE.templateId)} />
                </Col>
              </Row>
            )}
            {/* ASSENTE SULLA PD */}
            {[MIFID_OBIETTIVI_PF, MIFID_OBIETTIVI_PG].includes(MIFID_ACTIVE) && (
              <Row>
                <Col s={12}>
                  <Title>{this.getLabel(fields.OBBLIGAZIONI_PERPETUE.templateId)}</Title>
                </Col>
                <Col s={12}>
                  <ReadOnlyInput value={this.mapRisposta(fields.OBBLIGAZIONI_PERPETUE.templateId)} />
                </Col>
              </Row>
            )}
            <Row>
              <Col s={12}>
                <Title>{this.getSubLabel(fields.ESIGENZE_ASSICURATIVE.templateId)}</Title>
              </Col>
              <Col s={12}>
                <Row>
                  <Col s={6}>
                    <ReadOnlyInput value={this.getSubRadio(fields.ESIGENZE_ASSICURATIVE.templateId)} />
                  </Col>
                  <Col s={6}>
                    {(this.mapSubCheckbox(fields.ESIGENZE_ASSICURATIVE.templateId) || []).map((r, i) => (
                      <ReadOnlyInput key={i} value={r} />
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row> */}
            {/*   <Col s={12}> */}
            {/*     <Title>{this.getLabel(fields.PREFERENZE_INVESTIMENTI.templateId)}</Title> */}
            {/*   </Col> */}
            {/*   <Col s={12}> */}
            {/*     <ReadOnlyInput value={this.mapRisposta(fields.PREFERENZE_INVESTIMENTI.templateId)} /> */}
            {/*   </Col> */}
            {/* </Row> */}
          </Fragment>
        )}
      </CollapsibleItem>
    )
  }
}

ObiettiviInvestimentoReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  template: PropTypes.shape({
    descSezione: PropTypes.string,
    listaDomande: PropTypes.array
  }),
  isProfessionalSubjectDone: PropTypes.bool
}

export default ObiettiviInvestimentoReview
