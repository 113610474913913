import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { Row, Col, Input } from 'react-materialize'
import { debounce } from 'lodash'
import $ from 'jquery'
import Autocomplete from '../../../components/Autocomplete'
import ToggleBlurText from '../../../components/ToggleBlurText'
import ReadOnlyInput from '../../../components/Inputs/ReadOnly'
import Table from 'react-materialize/lib/Table'
import Button from 'react-materialize/lib/Button'
import moment from 'moment'
import staticData from '../../../static-data/data-aggiornamento-modulistica.json'

const statusOptions = [
  { desc: staticData.aggiornamento_antiriciclaggio.aml_expired, value: 0 },
  { desc: staticData.aggiornamento_antiriciclaggio.aml_expiring, value: 1 }
]

class ListaClientiAntiriciclaggioComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchDrawerCollapse: false,
      customerSelectedValue: '',
      status: []
    }
    this.customerAutocomplete = React.createRef()
    this.getSearchBoxHeight = this.getSearchBoxHeight.bind(this)
    this.getSearchBoxTop = this.getSearchBoxTop.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this)
    this.mapClientiToAutocomplete = this.mapClientiToAutocomplete.bind(this)
    this.updateCondition = this.updateCondition.bind(this)
    this.debouncedUpdateCondition = debounce(this.updateCondition, 500)
    this.nextStep = this.nextStep.bind(this)
    this.getCustomer = this.getCustomer.bind(this)
    this.parseCustomers = this.parseCustomers.bind(this)
  }

  componentDidMount() {
    this.props.getListAML()
    this.refs.iScroll.addEventListener('scroll', () => {
      if (
        this.refs.iScroll &&
        this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight
      ) {
        this.updateCondition(null, null, true)
      }
    })
  }

  componentDidUpdate(prevProps) {
    const { getCustomersAMLRequest, customersAML } = this.props
    if (
      getCustomersAMLRequest &&
      prevProps.getCustomersAMLRequest &&
      prevProps.getCustomersAMLRequest.fetching === true &&
      getCustomersAMLRequest.fetching === false &&
      getCustomersAMLRequest.error === null &&
      customersAML.length > 0
    ) {
      ReactDOM.findDOMNode(this.customerAutocomplete.current)
        .getElementsByTagName('input')[0]
        .focus()
    }
  }

  getSearchBoxTop() {
    if (this.state.searchDrawerCollapse) {
      const height = $('.chips-container').height()
      if (height > 26) {
        return `${-40 - height / 2}px`
      }
      return '-40px'
    }
    return '0'
  }

  getSearchBoxHeight() {
    if (this.state.searchDrawerCollapse) {
      const height = $('.chips-container').height()
      if (height > 26) {
        return `${40 + height}px`
      }
      return '40px'
    }
    return '50px'
  }

  async nextStep(item) {
    const { importSurveyExt, history } = this.props
    const pratica = await importSurveyExt(item)
    if (pratica) {
      history.push(`/aggiornamento-modulistica/compilazione-antiriciclaggio/${pratica.idPratica}`)
    }
  }

  handleCustomerSelect(customerSelectedValue, customerSelected) {
    this.setState({ customerSelected, customerSelectedValue })
    if (customerSelected) {
      this.debouncedUpdateCondition('querySearch', customerSelected.key)
    } else if (customerSelectedValue === '') {
      this.debouncedUpdateCondition('querySearch', '')
    }
  }

  handleStatusChange(array) {
    let status = array.map(s => parseFloat(s))
    if (status.find(s => s === -1)) {
      status = []
    }
    this.setState({ status })
    let _condition = status.length === 1 ? status[0] : ''
    this.updateCondition('amlStatus', _condition)
  }

  mapClientiToAutocomplete(arrayUsers) {
    let usersForAutocomplete = []
    arrayUsers.forEach(function(user) {
      let valueToSearch, label
      // PF
      if (user.nome && user.cognome) {
        valueToSearch = user.nome + ' ' + user.cognome + ' ' + user.nome + ' ' + user.codiceFiscale
        label = user.nome + ' ' + user.cognome
      }
      // PG/PD
      if (!user.nome && user.cognome) {
        valueToSearch = user.cognome + ' ' + user.codiceFiscale
        label = user.cognome
      }
      // Fallback
      if (!user.nome && !user.cognome) {
        valueToSearch = user.codiceFiscale
        label = valueToSearch
      }
      usersForAutocomplete.push({
        key: user.codClienteExt,
        value: user.codClienteExt,
        valueToSearch,
        label: label
      })
    })
    return usersForAutocomplete
  }

  updateCondition(field, value, page) {
    const updatePageSize = 20
    const { query, getListAML, pageSize, setListAMLPageSize, totClienti } = this.props
    const conditions = { ...query }

    if (field) {
      conditions[field] = value
      // fix bug scroll
      this.refs.iScroll.scrollTo(0, 0)
    }

    let pSize = pageSize + updatePageSize
    if (!page) {
      pSize = 20
    } else {
      pSize = pageSize + updatePageSize
    }

    conditions.pageNum = 1
    conditions.itemInPage = pSize
    setListAMLPageSize(pSize)
    if (pSize <= totClienti + 19 || !page) {
      getListAML({
        ...conditions
      })
    } else if (pSize > totClienti + 19) {
      setListAMLPageSize(totClienti)
    }
  }

  getCustomer(user) {
    if (!user || user.length < 2) {
      return
    }
    const { getCustomersAML } = this.props
    getCustomersAML({ querySearch: user })
  }

  parseCustomers(clienti, customersAML) {
    return [...clienti, ...customersAML].reduce((res, cur) => {
      if (res.find(r => r.idCliente === cur.idCliente)) {
        return res
      }
      return [...res, cur]
    }, [])
  }

  render() {
    const {
      toggleBlur,
      query: { querySearch = '' },
      clienti,
      totClienti,
      customersAML
    } = this.props

    const _clienti = this.parseCustomers(clienti, customersAML)

    return (
      <article className="page-left-content">
        <Row>
          <Col s={12}>
            <div className="search-box padding-r-10 padding-l-10">
              <div
                className={`search-box-content padding-10 ${this.state.searchDrawerCollapse ? 'closed' : ''}`}
                style={{ maxHeight: this.getSearchBoxHeight() }}>
                <Autocomplete
                  ref={this.customerAutocomplete}
                  id="customer"
                  name="customer"
                  placeholder={staticData.aggiornamento_antiriciclaggio.search_label}
                  threshold={0}
                  suggestions={this.mapClientiToAutocomplete(_clienti)}
                  onChange={this.handleCustomerSelect}
                  value={this.state.customerSelectedValue}
                  searchByValue
                  iconCallback={() => this.getCustomer(this.state.customerSelectedValue)}
                  tooltipTitle="Clicca sulla lente per ulteriori risultati"
                />
                <Input
                  type="select"
                  className={this.state.status.length > 0 ? 'selected-active' : ''}
                  multiple
                  onChange={(ev, value) => this.handleStatusChange(value)}
                  value={this.state.status}>
                  <option value={-1} disabled>
                    {staticData.aggiornamento_antiriciclaggio.select_label}
                  </option>
                  {statusOptions.map((el, index) => (
                    <option
                      key={`option-${el.value}`}
                      className={this.state.status.find(i => i === index + 1) ? 'selected' : ''}
                      value={el.value}>
                      {el.desc}
                    </option>
                  ))}
                </Input>
              </div>
              <div
                className={`search-box-handle padding-r-5 padding-l-5 ${
                  this.state.searchDrawerCollapse ? 'closed' : ''
                }`}
                style={{ top: this.getSearchBoxTop() }}>
                <div>
                  <ToggleBlurText />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="aml-body" ref="iScroll">
          {clienti.length === 0 && querySearch.length > 0 && (
            <p className="nosearch">{staticData.aggiornamento_antiriciclaggio.no_result}</p>
          )}
          {clienti.length !== 0 && (
            <Row>
              <Col s={12}>
                <div className="totaleServizi">
                  {totClienti + ' ' + staticData.aggiornamento_antiriciclaggio.practices}
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th data-field="nominativo">{staticData.aggiornamento_antiriciclaggio.name}</th>
                      <th data-field="codice_fiscale">{staticData.aggiornamento_antiriciclaggio.fiscal_code}</th>
                      <th data-field="stato">{staticData.aggiornamento_antiriciclaggio.status}</th>
                      <th data-field="data_scadenza">{staticData.aggiornamento_antiriciclaggio.expire_date}</th>
                      <th data-field="data_aggiornamento_av">{staticData.aggiornamento_antiriciclaggio.av_date}</th>
                      <th data-field="data_aggiornamento_antiriciclaggio">
                        {staticData.aggiornamento_antiriciclaggio.aml_date}
                      </th>
                      <th data-field="action_button" />
                    </tr>
                  </thead>

                  <tbody>
                    {clienti.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <ReadOnlyInput
                            value={(() => {
                              if (item.nome && item.cognome) {
                                return (
                                  <span className={toggleBlur ? 'blur-on' : ''}>
                                    {item.nome} {item.cognome}
                                  </span>
                                )
                              }
                              return <span>{item.cognome}</span>
                            })()}
                          />
                        </td>
                        <td>
                          <ReadOnlyInput
                            value={
                              <span className={toggleBlur && !item.isPG ? 'blur-on' : ''}>{item.codiceFiscale}</span>
                            }
                          />
                        </td>
                        <td>
                          <ReadOnlyInput value={item.amlStatusDesc} />
                        </td>
                        <td>
                          <ReadOnlyInput value={moment(item.dataScadenzaAml).format('DD/MM/YY')} />
                        </td>
                        <td>
                          <ReadOnlyInput value={moment(item.dataAggiornamentoAdegVer).format('DD/MM/YY')} />
                        </td>
                        <td>
                          <ReadOnlyInput value={moment(item.dataAggiornamentoAml).format('DD/MM/YY')} />
                        </td>
                        <td>
                          <Button className="button-compile-aml" onClick={() => this.nextStep(item)}>
                            {staticData.aggiornamento_antiriciclaggio.update_aml}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          )}
        </div>
      </article>
    )
  }
}

ListaClientiAntiriciclaggioComponent.propTypes = {
  clienti: PropTypes.array,
  query: PropTypes.object,
  getListAML: PropTypes.func,
  history: PropTypes.object,
  toggleBlur: PropTypes.bool,
  totClienti: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setListAMLPageSize: PropTypes.func,
  pageSize: PropTypes.number,
  importSurveyExt: PropTypes.func,
  getCustomersAML: PropTypes.func,
  customersAML: PropTypes.array,
  getCustomersAMLRequest: PropTypes.object
}

export default ListaClientiAntiriciclaggioComponent
