import React from 'react'
import { Slider, Slide } from 'react-materialize'

const Tutorial = () => (
  <Slider>
    {[1, 2, 3, 4, 5, 6].map(slide => (
      <Slide key={`slide_${slide}`} src={`img/tutorial/slide_${slide}.jpg`} />
    ))}
  </Slider>
)
export default Tutorial
