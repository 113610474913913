import React from 'react'
import PropTypes from 'prop-types'

const YesNoQuestion = ({ label, onChange, value }) => {
  let yesNoClassName = 'box-input'
  const onChangeFn = onChange || function() {}
  if (value) {
    yesNoClassName += ' yes-selected'
  } else if (value === false) {
    yesNoClassName += ' no-selected'
  }
  return (
    <div className="yes-no-question">
      <div>
        <p>{label}</p>
      </div>
      <div className={yesNoClassName}>
        <button className="yes-button" onClick={() => onChangeFn(true)}>
          SI
        </button>
        <button className="no-button" onClick={() => onChangeFn(false)}>
          NO
        </button>
      </div>
    </div>
  )
}
export default YesNoQuestion

YesNoQuestion.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool
}
