export default {
  SLIDER_ANNI_CONOSCENZA: {
    id: 'sliderAnniConoscenza',
    templateId: 105,
    defaultValue: 2,
    propRisposta: 'descRisposta'
  },
  RADIO_PRIMO_CONTATTO: {
    id: 'radioPrimoContatto',
    templateId: 106,
    defaultValue: '',
    propRisposta: 'idRisposta'
  },
  YES_NO_CONTESTO_FAMILIARE: {
    id: 'yesNoContestoFamiliare',
    templateId: 107,
    defaultValue: '',
    propRisposta: 'idRisposta'
  },
  YES_NO_CAMBIO: {
    id: 'yesNoCambio',
    templateId: 108,
    defaultValue: '',
    propRisposta: 'idRisposta'
  },
  MOTIVAZIONE: {
    id: 'motivazione',
    templateId: 109,
    defaultValue: '',
    propRisposta: 'descRisposta'
  },
  RADIO_TENORE_VITA: {
    id: 'radioTenoreVita',
    templateId: 110,
    defaultValue: '',
    propRisposta: 'idRisposta'
  },
  RAGIONEVOLEZZA_ATTIVITA: {
    id: 'ragionevolezzaAttivita',
    templateId: 111,
    defaultValue: '108',
    propRisposta: 'idRisposta'
  },
  RAGIONEVOLEZZA_RESIDENZA: {
    id: 'ragionevolezzaResidenza',
    templateId: 112,
    defaultValue: '108',
    propRisposta: 'idRisposta'
  },
  COMPORTAMENTO: {
    id: 'comportamento',
    templateId: 113,
    defaultValue: '110',
    propRisposta: 'idRisposta'
  },
  YES_NO_SOGGETTI_TERZI: {
    id: 'yesNoSoggettiTerzi',
    templateId: 114,
    defaultValue: '105',
    propRisposta: 'idRisposta'
  },
  YES_NO_INFORMAZIONI_ATTENDIBILI: {
    id: 'yesNoInformazioniAttendibili',
    templateId: 115,
    defaultValue: '104',
    propRisposta: 'idRisposta'
  },
  YES_NO_ELEMENTI_SOSPETTOSITA: {
    id: 'yesNoElementiSospettosita',
    templateId: 116,
    defaultValue: '105',
    propRisposta: 'idRisposta'
  },
  RADIO_RISCHIO_RICICLAGGIO: {
    id: 'radioRischioRiciclaggio',
    templateId: 117,
    defaultValue: '112',
    propRisposta: 'idRisposta'
  },
  TEXTAREA_MOTIVAZIONI_RISCHIO: {
    id: 'textareaMotivazioniRischio',
    templateId: 118,
    defaultValue: '',
    propRisposta: 'descRisposta'
  }
}
