import { combineReducers } from 'redux'
import { customers } from './modules/customers'
import { services } from './modules/services'
import { dominio } from './modules/dominio'
import { dashboard } from './modules/dashboard'
import { survey } from './modules/survey'
import { validation } from './modules/validation'
import { privacy } from './modules/privacy'
import { pratica } from './modules/pratica'
import { toggleBlur } from './modules/toggleBlur'
import { dataLoading } from './modules/dataLoading'
import { utility } from './modules/utility'
import { dirtyForm } from './modules/dirtyForm'
import { reducer as form } from 'redux-form'
import { procuratore } from './modules/procuratore'
import { prospectPersonaGiuridica } from './modules/personaGiuridica/prospect'
import { anagraficaPersonaGiuridicaCliente } from './modules/personaGiuridica/client'
import { reducer as derogaCommissionale } from './modules/derogaCommissionale'

export const makeRootReducer = asyncReducers => {
  return combineReducers({
    customers,
    services,
    dominio,
    dashboard,
    survey,
    privacy,
    validation,
    dataLoading,
    toggleBlur,
    utility,
    pratica,
    dirtyForm,
    derogaCommissionale,
    form,
    procuratore,
    prospectPersonaGiuridica,
    anagraficaPersonaGiuridicaCliente,
    ...asyncReducers
  })
}

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

  store.asyncReducers[key] = reducer
  store.replaceReducer(makeRootReducer(store.asyncReducers))
}

export default makeRootReducer
