import * as Forms from '../../store/modules/forms/constants'

export default {
  [Forms.DASHBOARD]: {
    SUBJECT: {
      id: 'subjectType',
      defaultValue: '',
      label: 'Tipologia soggetto'
    },
    CLIENT: {
      id: 'clientType',
      defaultValue: '',
      label: 'Tipologia soggetto'
    },
    PROFILE: {
      id: 'profile',
      defaultValue: '',
      label: 'Profilo '
    },
    TIMESPAN: {
      id: 'timespan',
      defaultValue: '',
      label: 'Periodo di riferimento'
    }
  }
}
