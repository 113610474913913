import { connect } from 'react-redux'
import moment from 'moment'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import { notBelongToDominio, loadData, loadDefaultFields } from '../../utils'
import { ANTIRICICLAGGIO } from '../../../../store/modules/forms/constants'
import AntiRiciclaggio from './AntiRiciclaggioComponent'
import allFields from '../../fields'

const fields = allFields[1][ANTIRICICLAGGIO]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const { NAZIONE_INSEDIAMENTO, PROVINCIA_ATTIVITA, ANNO_INIZIO } = fields

  if (
    !!values[NAZIONE_INSEDIAMENTO.id] &&
    notBelongToDominio(domini, NAZIONE_INSEDIAMENTO.dominio, values[NAZIONE_INSEDIAMENTO.id])
  ) {
    errors[NAZIONE_INSEDIAMENTO.id] = 'Nessun risultato trovato'
  }
  if (
    !!values[PROVINCIA_ATTIVITA.id] &&
    notBelongToDominio(domini, PROVINCIA_ATTIVITA.dominio, values[PROVINCIA_ATTIVITA.id])
  ) {
    errors[PROVINCIA_ATTIVITA.id] = 'Nessun risultato trovato'
  }
  /* NELL'IF ABBIAMO UN OR TRA DUE CONDIZIONI, NELLE QUALI GESTIAMO LE SITUAZIONI DI ERRORE. NELLA PRIMA CONDIZIONE MANDIAMO IN ERRORE SE LA STRINGA  HA LUNGHEZZA MINORE DI 4 E CON PARSEINT GESTIAMO LA STRINGA VUOTA,  NELLA SECONDA CONDIZIONE ANDIAMO A GESTIRE IL CASO IN CUI L'UTENTE INSERISCA STRINGA DI LETTERE */
  const format = moment()
    .year(values[ANNO_INIZIO.id])
    .format('YYYY')
  if ((!!parseInt(format) && values[ANNO_INIZIO.id].length < 4) || isNaN(values[ANNO_INIZIO.id])) {
    errors[ANNO_INIZIO.id] = 'Data non valida'
  }
  return errors
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadData(state.survey.amlPreviousForm, fields)
  },
  formValues: getFormValues(ANTIRICICLAGGIO)(state),
  formErrors: getFormSyncErrors(ANTIRICICLAGGIO)(state),
  fields,
  dataNascitaCF: state.customers.customer.dataNascita || state.customers.dataRegistry.dataNascita,
  isDirty: isDirty(ANTIRICICLAGGIO)(state),
  isFetching: state.survey.amlSaveRequest.fetching,
  apiError: state.survey.amlSaveRequest.error,
  apiSuccess: state.survey.amlSent
}))(
  reduxForm({
    form: ANTIRICICLAGGIO,
    validate,
    shouldError: () => isDirty(ANTIRICICLAGGIO)
  })(AntiRiciclaggio)
)
