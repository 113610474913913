import React, { Component } from 'react'
import DerogaCommissionaleForm from '../../Form/DerogaCommissionale'
import FooterDeroga from '../../FooterDeroga'
import { Row, Col } from 'react-materialize'
import PropTypes from 'prop-types'

class ModalDerogaCommissionale extends Component {
  render() {
    const { title, close, submitHandler, isValid } = this.props
    return (
      <div className="onboarding-modale">
        <div className="onboarding-modale-content">
          <Row>
            <Col
              s={12}
              className="center-align onboarding-modale-content__title"
              style={{ marginTop: '-20px', marginBottom: '30px' }}>
              {title}
            </Col>
            <Col s={12} className="center-align">
              <DerogaCommissionaleForm />
            </Col>
          </Row>
          <FooterDeroga isValid={isValid} submitHandler={submitHandler} backHandler={close} isModal />
        </div>
      </div>
    )
  }
}
export default ModalDerogaCommissionale

ModalDerogaCommissionale.propTypes = {
  title: PropTypes.string,
  close: PropTypes.func,
  submitHandler: PropTypes.func,
  isValid: PropTypes.bool
}
