import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Input } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { CLIENTI_PROFESSIONALI } from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'

const fields = allFields[0][CLIENTI_PROFESSIONALI]

class ClienteProfessionaleReview extends Component {
  render() {
    const { TYPE_PROFESSIONAL, CLIENTI_PROFESSIONALI_SU_RICHIESTA, IS_PROFESSIONAL_SUBJECT_DONE } = fields
    const { data } = this.props

    return (
      <CollapsibleItem
        header={dataSB.acquisizione.clienteProfessionale.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={CLIENTI_PROFESSIONALI}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={12}>
            {typeof data[IS_PROFESSIONAL_SUBJECT_DONE.id] !== 'undefined' &&
              data[TYPE_PROFESSIONAL.id] === 'RICHIESTA' && (
                <Input
                  name={IS_PROFESSIONAL_SUBJECT_DONE.id}
                  checked
                  type={'checkbox'}
                  label={CLIENTI_PROFESSIONALI_SU_RICHIESTA.label}
                  disabled
                />
              )}
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}

export default ClienteProfessionaleReview

ClienteProfessionaleReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object
}
