import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { CODICE_FISCALE } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import InputImage from '../../../../components/Inputs/Image'

class CodiceFiscaleReview extends React.Component {
  render() {
    const { data } = this.props
    return (
      <CollapsibleItem
        header="Codice fiscale"
        icon="keyboard_arrow_down"
        itemKey={CODICE_FISCALE}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={6}>
            <ReadOnlyInput label="Codice fiscale" value={data.codiceFiscale} />
          </Col>
          {/* <Col s={4}>
            {data.flagCustomerIsBlacklist ? (
              <span className="privacyAccepted blacklist-align">
                <Icon className="blacklist-alert">warning</Icon>
                CF presente in Blacklist
              </span>
            ) : (
              <span className="privacyAccepted blacklist-align">
                <i className="icon-check-codice-fiscale-success" />
                CF non presente in Blacklist
              </span>
            )}
          </Col> */}
        </Row>
        <Row>
          <Col s={6}>
            <InputImage
              readOnly
              label={
                <span>
                  <b>FRONTE</b> documento*
                </span>
              }
              fileIn={data.imgCFFronte}
            />
          </Col>
          <Col s={6}>
            <InputImage
              readOnly
              label={
                <span>
                  <b>RETRO</b> documento*
                </span>
              }
              fileIn={data.imgCFRetro}
            />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}
export default CodiceFiscaleReview

CodiceFiscaleReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object
}
