import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Footer from '../../../components/Footer'
import { Row, Col, Button, Table } from 'react-materialize'
// import AsideUsers from '../../../components/Aside/Users'
import staticData from '../../../static-data/data-main.json'
import HomeFilters from './HomeFilters'
import ServiceCard from './ServiceCard'
import ModalePopup from '../../../components/Modals/Popup'
import ModalFirma from '../../../components/Modals/Firma'
import ModalGeneric from '../../../components/ModalGeneric'
import Tutorial from './Tutorial'
import $ from 'jquery'
import { setRandomStr, setPromoterId } from '../../ConsulenzaBaseContainerLayout/utils'

// import { updateStatoPratica } from '../../../store/modules/pratica/actions'

class HomeComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      filtersVisibility: true,
      idIncarico: null,
      url: null,
      items: 20,
      showModal: false,
      showModalFirma: false,
      showModalGeneric: false,
      text: 'Sei sicuro di voler eliminare la pratica?',
      subText: 'SubTest',
      callback: () => {},
      forceToggle: false,
      idStatus: null,
      idTipologia: null,
      tutorial: false
    }

    this.onFiltersVisibilityChange = this.onFiltersVisibilityChange.bind(this)
    this.getPratica = this.getPratica.bind(this)
    this.deletePratica = this.deletePratica.bind(this)
    this.updateCondition = this.updateCondition.bind(this)
  }

  onFiltersVisibilityChange() {
    this.setState({
      filtersVisibility: !this.state.filtersVisibility
    })
  }

  componentDidMount() {
    setRandomStr()

    this.refs.iScroll.addEventListener('scroll', () => {
      this.setState({ forceToggle: !this.state.forceToggle })
      if (
        this.refs.iScroll &&
        this.refs.iScroll.scrollTop + this.refs.iScroll.clientHeight >= this.refs.iScroll.scrollHeight
      ) {
        this.loadMoreItems()
      }
    })

    // #FIX BEGIN ERROR 400
    if (this.props.history.location.search.startsWith('?token=')) {
      this.props.history.replace('/')
    }
    // #FIX END ERROR 400
  }

  loadMoreItems() {
    this.updateCondition(null, null, true)
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.deletePraticaRequest &&
      this.props.deletePraticaRequest &&
      prevProps.deletePraticaRequest.fetching &&
      !this.props.deletePraticaRequest.fetching &&
      !this.props.deletePraticaRequest.error
    ) {
      const {
        query: { conditions = [] }
      } = this.props
      if (conditions.find(c => c.field === 'idCustomer')) {
        this.updateCondition('idCustomer', '')
      } else {
        this.updateCondition()
      }
      this.setState({ showModal: false })
    }
    if (
      prevProps.serviceSearchRequest &&
      this.props.serviceSearchRequest &&
      prevProps.serviceSearchRequest.fetching &&
      !this.props.serviceSearchRequest.fetching &&
      !this.props.serviceSearchRequest.error
    ) {
      setPromoterId(this.props.idPromotore)
    }

    if (
      prevProps.praticaIntestatariRequest &&
      this.props.praticaIntestatariRequest &&
      prevProps.praticaIntestatariRequest.fetching &&
      !this.props.praticaIntestatariRequest.fetching &&
      !this.props.praticaIntestatariRequest.error
    ) {
      if (this.props.flagFirmaPraticaInvestimento) {
        this.props.history.push(this.getUrl(this.state.idStatus, this.state.idTipologia, this.state.idIncarico))
      } else {
        this.setState({ showModalGeneric: true, modalText: 'text' })
      }
    }

    if (!prevState.tutorial && this.state.tutorial) {
      $('.slider').height('100%')
      $('.slides').height('85%')
    }
  }

  goToModulistica(idIncarico, idStatus, services, history) {
    const _service = services.find(v => v.id === idIncarico)
    switch (idStatus) {
      case 2:
      case 17:
      case 18:
        this.props.setIdPromotore()
        if (_service.flagFirmaCartacea) {
          history.push(`/firma-cartacea/${idIncarico}?module=ar`)
        } else {
          history.push(`/firma-digitale/${idIncarico}?module=ar`)
        }
        break
      case 3:
        if (_service.adeguataVerificaAvailable) {
          this.props.getPratica(idIncarico)
          if (!_service.customers[0].nome) {
            history.push(`/adeguata-verifica-PG?module=ar`)
          } else {
            history.push(`/adeguata-verifica?module=ar`)
          }
        }
        break
      default:
        history.push(`/aggiornamento-modulistica/compilazione-antiriciclaggio/${idIncarico}`)
        break
    }
  }

  getPratica(idIncarico, idStatus, idTipologia, descTipologia, adeguataVerificaAvailable) {
    const { admin, history, getPraticaIntestatari, services } = this.props

    if (!adeguataVerificaAvailable && idStatus === 3) {
      this.setState({ showModalGeneric: true, modalText: 'av' })
    } else if (admin && (idStatus === 2 || idStatus === 3)) {
      this.setState({ showModalGeneric: true, modalText: 'firma' })
    } else if (idTipologia === 'AGGIORNAMENTO_MODULISTICA' || descTipologia === 'Antiriciclaggio') {
      this.goToModulistica(idIncarico, idStatus, services, history)
    } else if (idStatus !== 2 || (idTipologia === 'CONSULENZA' || descTipologia === 'Onboarding Persona Giuridica')) {
      history.push(this.getUrl(idStatus, idTipologia, idIncarico, descTipologia))
    } else {
      getPraticaIntestatari(idIncarico)
      this.setState({ idIncarico, idStatus, idTipologia, descTipologia })
    }
  }

  deletePratica(pratica) {
    let subText = null
    if (pratica.idStatus > 1 && pratica.idTipologia === 'CONSULENZA') {
      const ref = `${pratica.customers[0].nome} ${pratica.customers[0].cognome}`
      subText = (
        <Fragment>
          <br />
          {`Eliminando questa pratica saranno eliminate tutte le pratiche in cui ${ref} è presente come Componente.`}
        </Fragment>
      )
    }
    this.setState({
      showModal: true,
      subText,
      callback: () => this.props.deletePratica(pratica.id)
    })
  }

  getFlagFirmaCartacea(_idIncarico) {
    let flag
    this.props.services.reduce((result, current) => {
      if (current.id === _idIncarico) {
        flag = current.flagFirmaCartacea
      }
      return result
    }, [])
    return flag
  }

  getUrl(idStatus, idTipologia, idIncarico, descTipologia) {
    const { getPratica, setIdPromotore } = this.props

    switch (idStatus) {
      // TODO sarà 0
      case 0:
        switch (descTipologia) {
          case 'Onboarding Persona Giuridica':
            return `/consulenza-base-PG/${idIncarico}/data-gathering`
          default:
            return `/consulenza-base/${idIncarico}/data-gathering`
        }
      case 1:
        switch (idTipologia) {
          case 'CONSULENZA':
            switch (descTipologia) {
              case 'Onboarding Persona Giuridica':
                return `/consulenza-base-PG/${idIncarico}/acquisizione-documenti`
              default:
                return `/consulenza-base/${idIncarico}/acquisizione-documenti`
            }
          case 'CONSULENZA_GIURIDICA':
            return `/consulenza-base-PG/${idIncarico}/acquisizione-documenti`
          case 'INVESTIMENTO':
            switch (descTipologia) {
              case 'Onboarding Persona Giuridica':
                return `/consulenza-base-PG/${idIncarico}/data-gathering`
              default:
                this.props.setIdPratica(idIncarico)
                return '/apertura-servizi/configurazione/' + idIncarico
            }
        }
        break
      case 2:
        setIdPromotore()
        let flag = this.getFlagFirmaCartacea(idIncarico)
        switch (flag) {
          case true:
            this.props.setPraticaCorrente(idIncarico)
            return `/firma-cartacea/${idIncarico}`
          case false:
            return `/firma-digitale/${idIncarico}`
          default:
            return `/consulenza-base/${idIncarico}/riepilogo-cliente`
        }
      case 3:
        switch (idTipologia) {
          case 'CONSULENZA':
          case 'INVESTIMENTO':
            getPratica(idIncarico)
            return '/adeguata-verifica'
          case 'CONSULENZA_GIURIDICA':
            getPratica(idIncarico)
            return '/adeguata-verifica-PG'
        }
    }
  }

  updateCondition(field, value, page) {
    const updatePageSize = 20
    const { query, servicesSearch, pageSize, setPageSize, totServizi, setTotServizi } = this.props
    const conditions = query.conditions.reduce((res, cur) => {
      if (cur.field === field) return res
      return [...res, cur]
    }, [])

    if (value) {
      if (Array.isArray(value)) {
        value.forEach(v => conditions.push({ field, value: v }))
      } else {
        conditions.push({ field, value })
      }
    }
    let pSize = pageSize + updatePageSize
    if (!page) {
      setTotServizi(null)
      pSize = 20
    } else {
      pSize = pageSize + updatePageSize
    }
    query.pageBy = {
      pageRequest: 1,
      pageSize: pSize
    }

    setPageSize(pSize)
    if (pSize <= totServizi + 19 || !page) {
      servicesSearch({
        ...query,
        conditions
      })
    } else if (pSize > totServizi + 19) {
      setPageSize(totServizi)
    }
  }

  render() {
    const {
      servicesSearch,
      services,
      totServizi,
      query,
      serviceSearchRequest: { fetching },
      clienti,
      pageSize,
      flagAperturaServizi
    } = this.props

    return (
      <Row className="container-home is-home paddingless marginless">
        {this.state.showModalFirma && (
          <ModalFirma
            show={this.state.showModalFirma}
            handler={() => this.setState({ showModalFirma: false })}
            title={staticData.check_intestatari.title}
            text={staticData.check_intestatari.text}
            idIncarico={this.state.idIncarico}
            resetSelectedState={() => this.setState({ showModalFirma: false })}
            proseguiDisabled
          />
        )}
        {this.state.showModalGeneric && (
          <ModalGeneric
            show
            handler={() => this.setState({ showModalGeneric: false })}
            title={staticData.check_intestatari.title}
            text={staticData.check_intestatari[this.state.modalText]}
          />
        )}
        <ModalePopup
          visible={this.state.showModal}
          title="Attenzione!"
          text={this.state.text}
          subText={this.state.subText}
          primaryActionLabel="Continua"
          secondaryActionLabel="Annulla"
          secondaryAction={() => this.setState({ showModal: false })}
          primaryAction={this.state.callback}
        />
        <Col s={12} className={`container-home-left${this.state.tutorial ? ' full-container' : ''}`}>
          <div className="home-scroll">
            {!this.state.tutorial && (
              <HomeFilters
                servicesSearch={servicesSearch}
                updateCondition={this.updateCondition}
                pageSize={pageSize}
                query={query}
                clienti={clienti}
              />
            )}

            {/* {<button onClick={() => this.props.updateStatoPratica('286_PG', 1)}>RESET</button>} */}
            <div className={`vc${this.state.tutorial ? ' tutorial' : ''}`} ref="iScroll">
              {this.state.tutorial && <Tutorial />}
              {!this.state.tutorial && (
                <ul>
                  {services.length === 0 && query.conditions.length === 0 && !fetching && (
                    <Row key="add-service">
                      <Col className="suggestion-card s10 offset-s1">
                        <img className="iconographic-folder" src="img/folder.png" />
                        In questa fase puoi:
                        <br />- accedere a una versione demo per esplorare tutte le funzionalità cliccando su "Scopri le
                        funzioni"
                        <br />- aggiungere un nuovo servizio base cliccando sull'icona in basso a sinistra
                        <Button flat waves="light" onClick={() => this.setState({ tutorial: true })}>
                          Scopri le funzioni
                        </Button>
                        <img className="freccia" src="img/freccia.png" />
                      </Col>
                    </Row>
                  )}
                  {services.length === 0 && query.conditions.length > 0 && (
                    <p className="nosearch">Nessun risultato trovato</p>
                  )}
                  {services.length !== 0 && (
                    <Fragment>
                      {/* <Button
                        className="btn primary"
                        onClick={() => {
                          this.props.updateStatoPratica('733_PG', 1)
                        }}>
                        RESET
                      </Button> */}
                      <Col s={12}>
                        <div className="totaleServizi">{totServizi} pratiche</div>
                        <Table>
                          <thead>
                            <tr>
                              <th data-field="data_inserimento">Data inserimento</th>
                              <th data-field="id_pratica">ID pratica</th>
                              <th data-field="stato">Stato</th>
                              <th data-field="Servizio">Servizio</th>
                              <th data-field="modalita_firma">Modalità di Firma</th>
                              <th data-field="componenti">Componenti</th>
                              <th data-field="action_button" />
                              <th data-field="action_delete" />
                            </tr>
                          </thead>

                          <tbody>
                            {services.map((service, i) => (
                              <ServiceCard
                                toggleBlur={this.props.toggleBlur}
                                key={i}
                                service={service}
                                callBack={this.getPratica}
                                deletePratica={this.deletePratica}
                              />
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Fragment>
                  )}
                </ul>
              )}
            </div>
          </div>
          <Footer forceToggle={this.state.forceToggle} flagAperturaServizi={flagAperturaServizi} />
        </Col>
        {/* <Col s={3} className={`paddingless${this.state.tutorial ? ' hide-sidebar' : ''} sidebar-overflow`}>
          <AsideUsers query={query} updateCondition={this.updateCondition} />
                    </Col> */}
      </Row>
    )
  }
}

HomeComponent.propTypes = {
  services: PropTypes.array,
  servicesSearch: PropTypes.func,
  query: PropTypes.object,
  clienti: PropTypes.array,
  history: PropTypes.object,
  toggleBlur: PropTypes.bool,
  setPageSize: PropTypes.func,
  pageSize: PropTypes.number,
  deletePratica: PropTypes.func,
  deletePraticaRequest: PropTypes.object,
  totServizi: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  flagAperturaServizi: PropTypes.bool,
  setTotServizi: PropTypes.func,
  getPraticaIntestatari: PropTypes.func,
  praticaIntestatariRequest: PropTypes.object,
  flagFirmaPraticaInvestimento: PropTypes.object,
  serviceSearchRequest: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.any
  }),
  getPratica: PropTypes.func,
  setPraticaCorrente: PropTypes.func,
  setIdPratica: PropTypes.func,
  idPromotore: PropTypes.any,
  setIdPromotore: PropTypes.func,
  admin: PropTypes.bool
  // updateStatoPratica: PropTypes.func
  // getFlagFirmaCartacea: PropTypes.func
  // flagFirmaPraticaInvestimento: PropTypes.bool
}

export default HomeComponent
