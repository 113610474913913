import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-materialize'

const FooterDeroga = ({
  hideBack = false,
  backHandler = () => {},
  backClass = 'custom-btn primary-reverse transparent',
  backLabel = 'Annulla',
  submitClass = 'btn custom-btn primary',
  submitLabel = 'Aggiungi deroga',
  submitHandler = () => {},
  isValid = true,
  isModal = false
}) => (
  <Fragment>
    <div className={`Footer ${hideBack ? ' hide-back' : ''}`}>
      {!hideBack && (
        <Button className={backClass} onClick={backHandler}>
          {backLabel}
        </Button>
      )}
      <Button
        className={submitClass}
        disabled={!isValid}
        onClick={() => {
          submitHandler()
          isModal && backHandler()
        }}>
        {submitLabel}
      </Button>
    </div>
  </Fragment>
)
FooterDeroga.propTypes = {
  isValid: PropTypes.bool,
  submitHandler: PropTypes.func,
  hideBack: PropTypes.bool,
  backHandler: PropTypes.func,
  backClass: PropTypes.string,
  backLabel: PropTypes.string,
  submitClass: PropTypes.string,
  submitLabel: PropTypes.string,
  isModal: PropTypes.bool
}

export default FooterDeroga
