import * as actionTypes from './constants'

import axios from 'axios'

export function getFirma(idIncarico, randomStr) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.UTILITY_GET_FIRMA_START
    })
    try {
      const response = await axios.get(`utility/docfirma/sign?idIncarico=${idIncarico}&tokenRedirect=${randomStr}`)
      dispatch({
        type: actionTypes.UTILITY_GET_FIRMA_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.UTILITY_GET_FIRMA_ERROR,
        error
      })
    }
  }
}

export function checkDownloadZip(idIncarico, randomStr) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.CHECK_DOWNLOAD_ZIP_START
    })
    try {
      const response = await axios.get(`v2/utility/print-paper/checkDownload/${idIncarico}`)
      dispatch({
        type: actionTypes.CHECK_DOWNLOAD_ZIP_SUCCESS,
        checkDownload: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.CHECK_DOWNLOAD_ZIP_ERROR,
        error
      })
    }
  }
}

// axios({
//   url: `v2/utility/print-paper/download/${idIncarico}`,
//   method: 'GET',
//   responseType: 'blob', // important
// }).then((response) => {
//   const url = window.URL.createObjectURL(new Blob([response.data]));
//   const link = document.createElement('a');
//   link.href = url;
//   link.setAttribute('download', 'file.pdf');
//   document.body.appendChild(link);
//   link.click();
// });

export function getIdProspectByIdProcess(idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_ID_PROSPECT_BY_ID_PROCESS_START
    })
    try {
      const response = await axios.get(`/v2/utility/getIdProspectByIdProcess/${idPratica}`)
      dispatch({
        type: actionTypes.GET_ID_PROSPECT_BY_ID_PROCESS_SUCCESS,
        pDob: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ID_PROSPECT_BY_ID_PROCESS_ERROR,
        error
      })
    }
  }
}

export function downloadZip(idIncarico) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.DOWNLOAD_ZIP_START
    })
    try {
      axios({
        url: `v2/utility/print-paper/download/${idIncarico}`,
        method: 'GET',
        responseType: 'blob' // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'azimut-download.zip')
        document.body.appendChild(link)
        link.click()
        dispatch({
          type: actionTypes.DOWNLOAD_ZIP_SUCCESS,
          error: null
        })
      })
      // const response = await axios.get(`v2/utility/print-paper/download/${idIncarico}`)
      // dispatch(window.open(response.data))
    } catch (error) {
      dispatch({
        type: actionTypes.DOWNLOAD_ZIP_ERROR,
        error
      })
    }
  }
}

export function downloadPDF(idCliente, idIncarico, listaDomande) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.DOWNLOAD_ZIP_START
    })
    try {
      axios({
        url: `/utility/docfirma/sign/adegverifica/`,
        method: 'POST',
        contenType: 'application/pdf',
        data: { idCliente, idIncarico, listaDomande },
        responseType: 'blob' // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'azimut-av.pdf')
        document.body.appendChild(link)
        link.click()
        dispatch({
          type: actionTypes.DOWNLOAD_ZIP_SUCCESS,
          error: null
        })
      })
      // const response = await axios.get(`v2/utility/print-paper/download/${idIncarico}`)
      // dispatch(window.open(response.data))
    } catch (error) {
      dispatch({
        type: actionTypes.DOWNLOAD_ZIP_ERROR,
        error
      })
    }
  }
}

export function setPraticaCorrente(idIncarico) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRATICA_CORRENTE_FIRMA,
      idIncarico: idIncarico
    })
  }
}

export function setSigned(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.UTILITY_SET_SIGNED_START
    })
    try {
      const response = await axios.post(`/service-sign/sign`, payload)
      dispatch({
        type: actionTypes.UTILITY_SET_SIGNED_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.UTILITY_SET_SIGNED_ERROR,
        error: error
      })
    }
  }
}

export function saveDocument(idIncarico) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.UTILITY_SAVE_DOC_START
    })
    try {
      const response = await axios.get(`utility/docfirma/save?idIncarico=${idIncarico}`)
      dispatch({
        type: actionTypes.UTILITY_SAVE_DOC_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.UTILITY_SAVE_DOC_ERROR,
        error
      })
    }
  }
}

export function getNamirialUrl(idCliente) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.UTILITY_GET_NAMIRIAL_URL_START
    })
    try {
      const response = await axios.get(`utility/getNamirialUrl?idPratica=${idCliente}`)
      dispatch({
        type: actionTypes.UTILITY_GET_NAMIRIAL_URL_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.UTILITY_GET_NAMIRIAL_URL_ERROR,
        error
      })
    }
  }
}

export function interventionRequest(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.RICHIESTA_IN_SEDE_START
    })
    try {
      const response = await axios.post('utility/headquarters-intervention', payload)
      dispatch({
        type: actionTypes.RICHIESTA_IN_SEDE_SUCCESS,
        isSend: response.data.code
      })
    } catch (error) {
      dispatch({
        type: actionTypes.RICHIESTA_IN_SEDE_ERROR,
        error: error
      })
    }
  }
}

export function postAddictionalOwner(idPhysicalPerson, payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_ADDITIONAL_OWNER_START
    })
    try {
      const response = await axios.post(`/v2/legal-person/reference/addictional-owner/${idPhysicalPerson}`, payload)
      dispatch({
        type: actionTypes.POST_ADDITIONAL_OWNER_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_ADDITIONAL_OWNER_ERROR,
        error: error
      })
    }
  }
}

export function getAddictionalOwner(idPhysicalPerson) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_ADDITIONAL_OWNER_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/reference/addictional-owner/${idPhysicalPerson}`)
      dispatch({
        type: actionTypes.GET_ADDITIONAL_OWNER_SUCCESS,
        additionalOwnerList: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ADDITIONAL_OWNER_ERROR,
        error: error
      })
    }
  }
}

export function deleteAddictionalOwner(id) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.DELETE_ADDITIONAL_OWNER_START
    })
    try {
      await axios.delete(`/v2/legal-person/reference/addictional-owner/${id}`)
      dispatch({
        type: actionTypes.DELETE_ADDITIONAL_OWNER_SUCCESS
        // additionalOwnerList: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.DELETE_ADDITIONAL_OWNER_ERROR,
        error: error
      })
    }
  }
}

export function getDataFromTaxCode(tacCode, counter) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DATA_FROM_TAX_CODE_START
    })
    try {
      const response = await axios.get(`utility/get-data-from-tax-code?codiceFiscale=${tacCode}`)
      dispatch({
        type: actionTypes.GET_DATA_FROM_TAX_CODE_SUCCESS,
        dataFromTaxCode: response.data,
        cf: tacCode,
        counter: counter
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DATA_FROM_TAX_CODE_ERROR,
        error: error
      })
    }
  }
}

export function setEnabledTitolare(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_TITOLARE,
      titolare: value
    })
  }
}

export function setMultidTitolare(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_TITOLARE_MULTI,
      titolareMulti: value
    })
  }
}

export function setEnabledPep(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_PEP,
      titolare: value
    })
  }
}

export function setMultidPep(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_PEP_MULTI,
      titolare: value
    })
  }
}

export const resetFlagDownload = () => {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.RESET_DOWNLOADED
    })
  }
}

export function downloadDraftDocs(idIncarico) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.DOWNLOAD_DOCS_START
    })
    try {
      const response = await axios.get(`/utility/print-paper/preview/${idIncarico}`)
      if (response.data.previewDocument) {
        const linkSource = `data:application/pdf;base64,${response.data.previewDocument}`
        const downloadLink = document.createElement('a')
        const fileName = 'Modulo di aggiornamento questionario Antiriciclaggio.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
      dispatch({
        type: actionTypes.DOWNLOAD_DOCS_SUCCESS,
        error: null
      })
    } catch (error) {
      dispatch({
        type: actionTypes.DOWNLOAD_DOCS_ERROR,
        error
      })
    }
  }
}

export function richiamaPratica(idPratica, randomStr) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.RICHIAMA_PRATICA_START })
    try {
      const response = await axios.get(`/utility/docfirma/retire/${idPratica}?tokenRedirect=${randomStr}`)
      dispatch({
        type: actionTypes.RICHIAMA_PRATICA_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({ type: actionTypes.RICHIAMA_PRATICA_ERROR, error })
    }
  }
}

export function praticaToSignRoom(idPratica) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.PRATICA_TO_SIGN_ROOM_START })
    try {
      const response = await axios.get(`/utility/docfirma/sign/createBundleAml?idIncarico=${idPratica}`)
      dispatch({
        type: actionTypes.PRATICA_TO_SIGN_ROOM_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({ type: actionTypes.PRATICA_TO_SIGN_ROOM_ERROR, error })
    }
  }
}

export function downloadSignModule(idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.DOWNLOAD_SIGN_MODULE_START
    })
    try {
      const response = await axios.get(`/utility/downloadSignModule/${idPratica}`)
      if (response.data.module) {
        const linkSource = `data:application/pdf;base64,${response.data.module}`
        const downloadLink = document.createElement('a')
        const fileName = 'Modulo di firma.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      }
      dispatch({
        type: actionTypes.DOWNLOAD_SIGN_MODULE_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: actionTypes.DOWNLOAD_SIGN_MODULE_ERROR,
        error: error
      })
    }
  }
}
