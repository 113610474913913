import { connect } from 'react-redux'
import { MIFID_ALTRO, MIFID_ALTRO_LIGHT } from '../../../../store/modules/forms/constants'
import { loadMifidFields } from '../../utils'
import { reduxForm, formValueSelector, isDirty } from 'redux-form'
import allFields from '../../fields'
import AltreInformazioni from './AltreInformazioniComponent'

const fields = allFields[2][MIFID_ALTRO]
const fieldsLight = allFields[2][MIFID_ALTRO_LIGHT]
const selector = formValueSelector(MIFID_ALTRO)
const selectorLight = formValueSelector(MIFID_ALTRO_LIGHT)

const loadMifidData = (previousForm, template) => {
  if (!previousForm || !previousForm.listaDomande || !Array.isArray(previousForm.listaDomande)) {
    return {}
  }
  return template.listaDomande.reduce((res, cur) => {
    const prevDomanda = previousForm.listaDomande.find(el => el.idDomanda === cur.idDomanda)
    if (!prevDomanda) {
      return { ...res, [`field_${cur.idDomanda}`]: '' }
    }
    //
    if (
      cur.idDomanda === fields.RICCHEZZA_NON_INVESTITA.templateId ||
      cur.idDomanda === fieldsLight.RICCHEZZA_NON_INVESTITA.templateId
    ) {
      return {
        ...res,
        ...prevDomanda.listaRisposte.reduce((r, c) => ({ ...r, [`field_${c.idRisposta}`]: c.descRisposta }), {})
      }
    }
    return {
      ...res,
      [`field_${cur.idDomanda}`]: '' + prevDomanda.listaRisposte[0].idRisposta
    }
  }, {})
}

const mapStateToProps = (state, ownProps) => {
  let toProps = {}

  // Richiesta Mifid Light
  if (state.survey.professionalSubjectType === 'DI_DIRITTO' && ownProps.isProspectPersonaGiuridica) {
    const templateLight = state.survey.template.sezioni.find(sezione => sezione.idSezione === MIFID_ALTRO_LIGHT)
    toProps = {
      form: MIFID_ALTRO_LIGHT,
      initialValues: {
        ...loadMifidFields(fieldsLight, templateLight),
        ...loadMifidData(state.survey.previousForm, templateLight)
      },
      formValues: {
        ...loadMifidFields(fieldsLight, templateLight),
        ...selectorLight(
          state,
          ...Object.keys(loadMifidFields(fieldsLight, templateLight)).reduce((res, cur) => [...res, cur], [])
        )
      },
      isDirty: isDirty(MIFID_ALTRO_LIGHT)(state),
      isFetching: state.survey.mifidSaveRequest.fetching,
      apiError: state.survey.mifidSaveRequest.error
    }
  } else {
    const template = state.survey.template.sezioni.find(sezione => sezione.idSezione === MIFID_ALTRO)
    toProps = {
      form: MIFID_ALTRO,
      initialValues: {
        ...loadMifidFields(fields, template),
        ...loadMifidData(state.survey.previousForm, template)
      },
      formValues: {
        ...loadMifidFields(fields, template),
        ...selector(state, ...Object.keys(loadMifidFields(fields, template)).reduce((res, cur) => [...res, cur], []))
      },
      isDirty: isDirty(MIFID_ALTRO)(state),
      isFetching: state.survey.mifidSaveRequest.fetching,
      apiError: state.survey.mifidSaveRequest.error
    }
  }
  return toProps
}
export default connect(mapStateToProps)(reduxForm({})(AltreInformazioni))
