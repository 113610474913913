import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { Field } from 'redux-form'
import { Row, Col } from 'react-materialize'
import AccordionHeader from '../../../../components/AccordionHeader'
import { CollapsibleItem } from '../../../../components/Collapsible'
import InputText from '../../../../components/Inputs/Text'
// import dataSB from '../../../../static-data/data-servizio-base.json'
import { SCHEDA_DATI } from '../../../../store/modules/forms/constants'

import SchedaDatiPep from '../SchedaDatiPep'

import {
  getDominio,
  getAutocompleteDefaultOption,
  mapDominiToAutocomplete,
  mapDominiToOptions,
  getValueDominioFromKey
} from '../../utils'

import {
  renderSelect,
  renderDatePicker,
  renderAutocomplete,
  renderYesNoQuestion
} from '../../../../components/Forms/renderFields'
import ButtonSubmit from '../../../../components/Buttons/Submit'

class SchedaDati extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      test: true,
      personaEsposta: null,
      pepEstero: null,
      pepNazionale: null,
      isOwner: null,
      isUniqueOwner: null,
      titolareeffettivo: false,
      counter: 0,
      firstTime: true,
      waitingCap: false
    }
    this.submit = this.submit.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
    this.checkNazione = this.checkNazione.bind(this)
    this.checkNazioneNascita = this.checkNazioneNascita.bind(this)

    this.getOwner = this.getOwner.bind(this)
    // this.checkvisibilityFascia = this.checkvisibilityFascia.bind(this)
  }
  componentDidMount() {
    // console.log(this.props.initialValues)
    const { SETTORE_OCCUPAZIONE, NAZIONE_SCHEDA_DATI, CITTADINANZA_SCHEDA_DATI } = this.props.fields
    const payload = {
      listaDomini: [
        {
          idDominio: SETTORE_OCCUPAZIONE.dominio
        },
        {
          idDominio: NAZIONE_SCHEDA_DATI.dominio
        },
        {
          idDominio: CITTADINANZA_SCHEDA_DATI.dominio
        }
      ]
    }
    // const { idPhysicalPersonReference } = this.props
    this.props.dominioCustomRequest(payload)
  }
  componentDidUpdate(prevProps) {
    const {
      fields: { CAP_SCHEDA_DATI },
      change,
      // dominioRequest,
      domini
    } = this.props
    if (!this.props.dominioRequest.fetching && this.state.waitingCap) {
      const capDom = domini.listaDomini.find(d => d.idDominio === CAP_SCHEDA_DATI.dominio)
      if (capDom && Object.keys(capDom.valoriDominio).length === 1) {
        change(CAP_SCHEDA_DATI.id, Object.keys(capDom.valoriDominio)[0])
      }
      this.setState({ waitingCap: false })
    }
    if (prevProps.isFetching && !this.props.isFetching) {
      // this.props.resetForm(SCHEDA_DATI)
    }
  }

  counterTitolare() {
    this.setState({ counter: 1 })
  }

  updateDominio(idDominio, codice) {
    this.props.dominioCustomRequest(idDominio, codice)
    // { idDominio: 'DOM_COMUNE_RESIDENZA_PROC', filtro: { codice: sezioneProcuratore.residenceProvince } }
  }

  checkNazione(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { NAZIONE_SCHEDA_DATI }
    } = this.props
    if (isItalia) {
      return formValues[NAZIONE_SCHEDA_DATI.id] === NAZIONE_SCHEDA_DATI.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, NAZIONE_SCHEDA_DATI.dominio, formValues[NAZIONE_SCHEDA_DATI.id])
  }
  checkNazioneNascita(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { NAZIONE_NASCITA_SCHEDA_DATI }
    } = this.props
    if (isItalia) {
      return formValues[NAZIONE_NASCITA_SCHEDA_DATI.id] === NAZIONE_NASCITA_SCHEDA_DATI.italiaId
    }
    return !!getValueDominioFromKey(
      listaDomini,
      NAZIONE_NASCITA_SCHEDA_DATI.dominio,
      formValues[NAZIONE_NASCITA_SCHEDA_DATI.id]
    )
  }
  checkNazioneIsediamento(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { NAZIONE_ATTIVITA }
    } = this.props
    if (isItalia) {
      return formValues[NAZIONE_ATTIVITA.id] === NAZIONE_ATTIVITA.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, NAZIONE_ATTIVITA.dominio, formValues[NAZIONE_ATTIVITA.id])
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA_SCHEDA_DATI }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, PROVINCIA_SCHEDA_DATI.dominio, value)
  }

  checkComune(value) {
    const {
      domini: { listaDomini },
      fields: { COMUNE_SCHEDA_DATI }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, COMUNE_SCHEDA_DATI.dominio, value)
  }

  checkCap() {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA_SCHEDA_DATI, COMUNE_SCHEDA_DATI, CAP_SCHEDA_DATI },
      formValues
    } = this.props
    return (
      !this.checkNazione(true) ||
      !this.checkProvincia(formValues[PROVINCIA_SCHEDA_DATI.id]) ||
      !this.checkComune(formValues[COMUNE_SCHEDA_DATI.id]) ||
      Object.keys((listaDomini.find(d => d.idDominio === CAP_SCHEDA_DATI.dominio) || {}).valoriDominio || {}).length ===
        1
    )
  }

  getOwner(obj) {
    if (obj === null) {
      return null
    }
    if (obj.idRisposta !== undefined && obj.idRisposta !== null) {
      return obj.idRisposta
    } else {
      return obj
    }
  }

  submit(values) {
    //   const { submitHandler, currentInitial, fields, flagCustomerIsBlacklist } = this.props
    //   const { values, listaAllegati } = parseDocumentsData(fields, submittedValues, currentInitial)
    //   Object.assign(values, { flagCustomerIsBlacklist: !!flagCustomerIsBlacklist })
    //   submitHandler(values, listaAllegati)
    // }
    // console.log('Salva')

    const { initialize, formValues } = this.props

    let obj
    // let _this = this
    Object.keys(values).reduce((res, cur) => {
      if (cur.indexOf('_') > 0) {
        let temp = []
        temp = cur.split('_')
        obj = { ...res, [temp[0]]: formValues[cur] }
        return obj
      } else {
        obj = { ...res, [cur]: formValues[cur] }
        return obj
      }
    }, {})

    // console.log(obj)
    const {
      idPhysicalPersonReference,
      customer: { idCliente, idIncarico }
    } = this.props

    let objTosend = {
      idCliente: idCliente,
      idIncarico: idIncarico,
      clientType: obj.clientType === '' ? null : obj.clientType,
      name: obj.name === '' ? null : obj.name,
      surname: obj.surname === '' ? null : obj.surname,
      businessName: obj.businessName === '' ? null : obj.businessName,
      nationBirthCode: obj.nationBirthCode === '' ? null : obj.nationBirthCode,
      placeBirth: obj.placeBirth === '' ? null : obj.placeBirth,
      taxCode: obj.taxCode === '' ? null : obj.taxCode,
      birthDate: obj.birthDate === '' ? null : obj.birthDate,
      cityBirthCode: obj.cityBirthCode === '' ? null : obj.cityBirthCode,
      cityResidenceCode: obj.cityResidenceCode === '' ? null : obj.cityResidenceCode,
      addressResidenceCode: obj.addressResidenceCode === '' ? null : obj.addressResidenceCode,
      provinceResidenceCode: obj.provinceResidenceCode === '' ? null : obj.provinceResidenceCode,
      nationalResidenceCode: obj.nationalResidenceCode === '' ? null : obj.nationalResidenceCode,
      placeResidence: obj.placeResidence === '' ? null : obj.placeResidence,
      residence: obj.residence === '' ? null : obj.residence,
      citizenship: obj.citizenship === '' ? null : obj.citizenship,
      cap: obj.cap === '' ? null : obj.cap,
      mail: obj.mail === '' ? null : obj.mail,
      phone: obj.phone === '' ? null : obj.phone,
      documentType: obj.documentType === '' ? null : obj.documentType,
      documentNumber: obj.documentNumber === '' ? null : obj.documentNumber,
      placeRelease: obj.placeRelease === '' ? null : obj.placeRelease,
      releaseDate: obj.releaseDate === '' ? null : obj.releaseDate,
      expireDate: obj.expireDate === '' ? null : obj.expireDate,
      studyTitleCode: obj.studyTitleCode === '' ? null : obj.studyTitleCode,
      occupationalSectoreCode: obj.occupationalSectoreCode === '' ? null : obj.occupationalSectoreCode,
      occupationalSectoreCodeOther: obj.occupationalSectoreCodeOther === '' ? null : obj.occupationalSectoreCodeOther,
      // descriptionActivity: obj.descriptionActivity === '' ? null : obj.descriptionActivity,
      descriptionActivity: '-', // questo campo è stato rimosso parzialmente a FE, ma è ancora presente a BE
      nationalActivityCode: obj.nationalActivityCode === '' ? null : obj.nationalActivityCode,
      provinceActivityCode: obj.provinceActivityCode === '' ? null : obj.provinceActivityCode,
      activityYear: obj.activityYear === '' ? null : obj.activityYear,
      jobCode: obj.jobCode === '' ? null : obj.jobCode,
      prevJobCode: obj.prevJobCode === '' ? null : obj.prevJobCode,
      pepType: obj.pepType === '' ? null : obj.pepType,
      isUniqueOwner: this.getOwner(obj.isUniqueOwner),

      isOwner: this.getOwner(obj.isOwner),

      idPhysicalPerson: idPhysicalPersonReference,
      pepInfo: {
        // isIntestatario: obj.isIntestatario === '' ? null : obj.isIntestatario,
        // confermaDiEssereDescrizione: obj.confermaDiEssereDescrizione === '' ? null : obj.confermaDiEssereDescrizione,
        // tipologiaCliente: obj.tipologiaCliente === '' ? null : obj.tipologiaCliente,
        pepInQuanto: obj.pepInQuanto === '' ? null : obj.pepInQuanto,
        pepRelazione: obj.pepRelazione === '' ? null : obj.pepRelazione,
        tipoFasciaRedditoPep: obj.tipoFasciaRedditoPep === '' ? null : obj.tipoFasciaRedditoPep,
        pepPartecipazioni: obj.pepPartecipazioni === '' ? null : obj.pepPartecipazioni,
        pepProcedimenti: obj.pepProcedimenti === '' ? null : obj.pepProcedimenti,
        isTitolareEffettivoPEP: obj.isTitolareEffettivoPEP === '' ? null : obj.isTitolareEffettivoPEP,
        taxCode: obj.isTitolareEffettivoPEP === '' ? null : obj.codiceFiscalePivaPep
      }
    }
    // let _this = this

    this.props.postDataVerificaClienti(objTosend).then(function() {
      // _this.props.resetForm(SCHEDA_DATI)
      initialize(values || formValues)
    })

    this.setState({ saved: true })
  }

  isCompiled(v) {
    const { formValues = {} } = this.props
    return typeof formValues[v.id] !== 'undefined' && formValues[v.id] !== null && formValues[v.id] !== ''
  }
  checkvisibility() {
    let value
    const { formValues, fields } = this.props
    if (formValues[fields.YES_NO_MULTI_OWNER.id] !== null) {
      value = formValues[fields.YES_NO_MULTI_OWNER.id]
    } else if (this.props.dataVerificaClienti.isUniqueOwner !== null) {
      value = this.props.dataVerificaClienti.isUniqueOwner
    } else {
      return ''
    }
    return value
  }
  // checkvisibilityFascia() {
  //   let value
  //   const { formValues, fields } = this.props
  //   if (formValues[fields.YES_NO_OWNER.id] !== null) {
  //     value = formValues[fields.YES_NO_OWNER.id]
  //   } else if(this.props.dataVerificaClienti.isOwner !==null){
  //     value = this.props.dataVerificaClienti.isOwner
  //   }else{
  //     return  ''
  //   }
  //   return !value
  // }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      anyTouched,
      status,
      domini,
      formValues,
      formErrors,
      change,
      touch,
      fields,
      isFetching
      // invalid
    } = this.props
    const {
      // NOME,
      // COGNOME,
      // RAGIONE,
      CODICE_FISCALE,
      CODICE_CLIENTE,
      DATA_NASCITA,
      NAZIONE_NASCITA_SCHEDA_DATI,
      PROVINCIA_NASCITA_ESTERA_SCHEDA_DATI,
      INDIRIZZO_RESIDENZA,
      CAP_SCHEDA_DATI,
      CITTADINANZA_SCHEDA_DATI,
      EMAIL,
      CELLULARE,
      TIPO_DOCUMENTO,
      ESTREMI_DOCUMENTO,
      DATA_RILASCIO,
      DATA_SCADENZA,
      LUOGO_RILASCIO,
      TITOLO_STUDIO,
      SETTORE_OCCUPAZIONE,
      SETTORE_OCCUPAZIONE_ALTRO,
      PROFESSIONE_ATTUALE,
      PROFESSIONE_PRECEDENTE,
      TIPO_CLIENTE,
      // INTRATTENERE_PEP,

      NAZIONE_SCHEDA_DATI,
      PROVINCIA_SCHEDA_DATI,
      PROVINCIA_NASCITA_SCHEDA_DATI,
      COMUNE_SCHEDA_DATI,
      COMUNE_ESTERO_SCHEDA_DATI,
      LAVORATORI,
      PROVINCIA_ATTIVITA,
      // PROVINCIA_ATTIVITA_ALTRO,
      // PROVINCIA_ALTRO_SCHEDA_DATI,
      NAZIONE_ATTIVITA,
      ANNO_INIZIO
      // FASCIA_REDDITO
    } = fields
    // var formWasCompiled =
    // this.isCompiled(TIPO_DOCUMENTO) &&
    // this.isCompiled(NUMERO_DOCUMENTO) &&
    // this.isCompiled(NAZIONE_RILASCIO) &&
    // this.isCompiled(PROVINCIA_RILASCIO) &&
    // (this.isCompiled(COMUNE_RILASCIO) || this.isCompiled(COMUNE_ESTERO_RILASCIO)) &&
    // this.isCompiled(DATA_RILASCIO) &&
    // this.isCompiled(DATA_SCADENZA) &&
    // this.isCompiled(DOC_FRONTE) &&
    // this.isCompiled(DOC_RETRO)
    return (
      <CollapsibleItem
        header={'Scheda di raccolta dati per la verifica della clientela persona fisica di riferimento'}
        itemKey={SCHEDA_DATI}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <br />
          <div
            style={{
              fontSize: 'medium'
            }}>
            La Figura accessoria di riferimento è: *
          </div>
          <br />
          <Row s={12}>
            <Col>
              <Field
                name={TIPO_CLIENTE.id}
                className="with-gap"
                type="radio"
                id="legale-rappresentante-0"
                value="RAPPRESENTANTE"
                component="input"
              />

              <label htmlFor="legale-rappresentante-0">Legale rappresentante</label>
            </Col>
            <Col>
              <Field
                name={TIPO_CLIENTE.id}
                className="with-gap"
                type="radio"
                id="delegato-0"
                value="DELEGATO"
                component="input"
              />

              <label htmlFor="delegato-0">Delegato</label>
            </Col>
            <Col>
              <Field
                name={TIPO_CLIENTE.id}
                className="with-gap"
                type="radio"
                id="procuratore-0"
                value="PROCURATORE"
                component="input"
              />

              <label htmlFor="procuratore-0">Procuratore</label>
            </Col>
          </Row>

          <Row>
            <Field
              name={CODICE_FISCALE.id}
              s={3}
              disabled
              maxLength={16}
              error={formErrors[CODICE_FISCALE.id]}
              label={CODICE_FISCALE.label}
              value={formValues[CODICE_FISCALE.id]}
              change={change}
              touch={touch}
              component={InputText}
              formatter={value => (value ? value.toUpperCase() : '')}
            />
            <Field
              name={CODICE_CLIENTE.id}
              s={3}
              disabled
              error={formErrors[CODICE_CLIENTE.id]}
              label={CODICE_CLIENTE.label}
              value={formValues[CODICE_CLIENTE.id]}
              change={change}
              touch={touch}
              component={InputText}
            />

            <Field
              name={DATA_NASCITA.id}
              s={6}
              openDirection="down"
              anchorDirection="right"
              label={DATA_NASCITA.label}
              readOnly
              placeholder=""
              isOutsideRange={date => date.isAfter(moment().subtract(18, 'y'), 'day')}
              defaultDate={formValues[DATA_NASCITA.id]}
              onDateChange={date => change(DATA_NASCITA.id, date.format())}
              component={renderDatePicker}
            />
          </Row>

          <Row>
            <Col s={6}>
              <Field
                name={NAZIONE_NASCITA_SCHEDA_DATI.id}
                s={12}
                label={NAZIONE_NASCITA_SCHEDA_DATI.label}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  NAZIONE_NASCITA_SCHEDA_DATI.dominio,
                  formValues[NAZIONE_NASCITA_SCHEDA_DATI.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_NASCITA_SCHEDA_DATI.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                }}
                component={renderAutocomplete}
              />
            </Col>
            {this.checkNazioneNascita(true) && (
              <Col s={6}>
                <Field
                  // questo field è erroneamente assegnato a PROVINCIA_NASCITA_SCHEDA_DATI, ma è LUOGO_NASCITA
                  name={PROVINCIA_NASCITA_SCHEDA_DATI.id}
                  s={12}
                  label={PROVINCIA_NASCITA_SCHEDA_DATI.label}
                  threshold={0}
                  // disabled={!this.checkNazione(true)}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    PROVINCIA_NASCITA_SCHEDA_DATI.dominio,
                    formValues[PROVINCIA_NASCITA_SCHEDA_DATI.id]
                  )}
                  options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_NASCITA_SCHEDA_DATI.dominio))}
                  handleChange={(field, value) => {
                    change(field, value)
                  }}
                  component={renderAutocomplete}
                />
              </Col>
            )}
            {!this.checkNazioneNascita(true) && (
              <Col s={6}>
                <Field
                  name={PROVINCIA_NASCITA_ESTERA_SCHEDA_DATI.id}
                  s={12}
                  maxLength={50}
                  disabled={!this.checkNazioneNascita()}
                  label={PROVINCIA_NASCITA_ESTERA_SCHEDA_DATI.label}
                  error={formErrors[PROVINCIA_NASCITA_ESTERA_SCHEDA_DATI.id]}
                  value={formValues[PROVINCIA_NASCITA_ESTERA_SCHEDA_DATI.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
            )}
            <Col s={6}>
              {/* si spacca qui! */}
              <Field
                name={NAZIONE_SCHEDA_DATI.id}
                s={12}
                label={NAZIONE_SCHEDA_DATI.label}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  NAZIONE_SCHEDA_DATI.dominio,
                  formValues[NAZIONE_SCHEDA_DATI.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_SCHEDA_DATI.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[PROVINCIA_SCHEDA_DATI.id]) {
                    change(PROVINCIA_SCHEDA_DATI.id, '')
                  }
                  if (formValues[COMUNE_SCHEDA_DATI.id]) {
                    change(COMUNE_SCHEDA_DATI.id, '')
                  }
                  if (formValues[COMUNE_ESTERO_SCHEDA_DATI.id]) {
                    change(COMUNE_ESTERO_SCHEDA_DATI.id, '')
                  }
                  if (formValues[CAP_SCHEDA_DATI.id]) {
                    change(CAP_SCHEDA_DATI.id, '')
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
            {/* {this.checkNazione(true) && ( */}
            <Col s={6}>
              <Field
                name={PROVINCIA_SCHEDA_DATI.id}
                s={12}
                label={PROVINCIA_SCHEDA_DATI.label}
                threshold={0}
                disabled={!this.checkNazione(true)}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  PROVINCIA_SCHEDA_DATI.dominio,
                  formValues[PROVINCIA_SCHEDA_DATI.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_SCHEDA_DATI.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[COMUNE_SCHEDA_DATI.id]) {
                    change(COMUNE_SCHEDA_DATI.id, '')
                  }
                  if (formValues[CAP_SCHEDA_DATI.id]) {
                    change(CAP_SCHEDA_DATI.id, '')
                  }
                  if (this.checkProvincia(value)) {
                    this.setState({ waitingCap: true })
                    this.updateDominio(COMUNE_SCHEDA_DATI.dominio, value)
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
            {/* )} */}

            {/* {!this.checkNazione(true) && (
              <Col s={6}>
                <Field
                  name={PROVINCIA_ALTRO_SCHEDA_DATI.id}
                  s={12}
                  maxLength={10}
                  error={formErrors[PROVINCIA_ALTRO_SCHEDA_DATI.id]}
                  label={PROVINCIA_ALTRO_SCHEDA_DATI.label}
                  value={formValues[PROVINCIA_ALTRO_SCHEDA_DATI.id]}
                  disabled={!this.checkNazione(true)}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
            )} */}

            {this.checkNazione(true) && (
              <Col s={6}>
                <Field
                  name={COMUNE_SCHEDA_DATI.id}
                  s={12}
                  threshold={0}
                  label={COMUNE_SCHEDA_DATI.label}
                  disabled={!this.checkProvincia(formValues[PROVINCIA_SCHEDA_DATI.id])}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    COMUNE_SCHEDA_DATI.dominio,
                    formValues[COMUNE_SCHEDA_DATI.id]
                  )}
                  options={
                    formValues[PROVINCIA_SCHEDA_DATI.id]
                      ? mapDominiToAutocomplete(getDominio(domini, COMUNE_SCHEDA_DATI.dominio))
                      : []
                  }
                  handleChange={(field, value) => {
                    change(field, value)
                    if (formValues[CAP_SCHEDA_DATI.id]) {
                      change(CAP_SCHEDA_DATI.id, '')
                    }
                    if (this.checkComune(value)) {
                      this.setState({ waitingCap: true })
                      this.updateDominio(CAP_SCHEDA_DATI.dominio, value)
                    }
                  }}
                  component={renderAutocomplete}
                />
              </Col>
            )}

            {!this.checkNazione(true) && (
              <Field
                name={COMUNE_ESTERO_SCHEDA_DATI.id}
                s={6}
                maxLength={50}
                disabled={!this.checkNazione()}
                label={COMUNE_SCHEDA_DATI.label}
                error={formErrors[COMUNE_ESTERO_SCHEDA_DATI.id]}
                value={formValues[COMUNE_ESTERO_SCHEDA_DATI.id]}
                change={change}
                touch={touch}
                component={InputText}
              />
            )}

            <Col s={6}>
              <Field
                name={CAP_SCHEDA_DATI.id}
                s={12}
                threshold={0}
                label={CAP_SCHEDA_DATI.label}
                value={formValues[CAP_SCHEDA_DATI.id]}
                disabled={this.checkCap()}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  CAP_SCHEDA_DATI.dominio,
                  formValues[CAP_SCHEDA_DATI.id]
                )}
                options={
                  formValues[COMUNE_SCHEDA_DATI.id]
                    ? mapDominiToAutocomplete(getDominio(domini, CAP_SCHEDA_DATI.dominio))
                    : []
                }
                handleChange={change}
                component={renderAutocomplete}
              />
            </Col>

            <Field
              name={INDIRIZZO_RESIDENZA.id}
              s={6}
              error={formErrors[INDIRIZZO_RESIDENZA.id]}
              label={INDIRIZZO_RESIDENZA.label}
              value={formValues[INDIRIZZO_RESIDENZA.id]}
              change={change}
              touch={touch}
              component={InputText}
            />

            <Col s={6}>
              <Field
                name={CITTADINANZA_SCHEDA_DATI.id}
                size={12}
                label={CITTADINANZA_SCHEDA_DATI.label}
                value={formValues[CITTADINANZA_SCHEDA_DATI.id]}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  CITTADINANZA_SCHEDA_DATI.dominio,
                  formValues[CITTADINANZA_SCHEDA_DATI.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, CITTADINANZA_SCHEDA_DATI.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                }}
                component={renderAutocomplete}
              />
            </Col>
            <Field
              name={EMAIL.id}
              s={6}
              maxLength={50}
              disabled
              error={formErrors[EMAIL.id]}
              label={EMAIL.label}
              value={formValues[EMAIL.id]}
              change={change}
              touch={touch}
              component={InputText}
            />

            <Field
              name={CELLULARE.id}
              s={6}
              // maxLength={10}
              disabled
              error={formErrors[CELLULARE.id]}
              label={CELLULARE.label}
              value={formValues[CELLULARE.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
            <Field
              name={TIPO_DOCUMENTO.id}
              disabled
              size={6}
              label={TIPO_DOCUMENTO.label}
              value={formValues[TIPO_DOCUMENTO.id]}
              options={mapDominiToOptions(getDominio(domini, TIPO_DOCUMENTO.dominio), true)}
              component={renderSelect}
            />

            <Field
              name={ESTREMI_DOCUMENTO.id}
              s={6}
              maxLength={10}
              disabled
              error={formErrors[ESTREMI_DOCUMENTO.id]}
              label={ESTREMI_DOCUMENTO.label}
              value={formValues[ESTREMI_DOCUMENTO.id]}
              change={change}
              touch={touch}
              component={InputText}
            />

            <Field
              name={DATA_RILASCIO.id}
              value={formValues[DATA_RILASCIO.id]}
              s={6}
              openDirection="down"
              anchorDirection="right"
              disabled
              label={DATA_RILASCIO.label}
              readOnly
              placeholder=""
              isOutsideRange={date => date.isAfter(moment().subtract(0, 'y'), 'day')}
              defaultDate={formValues[DATA_RILASCIO.id]}
              onDateChange={date => change(DATA_RILASCIO.id, date.format())}
              component={renderDatePicker}
            />

            <Field
              name={DATA_SCADENZA.id}
              value={formValues[DATA_SCADENZA.id]}
              s={6}
              openDirection="down"
              // anchorDirection="right"
              label={DATA_SCADENZA.label}
              readOnly
              disabled
              placeholder=""
              isOutsideRange={date =>
                date.isBefore(moment().add(1, 'day'), 'day') ||
                date.isAfter(
                  moment()
                    .add(11, 'y')
                    .add(1, 'day'),
                  'day'
                )
              }
              defaultDate={formValues[DATA_SCADENZA.id]}
              onDateChange={date => change(DATA_SCADENZA.id, date.format())}
              component={renderDatePicker}
            />
            <Col s={6}>
              <Field
                name={LUOGO_RILASCIO.id}
                value={formValues[LUOGO_RILASCIO.id]}
                s={12}
                label={LUOGO_RILASCIO.label}
                threshold={0}
                disabled
                // disabled={!this.checkNazione(true)}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  LUOGO_RILASCIO.dominio,
                  formValues[LUOGO_RILASCIO.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, LUOGO_RILASCIO.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                }}
                component={renderAutocomplete}
              />
            </Col>

            <Field
              name={PROFESSIONE_ATTUALE.id}
              size={6}
              label={PROFESSIONE_ATTUALE.label}
              options={mapDominiToOptions(getDominio(domini, PROFESSIONE_ATTUALE.dominio), true)}
              component={renderSelect}
              onChange={(field, value) => {
                if (value !== PROFESSIONE_ATTUALE.liberoProfId) {
                  change(ANNO_INIZIO.id, '')
                  change(NAZIONE_ATTIVITA.id, '')
                  change(PROVINCIA_ATTIVITA.id, '')
                  change(LAVORATORI.id, '')
                }

                if (!this.checkNazioneIsediamento(true)) {
                  change(PROVINCIA_ATTIVITA.id, '')
                }
                if (value !== PROFESSIONE_ATTUALE.pensionatoId) {
                  change(PROFESSIONE_PRECEDENTE.id, '')
                }
                change(field, value)
              }}
            />

            <Field
              name={PROFESSIONE_PRECEDENTE.id}
              size={6}
              disabled={formValues[PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.pensionatoId}
              label={PROFESSIONE_PRECEDENTE.label}
              options={mapDominiToOptions(getDominio(domini, PROFESSIONE_PRECEDENTE.dominio), true)}
              component={renderSelect}
            />

            <Field
              name={TITOLO_STUDIO.id}
              size={6}
              label={TITOLO_STUDIO.label}
              options={mapDominiToOptions(getDominio(domini, TITOLO_STUDIO.dominio), true)}
              component={renderSelect}
            />

            <Field
              name={SETTORE_OCCUPAZIONE.id}
              size={6}
              label={SETTORE_OCCUPAZIONE.label}
              options={mapDominiToOptions(getDominio(domini, SETTORE_OCCUPAZIONE.dominio), true)}
              component={renderSelect}
            />
            <Field
              name={SETTORE_OCCUPAZIONE_ALTRO.id}
              disabled={formValues[SETTORE_OCCUPAZIONE.id] !== SETTORE_OCCUPAZIONE.altroId}
              size={6}
              label={SETTORE_OCCUPAZIONE_ALTRO.label}
              options={mapDominiToOptions(getDominio(domini, SETTORE_OCCUPAZIONE_ALTRO.dominio), true)}
              component={renderSelect}
            />
            {/* <Col s={6}>
              <Field
                name={LAVORATORI.id}
                s={12}
                maxLength={100}
                error={formErrors[LAVORATORI.id]}
                label={LAVORATORI.label}
                value={formValues[LAVORATORI.id]}
                change={change}
                touch={touch}
                disabled={formValues[PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.liberoProfId}
                component={Text}
              />
            </Col> */}
          </Row>

          <Row>
            <Col s={6}>
              <div
                style={{
                  fontSize: 'medium'
                }}>
                Lavoratori automoni/liberi professionisti
              </div>
            </Col>
          </Row>
          <Row>
            <Col s={6}>
              <Field
                name={NAZIONE_ATTIVITA.id}
                s={12}
                label={NAZIONE_ATTIVITA.label}
                threshold={0}
                disabled={formValues[PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.liberoProfId}
                // disabled={!this.checkNazione(true)}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  NAZIONE_ATTIVITA.dominio,
                  formValues[NAZIONE_ATTIVITA.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_ATTIVITA.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                  if (!this.checkNazioneIsediamento(true)) {
                    change(PROVINCIA_ATTIVITA.id, '')
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>

            <Col s={6}>
              <Field
                name={PROVINCIA_ATTIVITA.id}
                s={12}
                label={PROVINCIA_ATTIVITA.label}
                threshold={0}
                defaultValue={getAutocompleteDefaultOption(
                  domini.listaDomini,
                  PROVINCIA_ATTIVITA.dominio,
                  formValues[PROVINCIA_ATTIVITA.id]
                )}
                options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_ATTIVITA.dominio))}
                handleChange={(field, value) => {
                  change(field, value)
                }}
                component={renderAutocomplete}
                disabled={!this.checkNazioneIsediamento(true)}
              />
            </Col>
          </Row>
          <Row>
            <Field
              name={ANNO_INIZIO.id}
              s={6}
              maxLength={4}
              error={formErrors[ANNO_INIZIO.id]}
              label={ANNO_INIZIO.label}
              value={formValues[ANNO_INIZIO.id]}
              disabled={formValues[PROFESSIONE_ATTUALE.id] !== fields.PROFESSIONE_ATTUALE.liberoProfId}
              change={change}
              touch={touch}
              component={InputText}
            />
          </Row>

          {/* <Field
                name={PROFESSIONE_ATTUALE.id}
                size={12}
                label={PROFESSIONE_ATTUALE.label}
                options={[
                  <option key={0} value="">
                    -
                  </option>,
                  <option key={1} value="TBD">
                    TBD
                  </option>
                ]}
                component={renderSelect}
              /> */}
          <Row />

          <Row>
            <SchedaDatiPep {...this.props} />
          </Row>
          <Row>
            <Field
              name={fields.YES_NO_MULTI_OWNER.id}
              label={fields.YES_NO_MULTI_OWNER.label}
              handlerFn={value => {
                change(fields.YES_NO_MULTI_OWNER.id, value)
                this.props.setMultidTitolare(value)
                if (value === false) {
                  change(fields.YES_NO_OWNER.id, null)
                  this.props.setEnabledTitolare(null)
                }
              }}
              options={[{ idRisposta: true }, { idRisposta: false }]}
              component={renderYesNoQuestion}
            />
          </Row>
          {this.checkvisibility() && this.props.dataVerificaClienti.isUniqueOwner !== undefined && (
            <Row>
              <Field
                name={fields.YES_NO_OWNER.id}
                label={fields.YES_NO_OWNER.label}
                handlerFn={value => {
                  change(fields.YES_NO_OWNER.id, value)
                  this.props.setEnabledTitolare(value)
                }}
                options={[{ idRisposta: true }, { idRisposta: false }]}
                component={renderYesNoQuestion}
              />
            </Row>
          )}
          {/* {this.checkvisibilityFascia() && this.checkvisibility()  && this.props.dataVerificaClienti.isOwner!==undefined &&(

          <Row className="radio-vertical extraMarginLeftRadioPerent">
            <br />
            <br />
            <div
              style={{
    fontSize: 'medium'
  }}>
  LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*
            </div>
            <Row className="extraMarginLeftRadio">
              <Field
                name={FASCIA_REDDITO.id}
                className="with-gap"
                type="radio"
                id="pep-reddito-0"
                value="INCOME_RATE_1"
                component="input"
  />
              <label className="radio-vertical" htmlFor="pep-reddito-0">
                {'< 50.000 EURO'}
              </label>
            </Row>

            <Row className="extraMarginLeftRadio">
              <Field
                name={FASCIA_REDDITO.id}
                className="with-gap"
                type="radio"
                id="pep-reddito-1"
                value="INCOME_RATE_2"
                component="input"
  />
              <label className="radio-vertical" htmlFor="pep-reddito-1">
                {'50.000 < 100.000 EURO'}
              </label>
            </Row>

            <Row className="extraMarginLeftRadio">
              <Field
                name={FASCIA_REDDITO.id}
                className="with-gap"
                type="radio"
                id="pep-reddito-3"
                value="INCOME_RATE_3"
                component="input"
  />
              <label className="radio-vertical" htmlFor="pep-reddito-3">
                {'100.000 < 250.000 EURO'}
              </label>
            </Row>

            <Row className="extraMarginLeftRadio">
              <Field
                name={FASCIA_REDDITO.id}
                className="with-gap"
                type="radio"
                id="pep-reddito-4"
                value="INCOME_RATE_4"
                component="input"
  />
              <label className="radio-vertical" htmlFor="pep-reddito-4">
                {'250.000 < 1.000.000 EURO'}
              </label>
            </Row>

            <Row className="extraMarginLeftRadio">
              <Field
                name={FASCIA_REDDITO.id}
                className="with-gap"
                type="radio"
                id="pep-reddito-5"
                value="INCOME_RATE_5"
                component="input"
  />
              <label className="radio-vertical" htmlFor="pep-reddito-5">
                {'1.000.000 EUR'}
              </label>
            </Row>
          </Row>
          )} */}
          <Row>
            <Col s={12}>
              <ButtonSubmit
                disabled={
                  submitting ||
                  (!isDirty && !anyTouched) ||
                  // invalid ||
                  isFetching ||
                  Object.keys(formErrors).some(e => formErrors[e])
                  // !formWasCompiled
                }
              />
            </Col>
          </Row>
        </form>
      </CollapsibleItem>
    )
  }
}

SchedaDati.propTypes = {
  // submitHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  // invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  // apiError: PropTypes.object,
  initialize: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,
  resetForm: PropTypes.func,
  // currentInitial: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  domini: PropTypes.object,
  // dominioCustomRequest: PropTypes.func,
  // submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  anyTouched: PropTypes.bool,
  // dataNascitaCF: PropTypes.any,
  fields: PropTypes.object,
  dominioCustomRequest: PropTypes.func,
  setEnabledTitolare: PropTypes.func,
  setMultidTitolare: PropTypes.func,
  postDataVerificaClienti: PropTypes.func,
  idPhysicalPersonReference: PropTypes.string,
  dataVerificaClienti: PropTypes.object,
  dominioRequest: PropTypes.object,
  customer: PropTypes.object

  // getDataVerificaClienti: PropTypes.func,
  // postDataVerificaClientiRequest: PropTypes.object
}

SchedaDati.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default SchedaDati
