import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import { Button, Row, Col, Icon } from 'react-materialize'
import InputText from '../../../components/Inputs/Text'
import { renderSelect } from '../../../components/Forms/renderFields'
import { mapDominiToOptions, getDominio, getKeyDominioFromValue } from '../utils'
import ContattiForm from '../../../components/Forms/Contatti/ContattiForm'
// import ModalConsulenza from '../../../components/Modals/Consulenza'
import ModalWarn from '../../../components/Modals/ModalWarn'
import { setRandomStr } from '../../ConsulenzaBaseContainerLayout/utils'
import { setTimeout } from 'timers'
import { ProspectContextConsumer } from '../ProspectContext'
import Header from '../../../components/Header/'
import DatiPersonaGiuridica from '../../../components/Forms/DatiPersonaGiuridica'

const tabs = [
  { label: 'Persone Fisiche', route: '/consulenza-base/create' },
  { label: 'Persone Giuridiche', route: '/consulenza-base-PG/create' },
  { label: 'Persone Fisiche con Figure Accessorie', route: '/consulenza-base/create' }
]
export class DataGathering extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      modal: {
        label: 'Cellulare',
        flagId: '',
        createOtp: () => {},
        verifyOtp: () => {}
      },
      updatingPratica: false,
      prosegui: false,
      modalConfirm: false,
      readOnly: true,
      isSaved: false,
      typeConsulenza: '',
      blackList: false,
      certifiedPhone: {
        number: null,
        prefix: null
      },
      certifiedEmail: null,
      fetching: true,
      onlySave: true
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
    this.handleVerifyOtp = this.handleVerifyOtp.bind(this)
    this.saveForm = this.saveForm.bind(this)
    this.showModalConfirm = this.showModalConfirm.bind(this)
    this.closeModalConfirm = this.closeModalConfirm.bind(this)
    this.goToConsulenza = this.goToConsulenza.bind(this)
    this.closeBlackList = this.closeBlackList.bind(this)
    this.handleSubmitForSavePGData = this.handleSubmitForSavePGData.bind(this)
    this.handleClickForProseguiPG = this.handleClickForProseguiPG.bind(this)
    this.checkVerifiedValuesToSave = this.checkVerifiedValuesToSave.bind(this)
  }

  componentDidMount() {
    const { dominio, initialValues, fields } = this.props
    setRandomStr()
    dominio({
      listaDomini: Object.keys(fields).reduce((res, cur) => {
        if (fields[cur].dominio) {
          if (!fields[cur].dominioDep) {
            return [
              ...res,
              {
                idDominio: fields[cur].dominio
              }
            ]
          }
          if (initialValues[fields[cur].dominioDep]) {
            return [
              ...res,
              {
                idDominio: fields[cur].dominio,
                filtro: {
                  codice: initialValues[fields[cur].dominioDep]
                }
              }
            ]
          }
          return res
        }
        return res
      }, [])
    })

    if (this.props.submitting || !this.props.dirty) {
      this.setState({ isSaved: true })
    }

    // if (!this.props.submitting || this.props.dirty) {
    //   this.setState({ isSaved: false })
    // }

    this.setState({
      certifiedPhone: {
        number: initialValues[fields.FLAG_CELLULARE.id] ? initialValues[fields.CELLULARE.id] : '',
        prefix: initialValues[fields.FLAG_CELLULARE.id] ? initialValues[fields.PREFISSO.id] : ''
      },
      certifiedEmail: initialValues[fields.FLAG_EMAIL.id] ? initialValues[fields.EMAIL.id] : ''
    })
  }
  dominioCustomRequest(codDominio, codDominioFilter, value, isAlreadyKey) {
    const { domini, dominio } = this.props
    dominio({
      listaDomini: [
        {
          idDominio: codDominio,
          filtro: {
            codice: isAlreadyKey ? value : getKeyDominioFromValue(domini.listaDomini, codDominioFilter, value)
          }
        }
      ]
    })
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.getIdProspectByIdProcessRequest.fetching &&
      !this.props.getIdProspectByIdProcessRequest.fetching &&
      !this.props.getIdProspectByIdProcessRequest.error
    ) {
      const ReplyInterface = {
        name: 'START_IP_PROCESS',
        payload: {
          ownerCode: this.props.pDob
        }
      }
      try {
        const Url = document.location.ancestorOrigins[0]
        window.parent.postMessage(ReplyInterface, Url)
      } catch (e) {
        console.log(e, 'non esiste il parent')
      }

      this.props.goToConsulenza()
      this.setState({ modalConfirm: true })
    }

    if (
      prevProps.postPFAnagraficaRequest &&
      this.props.postPFAnagraficaRequest &&
      prevProps.postPFAnagraficaRequest.fetching &&
      !this.props.postPFAnagraficaRequest.fetching &&
      !this.props.postPFAnagraficaRequest.error
    ) {
      if (!this.state.onlySave) {
        this.props.goToConsulenza()
        this.setState({ modalConfirm: true })
      }
    }

    if (
      prevProps.postDataGatheringRequest &&
      this.props.postDataGatheringRequest &&
      prevProps.postDataGatheringRequest.fetching &&
      !this.props.postDataGatheringRequest.fetching &&
      !this.props.postDataGatheringRequest.error
    ) {
      if (this.props.isProspectPersonaGiuridica) {
        const { postAnagraficaPFActionCreator, formValues, fields, idPhysicalPersonReference } = this.props
        const { FLAG_CELLULARE, FLAG_EMAIL, PREFISSO, CELLULARE, EMAIL } = fields
        postAnagraficaPFActionCreator({
          phonePrefixCode: formValues[PREFISSO.id],
          phoneNumber: formValues[CELLULARE.id],
          mail: formValues[EMAIL.id],
          isMailVerified: formValues[FLAG_EMAIL.id],
          isPhoneVerified: formValues[FLAG_CELLULARE.id],
          idReferencePhysicalPerson: idPhysicalPersonReference
        })
      } else {
      }
    }

    if (
      prevProps.postDataGatheringRequest.fetching &&
      !this.props.postDataGatheringRequest.fetching &&
      this.props.submitSucceeded &&
      !this.props.postDataGatheringRequest.error
    ) {
      this.setState({ updatingPratica: true })
      this.props.getPratica(this.props.customer.idIncarico)
    }

    if (prevProps.praticaRequest.fetching && !this.props.praticaRequest.fetching && this.state.updatingPratica) {
      this.setState({ updatingPratica: false, prosegui: true })
      this.props.initialize(this.props.formValues)
    }
    const {
      dataGathering: { province },
      fields: { CITTA_PG, PROVINCIA_PG }
    } = this.props
    if (province && this.state.fetching) {
      this.dominioCustomRequest(CITTA_PG.dominio, PROVINCIA_PG.dominio, province, true)
      this.setState({ fetching: false })
    }

    // if (this.props.formValues[fields.AZIENDA.id]) {
    //   if (prevProps.value[fields.AZIENDA.id] === this.props.formValues[fields.AZIENDA.id]) {
    //     this.setState({ isSaved: true })
    //   }
    // }
    this.checkVerifiedValuesToSave(prevProps)
  }

  checkVerifiedValuesToSave(prevProps) {
    const { formValues, fields } = this.props
    const { CELLULARE, PREFISSO, EMAIL, FLAG_CELLULARE, FLAG_EMAIL } = fields

    if (prevProps.formValues && prevProps.formValues[FLAG_CELLULARE.id] === false && formValues[FLAG_CELLULARE.id]) {
      this.setState({
        certifiedPhone: {
          number: formValues[CELLULARE.id],
          prefix: formValues[PREFISSO.id]
        }
      })
    }
    if (prevProps.formValues && prevProps.formValues[FLAG_EMAIL.id] === false && formValues[FLAG_EMAIL.id]) {
      this.setState({ certifiedEmail: formValues[EMAIL.id] })
    }
  }

  updateDominio(idDominio, codice) {
    this.props.dominio({ listaDomini: [{ idDominio, filtro: { codice } }] })
  }

  handleSubmit(values) {
    const {
      customer: { idCliente },
      postDataGathering
    } = this.props
    postDataGathering(idCliente, values)
    this.setState({
      isSaved: true
    })
  }

  async handleSubmitForSavePGData(values, goToConsulenza = false) {
    const {
      postDataGathering,
      fields,
      pratica: { idLegalPerson },
      customer: { idCliente }
    } = this.props
    const {
      CODICE_FISCALE_PG,
      RAGIONE_SOCIALE_PG,
      FORMA_ASSOCIATIVA_PG,
      SEDE_LEGALE_PG,
      CITTA_PG,
      CITTA_ESTERO_PG,
      PROVINCIA_PG,
      NAZIONE_PG,
      CAP_PG,
      DATA_COSTITUZIONE_PG,
      // NAZIONE_PG_COSTITUZIONE,
      PROVINCIA_PG_COSTITUZIONE,
      CITTA_PG_COSTITUZIONE,
      ATTIVITA_ESERCITATA_PG,
      CODICE_ATECO_PG,
      PARTITA_IVA_PG,
      HA_DOMICILIO,
      CELLULARE_PG,
      EMAIL_PG
    } = fields
    const bodyRequest = {
      idLegalPerson: idLegalPerson || idCliente,
      taxCode: values[CODICE_FISCALE_PG.id] ? values[CODICE_FISCALE_PG.id].toUpperCase() : '',
      vatCode: values[PARTITA_IVA_PG.id],
      formCorporationCode: values[FORMA_ASSOCIATIVA_PG.id],
      businessName: values[RAGIONE_SOCIALE_PG.id],
      city: values[CITTA_PG.id],
      place: values[CITTA_ESTERO_PG.id],
      province: values[PROVINCIA_PG.id],
      nation: values[NAZIONE_PG.id],
      zipCode: values[CAP_PG.id],
      registeredOfficeAddress: values[SEDE_LEGALE_PG.id],
      creationDate: values[DATA_COSTITUZIONE_PG.id],
      nationCreation: fields.NAZIONE_PG_COSTITUZIONE.italiaId,
      // nationCreation: fields[NAZIONE_PG_COSTITUZIONE.italiaId],
      // nationCreation: values[NAZIONE_PG_COSTITUZIONE.id],
      provinceCreation: values[PROVINCIA_PG_COSTITUZIONE.id],
      cityCreation: values[CITTA_PG_COSTITUZIONE.id],
      activitySectorCode: values[ATTIVITA_ESERCITATA_PG.id],
      atecoCode: values[CODICE_ATECO_PG.id],
      flagDomicile: values[HA_DOMICILIO.id],
      mobilePhone: values[CELLULARE_PG.id],
      email: values[EMAIL_PG.id]
    }
    postDataGathering(bodyRequest)
  }

  async handleClickForProseguiPG(obj) {
    if (obj !== null && obj !== undefined) this.setState({ onlySave: obj.onlySave })
    else this.setState({ onlySave: true })

    const { formValues, goToConsulenza } = this.props
    if (this.props.isProspectPersonaGiuridica) {
      this.handleSubmitForSavePGData(formValues, true)
    } else {
      await this.handleSubmitForSavePGData(formValues, true)
      goToConsulenza()
    }

    //
  }

  goToConsulenza() {
    this.setState({
      modalConfirm: true
    })
  }

  saveForm(values) {
    const { formValues, initialize } = this.props
    initialize(values || formValues)
    this.setState({ isSaved: false })
  }

  showModalConfirm() {
    const {
      formValues,
      inBlacklistCF,
      customer: { idCliente },
      dataGathering: { name, surname, taxCode },
      blacklistCFRequest,
      flagCustomerIsBlacklist,
      pratica: { idPratica }
    } = this.props
    const data = {
      name,
      surname,
      taxCode,
      idCustomer: idCliente
    }
    this.handleSubmit(formValues)
    if (taxCode) {
      inBlacklistCF(data).then(() => {
        if (!flagCustomerIsBlacklist && blacklistCFRequest.error === null) {
          // get token for OW alla success eseguo
          this.props.getIdProspectByIdProcess(idPratica)

          // da spostare su update component quando ci sarà il WS della get token
          // window.parent.postMessage({ data:'2345678' }, { url:document.parent.location })
          // this.props.goToConsulenza()
          // this.setState({ modalConfirm: true })
        } else {
          this.setState({ blackList: true })
        }
      })
    } else {
      this.props.getIdProspectByIdProcess(idPratica)
      // get token for OW alla success eseguo
      // da spostare su update component quando ci sarà il WS della get token
      // window.parent.postMessage({ data:'2345678' }, { url:document.parent.location })

      // this.props.goToConsulenza()

      // this.setState({
      //   modalConfirm: true
      // })
    }
  }
  closeModalConfirm() {
    this.setState({
      modalConfirm: false
    })
  }

  closeBlackList() {
    this.props.goToConsulenza()

    this.setState({
      blackList: false,
      modalConfirm: true
    })
  }

  handleVerifyOtp(type, { codice, riferimento }, flag) {
    // REFACTOR THIS
    const {
      customer: { idCliente, idIncarico },
      validationVerifyOtp,
      handleSubmit
    } = this.props
    validationVerifyOtp(
      type,
      {
        codice,
        idCliente,
        idIncarico,
        riferimento,
        subjectType: location.pathname.indexOf('-PG') >= 0 ? 'LEGAL_PERSON' : 'PHYSICAL_SUBJECT'
      },
      null,
      flag === 'flagCertificazioneCellulare' ? handleSubmit(this.handleSubmit) : null
      // REFACTOR dataRegistry => dataGathering ?
      // checkStatusService(statusEndPoint, 'dataGathering')
    )
  }
  isCompiled(v) {
    const { formValues = {} } = this.props
    return v && typeof formValues[v.id] !== 'undefined' && formValues[v.id] !== null && formValues[v.id] !== ''
  }

  chooseConsulenza(selected, unselected) {
    this.setState({ typeConsulenza: selected })
    // border box
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[0].style.border = '2px solid #083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[0].style.border = '2px solid #dadada'
    // icon
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[1].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[1].style.color = '#616166'
    // title
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[2].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[2].style.color = '#616166'
    // subtitle
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[3].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[3].style.color = '#616166'
  }

  renderGiuridiche() {
    const {
      submitting,
      invalid,
      change,
      touch,
      domini,
      handleSubmit,
      dirty,
      formValues = {},
      formErrors = {},
      otpStatus,
      fields,
      currentInitial,
      verifyOtpRequest,
      createOtpRequest,
      validationCreateOtp
    } = this.props
    // var formWasCompiled = this.isCompiled(SETTORE) && this.isCompiled(RUOLO) && this.isCompiled(AZIENDA)
    const { FLAG_CELLULARE, FLAG_EMAIL } = fields
    let isSaveDisabled =
      Object.keys(formErrors).filter(k => [FLAG_CELLULARE.id, FLAG_EMAIL.id].indexOf(k) < 0).length > 0
    return (
      <Row className="container-home paddingless marginless">
        {this.props.blackList && (
          <ModalWarn
            title={
              <p className="onboarding-modale-content__title center-align onboarding-modale-text-blu">
                Prospect in black list
              </p>
            }
            children={
              <Row>
                <Col s={12} style={{ marginTop: '10px' }}>
                  <Icon class="materIconal-icons" large>
                    warning
                  </Icon>
                </Col>
              </Row>
            }
            text={
              <Row>
                <Col s={12} style={{ marginTop: '10px' }}>
                  <p className="onboarding-modale-content__subtitle center-align">
                    Il Prospect potrebbe essere soggetto ad ulteriori controlli
                  </p>
                </Col>
              </Row>
            }
            closePopUp={this.props.closePopUp}
            close={this.closeModalConfirm}
          />
        )}
        <Col s={12} className="paddingless">
          <section className="main-body no-aside DataGathering consulenza-base-codice-fiscale">
            <Header tabs={tabs} title={'Censimento Prospect'} activeTabIndex={1} />
            <article className="page-left-content">
              <form onSubmit={handleSubmit(this.handleSubmitForSavePGData)}>
                <div className="box-container box-professione">
                  <Row>
                    <Col s={12}>
                      <h3 className="section-title" style={{ 'margin-left': '1.5rem' }}>
                        Dati persona fisica di riferimento
                      </h3>
                    </Col>
                    <Col s={12}>
                      <Col s={12}>
                        <ContattiForm
                          fields={fields}
                          change={change}
                          touch={touch}
                          currentInitial={currentInitial}
                          domini={domini}
                          formValues={formValues}
                          formErrors={formErrors}
                          type={'LEGAL-PERSON'}
                          cambioStato={() => {
                            setTimeout(() => {
                              this.setState({
                                isSaved: false
                              })
                              if (!this.props.dirty) {
                                this.setState({
                                  isSaved: true
                                })
                              }
                            }, 100)
                          }}
                          verifyOtpRequest={verifyOtpRequest}
                          createOtpRequest={createOtpRequest}
                          validationCreateOtp={validationCreateOtp}
                          validationVerifyOtp={this.handleVerifyOtp}
                          otpStatus={otpStatus}
                          saveForm={this.saveForm}
                          verifyAndSaveFlag
                          certifiedPhone={this.state.certifiedPhone}
                          certifiedEmail={this.state.certifiedEmail}
                          isProspectPGAvailable={this.props.isProspectPGAvailable || null}
                        />
                      </Col>
                    </Col>
                  </Row>
                </div>
                <div className="box-container box-professione" style={{ padding: '1.5rem 3rem' }}>
                  <Col s={12} style={{ 'padding-left': '0' }}>
                    <h3
                      className="section-title"
                      style={{
                        color: '#003399',
                        'margin-left': '0'
                      }}>
                      Dati persona giuridica
                    </h3>
                  </Col>
                  <DatiPersonaGiuridica
                    fields={fields}
                    change={change}
                    touch={touch}
                    formValues={formValues}
                    updateDominio={this.updateDominio}
                    domini={domini}
                  />
                </div>
                <div className="final-note">* Campi obbligatori</div>
                <Row className="footer-buttons">
                  <div className="footer-buttons__left">
                    <Button
                      type="button"
                      className="footer-buttons__btn back-button"
                      onClick={() => this.props.goToWhenSaved(`/consulenza-base-PG/create`)}>
                      Indietro
                    </Button>
                  </div>
                  <div className="footer-buttons__right">
                    <Button
                      type="submit"
                      className="footer-buttons__btn save-button"
                      disabled={submitting || !dirty || invalid || isSaveDisabled}>
                      <i className="material-icons">save</i>
                      Salva
                    </Button>
                    <Button
                      type="button"
                      className="footer-buttons__btn"
                      disabled={submitting || invalid || Object.keys(formErrors).length > 0 /* || !formWasCompiled */}
                      onClick={() => {
                        if (this.state.isSaved) {
                          this.props.goToConsulenza()
                        } else {
                          this.handleClickForProseguiPG({ onlySave: false })
                        }
                      }}>
                      Prosegui
                    </Button>
                  </div>
                </Row>
              </form>
            </article>
          </section>
        </Col>
      </Row>
    )
  }

  renderFisiche() {
    // this.props.goToConsulenza()
    // return
    const {
      change,
      touch,
      domini,
      handleSubmit,
      formValues = {},
      formErrors = {},
      otpStatus,
      // customer: { idCliente },
      currentInitial,
      verifyOtpRequest,
      createOtpRequest,
      validationCreateOtp,
      fields
      // idIncarico
    } = this.props
    const { CELLULARE, PREFISSO, FLAG_CELLULARE, SETTORE, RUOLO, AZIENDA } = fields
    var formWasCompiled =
      this.isCompiled(CELLULARE) && this.isCompiled(PREFISSO) && formValues[FLAG_CELLULARE.id] === true
    return (
      <Row className="container-home paddingless marginless">
        {/* {this.state.modalConfirm && (
          <ModalConsulenza
            idIncarico={idIncarico}
            tipoConsulenza={CONSULENZA.chiave === 2 ? 'light' : 'full'}
            token={localStorage.randomToken}
            title={
              <p className="onboarding-modale-content__title center-align onboarding-modale-text-blu">
                Prospect censito correttamente
              </p>
            }
            text={
              <p className="onboarding-modale-content__subtitle">
                Scegli se proseguire con la Consulenza Base o con la Consulenza Goal Based.
              </p>
            }
            show={this.state.modalConfirm}
            close={this.closeModalConfirm}
            idCliente={idCliente}
            goToConsulenza={this.props.goToConsulenza}
            isSelected={this.state.typeConsulenza}>
            <Row>
              <Col s={6} className="center-align">
                <div
                  className="onboarding-modale-icon onboarding-modale-icon__consulenza-base"
                  onClick={() => {
                    CONSULENZA.chiave = 2
                    this.chooseConsulenza('consulenza-base', 'consulenza-goal')
                  }}>
                  <Icon className="onboarding-modale-icon__consulenza-base icon-icone-ips_sv-spesa" medium>
                    &#xe91a;
                  </Icon>
                  <p className="onboarding-modale-icon__consulenza-base onboarding-modale-icon__title">BASE</p>
                  <p className="onboarding-modale-icon__consulenza-base onboarding-modale-icon__title">
                    Consigliata per importi inferiori a 250.000 €
                  </p>
                </div>
              </Col>
              <Col s={6} className="center-align">
                <div
                  className="onboarding-modale-icon onboarding-modale-icon__consulenza-goal"
                  onClick={() => {
                    CONSULENZA.chiave = 3
                    this.chooseConsulenza('consulenza-goal', 'consulenza-base')
                  }}>
                  <Icon className="onboarding-modale-icon__consulenza-goal icon-icone-ips_sv-spesa" medium>
                    &#xe919;
                  </Icon>
                  <p className="onboarding-modale-icon__consulenza-goal onboarding-modale-icon__title">GOAL BASED</p>
                  <p className="onboarding-modale-icon__consulenza-goal onboarding-modale-icon__title">
                    Consigliata per importi superiori a 250.000 €
                  </p>
                </div>
              </Col>
            </Row>
          </ModalConsulenza>
        )} */}
        {this.state.blackList && (
          <ModalWarn
            title={
              <p className="onboarding-modale-content__title center-align onboarding-modale-text-blu">
                Prospect in black list
              </p>
            }
            children={
              <Row>
                <Col s={12} style={{ marginTop: '10px' }}>
                  <Icon class="materIconal-icons" large>
                    warning
                  </Icon>
                </Col>
              </Row>
            }
            text={
              <Row>
                <Col s={12} style={{ marginTop: '10px' }}>
                  <p className="onboarding-modale-content__subtitle center-align">
                    Il Prospect potrebbe essere soggetto ad ulteriori controlli
                  </p>
                </Col>
              </Row>
            }
            closePopUp={this.closeBlackList}
            // close={this.closeModalConfirm}
          />
        )}
        <Col s={12} className="paddingless">
          <section className="main-body no-aside DataGathering">
            <Header tabs={tabs} title={'Censimento Prospect'} activeTabIndex={0} />
            <article className="page-left-content">
              <form onSubmit={handleSubmit(this.handleSubmit)} encType="multipart/form-data">
                <div className="box-container box-professione">
                  <ContattiForm
                    fields={fields}
                    change={change}
                    touch={touch}
                    currentInitial={currentInitial}
                    domini={domini}
                    formValues={formValues}
                    formErrors={formErrors}
                    type={'PHYSICAL_SUBJECT'}
                    cambioStato={() => {
                      setTimeout(() => {
                        this.setState({
                          isSaved: false
                        })
                        if (!this.props.dirty) {
                          this.setState({
                            isSaved: true
                          })
                        }
                      }, 100)
                    }}
                    verifyOtpRequest={verifyOtpRequest}
                    createOtpRequest={createOtpRequest}
                    validationCreateOtp={validationCreateOtp}
                    validationVerifyOtp={this.handleVerifyOtp}
                    otpStatus={otpStatus}
                    saveForm={this.saveForm}
                    verifyAndSaveFlag
                    certifiedPhone={this.state.certifiedPhone}
                    certifiedEmail={this.state.certifiedEmail}
                  />
                </div>
                <div className="box-container box-professione">
                  <Row>
                    <Col s={12}>
                      <h3 className="section-title">Professione</h3>
                    </Col>
                    <Col s={12}>
                      <Field
                        name={SETTORE.id}
                        size={4}
                        label={SETTORE.label}
                        options={mapDominiToOptions(getDominio(domini, SETTORE.dominio), true)}
                        onChange={(field, value) => {
                          change(field, value)
                          setTimeout(() => {
                            this.setState({
                              isSaved: false
                            })
                            if (!this.props.dirty) {
                              this.setState({
                                isSaved: true
                              })
                            }
                          }, 100)
                        }}
                        component={renderSelect}
                      />
                      <Field
                        name={RUOLO.id}
                        size={4}
                        label={RUOLO.label}
                        options={mapDominiToOptions(getDominio(domini, RUOLO.dominio), true)}
                        onChange={(field, value) => {
                          change(field, value)
                          setTimeout(() => {
                            this.setState({
                              isSaved: false
                            })
                            if (!this.props.dirty) {
                              this.setState({
                                isSaved: true
                              })
                            }
                          }, 100)
                        }}
                        component={renderSelect}
                      />
                      <Field
                        name={AZIENDA.id}
                        s={4}
                        maxLength={AZIENDA.maxLength}
                        label={AZIENDA.label}
                        error={formErrors[AZIENDA.id]}
                        value={formValues[AZIENDA.id]}
                        change={(field, value) => {
                          change(field, value)
                          setTimeout(() => {
                            this.setState({
                              isSaved: false
                            })
                            if (!this.props.dirty) {
                              this.setState({
                                isSaved: true
                              })
                            }
                          }, 100)
                        }}
                        touch={touch}
                        component={InputText}
                      />
                    </Col>
                  </Row>
                </div>
                <div className="final-note">* Campi obbligatori</div>
                <Row className="footer-buttons">
                  <div className="footer-buttons__left">
                    <Button
                      type="button"
                      className="footer-buttons__btn back-button"
                      onClick={() => this.props.goToWhenSaved(`/consulenza-base/create`)}>
                      Indietro
                    </Button>
                  </div>
                  <div className="footer-buttons__right">
                    <Button
                      type="submit"
                      className="footer-buttons__btn save-button"
                      // disabled={this.state.isSaved}>
                      disabled={
                        this.state.isSaved || this.props.invalid
                        // this.props.submitting ||
                        // this.props.submitting || this.props.invalid || !this.props.dirty
                      }>
                      <i className="material-icons">save</i>
                      Salva
                    </Button>
                    <Button
                      type="button"
                      className="footer-buttons__btn"
                      disabled={
                        !formWasCompiled ||
                        (formValues[fields.EMAIL.id] && formValues[fields.FLAG_EMAIL.id] === false) ||
                        this.props.invalid
                      }
                      onClick={this.showModalConfirm}>
                      Prosegui
                    </Button>
                  </div>
                </Row>
              </form>
            </article>
          </section>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <ProspectContextConsumer>
        {({ isProspectPersonaGiuridica }) => {
          return isProspectPersonaGiuridica ? this.renderGiuridiche() : this.renderFisiche()
        }}
      </ProspectContextConsumer>
    )
  }
}

DataGathering.propTypes = {
  domini: PropTypes.object,
  dominio: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  dirty: PropTypes.bool,
  submitting: PropTypes.bool,
  invalid: PropTypes.bool,
  validationCreateOtp: PropTypes.func,
  validationVerifyOtp: PropTypes.func,
  createOtpRequest: PropTypes.object,
  verifyOtpRequest: PropTypes.object,
  otpStatus: PropTypes.object,
  postDataGathering: PropTypes.func,
  customer: PropTypes.object,
  fields: PropTypes.object,
  // resetForm: PropTypes.func,
  dataGathering: PropTypes.object,
  postDataGatheringRequest: PropTypes.object,
  initialize: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  getPratica: PropTypes.func,
  praticaRequest: PropTypes.object,
  goToConsulenza: PropTypes.func,
  currentInitial: PropTypes.object,
  goToWhenSaved: PropTypes.func,
  blackList: PropTypes.bool,
  closePopUp: PropTypes.func,
  idIncarico: PropTypes.object,
  inBlacklistCF: PropTypes.any,
  flagCustomerIsBlacklist: PropTypes.any,
  blacklistCFRequest: PropTypes.object,
  isProspectPGAvailable: PropTypes.bool,
  pratica: PropTypes.object,
  getIdProspectByIdProcess: PropTypes.func,
  getIdProspectByIdProcessRequest: PropTypes.object,
  pDob: PropTypes.string,
  postAnagraficaPFActionCreator: PropTypes.func,
  postPFAnagraficaRequest: PropTypes.object,
  idPhysicalPersonReference: PropTypes.any,
  isProspectPersonaGiuridica: PropTypes.bool
}

export default DataGathering
