import React from 'react'
import { reduxForm } from 'redux-form'
import PersoneFisiche from './ProspectFisicheComponent'
import { CREA_PRATICA } from '../../../store/modules/forms/constants'
import fields from '../../../routes/ConsulenzaBase/fields'
import { notBelongToDominio } from '../../../routes/ConsulenzaBaseContainerLayout/utils'

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const {
    NOME,
    COGNOME,
    CODICE_FISCALE,
    ULTERIORI_DATI,
    NAZIONALITA_ULTERIORI_DATI,
    RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
    COMUNE_NASCITA_ULTERIORI_DATI,
    COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
    SESSO_ULTERIORI_DATI,
    DATA_NASCITA_ULTERIORI_DATI,
    EMAIL,
    NAZIONE_NASCITA,
    PROVINCIA_NASCITA,
    COMUNE_NASCITA
  } = fields
  if (values[ULTERIORI_DATI.id]) {
    if (!values[NAZIONALITA_ULTERIORI_DATI.id]) {
      errors[NAZIONALITA_ULTERIORI_DATI.id] = 'Il campo Nazionalità è obbligatorio'
    }
    if (!values[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id]) {
      errors[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] = 'Il campo Residenza Anagrafica è obbligatorio'
    }
    if (!values[COMUNE_NASCITA_ULTERIORI_DATI.id]) {
      errors[COMUNE_NASCITA_ULTERIORI_DATI.id] = 'Il campo Comune di Nascita è obbligatorio'
    }
    if (!values[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id]) {
      errors[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id] = 'Il campo Comune di Nascita Estero è obbligatorio'
    }
    if (!values[DATA_NASCITA_ULTERIORI_DATI.id] && DATA_NASCITA_ULTERIORI_DATI.id !== onfocus) {
      errors[DATA_NASCITA_ULTERIORI_DATI.id] = 'Il campo Data di nascita è obbligatorio'
    }
    if (!(values[SESSO_ULTERIORI_DATI.id] === 'M' || values[SESSO_ULTERIORI_DATI.id] === 'F')) {
      errors[SESSO_ULTERIORI_DATI.id] = 'Il campo Sesso è obbligatorio'
    }
  } else {
    if (!values[CODICE_FISCALE.id]) {
      errors[CODICE_FISCALE.id] = 'Il campo Codice Fiscale è obbligatorio'
    }
  }
  if (!values[NOME.id]) {
    errors[NOME.id] = 'Il campo Nome è obbligatorio'
  } else if (!new RegExp(NOME.validazione).test(values[NOME.id])) {
    errors[NOME.id] = 'Il nome può contenere solo caratteri alfabetici'
  }
  if (!values[COGNOME.id]) {
    errors[COGNOME.id] = 'Il campo Cognome è obbligatorio'
  } else if (!new RegExp(COGNOME.validazione).test(values[COGNOME.id])) {
    errors[COGNOME.id] = 'Il cognome può contenere solo caratteri alfabetici'
  }
  if (values[CODICE_FISCALE.id]) {
    if (values[CODICE_FISCALE.id].length < CODICE_FISCALE.minLength) {
      errors[CODICE_FISCALE.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (values[CODICE_FISCALE.id] === 'Codice fiscale non valido') {
      errors[CODICE_FISCALE.id] = 'Si è verificato un errore nella codifica del codice fiscale'
    } else if (!new RegExp(CODICE_FISCALE.validazione).test(values[CODICE_FISCALE.id])) {
      errors[CODICE_FISCALE.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    } else if (values[CODICE_FISCALE.id].length > CODICE_FISCALE.maxLength || !CODICE_FISCALE.validazione) {
      errors[CODICE_FISCALE.id] = 'Il codice fiscale inserito non esiste'
    }
  }
  if (!!values[EMAIL.id] && !new RegExp(EMAIL.validazione).test(values[EMAIL.id])) {
    errors[EMAIL.id] = "L'indirizzo e-mail non è valido"
  }
  if (!!values[NAZIONE_NASCITA.id] && notBelongToDominio(domini, NAZIONE_NASCITA.dominio, values[NAZIONE_NASCITA.id])) {
    errors[NAZIONE_NASCITA.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA.id] &&
    notBelongToDominio(domini, PROVINCIA_NASCITA.dominio, values[PROVINCIA_NASCITA.id])
  ) {
    errors[PROVINCIA_NASCITA.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA.id] &&
    !!values[COMUNE_NASCITA.id] &&
    notBelongToDominio(domini, COMUNE_NASCITA.dominio, values[COMUNE_NASCITA.id])
  ) {
    errors[COMUNE_NASCITA.id] = 'Comune non riconosciuto'
  }

  if (
    !!values[NAZIONALITA_ULTERIORI_DATI.id] &&
    notBelongToDominio(domini, NAZIONALITA_ULTERIORI_DATI.dominio, values[NAZIONALITA_ULTERIORI_DATI.id])
  ) {
    errors[NAZIONALITA_ULTERIORI_DATI.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] &&
    notBelongToDominio(
      domini,
      RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.dominio,
      values[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id]
    )
  ) {
    errors[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] = 'Residenza non riconosciuta'
  }
  if (
    !!values[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] &&
    !!values[COMUNE_NASCITA_ULTERIORI_DATI.id] &&
    notBelongToDominio(domini, COMUNE_NASCITA_ULTERIORI_DATI.dominio, values[COMUNE_NASCITA_ULTERIORI_DATI.id])
  ) {
    errors[COMUNE_NASCITA_ULTERIORI_DATI.id] = 'Comune non riconosciuto'
  }
  if (values[NOME.id] && values[COGNOME.id]) {
    let maxLength = values[NOME.id].length + values[COGNOME.id].length
    if (maxLength > 50) {
      errors[COGNOME.id] = ' '
      errors[NOME.id] = 'La lunghezza dei campi Nome e Cognome non deve superare i 50 caratteri'
    }
  }

  return errors
}

const warn = values => {
  const warn = {}
  const { NOME, COGNOME, DATA_NASCITA, SESSO, NAZIONE_NASCITA, PROVINCIA_NASCITA, COMUNE_NASCITA } = fields

  if (!values[NOME.id]) {
    warn[NOME.id] = 'Il campo Nome è obbligatorio'
  }
  if (!values[COGNOME.id]) {
    warn[COGNOME.id] = 'Il campo Cognome è obbligatorio'
  }
  if (!values[DATA_NASCITA.id]) {
    warn[DATA_NASCITA.id] = 'Il campo Data di Nascita è obbligatorio'
  }
  if (!values[SESSO.id]) {
    warn[SESSO.id] = 'Il campo Sesso è obbligatorio'
  }
  if (!values[NAZIONE_NASCITA.id]) {
    warn[NAZIONE_NASCITA.id] = 'Il campo Nazione di Nascita è obbligatorio'
  }
  console.log(values[NAZIONE_NASCITA.id])
  if (values[NAZIONE_NASCITA.id] === 'ITA') {
    if (!values[PROVINCIA_NASCITA.id]) {
      warn[PROVINCIA_NASCITA.id] = 'Il campo Provincia di Nascita è obbligatorio'
    }
    if (!values[COMUNE_NASCITA.id]) {
      warn[COMUNE_NASCITA.id] = 'Il campo Comune di Nascita è obbligatorio'
    }
  }
  return warn
}

const ConnectedComponent = reduxForm({
  form: CREA_PRATICA,
  validate,
  warn
})(PersoneFisiche)

export default React.forwardRef((props, ref) => <ConnectedComponent innerRef={ref} {...props} />)
