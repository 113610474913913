import React from 'react'
// import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-materialize'
// import { createHashHistory } from 'history'

// export const history = createHashHistory()

export const FooterModal = props => {
  if (props.id && props.route) {
    return (
      <Row>
        <Col s={6}>
          <Button
            className="custom-btn primary-reverse transparent"
            onClick={() => {
              props.modalHandler('close')
              // props.resetSelectedState()
            }}>
            Esci
          </Button>
        </Col>

        <Col s={6} className="right-align">
          {!props.later &&
            (props.url === `/consulenza-base/${props.idPratica}/riepilogo-cliente` ? (
              <Button
                className="btn custom-btn primary "
                disabled={props.route === ''}
                onClick={() => {
                  if (props.url === `/consulenza-base/${props.idPratica}/riepilogo-cliente`)
                    props.updateStatoPratica(props.idPratica, 2, props.flag)
                  props.nextStep()
                }}>
                Prosegui
              </Button>
            ) : (
              <Button
                className="btn custom-btn primary "
                disabled={props.route === ''}
                onClick={() => {
                  // siamo in AML
                  if (props.url && props.url.search('compilazione-antiriciclaggio') !== -1) {
                    if (props.nextStep) {
                      props.nextStep()
                    } else {
                      props.push(`/${props.route}/${props.idPratica}`)
                    }
                  } else {
                    // tutti gli altri casi
                    props.updateStatoPratica(props.idPratica, 2, props.flag).then(() => {
                      if (props.nextStep) {
                        props.nextStep()
                      } else {
                        props.push(`/${props.route}/${props.idPratica}`)
                      }
                    })
                  }
                }}>
                Prosegui
              </Button>
            ))}

          {/* !props.later && (
            <NavLink
              className="btn custom-btn primary"
              to={`/${props.route}/${props.id}`}
              disabled={props.route === ''}>
              Prosegui
            </NavLink>
          ) */}
          {props.later && (
            <Button
              className="btn custom-btn primary"
              disabled={props.route === ''}
              onClick={() => {
                props.save(false)
                // props.resetSelectedState()
                // this.props.history.push('/')
              }}>
              salva e firma dopo
            </Button>
          )}
        </Col>
      </Row>
    )
  } else {
    return (
      <Row>
        <Col s={6}>
          <Button
            className="custom-btn primary-reverse transparent"
            onClick={() => {
              if (!props.url) {
                props.resetSelectedState()
              }
              props.modalHandler('close')
            }}>
            Esci
          </Button>
        </Col>
        <Col s={6} className="right-align">
          {!props.later && !props.proseguiDisabled && (
            <Button
              className="btn custom-btn primary "
              onClick={() => {
                // if (props.url === `/consulenza-base/${props.idPratica}/riepilogo-cliente`)
                //   props.updateStatoPratica(props.idPratica, 2, props.flag)
                props.nextStep()
              }}
              disabled={
                props.route === '' ||
                props.next === '' ||
                (props.tipoFirma === undefined && props.url === `/consulenza-base/${props.idPratica}/riepilogo-cliente`)
              }>
              Prosegui
            </Button>
          )}
          {props.later && (
            <Button
              className="btn custom-btn primary"
              disabled={
                props.route === '' ||
                props.next === '' ||
                (props.tipoFirma === undefined && props.url === `/consulenza-base/${props.idPratica}/riepilogo-cliente`)
              }
              onClick={() => {
                props.save(false)
                // props.resetSelectedState()
                //  this.props.history.push('/')
              }}>
              salva e firma dopo
            </Button>
          )}
        </Col>
      </Row>
    )
  }
}
FooterModal.propTypes = {
  modalHandler: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  route: PropTypes.string,
  next: PropTypes.string,
  resetSelectedState: PropTypes.func,
  history: PropTypes.object
}
