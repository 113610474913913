import React from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'

const StrumentiRow = ({ domanda, risposte, operazione, check, change, onCheckboxChange }) => {
  const { idDomanda, idDomandaDipendente, testoDomanda, subTestoDomanda } = domanda
  const { RISPOSTE_CONOSCENZA, RISPOSTE_OPERAZIONI, RISPOSTE_CHECK } = risposte

  return (
    <div className="instruments-row">
      <div className="instruments-row-col-1">
        <div className="instruments-col-border">
          <b>{testoDomanda}</b>
          <div>{subTestoDomanda || '-'}</div>
        </div>
      </div>
      <div className="instruments-row-col-2">
        <div className="radio-horizontal instruments-col-border">
          {Object.keys(RISPOSTE_CONOSCENZA).map(el => (
            <div className="radio" key={`strumenti-row-${el}-${idDomanda}`}>
              <Field
                name={`field_${idDomanda}_conoscenza`}
                className="with-gap"
                type="radio"
                onChange={() => {
                  if (el === 'NO') {
                    change(`field_${idDomandaDipendente}`, '')
                  }
                }}
                id={`risposte-conoscenza-${el.toLowerCase()}-${idDomanda}`}
                value={'' + RISPOSTE_CONOSCENZA[el].idRisposta}
                component="input"
              />
              <label htmlFor={`risposte-conoscenza-${el.toLowerCase()}-${idDomanda}`} />
            </div>
          ))}
        </div>
      </div>
      <div className="instruments-row-col-3">
        <div className="radio-horizontal instruments-col-border">
          {Object.keys(RISPOSTE_OPERAZIONI)
            .slice(0, 3)
            .map(el => (
              <div className="radio" key={`strumenti-row-${el}-${idDomanda}`}>
                <Field
                  name={`field_${idDomanda}_operazioni`}
                  className="with-gap"
                  type="radio"
                  id={`risposte-operazioni-${el.toLowerCase()}-${idDomanda}`}
                  value={'' + RISPOSTE_OPERAZIONI[el].idRisposta}
                  component="input"
                />
                <label htmlFor={`risposte-operazioni-${el.toLowerCase()}-${idDomanda}`} />
              </div>
            ))}
          <div className="radio">
            <Field
              name={`field_${idDomanda}_operazioni`}
              className="with-gap"
              type="radio"
              id={`risposte-operazioni-no-${idDomanda}`}
              onChange={() => change(`field_${idDomanda}_check`, '')}
              value={'' + RISPOSTE_OPERAZIONI.NO.idRisposta}
              component="input"
            />
            <label htmlFor={`risposte-operazioni-no-${idDomanda}`} />
          </div>
          <div className="check">
            <input
              className="filled-in vertical"
              type="checkbox"
              disabled={'' + operazione === '' + RISPOSTE_OPERAZIONI.NO.idRisposta}
              id={`risposte-operazioni-gt50-${idDomanda}`}
              onChange={() => onCheckboxChange(`field_${idDomanda}_check`, check ? '' : RISPOSTE_CHECK.GT50.idRisposta)}
              checked={check}
            />
            <label htmlFor={`risposte-operazioni-gt50-${idDomanda}`} />
          </div>
        </div>
      </div>
    </div>
  )
}

StrumentiRow.propTypes = {
  domanda: PropTypes.object,
  risposte: PropTypes.object,
  operazione: PropTypes.any,
  check: PropTypes.any,
  change: PropTypes.func,
  onCheckboxChange: PropTypes.func
}

export default StrumentiRow
