import React from 'react'
import { PropTypes } from 'prop-types'
import { Tooltip } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import StrumentiRow from './StrumentiRow'

const Strumenti = ({
  listaDomande = [],
  field = {},
  fieldDipendenze = {},
  formValues = {},
  change,
  onCheckboxChange
}) => {
  const strumentiTemplate = listaDomande.find(c => c.idDomanda === field.templateId)
  const domandeDipendenze =
    listaDomande.find(d => d.idDomanda === fieldDipendenze.templateId).strutturaInternaDomande.domande || []
  const listaDominiRisposte = strumentiTemplate.strutturaInternaDomande.listaDominiRisposte
  const domande = strumentiTemplate.strutturaInternaDomande.domande.map((domanda, index) => {
    return {
      ...domanda,
      idDomandaDipendente: (domandeDipendenze[index] || {}).idDomanda
    }
  })
  const risposte = {
    RISPOSTE_CONOSCENZA: {
      SI: listaDominiRisposte[0].risposte.find(d => d.descRisposta === 'SI'),
      NO: listaDominiRisposte[0].risposte.find(d => d.descRisposta === 'NO'),
      PARZ: listaDominiRisposte[0].risposte.find(d => d.descRisposta === 'PARZ.')
    },
    RISPOSTE_OPERAZIONI: {
      GT10: listaDominiRisposte[1].risposte[0],
      GT5: listaDominiRisposte[1].risposte[1],
      LT5: listaDominiRisposte[1].risposte[2],
      NO: listaDominiRisposte[1].risposte[3]
    },
    RISPOSTE_CHECK: {
      GT50: listaDominiRisposte[1].risposte[4]
    }
  }

  return (
    <div className="instruments-table">
      <div className="instruments-header">
        <div>
          <div className="instruments-col-border" />
        </div>
        <div>
          <div className="instruments-col-border">CONOSCENZA</div>
        </div>
        <div>
          <div className="instruments-col-border">
            OPERAZIONI PER TRIMESTRE <br />
            (ultimi 12/18 mesi)
          </div>
        </div>
      </div>
      <div className="instruments-sub-header">
        <div className="instruments-row-col-1">
          <div className="instruments-col-border" />
        </div>
        <div className="instruments-row-col-2">
          <div className="instruments-col-border">
            <div>
              <div>Si</div>
              <div>No</div>
              <div>Parz.</div>
            </div>
          </div>
        </div>
        <div className="instruments-row-col-3">
          <div className="instruments-col-border">
            <div>
              <div>Più di 10</div>
              <div>Tra 5 e 10</div>
              <div>Meno di 5</div>
              <div>No</div>
              <div className="instruments-col-select">
                <div>
                  &gt; 50%
                  <Tooltip
                    theme="dark"
                    arrow
                    title="Indica gli strumenti (fino ad un massimo di 2) detenuti per un importo complessivo superiore al 50% del patrimonio">
                    <i className="icon-info-circle" />
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {domande.map(domanda => (
        <StrumentiRow
          key={domanda.idDomanda}
          domanda={domanda}
          risposte={risposte}
          operazione={formValues[`field_${domanda.idDomanda}_operazioni`]}
          check={formValues[`field_${domanda.idDomanda}_check`]}
          change={change}
          onCheckboxChange={onCheckboxChange}
        />
      ))}
    </div>
  )
}

Strumenti.propTypes = {
  listaDomande: PropTypes.array,
  field: PropTypes.object,
  fieldDipendenze: PropTypes.object,
  formValues: PropTypes.object,
  change: PropTypes.func,
  onCheckboxChange: PropTypes.func
}

export default Strumenti
