import { connect } from 'react-redux'
import ToggleBlurText from './ToggleBlurTextComponent'
import { setToggleBlur } from '../../store/modules/toggleBlur/actions'

const mapDispatchToProps = dispatch => ({
  setToggleBlur: boolean => dispatch(setToggleBlur(boolean))
})

const mapStateToProps = state => ({
  toggleBlur: state.toggleBlur.toggleBlur
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToggleBlurText)
