import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { MIFID_EDUCAZIONE_PF, MIFID_EDUCAZIONE_PG } from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import AccordionHeader from '../../../../components/AccordionHeader'
import SurveyRadio from '../../../../components/SurveyRadio'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import SurveyHr from '../../../../components/SurveyHr'
import YesNo from '../YesNo'
import { Field } from 'redux-form'
import { renderYesNoQuestion } from '../../../../components/Forms/renderFields'
import SubRadio from '../SubRadio'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import { setValidators, setRisposte, isSaveDisabled, getFormValues } from '../mifidValidationUtils'

let MIFID_ACTIVE = null
let fields = null

class EducazioneFinanziaria extends React.Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, values: {} }
    this.submit = this.submit.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.EDUCAZIONE)
    fields = allFields[2][MIFID_ACTIVE]
    // Imposto i validators in modo da poter fare i controlli (sul padre) tra sezioni diverse
    setValidators(this.props.template.listaDomande)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.state.values)
      this.props.resetForm(MIFID_ACTIVE)
      this.setState({ saved: true, values: {} })
    }
    setRisposte(getFormValues(this.props.currentFormValues))
  }

  submit(values) {
    const { onSave } = this.props
    const submittedValues = Object.keys(values).reduce((res, cur) => {
      return { ...res, [cur.replace('field_', '')]: values[cur] }
    }, {})
    this.setState({ values })
    onSave(
      Object.keys(submittedValues).reduce((res, cur) => {
        if (cur.includes('_')) {
          const [idDomanda, idRisposta] = cur.split('_')
          const curRisp = res.find(r => r.idDomanda === idDomanda)
          let listaRisposte = []
          if (submittedValues[cur]) {
            listaRisposte = [{ idRisposta }]
          }
          if (curRisp) {
            listaRisposte = [...curRisp.listaRisposte, ...listaRisposte]
            return [...res.filter(r => r.idDomanda !== idDomanda), { idDomanda, listaRisposte }]
          }
          return [...res, { idDomanda, listaRisposte }]
        } else {
          return [...res, { idDomanda: cur, listaRisposte: [{ idRisposta: submittedValues[cur] }] }]
        }
      }, [])
    )
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      formValues,
      change,
      status,
      template: { descSezione, listaDomande },
      isFetching
    } = this.props

    return (
      <CollapsibleItem
        header={descSezione}
        itemKey={MIFID_ACTIVE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {/* NUOVA MIFID PF */}
          {MIFID_ACTIVE === MIFID_EDUCAZIONE_PF && (
            <React.Fragment>
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.MERCATI_FINANZIARI.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.MERCATI_FINANZIARI.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.AGGIORNAMENTO_MERCATI.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.AGGIORNAMENTO_MERCATI.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.INTERESSE_INVESTIMENTI.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.INTERESSE_INVESTIMENTI.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.PENSIONAMENTO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.PENSIONAMENTO.templateId)}
                  />
                </Col>
              </Row>
              <SurveyHr hideHr />
              <h2 className="collapsible-section-title">Si o no?</h2>
              <Row
                className={
                  this.props.fieldsWithErrors.includes(fields.CRISI_BANCARIE.templateId) ? 'fieldHasErrors' : ''
                }>
                <YesNo
                  field={fields.CRISI_BANCARIE}
                  listaDomande={listaDomande}
                  formValues={formValues}
                  change={change}
                />
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col
                  s={6}
                  className={
                    this.props.fieldsWithErrors.includes(fields.GRAFICO_PORTAFOGLIO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.GRAFICO_PORTAFOGLIO.templateId)}
                  />
                </Col>
                <Col s={6} className="center">
                  <img
                    className="responsive-img marginTop20"
                    src={listaDomande.find(d => d.idDomanda === fields.GRAFICO_PORTAFOGLIO.templateId).pathImage}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
          {/* NUOVA MIFID PG */}
          {MIFID_ACTIVE === MIFID_EDUCAZIONE_PG && (
            <React.Fragment>
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.DIPENDENTI.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <Field
                    name={`field_${fields.DIPENDENTI.templateId}`}
                    label={listaDomande.find(c => c.idDomanda === fields.DIPENDENTI.templateId).testoDomanda}
                    handlerFn={value => change(`field_${fields.DIPENDENTI.templateId}`, value)}
                    options={listaDomande.find(c => c.idDomanda === fields.DIPENDENTI.templateId).listaRisposte}
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={this.props.fieldsWithErrors.includes(fields.WELFARE.templateId) ? 'fieldHasErrors' : ''}>
                  <Field
                    name={`field_${fields.WELFARE.templateId}`}
                    label={listaDomande.find(c => c.idDomanda === fields.WELFARE.templateId).testoDomanda}
                    handlerFn={value => change(`field_${fields.WELFARE.templateId}`, value)}
                    options={listaDomande.find(c => c.idDomanda === fields.WELFARE.templateId).listaRisposte}
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={this.props.fieldsWithErrors.includes(fields.KEY_MAN.templateId) ? 'fieldHasErrors' : ''}>
                  <Field
                    name={`field_${fields.KEY_MAN.templateId}`}
                    label={listaDomande.find(c => c.idDomanda === fields.KEY_MAN.templateId).testoDomanda}
                    handlerFn={value => change(`field_${fields.KEY_MAN.templateId}`, value)}
                    options={listaDomande.find(c => c.idDomanda === fields.KEY_MAN.templateId).listaRisposte}
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.POLIZZE_ASSICURATIVE.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <Field
                    name={`field_${fields.POLIZZE_ASSICURATIVE.templateId}`}
                    label={listaDomande.find(c => c.idDomanda === fields.POLIZZE_ASSICURATIVE.templateId).testoDomanda}
                    handlerFn={value => change(`field_${fields.POLIZZE_ASSICURATIVE.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.POLIZZE_ASSICURATIVE.templateId).listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col
                  s={12}
                  className={this.props.fieldsWithErrors.includes(fields.FATTURATO.templateId) ? 'fieldHasErrors' : ''}>
                  <SubRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.FATTURATO.templateId)}
                  />
                </Col>
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.ATTIVITA_SOSTENIBILI.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <Field
                    name={`field_${fields.ATTIVITA_SOSTENIBILI.templateId}`}
                    label={listaDomande.find(c => c.idDomanda === fields.ATTIVITA_SOSTENIBILI.templateId).testoDomanda}
                    handlerFn={value => change(`field_${fields.ATTIVITA_SOSTENIBILI.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.ATTIVITA_SOSTENIBILI.templateId).listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.BILANCIO_SOCIALE.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <Field
                    name={`field_${fields.BILANCIO_SOCIALE.templateId}`}
                    label={listaDomande.find(c => c.idDomanda === fields.BILANCIO_SOCIALE.templateId).testoDomanda}
                    handlerFn={value => change(`field_${fields.BILANCIO_SOCIALE.templateId}`, value)}
                    options={listaDomande.find(c => c.idDomanda === fields.BILANCIO_SOCIALE.templateId).listaRisposte}
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.FONDO_PENSIONE_CATEGORIA.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <Field
                    name={`field_${fields.FONDO_PENSIONE_CATEGORIA.templateId}`}
                    label={
                      listaDomande.find(c => c.idDomanda === fields.FONDO_PENSIONE_CATEGORIA.templateId).testoDomanda
                    }
                    handlerFn={value => change(`field_${fields.FONDO_PENSIONE_CATEGORIA.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.FONDO_PENSIONE_CATEGORIA.templateId).listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.FONDO_PENSIONE_APERTO.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <Field
                    name={`field_${fields.FONDO_PENSIONE_APERTO.templateId}`}
                    label={listaDomande.find(c => c.idDomanda === fields.FONDO_PENSIONE_APERTO.templateId).testoDomanda}
                    handlerFn={value => change(`field_${fields.FONDO_PENSIONE_APERTO.templateId}`, value)}
                    options={
                      listaDomande.find(c => c.idDomanda === fields.FONDO_PENSIONE_APERTO.templateId).listaRisposte
                    }
                    component={renderYesNoQuestion}
                  />
                </Col>
              </Row>
              <SurveyHr hideHr />
              <Row>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.UTILIZZO_TESORERIA.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.UTILIZZO_TESORERIA.templateId)}
                  />
                </Col>
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.SCADENZA_DEBITO.templateId) ? 'fieldHasErrors' : ''
                  }>
                  <SurveyRadio
                    formValues={formValues}
                    template={listaDomande.find(d => d.idDomanda === fields.SCADENZA_DEBITO.templateId)}
                  />
                </Col>
              </Row>
            </React.Fragment>
          )}
          {/* NUOVA MIFID PD */}
          {/* {MIFID_ACTIVE === MIFID_EDUCAZIONE_PD && (
            <Row>
              <Col s={12}>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.RICAVI_MEDI.templateId)}
                />
              </Col>
              <Col s={12}>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.RICAVI_FUTURO.templateId)}
                />
              </Col>
            </Row>
          )} */}
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching || isSaveDisabled(listaDomande)} />
        </form>
      </CollapsibleItem>
    )
  }
}

EducazioneFinanziaria.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  change: PropTypes.func,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  template: PropTypes.object,
  onSave: PropTypes.func,
  currentFormValues: PropTypes.object,
  fieldsWithErrors: PropTypes.array
}

export default EducazioneFinanziaria
