import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Row, Col, Button, Icon } from 'react-materialize'
import InputText from '../../Inputs/Text'
import ModalPrivacy from '../../Modals/Privacy'
import ModalVerifica from '../../Modals/VerificaDatabase'
import CalcoloCodiceFiscale from '../CalcoloCodiceFiscale'
import {
  getAutocompleteDefaultOption,
  mapDominiToAutocomplete,
  getDominio
} from '../../../routes/ConsulenzaBaseContainerLayout/utils'
import { renderAutocomplete, renderDatePicker, renderSelect } from '../renderFields'
import { checkInvalidBirthdate } from '../../../utils'
import Confirm from '../../Confirm'
import Header from '../../Header'

class ProspectFisiche extends Component {
  constructor(props) {
    super(props)

    this.state = {
      codiceFiscale: '',
      continue: true
    }
    this.createBodyRequestForPrivacy = this.createBodyRequestForPrivacy.bind(this)
  }
  componentDidUpdate(prevProps) {
    const { idPratica, moduleSendRequest } = this.props
    if (prevProps.moduleSendRequest.fetching && !moduleSendRequest.fetching && !moduleSendRequest.error) {
      this.props.history.push(`/consulenza-base/${idPratica}/data-gathering`)
      // this.props.history.push(`/consulenza-base-PG/${this.props.idPratica}/data-gathering`)
    }

    // if (
    //   prevProps.privacySendRequest.fetching &&
    //   !privacySendRequest.fetching &&
    //   !privacySendRequest.error
    // ) {
    //   this.props.history.push(`/consulenza-base/${idPratica}/data-gathering`)
    //   // this.props.history.push(`/consulenza-base-PG/${this.props.idPratica}/data-gathering`)
    // }
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      codiceFiscale: ''
    })
  }

  createBodyRequestForPrivacy(fileUploadAcceptPrivacy, fileUploadAcceptPrivacyThumb) {
    const { formValues, fields } = this.props
    const {
      NOME,
      COGNOME,
      CODICE_FISCALE,
      NAZIONALITA_ULTERIORI_DATI,
      COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
      RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
      COMUNE_NASCITA_ULTERIORI_DATI,
      DATA_NASCITA_ULTERIORI_DATI,
      SESSO_ULTERIORI_DATI
    } = fields
    if (formValues[NAZIONALITA_ULTERIORI_DATI.id] === '1') {
      return {
        name: formValues[NOME.id],
        surname: formValues[COGNOME.id],
        taxCode: formValues[CODICE_FISCALE.id] == null ? null : formValues[CODICE_FISCALE.id].toUpperCase(),
        isPrivacyAcceptanceDone: true,
        cityBirthDescription: null, // comune estero
        citizenshipCode:
          formValues[NAZIONALITA_ULTERIORI_DATI.id] == null ? null : formValues[NAZIONALITA_ULTERIORI_DATI.id], // Nazione
        cityBirthCode:
          formValues[COMUNE_NASCITA_ULTERIORI_DATI.id] == null ? null : formValues[COMUNE_NASCITA_ULTERIORI_DATI.id], // codice comune
        registeredResidenceCode:
          formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] == null
            ? null
            : formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id], // residenza
        fileUploadAcceptPrivacy: fileUploadAcceptPrivacy,
        fileUploadAcceptPrivacyThumb: fileUploadAcceptPrivacyThumb,
        subjectType: 'PHYSICAL_SUBJECT',
        birthDate: formValues[DATA_NASCITA_ULTERIORI_DATI.id],
        emailAddress: null,
        sex: formValues[SESSO_ULTERIORI_DATI.id]
      }
    } else {
      return {
        name: formValues[NOME.id],
        surname: formValues[COGNOME.id],
        taxCode: formValues[CODICE_FISCALE.id] == null ? null : formValues[CODICE_FISCALE.id].toUpperCase(),
        isPrivacyAcceptanceDone: true,
        cityBirthCode: null, // codice comune
        cityBirthDescription: formValues[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id], // comune estero
        citizenshipCode:
          formValues[NAZIONALITA_ULTERIORI_DATI.id] == null ? null : formValues[NAZIONALITA_ULTERIORI_DATI.id], // Nazione
        registeredResidenceCode:
          formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] == null
            ? null
            : formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id], // residenza
        fileUploadAcceptPrivacy: fileUploadAcceptPrivacy,
        fileUploadAcceptPrivacyThumb: fileUploadAcceptPrivacyThumb,
        subjectType: 'PHYSICAL_SUBJECT',
        birthDate: formValues[DATA_NASCITA_ULTERIORI_DATI.id],
        emailAddress: null,
        sex: formValues[SESSO_ULTERIORI_DATI.id]
      }
    }
  }

  render() {
    const {
      handleSubmit,
      change,
      domini,
      isUserAvailable,
      checkCFRequest: { fetching },
      history: { push },
      formValues = {},
      formErrors = {},
      formWarnings = {},
      submitting,
      touch,
      invalid,
      isSend,
      computeCFRequest,
      initialLoading,
      showModal,
      handleModal,
      tabs,
      handleSubmitPF,
      fields,
      checkNazione,
      checkNazionalitaUlterioriDati,
      checkProvincia,
      computeCFstate,
      handleComputeCFstate,
      handleGetCF,
      updateDominio,
      verified,
      verificaModale,
      handleVerified,
      sended,
      imageupload,
      openCamera,
      interventoInSede,
      close,
      innerRef,
      untouch,
      idPratica
    } = this.props

    const {
      NOME,
      COGNOME,
      CODICE_FISCALE,
      ULTERIORI_DATI,
      NAZIONALITA_ULTERIORI_DATI,
      RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
      COMUNE_NASCITA_ULTERIORI_DATI,
      COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
      DATA_NASCITA_ULTERIORI_DATI,
      SESSO_ULTERIORI_DATI
    } = fields

    if (!initialLoading) return null
    return (
      <Row className="container-home paddingless marginless">
        <ModalPrivacy
          show={showModal}
          fields={fields}
          parentFormValues={formValues}
          createBodyRequestForPrivacy={this.createBodyRequestForPrivacy}
          closeHandler={handleModal}
          continueHandler={value => {
            if (value === 'gotoData') {
              push(`/consulenza-base/${idPratica}/data-gathering`)
            }
            this.setState({ continue: true })
          }} // da spostare
          modalHandler={handleModal}
          history={this.props.history.push}
        />
        <Col s={12} className="paddingless">
          <section className="main-body no-aside consulenza-base-codice-fiscale">
            <Header tabs={tabs} title={'Censimento Prospect'} activeTabIndex={0} />
            <article className="page-left-content">
              <form onSubmit={handleSubmit(handleSubmitPF)} encType="multipart/form-data">
                <div className="box-container box-codice-fiscale">
                  <Row>
                    <Col s={12}>
                      <Field
                        name={NOME.id}
                        s={6}
                        maxLength={NOME.maxLength}
                        label={NOME.label}
                        error={formErrors[NOME.id]}
                        value={formValues[NOME.id]}
                        change={change}
                        touch={touch}
                        component={InputText}
                      />
                      <Field
                        name={COGNOME.id}
                        s={6}
                        maxLength={COGNOME.maxLength}
                        label={COGNOME.label}
                        error={formErrors[COGNOME.id]}
                        value={formValues[COGNOME.id]}
                        change={change}
                        touch={touch}
                        component={InputText}
                      />
                    </Col>
                  </Row>
                  <CalcoloCodiceFiscale
                    computeCFstate={computeCFstate}
                    onClick={handleComputeCFstate}
                    fields={fields}
                    formValues={formValues}
                    domini={domini}
                    change={change}
                    checkNazione={checkNazione}
                    checkProvincia={checkProvincia}
                    computeCFRequest={computeCFRequest.fetching}
                    formWarnings={formWarnings}
                    handleGetCF={handleGetCF}
                    updateDominio={updateDominio}
                    notVerified={handleVerified}
                    formErrors={formErrors}
                    ulterioriDatiChange={() => {
                      if (formValues[ULTERIORI_DATI.id]) change(ULTERIORI_DATI.id, false)
                    }}
                  />
                  <Row className="m-top20">
                    <Col s={11}>
                      <Field
                        name={CODICE_FISCALE.id}
                        s={12}
                        minLength={CODICE_FISCALE.minLength}
                        maxLength={CODICE_FISCALE.maxLength}
                        label={`${formValues[ULTERIORI_DATI.id] ? '' : CODICE_FISCALE.label}`}
                        disabled={formValues[ULTERIORI_DATI.id]}
                        error={formErrors[CODICE_FISCALE.id]}
                        value={formValues[CODICE_FISCALE.id]}
                        change={(field, value) => {
                          handleVerified(false)
                          change(field, value)
                        }}
                        touch={touch}
                        component={InputText}
                        formatter={value => (value ? value.toUpperCase() : '')}
                      />
                    </Col>
                    <Col s={1} className="col-barcode">
                      <input
                        name="image"
                        type="file"
                        ref={input => (innerRef.current = input)}
                        accept=".jpg, .png, .jpeg, .bmp, .tif, .tiff|images/*"
                        capture
                      />
                      <i className="icon-barcode" onClick={openCamera} />
                    </Col>
                  </Row>
                  <Row className={`m-top10${formValues[ULTERIORI_DATI.id] ? '' : ' m-bottom50'}`}>
                    <Col s={12} className="hr">
                      <hr />
                      <a
                        onClick={() => {
                          handleVerified(false)
                          // this.setState({ verified: false, verificaModale: true }) // !this.state.verified
                          change(ULTERIORI_DATI.id, !formValues[ULTERIORI_DATI.id])
                          if (computeCFstate) {
                            handleComputeCFstate()
                          }
                          if (formValues[CODICE_FISCALE.id] && formValues[CODICE_FISCALE.id] !== '') {
                            this.state.codiceFiscale = formValues[CODICE_FISCALE.id]
                            change(CODICE_FISCALE.id, '')
                          }
                          if (!formValues[ULTERIORI_DATI.id] === true) {
                            untouch(
                              NAZIONALITA_ULTERIORI_DATI.id,
                              RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id,
                              COMUNE_NASCITA_ULTERIORI_DATI.id,
                              COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id,
                              DATA_NASCITA_ULTERIORI_DATI.id,
                              SESSO_ULTERIORI_DATI.id
                            )
                          } else if (this.state.codiceFiscale !== '') {
                            change(CODICE_FISCALE.id, this.state.codiceFiscale)
                          }
                        }}
                        className="indirizzo-alternativo-button">
                        {`${formValues[ULTERIORI_DATI.id] ? 'Nascondi Ulteriori Dati' : 'Ulteriori Dati'}`}
                        <i className="material-icons">{`expand_${formValues[ULTERIORI_DATI.id] ? 'less' : 'more'}`}</i>
                      </a>
                    </Col>
                  </Row>
                  <div
                    className={`box-ulteriori-dati calcolo-cf${
                      formValues[ULTERIORI_DATI.id] ? ' calcolo-cf-active' : ''
                    }`}>
                    <div>
                      <Row>
                        <Col s={6}>
                          <Field
                            name={NAZIONALITA_ULTERIORI_DATI.id}
                            size={12}
                            label={NAZIONALITA_ULTERIORI_DATI.label}
                            threshold={0}
                            defaultValue={getAutocompleteDefaultOption(
                              domini.listaDomini,
                              NAZIONALITA_ULTERIORI_DATI.dominio,
                              formValues[NAZIONALITA_ULTERIORI_DATI.id]
                            )}
                            options={mapDominiToAutocomplete(getDominio(domini, NAZIONALITA_ULTERIORI_DATI.dominio))}
                            handleChange={(field, value) => {
                              change(field, value)
                              if (formValues[COMUNE_NASCITA_ULTERIORI_DATI.id]) {
                                change(COMUNE_NASCITA_ULTERIORI_DATI.id, '')
                              }
                              if (formValues[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id]) {
                                change(COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id, '')
                              }
                              handleVerified(false)
                              untouch(RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id)
                            }}
                            component={renderAutocomplete}
                          />
                        </Col>
                        <Col s={6}>
                          <Field
                            name={RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id}
                            s={12}
                            label={RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.label}
                            threshold={0}
                            defaultValue={getAutocompleteDefaultOption(
                              domini.listaDomini,
                              RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.dominio,
                              formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id]
                            )}
                            options={mapDominiToAutocomplete(
                              getDominio(domini, RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.dominio)
                            )}
                            handleChange={(field, value) => {
                              change(field, value)
                              handleVerified(false)
                              untouch(RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id)
                            }}
                            component={renderAutocomplete}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {(checkNazionalitaUlterioriDati(true) || !formValues[NAZIONALITA_ULTERIORI_DATI.id]) && (
                          <Col s={6}>
                            <Field
                              name={COMUNE_NASCITA_ULTERIORI_DATI.id}
                              s={12}
                              threshold={0}
                              label={COMUNE_NASCITA_ULTERIORI_DATI.label}
                              /* disabled={!this.checkProvincia(formValues[PROVINCIA_NASCITA.id])} */
                              defaultValue={getAutocompleteDefaultOption(
                                domini.listaDomini,
                                COMUNE_NASCITA_ULTERIORI_DATI.dominio,
                                formValues[COMUNE_NASCITA_ULTERIORI_DATI.id]
                              )}
                              options={mapDominiToAutocomplete(
                                getDominio(domini, COMUNE_NASCITA_ULTERIORI_DATI.dominio)
                              )}
                              handleChange={(field, value) => {
                                change(field, value)
                                handleVerified(false)
                                untouch(COMUNE_NASCITA_ULTERIORI_DATI.id)
                              }}
                              component={renderAutocomplete}
                            />
                          </Col>
                        )}
                        {!checkNazionalitaUlterioriDati(true) && formValues[NAZIONALITA_ULTERIORI_DATI.id] && (
                          <Col s={6} className="comune-nascita-estero">
                            <Field
                              name={COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id}
                              s={12}
                              threshold={0}
                              label={COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.label}
                              error={formErrors[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id]}
                              change={(field, value) => {
                                change(field, value)
                                handleVerified(false)
                              }}
                              component={InputText}
                              value={formValues[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id]}
                              touch={touch}
                            />
                          </Col>
                        )}
                        <Col s={6}>
                          <Field
                            name={DATA_NASCITA_ULTERIORI_DATI.id}
                            touch={touch}
                            untouch={untouch}
                            s={12}
                            openDirection="down"
                            anchorDirection="right"
                            label={DATA_NASCITA_ULTERIORI_DATI.label}
                            readOnly
                            placeholder=""
                            isOutsideRange={date => checkInvalidBirthdate(date)}
                            defaultDate={formValues[DATA_NASCITA_ULTERIORI_DATI.id]}
                            onDateChange={date => {
                              change(DATA_NASCITA_ULTERIORI_DATI.id, date.format())
                              handleVerified(false)
                              untouch(DATA_NASCITA_ULTERIORI_DATI.id)
                            }}
                            component={renderDatePicker}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Field
                          name={SESSO_ULTERIORI_DATI.id}
                          size={6}
                          label={SESSO_ULTERIORI_DATI.label}
                          options={[
                            <option key={-1} value="" disabled>
                              -
                            </option>,
                            <option key={1} value="M">
                              Maschio
                            </option>,
                            <option key={2} value="F">
                              Femmina
                            </option>
                          ]}
                          onChange={(field, value) => {
                            change(field, value)
                            handleVerified(false)
                          }}
                          component={renderSelect}
                        />
                      </Row>
                    </div>
                  </div>
                  {(!verified || !isUserAvailable) && (
                    <Row className="center-button m-top20">
                      <Button type="submit" disabled={fetching || submitting || invalid}>
                        Verifica se già cliente
                      </Button>
                    </Row>
                  )}
                  {verified && !verificaModale && !isUserAvailable && (
                    <ModalVerifica
                      title={
                        <p className="onboarding-modale-content__title center-align onboarding-modale-text__blu">
                          Cliente già censito
                        </p>
                      }
                      icon={
                        <Icon
                          className="onboarding-modale-icon__firma-digitale icon-icone-ips_sv-spesa onboarding-modale-text__blu"
                          large>
                          &#xe909;
                        </Icon>
                      }
                      text={
                        <p className="onboarding-modale-content__subtitle center-align">
                          La persona censita risulta essere già cliente di un altro consulente finanziario.
                        </p>
                      }
                      interventoInSede={interventoInSede}
                      close={close}
                    />
                  )}
                  {sended && !isUserAvailable && isSend === '0' && (
                    <ModalVerifica
                      title={
                        <p className="onboarding-modale-content__title center-align onboarding-modale-text__blu">
                          Cliente già censito
                        </p>
                      }
                      icon={
                        <Icon
                          className="onboarding-modale-icon__firma-digitale icon-icone-ips_sv-spesa onboarding-modale-text__blu"
                          large>
                          &#xe909;
                        </Icon>
                      }
                      text={
                        <p className="onboarding-modale-content__subtitle center-align">
                          Richiesta di intervento inviata
                        </p>
                      }
                      close={close}
                    />
                  )}
                  {sended && !isUserAvailable && isSend !== '0' && (
                    <ModalVerifica
                      title={
                        <p className="onboarding-modale-content__title center-align onboarding-modale-text__blu">
                          Cliente già censito
                        </p>
                      }
                      icon={
                        <Icon
                          className="onboarding-modale-icon__firma-digitale icon-icone-ips_sv-spesa onboarding-modale-text__blu"
                          large>
                          &#xe909;
                        </Icon>
                      }
                      text={
                        <p className="onboarding-modale-content__subtitle center-align">
                          Richiesta di intervento fallita
                        </p>
                      }
                      close={close}
                    />
                  )}
                  {verified && isUserAvailable && formValues[fields.CODICE_FISCALE.id] && (
                    <Confirm className="m-top20" text="Codice fiscale non presente nel database.">
                      <i className="material-icons">check_circle_outline</i>
                    </Confirm>
                  )}
                  {verified && isUserAvailable && !formValues[fields.CODICE_FISCALE.id] && (
                    <Confirm className="m-top20" text="Prospect non presente nel database.">
                      <i className="material-icons">check_circle_outline</i>
                    </Confirm>
                  )}
                </div>
              </form>
              <div className="final-note">* Campi obbligatori</div>
              <Row className="footer-buttons">
                <Button className="back-button" onClick={() => push(`/`)}>
                  Esci
                </Button>
                <Button
                  className="accetta"
                  disabled={
                    !verified ||
                    !isUserAvailable ||
                    !formValues[NOME.id] ||
                    formErrors[NOME.id] ||
                    !formValues[COGNOME.id] ||
                    formErrors[COGNOME.id]
                  }
                  onClick={handleModal}>
                  Accetta Privacy
                </Button>
              </Row>
            </article>
            <img id="preview" src={imageupload} />
          </section>
        </Col>
      </Row>
    )
  }
}

ProspectFisiche.propTypes = {
  change: PropTypes.func,
  checkNazionalitaUlterioriDati: PropTypes.func,
  checkNazione: PropTypes.func,
  checkProvincia: PropTypes.func,
  close: PropTypes.func,
  computeCFRequest: PropTypes.object,
  computeCFstate: PropTypes.bool,
  domini: PropTypes.object,
  fields: PropTypes.object,
  formErrors: PropTypes.object,
  formValues: PropTypes.object,
  formWarnings: PropTypes.object,
  handleComputeCFstate: PropTypes.func,
  handleGetCF: PropTypes.func,
  handleModal: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleSubmitPF: PropTypes.func,
  handleVerified: PropTypes.func,
  history: PropTypes.object,
  idPratica: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageupload: PropTypes.string,
  initialLoading: PropTypes.bool,
  innerRef: PropTypes.object,
  interventoInSede: PropTypes.func,
  invalid: PropTypes.bool,
  isSend: PropTypes.string,
  isUserAvailable: PropTypes.bool,
  openCamera: PropTypes.func,
  sended: PropTypes.bool,
  showModal: PropTypes.bool,
  submitting: PropTypes.bool,
  tabs: PropTypes.array,
  touch: PropTypes.func,
  untouch: PropTypes.func,
  updateDominio: PropTypes.func,
  verificaModale: PropTypes.bool,
  verified: PropTypes.bool,
  checkCFRequest: PropTypes.object,
  moduleSendRequest: PropTypes.object
  // privacySendRequest: PropTypes.object
}

export default ProspectFisiche
