import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'
import { customerCreate, resetCheckCF, computeCF, checkCF } from '../../../store/modules/customers/actions'
import {
  privacySend,
  moduleSend,
  privacyCheck,
  moduleCheck,
  privacyReset
} from '../../../store/modules/privacy/actions'
import {
  privacyCheckProcuratore,
  privacySendProcuratore,
  moduleSendProcuratore
} from '../../../store/modules/procuratore/actions'
import { MODAL_PRIVACY } from '../../../store/modules/forms/constants'
import ModalPrivacy from './ModalPrivacyComponent'

const validate = (values, props = {}) => {
  const errors = {}
  const {
    fields: { EMAIL }
  } = props

  if (!values[EMAIL.id]) {
    errors[EMAIL.id] = 'Email obbligatoria'
  } else if (!new RegExp(EMAIL.validazione).test(values[EMAIL.id])) {
    errors[EMAIL.id] = 'Email non valida'
  }

  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      customerCreate,
      resetCheckCF,
      computeCF,
      checkCF,
      privacySend,
      moduleSend,
      privacyCheck,
      moduleCheck,
      privacyCheckProcuratore,
      privacySendProcuratore,
      moduleSendProcuratore,
      privacyReset
    },
    dispatch
  )

const mapStateToProps = (state, { fields: { EMAIL } }) => {
  return {
    initialValues: {
      [EMAIL.id]: ''
      // ,
      // isPrivacyAcceptanceDone: false
    },
    processId: state.privacy.processId,
    processIdProcuratore: state.procuratore.processId,
    idPratica: state.privacy.idPratica,
    idLegalPerson: state.pratica.idLegalPerson,
    isPrivacyAcceptanceDone: state.privacy.isPrivacyAcceptanceDone,
    isPrivacyAcceptanceDoneProcuratore: state.procuratore.procuratore.isPrivacyAcceptanceDone,
    formValues: getFormValues(MODAL_PRIVACY)(state),
    formErrors: getFormSyncErrors(MODAL_PRIVACY)(state),
    privacySendRequest: state.privacy.privacySendRequest,
    privacyProcuratoreSendRequest: state.procuratore.privacySendRequest,
    moduleSendRequest: state.privacy.moduleSendRequest,
    moduleSendRequestProcuratore: state.procuratore.moduleSendRequest,
    privacyCheckRequestProcuratore: state.procuratore.privacyCheckRequest,
    privacyCheckRequest: state.privacy.privacyCheckRequest,
    duplicatedEmail: state.privacy.duplicatedEmail,
    flagIsBlacklistProcuratore: ((state.customers || {}).documentsDataProcuratore || {}).flagIsBlacklist
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: MODAL_PRIVACY,
    validate
  })(ModalPrivacy)
)
