import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row } from 'react-materialize'
import * as forms from '../../../store/modules/forms/constants'
import dataSB from '../../../static-data/data-servizio-base.json'
import { Collapsible } from '../../../components/Collapsible'
import YesNoQuestion from '../../../components/YesNoQuestion'
import ConoscenzaEsperienza from './ConoscenzaEsperienza'
import ObiettiviInvestimento from './ObiettiviInvestimento'
import SituazioneFinanziaria from './SituazioneFinanziaria'
import AltreInformazioni from './AltreInformazioni'
import TolleranzaOscillazione from './TolleranzaOscillazione'
import Anagrafica from './Anagrafica'
import fields from '../fields'
import {
  checkStatusService,
  getSubSectionToBeCompleted,
  getSectionStatus,
  getActiveMifid,
  setActiveMifid,
  MIFID_CONST,
  getMifidByCategory,
  getActiveMifidSection,
  getActiveMifidSectionSummaryData
} from '../utils'
import EducazioneFinanziaria from './EducazioneFinanziaria'
import ModalGeneric from '../../../components/ModalGeneric'
import { checkValidationErrors, buildErrorsTemplate } from './mifidValidationUtils'
import InvestimentiESG from './InvestimentiESG/InvestimentiESGContainer'
import PromisesState from './promisesState'

let MIFID_ACTIVE = {}
let NEW_MIFID = true
let showAlert = false

const getOpenCollapsible = (summaryData, current, reduxForms) => {
  const next = getSubSectionToBeCompleted(summaryData, 2, current, reduxForms)
  return forms[Object.keys(forms).find(f => forms[f] === next)]
}

class QuestionarioProfilatura extends Component {
  constructor(props) {
    super(props)
    this.promisesState = new PromisesState(['dominioRequest', 'getDataSummaryLight', 'mifidRequest'])
    this.state = {
      openCollapsible: props.accordionKey || getOpenCollapsible(props.summaryData),
      loadingComplete: false,
      waitingDomini: true,
      mifidMandatory: true,
      thenContinue: false,
      isProspectPersonaGiuridica: true,
      showModalAlert: false,
      modalAlertBody: '',
      validationErrors: [],
      promises: this.promisesState.getInitialState()
    }
    this.handleOpenCollapsibleChange = this.handleOpenCollapsibleChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.manageContinue = this.manageContinue.bind(this)
    this.resetForm = this.resetForm.bind(this)
  }

  handleOpenCollapsibleChange(openCollapsible) {
    this.setState({ openCollapsible })
  }
  handleSave(idServizio, listaDomande = [], flagProfilatura = true) {
    const {
      customer: { idCliente, idIncarico },
      saveSurvey
    } = this.props
    saveSurvey(
      idCliente,
      idIncarico,
      idServizio,
      listaDomande,
      flagProfilatura,
      this.props.professionalSubjectType === 'DI_DIRITTO',
      this.props.isProspectPersonaGiuridica
    )
  }
  manageContinue() {
    const {
      checkForms,
      goToWhenSaved,
      previousForm,
      summaryData: { statusEndPoint }
    } = this.props
    if (
      !checkForms() &&
      (!checkStatusService(statusEndPoint, 'mifid') || previousForm.flagProfilatura !== this.state.mifidMandatory)
    ) {
      this.handleSave(this.props.isProfessionalSubjectDone ? 'HEADER_LIGHT' : 'HEADER', [], this.state.mifidMandatory)
      this.setState({ thenContinue: true })
    } else {
      goToWhenSaved('./sottoscrizione-servizi')
    }
  }
  getSectionStatus(index) {
    if (typeof index === 'string') {
      return getSectionStatus(this.props.summaryData, getActiveMifidSectionSummaryData(), index)
    }
    return getSectionStatus(this.props.summaryData, 2, index)
  }
  resetForm(form) {
    const { summaryData, goToWhenSaved, reduxForms, isMifidInconsistent } = this.props
    let openCollapsible = ''
    if (isMifidInconsistent) {
      openCollapsible = this.props.isProfessionalSubjectDone ? forms.MIFID_ALTRO_LIGHT : forms.MIFID_ALTRO
    } else {
      openCollapsible = getOpenCollapsible(summaryData, form, reduxForms)
    }
    this.setState({ openCollapsible })
    if (!openCollapsible) {
      goToWhenSaved('./sottoscrizione-servizi')
    }
  }

  setMifidType() {
    if (this.props.isProspectPersonaGiuridica) {
      if (this.props.professionalSubjectType === 'DI_DIRITTO') {
        setActiveMifid(MIFID_CONST.PD)
      } else {
        setActiveMifid(MIFID_CONST.PG)
      }
    } else {
      setActiveMifid(MIFID_CONST.PF)
    }
  }

  componentWillMount() {
    // Imposto la mifid attiva
    this.setMifidType()
    MIFID_ACTIVE = getActiveMifid()
  }

  componentDidMount() {
    const { TITOLO_STUDIO, SETTORE_OCCUPAZIONE, SETTORE_PROFESSIONE } = fields[2][forms[MIFID_ACTIVE.CONOSCENZA]]
    const {
      customer: { idCliente, codiceFiscale, idIncarico },
      summaryData: { statusEndPoint },
      match: { url },
      idPratica,
      isProspectPersonaGiuridica,
      idPhysicalPersonReference
    } = this.props

    let listaDomini = [{ idDominio: TITOLO_STUDIO.dominio }, { idDominio: SETTORE_OCCUPAZIONE.dominio }]

    // SETTORE_PROFESSIONE MANCA SU PG NUOVA MIFID
    if (SETTORE_PROFESSIONE) {
      listaDomini.push({ idDominio: SETTORE_PROFESSIONE.dominio })
    }

    this.setState({ promises: this.promisesState.setPending('dominioRequest') })
    this.props.dominio({
      listaDomini: listaDomini
    })

    if (isProspectPersonaGiuridica) {
      this.props.getProfessionale(idPhysicalPersonReference)
    }

    if (url === `/consulenza-base-PG/${idPratica}/questionario-profilatura`) {
      this.setState({ isProspectPersonaGiuridica: true })
      const { getDataSummaryLightPGActionCreator, customer, idPratica } = this.props
      this.setState({ promises: this.promisesState.setPending('getDataSummaryLight') })
      getDataSummaryLightPGActionCreator(customer.idCliente, idPratica)
    }

    // ioio

    // let loadingComplete = false
    // if (statusEndPoint) {
    //   // (idCliente, flagRiepilogo, active, mifidLight, isPersonaGiuridica)
    //   setTimeout(() => {
    //     if (!this.props.isProspectPersonaGiuridica) {
    //       fetchSurveyMifid(
    //         idCliente,
    //         false,
    //         this.props.isProfessionalSubjectDone
    //           ? checkStatusService(statusEndPoint, 'mifidLight')
    //           : checkStatusService(statusEndPoint, 'mifid'),
    //         this.props.isProfessionalSubjectDone, // this.props.isProfessionalSubjectDone,
    //         this.props.isProspectPersonaGiuridica
    //       )
    //     } else {
    //       fetchSurveyMifid(
    //         idCliente,
    //         false,
    //         this.props.isProfessionalSubjectDone
    //           ? checkStatusService(statusEndPoint, 'mifidLight')
    //           : checkStatusService(statusEndPoint, 'mifid'), // checkStatusService(statusEndPoint, 'mifid'),
    //         this.props.isProfessionalSubjectDone, // this.props.isProfessionalSubjectDone,
    //         this.props.isProspectPersonaGiuridica
    //       )
    //     }
    //   }, 3000)
    // } else {
    //   // loadingComplete = true
    // }
    // this.setState({ loadingComplete, waitingDomini: false })

    this.props.getDataRegistry(
      codiceFiscale,
      idCliente,
      idIncarico,
      checkStatusService(statusEndPoint, 'dataRegistry'),

      false
    )

    this.props.getDocumentsData(codiceFiscale, idCliente, idIncarico, checkStatusService(statusEndPoint, 'idCardData'))
  }

  componentDidUpdate(prevProps, prevState) {
    const isChangedProfessionalSubjectType = prevProps.professionalSubjectType !== this.props.professionalSubjectType

    if (prevProps.dominioRequest.fetching === true && this.props.dominioRequest.fetching === false) {
      this.setState({ promises: this.promisesState.setFulfilled('dominioRequest') })
    }

    if (prevProps.mifidRequest.fetching === true && this.props.mifidRequest.fetching === false) {
      this.setState({ promises: this.promisesState.setFulfilled('mifidRequest') })
    }

    if (this.props.isProspectPersonaGiuridica) {
      if (prevProps.getDataSummaryLight.fetching === true && this.props.getDataSummaryLight.fetching === false) {
        this.setState({ promises: this.promisesState.setFulfilled('getDataSummaryLight') })
      }
    }

    if (isChangedProfessionalSubjectType) {
      // Imposto la mifid attiva
      this.setMifidType()
      MIFID_ACTIVE = getActiveMifid()
    }

    if (this.props.isProspectPersonaGiuridica) {
      const isReady =
        this.promisesState.isFulfilled('dominioRequest') &&
        this.promisesState.isFulfilled('getDataSummaryLight') &&
        this.promisesState.isNull('mifidRequest')
      const isChangedIdCliente = prevProps.customer.idCliente !== this.props.customer.idCliente
      const isChangedIsProspectPersonaGiuridica =
        prevProps.isProspectPersonaGiuridica !== this.props.isProspectPersonaGiuridica
      const isChangedStatusEndPointMifid =
        checkStatusService(prevProps.summaryData.statusEndPoint, 'mifid') !==
        checkStatusService(this.props.summaryData.statusEndPoint, 'mifid')
      if (
        isReady ||
        isChangedIdCliente ||
        isChangedProfessionalSubjectType ||
        isChangedIsProspectPersonaGiuridica ||
        isChangedStatusEndPointMifid
      ) {
        this.setState({ promises: this.promisesState.setPending('mifidRequest') })

        const {
          fetchSurveyMifid,
          summaryData: { statusEndPoint },
          customer: { idCliente },
          idPratica
        } = this.props
        fetchSurveyMifid(
          idCliente,
          false,
          checkStatusService(statusEndPoint, 'mifid'), // checkStatusService(statusEndPoint, 'mifid'),
          this.props.professionalSubjectType === 'DI_DIRITTO', // this.props.isProfessionalSubjectDone,
          this.props.isProspectPersonaGiuridica,
          idPratica
        )
      }
    }
    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && this.state.waitingDomini) {
      const {
        fetchSurveyMifid,
        summaryData: { statusEndPoint },
        customer: { idCliente },
        idPratica
      } = this.props
      let loadingComplete = false
      if (!this.props.isProspectPersonaGiuridica) {
        fetchSurveyMifid(
          idCliente,
          false,
          checkStatusService(statusEndPoint, 'mifid'),
          this.props.isProfessionalSubjectDone, // this.props.isProfessionalSubjectDone,
          this.props.isProspectPersonaGiuridica,
          idPratica
        )
      } else if (this.props.isProspectPersonaGiuridica) {
        // fetchSurveyMifid(
        //   idCliente,
        //   false,
        //   checkStatusService(statusEndPoint, 'mifid'), // checkStatusService(statusEndPoint, 'mifid'),
        //   this.props.professionalSubjectType === 'DI_DIRITTO', // this.props.isProfessionalSubjectDone,
        //   this.props.isProspectPersonaGiuridica,
        //   idPratica
        // )
      } else {
        loadingComplete = true
      }
      this.setState({ loadingComplete, waitingDomini: false })
    }
    if (prevProps.mifidRequest.fetching && !this.props.mifidRequest.fetching && !this.props.mifidRequest.error) {
      let openCollapsible = this.state.openCollapsible
      if (this.props.isMifidInconsistent) {
        openCollapsible = this.props.isProfessionalSubjectDone ? forms.MIFID_ALTRO_LIGHT : forms.MIFID_ALTRO
      }
      this.setState({ loadingComplete: true, mifidMandatory: this.props.previousForm.flagProfilatura, openCollapsible })
    }
    if (
      prevProps.mifidSaveRequest.fetching &&
      !this.props.mifidSaveRequest.fetching &&
      this.props.mifidSaveRequest.error === null &&
      this.state.thenContinue
    ) {
      this.setState({ thenContinue: false })
      this.props.goToWhenSaved('./sottoscrizione-servizi')
    }

    // Lancio la validazione intraquestionario
    const validationErrors = checkValidationErrors()

    // Variabile d'appoggio (showAlert) perché lo state si aggiorna continuamente e showModalAlert devo usarla per chiudere la modale
    // Sollevo l'errore di nuovo nel caso in cui si presentano nuovi errori ed i vecchi non sono stati risolti
    if ((validationErrors.length > 0 && !showAlert) || validationErrors.length > this.state.validationErrors.length) {
      showAlert = true
      this.setState({
        showModalAlert: true,
        modalAlertBody: buildErrorsTemplate(getActiveMifidSection()),
        validationErrors: validationErrors
      })
    }

    if (validationErrors.length === 0 && showAlert) {
      showAlert = false
      this.setState({ showModalAlert: false, modalAlertBody: '', validationErrors: [] })
    }
    // Se risolvo alcuni errori ma ne lascio altri devo aggiornare lo state
    if (validationErrors.length < this.state.validationErrors.length) {
      this.setState({ validationErrors: validationErrors })
    }
  }

  render() {
    const { template, domini } = this.props
    const { titoloSezione, titoloSubSezione, titoloSubSubSezione, sezioni } = template
    if (template === undefined || Object.keys(template).length === 0) {
      return <React.Fragment />
    }

    /* TMP In attesa che il BE ci invii i nomi corretti per la sezione MIFID LIGHT */
    // if (
    //   this.props.isProfessionalSubjectDone &&
    //   (this.state.openCollapsible === 'INVESTIMENTO' || this.state.openCollapsible === 'ALTRO') &&
    //   this.state.openCollapsible.indexOf('LIGHT') === -1
    // ) {
    //   this.setState({ openCollapsible: this.state.openCollapsible + '_LIGHT' })
    // }
    //  // let altreInfoHide = false
    //   if (this.props.isProspectPersonaGiuridica === true && this.props.isProfessionalSubjectDone === true) {
    //     altreInfoHide = true
    //   }
    return (
      <section className="main-body consulenza-base questionario-profilatura">
        {this.state.showModalAlert && (
          <ModalGeneric
            show={this.state.showModalAlert}
            text={this.state.modalAlertBody}
            handler={() => this.setState({ showModalAlert: false })}
          />
        )}
        <article className="page-left-content">
          <div className="background_pattern" />
          <h2 className="title-sezione">{titoloSezione}</h2>
          <div className="subtitle-sezione">
            <div className="question">
              <h5>{titoloSubSezione}</h5>
              <h6>{titoloSubSubSezione}</h6>
            </div>
            {this.state.loadingComplete && (
              <YesNoQuestion
                label=""
                onChange={mifidMandatory => {
                  this.setState({ mifidMandatory })
                }}
                value={this.state.mifidMandatory}
              />
            )}
          </div>
          {this.state.mifidMandatory && this.state.loadingComplete && (
            <Collapsible open={this.state.openCollapsible} onChange={this.handleOpenCollapsibleChange}>
              {/* NUOVA MIFID */}
              {NEW_MIFID && (
                <React.Fragment>
                  {this.props.professionalSubjectType !== 'DI_DIRITTO' && (
                    <React.Fragment>
                      {/* Status? */
                      !this.props.isProspectPersonaGiuridica && (
                        <Anagrafica
                          status={this.getSectionStatus(0)}
                          template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.ANAGRAFICA])}
                          resetForm={this.resetForm}
                          onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.ANAGRAFICA], ...params)}
                          fieldsWithErrors={this.state.validationErrors}
                        />
                      )}
                      <ConoscenzaEsperienza
                        status={
                          !this.props.isProspectPersonaGiuridica ? this.getSectionStatus(1) : this.getSectionStatus(0)
                        }
                        template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.CONOSCENZA])}
                        domini={domini}
                        resetForm={this.resetForm}
                        onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.CONOSCENZA], ...params)}
                        fieldsWithErrors={this.state.validationErrors}
                      />
                    </React.Fragment>
                  )}
                  {this.props.professionalSubjectType !== 'DI_DIRITTO' && (
                    <SituazioneFinanziaria
                      status={
                        !this.props.isProspectPersonaGiuridica
                          ? this.getSectionStatus(2)
                          : this.props.professionalSubjectType === 'DI_DIRITTO'
                          ? this.getSectionStatus(2)
                          : this.getSectionStatus(1)
                      }
                      template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.FINANZIARIA])}
                      resetForm={this.resetForm}
                      onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.FINANZIARIA], ...params)}
                      fieldsWithErrors={this.state.validationErrors}
                    />
                  )}
                  <TolleranzaOscillazione
                    status={
                      !this.props.isProspectPersonaGiuridica
                        ? this.getSectionStatus(3)
                        : this.props.professionalSubjectType === 'DI_DIRITTO'
                        ? this.getSectionStatus(0)
                        : this.getSectionStatus(2)
                    }
                    template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.TOLLERANZA])}
                    resetForm={this.resetForm}
                    onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.TOLLERANZA], ...params)}
                    fieldsWithErrors={this.state.validationErrors}
                  />
                  <ObiettiviInvestimento
                    status={
                      !this.props.isProspectPersonaGiuridica
                        ? this.getSectionStatus(4)
                        : this.props.professionalSubjectType === 'DI_DIRITTO'
                        ? this.getSectionStatus(1)
                        : this.getSectionStatus(3)
                    }
                    template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.OBIETTIVI])}
                    resetForm={this.resetForm}
                    onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.OBIETTIVI], ...params)}
                    clienteProfessionale={this.props.professionalSubjectType === 'DI_DIRITTO'}
                    fieldsWithErrors={this.state.validationErrors}
                  />
                  {this.props.professionalSubjectType !== 'DI_DIRITTO' && (
                    <InvestimentiESG
                      status={this.getSectionStatus(getMifidByCategory(MIFID_CONST.INVESTIMENTI_ESG))}
                      template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.INVESTIMENTI_ESG])}
                      resetForm={this.resetForm}
                      onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.INVESTIMENTI_ESG], ...params)}
                      fieldsWithErrors={this.state.validationErrors}
                    />
                  )}
                  {this.props.professionalSubjectType === 'DI_DIRITTO' && (
                    <SituazioneFinanziaria
                      status={
                        !this.props.isProspectPersonaGiuridica
                          ? this.getSectionStatus(2)
                          : this.props.professionalSubjectType === 'DI_DIRITTO'
                          ? this.getSectionStatus(2)
                          : this.getSectionStatus(1)
                      }
                      template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.FINANZIARIA])}
                      resetForm={this.resetForm}
                      onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.FINANZIARIA], ...params)}
                      fieldsWithErrors={this.state.validationErrors}
                    />
                  )}
                  {this.props.professionalSubjectType === 'DI_DIRITTO' && (
                    <React.Fragment>
                      <InvestimentiESG
                        status={this.getSectionStatus(getMifidByCategory(MIFID_CONST.INVESTIMENTI_ESG))}
                        template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.INVESTIMENTI_ESG])}
                        resetForm={this.resetForm}
                        onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.INVESTIMENTI_ESG], ...params)}
                        fieldsWithErrors={this.state.validationErrors}
                      />
                    </React.Fragment>
                  )}
                  {this.props.professionalSubjectType !== 'DI_DIRITTO' && (
                    <EducazioneFinanziaria
                      status={
                        !this.props.isProspectPersonaGiuridica ? this.getSectionStatus(6) : this.getSectionStatus(4)
                      }
                      template={sezioni.find(sezione => sezione.idSezione === forms[MIFID_ACTIVE.EDUCAZIONE])}
                      resetForm={this.resetForm}
                      onSave={(...params) => this.handleSave(forms[MIFID_ACTIVE.EDUCAZIONE], ...params)}
                      fieldsWithErrors={this.state.validationErrors}
                    />
                  )}
                </React.Fragment>
              )}

              {/* VECCHIA MIFID */}
              {!NEW_MIFID && (
                <React.Fragment>
                  <ObiettiviInvestimento
                    status={
                      // this.props.isProfessionalSubjectDone
                      //   ? this.getSectionStatus(0)
                      //   : this.getSectionStatus(1)

                      !this.props.isProspectPersonaGiuridica
                        ? this.getSectionStatus(1)
                        : this.props.professionalSubjectType === 'DI_DIRITTO'
                        ? this.getSectionStatus(0)
                        : this.getSectionStatus(1)
                    }
                    template={
                      !this.props.isProspectPersonaGiuridica
                        ? sezioni.find(sezione => sezione.idSezione === forms.MIFID_OBIETTIVI)
                        : this.props.professionalSubjectType === 'DI_DIRITTO'
                        ? sezioni.find(sezione => sezione.idSezione === forms.MIFID_OBIETTIVI_LIGHT)
                        : sezioni.find(sezione => sezione.idSezione === forms.MIFID_OBIETTIVI)
                    }
                    resetForm={this.resetForm}
                    onSave={(...params) =>
                      this.handleSave(
                        // this.props.isProfessionalSubjectDone
                        //   ? forms.MIFID_OBIETTIVI_LIGHT
                        //   : forms.MIFID_OBIETTIVI,
                        // ...params
                        !this.props.isProspectPersonaGiuridica
                          ? forms.MIFID_OBIETTIVI
                          : this.props.professionalSubjectType === 'DI_DIRITTO'
                          ? forms.MIFID_OBIETTIVI_LIGHT
                          : forms.MIFID_OBIETTIVI,
                        ...params
                      )
                    }
                    clienteProfessionale={this.props.professionalSubjectType === 'DI_DIRITTO'}
                  />
                  {this.props.professionalSubjectType !== 'DI_DIRITTO' && (
                    <SituazioneFinanziaria
                      status={this.getSectionStatus(2)}
                      template={sezioni.find(sezione => sezione.idSezione === forms.MIFID_FINANZIARIA)}
                      resetForm={this.resetForm}
                      onSave={(...params) => this.handleSave(forms.MIFID_FINANZIARIA, ...params)}
                    />
                  )}
                  <AltreInformazioni
                    status={
                      !this.props.isProspectPersonaGiuridica
                        ? this.getSectionStatus(3)
                        : this.props.professionalSubjectType === 'DI_DIRITTO'
                        ? this.getSectionStatus(1)
                        : this.getSectionStatus(3)
                    }
                    template={
                      // this.props.isProfessionalSubjectDone
                      //   ? sezioni.find(sezione => sezione.idSezione === forms.MIFID_ALTRO_LIGHT)
                      //   : sezioni.find(sezione => sezione.idSezione === forms.MIFID_ALTRO)
                      !this.props.isProspectPersonaGiuridica
                        ? sezioni.find(sezione => sezione.idSezione === forms.MIFID_ALTRO)
                        : this.props.professionalSubjectType === 'DI_DIRITTO'
                        ? sezioni.find(sezione => sezione.idSezione === forms.MIFID_ALTRO_LIGHT)
                        : sezioni.find(sezione => sezione.idSezione === forms.MIFID_ALTRO)
                    }
                    resetForm={this.resetForm}
                    onSave={(...params) =>
                      this.handleSave(
                        // this.props.isProfessionalSubjectDone
                        //   ? forms.MIFID_ALTRO_LIGHT
                        //   : forms.MIFID_ALTRO,
                        !this.props.isProspectPersonaGiuridica
                          ? forms.MIFID_ALTRO
                          : this.props.professionalSubjectType === 'DI_DIRITTO'
                          ? forms.MIFID_ALTRO_LIGHT
                          : forms.MIFID_ALTRO,
                        ...params
                      )
                    }
                    clienteProfessionale={this.props.professionalSubjectType === 'DI_DIRITTO'}
                    isProspectPersonaGiuridica={this.props.isProspectPersonaGiuridica}
                  />
                </React.Fragment>
              )}
            </Collapsible>
          )}
          {!this.state.mifidMandatory && this.state.loadingComplete && (
            <div className="collapsible">
              <div className="collapsible-item">
                <div className="no-survey">
                  Se il cliente non sarà intestatario non è necessario compilare il questionario
                </div>
              </div>
            </div>
          )}
          <Row className="right-align padding-r-20 margin-t-20">
            {this.state.loadingComplete && (
              <a onClick={this.manageContinue} to="#">
                {dataSB.link_continue} <i className="icon-arrow" />
              </a>
            )}
          </Row>
        </article>
      </section>
    )
  }
}
export default QuestionarioProfilatura

QuestionarioProfilatura.propTypes = {
  customer: PropTypes.object,
  domini: PropTypes.object,
  dominio: PropTypes.func,
  dominioRequest: PropTypes.object,
  fetchSurveyMifid: PropTypes.func,
  saveSurvey: PropTypes.func,
  template: PropTypes.object,
  mifidRequest: PropTypes.object,
  getDataSummaryLight: PropTypes.object,
  mifidSaveRequest: PropTypes.object,
  summaryData: PropTypes.object,
  goToWhenSaved: PropTypes.func.isRequired,
  accordionKey: PropTypes.string,
  previousForm: PropTypes.object,
  checkForms: PropTypes.func,
  reduxForms: PropTypes.object,
  isMifidInconsistent: PropTypes.bool,
  getProfessionale: PropTypes.func,
  // professionalSubject: PropTypes.bool,
  match: PropTypes.object,
  idPratica: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getDataRegistry: PropTypes.any,
  getDocumentsData: PropTypes.any,
  isProspectPersonaGiuridica: PropTypes.bool,
  isProfessionalSubjectDone: PropTypes.bool,
  professionalSubjectType: PropTypes.string,
  idPhysicalPersonReference: PropTypes.string,
  getDataSummaryLightPGActionCreator: PropTypes.func
}
