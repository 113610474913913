import Aside from './AsideComponent'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isDirty } from 'redux-form'
import * as forms from '../../store/modules/forms/constants'
import { goToWhenSaved } from '../../store/modules/dirtyForm/actions'

const mapDispatchToProps = dispatch => bindActionCreators({ goToWhenSaved }, dispatch)

const mapStateToProps = state => {
  return {
    idPratica: state.pratica.idPratica,
    checkForms: () => Object.keys(forms).some(form => isDirty(forms[form])(state))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Aside)
