import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import allFields from '../../fields'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import Title from '../../../../components/Title'

let MIFID_ACTIVE = null
let fields = null

class TolleranzaOscillazioneReview extends Component {
  constructor(props) {
    super(props)

    this.getRisposta = this.getRisposta.bind(this)
    this.mapRisposta = this.mapRisposta.bind(this)
    this.getLabel = this.getLabel.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.TOLLERANZA)
    fields = allFields[2][MIFID_ACTIVE]
  }

  getLabel({ templateId }) {
    const { template } = this.props
    if (template) {
      return template.listaDomande.find(d => d.idDomanda === templateId).testoDomanda
    } else {
      return null
    }
  }

  getRisposta({ templateId }) {
    const {
      data: { listaDomande }
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    return domanda.listaRisposte[0].idRisposta
  }

  mapRisposta({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    const idRisposta = domanda.listaRisposte[0].idRisposta
    return template.listaDomande
      .find(d => d.idDomanda === templateId)
      .listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
  }

  render() {
    const { template } = this.props
    return (
      <CollapsibleItem
        header={template ? template.descSezione : ''}
        icon="keyboard_arrow_down"
        itemKey={MIFID_ACTIVE}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={12}>
            <Title>{this.getLabel(fields.PERDITA)}</Title>
          </Col>
          <Col s={12}>
            <ReadOnlyInput value={this.mapRisposta(fields.PERDITA)} />
          </Col>
        </Row>
        <Row>
          <Col s={12}>
            <Title>{this.getLabel(fields.RISCHIO)}</Title>
          </Col>
          <Col s={12}>
            <ReadOnlyInput value={this.mapRisposta(fields.RISCHIO)} />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}

TolleranzaOscillazioneReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  template: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default TolleranzaOscillazioneReview
