import * as actionTypes from './constants'

const apiRequests = {
  legalPersonPG: null,
  checkPGRequest: {
    fetching: false,
    error: null
  },
  postDataGatheringRequest: {
    fetching: false,
    error: null
  },
  getDataGatheringRequest: {
    fetching: false,
    error: null
  },
  getDataSummaryLight: {
    fetching: false,
    error: null
  },
  updateTipologiaClienteRequest: {
    fetching: false,
    error: null
  },
  legalPersonCreatePFRequest: {
    fetching: false,
    error: null
  }
}

const initialState = {
  dataGathering: {},
  summaryData: {},
  isProspectPGAvailable: false
}

// LEGAL_PERSON_CREATE_PF

export default (state = { ...initialState, ...apiRequests }, action) => {
  switch (action.type) {
    case actionTypes.LEGAL_PERSON_CREATE_PF_START:
      return {
        ...state,
        legalPersonCreatePFRequest: {
          fetching: true
        }
      }
    case actionTypes.LEGAL_PERSON_CREATE_PF_SUCCESS: {
      return {
        ...state,
        legalPersonPG: { ...action.legalPersonPG },
        legalPersonCreatePFRequest: {
          fetching: false,
          error: null
        }
      }
    }
    case actionTypes.LEGAL_PERSON_CREATE_PF_ERROR:
      return {
        ...state,
        legalPersonCreatePFRequest: {
          fetching: false,
          error: action.error
        }
      }

    /** CHECK PG START */
    case actionTypes.POST_STATUS_PG_START: {
      return {
        ...state,
        updateTipologiaClienteRequest: {
          fetching: true,
          error: null
        }
      }
    }
    case actionTypes.POST_STATUS_PG_SUCCESS: {
      return {
        ...state,
        updateTipologiaClienteRequest: {
          fetching: false,
          error: null
        }
      }
    }

    case actionTypes.PROSPECT_CHECK_PG_START:
      return {
        ...state,
        checkPGRequest: {
          fetching: true
        }
      }
    case actionTypes.PROSPECT_CHECK_PG_SUCCESS:
      return {
        ...state,
        isProspectPGAvailable: action.isProspectPGAvailable === '0',
        checkPGRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PROSPECT_CHECK_PG_ERROR:
      return {
        ...state,
        isProspectPGAvailable: false,
        checkPGRequest: {
          fetching: false,
          error: action.error
        }
      }
    /** CHECK PG END */
    /** INIZIO POST DATA GATHERING PER PG */
    case actionTypes.POST_DATA_GATHERING_PG_START: {
      return {
        ...state,
        postDataGatheringRequest: {
          fetching: true,
          error: null
        }
      }
    }
    case actionTypes.POST_DATA_GATHERING_PG_SUCCESS: {
      return {
        ...state,
        postDataGatheringRequest: {
          fetching: false,
          error: null
        },
        dataGathering: action.requestApi
      }
    }
    case actionTypes.POST_DATA_GATHERING_PG_ERROR: {
      return {
        ...state,
        postDataGatheringRequest: {
          fetching: false,
          error: action.error
        }
      }
    }
    /** FINE POST DATA GATHERING PER PG */
    /** INIZIO GET DATA GATHERING PER PG */
    case actionTypes.GET_DATA_GATHERING_PG_START:
      return {
        ...state,
        getDataGatheringRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DATA_GATHERING_PG_SUCCESS:
      return {
        ...state,
        dataGathering: action.responseApi,
        getDataGatheringRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DATA_GATHERING_PG_ERROR:
      return {
        ...state,
        getDataGatheringRequest: {
          fetching: false,
          error: action.error
        }
      }
    /** FINE GET DATA GATHERING PER PG */
    /** INIZIO GET DATA SUMMARY LIGHT PER PG */
    case actionTypes.GET_DATA_SUMMARY_LIGHT_PG_START:
      return {
        ...state,
        getDataSummaryLight: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DATA_SUMMARY_LIGHT_PG_SUCCESS:
      return {
        ...state,
        summaryData: action.responseApi,
        getDataSummaryLight: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DATA_SUMMARY_LIGHT_PG_ERROR:
      return {
        ...state,
        getDataSummaryLight: {
          fetching: false,
          error: action.error
        }
      }
    /** FINE  DATA SUMMARY LIGHT  PER PG */
    default:
      return state
  }
}
