import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty, getFormInitialValues } from 'redux-form'
import { SCHEDA_DATI_TITOLARE } from '../../../../store/modules/forms/constants'
import {
  setEnabledTitolare,
  postAddictionalOwner,
  getAddictionalOwner,
  deleteAddictionalOwner,
  getDataFromTaxCode
} from '../../../../store/modules/utility/actions'
import { dominio } from '../../../../store/modules/dominio/actions'

import { loadDocumentsDataPep, notBelongToDominio } from '../../utils'
import SchedaDatiTitolare from './SchedaDatiTitolareComponent'
import allFields from '../../fields'

const fields = allFields[0][SCHEDA_DATI_TITOLARE]

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props

  const {
    EMAIL_0,
    CELLULARE_0,
    CODICE_FISCALE_0,
    EMAIL_1,
    CELLULARE_1,
    CODICE_FISCALE_1,
    EMAIL_2,
    CELLULARE_2,
    CODICE_FISCALE_2,
    EMAIL_3,
    CELLULARE_3,
    CODICE_FISCALE_3,
    EMAIL_4,
    CELLULARE_4,
    CODICE_FISCALE_4,
    NUMERO_DOCUMENTO_0,
    NUMERO_DOCUMENTO_1,
    NUMERO_DOCUMENTO_2,
    NUMERO_DOCUMENTO_3,
    NUMERO_DOCUMENTO_4,
    TIPO_RELAZIONE_ALTRO_0,
    TIPO_RELAZIONE_ALTRO_1,
    TIPO_RELAZIONE_ALTRO_2,
    TIPO_RELAZIONE_ALTRO_3,
    TIPO_RELAZIONE_ALTRO_4,
    INDIRIZZO_0,
    INDIRIZZO_1,
    INDIRIZZO_2,
    INDIRIZZO_3,
    INDIRIZZO_4,
    NAZIONE_NASCITA_SCHEDA_DATI_0,
    PROVINCIA_NASCITA_SCHEDA_DATI_0,
    COMUNE_NASCITA_SCHEDA_DATI_0,
    CITTADINANZA_0,
    NAZIONE_SCHEDA_DATI_0,
    PROVINCIA_SCHEDA_DATI_0,
    COMUNE_SCHEDA_DATI_0,
    CAP_SCHEDA_DATI_0,
    COMUNE_RILASCIO_0,
    NAZIONE_POSTAL_0,
    PROVINCIA_POSTAL_0,
    COMUNE_POSTAL_0,
    CAP_POSTAL_0,
    NAZIONE_NASCITA_SCHEDA_DATI_1,
    PROVINCIA_NASCITA_SCHEDA_DATI_1,
    COMUNE_NASCITA_SCHEDA_DATI_1,
    CITTADINANZA_1,
    NAZIONE_SCHEDA_DATI_1,
    PROVINCIA_SCHEDA_DATI_1,
    COMUNE_SCHEDA_DATI_1,
    CAP_SCHEDA_DATI_1,
    COMUNE_RILASCIO_1,
    NAZIONE_POSTAL_1,
    PROVINCIA_POSTAL_1,
    COMUNE_POSTAL_1,
    CAP_POSTAL_1,
    NAZIONE_NASCITA_SCHEDA_DATI_2,
    PROVINCIA_NASCITA_SCHEDA_DATI_2,
    COMUNE_NASCITA_SCHEDA_DATI_2,
    CITTADINANZA_2,
    NAZIONE_SCHEDA_DATI_2,
    PROVINCIA_SCHEDA_DATI_2,
    COMUNE_SCHEDA_DATI_2,
    CAP_SCHEDA_DATI_2,
    COMUNE_RILASCIO_2,
    NAZIONE_POSTAL_2,
    PROVINCIA_POSTAL_2,
    COMUNE_POSTAL_2,
    CAP_POSTAL_2,
    NAZIONE_NASCITA_SCHEDA_DATI_3,
    PROVINCIA_NASCITA_SCHEDA_DATI_3,
    COMUNE_NASCITA_SCHEDA_DATI_3,
    CITTADINANZA_3,
    NAZIONE_SCHEDA_DATI_3,
    PROVINCIA_SCHEDA_DATI_3,
    COMUNE_SCHEDA_DATI_3,
    CAP_SCHEDA_DATI_3,
    COMUNE_RILASCIO_3,
    NAZIONE_POSTAL_3,
    PROVINCIA_POSTAL_3,
    COMUNE_POSTAL_3,
    CAP_POSTAL_3,
    NAZIONE_NASCITA_SCHEDA_DATI_4,
    PROVINCIA_NASCITA_SCHEDA_DATI_4,
    COMUNE_NASCITA_SCHEDA_DATI_4,
    CITTADINANZA_4,
    NAZIONE_SCHEDA_DATI_4,
    PROVINCIA_SCHEDA_DATI_4,
    COMUNE_SCHEDA_DATI_4,
    CAP_SCHEDA_DATI_4,
    COMUNE_RILASCIO_4,
    NAZIONE_POSTAL_4,
    PROVINCIA_POSTAL_4,
    COMUNE_POSTAL_4,
    CAP_POSTAL_4
  } = fields

  // ITEM 0
  if (
    !!values[NAZIONE_NASCITA_SCHEDA_DATI_0.id] &&
    notBelongToDominio(domini, NAZIONE_NASCITA_SCHEDA_DATI_0.dominio, values[NAZIONE_NASCITA_SCHEDA_DATI_0.id])
  ) {
    errors[NAZIONE_NASCITA_SCHEDA_DATI_0.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_0.id] &&
    notBelongToDominio(domini, PROVINCIA_NASCITA_SCHEDA_DATI_0.dominio, values[PROVINCIA_NASCITA_SCHEDA_DATI_0.id])
  ) {
    errors[PROVINCIA_NASCITA_SCHEDA_DATI_0.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_0.id] &&
    !!values[COMUNE_NASCITA_SCHEDA_DATI_0.id] &&
    notBelongToDominio(domini, COMUNE_NASCITA_SCHEDA_DATI_0.dominio, values[COMUNE_NASCITA_SCHEDA_DATI_0.id])
  ) {
    errors[COMUNE_NASCITA_SCHEDA_DATI_0.id] = 'Comune non riconosciuto'
  }
  if (!!values[CITTADINANZA_0.id] && notBelongToDominio(domini, CITTADINANZA_0.dominio, values[CITTADINANZA_0.id])) {
    errors[CITTADINANZA_0.id] = 'Cittadinanza non riconosciuta'
  }
  if (
    !!values[NAZIONE_SCHEDA_DATI_0.id] &&
    notBelongToDominio(domini, NAZIONE_SCHEDA_DATI_0.dominio, values[NAZIONE_SCHEDA_DATI_0.id])
  ) {
    errors[NAZIONE_SCHEDA_DATI_0.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_SCHEDA_DATI_0.id] &&
    notBelongToDominio(domini, PROVINCIA_SCHEDA_DATI_0.dominio, values[PROVINCIA_SCHEDA_DATI_0.id])
  ) {
    errors[PROVINCIA_SCHEDA_DATI_0.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[COMUNE_SCHEDA_DATI_0.id] &&
    notBelongToDominio(domini, COMUNE_SCHEDA_DATI_0.dominio, values[COMUNE_SCHEDA_DATI_0.id])
  ) {
    errors[COMUNE_SCHEDA_DATI_0.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[CAP_SCHEDA_DATI_0.id] &&
    notBelongToDominio(domini, CAP_SCHEDA_DATI_0.dominio, values[CAP_SCHEDA_DATI_0.id])
  ) {
    errors[CAP_SCHEDA_DATI_0.id] = 'CAP non riconosciuto'
  }
  if (
    !!values[COMUNE_RILASCIO_0.id] &&
    notBelongToDominio(domini, COMUNE_RILASCIO_0.dominio, values[COMUNE_RILASCIO_0.id])
  ) {
    errors[COMUNE_RILASCIO_0.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[NAZIONE_POSTAL_0.id] &&
    notBelongToDominio(domini, NAZIONE_POSTAL_0.dominio, values[NAZIONE_POSTAL_0.id])
  ) {
    errors[NAZIONE_POSTAL_0.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_POSTAL_0.id] &&
    notBelongToDominio(domini, PROVINCIA_POSTAL_0.dominio, values[PROVINCIA_POSTAL_0.id])
  ) {
    errors[PROVINCIA_POSTAL_0.id] = 'Provincia non riconosciuta'
  }
  if (!!values[COMUNE_POSTAL_0.id] && notBelongToDominio(domini, COMUNE_POSTAL_0.dominio, values[COMUNE_POSTAL_0.id])) {
    errors[COMUNE_POSTAL_0.id] = 'Comune non riconosciuto'
  }
  if (!!values[CAP_POSTAL_0.id] && notBelongToDominio(domini, CAP_POSTAL_0.dominio, values[CAP_POSTAL_0.id])) {
    errors[CAP_POSTAL_0.id] = 'CAP non riconosciuto'
  }

  // ITEM 1
  if (
    !!values[NAZIONE_NASCITA_SCHEDA_DATI_1.id] &&
    notBelongToDominio(domini, NAZIONE_NASCITA_SCHEDA_DATI_1.dominio, values[NAZIONE_NASCITA_SCHEDA_DATI_1.id])
  ) {
    errors[NAZIONE_NASCITA_SCHEDA_DATI_1.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_1.id] &&
    notBelongToDominio(domini, PROVINCIA_NASCITA_SCHEDA_DATI_1.dominio, values[PROVINCIA_NASCITA_SCHEDA_DATI_1.id])
  ) {
    errors[PROVINCIA_NASCITA_SCHEDA_DATI_1.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_1.id] &&
    !!values[COMUNE_NASCITA_SCHEDA_DATI_1.id] &&
    notBelongToDominio(domini, COMUNE_NASCITA_SCHEDA_DATI_1.dominio, values[COMUNE_NASCITA_SCHEDA_DATI_1.id])
  ) {
    errors[COMUNE_NASCITA_SCHEDA_DATI_1.id] = 'Comune non riconosciuto'
  }
  if (!!values[CITTADINANZA_1.id] && notBelongToDominio(domini, CITTADINANZA_1.dominio, values[CITTADINANZA_1.id])) {
    errors[CITTADINANZA_1.id] = 'Cittadinanza non riconosciuta'
  }
  if (
    !!values[NAZIONE_SCHEDA_DATI_1.id] &&
    notBelongToDominio(domini, NAZIONE_SCHEDA_DATI_1.dominio, values[NAZIONE_SCHEDA_DATI_1.id])
  ) {
    errors[NAZIONE_SCHEDA_DATI_1.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_SCHEDA_DATI_1.id] &&
    notBelongToDominio(domini, PROVINCIA_SCHEDA_DATI_1.dominio, values[PROVINCIA_SCHEDA_DATI_1.id])
  ) {
    errors[PROVINCIA_SCHEDA_DATI_1.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[COMUNE_SCHEDA_DATI_1.id] &&
    notBelongToDominio(domini, COMUNE_SCHEDA_DATI_1.dominio, values[COMUNE_SCHEDA_DATI_1.id])
  ) {
    errors[COMUNE_SCHEDA_DATI_1.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[CAP_SCHEDA_DATI_1.id] &&
    notBelongToDominio(domini, CAP_SCHEDA_DATI_1.dominio, values[CAP_SCHEDA_DATI_1.id])
  ) {
    errors[CAP_SCHEDA_DATI_1.id] = 'CAP non riconosciuto'
  }
  if (
    !!values[COMUNE_RILASCIO_1.id] &&
    notBelongToDominio(domini, COMUNE_RILASCIO_1.dominio, values[COMUNE_RILASCIO_1.id])
  ) {
    errors[COMUNE_RILASCIO_1.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[NAZIONE_POSTAL_1.id] &&
    notBelongToDominio(domini, NAZIONE_POSTAL_1.dominio, values[NAZIONE_POSTAL_1.id])
  ) {
    errors[NAZIONE_POSTAL_1.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_POSTAL_1.id] &&
    notBelongToDominio(domini, PROVINCIA_POSTAL_1.dominio, values[PROVINCIA_POSTAL_1.id])
  ) {
    errors[PROVINCIA_POSTAL_1.id] = 'Provincia non riconosciuta'
  }
  if (!!values[COMUNE_POSTAL_1.id] && notBelongToDominio(domini, COMUNE_POSTAL_1.dominio, values[COMUNE_POSTAL_1.id])) {
    errors[COMUNE_POSTAL_1.id] = 'Comune non riconosciuto'
  }
  if (!!values[CAP_POSTAL_1.id] && notBelongToDominio(domini, CAP_POSTAL_1.dominio, values[CAP_POSTAL_1.id])) {
    errors[CAP_POSTAL_1.id] = 'CAP non riconosciuto'
  }

  // ITEM 2
  if (
    !!values[NAZIONE_NASCITA_SCHEDA_DATI_2.id] &&
    notBelongToDominio(domini, NAZIONE_NASCITA_SCHEDA_DATI_2.dominio, values[NAZIONE_NASCITA_SCHEDA_DATI_2.id])
  ) {
    errors[NAZIONE_NASCITA_SCHEDA_DATI_2.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_2.id] &&
    notBelongToDominio(domini, PROVINCIA_NASCITA_SCHEDA_DATI_2.dominio, values[PROVINCIA_NASCITA_SCHEDA_DATI_2.id])
  ) {
    errors[PROVINCIA_NASCITA_SCHEDA_DATI_2.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_2.id] &&
    !!values[COMUNE_NASCITA_SCHEDA_DATI_2.id] &&
    notBelongToDominio(domini, COMUNE_NASCITA_SCHEDA_DATI_2.dominio, values[COMUNE_NASCITA_SCHEDA_DATI_2.id])
  ) {
    errors[COMUNE_NASCITA_SCHEDA_DATI_2.id] = 'Comune non riconosciuto'
  }
  if (!!values[CITTADINANZA_2.id] && notBelongToDominio(domini, CITTADINANZA_2.dominio, values[CITTADINANZA_2.id])) {
    errors[CITTADINANZA_2.id] = 'Cittadinanza non riconosciuta'
  }
  if (
    !!values[NAZIONE_SCHEDA_DATI_2.id] &&
    notBelongToDominio(domini, NAZIONE_SCHEDA_DATI_2.dominio, values[NAZIONE_SCHEDA_DATI_2.id])
  ) {
    errors[NAZIONE_SCHEDA_DATI_2.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_SCHEDA_DATI_2.id] &&
    notBelongToDominio(domini, PROVINCIA_SCHEDA_DATI_2.dominio, values[PROVINCIA_SCHEDA_DATI_2.id])
  ) {
    errors[PROVINCIA_SCHEDA_DATI_2.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[COMUNE_SCHEDA_DATI_2.id] &&
    notBelongToDominio(domini, COMUNE_SCHEDA_DATI_2.dominio, values[COMUNE_SCHEDA_DATI_2.id])
  ) {
    errors[COMUNE_SCHEDA_DATI_2.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[CAP_SCHEDA_DATI_2.id] &&
    notBelongToDominio(domini, CAP_SCHEDA_DATI_2.dominio, values[CAP_SCHEDA_DATI_2.id])
  ) {
    errors[CAP_SCHEDA_DATI_2.id] = 'CAP non riconosciuto'
  }
  if (
    !!values[COMUNE_RILASCIO_2.id] &&
    notBelongToDominio(domini, COMUNE_RILASCIO_2.dominio, values[COMUNE_RILASCIO_2.id])
  ) {
    errors[COMUNE_RILASCIO_2.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[NAZIONE_POSTAL_2.id] &&
    notBelongToDominio(domini, NAZIONE_POSTAL_2.dominio, values[NAZIONE_POSTAL_2.id])
  ) {
    errors[NAZIONE_POSTAL_2.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_POSTAL_2.id] &&
    notBelongToDominio(domini, PROVINCIA_POSTAL_2.dominio, values[PROVINCIA_POSTAL_2.id])
  ) {
    errors[PROVINCIA_POSTAL_2.id] = 'Provincia non riconosciuta'
  }
  if (!!values[COMUNE_POSTAL_2.id] && notBelongToDominio(domini, COMUNE_POSTAL_2.dominio, values[COMUNE_POSTAL_2.id])) {
    errors[COMUNE_POSTAL_2.id] = 'Comune non riconosciuto'
  }
  if (!!values[CAP_POSTAL_2.id] && notBelongToDominio(domini, CAP_POSTAL_2.dominio, values[CAP_POSTAL_2.id])) {
    errors[CAP_POSTAL_2.id] = 'CAP non riconosciuto'
  }

  // ITEM 3
  if (
    !!values[NAZIONE_NASCITA_SCHEDA_DATI_3.id] &&
    notBelongToDominio(domini, NAZIONE_NASCITA_SCHEDA_DATI_3.dominio, values[NAZIONE_NASCITA_SCHEDA_DATI_3.id])
  ) {
    errors[NAZIONE_NASCITA_SCHEDA_DATI_3.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_3.id] &&
    notBelongToDominio(domini, PROVINCIA_NASCITA_SCHEDA_DATI_3.dominio, values[PROVINCIA_NASCITA_SCHEDA_DATI_3.id])
  ) {
    errors[PROVINCIA_NASCITA_SCHEDA_DATI_3.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_3.id] &&
    !!values[COMUNE_NASCITA_SCHEDA_DATI_3.id] &&
    notBelongToDominio(domini, COMUNE_NASCITA_SCHEDA_DATI_3.dominio, values[COMUNE_NASCITA_SCHEDA_DATI_3.id])
  ) {
    errors[COMUNE_NASCITA_SCHEDA_DATI_3.id] = 'Comune non riconosciuto'
  }
  if (!!values[CITTADINANZA_3.id] && notBelongToDominio(domini, CITTADINANZA_3.dominio, values[CITTADINANZA_3.id])) {
    errors[CITTADINANZA_3.id] = 'Cittadinanza non riconosciuta'
  }
  if (
    !!values[NAZIONE_SCHEDA_DATI_3.id] &&
    notBelongToDominio(domini, NAZIONE_SCHEDA_DATI_3.dominio, values[NAZIONE_SCHEDA_DATI_3.id])
  ) {
    errors[NAZIONE_SCHEDA_DATI_3.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_SCHEDA_DATI_3.id] &&
    notBelongToDominio(domini, PROVINCIA_SCHEDA_DATI_3.dominio, values[PROVINCIA_SCHEDA_DATI_3.id])
  ) {
    errors[PROVINCIA_SCHEDA_DATI_3.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[COMUNE_SCHEDA_DATI_3.id] &&
    notBelongToDominio(domini, COMUNE_SCHEDA_DATI_3.dominio, values[COMUNE_SCHEDA_DATI_3.id])
  ) {
    errors[COMUNE_SCHEDA_DATI_3.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[CAP_SCHEDA_DATI_3.id] &&
    notBelongToDominio(domini, CAP_SCHEDA_DATI_3.dominio, values[CAP_SCHEDA_DATI_3.id])
  ) {
    errors[CAP_SCHEDA_DATI_3.id] = 'CAP non riconosciuto'
  }
  if (
    !!values[COMUNE_RILASCIO_3.id] &&
    notBelongToDominio(domini, COMUNE_RILASCIO_3.dominio, values[COMUNE_RILASCIO_3.id])
  ) {
    errors[COMUNE_RILASCIO_3.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[NAZIONE_POSTAL_3.id] &&
    notBelongToDominio(domini, NAZIONE_POSTAL_3.dominio, values[NAZIONE_POSTAL_3.id])
  ) {
    errors[NAZIONE_POSTAL_3.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_POSTAL_3.id] &&
    notBelongToDominio(domini, PROVINCIA_POSTAL_3.dominio, values[PROVINCIA_POSTAL_3.id])
  ) {
    errors[PROVINCIA_POSTAL_3.id] = 'Provincia non riconosciuta'
  }
  if (!!values[COMUNE_POSTAL_3.id] && notBelongToDominio(domini, COMUNE_POSTAL_3.dominio, values[COMUNE_POSTAL_3.id])) {
    errors[COMUNE_POSTAL_3.id] = 'Comune non riconosciuto'
  }
  if (!!values[CAP_POSTAL_3.id] && notBelongToDominio(domini, CAP_POSTAL_3.dominio, values[CAP_POSTAL_3.id])) {
    errors[CAP_POSTAL_3.id] = 'CAP non riconosciuto'
  }

  // ITEM 4
  if (
    !!values[NAZIONE_NASCITA_SCHEDA_DATI_4.id] &&
    notBelongToDominio(domini, NAZIONE_NASCITA_SCHEDA_DATI_4.dominio, values[NAZIONE_NASCITA_SCHEDA_DATI_4.id])
  ) {
    errors[NAZIONE_NASCITA_SCHEDA_DATI_4.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_4.id] &&
    notBelongToDominio(domini, PROVINCIA_NASCITA_SCHEDA_DATI_4.dominio, values[PROVINCIA_NASCITA_SCHEDA_DATI_4.id])
  ) {
    errors[PROVINCIA_NASCITA_SCHEDA_DATI_4.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[PROVINCIA_NASCITA_SCHEDA_DATI_4.id] &&
    !!values[COMUNE_NASCITA_SCHEDA_DATI_4.id] &&
    notBelongToDominio(domini, COMUNE_NASCITA_SCHEDA_DATI_4.dominio, values[COMUNE_NASCITA_SCHEDA_DATI_4.id])
  ) {
    errors[COMUNE_NASCITA_SCHEDA_DATI_4.id] = 'Comune non riconosciuto'
  }
  if (!!values[CITTADINANZA_4.id] && notBelongToDominio(domini, CITTADINANZA_4.dominio, values[CITTADINANZA_4.id])) {
    errors[CITTADINANZA_4.id] = 'Cittadinanza non riconosciuta'
  }
  if (
    !!values[NAZIONE_SCHEDA_DATI_4.id] &&
    notBelongToDominio(domini, NAZIONE_SCHEDA_DATI_4.dominio, values[NAZIONE_SCHEDA_DATI_4.id])
  ) {
    errors[NAZIONE_SCHEDA_DATI_4.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_SCHEDA_DATI_4.id] &&
    notBelongToDominio(domini, PROVINCIA_SCHEDA_DATI_4.dominio, values[PROVINCIA_SCHEDA_DATI_4.id])
  ) {
    errors[PROVINCIA_SCHEDA_DATI_4.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[COMUNE_SCHEDA_DATI_4.id] &&
    notBelongToDominio(domini, COMUNE_SCHEDA_DATI_4.dominio, values[COMUNE_SCHEDA_DATI_4.id])
  ) {
    errors[COMUNE_SCHEDA_DATI_4.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[CAP_SCHEDA_DATI_4.id] &&
    notBelongToDominio(domini, CAP_SCHEDA_DATI_4.dominio, values[CAP_SCHEDA_DATI_4.id])
  ) {
    errors[CAP_SCHEDA_DATI_4.id] = 'CAP non riconosciuto'
  }
  if (
    !!values[COMUNE_RILASCIO_4.id] &&
    notBelongToDominio(domini, COMUNE_RILASCIO_4.dominio, values[COMUNE_RILASCIO_4.id])
  ) {
    errors[COMUNE_RILASCIO_4.id] = 'Comune non riconosciuto'
  }
  if (
    !!values[NAZIONE_POSTAL_4.id] &&
    notBelongToDominio(domini, NAZIONE_POSTAL_4.dominio, values[NAZIONE_POSTAL_4.id])
  ) {
    errors[NAZIONE_POSTAL_4.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_POSTAL_4.id] &&
    notBelongToDominio(domini, PROVINCIA_POSTAL_4.dominio, values[PROVINCIA_POSTAL_4.id])
  ) {
    errors[PROVINCIA_POSTAL_4.id] = 'Provincia non riconosciuta'
  }
  if (!!values[COMUNE_POSTAL_4.id] && notBelongToDominio(domini, COMUNE_POSTAL_4.dominio, values[COMUNE_POSTAL_4.id])) {
    errors[COMUNE_POSTAL_4.id] = 'Comune non riconosciuto'
  }
  if (!!values[CAP_POSTAL_4.id] && notBelongToDominio(domini, CAP_POSTAL_4.dominio, values[CAP_POSTAL_4.id])) {
    errors[CAP_POSTAL_4.id] = 'CAP non riconosciuto'
  }

  if (values[CODICE_FISCALE_0.id]) {
    if (values[CODICE_FISCALE_0.id].length < CODICE_FISCALE_0.minLength) {
      errors[CODICE_FISCALE_0.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CODICE_FISCALE_0.validazione).test(values[CODICE_FISCALE_0.id])) {
      errors[CODICE_FISCALE_0.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (!!values[CELLULARE_0.id] && !new RegExp(CELLULARE_0.validazione).test(values[CELLULARE_0.id])) {
    errors[CELLULARE_0.id] = 'Numero di cellulare non valido'
  }
  if (!!values[EMAIL_0.id] && !new RegExp(EMAIL_0.validazione).test(values[EMAIL_0.id])) {
    errors[EMAIL_0.id] = "L'indirizzo e-mail non è valido"
  }

  if (values[CODICE_FISCALE_1.id]) {
    if (values[CODICE_FISCALE_1.id].length < CODICE_FISCALE_1.minLength) {
      errors[CODICE_FISCALE_1.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CODICE_FISCALE_1.validazione).test(values[CODICE_FISCALE_1.id])) {
      errors[CODICE_FISCALE_1.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }

  if (!!values[CELLULARE_1.id] && !new RegExp(CELLULARE_1.validazione).test(values[CELLULARE_1.id])) {
    errors[CELLULARE_1.id] = 'Numero di cellulare non valido'
  }
  if (!!values[EMAIL_1.id] && !new RegExp(EMAIL_1.validazione).test(values[EMAIL_1.id])) {
    errors[EMAIL_1.id] = "L'indirizzo e-mail non è valido"
  }

  if (values[CODICE_FISCALE_2.id]) {
    if (values[CODICE_FISCALE_2.id].length < CODICE_FISCALE_2.minLength) {
      errors[CODICE_FISCALE_2.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CODICE_FISCALE_2.validazione).test(values[CODICE_FISCALE_2.id])) {
      errors[CODICE_FISCALE_2.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (!!values[CELLULARE_2.id] && !new RegExp(CELLULARE_2.validazione).test(values[CELLULARE_2.id])) {
    errors[CELLULARE_2.id] = 'Numero di cellulare non valido'
  }
  if (!!values[EMAIL_2.id] && !new RegExp(EMAIL_2.validazione).test(values[EMAIL_2.id])) {
    errors[EMAIL_2.id] = "L'indirizzo e-mail non è valido"
  }
  if (values[CODICE_FISCALE_3.id]) {
    if (values[CODICE_FISCALE_3.id].length < CODICE_FISCALE_3.minLength) {
      errors[CODICE_FISCALE_3.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CODICE_FISCALE_3.validazione).test(values[CODICE_FISCALE_3.id])) {
      errors[CODICE_FISCALE_3.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (!!values[CELLULARE_3.id] && !new RegExp(CELLULARE_3.validazione).test(values[CELLULARE_3.id])) {
    errors[CELLULARE_3.id] = 'Numero di cellulare non valido'
  }
  if (!!values[EMAIL_3.id] && !new RegExp(EMAIL_3.validazione).test(values[EMAIL_3.id])) {
    errors[EMAIL_3.id] = "L'indirizzo e-mail non è valido"
  }
  if (values[CODICE_FISCALE_4.id]) {
    if (values[CODICE_FISCALE_4.id].length < CODICE_FISCALE_4.minLength) {
      errors[CODICE_FISCALE_4.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(CODICE_FISCALE_4.validazione).test(values[CODICE_FISCALE_4.id])) {
      errors[CODICE_FISCALE_4.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }
  if (!!values[CELLULARE_4.id] && !new RegExp(CELLULARE_4.validazione).test(values[CELLULARE_4.id])) {
    errors[CELLULARE_4.id] = 'Numero di cellulare non valido'
  }
  if (!!values[EMAIL_4.id] && !new RegExp(EMAIL_4.validazione).test(values[EMAIL_4.id])) {
    errors[EMAIL_3.id] = "L'indirizzo e-mail non è valido"
  }
  if (
    !!values[NUMERO_DOCUMENTO_0.id] &&
    !new RegExp(NUMERO_DOCUMENTO_0.validazione).test(values[NUMERO_DOCUMENTO_0.id])
  ) {
    errors[NUMERO_DOCUMENTO_0.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (
    !!values[NUMERO_DOCUMENTO_1.id] &&
    !new RegExp(NUMERO_DOCUMENTO_1.validazione).test(values[NUMERO_DOCUMENTO_1.id])
  ) {
    errors[NUMERO_DOCUMENTO_1.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (
    !!values[NUMERO_DOCUMENTO_2.id] &&
    !new RegExp(NUMERO_DOCUMENTO_2.validazione).test(values[NUMERO_DOCUMENTO_2.id])
  ) {
    errors[NUMERO_DOCUMENTO_2.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (
    !!values[NUMERO_DOCUMENTO_3.id] &&
    !new RegExp(NUMERO_DOCUMENTO_3.validazione).test(values[NUMERO_DOCUMENTO_3.id])
  ) {
    errors[NUMERO_DOCUMENTO_3.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (
    !!values[NUMERO_DOCUMENTO_4.id] &&
    !new RegExp(NUMERO_DOCUMENTO_4.validazione).test(values[NUMERO_DOCUMENTO_4.id])
  ) {
    errors[NUMERO_DOCUMENTO_4.id] = 'è possibile inserire solo caratteri alfanumerici'
  }

  if (
    !!values[TIPO_RELAZIONE_ALTRO_0.id] &&
    !new RegExp(TIPO_RELAZIONE_ALTRO_0.validazione).test(values[TIPO_RELAZIONE_ALTRO_0.id])
  ) {
    errors[TIPO_RELAZIONE_ALTRO_0.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (
    !!values[TIPO_RELAZIONE_ALTRO_1.id] &&
    !new RegExp(TIPO_RELAZIONE_ALTRO_1.validazione).test(values[TIPO_RELAZIONE_ALTRO_1.id])
  ) {
    errors[TIPO_RELAZIONE_ALTRO_1.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (
    !!values[TIPO_RELAZIONE_ALTRO_2.id] &&
    !new RegExp(TIPO_RELAZIONE_ALTRO_2.validazione).test(values[TIPO_RELAZIONE_ALTRO_2.id])
  ) {
    errors[TIPO_RELAZIONE_ALTRO_2.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (
    !!values[TIPO_RELAZIONE_ALTRO_3.id] &&
    !new RegExp(TIPO_RELAZIONE_ALTRO_3.validazione).test(values[TIPO_RELAZIONE_ALTRO_3.id])
  ) {
    errors[TIPO_RELAZIONE_ALTRO_3.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (
    !!values[TIPO_RELAZIONE_ALTRO_4.id] &&
    !new RegExp(TIPO_RELAZIONE_ALTRO_4.validazione).test(values[TIPO_RELAZIONE_ALTRO_4.id])
  ) {
    errors[TIPO_RELAZIONE_ALTRO_4.id] = 'è possibile inserire solo caratteri alfanumerici'
  }

  if (!!values[INDIRIZZO_0.id] && !new RegExp(INDIRIZZO_0.validazione).test(values[INDIRIZZO_0.id])) {
    errors[INDIRIZZO_0.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (!!values[INDIRIZZO_1.id] && !new RegExp(INDIRIZZO_1.validazione).test(values[INDIRIZZO_1.id])) {
    errors[INDIRIZZO_1.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (!!values[INDIRIZZO_2.id] && !new RegExp(INDIRIZZO_2.validazione).test(values[INDIRIZZO_2.id])) {
    errors[INDIRIZZO_2.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (!!values[INDIRIZZO_3.id] && !new RegExp(INDIRIZZO_3.validazione).test(values[INDIRIZZO_3.id])) {
    errors[INDIRIZZO_3.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  if (!!values[INDIRIZZO_4.id] && !new RegExp(INDIRIZZO_4.validazione).test(values[INDIRIZZO_4.id])) {
    errors[INDIRIZZO_4.id] = 'è possibile inserire solo caratteri alfanumerici'
  }
  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEnabledTitolare,
      retrieveDominio: (...params) => dominio(...params),
      postAddictionalOwner,
      getAddictionalOwner,
      getDataFromTaxCode,
      deleteAddictionalOwner
    },
    dispatch
  )
const dataAda = state => {
  let obj
  obj = loadDocumentsDataPep(fields, state.utility.additionalOwnerList)
  return obj
}

const getPepInfo = state => {
  if (Array.isArray(state.utility.additionalOwnerList) && state.utility.additionalOwnerList.length > 0) {
    let i = 0
    let obj = {}
    while (i <= state.utility.additionalOwnerList.length - 1) {
      obj = {
        ...obj,

        [fields[`TITOLARE_EFFETTIVO_PEP_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {})
          .isTitolareEffettivoPEP,
        [fields[`TIPO_CLIENTE_PEP_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {}).tipologiaCliente,
        [fields[`INTESTATARIO_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {}).isIntestatario,
        [fields[`CONFERMA_ESSERE_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {})
          .confermaDiEssereDescrizione,
        [fields[`PEP_IN_QUANTO_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {}).pepInQuanto,
        [fields[`PEP_FAMILIARE_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {}).pepRelazione,
        [fields[`PEP_PROCEDIMENTI_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {}).pepProcedimenti,
        [fields[`PEP_PARTECIPAZIONI_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {}).pepPartecipazioni,
        [fields[`PEP_REDDITO_${i}`].id]: (state.utility.additionalOwnerList[i].pepInfo || {}).tipoFasciaRedditoPep
      }
      i++
    }
    return {
      ...obj
    }
  }
}

const mapStateToProps = state => {
  return {
    initialValues: {
      ...dataAda(state),
      ...getPepInfo(state)
    },
    formValues: getFormValues(SCHEDA_DATI_TITOLARE)(state),
    dataFromTaxCode: state.utility.dataFromTaxCode,
    getDataFromTaxCodeRequest: state.utility.getDataFromTaxCodeRequest,
    getAdditionalOwnerRequest: state.utility.getAdditionalOwnerList,
    dominioRequest: state.utility.dominioRequest,
    additionalOwnerList: state.utility.additionalOwnerList,
    titolare: state.utility.titolare,
    titolareMulti: state.utility.titolareMulti,
    formErrors: getFormSyncErrors(SCHEDA_DATI_TITOLARE)(state),
    dataNascitaCF: state.customers.dataRegistry.dataNascita || state.customers.customer.dataNascita,
    currentInitial: getFormInitialValues(SCHEDA_DATI_TITOLARE)(state),
    fields,
    isDirty: isDirty(SCHEDA_DATI_TITOLARE)(state),
    idPhysicalPersonReference: state.prospectPersonaGiuridica.summaryData.idPhysicalPersonReference,
    domini: state.dominio.domini,
    isFetching: state.utility.getAdditionalOwnerList.fetching,
    apiError: state.utility.getAdditionalOwnerList.error
    // flagCustomerIsBlacklist:
    //   state.customers.documentsData.flagCustomerIsBlacklist === false ||
    //   state.customers.documentsData.flagCustomerIsBlacklist === true
    //     ? state.customers.documentsData.flagCustomerIsBlacklist
    //     : state.customers.flagCustomerIsBlacklist
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: SCHEDA_DATI_TITOLARE,
    validate,
    enableReinitialize: true,
    shouldError: () => isDirty(SCHEDA_DATI_TITOLARE)
  })(SchedaDatiTitolare)
)
