import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FirmaWrapperComponent from './FirmaWrapperComponent'
import { servicesSearch } from '../../store/modules/services/actions'

const mapDispatchToProps = dispatch => bindActionCreators({ servicesSearch }, dispatch)

const mapStateToProps = state => ({
  services: state.services.services,
  query: state.services.query
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirmaWrapperComponent)
