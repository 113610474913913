import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Button } from 'react-materialize'
import { module_type, module_flow } from '../../routes/ConsulenzaBaseContainerLayout/utils'

const UserCard = ({ listaClienti = [], callback, staticDataFirma, tipoFirma, moduleType = '', disabled = false }) => {
  const items = listaClienti.map((user, i) => {
    let userIcon = null
    userIcon = (
      <span>
        <img src="/img/user_complete.png" />
        <Icon>done</Icon>
      </span>
    )

    let userText = null
    let userText2 = null

    userText = <div className="user-card-nome">{`${user.nomeCliente}`}</div>
    userText2 = <div className="user-card-cf">{`${user.codiceFiscale}`}</div>
    let userStatoPratica = null
    let labelUserStato = staticDataFirma.firmato
    if (user.stato === 'DA_FIRMARE') {
      labelUserStato = staticDataFirma.da_firmare
    } else {
      labelUserStato = user.stato
    }
    userStatoPratica = (
      <div>
        <div className="user-card-statopratica-titolo">{staticDataFirma.stato_label}</div>
        <div className="user-card-statopratica-dati">{`${labelUserStato.toLowerCase()}`}</div>
      </div>
    )

    let buttonAction = null
    const classNameStato = `color-${[user.stato]}`
    // firma digitale
    if (tipoFirma !== true) {
      if (user.stato !== 'FIRMATO') {
        switch (moduleType) {
          case module_type.ar:
            buttonAction = (
              <div className="ar-buttons">
                <Button flat waves="light" onClick={() => callback(module_flow.sign_room)}>
                  {' '}
                  {staticDataFirma.sign_room}
                </Button>
                <Button flat waves="light" onClick={() => callback(module_flow.modifica)}>
                  {' '}
                  {staticDataFirma.modifica}
                </Button>
              </div>
            )
            break
          default:
            buttonAction = (
              <Button flat waves="light" onClick={() => callback(user.urlIframe, user.idCliente)}>
                {' '}
                {staticDataFirma.prendi_visione}
              </Button>
            )
            break
        }
      }
      if (user.stato === 'CONDIVISA') {
        buttonAction = (
          <div className="ar-buttons">
            <Button flat waves="light" onClick={() => callback(module_flow.richiama)}>
              {' '}
              {staticDataFirma.richiama}
            </Button>
          </div>
        )
      }
    } else {
      // firma cartacea
      switch (moduleType) {
        case module_type.ar:
          buttonAction = (
            <div className="ar-buttons">
              <Button disabled={disabled} flat waves="light" onClick={() => callback(module_flow.firma)}>
                {' '}
                {staticDataFirma.firma}
              </Button>
              <Button flat waves="light" onClick={() => callback(module_flow.modifica)}>
                {' '}
                {staticDataFirma.modifica}
              </Button>
            </div>
          )
          break
        default:
          break
      }
    }

    return (
      <div key={i} className={`user-list-item padding-10 ${classNameStato}`}>
        <div className="user-icon">{userIcon}</div>
        <div className="user-name padding-b-5">
          {userText}
          {userText2}
          {userStatoPratica}
        </div>
        {buttonAction}
      </div>
    )
  })
  return (
    <div className="box-vertical box-vertical-right">
      <div className="container-list">{items}</div>
    </div>
  )
}
export default UserCard

UserCard.propTypes = {
  listaClienti: PropTypes.array,
  callback: PropTypes.func,
  staticDataFirma: PropTypes.object,
  tipoFirma: PropTypes.bool,
  moduleType: PropTypes.string,
  disabled: PropTypes.bool
}
