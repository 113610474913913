import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { ANTIRICICLAGGIO } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import ReadOnlyYesNo from '../../../../components/Inputs/ReadOnly/YesNo'
import { getValueDominioFromKey } from '../../utils'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'
import Title from '../../../../components/Title'

const fields = allFields[1][ANTIRICICLAGGIO]

class AntiriciclaggioReview extends Component {
  constructor(props) {
    super(props)
    this.renderSelect = this.renderSelect.bind(this)
    this.getRisposta = this.getRisposta.bind(this)
    this.mapRisposta = this.mapRisposta.bind(this)
    this.isAnswered = this.isAnswered.bind(this)
    this.getLabel = this.getLabel.bind(this)
  }

  getLabel({ templateId }) {
    return Object.keys(this.props.template).length > 0
      ? this.props.template.find(d => d.idDomanda === templateId).testoDomanda
      : ''
  }

  getRisposta({ templateId }) {
    const {
      data: { listaDomande }
    } = this.props
    if (!listaDomande) {
      return null
    }
    const risposta = listaDomande.find(d => d.idDomanda === templateId)
    if (!risposta) {
      return null
    }
    return risposta.listaRisposte[0].descRisposta
  }

  mapRisposta({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    if (!listaDomande) {
      return null
    }
    const risposta = listaDomande.find(d => d.idDomanda === templateId)
    if (!risposta) {
      return null
    }
    const idRisposta = risposta.listaRisposte[0].idRisposta
    return template.find(d => d.idDomanda === templateId).listaRisposte.find(r => r.idRisposta === idRisposta)
      .descRisposta
  }

  isAnswered({ templateId }) {
    const {
      data: { listaDomande }
    } = this.props
    if (!listaDomande) {
      return null
    }
    return !!listaDomande.find(d => d.idDomanda === templateId)
  }

  renderSelect(field, forceDominio) {
    const {
      domini: { listaDomini }
    } = this.props
    const { dominio } = field
    return getValueDominioFromKey(listaDomini, forceDominio || dominio, this.getRisposta(field))
  }

  mapCheckbox({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande ? listaDomande.find(d => d.idDomanda === templateId) : null
    const domandaTemplate = template ? template.find(d => d.idDomanda === templateId) : {}
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    return domanda.listaRisposte.map(({ idRisposta }) => {
      return domandaTemplate && domandaTemplate.listaRisposte
        ? domandaTemplate.listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
        : ''
    })
  }

  render() {
    const {
      data: { listaDomande },
      template
    } = this.props

    const templateFinderDomanda = template ? template.find(d => d.idDomanda === fields.RADIO_PEP.templateId) : ''
    const radioPepRisposte = templateFinderDomanda ? templateFinderDomanda.listaRisposte.map(r => r.idRisposta) : ''

    let siNoTrue = []
    let strutturaInternaDomandeSiNo = null
    if (template && template.length !== 0) {
      strutturaInternaDomandeSiNo = template.find(t => t.idDomanda === fields.INVESTIMENTI_IMMOBILIARI.templateId)
        .strutturaInternaDomande
    }

    if (strutturaInternaDomandeSiNo) {
      siNoTrue = strutturaInternaDomandeSiNo.listaDominiRisposte[0].risposte[0]
    }
    return (
      <CollapsibleItem
        header={dataSB.censimento_anagrafica.antiriciclaggio.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={ANTIRICICLAGGIO}
        labelInfo={this.props.accordionHeader}>
        <Row className="col-children-margin-bottom">
          <Col s={12}>
            <Title>Il sottoscritto dichiara di essere titolare effettivo</Title>
          </Col>
          <Col s={6}>
            <ReadOnlyInput
              label={this.getLabel(fields.TITOLO_STUDIO)}
              value={this.renderSelect(fields.TITOLO_STUDIO)}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput
              label={this.getLabel(fields.NATURA_RAPPORTO)}
              value={this.renderSelect(fields.NATURA_RAPPORTO)}
            />
          </Col>
          {this.isAnswered(fields.NATURA_RAPPORTO_ALTRO) && (
            <Col s={6}>
              <ReadOnlyInput
                label={this.getLabel(fields.NATURA_RAPPORTO_ALTRO)}
                value={this.getRisposta(fields.NATURA_RAPPORTO_ALTRO)}
              />
            </Col>
          )}
          <Col s={6}>
            <ReadOnlyInput
              label={this.getLabel(fields.SETTORE_OCCUPAZIONE)}
              value={this.renderSelect(fields.SETTORE_OCCUPAZIONE)}
            />
          </Col>
          {this.isAnswered(fields.SETTORE_OCCUPAZIONE_ALTRO) && (
            <Col s={6}>
              <ReadOnlyInput
                label={this.getLabel(fields.SETTORE_OCCUPAZIONE_ALTRO)}
                value={this.renderSelect(fields.SETTORE_OCCUPAZIONE_ALTRO)}
              />
            </Col>
          )}
          <Col s={6}>
            <ReadOnlyInput
              label={this.getLabel(fields.PROFESSIONE_ATTUALE)}
              value={this.renderSelect(fields.PROFESSIONE_ATTUALE)}
            />
          </Col>
          {this.isAnswered(fields.PROFESSIONE_PRECEDENTE) && (
            <Col s={6}>
              <ReadOnlyInput
                label={this.getLabel(fields.PROFESSIONE_PRECEDENTE)}
                value={this.renderSelect(fields.PROFESSIONE_PRECEDENTE, fields.PROFESSIONE_ATTUALE.dominio)}
              />
            </Col>
          )}
        </Row>
        <Row>
          {this.isAnswered(fields.NAZIONE_INSEDIAMENTO) && (
            <Col s={4}>
              <ReadOnlyInput
                label={this.getLabel(fields.NAZIONE_INSEDIAMENTO)}
                value={this.renderSelect(fields.NAZIONE_INSEDIAMENTO)}
              />
            </Col>
          )}
          {this.isAnswered(fields.PROVINCIA_ATTIVITA) && (
            <Col s={4}>
              <ReadOnlyInput
                label={this.getLabel(fields.PROVINCIA_ATTIVITA)}
                value={this.renderSelect(fields.PROVINCIA_ATTIVITA)}
              />
            </Col>
          )}
          {this.isAnswered(fields.ANNO_INIZIO) && (
            <Col s={4}>
              <ReadOnlyInput label={this.getLabel(fields.ANNO_INIZIO)} value={this.getRisposta(fields.ANNO_INIZIO)} />
            </Col>
          )}
        </Row>
        {!!listaDomande && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>Il Cliente è un soggetto PEP (Politically Exposed Person) o ha rapport con PEP?</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RADIO_PEP)} />
              </Col>
            </Row>
            {radioPepRisposte
              .slice(1, 3)
              .some(
                r =>
                  r === listaDomande.find(d => d.idDomanda === fields.RADIO_PEP.templateId).listaRisposte[0].idRisposta
              ) && (
              <Fragment>
                <Row>
                  <Col s={6}>
                    <ReadOnlyInput
                      label={this.getLabel(fields.CARICA_RICOPERTA)}
                      value={this.getRisposta(fields.CARICA_RICOPERTA)}
                    />
                  </Col>
                  <Col s={6}>
                    <ReadOnlyInput
                      label={this.getLabel(fields.INIZIO_CARICA)}
                      value={moment(this.getRisposta(fields.INIZIO_CARICA)).format('DD/MM/YYYY')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col s={12}>
                    <Title>{this.getLabel(fields.FONTE_REDDITO)}</Title>
                  </Col>
                  <Col s={12}>
                    <ReadOnlyInput value={this.getRisposta(fields.FONTE_REDDITO)} />
                  </Col>
                </Row>
                <Row>
                  <Col s={12}>
                    <Title>{this.getLabel(fields.RADIO_PEP_PERCHE)}</Title>
                  </Col>
                  <Col s={12}>
                    <ReadOnlyInput value={this.mapRisposta(fields.RADIO_PEP_PERCHE)} />
                  </Col>
                </Row>
              </Fragment>
            )}
            {listaDomande.find(d => d.idDomanda === fields.RADIO_PEP.templateId).listaRisposte[0].idRisposta ===
              radioPepRisposte[3] && (
              <Row>
                <Col s={12}>
                  <Title>{this.getLabel(fields.RADIO_PEP_RAPPORTI_PERCHE)}</Title>
                </Col>
                <Col s={12}>
                  <ReadOnlyInput value={this.mapRisposta(fields.RADIO_PEP_RAPPORTI_PERCHE)} />
                </Col>
              </Row>
            )}
            {listaDomande.find(d => d.idDomanda === fields.RADIO_PEP.templateId).listaRisposte[0].idRisposta !==
              radioPepRisposte[0] && (
              <Row>
                <Col s={12}>
                  <Title>{this.getLabel(fields.RADIO_PEP_REDDITO)}</Title>
                </Col>
                <Col s={12}>
                  <ReadOnlyInput value={this.mapRisposta(fields.RADIO_PEP_REDDITO)} />
                </Col>
              </Row>
            )}
            <Row>
              {listaDomande.find(d => d.idDomanda === fields.RADIO_PEP.templateId).listaRisposte[0].idRisposta !==
                radioPepRisposte[0] && (
                <Fragment>
                  <Col s={12}>
                    <ReadOnlyYesNo
                      label={this.getLabel(fields.YES_NO_PEP_PARTECIPAZIONI_SOC)}
                      value={this.mapRisposta(fields.YES_NO_PEP_PARTECIPAZIONI_SOC)}
                    />
                  </Col>
                  <Col s={12}>
                    <ReadOnlyYesNo
                      label={this.getLabel(fields.YES_NO_PEP_PROCEDIMENTI)}
                      value={this.mapRisposta(fields.YES_NO_PEP_PROCEDIMENTI)}
                    />
                  </Col>
                </Fragment>
              )}
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.YES_NO_CARICHE_POLITICHE)}
                  value={this.mapRisposta(fields.YES_NO_CARICHE_POLITICHE)}
                />
              </Col>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.YES_NO_CARICHE_EXTRA)}
                  value={this.mapRisposta(fields.YES_NO_CARICHE_EXTRA)}
                />
              </Col>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.YES_NO_CARICHE_PUBBLICHE)}
                  value={this.mapRisposta(fields.YES_NO_CARICHE_PUBBLICHE)}
                />
              </Col>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.YES_NO_FONDI_PUBBLICI)}
                  value={this.mapRisposta(fields.YES_NO_FONDI_PUBBLICI)}
                />
              </Col>
            </Row>
          </Fragment>
        )}
        <Fragment>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.PRINCIPALE_FONTE_REDDITO)}</Title>
            </Col>
            <Col s={12}>
              {(this.mapCheckbox(fields.PRINCIPALE_FONTE_REDDITO) || []).map((r, i) => (
                <ReadOnlyInput key={i} value={r} />
              ))}
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.FONTE_REDDITO_MEDIO)}</Title>
            </Col>
            <Col s={12}>
              <ReadOnlyInput value={this.mapRisposta(fields.FONTE_REDDITO_MEDIO)} />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.REDDITO_FUTURO)}</Title>
            </Col>
            <Col s={12}>
              <ReadOnlyInput value={this.mapRisposta(fields.REDDITO_FUTURO)} />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.PATRIMONIO_FINANZIARIO)}</Title>
            </Col>
            <Col s={12}>
              <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_FINANZIARIO)} />
            </Col>
          </Row>
          <h2 className="collapsible-section-title">Si o no?</h2>
          <div>
            <Title>{this.getLabel(fields.INVESTIMENTI_IMMOBILIARI)}</Title>
          </div>
          <Row>
            {strutturaInternaDomandeSiNo &&
              strutturaInternaDomandeSiNo.domande &&
              strutturaInternaDomandeSiNo.domande.map(domanda => {
                const listaRisp =
                  ((!!listaDomande && listaDomande.find(t => t.idDomanda === domanda.idDomanda)) || {}).listaRisposte ||
                  []
                const yes = listaRisp.some(r => r.idRisposta === siNoTrue.idRisposta)
                return (
                  <Col s={12} className="true-false-row" key={`col_${domanda.idDomanda}`}>
                    <div className="true-false-question disabled">
                      <p className="title">{domanda.testoDomanda}</p>
                      <p className="subtitle">{domanda.subTestoDomanda}</p>
                      {listaRisp.length > 0 && yes && (
                        <div className="box-input yes-selected">
                          <button type="button" className="yes-button si-btn" disabled>
                            SI
                          </button>
                        </div>
                      )}
                      {listaRisp.length > 0 && !yes && (
                        <div className="box-input no-selected">
                          <button type="button" className="no-button no-btn" disabled>
                            NO
                          </button>
                        </div>
                      )}
                      {listaRisp.length === 0 && <div className="box-input" />}
                    </div>
                  </Col>
                )
              })}
          </Row>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.PATRIMONIO_IMMOBILIARE)}</Title>
            </Col>
            <Col s={12}>
              <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_IMMOBILIARE)} />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO)}</Title>
            </Col>
            <Col s={12}>
              <ReadOnlyInput value={this.mapRisposta(fields.PATRIMONIO_FINANZIARIO_COMPLESSIVO)} />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.IMPEGNI_FINANZIARI_MENSILI)}</Title>
            </Col>
            <Col s={12}>
              <ReadOnlyInput value={this.mapRisposta(fields.IMPEGNI_FINANZIARI_MENSILI)} />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.RISPARMIO_MENSILE)}</Title>
            </Col>
            <Col s={12}>
              <ReadOnlyInput value={this.mapRisposta(fields.RISPARMIO_MENSILE)} />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Title>{this.getLabel(fields.PERSONE_DIPENDENTI)}</Title>
            </Col>
            <Col s={12}>
              <ReadOnlyInput value={this.mapRisposta(fields.PERSONE_DIPENDENTI)} />
            </Col>
          </Row>
        </Fragment>
      </CollapsibleItem>
    )
  }
}
export default AntiriciclaggioReview

AntiriciclaggioReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object,
  template: PropTypes.array
}
