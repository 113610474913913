import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import allFields from '../../fields'

let MIFID_ACTIVE = null
let fields = null

class AnagraficaReview extends Component {
  constructor(props) {
    super(props)

    this.renderSelect = this.renderSelect.bind(this)
    this.getRisposta = this.getRisposta.bind(this)
    this.mapRisposta = this.mapRisposta.bind(this)
    this.getLabel = this.getLabel.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.ANAGRAFICA)
    fields = allFields[2][MIFID_ACTIVE]
  }

  getLabel({ templateId }) {
    const { template } = this.props
    if (template) {
      return template.listaDomande.find(d => d.idDomanda === templateId).testoDomanda
    } else {
      return null
    }
  }

  getRisposta({ templateId }) {
    const {
      data: { listaDomande }
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    return domanda.listaRisposte[0].idRisposta
  }

  mapRisposta({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    const idRisposta = domanda.listaRisposte[0].idRisposta
    return template.listaDomande
      .find(d => d.idDomanda === templateId)
      .listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
  }

  renderSelect(field) {
    const {
      template: { listaDomande }
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === field.templateId)
    const res = domanda.listaRisposte.find(d => d.idRisposta === this.getRisposta(field))
    return res ? res.descRisposta : ''
  }

  render() {
    const { template } = this.props
    return (
      <CollapsibleItem
        header={template ? template.descSezione : ''}
        icon="keyboard_arrow_down"
        itemKey={MIFID_ACTIVE}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={6}>
            <ReadOnlyInput label={this.getLabel(fields.STATO_CIVILE)} value={this.renderSelect(fields.STATO_CIVILE)} />
          </Col>
          <Col s={6}>
            <ReadOnlyInput
              label={this.getLabel(fields.NUCLEO_FAMILIARE)}
              value={this.renderSelect(fields.NUCLEO_FAMILIARE)}
            />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}

AnagraficaReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  template: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
}

export default AnagraficaReview
