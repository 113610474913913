import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import allFields from '../../fields'
import Title from '../../../../components/Title'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import * as Forms from '../../../../store/modules/forms/constants'

let MIFID_ACTIVE = null
let fields = null
let NEW_MIFID = true

class InvestimentiESGReview extends Component {
  constructor(props) {
    super(props)

    this.mapRisposta = this.mapRisposta.bind(this)
    this.getLabel = this.getLabel.bind(this)
    this.getSubRadio = this.getSubRadio.bind(this)
    this.mapSubCheckbox = this.mapSubCheckbox.bind(this)
    this.getSubLabel = this.getSubLabel.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.INVESTIMENTI_ESG)
    fields = allFields[2][MIFID_ACTIVE]
  }

  getLabel(templateId, hideNumber) {
    const { template } = this.props
    const domanda = template.listaDomande.find(d => d.idDomanda === templateId)
    if (template) {
      return !hideNumber ? domanda.testoDomanda : domanda.testoDomanda.replace(domanda.numDomanda + ' - ', '')
    } else {
      return ''
    }
  }

  mapRisposta(templateId) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    const idRisposta = domanda.listaRisposte[0].idRisposta
    return template
      ? template.listaDomande.find(d => d.idDomanda === templateId).listaRisposte.find(r => r.idRisposta === idRisposta)
          .descRisposta
      : []
  }

  getSubLabel(templateId, hideNumber) {
    const { template } = this.props
    const domanda = template.listaDomande.find(d => d.idDomanda === templateId)
    const {
      strutturaInternaDomande: { domande }
    } = domanda
    if (template) {
      return !hideNumber ? domande[0].testoDomanda : domande[0].testoDomanda.replace(domande[0].numDomanda + ' - ', '')
    } else {
      return ''
    }
  }

  getSubRadio(templateId) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = template.listaDomande.find(d => d.idDomanda === templateId)
    const {
      strutturaInternaDomande: { domande, listaDominiRisposte }
    } = domanda
    const subDomanda = listaDomande.find(d => d.idDomanda === domande[0].idDomanda)
    const idRisposta = subDomanda.listaRisposte[0].idRisposta
    return listaDominiRisposte
      ? listaDominiRisposte[0].risposte.find(d => d.idRisposta === idRisposta).descRisposta
      : []
  }

  mapSubCheckbox(templateId) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = template.listaDomande.find(d => d.idDomanda === templateId)
    const {
      strutturaInternaDomande: { domande, listaDominiRisposte }
    } = domanda
    const subDomanda = listaDomande.find(d => d.idDomanda === domande[0].idDomanda)
    const [, ...checkbox] = subDomanda.listaRisposte
    return checkbox
      ? checkbox.map(
          ({ idRisposta }) => listaDominiRisposte[0].risposte.find(d => d.idRisposta === idRisposta).descRisposta || ''
        )
      : []
  }

  mapCheckbox({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    const domandaTemplate = template ? template.listaDomande.find(d => d.idDomanda === templateId) : {}
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    return domanda.listaRisposte.map(({ idRisposta }) => {
      return domandaTemplate && domandaTemplate.listaRisposte
        ? domandaTemplate.listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
        : ''
    })
  }

  render() {
    const { template } = this.props
    return (
      <CollapsibleItem
        header={template ? template.descSezione : ''}
        icon="keyboard_arrow_down"
        itemKey={MIFID_ACTIVE}
        labelInfo={this.props.accordionHeader}>
        {/* NUOVA MIFID PF/PG/PD */}
        {NEW_MIFID && (
          <Fragment>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.CONOSCENZA_ESPERIENZA.templateId)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.CONOSCENZA_ESPERIENZA.templateId)} />
              </Col>
            </Row>
            {/* <Row> */}
            {/*   <Col s={12}> */}
            {/*     <Title>{this.getLabel(fields.QUOTA_PORTAFOGLIO.templateId)}</Title> */}
            {/*   </Col> */}
            {/*   <Col s={12}> */}
            {/*     <ReadOnlyInput value={this.mapRisposta(fields.QUOTA_PORTAFOGLIO.templateId)} /> */}
            {/*   </Col> */}
            {/* </Row> */}
            {/* <Row> */}
            {/*   <Col s={12}> */}
            {/*     <Title>{this.getLabel(fields.LIMITAZIONI_DISINVESTIMENTO.templateId)}</Title> */}
            {/*   </Col> */}
            {/*   <Col s={12}> */}
            {/*     <ReadOnlyInput value={this.mapRisposta(fields.LIMITAZIONI_DISINVESTIMENTO.templateId)} /> */}
            {/*   </Col> */}
            {/* </Row> */}
            {/* /!* ASSENTE SULLA PD *!/ */}
            {/* {[MIFID_OBIETTIVI_PF, MIFID_OBIETTIVI_PG].includes(MIFID_ACTIVE) && ( */}
            {/*   <Row> */}
            {/*     <Col s={12}> */}
            {/*       <Title>{this.getLabel(fields.PERCENTUALE_DISINVESTIMENTO.templateId)}</Title> */}
            {/*     </Col> */}
            {/*     <Col s={12}> */}
            {/*       <ReadOnlyInput value={this.mapRisposta(fields.PERCENTUALE_DISINVESTIMENTO.templateId)} /> */}
            {/*     </Col> */}
            {/*   </Row> */}
            {/* )} */}
            {/* /!* SOLO PER PF *!/ */}
            {/* {MIFID_ACTIVE === MIFID_OBIETTIVI_PF && ( */}
            {/*   <Row> */}
            {/*     <Col s={12}> */}
            {/*       <Title>{this.getLabel(fields.ESTENSIONI_SUCCESSORIE.templateId)}</Title> */}
            {/*     </Col> */}
            {/*     <Col s={12}> */}
            {/*       <ReadOnlyInput value={this.mapRisposta(fields.ESTENSIONI_SUCCESSORIE.templateId)} /> */}
            {/*     </Col> */}
            {/*   </Row> */}
            {/* )} */}
            {/* /!* ASSENTE SULLA PD *!/ */}
            {/* {[MIFID_OBIETTIVI_PF, MIFID_OBIETTIVI_PG].includes(MIFID_ACTIVE) && ( */}
            {/*   <Row> */}
            {/*     <Col s={12}> */}
            {/*       <Title>{this.getLabel(fields.OBBLIGAZIONI_PERPETUE.templateId)}</Title> */}
            {/*     </Col> */}
            {/*     <Col s={12}> */}
            {/*       <ReadOnlyInput value={this.mapRisposta(fields.OBBLIGAZIONI_PERPETUE.templateId)} /> */}
            {/*     </Col> */}
            {/*   </Row> */}
            {/* )} */}
            <Row>
              <Col s={12}>
                <Title>{this.getSubLabel(fields.INTERESSE.templateId)}</Title>
              </Col>
              <Col s={12}>
                <Row>
                  <Col s={6}>
                    <ReadOnlyInput value={this.getSubRadio(fields.INTERESSE.templateId)} />
                  </Col>
                  <Col s={6}>
                    {(this.mapSubCheckbox(fields.INTERESSE.templateId) || []).map((r, i) => (
                      <ReadOnlyInput key={i} value={r} />
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col s={12}>
                <Title>{this.getSubLabel(fields.QUOTA_PORTAFOGLIO.templateId)}</Title>
              </Col>
              <Col s={12}>
                <Row>
                  <Col s={6}>
                    <ReadOnlyInput value={this.getSubRadio(fields.QUOTA_PORTAFOGLIO.templateId)} />
                  </Col>
                  <Col s={6}>
                    {(this.mapSubCheckbox(fields.QUOTA_PORTAFOGLIO.templateId) || []).map((r, i) => (
                      <ReadOnlyInput key={i} value={r} />
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>

            {(MIFID_ACTIVE === Forms.MIFID_INVESTIMENTI_ESG_PF || MIFID_ACTIVE === Forms.MIFID_INVESTIMENTI_ESG_PG) && (
              <Row>
                <Col s={12}>
                  <Title>
                    5.4 - Gli strumenti finanziari che contribuiranno alla parte del Suo portafoglio indicata nella
                    domanda precedente appartengono a una o più delle tre categorie di seguito elencate. Per ciascuna di
                    queste categorie, può indicare quali corrispondono alle Sue preferenze? (è sufficiente che lo
                    strumento finanziario soddisfi una delle seguenti preferenze)
                  </Title>
                </Col>
              </Row>
            )}

            {MIFID_ACTIVE === Forms.MIFID_INVESTIMENTI_ESG_PD && (
              <Row>
                <Col s={12}>
                  <Title>
                    3.4 - Gli strumenti finanziari che contribuiranno alla parte del Suo portafoglio indicata nella
                    domanda precedente appartengono a una o più delle tre categorie di seguito elencate. Per ciascuna di
                    queste categorie, può indicare quali corrispondono alle Sue preferenze? (è sufficiente che lo
                    strumento finanziario soddisfi una delle seguenti preferenze)
                  </Title>
                </Col>
              </Row>
            )}

            <Row>
              <Col s={12}>
                <Title>
                  a) Strumenti finanziari che tengono conto del loro impatto negativo sui fattori di sostenibilità,
                  ovvero tematiche ambientali, sociali e relative ai diritti umani, di governance
                </Title>
              </Col>
            </Row>

            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.IMPATTI_AMBIENTE.templateId, true)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.IMPATTI_AMBIENTE.templateId)} />
              </Col>
            </Row>

            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.IMPATTI_SOCIALI_GOV.templateId, true)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.IMPATTI_SOCIALI_GOV.templateId)} />
              </Col>
            </Row>

            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.STRUMENTI_SOCIALI_GOV.templateId, true)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.STRUMENTI_SOCIALI_GOV.templateId)} />
              </Col>
            </Row>

            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.STRUMENTI_AMBIENTE.templateId, true)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.STRUMENTI_AMBIENTE.templateId)} />
              </Col>
            </Row>
            {/* <Row> */}
            {/*   <Col s={12}> */}
            {/*     <Title>{this.getLabel(fields.PREFERENZE_INVESTIMENTI.templateId)}</Title> */}
            {/*   </Col> */}
            {/*   <Col s={12}> */}
            {/*     <ReadOnlyInput value={this.mapRisposta(fields.PREFERENZE_INVESTIMENTI.templateId)} /> */}
            {/*   </Col> */}
            {/* </Row> */}
          </Fragment>
        )}
      </CollapsibleItem>
    )
  }
}

InvestimentiESGReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  template: PropTypes.shape({
    descSezione: PropTypes.string,
    listaDomande: PropTypes.array
  })
}

export default InvestimentiESGReview
