import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { PROCURATORE_ID } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly/index'
// import InputImage from '../../../../components/InputImage'
import InputImage from '../../../../components/Inputs/Image'

import { getValueDominioFromKey } from '../../utils'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'

const fields = allFields[0][PROCURATORE_ID]

class ProcuratoreDocumentiReview extends Component {
  render() {
    const {
      data,
      domini: { listaDomini }
    } = this.props
    const {
      PREFISSO,
      CELLULARE,
      EMAIL,
      TIPO_DOCUMENTO,
      NAZIONE_RILASCIO,
      PROVINCIA_RILASCIO_PROC,
      COMUNE_RILASCIO_PROC,
      DATA_RILASCIO,
      DATA_SCADENZA,
      NUMERO_DOCUMENTO,
      DOC_RETRO,
      DOC_FRONTE
    } = fields
    return (
      <CollapsibleItem
        header={dataSB.acquisizione.procuratoreDocumenti.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={PROCURATORE_ID}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={4}>
            <ReadOnlyInput
              label={PREFISSO.label}
              value={getValueDominioFromKey(listaDomini, PREFISSO.dominio, data[PREFISSO.id] || {} /* .chiave */)}
            />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={CELLULARE.label} value={data[CELLULARE.id]} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={EMAIL.label} value={data[EMAIL.id]} />
          </Col>
        </Row>
        <Row>
          <Col s={4}>
            <ReadOnlyInput
              label={TIPO_DOCUMENTO.label}
              value={getValueDominioFromKey(listaDomini, TIPO_DOCUMENTO.dominio, data[TIPO_DOCUMENTO.id] || {})}
            />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={NUMERO_DOCUMENTO.label} value={data[NUMERO_DOCUMENTO.id]} />
          </Col>
        </Row>
        <Row>
          <Col s={4}>
            <ReadOnlyInput
              label={NAZIONE_RILASCIO.label}
              value={getValueDominioFromKey(listaDomini, NAZIONE_RILASCIO.dominio, data[NAZIONE_RILASCIO.id] || {})}
            />
          </Col>
          <Col s={4}>
            <ReadOnlyInput
              label={PROVINCIA_RILASCIO_PROC.label}
              value={getValueDominioFromKey(
                listaDomini,
                PROVINCIA_RILASCIO_PROC.dominio,
                data[PROVINCIA_RILASCIO_PROC.id] || {}
              )}
            />
          </Col>
          <Col s={4}>
            <ReadOnlyInput
              label={COMUNE_RILASCIO_PROC.label}
              value={getValueDominioFromKey(
                listaDomini,
                COMUNE_RILASCIO_PROC.dominio,
                data[COMUNE_RILASCIO_PROC.id] || {}
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col s={4}>
            <ReadOnlyInput label={DATA_RILASCIO.label} value={moment(data[DATA_RILASCIO.id]).format('DD/MM/YYYY')} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={DATA_SCADENZA.label} value={moment(data[DATA_SCADENZA.id]).format('DD/MM/YYYY')} />
          </Col>
        </Row>
        <Row>
          <Col s={6}>
            <InputImage
              readOnly
              label={
                <span>
                  <b>FRONTE</b> documento*
                </span>
              }
              fileIn={data[DOC_FRONTE.id]}
            />
          </Col>
          <Col s={6}>
            <InputImage
              readOnly
              label={
                <span>
                  <b>RETRO</b> documento*
                </span>
              }
              fileIn={data[DOC_RETRO.id]}
            />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}

ProcuratoreDocumentiReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object
}

export default ProcuratoreDocumentiReview
