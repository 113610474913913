import * as actionTypes from './constants'

const apiRequests = {
  privacySendRequest: {
    fetching: false,
    error: null
  },
  privacyCheckRequest: {
    fetching: false,
    error: null
  },
  moduleSendRequest: {
    fetching: false,
    error: null
  }
}

const initialState = {
  processId: null,
  isPrivacyAcceptanceDone: false,
  duplicatedEmail: false,
  modalState: false,
  hasProcuratore: false
}

export default function(state = { ...initialState, ...apiRequests }, action) {
  switch (action.type) {
    case actionTypes.PRIVACY_SEND_START:
      return {
        ...state,
        processId: null,
        privacySendRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRIVACY_RESET:
      return {
        ...state,
        processId: null,
        isPrivacyAcceptanceDone: false,
        privacyCheckRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRIVACY_SEND_SUCCESS:
      return {
        ...state,
        processId: action.payload.processId,
        duplicatedEmail: action.payload.duplicatedEmail,
        privacySendRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRIVACY_SEND_ERROR:
      return {
        ...state,
        privacySendRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.PRIVACY_CHECK_START:
      return {
        ...state,
        isPrivacyAcceptanceDone: false,
        privacyCheckRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRIVACY_CHECK_SUCCESS:
      return {
        ...state,
        isPrivacyAcceptanceDone: action.isPrivacyAcceptanceDone,
        idProcurator: action.idProcurator,
        privacyCheckRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRIVACY_CHECK_ERROR:
      return {
        ...state,
        privacyCheckRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.MODULE_CHECK_START:
      return {
        ...state,
        isPrivacyAcceptanceDone: false,
        privacyCheckRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.MODULE_CHECK_SUCCESS:
      return {
        ...state,
        isPrivacyAcceptanceDone: action.isPrivacyAcceptanceDone,
        privacyCheckRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.MODULE_CHECK_ERROR:
      return {
        ...state,
        privacyCheckRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.SET_PROCURATORE:
      return {
        ...state,
        hasProcuratore: true
      }
    case actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_TRUE:
      return {
        ...state,
        modalState: true
      }
    case actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_FALSE:
      return {
        ...state,
        modalState: false
      }
    case actionTypes.CONFIRM_PAPER_PRIVACY_START:
      return {
        ...state,
        // processId: null,
        moduleSendRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.CONFIRM_PAPER_PRIVACY_SUCCESS:
      return {
        ...state,
        isPrivacyAcceptanceDone: action.isPrivacyAcceptanceDone,
        idProcurator: action.idProcurator,
        moduleSendRequest: {
          fetching: false,
          error: null
        }
      }

    case actionTypes.CONFIRM_PAPER_PRIVACY_ERROR:
      return {
        ...state,
        moduleSendRequest: {
          fetching: false,
          error: action.error
        }
      }
    default:
      return state
  }
}
