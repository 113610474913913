import React from 'react'
import PropTypes from 'prop-types'

class ToggleBlurText extends React.Component {
  constructor(props) {
    super(props)
    this.toggleBlur = this.toggleBlur.bind(this)
  }

  componentDidUpdate(prevProps) {
    // set default
  }
  toggleBlur(e) {
    this.props.setToggleBlur(!this.props.toggleBlur)
  }

  render() {
    return (
      <div className="switch padding-10 align-right">
        <label>
          NASCONDI CLIENTI
          <input
            type="checkbox"
            checked={this.props.toggleBlur}
            onChange={e => {
              this.toggleBlur(e)
            }}
          />
          <span className="lever" />
        </label>
      </div>
    )
  }
}

ToggleBlurText.propTypes = {
  toggleBlur: PropTypes.bool,
  setToggleBlur: PropTypes.func
}

export default ToggleBlurText
