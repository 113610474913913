import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'
import { Route, Switch } from 'react-router-dom'
import { goToWhenSaved } from '../../store/modules/dirtyForm/actions'
import ModaleDirtyForm from '../../components/Modals/DirtyForm'
import { ConfigurazioneServizi } from './ConfigurazioneServizi'
import { Intestatari } from './Intestatari'

class AperturaServiziContainerLayout extends React.Component {
  render() {
    return (
      <Row className="container-home paddingless marginless">
        <ModaleDirtyForm history={this.props.history} />
        <Col s={12} className="container-home-left">
          {
            <section className="main-body no-aside">
              <Row className="thule-header valign-wrapper">
                <Col s={6} className="left-align">
                  <p className="thule-header-title">Apertura Servizi</p>
                  <a onClick={() => this.props.history.push('/')} className="torna-pratiche">
                    <i className="icon-arrow-back" /> Torna a elenco pratiche
                  </a>
                </Col>

                <Col s={6} className="right-align" />
              </Row>
              <Switch>
                <Route path={`${this.props.match.url}/intestatari`} component={Intestatari} />
                <Route path={`${this.props.match.url}/configurazione/:idPratica`} component={ConfigurazioneServizi} />
              </Switch>
            </section>
          }
        </Col>
      </Row>
    )
  }
}

AperturaServiziContainerLayout.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object
}

const mapStateToProps = state => ({})
const mapDispatchToProps = dispatch => bindActionCreators({ goToWhenSaved }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AperturaServiziContainerLayout)
