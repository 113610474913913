import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'
import { Route, Switch } from 'react-router-dom'
import { getDataSummaryLight, getDataGathering, resetConsulenza } from '../../store/modules/customers/actions'
import { dominio } from '../../store/modules/dominio/actions'
import { goToWhenSaved, resetGoToForced } from '../../store/modules/dirtyForm/actions'
import { getPratica, updateTipologiaCliente, updatePratica } from '../../store/modules/pratica/actions'
import { DataGathering } from './DataGathering'
import { AcquisizioneDocumenti } from './AcquisizioneDocumenti'
import { QuestionarioProfilatura } from './QuestionarioProfilatura'
import { CensimentoAnagrafico } from './CensimentoAnagrafico'
import { SottoscrizioneServizi } from './SottoscrizioneServizi'
import { RiepilogoCliente } from './RiepilogoCliente'
import Aside from '../../components/Aside/'
import { DOCUMENTO_IDENTITA_ALLEGATO, DATA_GATHERING } from '../../store/modules/forms/constants'
import ModaleDirtyForm from '../../components/Modals/DirtyForm'
import { getDominioRequest, getSectionToBeCompleted, getSubSectionToBeCompleted } from './utils'
import fields from './fields'
import { ProspectContextProvider } from './ProspectContext'

const fieldsContatti = fields[4][DATA_GATHERING]

class ConsulenzaBaseContainerLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingComplete: false,
      accordionKey: {},
      url: 'data-gathering'
    }
    this.renderAcquisizione = this.renderAcquisizione.bind(this)
    this.renderCensimento = this.renderCensimento.bind(this)
    this.renderMifid = this.renderMifid.bind(this)
    this.renderSottoscrizione = this.renderSottoscrizione.bind(this)
    this.renderRiepilogo = this.renderRiepilogo.bind(this)
    this.goToWhenSaved = this.goToWhenSaved.bind(this)
    this.renderDataGathering = this.renderDataGathering.bind(this)
  }

  componentDidMount() {
    const {
      getPratica,
      match: {
        params: { idPratica }
      },
      resetConsulenza
    } = this.props
    getPratica(idPratica)
    resetConsulenza()
  }

  componentDidUpdate(prevProps) {
    const {
      dominio,
      match,
      history,
      summaryData,
      getDataSummaryRequest,
      location: { pathname },
      dominioRequest,
      praticaRequest,
      getDataSummaryLight,
      forcedAccordion,
      forcedPath,
      resetGoToForced
    } = this.props

    if (
      prevProps.praticaRequest &&
      praticaRequest &&
      prevProps.praticaRequest.fetching &&
      !praticaRequest.fetching &&
      !praticaRequest.error
    ) {
      const {
        pratica: { intestatari, idStatoPratica }
      } = this.props
      let bool = location.pathname.indexOf('-PG') >= 0

      // Se idStatoPratica è maggiore di 1 non posso rientrare in modifica
      if (idStatoPratica > 1) {
        this.props.history.push('/')
      }

      if (!bool) {
        getDataSummaryLight(intestatari[0].id, intestatari[0].idIncarico)
      }
    }

    if (prevProps.getDataSummaryRequest.fetching && !getDataSummaryRequest.fetching && !this.state.loadingComplete) {
      // SARA 0
      if (this.props.pratica.idStatoPratica === 0) {
        const {
          getDataGathering,
          customer: { idCliente }
        } = this.props
        getDataGathering(idCliente)
      } else {
        const {
          route: { index, url }
        } = getSectionToBeCompleted(summaryData)
        const accordionKey = getSubSectionToBeCompleted(summaryData, index)
        // Se siamo nel riepilogo cliente, bisogna aspettare tutti i domini
        if (url === 'riepilogo-cliente') {
          dominio(
            fields.reduce(
              (res, cur) => {
                return {
                  listaDomini: [
                    ...res.listaDomini,
                    ...Object.keys(cur).reduce((r, c) => [...r, ...getDominioRequest(cur, c).listaDomini], [])
                  ]
                }
              },
              { listaDomini: [] }
            )
          )
        } else {
          dominio(getDominioRequest(fields[index], accordionKey))
          dominio(
            fields.reduce(
              (res, cur) => {
                return {
                  listaDomini: [
                    ...res.listaDomini,
                    ...Object.keys(cur).reduce((r, c) => {
                      if (c !== accordionKey) {
                        return [...r, ...getDominioRequest(cur, c).listaDomini]
                      }
                      return r
                    }, [])
                  ]
                }
              },
              { listaDomini: [] }
            ),
            true
          )
        }
        this.setState({ url })
      }
    }

    if (
      prevProps.getDataGatheringRequest.fetching &&
      !this.props.getDataGatheringRequest.fetching &&
      !this.state.loadingComplete
    ) {
      history.push(`${match.url}/${this.state.url}`)
      this.setState({ loadingComplete: true })
    }

    if (prevProps.dominioRequest.fetching && !dominioRequest.fetching && !this.state.loadingComplete) {
      history.push(`${match.url}/${this.state.url}`)
      this.setState({ loadingComplete: true })
    }

    if (!prevProps.forcedAccordion && forcedAccordion) {
      this.setState({ accordionKey: { [`${match.url}/${forcedPath}`]: forcedAccordion }, url: forcedPath })
      resetGoToForced()
      history.push(`${match.url}/${forcedPath}`)
    }

    if (
      (prevProps.location.pathname === '/consulenza-base/create' && pathname !== '/consulenza-base/create') ||
      (prevProps.location.pathname === '/consulenza-base-PG/create' && pathname !== '/consulenza-base-PG/create')
    ) {
      dominio(getDominioRequest(fields[0], DOCUMENTO_IDENTITA_ALLEGATO), true)
    }
  }

  goToWhenSaved(forcedUrl) {
    const {
      summaryData,
      goToWhenSaved,
      location: { pathname },
      match: {
        path,
        params: { idPratica }
      }
    } = this.props
    const { route, isPrevious } = getSectionToBeCompleted(summaryData, pathname.replace(path, ''))
    const url =
      forcedUrl || `/consulenza-base/${idPratica}/${route.url}` || `/consulenza-base-PG/${idPratica}/${route.url}`
    goToWhenSaved(url, isPrevious)
  }

  renderDataGathering(props) {
    const {
      match: {
        params: { idPratica }
      },
      customer: { idCliente }
    } = this.props
    return (
      <DataGathering
        {...props}
        idPratica={idPratica}
        blackList={this.state.blackList}
        isProspectGiuridiche={false}
        goToConsulenza={() => {
          const payload = {
            idStatoPratica: 1,
            codiceTipoConsulenza: fieldsContatti.CONSULENZA.chiave
          }
          this.setState({ loadingComplete: false })
          this.props.updateTipologiaCliente({
            idCustomer: idCliente,
            idPratica: idPratica,
            dominioTipologiaCliente: 'CLIENTE'
          })
          this.props.updatePratica(idPratica, payload).then(() => {
            this.props.getPratica(idPratica)
          })
        }}
      />
    )
  }

  renderAcquisizione(props) {
    const {
      location: { pathname },
      match: {
        params: { idPratica }
      }
    } = props
    return (
      <AcquisizioneDocumenti
        {...props}
        goToWhenSaved={this.goToWhenSaved}
        accordionKey={this.state.accordionKey[pathname]}
        idPratica={idPratica}
      />
    )
  }

  renderCensimento(props) {
    const {
      location: { pathname },
      match: {
        params: { idPratica }
      }
    } = props
    return (
      <CensimentoAnagrafico
        {...props}
        goToWhenSaved={this.goToWhenSaved}
        accordionKey={this.state.accordionKey[pathname]}
        idPratica={idPratica}
      />
    )
  }

  renderMifid(props) {
    const {
      location: { pathname }
    } = props
    return (
      <QuestionarioProfilatura
        {...props}
        goToWhenSaved={this.goToWhenSaved}
        accordionKey={this.state.accordionKey[pathname]}
      />
    )
  }

  renderSottoscrizione(props) {
    const {
      location: { pathname }
    } = props
    return (
      <SottoscrizioneServizi
        {...props}
        goToWhenSaved={this.goToWhenSaved}
        accordionKey={this.state.accordionKey[pathname]}
        url={this.props.match.url}
        idPratica={this.props.idPratica}
      />
    )
  }

  renderRiepilogo(props) {
    const {
      location: { pathname }
    } = props
    return (
      <RiepilogoCliente
        {...props}
        goToWhenSaved={this.goToWhenSaved}
        accordionKey={this.state.accordionKey[pathname]}
      />
    )
  }

  renderAside(history) {
    const {
      customer,
      summaryData: { profiloCliente, sezioni, percentuale }
    } = this.props
    const dataRegistry = customer.dataRegistry || {}
    const documentsData = customer.documentsData || {}

    return (
      <Aside
        history={history}
        name={dataRegistry.nome}
        surname={dataRegistry.cognome}
        taxCode={documentsData.taxCode || customer.codiceFiscale}
        profiloCliente={profiloCliente}
        sezioni={sezioni}
        percentuale={percentuale}
      />
    )
  }

  render() {
    const { history } = this.props

    return (
      <Row className="container-home paddingless marginless">
        <ModaleDirtyForm history={history} />
        {this.state.loadingComplete && (
          <Col s={12} className="container-home-left">
            <ProspectContextProvider value={{ isProspectPersonaGiuridica: location.pathname.indexOf('-PG') >= 0 }}>
              {this.props.match.url === `/consulenza-base/${this.props.idPratica}` ? (
                <Switch>
                  <Route
                    path="/consulenza-base/:idPratica/acquisizione-documenti"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                  <Route
                    path="/consulenza-base/:idPratica/censimento-anagrafico"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                  <Route
                    path="/consulenza-base/:idPratica/questionario-profilatura"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                  <Route
                    path="/consulenza-base/:idPratica/sottoscrizione-servizi"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                  <Route
                    path="/consulenza-base/:idPratica/riepilogo-cliente"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                </Switch>
              ) : (
                <Switch>
                  <Route
                    path="/consulenza-base-PG/:idPratica/acquisizione-documenti"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                  <Route
                    path="/consulenza-base-PG/:idPratica/censimento-anagrafico"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                  <Route
                    path="/consulenza-base-PG/:idPratica/questionario-profilatura"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                  <Route
                    path="/consulenza-base-PG/:idPratica/sottoscrizione-servizi"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                  <Route
                    path="/consulenza-base-PG/:idPratica/riepilogo-cliente"
                    component={_ => this.renderAside(history, this.props.match.url)}
                  />
                </Switch>
              )}
              <Switch>
                <Route path="/consulenza-base/:idPratica/data-gathering" component={this.renderDataGathering} />
                <Route path="/consulenza-base/:idPratica/acquisizione-documenti" component={this.renderAcquisizione} />
                <Route path="/consulenza-base/:idPratica/censimento-anagrafico" component={this.renderCensimento} />
                <Route path="/consulenza-base/:idPratica/questionario-profilatura" component={this.renderMifid} />
                <Route
                  path="/consulenza-base/:idPratica/sottoscrizione-servizi"
                  component={this.renderSottoscrizione}
                />
                <Route path="/consulenza-base/:idPratica/riepilogo-cliente" component={this.renderRiepilogo} />

                <Route path="/consulenza-base-PG/:idPratica/data-gathering" component={this.renderDataGathering} />
                <Route
                  path="/consulenza-base-PG/:idPratica/acquisizione-documenti"
                  component={this.renderAcquisizione}
                />
                <Route path="/consulenza-base-PG/:idPratica/censimento-anagrafico" component={this.renderCensimento} />
                <Route path="/consulenza-base-PG/:idPratica/questionario-profilatura" component={this.renderMifid} />
                <Route
                  path="/consulenza-base-PG/:idPratica/sottoscrizione-servizi"
                  component={this.renderSottoscrizione}
                />
                <Route path="/consulenza-base-PG/:idPratica/riepilogo-cliente" component={this.renderRiepilogo} />
              </Switch>
            </ProspectContextProvider>
          </Col>
        )}
      </Row>
    )
  }
}

ConsulenzaBaseContainerLayout.propTypes = {
  nome: PropTypes.string,
  cognome: PropTypes.string,
  codiceFiscale: PropTypes.string,
  inBlacklistCF: PropTypes.func,
  checkCFRequest: PropTypes.object,
  statusBlack: PropTypes.string,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  resetConsulenza: PropTypes.func,
  getDataSummaryLight: PropTypes.func,
  getDataSummaryRequest: PropTypes.object,
  customer: PropTypes.object,
  summaryData: PropTypes.object,
  dominio: PropTypes.func,
  dominioRequest: PropTypes.object,
  forcedAccordion: PropTypes.any,
  forcedPath: PropTypes.string,
  resetGoToForced: PropTypes.func,
  goToWhenSaved: PropTypes.func,
  getPratica: PropTypes.func,
  idPratica: PropTypes.any,
  praticaRequest: PropTypes.object,
  pratica: PropTypes.object,
  getDataGathering: PropTypes.func,
  getDataGatheringRequest: PropTypes.object,
  updatePratica: PropTypes.func,
  updateTipologiaCliente: PropTypes.func,
  flagCustomerIsBlacklist: PropTypes.any
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dominio,
      updatePratica,
      updateTipologiaCliente,
      getDataSummaryLight,
      getPratica,
      resetConsulenza,
      resetGoToForced,
      goToWhenSaved,
      getDataGathering
    },
    dispatch
  )
const mapStateToProps = state => ({
  customer: state.customers.customer,
  summaryData: state.customers.summaryData,
  getDataSummaryRequest: state.customers.getDataSummaryRequest,
  getDataGatheringRequest: state.customers.getDataGatheringRequest,
  dominioRequest: state.dominio.dominioRequest,
  forcedAccordion: state.dirtyForm.accordion,
  forcedPath: state.dirtyForm.path,
  pratica: state.pratica.pratica,
  idPratica: state.pratica.idPratica,
  praticaRequest: state.pratica.praticaRequest,
  nome: state.customers.dataGathering.name,
  cognome: state.customers.dataGathering.surname,
  codiceFiscale: state.customers.dataGathering.taxCode,
  statusBlack: state.customers.statusBlack
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsulenzaBaseContainerLayout)
