import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-materialize'
import moment from 'moment'
import ReadOnlyInput from '../../../components/Inputs/ReadOnly'

const ServiceCard = ({ toggleBlur, service, callBack, deletePratica }) => {
  // variable that changes CSS class for Button
  let stateDescrition
  // variable that changes CSS class for Button
  // let cardClass
  // label button
  let labelButton
  // variable that defines (name/surname or fiscal code)
  let elimina = false

  // set different CSS depending on idStatus
  if (service.idStatus !== undefined) {
    switch (service.idStatus) {
      case 0:
        stateDescrition = 'button-draft'
        // cardClass = 'service-card draft'
        labelButton = 'Concludi' // prospect
        elimina = true
        break
      case 1:
        stateDescrition = 'button-draft'
        // cardClass = 'service-card draft'
        labelButton = 'Concludi'
        elimina = true
        break
      case 2:
        stateDescrition = 'button-to-sign'
        // cardClass = 'service-card to-sign'
        labelButton = 'Firma'
        elimina = true
        break
      case 3:
        stateDescrition = 'button-compile-av'
        // cardClass = 'service-card compile-av'
        labelButton = 'Compila AV'
        break
      case 4:
        stateDescrition = 'button-complete'
        // cardClass = 'service-card complete'
        labelButton = ''
        break
      case 17:
        stateDescrition = 'button-draft'
        // cardClass = 'service-card draft'
        labelButton = 'Concludi' // prospect
        break
      case 18:
        stateDescrition = 'button-draft'
        // cardClass = 'service-card draft'
        labelButton = 'Concludi' // prospect
        elimina = true
        break
      default:
        stateDescrition = 'service-action'
        // cardClass = 'card'
        labelButton = ''
        break
    }
  } else {
    stateDescrition = 'service-action'
    // cardClass = 'card'
    labelButton = ''
  }

  let buttonRender = ''
  if (service.idStatus < 4 || [17, 18].indexOf(service.idStatus) > -1) {
    buttonRender = (
      <Button
        className={stateDescrition}
        onClick={() => {
          callBack(
            service.id,
            service.idStatus,
            service.idTipologia,
            service.descTipologia,
            service.adeguataVerificaAvailable
          )
        }}>
        {labelButton}
      </Button>
    )
  }
  let buttonDelete = ''
  if (elimina) {
    buttonDelete = (
      <button type="button" onClick={() => deletePratica(service)} className="delete-button">
        <i className="material-icons">delete_outline</i>
      </button>
    )
  }

  const renderFirma = flagFirmaCartacea => {
    switch (flagFirmaCartacea) {
      case true:
        return <ReadOnlyInput value="Cartacea" />
      case false:
        return <ReadOnlyInput value="Digitale" />
      default:
        return <ReadOnlyInput value="" />
    }
  }

  return (
    <tr>
      <td>
        <ReadOnlyInput value={moment(service.insertionDate).format('DD/MM/YY')} />
      </td>
      <td>
        <ReadOnlyInput value={service.id} />
      </td>
      <td>
        <ReadOnlyInput value={service.descStatus} />
      </td>
      <td className="tdServiceName">
        <ReadOnlyInput value={service.descTipologia} />
      </td>
      <td>{renderFirma(service.flagFirmaCartacea)}</td>
      <td>
        <ReadOnlyInput
          value={service.customers
            .sort((a, b) => a.idRuolo > b.idRuolo)
            .map((customer = {}, index) => {
              const { nome, cognome, codiceFiscale, migrato: isMigrato } = customer
              const textMigrato = isMigrato ? 'Cliente migrato, si prega di procedere nella modalità cartacea' : ''
              const suffix = index < service.customers.length - 1 ? ', ' : ' '
              if (nome && cognome) {
                return (
                  <span key={`cust_${index}`} className={toggleBlur ? 'blur-on' : ''} title={textMigrato}>
                    {nome} {cognome}
                    {suffix}
                  </span>
                )
              }
              if (!nome && cognome) {
                return (
                  <span key={`cust_${index}`} title={textMigrato}>
                    {cognome}
                    {suffix}
                  </span>
                )
              }
              return (
                <span key={`cust_${index}`} title={textMigrato}>
                  {codiceFiscale}
                  {suffix}
                </span>
              )
            })}
        />
      </td>
      <td>
        <div className="service-card-column-button">{buttonRender}</div>
      </td>
      <td>{buttonDelete}</td>
    </tr>
  )
}

ServiceCard.propTypes = {
  service: PropTypes.object,
  callBack: PropTypes.func,
  toggleBlur: PropTypes.bool,
  deletePratica: PropTypes.func
}

export default ServiceCard
