import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import { bindActionCreators } from 'redux'
import SoggettiCorrelati from './SoggettiCorrelatiComponent'
import { dominio } from '../../../../store/modules/dominio/actions'
import { SOGGETTI_CORRELATI } from '../../../../store/modules/forms/constants'
import { getInitialValues } from '../../utils'
import allFields from '../../../ConsulenzaBaseContainerLayout/fields'

const fields = allFields[1][SOGGETTI_CORRELATI]

const validate = values => {
  const errors = {}
  const { RICERCA } = fields
  if (values[RICERCA.id].length < 2) {
    errors[RICERCA.id] = 'Il campo Ricerca è obbligatorio con almeno 2 caratteri'
  }
  return errors
}

const mapDispatchToProps = dispatch => bindActionCreators({ dominio }, dispatch)

const mapStateToProps = state => {
  return {
    customer: state.customers.customer,
    isEmail: state.customers.isEmail,
    soggettiCorrelatiListData: state.customers.soggettiCorrelatiListData,
    totalpage: state.customers.totalpage,
    soggettiGiaCorrelatiListData: state.customers.soggettiGiaCorrelatiListData,
    soggettiCorrelatiListDataGiuridiche: state.customers.soggettiCorrelatiListDataGiuridiche,
    soggettiGiaCorrelatiListDataGiuridiche: state.customers.soggettiGiaCorrelatiListDataGiuridiche,
    file: state.customers.file,
    soggettiGiaCorrelatiRequest: state.customers.soggettiGiaCorrelatiRequest,
    domini: state.dominio.domini,
    dominioRequest: state.dominio.dominioRequest,
    formValues: getFormValues(SOGGETTI_CORRELATI)(state),
    formErrors: getFormSyncErrors(SOGGETTI_CORRELATI)(state),
    initialValues: getInitialValues(fields, state.customers.soggettiCorrelatiListData),
    users: state.customers.users
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: SOGGETTI_CORRELATI,
    validate,
    shouldError: () => isDirty(SOGGETTI_CORRELATI)
  })(SoggettiCorrelati)
)
