import React from 'react'
import PropTypes from 'prop-types'

const ModalGeneric = ({ show = false, handler, buttonLabel = 'Chiudi', title = 'Attenzione', text }) => {
  if (!show) return null
  return (
    <div className="modal-background">
      <div className="modal-box-container">
        <div className="modal-box-content">
          <h1 className="modal-title">{title}</h1>
          <p className="modal-text">{text}</p>
        </div>
        <div className="modal-box-actions">
          <button className="modal-action primary btncenter" onClick={handler}>
            {buttonLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

ModalGeneric.propTypes = {
  handler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  show: PropTypes.bool
}

export default ModalGeneric
