import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { renderCheckbox } from '../Forms/renderFields'
import Title from '../Title'

const SurveyCheckbox = ({
  template: { idDomanda, testoDomanda, subTestoDomanda, listaRisposte = [] },
  showSubTesto,
  formValues = {},
  radioClass = 'radio-vertical'
}) => {
  const isDisabled = risposta => {
    if (risposta.flagDipendenza && Array.isArray(risposta.listaDipendenze)) {
      return risposta.listaDipendenze.some(
        dip => formValues[`field_${dip.idDomandaDipendenza}`] === '' + dip.idRispostaDipendenza
      )
    }
    return false
  }

  return (
    <Fragment>
      <Title>
        {!showSubTesto && testoDomanda}
        {showSubTesto && subTestoDomanda}
      </Title>
      <div className={radioClass}>
        {listaRisposte.map(risposta => (
          <p key={risposta.idRisposta}>
            <Field
              name={`field_${idDomanda}_${risposta.idRisposta}`}
              type="checkbox"
              label={risposta.descRisposta}
              className="filled-in"
              disabled={isDisabled(risposta)}
              component={renderCheckbox}
            />
          </p>
        ))}
      </div>
    </Fragment>
  )
}
export default SurveyCheckbox

SurveyCheckbox.propTypes = {
  template: PropTypes.shape({
    idDomanda: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    testoDomanda: PropTypes.string,
    listaRisposte: PropTypes.array
  }).isRequired,
  radioClass: PropTypes.string,
  formValues: PropTypes.object,
  showSubTesto: PropTypes.bool
}
