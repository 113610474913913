import { connect } from 'react-redux'
import DerogaIntestatariView from '../components/DerogaIntestatariView'
import { getCustomerList } from '../../../../store/modules/customers/actions'
import { saveAndProceed, setIdPratica, resetPratica } from '../../../../store/modules/pratica/actions'
import { saveIntestatari } from '../../../../store/modules/derogaCommissionale/actions'

const mapDispatchToProps = dispatch => {
  return {
    getCustomerList: () => dispatch(getCustomerList()),
    saveAndProceed: paramObject => dispatch(saveAndProceed(paramObject)),
    saveIntestatari: paramObject => dispatch(saveIntestatari(paramObject)),
    setIdPratica: value => dispatch(setIdPratica(value)),
    resetPratica: () => dispatch(resetPratica())
  }
}

const mapStateToProps = state => ({
  users: state.customers.customers,
  getClientiListRequest: state.customers.getClientiListRequest,
  savePraticaRequest: state.pratica.savePraticaRequest,
  idPratica: state.pratica.idPratica || undefined
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DerogaIntestatariView)
