import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SottoscrizioneServizi from './SottoscrizioneServiziComponent'
import {
  getServiziDigitali,
  postServiziDigitali,
  getServiziDigitaliPG,
  postServiziDigitaliPG,
  getDataRegistry,
  getDocumentsData
} from '../../../store/modules/customers/actions'
import { dominio } from '../../../store/modules/dominio/actions'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import { SERVIZI_DIGITALI } from '../../../store/modules/forms/constants'
import { loadDefaultFields, notBelongToDominio } from '../utils'
import allFields from '../fields'
import { updateStatoPratica, tipoFirma } from '../../../store/modules/pratica/actions'
import { getDataSummaryLightPGActionCreator } from '../../../store/modules/personaGiuridica/prospect/actions'
import { downloadSignModule } from '../../../store/modules/utility/actions'

const fields = allFields[3][SERVIZI_DIGITALI]
const defaultFields = loadDefaultFields(fields)
const loadDocumentsData = serviziData => {
  const { SERVIZIO, MODULO, MODULO_THUMB, COMUNE_FIRMA } = fields
  let servizio = defaultFields[SERVIZIO.id]
  if (serviziData[SERVIZIO.id] !== undefined) {
    servizio = '' + serviziData[SERVIZIO.id]
  }
  return {
    [SERVIZIO.id]: servizio,
    [MODULO.id]: serviziData[MODULO.id],
    [MODULO_THUMB.id]: serviziData[MODULO_THUMB.id],
    [COMUNE_FIRMA.id]: (serviziData[COMUNE_FIRMA.id] || {}).chiave
  }
}

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const { COMUNE_FIRMA } = fields
  if (!!values[COMUNE_FIRMA.id] && notBelongToDominio(domini, COMUNE_FIRMA.dominio, values[COMUNE_FIRMA.id])) {
    errors[COMUNE_FIRMA.id] = 'Comune non riconosciuto'
  }

  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getServiziDigitali,
      postServiziDigitali,
      getServiziDigitaliPG,
      postServiziDigitaliPG,
      updateStatoPratica,
      getDataSummaryLightPGActionCreator,
      tipoFirma,
      getDataRegistry: (...params) => getDataRegistry('', ...params, false),
      retrieveDominio: (...params) => dominio(...params),
      getDocumentsData,
      downloadSignModule
    },
    dispatch
  )

const mapStateToProps = (state, ownProps) => {
  let commonstateToProps = {
    initialValues: {
      ...defaultFields,
      ...loadDocumentsData(state.customers.serviziData),
      [fields.CHECK_MODULO.id]: state.customers.serviziData.flagModuloFirmato
    },
    formValues: getFormValues(SERVIZI_DIGITALI)(state),
    formErrors: getFormSyncErrors(SERVIZI_DIGITALI)(state),
    // checkForms: () => Object.keys(forms).some(form => isDirty(forms[form])(state)),
    fields,
    isDirty: isDirty(SERVIZI_DIGITALI)(state),
    isFetching: state.customers.postServiziDigitaliRequest.fetching,
    apiError: state.customers.postServiziDigitaliRequest.error,
    customer: state.customers.customer,
    dataRegistry: state.customers.dataRegistry,
    serviziData: state.customers.serviziData,
    getDataRegistryRequest: state.customers.getDataRegistryRequest,
    getServiziDigitaliRequest: state.customers.getServiziDigitaliRequest,
    dominioRequest: state.dominio.dominioRequest,
    domini: state.dominio.domini,
    updatePraticaRequest: state.pratica.updatePraticaRequest,
    idPratica: state.pratica.idPratica
  }

  if (ownProps.isProspectPersonaGiuridica) {
    commonstateToProps = {
      ...commonstateToProps,
      summaryData: state.prospectPersonaGiuridica.summaryData,
      isAdmin: state.prospectPersonaGiuridica.admin,
      customer: state.customers.customer,
      updatePraticaRequest: state.pratica.updatePraticaRequest
    }
  } else {
    commonstateToProps = {
      ...commonstateToProps,
      summaryData: state.customers.summaryData
    }
  }
  return commonstateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: SERVIZI_DIGITALI,
    enableReinitialize: true,
    validate,
    shouldError: () => isDirty(SERVIZI_DIGITALI)
  })(SottoscrizioneServizi)
)
