import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { pie as d3Pie } from 'd3-shape'
import { csvParse, csvFormat } from 'd3-dsv'
import { Legend } from './Legend'
import { Chart } from './Chart'

// import dataJSON from './data.json'

/* TODO npm remove d3-scale d3-fetch */
class D3Donut extends Component {
  constructor(props) {
    super(props)
    /* Grafico a torta */
    this.pie = d3Pie().value(section => section.total)
    /* Dimensioni grafico */
    this.width = 500
    this.height = 400
    this.radius = Math.min(this.width, this.height) / 3
    /* Separatore archi */
    // this.padAngle = 0.01
  }

  /* Convertitore delle informazioni da JSON to CSV */
  converterJSONtoCSV(data) {
    return this.pie(csvParse(csvFormat(data.chart.series)))
  }

  /* Calcolo percentuali */
  calculatePercentage(totaleComplessivo, totaleField) {
    return (totaleField / totaleComplessivo) * 100
  }

  render() {
    // const { totaleComplessivo } = this.props
    /* In attesa dei dati corretti - MOCK */
    let totaleCalcolato = 0.0
    let listaPercentuali = []
    const { data } = this.props
    let json = this.converterJSONtoCSV(data)
    /* Totale conto mockato */
    json.map(item => {
      totaleCalcolato += parseFloat(item.value)
    })
    /* Lista percentuali mockate */
    json.map((item, index) => {
      listaPercentuali[index] = this.calculatePercentage(totaleCalcolato, item.value).toFixed(2)
    })

    return (
      <Fragment className="donut">
        <svg width={this.width} height={this.height} id="riepilogo-chart">
          {/* Grafico */}
          <Chart list={json} width={this.width} height={this.height} radius={this.radius} padAngle={this.padAngle} />
          {/* Informazione centrale */}
          {/* <foreignObject className="node" x="110" y="170" width="250" height="100">
            <div>
              <p style={{ textAlign: 'center' }}>Totale Portafoglio</p>
              <p style={{ textAlign: 'center' }}>{totaleComplessivo || totaleCalcolato}</p>
            </div>
          </foreignObject> */}
          {/* Legenda */}
          <foreignObject className="node" x="250" y="70" width="500" height="400">
            <Legend list={json} listaPercentuali={listaPercentuali} />
          </foreignObject>
        </svg>
      </Fragment>
    )
  }
}
export default D3Donut

/* D3Donut.propTypes = {
  totaleComplessivo: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
} */

D3Donut.propTypes = {
  data: PropTypes.object
}
