import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { CONTATTI } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import { getValueDominioFromKey } from '../../utils'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'
import SezioneResidenza from './SezioneResidenza'

const fields = allFields[1][CONTATTI]

class ContattiReview extends Component {
  render() {
    const {
      data,
      domini: { listaDomini }
    } = this.props

    return (
      <CollapsibleItem
        header={dataSB.censimento_anagrafica.contatti.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={CONTATTI}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={6}>
            <ReadOnlyInput
              label={fields.CELLULARE.label}
              value={`${getValueDominioFromKey(listaDomini, fields.PREFISSO.dominio, data[fields.PREFISSO.id] || {})} ${
                data[fields.CELLULARE.id]
              }`}
            />
          </Col>
          <Col s={6}>
            <ReadOnlyInput label={fields.EMAIL.label} value={data[fields.EMAIL.id]} />
          </Col>
        </Row>
        {data[fields.FLAG_INDIRIZZO.id] && <SezioneResidenza fields={fields} data={data} listaDomini={listaDomini} />}
      </CollapsibleItem>
    )
  }
}
export default ContattiReview

ContattiReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object
}
