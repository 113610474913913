import * as actionTypes from './constants'

import axios from 'axios'

export function servicesSearch(queryParam) {
  return async function(dispatch) {
    const query = {
      pageBy: {
        pageRequest: 1,
        pageSize: 4
      },
      sortBy: [
        {
          field: 'insertionDate',
          type: 'asc'
        }
      ],
      conditions: [],
      ...queryParam
    }

    dispatch({
      type: actionTypes.SERVICE_SEARCH_START,
      query: query
    })
    try {
      const response = await axios.post('/servizio/search', query)
      dispatch({
        type: actionTypes.SERVICE_SEARCH_SUCCESS,
        services: response.data.servizi,
        clienti: response.data.clienti,
        totServizi: response.data.totServizi,
        flagAperturaServizi: !!response.data.flagAperturaServizi,
        idPromotore: response.data.idPromotore,
        admin: response.data.admin
      })
    } catch (error) {
      dispatch({
        type: actionTypes.SERVICE_SEARCH_ERROR,
        error: error
      })
    }
  }
}

function fetchServizio(idServizio) {
  if (!idServizio) {
    return new Promise(function(resolve, reject) {
      resolve({ data: {} })
    })
  }
  try {
    return axios.get(`/servizio/${idServizio}`)
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = `fetchServizio ${idServizio}`
    throw newError
  }
}

function fetchPercentComm() {
  try {
    return axios.get('/utility/dominio/percentcomm')
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'fetchPercentComm'
    throw newError
  }
}

export function getServizioInfo(servizi = []) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.GET_SERVICE_INFO_START })
    try {
      const responses = await Promise.all([...servizi.map(s => fetchServizio(s.idServizio)), fetchPercentComm()])
      dispatch({
        type: actionTypes.GET_SERVICE_INFO_SUCCESS,
        serviceData: servizi.reduce((res, cur, index) => {
          return { ...res, [`servizio_${cur.idTipoServizio}`]: (responses[index].data || {}).servizio }
        }, {}),
        percentualiCommissionali: responses[responses.length - 1].data.percentualiCommissionali
      })
    } catch (error) {
      dispatch({ type: actionTypes.GET_SERVICE_INFO_ERROR, error })
    }
  }
}

export function postServizi(servizi = []) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.SERVICE_SAVE_MULTIPLE_START })
    try {
      const [responses] = await Promise.all([
        ...servizi.map(({ idServizio, payload }) => postServizio(idServizio, payload))
      ])
      dispatch({
        type: actionTypes.SERVICE_SAVE_MULTIPLE_SUCCESS,
        responses: responses
      })
    } catch (error) {
      dispatch({ type: actionTypes.SERVICE_SAVE_MULTIPLE_ERROR })
    }
  }
}

function postServizio(idServizio, payload) {
  try {
    return axios.post(`/servizio/${idServizio}`, payload)
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'postServizio'
    throw newError
  }
}

export function postServizioInfo(idServizio, payload) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.SERVICE_SAVE_START })
    try {
      const response = await postServizio(idServizio, payload)
      dispatch({
        type: actionTypes.SERVICE_SAVE_SUCCESS,
        data: response.data,
        payload
      })
    } catch (error) {
      dispatch({ type: actionTypes.SERVICE_SAVE_ERROR, error })
    }
  }
}

export function setTotServizi(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_TOT_SERVIZI,
      totServizi: value
    })
  }
}

export function setIdPromotore() {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_ID_PROMOTORE
    })
  }
}

export function getDomainService(serviceId) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DOMAIN_SERVICE_START
    })
    try {
      const response = await axios.get(`/servizio/getDomainService/${serviceId}`)
      dispatch({
        type: actionTypes.GET_DOMAIN_SERVICE_SUCCESS,
        domainServiceList: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DOMAIN_SERVICE_ERROR,
        error: error
      })
    }
  }
}
