import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import HomeComponent from '../components/HomeComponent'
import { servicesSearch, setTotServizi, setIdPromotore } from '../../../store/modules/services/actions'
import {
  deletePratica,
  getPraticaIntestatari,
  getPratica,
  updateStatoPratica,
  setIdPratica
} from '../../../store/modules/pratica/actions'
import { setPageSize } from '../../../store/modules/dataLoading/actions'
import { setPraticaCorrente } from '../../../store/modules/utility/actions'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      servicesSearch,
      setTotServizi,
      setPageSize,
      deletePratica,
      getPratica,
      getPraticaIntestatari,
      setPraticaCorrente,
      updateStatoPratica,
      setIdPratica,
      setIdPromotore
    },
    dispatch
  )

const mapStateToProps = state => ({
  services: state.services.services,
  idPromotore: state.services.idPromotore,
  totServizi: state.services.totServizi,
  query: state.services.query,
  clienti: state.services.clienti,
  admin: state.services.admin,
  flagAperturaServizi: state.services.flagAperturaServizi,
  deletePraticaRequest: state.pratica.deletePraticaRequest,
  praticaIntestatariRequest: state.pratica.praticaIntestatariRequest,
  serviceSearchRequest: state.services.serviceSearchRequest,
  toggleBlur: state.toggleBlur.toggleBlur,
  pageSize: state.dataLoading.pageSizeValue,
  flagFirmaPraticaInvestimento: state.pratica.flagFirmaPraticaInvestimento
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeComponent)
