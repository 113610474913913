import * as actionTypes from './constants'
import * as actionTypesProspect from '../prospect/constants'
import axios from 'axios'
import { getDataSummaryLightPGActionCreator, retrieveDataSummaryLightPG } from '../prospect/actions'

/**
 * Il seguente servizio REST permette di salvare le immagini - preview di quanto caricato sull'applicativo (es fronte e retro documenti identita')
 * @param {*} query
 * @param {*} isProcurator
 */
function postIdCardDataAttachment(query, isProcurator = false) {
  try {
    // let url = isProcurator ? `/v2/procurator/${id}/${endpoint}` : `/v2/customer/${id}/${endpoint}`
    return axios.post(`/v2/legal-person/reference/id-card-data-attachment`, query)
  } catch (error) {
    const newError = new Error(error.message)
    throw newError
  }
}

/**
 * Il seguente servizio REST permette di salvare le immagini - preview di quanto caricato sull'applicativo (es fronte e retro documenti identita')
 * @param {*} query
 * @param {*} isProcurator
 */
function postIdCardData(query, isProcurator = false) {
  try {
    // let url = isProcurator ? `/v2/procurator/${id}/${endpoint}` : `/v2/customer/${id}/${endpoint}`
    return axios.post(`/v2/legal-person/reference/id-card-data`, query)
  } catch (error) {
    const newError = new Error(error.message)
    throw newError
  }
}

/**
 * Il seguente servizio REST permette di ricevere le informazioni relative alla sezione Documenti di Idendita - Persona fisica di riferimento'
 * @param {string} idLegalPerson
 * @param {string} idPractice
 */
export function getDocumentIdPFActionCreator(idLegalPerson, idPractice) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DOC_ID_PF_START
    })
    try {
      const response = await axios.get(
        `/v2/legal-person/reference/id-card-data/${idLegalPerson}/?&idPractice=${idPractice}`
      )
      dispatch({
        type: actionTypes.GET_DOC_ID_PF_SUCCESS,
        responseApi: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DOC_ID_PF_ERROR,
        error
      })
    }
  }
}

/**
 * Il seguente servizio REST permette di ricevere le informazioni relative alla sezione Scheda di raccolta dati per la verifica della clientela '
 * @param {string} idLegalPerson
 */
export function getDataVerificaClienti(idLegalPerson) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DATA_VERIFICA_CLIENTI_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/reference/data-customer-verification/${idLegalPerson}/`)
      dispatch({
        type: actionTypes.GET_DATA_VERIFICA_CLIENTI_SUCCESS,
        dataVerificaClienti: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DATA_VERIFICA_CLIENTI_ERROR,
        error
      })
    }
  }
}
/**
 * Il seguente servizio REST permette di salvare le informazioni relative alla sezione Scheda di raccolta dati per la verifica della clientela '
 * @param {string} idLegalPerson
 */
export function postDataVerificaClienti(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_DATA_VERIFICA_CLIENTI_START
    })
    try {
      const response = await axios.post(`/v2/legal-person/reference/data-customer-verification/`, payload)
      dispatch({
        type: actionTypes.POST_DATA_VERIFICA_CLIENTI_SUCCESS,
        dataVerificaClienti: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_DATA_VERIFICA_CLIENTI_ERROR,
        error
      })
    }
  }
}

/** accordion documenti persona giuridica
 * Servizo REST per recuperare le informazioni relative alla sezione Documenti di Idendita - Persona giuridica
 * @param {string} idLegalPerson
 */
export function getDocumentIdPGActionCreator(idLegalPerson) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DOC_ID_PG_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/id-card-data/${idLegalPerson}/`)
      dispatch({
        type: actionTypes.GET_DOC_ID_PG_SUCCESS,
        responseApi: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DOC_ID_PG_ERROR,
        error
      })
    }
  }
}

export function getDataRegistryPGExt(idCustomerExt) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DATA_REGISTRY_PG_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/id-card-data-ext/${idCustomerExt}/`)
      dispatch({
        type: actionTypes.GET_DATA_REGISTRY_PG_SUCCESS,
        responseApi: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DATA_REGISTRY_PG_ERROR,
        error
      })
    }
  }
}

/**
 * Servizio per recuperare le info relative alla persona fisica di riferimento
 * @param {string} idLegalPerson
 */
export function getAnagraficaPFActionCreator(idLegalPerson) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_ANAGRAFICA_PF_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/reference/personal-information/${idLegalPerson}`)
      dispatch({
        type: actionTypes.GET_ANAGRAFICA_PF_SUCCESS,
        responseApi: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ANAGRAFICA_PF_ERROR,
        error
      })
    }
  }
}

export function postAnagraficaPFActionCreator(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_ANAGRAFICA_PF_START
    })
    try {
      const response = await axios.post(`/v2/legal-person/reference/personal-information/`, payload)
      dispatch({
        type: actionTypes.POST_ANAGRAFICA_PF_SUCCESS,
        responseApi: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_ANAGRAFICA_PF_ERROR,
        error
      })
    }
  }
}

/**
 * Servizo REST per recuperare le informazioni relative alla sezione Documenti di Idendita - Persona giuridica
 * @param {string} idLegalPerson
 */
export function getAnagraficaPGActionCreator(idLegalPerson) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_ANAGRAFICA_PG_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/id-card-data/${idLegalPerson}/`)
      dispatch({
        type: actionTypes.GET_DOC_ID_PG_SUCGET_ANAGRAFICA_PG_SUCCESSCESS,
        responseApi: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ANAGRAFICA_PG_ERROR,
        error
      })
    }
  }
}
/**
 * Servizo REST per salvare le informazioni relative alla sezione Documenti di Idendita - Persona giuridica
 * @param {object} objectBody
 */
// export function postDocumentIdPGActionCreator(objectBody) {
//   return async function(dispatch) {
//     dispatch({
//       type: actionTypes.POST_DOC_ID_PG_START
//     })
//     try {
//       const response = await axios.post('/v2/legal-person/reference/id-card-data/', objectBody)
//       dispatch({
//         type: actionTypes.POST_DOC_ID_PG_SUCCESS,
//         responseApi: response.data,
//         requestApi: objectBody
//       })
//     } catch (error) {
//       dispatch({
//         type: actionTypes.POST_DOC_ID_PG_ERROR,
//         error
//       })
//     }
//   }
// }

export function postDocumentIdPGActionCreator(idCliente, idIncarico, query, idPfRiferimento) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_DOC_ID_PG_START,
      query
    })
    let responseApi = null
    axios({
      method: 'POST',
      url: `/v2/legal-person/reference/id-card-data/`,
      data: query
    })
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLightPG(idCliente, idIncarico)
      })
      .then(response => {
        dispatch(getDataVerificaClienti(idPfRiferimento))
        return response
      })
      .then(response => {
        dispatch({
          type: actionTypesProspect.GET_DATA_SUMMARY_LIGHT_PG_SUCCESS,
          responseApi: response.data
        })
        dispatch({
          type: actionTypes.POST_DOC_ID_PG_SUCCESS,
          data: responseApi,
          requestApi: query
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_DOC_ID_PG_ERROR,
          error
        })
      })
  }
}
// TODO RETRIEVE
// export function postDocumentIdPersoneGActionCreator(objectBody) {
//   return async function(dispatch) {
//     dispatch({
//       type: actionTypes.POST_DOC_ID_PERSONE_G_START
//     })
//     try {
//       const response = await axios.post('/v2/legal-person/id-card-data/', objectBody)
//       dispatch({
//         type: actionTypes.POST_DOC_ID_PERSONE_G_SUCCESS,
//         responseApi: response.data,
//         requestApi: objectBody
//       })
//     } catch (error) {
//       dispatch({
//         type: actionTypes.POST_DOC_ID_PERSONE_G_ERROR,
//         error
//       })
//     }
//   }
// }

export function postDocumentIdPersoneGActionCreator(idCliente, idIncarico, query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_DOC_ID_PERSONE_G_START,
      query
    })
    let responseApi = null
    axios({
      method: 'POST',
      url: `/v2/legal-person/id-card-data/`,
      data: query
    })
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLightPG(idCliente, idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypesProspect.GET_DATA_SUMMARY_LIGHT_PG_SUCCESS,
          responseApi: response.data
        })
        dispatch({
          type: actionTypes.POST_DOC_ID_PERSONE_G_SUCCESS,
          data: responseApi,
          requestApi: query
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_DOC_ID_PERSONE_G_ERROR,
          error
        })
      })
  }
}

/**
 * Servizio per il salvataggio del codice fiscale (immagini) della persona fisica di riferimento
 * @param {*} idCardAllegati
 * @param {*} idCardData
 */
export function saveCodiceFiscalePFActionCreator(idLegalPerson, idPractice, idCardAllegati, idCardData) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_CF_PF_START
    })

    try {
      const postAttachmentResponse = await postIdCardDataAttachment(idCardAllegati)
      const postDataResponse = await postIdCardData(idCardData)
      // Serve la data summary lite per far chiudere l'accordion
      await dispatch(getDataSummaryLightPGActionCreator(idLegalPerson, idPractice))
      dispatch({
        type: actionTypes.POST_CF_PF_SUCCESS,
        responseApiAttachment: postAttachmentResponse,
        responseApiData: postDataResponse
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_CF_PF_ERROR,
        error
      })
    }
  }
}

export function checkProspectPGActionCreator(objectBody) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PROSPECT_CHECK_PG_START
    })
    try {
      const response = await axios.post('/v2/legal-person/check-legal-person', objectBody)

      dispatch({
        type: actionTypes.PROSPECT_CHECK_PG_SUCCESS,
        isProspectPGAvailable: response.data.code
      })
    } catch (error) {
      dispatch({
        type: actionTypes.PROSPECT_CHECK_PG_ERROR,
        error
      })
    }
  }
}

export function postFatcaPG(objectBody) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_FATCA_START
    })
    try {
      const response = await axios.post('/v2/legal-person/fatca-crs/save', objectBody)
      dispatch({
        type: actionTypes.POST_FATCA_SUCCESS,
        responseFatcaPG: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_FATCA_ERROR,
        error
      })
    }
  }
}

export function getFatcaPG(idLegalPerson) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_FATCA_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/fatca-crs/${idLegalPerson}/get`)
      dispatch({
        type: actionTypes.GET_FATCA_SUCCESS,
        dataFatcaPG: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_FATCA_ERROR,
        error
      })
    }
  }
}

export function setCurrentForm(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_CURRENT_FORM,
      currentForm: value
    })
  }
}
