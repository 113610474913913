import ModaleDirtyForm from './ModaleDirtyFormComponent'
import { connect } from 'react-redux'
import { isDirty } from 'redux-form'
import * as forms from '../../../store/modules/forms/constants'

const mapStateToProps = state => {
  return {
    nextPath: state.dirtyForm.path,
    isPrevious: state.dirtyForm.isPrevious,
    counter: state.dirtyForm.counter,
    checkForms: () => Object.keys(forms).some(form => isDirty(forms[form])(state))
  }
}

export default connect(mapStateToProps)(ModaleDirtyForm)
