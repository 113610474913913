import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { CONTATTI } from '../../../../store/modules/forms/constants'
import { loadContatti, fieldSpreader } from '../../utils'
import AccordionHeader from '../../../../components/AccordionHeader'
import * as dataSB from '../../../../static-data/data-servizio-base.json'
import ContattiResidenzaForm from '../../../../components/Forms/Contatti/ContattiResidenzaForm'
import ContattiForm from '../../../../components/Forms/Contatti/ContattiForm'
import ButtonSubmit from '../../../../components/Buttons/Submit'

const infoContatti = dataSB.censimento_anagrafica.contatti

class Contatti extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saved: false,
      waitingDomini: false,
      certifiedPhone: {
        number: null,
        prefix: null
      },
      certifiedEmail: null
    }

    this.addResidenzaToggle = this.addResidenzaToggle.bind(this)
    this.submit = this.submit.bind(this)
    this.saveForm = this.saveForm.bind(this)
    this.handleVerifyOtp = this.handleVerifyOtp.bind(this)
    this.checkVerifiedValuesToSave = this.checkVerifiedValuesToSave.bind(this)
  }

  saveForm(values) {
    const { formValues, initialize, resetForm } = this.props
    initialize(values || formValues)
    resetForm(CONTATTI)
    this.setState({ saved: true })
  }

  componentDidMount() {
    const {
      initialValues,
      fields: { CELLULARE, PREFISSO, EMAIL }
    } = this.props

    this.setState({
      certifiedPhone: { number: initialValues[CELLULARE.id], prefix: initialValues[PREFISSO.id] },
      certifiedEmail: initialValues[EMAIL.id]
    })
  }

  componentDidUpdate(prevProps) {
    const {
      formValues,
      change,
      fields: { FLAG_INDIRIZZO }
    } = this.props

    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.saveForm()
    }
    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && this.state.waitingDomini) {
      change(FLAG_INDIRIZZO.id, !formValues[FLAG_INDIRIZZO.id])
      this.setState({ waitingDomini: false })
    }

    this.checkVerifiedValuesToSave(prevProps)
  }

  checkVerifiedValuesToSave(prevProps) {
    const {
      formValues,
      fields: { CELLULARE, PREFISSO, EMAIL, FLAG_CELLULARE, FLAG_EMAIL }
    } = this.props

    if (prevProps.formValues[FLAG_CELLULARE.id] === false && formValues[FLAG_CELLULARE.id]) {
      this.setState({
        certifiedPhone: {
          number: formValues[CELLULARE.id],
          prefix: formValues[PREFISSO.id]
        }
      })
    }
    if (prevProps.formValues[FLAG_EMAIL.id] === false && formValues[FLAG_EMAIL.id]) {
      this.setState({ certifiedEmail: formValues[EMAIL.id] })
    }
  }

  addResidenzaToggle() {
    const { formValues, change, dataRegistry, updateDomini, fields } = this.props
    const listaDomini = []
    if (!formValues[fields.FLAG_INDIRIZZO.id]) {
      const previous = loadContatti(dataRegistry, fields)
      Object.keys(fields).forEach(k => {
        const field = fields[k]
        if ((field.daInformazioni || field.daResidenza) && formValues[field.id] !== previous[field.id]) {
          change(field.id, previous[field.id])
          if (field.dominio && field.dominioDep && previous[field.dominioDep]) {
            listaDomini.push({
              idDominio: field.dominio,
              filtro: {
                codice: previous[field.dominioDep]
              }
            })
          }
        }
      })
    }
    if (listaDomini.length > 0) {
      this.setState({ waitingDomini: true })
      updateDomini(listaDomini)
    } else {
      change(fields.FLAG_INDIRIZZO.id, !formValues[fields.FLAG_INDIRIZZO.id])
    }
  }

  handleVerifyOtp(type, { codice, riferimento }, flag) {
    const { idCliente, idIncarico, validationVerifyOtp, handleSubmit } = this.props

    validationVerifyOtp(
      type,
      {
        codice,
        idCliente,
        idIncarico,
        riferimento,
        subjectType: 'PHYSICAL_SUBJECT'
      },
      null,
      flag === 'flagCertificazioneCellulare' && this.isFormValidForSavingPhone ? handleSubmit(this.submit) : null
    )
  }

  submit(values) {
    const { onSave, fields } = this.props
    const spreadFields = fieldSpreader(fields)
    onSave(
      Object.keys(values).reduce((res, cur) => {
        const field = spreadFields.find(el => el.id === cur)
        const { dominio } = field
        if (!values[fields.FLAG_INDIRIZZO.id] && (field.daInformazioni || field.daResidenza)) {
          return res
        }
        if (dominio && (/^[a-zA-Z0-9]*$/g.test(values[cur]) || values[cur] === '')) {
          return {
            ...res,
            [cur]: { codiceDominio: dominio, chiave: values[cur] },
            internationalPrefixCellNumberCode: this.props.formValues[this.props.fields.PREFISSO.id]
          }
        }
        return { ...res, [cur]: values[cur] }
      }, {})
    )
  }

  get isFormValidForSavingPhone() {
    const { submitting, invalid, formErrors, isFetching } = this.props

    const formErrorsWithoutPhone = { ...formErrors }
    delete formErrorsWithoutPhone.flagCertificazioneCellulare

    return !submitting && !invalid && !isFetching && Object.keys(formErrorsWithoutPhone).length === 0
  }

  render() {
    const {
      handleSubmit,
      submitting,
      status,
      isDirty,
      invalid,
      domini,
      dominioRequest,
      updateDominio,
      formValues,
      formErrors,
      fields,
      currentInitial,
      change,
      touch,
      isFetching,
      verifyOtpRequest,
      createOtpRequest,
      validationCreateOtp,
      otpStatus,
      fieldsResidenza,
      formValuesResidenza
    } = this.props

    return (
      <CollapsibleItem
        itemKey={CONTATTI}
        header={infoContatti.title_accordion}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <ContattiForm
            fields={fields}
            change={change}
            touch={touch}
            currentInitial={currentInitial}
            domini={domini}
            formValues={formValues}
            formErrors={formErrors}
            verifyOtpRequest={verifyOtpRequest}
            createOtpRequest={createOtpRequest}
            validationCreateOtp={validationCreateOtp}
            validationVerifyOtp={this.handleVerifyOtp}
            otpStatus={otpStatus}
            saveForm={this.saveForm}
            type={'PHYSICAL_SUBJECT'}
            verifyAndSaveFlag={this.isFormValidForSavingPhone}
            certifiedPhone={this.state.certifiedPhone}
            certifiedEmail={this.state.certifiedEmail}
          />
          <Row className="m-top10">
            <Col s={12}>
              <hr />
              {!formValues[fields.FLAG_INDIRIZZO.id] && (
                <span className="indirizzo-alternativo">
                  <b>{infoContatti.label_comunicazioni}</b> {infoContatti.label_comunicazioni_value}
                </span>
              )}
              <a onClick={this.addResidenzaToggle} className="indirizzo-alternativo-button">
                {!formValues[fields.FLAG_INDIRIZZO.id] && infoContatti.button_link_modifica}
                {formValues[fields.FLAG_INDIRIZZO.id] && infoContatti.button_link_annulla}
              </a>
            </Col>
          </Row>
          {formValues[fields.FLAG_INDIRIZZO.id] && (
            <ContattiResidenzaForm
              fields={fields}
              fieldsResidenza={fieldsResidenza}
              formErrors={formErrors}
              change={change}
              touch={touch}
              updateDominio={updateDominio}
              domini={domini}
              dominioRequest={dominioRequest}
              formValues={formValues}
              formValuesResidenza={formValuesResidenza}
              isDirty={isDirty}
            />
          )}
          <ButtonSubmit
            disabled={
              submitting || !isDirty || invalid || isFetching || Object.keys(formErrors).some(e => formErrors[e])
            }
          />
        </form>
      </CollapsibleItem>
    )
  }
}

Contatti.propTypes = {
  domini: PropTypes.object,
  dominioRequest: PropTypes.object,
  updateDominio: PropTypes.func,
  updateDomini: PropTypes.func,
  onSave: PropTypes.func,
  validationCreateOtp: PropTypes.func,
  validationVerifyOtp: PropTypes.func,
  createOtpRequest: PropTypes.object,
  verifyOtpRequest: PropTypes.object,
  otpStatus: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  formValuesResidenza: PropTypes.object,
  formErrors: PropTypes.object,
  fields: PropTypes.object,
  fieldsResidenza: PropTypes.object,
  currentInitial: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  change: PropTypes.func,
  touch: PropTypes.func,
  initialize: PropTypes.func,
  status: PropTypes.any,
  dataRegistry: PropTypes.object,
  idCliente: PropTypes.any,
  idIncarico: PropTypes.any,
  initialValues: PropTypes.object
}

Contatti.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default Contatti
