import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import Title from '../../../../components/Title'

const RicchezzaNonInvestita = ({ template: { listaRisposte, testoDomanda }, barTotal, formValues, change }) => {
  const onBarChange = idRisposta => ({ target: { value } }) => {
    if (value.match(/^[0-9]{0,3}$/)) {
      change(`field_${idRisposta}`, parseInt(value.replace(/[^0-9]/, '')))
    }
  }

  return (
    <div className="ricchezza-non-investita">
      <Title>{testoDomanda}</Title>
      {listaRisposte.map(({ idRisposta, descRisposta }, i) => (
        <Row key={idRisposta} className="input-row">
          <Col s={10}>
            <div className={`color-key color-key-line color-${i}`} />
            <div className="p-left20">{descRisposta}</div>
          </Col>
          <Col s={2} className="input-wrapper">
            <input
              type="number"
              maxLength={3}
              disabled={!formValues[`field_${idRisposta}`] && barTotal >= 100}
              onChange={onBarChange(idRisposta)}
              value={formValues[`field_${idRisposta}`] ? formValues[`field_${idRisposta}`] : 0}
            />
          </Col>
        </Row>
      ))}
      <div className="bar-container">
        <div className="bar">
          {barTotal > 0 &&
            listaRisposte.map(({ idRisposta }, i) => {
              const width = formValues[`field_${idRisposta}`]
              if (!width) {
                return null
              }
              return <div key={i} className={`color-key color-${i}`} style={{ width: `${width}%` }} />
            })}
        </div>
        <p className="empty-bar-msg">
          {barTotal < 1 && <span>(compila i campi per popolare la barra indicativa)</span>}
          {barTotal > 100 && (
            <span className="error-msg">Attenzione: la somma delle percentuali non può essere superiore al 100%.</span>
          )}
        </p>
      </div>
    </div>
  )
}

RicchezzaNonInvestita.propTypes = {
  template: PropTypes.object,
  formValues: PropTypes.object,
  change: PropTypes.func,
  barTotal: PropTypes.number
}

export default RicchezzaNonInvestita
