import * as actionTypes from './constants'

export function setDataLoading(dataLoadingValue) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_DATA_LOADING,
      dataLoadingValue
    })
  }
}

export function setPageSize(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_PAGE_SIZE,
      pageSizeValue: value
    })
  }
}
