import axios from 'axios'
import * as actionTypes from './constants'
import * as actionTypesPratica from '../pratica/constants'

const BASE_API = '/v2/privacy-acceptance'

export function moduleSendProcuratoreTrue(payload) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_TRUE
    })
  }
}

export function moduleSendProcuratoreFalse(payload) {
  return function(dispatch) {
    dispatch({
      type: actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_FALSE
    })
  }
}

export function moduleSend(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.CONFIRM_PAPER_PRIVACY_START
    })
    axios
      .post(`${BASE_API}/confirm-paper-privacy`, payload)
      .then(response => {
        const { isPrivacyAcceptanceDone, idPractice, idProcurator, idLegalPerson } = response.data
        if (response.code === 'UTILITY.004' && response.description === 'Errore nel richiamo dei servizi esterni') {
          let error = {
            response: {
              statusText: 'Errore nel richiamo di servizi esterni'
            }
          }
          dispatch({
            type: actionTypes.CONFIRM_PAPER_PRIVACY_ERROR,
            error
          })
        } else
          dispatch({
            type: actionTypes.CONFIRM_PAPER_PRIVACY_SUCCESS,
            isPrivacyAcceptanceDone,
            idPratica: idPractice,
            idLegalPerson: idLegalPerson,
            idProcurator
          })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CONFIRM_PAPER_PRIVACY_ERROR,
          error
        })
      })
  }
}

export function privacyReset() {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRIVACY_RESET
    })
  }
}

export function privacySend(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRIVACY_SEND_START
    })
    axios
      .post(`${BASE_API}/send`, payload)
      .then(response => {
        dispatch({
          type: actionTypes.PRIVACY_SEND_SUCCESS,
          payload: {
            // processId: null,
            processId: response.data.processId,
            duplicatedEmail: response.data.duplicatedEmail // || true
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.PRIVACY_SEND_ERROR,
          error
        })
      })
  }
}

export function privacyCheck(processId) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRIVACY_CHECK_START
    })
    axios
      .get(`${BASE_API}/check?processId=${processId}`)
      .then(response => {
        const { isPrivacyAcceptanceDone, newCustomer, idCustomer } = response.data
        if (newCustomer !== null) {
          if ((newCustomer || {}).idLegalPerson !== null && (newCustomer || {}).idLegalPerson !== undefined) {
            dispatch({
              type: actionTypesPratica.SET_LEGAL_PERSON,
              idLegalPerson: (newCustomer || {}).idLegalPerson,
              idIncarico: (newCustomer || {}).idIncarico
            })
          }
        }

        dispatch({
          type: actionTypes.PRIVACY_CHECK_SUCCESS,
          isPrivacyAcceptanceDone,
          idPratica: (newCustomer || {}).idIncarico,
          idLegalPerson: (newCustomer || {}).idLegalPerson,
          idCustomer: idCustomer,
          idIncarico: (newCustomer || {}).idIncarico
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.PRIVACY_CHECK_ERROR,
          error
        })
      })
  }
}

export function moduleCheck(processId) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.MODULE_CHECK_START
    })
    axios
      .get(`${BASE_API}/check?processId=${processId}`)
      .then(response => {
        const { isPrivacyAcceptanceDone, newCustomer } = response.data
        dispatch({
          type: actionTypes.MODULE_CHECK_SUCCESS,
          isPrivacyAcceptanceDone,
          idPratica: (newCustomer || {}).idIncarico
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.MODULE_CHECK_ERROR,
          error
        })
      })
  }
}
