import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, isDirty, Field } from 'redux-form'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../../components/Collapsible'
import AccordionHeader from '../../../../../components/AccordionHeader'
import InputPlusMinus from '../../../../../components/InputPlusMinus'
import { renderSelect } from '../../../../../components/Forms/renderFields'
import ModalitaPagamento from '../common/ModalitaPagamento'
import SpeseButton from '../common/SpeseButton'
import { APERTURA_MAX_BNP } from '../../../../../store/modules/forms/constants'
import allFields from '../../../fields'
import { loadDefaultFields } from '../../../../ConsulenzaBaseContainerLayout/utils'
import { createPayload, loadServiceData, getIdServizio, mapCommissioneToOption } from '../../utils'
import ButtonSubmit from '../../../../../components/ButtonSubmit'
import TipologiaContoForm from '../common/TipologiaContoForm'

const fields = allFields[APERTURA_MAX_BNP]
const defaultFields = loadDefaultFields(fields)
const selector = formValueSelector(APERTURA_MAX_BNP)
const idServizio = getIdServizio(APERTURA_MAX_BNP)

const validate = () => ({})

class MaxBnp extends Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, expanded: false }
    this.isDisabled = this.isDisabled.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && !this.props.apiError && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(APERTURA_MAX_BNP)
      this.setState({ saved: true })
    }
  }

  isDisabled(id) {
    const { IMPORTO, ORIGINE } = fields
    const { formValues } = this.props
    switch (id) {
      case ORIGINE.id:
        return !formValues[IMPORTO.id]
      default:
        return false
    }
  }

  submit(values) {
    const { serviceData, onSave } = this.props

    let obTosend = createPayload(fields, values, serviceData, this.state.expanded)
    if (obTosend.idTipologiaConto === null) {
      obTosend.idTipologiaConto = '1'
    }
    onSave(serviceData.idServizio, obTosend)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      getStatus,
      isDirty,
      anyTouched,
      invalid,
      domini,
      formValues,
      percentualiCommissionali,
      change,
      isFetching,
      serviceInformation
    } = this.props
    const { FASCIA, COMM_ADVISORY } = fields
    const percComm = percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    const selectedComm = percComm.find(p => p.codiceCommissione === parseInt(formValues[FASCIA.id])) || {}

    return (
      <CollapsibleItem
        itemKey={APERTURA_MAX_BNP}
        header={serviceInformation[0].descTipoServizio}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, getStatus(this.state.saved))}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalitaPagamento
            fields={fields}
            domini={domini}
            formValues={formValues}
            change={change}
            origineRequired={!!formValues[fields.IMPORTO.id]}
            isDisabled={this.isDisabled}
          />
          <Row>
            <Field
              name={FASCIA.id}
              size={6}
              label={FASCIA.label}
              defaultValue={FASCIA.defaultValue}
              options={mapCommissioneToOption(percComm)}
              onChange={(ev, key) =>
                change(COMM_ADVISORY.id, percComm.find(p => p.codiceCommissione === parseInt(key)).percentualeDefault)
              }
              component={renderSelect}
            />
          </Row>
          <Row className="m-top10">
            <Col s={12} className="spese-button">
              <SpeseButton
                expanded={this.state.expanded}
                handler={() => this.setState({ expanded: !this.state.expanded })}
              />
            </Col>
          </Row>
          {this.state.expanded && (
            <Row>
              <Col s={6}>
                <InputPlusMinus
                  label={COMM_ADVISORY.label}
                  disabled={!formValues[FASCIA.id]}
                  value={formValues[COMM_ADVISORY.id]}
                  stepValue={selectedComm.percentualeIntervallo}
                  minValue={selectedComm.percentualeMinima || percComm.percentualeDefault}
                  maxValue={selectedComm.percentualeMassima || percComm.percentualeDefault}
                  onChange={value => change(COMM_ADVISORY.id, value)}
                />
              </Col>
            </Row>
          )}
          <TipologiaContoForm
            fields={fields}
            tipoServizio="MAX_BNP"
            formValues={formValues}
            {...this.props}
            isDisabled
          />
          <ButtonSubmit disabled={submitting || (!isDirty && !anyTouched) || invalid || isFetching} />
        </form>
      </CollapsibleItem>
    )
  }
}

MaxBnp.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  anyTouched: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  formValues: PropTypes.object,
  domini: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  serviceData: PropTypes.object,
  onSave: PropTypes.func,
  percentualiCommissionali: PropTypes.array,
  getStatus: PropTypes.func,
  serviceInformation: PropTypes.array
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData.servizio_12,
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    ),
    ...{ [fields.TIPOLOGIA_CONTO.id]: '1' }
  },
  formValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData.servizio_12,
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    ),
    ...selector(state, ...Object.keys(defaultFields).reduce((res, cur) => [...res, cur], []))
  },
  isDirty: isDirty(APERTURA_MAX_BNP)(state),
  isFetching: state.services.postServizioInfoRequest.fetching,
  apiError: state.services.postServizioInfoRequest.error,
  serviceInformation: state.pratica.pratica.servizi.filter(item => item.idTipoServizio === 12)
}))(
  reduxForm({
    form: APERTURA_MAX_BNP,
    validate
  })(MaxBnp)
)
