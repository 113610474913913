import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { untouch, formValueSelector, reduxForm, isDirty } from 'redux-form'
import CompilazioneAntiriciclaggio from './CompilazioneAntiriciclaggioComponent'
import { dominio } from '../../../store/modules/dominio/actions'
import { fetchSurveyAml, fetchSurveyAmlPg, saveAggAml, saveAggAmlPg } from '../../../store/modules/survey/actions'
import { validationCreateOtp } from '../../../store/modules/validation/actions'
import {
  getStatoPratica,
  tipoFirma,
  updateStatoPratica,
  getPratica,
  updatePratica,
  checkTipoFirmaIntestatari
} from '../../../store/modules/pratica/actions'
import { getFeqAbilitata, getPromoterCustomerHimself } from '../../../store/modules/customers/actions'
import { loadDefaultFields, notBelongToDominio } from '../../ConsulenzaBaseContainerLayout/utils'
import * as forms from '../../../store/modules/forms/constants'
import fields from '../../ConsulenzaBaseContainerLayout/fields'
const allFields = fields[7][forms.INFO_GENERALI]
const defaultFields = loadDefaultFields(allFields)
const selector = formValueSelector(forms.INFO_GENERALI)

const loadInitialFields = (pratica = {}) => {
  const { COMUNE_FIRMA } = allFields
  const result = {
    [COMUNE_FIRMA.id]: (pratica[COMUNE_FIRMA.id] || {}).chiave || COMUNE_FIRMA.defaultValue
  }
  return result
}

const validate = (values, props = {}) => {
  const { COMUNE_FIRMA } = allFields
  const { domini = {} } = props
  const errors = {}
  if (!!values[COMUNE_FIRMA.id] && notBelongToDominio(domini, COMUNE_FIRMA.dominio, values[COMUNE_FIRMA.id])) {
    errors[COMUNE_FIRMA.id] = 'Comune non riconosciuto'
  }
  return errors
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const bindendActionCreator = {
    fetchSurveyAml,
    fetchSurveyAmlPg,
    validationCreateOtp,
    saveAggAml,
    saveAggAmlPg,
    retrieveDominio: (...params) => dominio(...params),
    untouch,
    getStatoPratica,
    tipoFirma,
    updateStatoPratica,
    getPratica,
    updatePratica,
    checkTipoFirmaIntestatari,
    getFeqAbilitata,
    getPromoterCustomerHimself
  }
  return bindActionCreators(bindendActionCreator, dispatch)
}

const mapStateToProps = (state, ownProps) => {
  let commonstateToProps = {
    pratica: state.pratica.pratica,
    customer: state.customers.customer,
    domini: state.dominio.domini,
    dominioRequest: state.dominio.dominioRequest,
    templateAml: state.survey.templateAml,
    templateAmlPg: state.survey.templateAmlPg,
    amlPgRequest: state.survey.amlPgRequest,
    amlRequest: state.survey.amlRequest,
    amlPreviousForm: state.survey.amlPreviousForm,
    amlPgPreviousForm: state.survey.amlPgPreviousForm,
    updatePraticaRequest: state.pratica.updatePraticaRequest,
    praticaRequest: state.pratica.praticaRequest,
    statoPratica: state.pratica.statoPratica,
    flag: state.pratica.pratica.tipoFirma,
    flagDigitalSignatureEnabled: state.pratica.pratica.flagDigitalSignatureEnabled,
    feqAbilitata: state.customers.feqAbilitata,
    isPromoterCustomerHimself: state.customers.isPromoterCustomerHimself,
    initialValues: {
      ...defaultFields,
      ...loadInitialFields(state.pratica.pratica)
    },
    formValues: {
      ...defaultFields,
      ...loadInitialFields(state.pratica.pratica),
      // se ho una lista di campi con un solo valore la selector mi restituisce solo il singolo value e non un oggetto mappato
      [allFields.COMUNE_FIRMA.id]: selector(
        state,
        ...Object.keys(defaultFields).reduce((res, cur) => [...res, cur], [])
      )
    },
    isDirty: isDirty(forms.INFO_GENERALI)(state),
    isFetching: state.pratica.updatePraticaRequest.fetching,
    apiError: state.pratica.updatePraticaRequest.error
  }

  if (state.pratica && state.pratica.idPratica) {
    if (state.pratica.pratica.flagIntestatarioPG) {
      commonstateToProps = {
        ...commonstateToProps,
        amlPgSaveRequest: state.survey.amlPgSaveRequest
      }
    } else {
      commonstateToProps = {
        ...commonstateToProps,
        amlSaveRequest: state.survey.amlSaveRequest
      }
    }
  }
  return commonstateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: forms.INFO_GENERALI,
    validate,
    enableReinitialize: true
  })(CompilazioneAntiriciclaggio)
)
