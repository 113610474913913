import '../../../api/apiConfig'
import * as actionTypes from './constants'

export function saveFormFieldsActionCreator(formData) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SAVE_FIELDS_DEROGA,
      formData
    })
  }
}
