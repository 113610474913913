import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, getFormInitialValues, isDirty } from 'redux-form'
import { DOCUMENTI_GIURIDICHE } from '../../../../store/modules/forms/constants'
import {
  getDocumentIdPGActionCreator,
  postDocumentIdPersoneGActionCreator
} from '../../../../store/modules/personaGiuridica/client/actions'
import DocumentiGiuridiche from './DocumentiGiuridicheComponent'
import allFields from '../../fields'
import { bindActionCreators } from 'redux'
import { dominio } from '../../../../store/modules/dominio/actions'
import { notBelongToDominio } from '../../utils'

const fields = allFields[0][DOCUMENTI_GIURIDICHE]

// const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const {
    RAGIONE_SOCIALE_PG,
    NAZIONE_PG,
    PROVINCIA_PG,
    CITTA_PG,
    CAP_PG,
    NAZIONE_PG_COSTITUZIONE,
    PROVINCIA_PG_COSTITUZIONE,
    CITTA_PG_COSTITUZIONE
  } = fields

  if (!!values[NAZIONE_PG.id] && notBelongToDominio(domini, NAZIONE_PG.dominio, values[NAZIONE_PG.id])) {
    errors[NAZIONE_PG.id] = 'Nazione non riconosciuta'
  }

  if (!!values[PROVINCIA_PG.id] && notBelongToDominio(domini, PROVINCIA_PG.dominio, values[PROVINCIA_PG.id])) {
    errors[PROVINCIA_PG.id] = 'Provincia non riconosciuta'
  }

  if (!!values[CITTA_PG.id] && notBelongToDominio(domini, CITTA_PG.dominio, values[CITTA_PG.id])) {
    errors[CITTA_PG.id] = 'Città non riconosciuta'
  }

  if (!!values[CAP_PG.id] && notBelongToDominio(domini, CAP_PG.dominio, values[CAP_PG.id])) {
    errors[CAP_PG.id] = 'CAP non riconosciuto'
  }

  if (
    !!values[NAZIONE_PG_COSTITUZIONE.id] &&
    notBelongToDominio(domini, NAZIONE_PG_COSTITUZIONE.dominio, values[NAZIONE_PG_COSTITUZIONE.id])
  ) {
    errors[NAZIONE_PG_COSTITUZIONE.id] = 'Nazione non riconosciuta'
  }

  if (
    !!values[PROVINCIA_PG_COSTITUZIONE.id] &&
    notBelongToDominio(domini, PROVINCIA_PG_COSTITUZIONE.dominio, values[PROVINCIA_PG_COSTITUZIONE.id])
  ) {
    errors[PROVINCIA_PG_COSTITUZIONE.id] = 'Provincia non riconosciuta'
  }

  if (
    !!values[CITTA_PG_COSTITUZIONE.id] &&
    notBelongToDominio(domini, CITTA_PG_COSTITUZIONE.dominio, values[CITTA_PG_COSTITUZIONE.id])
  ) {
    errors[CITTA_PG_COSTITUZIONE.id] = 'Città non riconosciuta'
  }

  // if (
  //   !values[PARTITA_IVA_PG.id] &&
  //   (!values[CODICE_FISCALE_PG.id] || !new RegExp(CODICE_FISCALE_PG.validazione).test(values[CODICE_FISCALE_PG.id]))
  // ) {
  //   errors[CODICE_FISCALE_PG.id] = 'Codice fiscale non valido'
  // }
  // if (
  //   !values[CODICE_FISCALE_PG.id] &&
  //   (!values[PARTITA_IVA_PG.id] || !new RegExp(PARTITA_IVA_PG.validazione).test(values[PARTITA_IVA_PG.id]))
  // ) {
  //   errors[PARTITA_IVA_PG.id] = 'Partita iva non è valida'
  // }
  if (
    !values[RAGIONE_SOCIALE_PG.id] ||
    !new RegExp(RAGIONE_SOCIALE_PG.validazione).test(values[RAGIONE_SOCIALE_PG.id])
  ) {
    errors[RAGIONE_SOCIALE_PG.id] = 'Ragione sociale non valida'
  }

  // if (!values[STATUTO.id]) {
  //   errors[STATUTO.id] = 'Lo statuto è obbligatorio'
  // }
  // if (!values[VISURA_CAMERALE.id]) {
  //   errors[VISURA_CAMERALE.id] = 'La visura camerale è obbligatorio'
  // }
  // if (!values[ATTO_COSTITUTIVO.id]) {
  //   errors[ATTO_COSTITUTIVO.id] = "L'atto costitutivo è obbligatorio"
  // }

  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDocumentIdPGActionCreator,
      postDocumentIdPersoneGActionCreator,
      retrieveDominio: (...params) => dominio(...params)
    },
    dispatch
  )

const mapStateToProps = state => ({
  initialValues: state.anagraficaPersonaGiuridicaCliente.docIDPG,
  customer: state.customers.customer,
  praticaRequest: state.pratica.praticaRequest,
  docIDPG: state.anagraficaPersonaGiuridicaCliente.docIDPG,
  postDocPGIDPGRequest: state.anagraficaPersonaGiuridicaCliente.postDocPGIDPGRequest,
  isDirty: isDirty(DOCUMENTI_GIURIDICHE)(state),
  formValues: getFormValues(DOCUMENTI_GIURIDICHE)(state) || {},
  formErrors: getFormSyncErrors(DOCUMENTI_GIURIDICHE)(state),
  currentInitial: getFormInitialValues(DOCUMENTI_GIURIDICHE)(state),
  dominioRequest: state.dominio.dominioRequest,
  fields
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: DOCUMENTI_GIURIDICHE,
    validate,
    enableReinitialize: true,
    shouldError: () => isDirty(DOCUMENTI_GIURIDICHE)
  })(DocumentiGiuridiche)
)
