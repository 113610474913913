import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
// import { reduxForm, getFormValues, getFormSyncErrors, isDirty, getFormInitialValues } from 'redux-form'
// import { SCHEDA_DATI } from '../../../../store/modules/forms/constants'
import { setEnabledPep } from '../../../../store/modules/utility/actions'

// import { loadDefaultFields, loadDocumentsData, notBelongToDominio } from '../../utils'
import SchedaDatiPep from './SchedaDatiPepComponent'
// import allFields from '../../fields'

// const fields = allFields[0][SCHEDA_DATI]
// const parentFields = allFields[0][SCHEDA_DATI]

// const defaultFields = loadDefaultFields(fields)

// const validate = (values, props = {}) => {
//   const errors = {}
// const { domini = {} } = props
//   const {
//     // TIPO_DOCUMENTO,
//     NUMERO_DOCUMENTO,
//     // NAZIONE_RILASCIO,
//     // PROVINCIA_RILASCIO,
//     // COMUNE_RILASCIO,
//     // COMUNE_ESTERO_RILASCIO,
//     // DATA_RILASCIO,
//     // DATA_SCADENZA,
//     PRIVACY_DOC
//   } = fields

//   // if (
//   //   !!values[NAZIONE_RILASCIO.id] &&
//   //   notBelongToDominio(domini, NAZIONE_RILASCIO.dominio, values[NAZIONE_RILASCIO.id])
//   // ) {
//   //   errors[NAZIONE_RILASCIO.id] = 'Nazione non riconosciuta'
//   // }
//   // if (
//   //   !!values[PROVINCIA_RILASCIO.id] &&
//   //   notBelongToDominio(domini, PROVINCIA_RILASCIO.dominio, values[PROVINCIA_RILASCIO.id])
//   // ) {
//   //   errors[PROVINCIA_RILASCIO.id] = 'Provincia non riconosciuta'
//   // }
//   // if (
//   //   !!values[PROVINCIA_RILASCIO.id] &&
//   //   !!values[COMUNE_RILASCIO.id] &&
//   //   notBelongToDominio(domini, COMUNE_RILASCIO.dominio, values[COMUNE_RILASCIO.id])
//   // ) {
//   //   errors[COMUNE_RILASCIO.id] = 'Comune non riconosciuto'
//   // }

//   // if (!values[NAZIONE_RILASCIO.id]) {
//   //   errors[NAZIONE_RILASCIO.id] = 'Il campo è obbligatorio'
//   // }
//   // if (!values[PROVINCIA_RILASCIO.id]) {
//   //   errors[PROVINCIA_RILASCIO.id] = 'Il campo è obbligatorio'
//   // }
//   // if (!values[COMUNE_RILASCIO.id] && !values[COMUNE_ESTERO_RILASCIO.id]) {
//   //   errors[COMUNE_RILASCIO.id] = 'Il campo è obbligatorio'
//   // }
//   if (!values[NUMERO_DOCUMENTO.id]) {
//     errors[NUMERO_DOCUMENTO.id] = 'Il campo è obbligatorio'
//   }
//   // if (!values[DATA_RILASCIO.id]) {
//   //   errors[DATA_RILASCIO.id] = 'Il campo è obbligatorio'
//   // }
//   // if (!values[DATA_SCADENZA.id]) {
//   //   errors[DATA_SCADENZA.id] = 'Il campo è obbligatorio'
//   // }
//   // if (!values[TIPO_DOCUMENTO.id]) {
//   //   errors[TIPO_DOCUMENTO.id] = 'Il campo è obbligatorio'
//   // }

//   return errors
// }

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setEnabledPep
    },
    dispatch
  )

const mapStateToProps = state => {
  return {
    // initialValues: {
    //   ...defaultFields,
    //   ...loadDocumentsData(fields, state.customers.documentsData),
    //   [fields.HA_PROCURATORE.id]: state.customers.summaryData.idProcurator !== null
    // },
    // formValues: getFormValues(SCHEDA_DATI)(state),
    titolare: state.utility.titolare,
    titolareMulti: state.utility.titolareMulti,
    // formErrors: getFormSyncErrors(SCHEDA_DATI)(state),
    dataNascitaCF: state.customers.dataRegistry.dataNascita || state.customers.customer.dataNascita,
    // currentInitial: getFormInitialValues(SCHEDA_DATI)(state),
    // fields,
    // isDirty: isDirty(SCHEDA_DATI)(state),
    domini: state.dominio.domini,
    isFetching: state.customers.postDataAllegatiDOCRequest.fetching,
    apiError: state.customers.postDataAllegatiDOCRequest.error
    // flagCustomerIsBlacklist:
    //   state.customers.documentsData.flagCustomerIsBlacklist === false ||
    //   state.customers.documentsData.flagCustomerIsBlacklist === true
    //     ? state.customers.documentsData.flagCustomerIsBlacklist
    //     : state.customers.flagCustomerIsBlacklist
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SchedaDatiPep)
