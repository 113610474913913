import { connect } from 'react-redux'
import { reduxForm, getFormValues, isDirty, getFormSyncErrors } from 'redux-form'
import { RESIDENZA } from '../../../../store/modules/forms/constants'
import Residenza from './ResidenzaComponent'
import { loadDataRegistry, loadDefaultFields, notBelongToDominio } from '../../utils'
import allFields from '../../fields'

const fields = allFields[1][RESIDENZA]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const { NAZIONE, PROVINCIA, COMUNE, CAP } = fields

  if (!!values[NAZIONE.id] && notBelongToDominio(domini, NAZIONE.dominio, values[NAZIONE.id])) {
    errors[NAZIONE.id] = 'Nessun risultato trovato'
  }
  if (!!values[PROVINCIA.id] && notBelongToDominio(domini, PROVINCIA.dominio, values[PROVINCIA.id])) {
    errors[PROVINCIA.id] = 'Nessun risultato trovato'
  }
  if (!!values[PROVINCIA.id] && !!values[COMUNE.id] && notBelongToDominio(domini, COMUNE.dominio, values[COMUNE.id])) {
    errors[COMUNE.id] = 'Nessun risultato trovato'
  }
  if (!!values[CAP.id] && notBelongToDominio(domini, CAP.dominio, values[CAP.id])) {
    errors[CAP.id] = 'Nessun risultato trovato'
  }

  return errors
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadDataRegistry(state.customers.dataRegistry, fields)
  },
  formValues: getFormValues(RESIDENZA)(state),
  formErrors: getFormSyncErrors(RESIDENZA)(state),
  fields,
  isDirty: isDirty(RESIDENZA)(state),
  isFetching: state.customers.postDataRegistryRequest.fetching,
  apiError: state.customers.postDataRegistryRequest.error,
  dominioRequest: state.dominio.dominioRequest
}))(
  reduxForm({
    form: RESIDENZA,
    validate,
    shouldError: () => isDirty(RESIDENZA)
  })(Residenza)
)
