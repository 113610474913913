import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import { getInitialValues, loadDefaultFields } from '../../utils'
import { INFORMAZIONI_PERSONALI_GIURIDICHE } from '../../../../store/modules/forms/constants'
import InformazioniPersonaliGiuridiche from './InformazioniPersonaliGiuridicheComponent'
import allFields from '../../fields'

const fields = allFields[0][INFORMAZIONI_PERSONALI_GIURIDICHE]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  // const errors = {}
  // const { domini = {} } = props
  // const { NAZIONE_INSEDIAMENTO, PROVINCIA_ATTIVITA } = fields
  // if (
  //   !!values[NAZIONE_INSEDIAMENTO.id] &&
  //   notBelongToDominio(domini, NAZIONE_INSEDIAMENTO.dominio, values[NAZIONE_INSEDIAMENTO.id])
  // ) {
  //   errors[NAZIONE_INSEDIAMENTO.id] = 'Nessun risultato trovato'
  // }
  // if (
  //   !!values[PROVINCIA_ATTIVITA.id] &&
  //   notBelongToDominio(domini, PROVINCIA_ATTIVITA.dominio, values[PROVINCIA_ATTIVITA.id])
  // ) {
  //   errors[PROVINCIA_ATTIVITA.id] = 'Nessun risultato trovato'
  // }
  // return errors
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...getInitialValues(fields, state.anagraficaPersonaGiuridicaCliente.docIDPG)
  },
  formValues: getFormValues(INFORMAZIONI_PERSONALI_GIURIDICHE)(state),
  formErrors: getFormSyncErrors(INFORMAZIONI_PERSONALI_GIURIDICHE)(state),
  dataGathering: state.prospectPersonaGiuridica.dataGathering,
  fields,
  // dataNascitaCF: state.customers.customer.dataNascita || state.customers.dataRegistry.dataNascita,
  isDirty: isDirty(INFORMAZIONI_PERSONALI_GIURIDICHE)(state)
  // isFetching: state.survey.amlPgSaveRequest.fetching,
  // apiError: state.survey.amlPgSaveRequest.error,
  // apiSuccess: state.survey.amlPgSent
}))(
  reduxForm({
    form: INFORMAZIONI_PERSONALI_GIURIDICHE,
    validate,
    enableReinitialize: true,
    shouldError: () => isDirty(INFORMAZIONI_PERSONALI_GIURIDICHE)
  })(InformazioniPersonaliGiuridiche)
)
