import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ModalDerogaCommissionale from '../../../components/Modal/DerogaCommissionale'
import Fab from '../../../components/Button/Fab'
import { FORM_ADD_DEROGA } from '../../../store/modules/forms/constants'
import derogaFields from '../../../config/fields'
import DerogaItem from '../../../components/DerogaItem'
import { getValueDominioFromKey } from '../../../services/utils'
import { Col, Row } from 'react-materialize'

const fields = derogaFields[0][FORM_ADD_DEROGA]

class DerogaCommissionaleComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showList: false,
      listaDeroghe: []
    }
    this.modalHandler = this.modalHandler.bind(this)
    this.enableSubmit = this.enableSubmit.bind(this)
    this.submitHandler = this.submitHandler.bind(this)
    this.inviaDeroga = this.inviaDeroga.bind(this)
  }

  componentDidMount() {
    const { TIPOLOGIA_SERVIZIO, FASCIA_INVESTIMENTO, FASCIA_DEROGA, CLUSTER, TIPOLOGIA_STRUM_FINANZIARIO } = fields
    const { match, getDeroghe, dominioActionCreator } = this.props
    dominioActionCreator({
      listaDomini: [
        {
          idDominio: TIPOLOGIA_SERVIZIO.dominio
        },
        {
          idDominio: FASCIA_INVESTIMENTO.dominio
        },
        {
          idDominio: FASCIA_DEROGA.dominio
        },
        {
          idDominio: CLUSTER.dominio
        },
        {
          idDominio: TIPOLOGIA_STRUM_FINANZIARIO.dominio
        }
      ]
    })
    getDeroghe(match.params.idCustomer)
  }

  modalHandler() {
    const { form } = this.props
    if (this.state.showModal && form && form.FORM_ADD_DEROGA) {
      this.props.saveFormFieldsActionCreator(form.FORM_ADD_DEROGA.values)
    }
    this.setState({ showModal: !this.state.showModal })
  }

  enableSubmit() {
    const { formErrors } = this.props
    return Object.keys(formErrors).length === 0
  }

  submitHandler() {
    const { TIPOLOGIA_SERVIZIO, FASCIA_INVESTIMENTO, FASCIA_DEROGA, CLUSTER, TIPOLOGIA_STRUM_FINANZIARIO } = fields

    const { formValues } = this.props
    //
    let lista = this.state.listaDeroghe
    let obj

    if (formValues[TIPOLOGIA_SERVIZIO.id] === '1') {
      obj = {
        label_1: TIPOLOGIA_SERVIZIO.label,
        label_2: FASCIA_INVESTIMENTO.label,
        label_3: FASCIA_DEROGA.label,
        text_1: getValueDominioFromKey(
          this.props.domini.listaDomini,
          TIPOLOGIA_SERVIZIO.dominio,
          formValues[TIPOLOGIA_SERVIZIO.id]
        ),
        text_2: getValueDominioFromKey(
          this.props.domini.listaDomini,
          FASCIA_INVESTIMENTO.dominio,
          formValues[FASCIA_INVESTIMENTO.id]
        ),
        text_3: getValueDominioFromKey(
          this.props.domini.listaDomini,
          FASCIA_DEROGA.dominio,
          formValues[FASCIA_DEROGA.id]
        ),
        value_1: formValues[TIPOLOGIA_SERVIZIO.id],
        value_2: formValues[FASCIA_INVESTIMENTO.id],
        value_3: formValues[FASCIA_DEROGA.id]
      }
    } else {
      obj = {
        label_1: TIPOLOGIA_SERVIZIO.label,
        label_2: CLUSTER.label,
        label_3: TIPOLOGIA_STRUM_FINANZIARIO.label,
        text_1: getValueDominioFromKey(
          this.props.domini.listaDomini,
          TIPOLOGIA_SERVIZIO.dominio,
          formValues[TIPOLOGIA_SERVIZIO.id]
        ),
        text_2: getValueDominioFromKey(this.props.domini.listaDomini, CLUSTER.dominio, formValues[CLUSTER.id]),
        text_3: getValueDominioFromKey(
          this.props.domini.listaDomini,
          TIPOLOGIA_STRUM_FINANZIARIO.dominio,
          formValues[TIPOLOGIA_STRUM_FINANZIARIO.id]
        ),
        value_1: formValues[TIPOLOGIA_SERVIZIO.id],
        value_2: formValues[CLUSTER.id],
        value_3: formValues[TIPOLOGIA_STRUM_FINANZIARIO.id]
      }
    }
    lista.push(obj)
    this.setState({ listaDeroghe: lista })
    this.setState({ showList: true })

    // postDeroga(body)
    // getDeroghe(match.params.idCustomer)
  }
  inviaDeroga(context) {
    console.log('deroga', context)
    // this.setState({ showList:true })

    let lista = this.state.listaDeroghe
    lista[context].invio = true
    this.setState({ listaDeroghe: lista })

    this.props.postDeroga({
      deroga: {
        idCustomer: this.props.match.params.idCustomer,
        idPrimoIntestatario: this.props.intestatari[0].idCliente,
        idSecondoIntestatario: this.props.intestatari.lenght > 1 ? this.props.intestatari[1].idCliente : null,
        tipologiaServizioCode: this.state.listaDeroghe[context].value_1,
        fasciaInvestimentoCode:
          this.state.listaDeroghe[context].value_1 === '1' ? this.state.listaDeroghe[context].value_2 : null,
        fasciaDerogaCode:
          this.state.listaDeroghe[context].value_1 === '1' ? this.state.listaDeroghe[context].value_3 : null,
        clusterCode: this.state.listaDeroghe[context].value_1 === '4' ? this.state.listaDeroghe[context].value_2 : null,
        tipoStrumFinanziarioCode:
          this.state.listaDeroghe[context].value_1 === '4' ? this.state.listaDeroghe[context].value_3 : null,
        dataInvioRichiesta: '2019-07-31T09:50:52.064Z',
        dataApprovazione: '2019-07-31T09:50:52.064Z',
        statoRichiesta: 'IN_ATTESA'
      }
    })
  }

  render() {
    // const { domini, listaDeroghe } = this.props
    // const tableRow = listaDeroghe
    // const tableData = {
    //   tableHeader: [
    //     'Tipologia Servizio',
    //     'Fascia di Investimento',
    //     'Deroga',
    //     'Data Invio Richiesta',
    //     'Data Approvazione',
    //     'Approvata da',
    //     'Stato'
    //   ],
    //   tableRow
    // }
    let _this = this
    return (
      <Fragment>
        {this.state.showList &&
          this.state.listaDeroghe.map(function(itemObj, index) {
            return <DerogaItem context={index} {...itemObj} callBack={_this.inviaDeroga} />
          })}

        {this.state.showModal && (
          <ModalDerogaCommissionale
            title="Richiesta deroga commissionale"
            isValid={this.enableSubmit()}
            submitHandler={this.submitHandler}
            close={this.modalHandler}
            isModal
          />
        )}
        <Row className=" ">
          <Col s={12} className="center-align">
            <Fab modalHandler={() => this.modalHandler()} />
          </Col>
        </Row>
      </Fragment>
    )
  }
}

DerogaCommissionaleComponent.propTypes = {
  match: PropTypes.object.isRequired,
  getDeroghe: PropTypes.func,
  postDeroga: PropTypes.func,
  saveFormFieldsActionCreator: PropTypes.func,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  dominioActionCreator: PropTypes.func,
  form: PropTypes.object,
  intestatari: PropTypes.array,
  domini: PropTypes.object
}

export default DerogaCommissionaleComponent
