export const PRATICA_GET_START = 'PRATICA_GET_START'
export const PRATICA_GET_SUCCESS = 'PRATICA_GET_SUCCESS'
export const PRATICA_GET_ERROR = 'PRATICA_GET_ERROR'

export const PRATICA_PG_GET_START = 'PRATICA_PG_GET_START'
export const PRATICA_PG_GET_SUCCESS = 'PRATICA_PG_GET_SUCCESS'
export const PRATICA_PG_GET_ERROR = 'PRATICA_PG_GET_ERROR'

export const PRATICA_SAVE_START = 'PRATICA_SAVE_START'
export const PRATICA_SAVE_SUCCESS = 'PRATICA_SAVE_SUCCESS'
export const PRATICA_SAVE_ERROR = 'PRATICA_SAVE_ERROR'

export const UPDATE_TIPOLOGIA_CLIENTE_START = 'UPDATE_TIPOLOGIA_CLIENTE_START'
export const UPDATE_TIPOLOGIA_CLIENTE_SUCCESS = 'UPDATE_TIPOLOGIA_CLIENTE_SUCCESS'
export const UPDATE_TIPOLOGIA_CLIENTE_ERROR = 'UPDATE_TIPOLOGIA_CLIENTE_ERROR'

export const UPDATE_STATO_START = 'UPDATE_STATO_START'
export const UPDATE_STATO_SUCCESS = 'UPDATE_STATO_SUCCESS'
export const UPDATE_STATO_ERROR = 'UPDATE_STATO_ERROR'

export const UPDATE_TIPO_FIRMA = 'UPDATE_TIPO_FIRMA'

export const PRATICA_UPDATE_START = 'PRATICA_UPDATE_START'
export const PRATICA_UPDATE_SUCCESS = 'PRATICA_UPDATE_SUCCESS'
export const PRATICA_UPDATE_ERROR = 'PRATICA_UPDATE_ERROR'

export const PRATICA_PG_UPDATE_START = 'PRATICA_PG_UPDATE_START'
export const PRATICA_PG_UPDATE_SUCCESS = 'PRATICA_PG_UPDATE_SUCCESS'
export const PRATICA_PG_UPDATE_ERROR = 'PRATICA_PG_UPDATE_ERROR'

export const PRATICA_DELETE_START = 'PRATICA_DELETE_START'
export const PRATICA_DELETE_SUCCESS = 'PRATICA_DELETE_SUCCESS'
export const PRATICA_DELETE_ERROR = 'PRATICA_DELETE_ERROR'

export const SET_ID_PRATICA = 'SET_ID_PRATICA'

export const RESET_PRATICA = 'RESET_PRATICA'

export const PRATICA_INTESTATARI_GET_START = 'PRATICA_INTESTATARI_GET_START'
export const PRATICA_INTESTATARI_GET_SUCCESS = 'PRATICA_INTESTATARI_GET_SUCCESS'
export const PRATICA_INTESTATARI_GET_ERROR = 'PRATICA_INTESTATARI_GET_ERROR'

export const PRATICA_UPDATE_STATO_START = 'PRATICA_UPDATE_STATO_START'
export const PRATICA_UPDATE_STATO_SUCCESS = 'PRATICA_UPDATE_STATO_SUCCESS'
export const PRATICA_UPDATE_STATO_ERROR = 'PRATICA_UPDATE_STATO_ERROR'

export const PRATICA_CHECK_FIRMA_INTESTATARI_START = 'PRATICA_CHECK_FIRMA_INTESTATARI_START'
export const PRATICA_CHECK_FIRMA_INTESTATARI_SUCCESS = 'PRATICA_CHECK_FIRMA_INTESTATARI_SUCCESS'
export const PRATICA_CHECK_FIRMA_INTESTATARI_ERROR = 'PRATICA_CHECK_FIRMA_INTESTATARI_ERROR'

export const SET_LEGAL_PERSON = 'SET_LEGAL_PERSON'

export const GET_STATO_PRATICA_START = 'GET_STATO_PRATICA_START'
export const GET_STATO_PRATICA_SUCCESS = 'GET_STATO_PRATICA_SUCCESS'
export const GET_STATO_PRATICA_ERROR = 'GET_STATO_PRATICA_ERROR'

export const PRATICA_UPDATE_DRAFT_START = 'PRATICA_UPDATE_DRAFT_START'
export const PRATICA_UPDATE_DRAFT_SUCCESS = 'PRATICA_UPDATE_DRAFT_SUCCESS'
export const PRATICA_UPDATE_DRAFT_ERROR = 'PRATICA_UPDATE_DRAFT_ERROR'
