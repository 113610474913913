import React, { Component } from 'react'
import { scaleLinear } from 'd3-scale'
import { select } from 'd3-selection'
import { Legend } from './Legend'
import PropTypes from 'prop-types'

// import dataJSON from '../../Charts/Bar/data.json'
class BarChart extends Component {
  constructor(props) {
    super(props)
    this.createBarChart = this.createBarChart.bind(this)
  }

  componentDidMount() {
    this.createBarChart()
  }

  componentDidUpdate() {
    this.createBarChart()
  }

  createBarChart() {
    const node = this.node
    const { data } = this.props
    if (data.length > 0) {
      const dataMax = data.reduce(function(prev, current) {
        return prev.percentage > current.percentage ? prev : current
      })
      const yScale = scaleLinear()
        .domain([0, dataMax.percentage])
        .range([0, 200])

      select(node)
        .selectAll('rect')
        .data(this.props.data)
        .enter()
        .append('rect')

      select(node)
        .selectAll('rect')
        .data(this.props.data)
        .exit()
        .remove()

      select(node)
        .selectAll('rect')
        .data(this.props.data)
        .attr('fill', d => d.color)
        .attr('x', 30)
        .attr('y', (d, i) => 30 + i * 60)
        .attr('height', 45)
        .attr('width', d => yScale(d.percentage))
    }
  }

  render() {
    return (
      <svg ref={node => (this.node = node)} width={500} height={400}>
        {/* Legenda */}
        <foreignObject className="node" x="250" y="70" width="500" height="400">
          <Legend list={this.props.data} />
        </foreignObject>
      </svg>
    )
  }
}

export default BarChart

BarChart.propTypes = {
  data: PropTypes.object
}
