import { connect } from 'react-redux'
import UserCard from './UserCardComponent'
// import { servicesActions } from '../../store/modules/services'

const mapDispatchToProps = dispatch => {
  return {}
}

const mapStateToProps = state => {
  return {
    listaClienti: (state.utility.listaClienti || []).sort((a, b) => a.idRuolo > b.idRuolo)
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCard)
