import React from 'react'
import PropTypes from 'prop-types'

const PdfFile = ({ documentoPdf: { nomeFile } }) => {
  return (
    <li>
      <i className="icon-document-pdf" />
      <span className="description-pdfFile">{nomeFile}</span>
    </li>
  )
}

PdfFile.propTypes = {
  documentoPdf: PropTypes.any
}

export default PdfFile
