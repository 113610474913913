import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-materialize'
import PropTypes from 'prop-types'

const Header = props => {
  const { title, tabs, activeTabIndex } = props
  return (
    <header className="Header">
      <h1 className="title-macrosezione">{title}</h1>
      {/* TABS */}
      <div className="persone-tabs">
        <Row>
          {tabs.map((tab, index) => (
            <Col s={4} key={`tab_${index}`} className={`persone-tab${index === activeTabIndex ? ' active' : ''}`}>
              <Link to={`${tab.route}`} style={{ color: 'inherit' }}>
                {tab.label}
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </header>
  )
}
export default Header

Header.propTypes = {
  title: PropTypes.string,
  activeTabIndex: PropTypes.number,
  tabs: PropTypes.array
}
