import React from 'react'

const completato = 'COMPLETATA'

const AccordionHeader = (dirty, submitted, status) => {
  if (dirty) {
    return <span className="accordion-info accordion-pending">Dati non salvati</span>
  }

  if (submitted && status !== completato) {
    return <span className="accordion-info accordion-saved">Dati salvati</span>
  }

  if (status === completato) {
    return <span className="accordion-info accordion-completed">COMPLETATO</span>
  }

  return null
}
export default AccordionHeader
