import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import { Col, Row } from 'react-materialize'
import { loadQuestions, reduceQuestions } from '../../utils'
import AccordionHeader from '../../../../components/AccordionHeader'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { CONSENSI_PRIVACY } from '../../../../store/modules/forms/constants'
import * as dataSB from '../../../../static-data/data-servizio-base.json'
import { renderHtmlInput } from '../../../../components/Forms/renderFields'
import Title from '../../../../components/Title'
import ButtonSubmit from '../../../../components/Buttons/Submit'

class ConsensiPrivacy extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      ...loadQuestions(this.props.previousForm, this.props.template.listaDomande)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.apiSuccess && this.props.apiSuccess && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(CONSENSI_PRIVACY)
      this.setState({ saved: true })
    }
  }

  render() {
    const { template, handleSubmit, onSave, submitting, isDirty, invalid, status, fields, isFetching } = this.props
    const { listaDomande } = template
    const attivitaMarketing = fields.ATTIVITA_MARKETING
    const attivitaAltro = fields.ATTIVITA_ALTRO
    const submit = values => {
      if (onSave) {
        this.setState({ saved: false })
        onSave(reduceQuestions(fields, values))
      }
    }

    return (
      <CollapsibleItem
        header={dataSB.censimento_anagrafica.consensi_privacy.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={CONSENSI_PRIVACY}
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(submit)} noValidate>
          <Row>
            <Col s={12}>
              <Title>{listaDomande.find(c => c.idDomanda === attivitaMarketing.templateId).testoDomanda}</Title>
              <div className="radio-horizontal">
                {listaDomande
                  .find(d => d.idDomanda === attivitaMarketing.templateId)
                  .listaRisposte.map((risposta, index) => (
                    <p key={`attivita-marketing-${index}`}>
                      <Field
                        name={attivitaMarketing.id}
                        className="with-gap"
                        type="radio"
                        id={`attivita-marketing-i-${index}`}
                        value={(attivitaMarketing.value = '' + risposta.idRisposta)}
                        component={renderHtmlInput}
                      />
                      <label htmlFor={`attivita-marketing-i-${index}`}>{risposta.descRisposta}</label>
                    </p>
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <Title>{listaDomande.find(c => c.idDomanda === attivitaAltro.templateId).testoDomanda}</Title>
              <div className="radio-horizontal">
                {listaDomande
                  .find(d => d.idDomanda === attivitaAltro.templateId)
                  .listaRisposte.map((risposta, index) => (
                    <p key={`attivita-altro-${index}`}>
                      <Field
                        name={attivitaAltro.id}
                        className="with-gap"
                        type="radio"
                        id={`attivita-altro-i-${index}`}
                        value={(attivitaAltro.value = '' + risposta.idRisposta)}
                        component={renderHtmlInput}
                      />
                      <label htmlFor={`attivita-altro-i-${index}`}>{risposta.descRisposta}</label>
                    </p>
                  ))}
              </div>
            </Col>
          </Row>
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching} />
        </form>
      </CollapsibleItem>
    )
  }
}

ConsensiPrivacy.propTypes = {
  onSave: PropTypes.func,
  template: PropTypes.object,
  previousForm: PropTypes.object,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiSuccess: PropTypes.bool,
  resetForm: PropTypes.func,
  initialize: PropTypes.func,
  formValues: PropTypes.object,
  fields: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any
}

export default ConsensiPrivacy
