import React from 'react'
import { CardPanel, Col, Row, Button } from 'react-materialize'
import PropTypes from 'prop-types'
import './_derogaItem.scss'

const DerogaItem = ({ label_1, label_2, label_3, text_1, text_2, text_3, context, callBack, invio = false }) => (
  <div className="itemParent">
    <Col s={10} className="centraItem">
      <CardPanel>
        <Row className=" ">
          <Col s={10}>
            <Col s={4}>
              <div className=" label">{label_1}</div>
              <div className=" text">{text_1}</div>
            </Col>
            <Col s={4}>
              <div className=" label">{label_2}</div>
              <div className=" text">{text_2}</div>
            </Col>
            <Col s={4}>
              <div className=" label">{label_3}</div>
              <div className=" text">{text_3}</div>
            </Col>
          </Col>

          <Col s={2}>
            <Button
              disabled={invio}
              onClick={() => {
                callBack(context)
              }}>
              {!invio ? ' invia' : 'in attesa'}
            </Button>
          </Col>
        </Row>
      </CardPanel>
    </Col>
  </div>
)
export default DerogaItem

DerogaItem.propTypes = {
  label_1: PropTypes.string,
  label_2: PropTypes.string,
  label_3: PropTypes.string,
  text_1: PropTypes.string,
  text_2: PropTypes.string,
  text_3: PropTypes.string,
  context: PropTypes.string,
  callBack: PropTypes.string,
  invio: PropTypes.bool
}
