import React, { Component, Fragment } from 'react'
import { Row, Col, Icon } from 'react-materialize'
import { PropTypes } from 'prop-types'
import $ from 'jquery'
import { Collapsible } from '../../../../components/Collapsible'
import InformazioniGenerali from './InformazioniGenerali'
import InformazioniGeneraliGiuridiche from './InformazioniGeneraliGiuridiche'
import RtoUbs from './RtoUbs'
import RtoBpm from './RtoBpm'
import Max from './Max'
import MaxFund from './MaxFund'
import RtoImi from './RtoImi'
import { Forms, FormsGiuridiche, createDefaultPayload, getFormFromIdServizio } from '../utils'
import fields from '../../fields'
import FirmaFooter from '../../../../components/FirmaFooter'
// import { AsideServizi } from '../../../../components/AsideServizi'
import ModalGeneric from '../../../../components/ModalGeneric'
import staticData from '../../../../static-data/data-main.json'
import ModalFirma from '../../../../components/Modals/Firma'
import ContoDepositoLiquidita from './ContoDepositoLiquidita'
import PIR from './PIR'
import MaxBnp from './MaxBnp'
import MaxFundBnp from './MaxFundBnp'
import GoalBasedPlan from './GoalBasedPlan'

export class ConfigurazioneServiziView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      serviceData: null,
      openCollapsible: '',
      loadingComplete: false,
      firmaOra: null,
      saved: [],
      typeFirma: '',
      showModal: false,
      showModalFirma: false,
      later: false,
      callTipofirma: true,
      tipoFirma: false,
      updateStato: true
    }

    this.handleOpenCollapsibleChange = this.handleOpenCollapsibleChange.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
    this.updateDomini = this.updateDomini.bind(this)
    this.salva = this.salva.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.loadDominiFromServizi = this.loadDominiFromServizi.bind(this)
    this.getDominiFromCorrispondenza = this.getDominiFromCorrispondenza.bind(this)
    this.isFirmaDisabled = this.isFirmaDisabled.bind(this)
    this.isDisabledServizio0 = this.isDisabledServizio0.bind(this)
    this.isDisabledServizio1 = this.isDisabledServizio1.bind(this)
    this.isDisabledServizio2 = this.isDisabledServizio2.bind(this)
    this.isDisabledServizio3 = this.isDisabledServizio3.bind(this)
    this.isDisabledServizio4 = this.isDisabledServizio4.bind(this)
    this.isDisabledServizio7 = this.isDisabledServizio7.bind(this)
    this.isDisabledServizio9 = this.isDisabledServizio9.bind(this)
    this.isDisabledServizio10 = this.isDisabledServizio10.bind(this)
    this.isDisabledServizio12 = this.isDisabledServizio12.bind(this)
    this.isDisabledServizio13 = this.isDisabledServizio13.bind(this)
    this.isDisabledServizio14 = this.isDisabledServizio14.bind(this)
    this.getStatus = this.getStatus.bind(this)
    this.getOpenCollapsible = this.getOpenCollapsible.bind(this)
    this.modalHandler = this.modalHandler.bind(this)
    this.saveFromModalFirma = this.saveFromModalFirma.bind(this)
    this.openModalFirma = this.openModalFirma.bind(this)
    this.isDigitalSignDisabled = this.isDigitalSignDisabled.bind(this)
  }

  componentDidMount() {
    const {
      getPratica,
      idPratica,
      resetConsulenza,
      match: { params }
    } = this.props
    let id = idPratica || params.idPratica
    getPratica(id)
    resetConsulenza()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.praticaRequest.fetching && !this.props.praticaRequest.fetching) {
      const {
        pratica: { intestatari },
        getDataRegistry,
        getIdCardData,
        getDataRegistryExt
      } = this.props

      if (!this.props.pratica.flagIntestatarioPG) {
        if (intestatari[0].codiceClienteEsterno) {
          getDataRegistryExt(intestatari[0].codiceClienteEsterno)
        } else {
          getIdCardData(intestatari[0].id, intestatari[0].idIncarico)
          getDataRegistry(intestatari[0].id, intestatari[0].idIncarico)
        }
      } else {
        const { getPraticaPG, idPratica } = this.props
        getPraticaPG(idPratica)
      }
    }

    if (
      (prevProps.getDocIDPGRequest.fetching &&
        !this.props.getDocIDPGRequest.fetching &&
        !this.props.getDocIDPGRequest.error) ||
      (prevProps.getDataRegistryPGExtRequest.fetching &&
        !this.props.getDataRegistryPGExtRequest.fetching &&
        !this.props.getDataRegistryPGExtRequest.error)
    ) {
      if (this.props.anagraficaPG.province) {
        this.props.dominio({
          listaDomini: [
            {
              idDominio: 'DOM_COMUNE_SEDE_LEGALE_PG',
              filtro: {
                codice: this.props.anagraficaPG.province
              }
            }
          ]
        })
      }
    }
    if (prevProps.getPraticaPGRequest.fetching && !this.props.getPraticaPGRequest.fetching) {
      const {
        getServizioInfo,
        pratica: { intestatari },
        getDocumentIdPGActionCreator,
        getDataRegistryPGExt,
        pratica: { servizi }
      } = this.props
      getServizioInfo(servizi)
      if (intestatari[0].codiceClienteEsterno) {
        getDataRegistryPGExt(intestatari[0].codiceClienteEsterno)
      } else {
        getDocumentIdPGActionCreator(intestatari[0].id)
      }
    }

    if (
      (prevProps.getDataRegistryRequest.fetching && !this.props.getDataRegistryRequest.fetching) ||
      (prevProps.getDataRegistryExtRequest.fetching && !this.props.getDataRegistryExtRequest.fetching)
    ) {
      const {
        getServizioInfo,
        pratica: { servizi }
      } = this.props
      getServizioInfo(servizi)
    }

    if (prevProps.getServiceInfoRequest.fetching && !this.props.getServiceInfoRequest.fetching) {
      const { pratica = {} } = this.props
      let FormTemp
      if (!this.props.pratica.flagIntestatarioPG) {
        FormTemp = Forms
      } else {
        FormTemp = FormsGiuridiche
      }
      if (pratica.corrispondenza && pratica[fields[FormTemp[0]].FLAG_INDIRIZZO.id]) {
        this.props.dominio({
          listaDomini: [...this.loadDominiFromServizi(), ...this.getDominiFromCorrispondenza(fields[FormTemp[0]])]
        })
      } else {
        this.props.dominio({
          listaDomini: [...this.loadDominiFromServizi()]
        })
      }
    }

    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && !this.state.loadingComplete) {
      this.setState({ loadingComplete: true, openCollapsible: this.getOpenCollapsible() })
    }

    if (
      prevProps.postServiziRequest.fetching &&
      !this.props.postServiziRequest.fetching &&
      !this.props.postServiziRequest.error &&
      this.state.updateStato
    ) {
      let flagFirmaCartacea
      if (this.state.typeFirma === 'firma-digitale') {
        flagFirmaCartacea = false
      } else {
        flagFirmaCartacea = true
      }
      this.props.updateStatoPratica(this.props.pratica.idPratica, 2, flagFirmaCartacea)
      this.setState({ updateStato: true })
    }

    if (
      prevProps.updateStatoPraticaRequest.fetching &&
      !this.props.updateStatoPraticaRequest.fetching &&
      !this.props.updateStatoPraticaRequest.error
    ) {
      if (this.state.firmaOra) {
        this.props.getPraticaIntestatari(this.props.pratica.idPratica)

        if (this.state.typeFirma !== 'firma-digitale') {
          this.props.history.push(`/firma-cartacea/${this.props.idPratica}`)
        } else {
          this.props.history.push(`/firma-digitale/${this.props.idPratica}`)
        }
      } else if (!this.state.firmaOra && this.state.firmaOra !== null) {
        this.props.history.push('/')
      }
    }

    if (
      prevProps.praticaIntestatariRequest.fetching &&
      !this.props.praticaIntestatariRequest.fetching &&
      !this.props.praticaIntestatariRequest.error
    ) {
      if (this.props.flagFirmaPraticaInvestimento) {
        this.props.setIdPromotore()
      } else {
        this.setState({ showModalFirma: true })
      }
    }

    if (prevState.openCollapsible !== this.state.openCollapsible && this.state.openCollapsible && $('.open')) {
      setTimeout(
        () =>
          $('.container-home-left').scrollTop(
            $('.container-home-left').scrollTop() + ($('.open').offset() || {}).top - 50
          ),
        200
      )
    }
  }
  saveFromModalFirma(value) {
    let flagFirmaCartacea
    if (this.state.typeFirma === 'firma-digitale') {
      flagFirmaCartacea = false
    } else {
      flagFirmaCartacea = true
    }

    this.props.updateStatoPratica(this.props.pratica.idPratica, 2, flagFirmaCartacea)
    this.setState({ firmaOra: value })
  }

  getOpenCollapsible(form = '') {
    const {
      pratica: { servizi = [] }
    } = this.props
    let FormTemp
    if (!this.props.pratica.flagIntestatarioPG) {
      FormTemp = Forms
    } else {
      FormTemp = FormsGiuridiche
    }

    const forms = [
      {
        current: FormTemp[0],
        idTipoServizio: 0
      },
      ...servizi.map(({ idTipoServizio }) => ({ current: getFormFromIdServizio(idTipoServizio), idTipoServizio }))
    ]
    let currentIndex = forms.findIndex(({ current }) => current === form)
    if (currentIndex === -1) {
      currentIndex = 0
    }
    let next = forms.slice(currentIndex).find(({ idTipoServizio }) => {
      return (
        this[`isDisabledServizio${idTipoServizio}`]() ||
        (servizi || []).some(s => s.idTipoServizio === idTipoServizio && !s.flagDatiServizioSalvati)
      )
    })
    if (!next) {
      next = forms.slice(0, currentIndex - 1).find(({ idTipoServizio }) => {
        return (
          this[`isDisabledServizio${idTipoServizio}`]() ||
          (servizi || []).some(s => s.idTipoServizio === idTipoServizio && !s.flagDatiServizioSalvati)
        )
      })
    }
    return next ? next.current : ''
  }

  loadDominiFromServizi() {
    return Object.keys(fields)
      .reduce((res, cur) => {
        return Object.keys(fields[cur]).reduce((r, c) => {
          const field = fields[cur][c]
          if (
            field.dominio &&
            !field.dominioDep &&
            !field.skip &&
            !field.isPercComm &&
            !r.find(e => e === field.dominio)
          ) {
            return [...r, field.dominio]
          }
          return r
        }, res)
      }, [])
      .map(idDominio => ({ idDominio }))
  }

  getDominiFromCorrispondenza(fields) {
    const {
      pratica: { corrispondenza = [] },
      pratica: { corrispondenzaPG = [] }
    } = this.props

    if (!this.props.pratica.flagIntestatarioPG) {
      return ['COMUNE', 'CAP'].reduce((res, cur) => {
        if (
          corrispondenza[fields.PROVINCIA.id] &&
          corrispondenza[fields.PROVINCIA.id].chiave &&
          corrispondenza[fields[cur].dominioDep] &&
          corrispondenza[fields[cur].dominioDep].chiave
        ) {
          return [
            ...res,
            {
              idDominio: fields[cur].dominio,
              filtro: {
                codice: corrispondenza[fields[cur].dominioDep].chiave
              }
            }
          ]
        }
        return res
      }, [])
    } else {
      return ['CITTA_PG', 'CAP_PG'].reduce((res, cur) => {
        if (
          corrispondenzaPG[fields.PROVINCIA_PG.id] &&
          corrispondenzaPG[fields.PROVINCIA_PG.id].chiave &&
          corrispondenzaPG[fields[cur].dominioDep] &&
          corrispondenzaPG[fields[cur].dominioDep].chiave
        ) {
          return [
            ...res,
            {
              idDominio: fields[cur].dominio,
              filtro: {
                codice: corrispondenzaPG[fields[cur].dominioDep].chiave
              }
            }
          ]
        }
        return res
      }, [])
    }
  }

  updateDominio(name, filter) {
    this.props.dominio({
      listaDomini: [
        {
          idDominio: name,
          filtro: {
            codice: filter
          }
        }
      ]
    })
  }

  updateDomini(listaDomini) {
    this.props.dominio({ listaDomini })
  }

  salva(firmaOra = false) {
    const {
      pratica: { servizi = [] },
      percentualiCommissionali = [],
      serviceData,
      postServizi
    } = this.props
    // this.setState({ firmaOra: firmaOra })
    let formTemp

    postServizi(
      servizi.reduce((res, cur) => {
        if (cur.idTipoServizio === 7) {
          formTemp = fields[Forms[5]]
        } else {
          formTemp = fields[Forms[parseInt(cur.idTipoServizio)]]
        }

        if (!cur.flagDatiServizioSalvati) {
          return [
            ...res,
            {
              idServizio: cur.idServizio,
              payload: {
                ...createDefaultPayload(
                  formTemp,
                  percentualiCommissionali.filter(p => p.codiceTipoServizio === cur.idTipoServizio),
                  serviceData[`servizio_${cur.idTipoServizio}`]
                ),
                idTipologiaConto: '1'
              }
            }
          ]
        }
        return res
      }, [])
    )
  }

  handleOpenCollapsibleChange(itemKey) {
    this.setState({
      openCollapsible: itemKey
    })
  }

  resetForm(form) {
    const saved = this.state.saved
    saved.push(form)
    this.setState({ saved, openCollapsible: this.getOpenCollapsible(form) })
  }

  isDisabledServizio0() {
    if (!this.props.pratica.flagIntestatarioPG) {
      const { pratica } = this.props
      const apertura = fields[Forms[0]]
      if (!pratica[apertura.REGIME.id]) {
        return true
      }
      if (!(pratica[apertura.COMUNE_FIRMA.id] || {}).chiave) {
        return true
      }
      if (pratica[apertura.FLAG_INDIRIZZO.id]) {
        const { corrispondenza = {} } = pratica
        const { NOME, COGNOME, INDIRIZZO, NAZIONE, PROVINCIA, COMUNE, COMUNE_ESTERO, CAP } = apertura
        if (
          !corrispondenza[NOME.id] ||
          !corrispondenza[COGNOME.id] ||
          !corrispondenza[INDIRIZZO.id] ||
          !corrispondenza[NAZIONE.id]
        ) {
          return true
        }
        if (corrispondenza[NAZIONE.id].chiave === NAZIONE.italiaId) {
          if (!corrispondenza[PROVINCIA.id] || !corrispondenza[COMUNE.id] || !corrispondenza[CAP.id]) {
            return true
          }
        } else {
          if (!corrispondenza[COMUNE_ESTERO.id]) {
            return true
          }
        }
      }
    } else {
      const { pratica } = this.props
      const apertura = fields[FormsGiuridiche[0]]
      if (!pratica[apertura.REGIME.id]) {
        return true
      }
      if (!(pratica[apertura.COMUNE_FIRMA.id] || {}).chiave) {
        return true
      }
      if (pratica[apertura.FLAG_INDIRIZZO.id]) {
        const { corrispondenzaPG = {} } = pratica
        const {
          RAGIONE_SOCIALE_PG,
          SEDE_LEGALE_PG,
          NAZIONE_PG,
          PROVINCIA_PG,
          CITTA_PG,
          CAP_PG,
          CITTA_ESTERO_PG
        } = apertura
        if (
          !corrispondenzaPG[RAGIONE_SOCIALE_PG.id] ||
          !corrispondenzaPG[SEDE_LEGALE_PG.id] ||
          !corrispondenzaPG[NAZIONE_PG.id]
        ) {
          return true
        }
        if (corrispondenzaPG[NAZIONE_PG.id].chiave === NAZIONE_PG.italiaId) {
          if (!corrispondenzaPG[PROVINCIA_PG.id] || !corrispondenzaPG[CITTA_PG.id] || !corrispondenzaPG[CAP_PG.id]) {
            return true
          }
        } else {
          if (!corrispondenzaPG[CITTA_ESTERO_PG.id]) {
            return true
          }
        }
      }
    }

    return false
  }

  isDisabledServizio1() {
    const {
      serviceData: { servizio_1 }
    } = this.props
    const {
      IMPORTO,
      ORIGINE
      // VALORE_INIZIALE_CONTO,
      // FLAG_INTESTATARIO_CONTO,
      // NOMINATIVO,
      // CONTROVALORE_GARANZIA
    } = fields[Forms[1]]
    if (servizio_1) {
      const { pagamento, percComm } = servizio_1
      /* if (String(servizio_1.idTipologiaConto) === '2') {
        if (
          // !contoIndividuale[VALORE_INIZIALE_CONTO.id] ||
          // !contoIndividuale[FLAG_INTESTATARIO_CONTO.id] ||
          // !contoIndividuale[NOMINATIVO.id] ||
          !contoIndividuale[CONTROVALORE_GARANZIA.id] ||
          // contoIndividuale[VALORE_INIZIALE_CONTO.id] === '' ||
          // contoIndividuale[FLAG_INTESTATARIO_CONTO.id] === '' ||
          // contoIndividuale[NOMINATIVO.id] === '' ||
          contoIndividuale[CONTROVALORE_GARANZIA.id] === ''
        ) {
          return true
        }
      } */
      if (pagamento && pagamento[IMPORTO.id] && !(pagamento[ORIGINE.id] || {}).chiave) {
        return true
      }
      if (!percComm) {
        return true
      }
    }
    return false
  }

  isDisabledServizio2() {
    const {
      serviceData: { servizio_2 }
    } = this.props
    const { IMPORTO, ORIGINE } = fields[Forms[2]]
    if (servizio_2) {
      const { pagamento } = servizio_2
      if (!pagamento) return true

      if (!pagamento[IMPORTO.id] || !pagamento[ORIGINE.id]) {
        return true
      }

      if (pagamento[IMPORTO.id] && !(pagamento[ORIGINE.id] || {}).chiave) {
        return true
      }
    }
    return false
  }

  isDisabledServizio3() {
    const {
      serviceData: { servizio_3 }
    } = this.props
    const rtoBpm = fields[Forms[3]]

    if (servizio_3) {
      const { pagamento } = servizio_3
      if (!pagamento) return true

      /* if (String(servizio_3.idTipologiaConto) === '2') {
        if (
          // !contoIndividuale[rtoBpm.VALORE_INIZIALE_CONTO.id] ||
          // !contoIndividuale[rtoBpm.FLAG_INTESTATARIO_CONTO.id] ||
          // !contoIndividuale[rtoBpm.NOMINATIVO.id] ||
          !contoIndividuale[rtoBpm.CONTROVALORE_GARANZIA.id] ||
          // contoIndividuale[rtoBpm.VALORE_INIZIALE_CONTO.id] === '' ||
          // contoIndividuale[rtoBpm.FLAG_INTESTATARIO_CONTO.id] === '' ||
          // contoIndividuale[rtoBpm.NOMINATIVO.id] === '' ||
          contoIndividuale[rtoBpm.CONTROVALORE_GARANZIA.id] === ''
        ) {
          return true
        }
      } */

      if (pagamento[rtoBpm.FLAG_BONIFICO.id] || pagamento[rtoBpm.FLAG_ASSEGNO.id]) {
        if (!(pagamento[rtoBpm.IMPORTO.id] && pagamento[rtoBpm.ORIGINE.id] && !!pagamento[rtoBpm.ORIGINE.id].chiave)) {
          return true
        }
      }
      if (pagamento[rtoBpm.FLAG_ASSEGNO.id]) {
        const { dettagliAssegno } = pagamento
        if (!dettagliAssegno) return true
        if (Object.keys(rtoBpm).some(k => rtoBpm[k].isAssegno && !dettagliAssegno[rtoBpm[k].id])) {
          return true
        }
      }
      if (
        !pagamento[rtoBpm.FLAG_BONIFICO.id] &&
        !pagamento[rtoBpm.FLAG_TRANSFER.id] &&
        !pagamento[rtoBpm.FLAG_ASSEGNO.id]
      ) {
        return true
      }
    }
    return false
  }

  isDisabledServizio4() {
    const {
      serviceData: { servizio_4 }
    } = this.props
    const {
      FLAG_BONIFICO,
      IMPORTO,
      ORIGINE,
      FLAG_TRANSFER
      // VALORE_INIZIALE_CONTO,
      // FLAG_INTESTATARIO_CONTO,
      // NOMINATIVO,
      // CONTROVALORE_GARANZIA
    } = fields[Forms[4]]
    if (servizio_4) {
      const { pagamento } = servizio_4

      /* if (String(servizio_4.idTipologiaConto) === '2') {
        if (
          // !contoIndividuale[VALORE_INIZIALE_CONTO.id] ||
          // !contoIndividuale[FLAG_INTESTATARIO_CONTO.id] ||
          // !contoIndividuale[NOMINATIVO.id] ||
          !contoIndividuale[CONTROVALORE_GARANZIA.id] ||
          // contoIndividuale[VALORE_INIZIALE_CONTO.id] === '' ||
          // contoIndividuale[FLAG_INTESTATARIO_CONTO.id] === '' ||
          // contoIndividuale[NOMINATIVO.id] === '' ||
          contoIndividuale[CONTROVALORE_GARANZIA.id] === ''
        ) {
          return true
        }
      } */
      if (!pagamento) return true
      if (pagamento[FLAG_BONIFICO.id]) {
        if (!(pagamento[IMPORTO.id] && pagamento[ORIGINE.id] && !!pagamento[ORIGINE.id].chiave)) {
          return true
        }
      }
      if (!pagamento[FLAG_BONIFICO.id] && !pagamento[FLAG_TRANSFER.id]) {
        return true
      }
    }
    return false
  }

  isDisabledServizio7() {
    const {
      serviceData: { servizio_7 }
    } = this.props
    const { FLAG_BONIFICO, IMPORTO, ORIGINE, FLAG_TRANSFER } = fields[Forms[5]]

    if (servizio_7) {
      const { pagamento } = servizio_7
      if (!pagamento) return true
      if (pagamento[FLAG_BONIFICO.id]) {
        if (!(pagamento[IMPORTO.id] && pagamento[ORIGINE.id] && !!pagamento[ORIGINE.id].chiave)) {
          return true
        }
      }
      if (!pagamento[FLAG_BONIFICO.id] && !pagamento[FLAG_TRANSFER.id]) {
        return true
      }
    }
    return false
  }

  isDisabledServizio9() {
    const {
      serviceData: { servizio_9 }
    } = this.props
    const cdl = fields[Forms[6]]

    if (servizio_9) {
      const { pagamento } = servizio_9
      if (!pagamento) return true
      if (pagamento[cdl.FLAG_BONIFICO.id] || pagamento[cdl.FLAG_ASSEGNO.id]) {
        if (!(pagamento[cdl.IMPORTO.id] && pagamento[cdl.ORIGINE.id] && !!pagamento[cdl.ORIGINE.id].chiave)) {
          return true
        }
      }
      if (pagamento[cdl.FLAG_ASSEGNO.id]) {
        const { dettagliAssegno } = pagamento
        if (!dettagliAssegno) return true
        if (Object.keys(cdl).some(k => cdl[k].isAssegno && !dettagliAssegno[cdl[k].id])) {
          return true
        }
      }
    }
    return false
  }

  isDisabledServizio10() {
    return false
  }

  isDisabledServizio12() {
    const {
      serviceData: { servizio_12 }
    } = this.props
    const { IMPORTO, ORIGINE } = fields[Forms[1]]
    if (servizio_12) {
      const { pagamento, percComm } = servizio_12
      if (pagamento && pagamento[IMPORTO.id] && !(pagamento[ORIGINE.id] || {}).chiave) {
        return true
      }
      if (!percComm) {
        return true
      }
    }
    return false
  }

  isDisabledServizio13() {
    const {
      serviceData: { servizio_13 }
    } = this.props
    const { IMPORTO, ORIGINE } = fields[Forms[2]]
    if (servizio_13) {
      const { pagamento } = servizio_13
      if (!pagamento) return true

      if (!pagamento[IMPORTO.id] || !pagamento[ORIGINE.id]) {
        return true
      }

      if (pagamento[IMPORTO.id] && !(pagamento[ORIGINE.id] || {}).chiave) {
        return true
      }
    }
    return false
  }

  isDisabledServizio14() {
    return false
  }

  isFirmaDisabled() {
    const { checkForms } = this.props
    if (checkForms()) {
      return true
    }
    if (this.props.pratica.flagIntestatarioPG !== true) {
      return [0, 1, 2, 3, 4, 7, 9, 10, 12, 13, 14].some(n => this[`isDisabledServizio${n}`]())
    } else {
      return [1, 2, 3, 4, 7, 9, 10, 12, 13, 14].some(n => this[`isDisabledServizio${n}`]())
    }
  }

  getStatus(saved, index = 0) {
    const {
      pratica: { servizi = [] }
    } = this.props
    if (
      (saved || (servizi.find(s => s.idTipoServizio === index) || {}).flagDatiServizioSalvati) &&
      !this[`isDisabledServizio${index}`]()
    ) {
      return 'COMPLETATA'
    }
    return 'BOZZA'
  }

  modalHandler(value) {
    this.setState({ typeFirma: '' })
    if (value === 'close') {
      this.setState({ showModal: !this.state.showModal })
    }

    this.setState({ later: value })
    // if (!this.state.showModalFirma && this.state.callTipofirma , value){
    //   this.props.checkTipoFirmaIntestatari(this.props.idPratica)
    //   this.setState({ callTipofirma: false })
    // }
  }

  openModalFirma(value) {
    const { checkTipoFirmaIntestatari, getPraticaIntestatari, idPratica } = this.props
    checkTipoFirmaIntestatari(idPratica)
    this.setState({ updateStato: false })
    if (!value) {
      getPraticaIntestatari(idPratica)
      this.salva(value)
    }
    this.setState({ showModal: !this.state.showModal, callTipofirma: true })
  }

  isDigitalSignDisabled() {
    const { flagDigitalSignatureEnabled } = this.props
    return !flagDigitalSignatureEnabled
  }

  chooseFirma(selected, unselected) {
    this.setState({ typeFirma: selected })

    // border box
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[0].style.border = '2px solid #083b67'
    // icon
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[1].style.color = '#083b67'
    // title
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[2].style.color = '#083b67'
    if (!this.isDigitalSignDisabled()) {
      document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[0].style.border = '2px solid #dadada'
      document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[1].style.color = '#616166'
      document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[2].style.color = '#616166'
    }
  }

  render() {
    const {
      domini,
      postServizioInfo,
      updatePratica,
      updatePraticaPG,
      pratica,
      // history,
      dataRegistry,
      idCardData,
      anagraficaPG,
      percentualiCommissionali,
      serviceData: {
        servizio_1,
        servizio_2,
        servizio_3,
        servizio_4,
        servizio_7,
        servizio_9,
        servizio_10,
        servizio_12,
        servizio_13,
        servizio_14
      },
      pratica: { intestatari = [] },
      getIdCardDataRequest
    } = this.props

    if (!domini.listaDomini || !this.state.loadingComplete) {
      return null
    }

    return (
      <Fragment>
        <ModalGeneric
          show={this.state.showModalFirma}
          handler={() => this.props.history.push('/')}
          title={staticData.check_intestatari.title}
          text={staticData.check_intestatari.text}
        />

        {this.state.showModal && (
          <ModalFirma
            customer={intestatari[0]}
            handler={this.modalHandler}
            title={<p className="onboarding-modale-content__title">Area di firma</p>}
            text={
              <p className="onboarding-modale-content__subtitle">
                Parte dei moduli da compilare può essere firmata sia attraverso la modalità di <b>Firma Digitale</b> che
                in modalità di <b>Firma Cartacea.</b> Seleziona la modalità desiderata.
              </p>
            }
            typeFirma={this.state.typeFirma}
            resetSelectedState={() => {
              this.setState({ showModal: false })
            }}
            later={this.state.later}
            save={this.saveFromModalFirma}
            push={this.props.history.push}
            flag={this.state.typeFirma !== 'firma-digitale'}
            {...this.props}>
            <Row>
              <Col s={6} className="center-align">
                <div
                  disabled={this.isDigitalSignDisabled()}
                  className="onboarding-modale-icon onboarding-modale-icon__firma-digitale"
                  onClick={() => {
                    if (!this.isDigitalSignDisabled()) {
                      this.chooseFirma('firma-digitale', 'firma-cartacea')
                    }
                  }}>
                  <Icon className="onboarding-modale-icon__firma-digitale icon-icone-ips_sv-spesa" medium>
                    &#xe917;
                  </Icon>
                  <p className="onboarding-modale-icon__firma-digitale onboarding-modale-icon__title">FIRMA DIGITALE</p>
                </div>
              </Col>
              <Col s={6} className="center-align">
                <div
                  className="onboarding-modale-icon onboarding-modale-icon__firma-cartacea"
                  onClick={() => this.chooseFirma('firma-cartacea', 'firma-digitale')}>
                  <Icon className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa" medium>
                    &#xe918;
                  </Icon>
                  <p className="onboarding-modale-icon__firma-cartacea onboarding-modale-icon__title">FIRMA CARTACEA</p>
                </div>
              </Col>
            </Row>
          </ModalFirma>
        )}
        <Row>
          <Col s={12}>
            <div className="configurazione-servizi">
              <Collapsible open={this.state.openCollapsible} onChange={this.handleOpenCollapsibleChange}>
                {this.props.pratica.flagIntestatarioPG !== true && (
                  <InformazioniGenerali
                    domini={domini}
                    dominio={this.props.dominio}
                    updateDominio={this.updateDominio}
                    updateDomini={this.updateDomini}
                    pratica={pratica}
                    serviceData={this.props.serviceData}
                    dataRegistry={dataRegistry}
                    onSave={updatePratica}
                    resetForm={this.resetForm}
                    isCompleted={!this.isDisabledServizio0()}
                    dominioRequest={this.props.dominioRequest}
                    isPG={this.props.pratica.flagIntestatarioPG}
                  />
                )}
                {this.props.pratica.flagIntestatarioPG === true && (
                  <InformazioniGeneraliGiuridiche
                    domini={domini}
                    dominio={this.props.dominio}
                    updateDominio={this.updateDominio}
                    updateDomini={this.updateDomini}
                    pratica={pratica}
                    serviceData={this.props.serviceData}
                    dataRegistry={anagraficaPG}
                    onSave={updatePraticaPG}
                    resetForm={this.resetForm}
                    isCompleted={!this.isDisabledServizio0()}
                    dominioRequest={this.props.dominioRequest}
                    isPG={this.props.pratica.flagIntestatarioPG}
                  />
                )}

                {servizio_1 && (
                  <Max
                    domini={domini}
                    serviceData={servizio_1}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 1)}
                    resetForm={this.resetForm}
                  />
                )}
                {servizio_2 && (
                  <MaxFund
                    domini={domini}
                    serviceData={servizio_2}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 2)}
                    resetForm={this.resetForm}
                  />
                )}
                {servizio_3 && (
                  <RtoBpm
                    domini={domini}
                    serviceData={servizio_3}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 3)}
                    resetForm={this.resetForm}
                  />
                )}
                {servizio_4 && (
                  <RtoUbs
                    domini={domini}
                    serviceData={servizio_4}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 4)}
                    resetForm={this.resetForm}
                  />
                )}
                {servizio_7 && (
                  <RtoImi
                    domini={domini}
                    serviceData={servizio_7}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 7)}
                    resetForm={this.resetForm}
                  />
                )}
                {servizio_9 && (
                  <ContoDepositoLiquidita
                    domini={domini}
                    serviceData={servizio_9}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 9)}
                    resetForm={this.resetForm}
                  />
                )}
                {servizio_10 && (
                  <React.Fragment>
                    <PIR
                      domini={domini}
                      serviceData={servizio_10}
                      data={{ ...dataRegistry, ...idCardData }}
                      updateDomini={this.updateDomini}
                      getIdCardDataRequest={getIdCardDataRequest}
                    />
                    <p className="disclaimer">{staticData.PIR.footer}</p>
                  </React.Fragment>
                )}
                {servizio_12 && (
                  <MaxBnp
                    domini={domini}
                    serviceData={servizio_12}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 12)}
                    resetForm={this.resetForm}
                  />
                )}
                {servizio_13 && (
                  <MaxFundBnp
                    domini={domini}
                    serviceData={servizio_13}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 13)}
                    resetForm={this.resetForm}
                  />
                )}
                {servizio_14 && (
                  <GoalBasedPlan
                    domini={domini}
                    serviceData={servizio_14}
                    percentualiCommissionali={percentualiCommissionali}
                    onSave={postServizioInfo}
                    getStatus={saved => this.getStatus(saved, 14)}
                    resetForm={this.resetForm}
                  />
                )}
              </Collapsible>
            </div>

            <FirmaFooter
              onSaveText="Sarà possibile firmare in modalità digitale o cartacea"
              save={this.salva}
              isDisabled={this.isFirmaDisabled()}
              openModal={value => {
                this.openModalFirma(value)
              }}
              call_back={this.modalHandler}
              dinamicClass={!this.isFirmaDisabled() ? 'colorBlue' : ''}
            />
          </Col>
          {/* <Col s={3} className="paddingless">
            <AsideServizi intestatari={intestatari} />
          </Col> */}
        </Row>
      </Fragment>
    )
  }
}
ConfigurazioneServiziView.propTypes = {
  getDocIDPGRequest: PropTypes.object,
  dominioRequest: PropTypes.object,
  dominio: PropTypes.func,
  domini: PropTypes.object,
  pratica: PropTypes.object,
  serviceData: PropTypes.object,
  getServizioInfo: PropTypes.func,
  getServiceInfoRequest: PropTypes.object,
  updateStatoPraticaRequest: PropTypes.object,
  postServizioInfo: PropTypes.func,
  postServizi: PropTypes.func,
  postServiziRequest: PropTypes.object,
  updatePratica: PropTypes.func,
  updatePraticaPG: PropTypes.func,
  updateStatoPratica: PropTypes.func,
  history: PropTypes.object,
  percentualiCommissionali: PropTypes.array,
  getDataRegistry: PropTypes.func,
  resetConsulenza: PropTypes.func,
  checkForms: PropTypes.func,
  getDataRegistryRequest: PropTypes.object,
  dataRegistry: PropTypes.object,
  anagraficaPG: PropTypes.object,
  getPratica: PropTypes.func,
  idPratica: PropTypes.any,
  praticaRequest: PropTypes.object,
  getPraticaIntestatari: PropTypes.func,
  praticaIntestatariRequest: PropTypes.object,
  flagFirmaPraticaInvestimento: PropTypes.bool,
  setIdPromotore: PropTypes.func,
  match: PropTypes.object,
  checkTipoFirmaIntestatari: PropTypes.func,
  flagDigitalSignatureEnabled: PropTypes.bool,
  getDocumentIdPGActionCreator: PropTypes.func,
  getPraticaPG: PropTypes.func,
  getPraticaPGRequest: PropTypes.object,
  getDataRegistryExt: PropTypes.func,
  getDataRegistryExtRequest: PropTypes.object,
  getDataRegistryPGExt: PropTypes.func,
  getDataRegistryPGExtRequest: PropTypes.object,
  getIdCardData: PropTypes.func,
  idCardData: PropTypes.object,
  getIdCardDataRequest: PropTypes.object
}

export default ConfigurazioneServiziView
