import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

class Loading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      callsCounter: 0
    }
    this.setCall = this.setCall.bind(this)
    this.mutateCallsCounter = this.mutateCallsCounter.bind(this)
  }

  componentDidMount() {
    this.setCall()
  }

  componentDidUpdate() {
    if (this.state.callsCounter === 0) {
      this.props.setDataLoading('spinner')
    }
  }

  mutateCallsCounter(increment) {
    this.setState({ callsCounter: increment ? this.state.callsCounter + 1 : this.state.callsCounter - 1 })
  }

  setCall() {
    // request interceptor
    axios.interceptors.request.use(
      config => {
        this.mutateCallsCounter(true)
        this.props.setDataLoading('fixedloading spinner')
        return config
      },
      error => {
        this.mutateCallsCounter(false)
        return Promise.reject(error)
      }
    )

    // response interceptor
    axios.interceptors.response.use(
      response => {
        this.mutateCallsCounter(false)
        return response
      },
      error => {
        this.mutateCallsCounter(false)
        return Promise.reject(error)
      }
    )
  }

  render() {
    return (
      <div className={this.props.dataLoading}>
        <div className="loader-inner ball-spin-fade-loader">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }
}
export default Loading

Loading.propTypes = {
  dataLoading: PropTypes.string,
  setDataLoading: PropTypes.func
}
