import * as actionTypes from './constants'

const initialState = {
  otpStatus: {},
  createOtpRequest: {
    fetching: false,
    error: null
  },
  verifyOtpRequest: {
    fetching: false,
    error: null
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.VALIDATION_CREATE_OTP_START:
      return {
        ...state,
        otpStatus: {},
        createOtpRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.VALIDATION_CREATE_OTP_SUCCESS:
      return {
        ...state,
        createOtpRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.VALIDATION_CREATE_OTP_ERROR:
      return {
        ...state,
        createOtpRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.VALIDATION_VERIFY_OTP_START:
      return {
        ...state,
        verifyOtpRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.VALIDATION_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        otpStatus: action.data,
        verifyOtpRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.VALIDATION_VERIFY_OTP_ERROR:
      return {
        ...state,
        verifyOtpRequest: {
          fetching: false,
          error: action.error
        }
      }
  }
  return state
}
