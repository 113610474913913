import React from 'react'
import ReactDOM from 'react-dom'
import createStore from './store/createStore'
import moment from 'moment'
import App from './components/App'
import './api/apiConfig'
import 'react-dates/initialize'
import './styles/main.scss'
import 'moment/locale/it'
import 'babel-polyfill'

// Store Initialization
// ------------------------------------
const store = createStore(window.__INITIAL_STATE__)

// Render Setup
// ------------------------------------
const MOUNT_NODE = document.getElementById('root')

moment.locale('it')
let render = () => {
  // const App = require('./components/App').default
  // const routes = require('./routes/index').default(store)

  ReactDOM.render(<App store={store} />, MOUNT_NODE)
}

window.moment = moment

render()
