import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import { Col, Row, Icon } from 'react-materialize'
import { renderSelect, renderCheckbox, renderInput } from '../../../../../components/Forms/renderFields'
import { tipologiaContoDetails } from '../../../ConfigurazioneServizi/utils'
// import { mapDominiToOptions } from '../../../../routes/ConsulenzaBaseContainerLayout/utils'
// import ReadOnlyInput from '../../../Inputs/ReadOnly'
// import InputText from '../../../Inputs/Text'
// import { mapDominiToOptions, getDominio } from '../../../../ConsulenzaBaseContainerLayout/utils'

// const regex = /^(?:[1-9]\d*)$/

const IndividualeUBSForm = ({ fields }) => {
  return (
    <div style={{ marginLeft: '10px', marginRight: '10px' }}>
      <Row className="m-top10">
        <Col s={12}>
          <p>
            <b>Linea di credito</b>
          </p>
        </Col>
      </Row>
      <Row className="valign-wrapper">
        <Col s={6}>
          <p>Si richiede inoltre l'apertura di una linea di credito per</p>
        </Col>
        <Col s={6}>
          <Field
            name={fields.VALORE_INIZIALE_CONTO.id}
            size={12}
            label={fields.VALORE_INIZIALE_CONTO.label}
            /* options={
            mapDominiToOptions(getDominio(domini, fields.ORIGINE.dominio), true) mapDominiToOptions(
              tipologiaContoDetails.filter(item => item.descTipoServizio === tipoServizio)[0].tipologia,
              false
            )
          } */
            /* disabled={isDisabled(fields.ORIGINE.id)} */
            component={renderInput}
          />
        </Col>
      </Row>
      <Row className="valign-wrapper">
        <Col s={2} style={{ marginLeft: '0px' }}>
          <p>A favore di:</p>
        </Col>
        {/* <Col s={3} style={{ marginTop: '15px' }}> */}
        <Col className="ml-30 mt-15">
          <Field
            /* name={fields.TIPOLOGIA_CONTO.id} */
            s={3}
            name={fields.FLAG_INTESTATARIO_CONTO.id}
            label="Mio/Nostro favore"
            className="filled-in"
            type="radio"
            value="SOTTOSCRITTI"
            /* label={`${fields.TIPOLOGIA_CONTO.label}${origineRequired ? '*' : ''}`} */
            /* options={
            mapDominiToOptions(getDominio(domini, fields.ORIGINE.dominio), true) mapDominiToOptions(
              tipologiaContoDetails.filter(item => item.descTipoServizio === tipoServizio)[0].tipologia,
              false
            )
          } */
            /* disabled={isDisabled(fields.ORIGINE.id)} */
            component={renderCheckbox}
          />
          {/* </Col> */}
          {/* <Col s={1} style={{ marginTop: '15px' }}> */}
          <Field
            s={1}
            /* name={fields.TIPOLOGIA_CONTO.id} */
            name={fields.FLAG_INTESTATARIO_CONTO.id}
            label="Terzi"
            className="filled-in"
            type="radio"
            value="TERZI"
            /* label={`${fields.TIPOLOGIA_CONTO.label}${origineRequired ? '*' : ''}`} */
            /* options={
            mapDominiToOptions(getDominio(domini, fields.ORIGINE.dominio), true) mapDominiToOptions(
              tipologiaContoDetails.filter(item => item.descTipoServizio === tipoServizio)[0].tipologia,
              false
            )
          } */
            /* disabled={isDisabled(fields.ORIGINE.id)} */
            component={renderCheckbox}
          />
        </Col>

        {/* </Col> */}
        <Col s={6}>
          <Field
            name={fields.NOMINATIVO.id}
            size={12}
            label={fields.NOMINATIVO.label}
            /* disabled={isDisabled(fields.ORIGINE.id)} */
            component={renderInput}
          />
        </Col>
      </Row>
      <Row className="center-align valign-wrapper">
        <Col s={6}>
          <p>Controvalore della garanzia:</p>
        </Col>
        <Col s={6}>
          <Field
            name={fields.CONTROVALORE_GARANZIA.id}
            size={12}
            label={fields.CONTROVALORE_GARANZIA.label}
            /* label={`${fields.TIPOLOGIA_CONTO.label}${origineRequired ? '*' : ''}`} */
            /* options={
            mapDominiToOptions(getDominio(domini, fields.ORIGINE.dominio), true) mapDominiToOptions(
              tipologiaContoDetails.filter(item => item.descTipoServizio === tipoServizio)[0].tipologia,
              false
            )
          } */
            /* disabled={isDisabled(fields.ORIGINE.id)} */

            component={renderInput}
          />
        </Col>
      </Row>
      <Row className="center-align valign-wrapper">
        <Col s={12} style={{ paddingLeft: '0px', paddingRight: '11px' }}>
          <Field
            name={fields.ALTRE_RICHIESTE.id}
            size={12}
            label={fields.ALTRE_RICHIESTE.label}
            /* label={`${fields.TIPOLOGIA_CONTO.label}${origineRequired ? '*' : ''}`} */
            /* options={
            mapDominiToOptions(getDominio(domini, fields.ORIGINE.dominio), true) mapDominiToOptions(
              tipologiaContoDetails.filter(item => item.descTipoServizio === tipoServizio)[0].tipologia,
              false
            )
          } */
            /* disabled={isDisabled(fields.ORIGINE.id)} */
            component={renderInput}
          />
        </Col>
      </Row>
    </div>
  )
}
IndividualeUBSForm.propTypes = {
  fields: PropTypes.shape({
    TIPOLOGIA_CONTO: PropTypes.object.isRequired
  })
}

const OmnibusUBSForm = ({ props }) => {
  return ''
}
OmnibusUBSForm.propTypes = { props: PropTypes.any }

export const IndividualeBPMForm = ({ props }) => {
  return (
    <div style={{ marginLeft: '10px', marginRight: '10px' }}>
      <Row className="m-top10">
        <Col s={12}>
          <p style={{ fontSize: '20px', color: '#003969', fontWeight: 'lighter' }}>
            <b>Documenti</b>
          </p>
        </Col>
      </Row>
      <Row className="m-top20 valign-wrapper" style={{ borderBottom: '1px solid grey' }}>
        <Col s={1}>
          <Icon
            className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa onboarding-modale-text__blu"
            small>
            &#xe915;
          </Icon>
        </Col>
        <Col s={10}>
          <p className="onboarding-modale-text__blu left-align">FATCA-CRS</p>
        </Col>
        <Col s={1}>
          <button className={'onboarding-more-options-button'}>
            <Icon className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa right-align" small>
              &#xe91d;
            </Icon>
          </button>
        </Col>
      </Row>
      <Row className="m-top20 valign-wrapper" style={{ borderBottom: '1px solid grey' }}>
        <Col s={1}>
          <Icon
            className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa onboarding-modale-text__blu"
            small>
            &#xe915;
          </Icon>
        </Col>
        <Col s={10}>
          <p className="onboarding-modale-text__blu left-align">Addendum</p>
        </Col>
        <Col s={1}>
          <button className={'onboarding-more-options-button'}>
            <Icon className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa right-align" small>
              &#xe91d;
            </Icon>
          </button>
        </Col>
      </Row>
    </div>
  )
}
IndividualeBPMForm.propTypes = { props: PropTypes.any }

const OmnibusBPMForm = ({ props }) => {
  return ''
}
OmnibusBPMForm.propTypes = { props: PropTypes.any }

const OmnibusForm = ({ props }) => {
  return ''
}
OmnibusForm.propTypes = { props: PropTypes.any }

const renderContoForm = (tipologiaConto, fields, tipoServizio) => {
  switch (tipologiaConto) {
    case '2':
      switch (tipoServizio) {
        case 'MAX':
        case 'UBS':
          return <IndividualeUBSForm fields={fields} />
        case 'BPM':
          return <IndividualeUBSForm fields={fields} />
      }
      break
    case '1':
      switch (tipoServizio) {
        case 'MAX':
        case 'UBS':
          return <OmnibusUBSForm />
        case 'BPM':
          return <OmnibusBPMForm />
        case 'MAX FUND':
          return <OmnibusForm />
      }
  }
}

const TipologiaContoForm = ({
  fields,
  // domini,
  formValues,
  isDisabled,
  change,
  touch,
  importoRequired,
  origineRequired,
  tipoServizio
}) => {
  return (
    <Fragment>
      <Row>
        <Col s={5} className="left-align">
          <p className="collapsible-section-title">Tipologia di conto</p>
        </Col>
      </Row>
      <Row className="m-top10">
        <Col s={6}>
          <Field
            name={fields.TIPOLOGIA_CONTO.id}
            disabled={isDisabled}
            size={12}
            label={`${fields.TIPOLOGIA_CONTO.label}${origineRequired ? '*' : ''}`}
            options={
              /* Da includere */
              /* mapDominiToOptions(getDominio(domini, fields.ORIGINE.dominio), true) */
              tipologiaContoDetails
                .filter(item => item.descTipoServizio === tipoServizio)[0]
                .tipologia.map((item, index) => (
                  <option key={`${index + 1}`} value={index + 1}>
                    {item}
                  </option>
                ))
            }
            /* disabled={isDisabled(fields.ORIGINE.id)} */
            component={renderSelect}
          />
        </Col>
      </Row>
      {true && renderContoForm(String(formValues[fields.TIPOLOGIA_CONTO.id]), fields, tipoServizio)}
    </Fragment>
  )
}
export default TipologiaContoForm

TipologiaContoForm.propTypes = {
  fields: PropTypes.shape({
    TIPOLOGIA_CONTO: PropTypes.object.isRequired
  }),
  formValues: PropTypes.object,
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  importoRequired: PropTypes.bool,
  origineRequired: PropTypes.bool,
  tipoServizio: PropTypes.any,
  isDisabled: PropTypes.bool
}
