import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import moment from 'moment'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { INFORMAZIONI_PERSONALI } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import { getValueDominioFromKey } from '../../utils'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'

const fields = allFields[1][INFORMAZIONI_PERSONALI]

class InformazioniPersonaliReview extends Component {
  render() {
    const {
      data,
      domini: { listaDomini }
    } = this.props

    return (
      <CollapsibleItem
        header={dataSB.censimento_anagrafica.info_personali.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={INFORMAZIONI_PERSONALI}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={4}>
            <ReadOnlyInput label={fields.NOME.label} value={data[fields.NOME.id]} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput label={fields.COGNOME.label} value={data[fields.COGNOME.id]} />
          </Col>
          <Col s={4}>
            <ReadOnlyInput
              label={fields.NAZIONE_NASCITA.label}
              value={getValueDominioFromKey(
                listaDomini,
                fields.NAZIONE_NASCITA.dominio,
                (data[fields.NAZIONE_NASCITA.id] || {}).chiave
              )}
            />
          </Col>
        </Row>
        <Row>
          {(data[fields.PROVINCIA_NASCITA.id] || {}).chiave && (
            <Fragment>
              <Col s={4}>
                <ReadOnlyInput
                  label={fields.PROVINCIA_NASCITA.label}
                  value={getValueDominioFromKey(
                    listaDomini,
                    fields.PROVINCIA_NASCITA.dominio,
                    data[fields.PROVINCIA_NASCITA.id].chiave
                  )}
                />
              </Col>
              <Col s={4}>
                <ReadOnlyInput
                  label={fields.COMUNE_NASCITA.label}
                  value={getValueDominioFromKey(
                    listaDomini,
                    fields.COMUNE_NASCITA.dominio,
                    (data[fields.COMUNE_NASCITA.id] || {}).chiave
                  )}
                />
              </Col>
            </Fragment>
          )}
          {!(data[fields.PROVINCIA_NASCITA.id] || {}).chiave && (
            <Col s={4}>
              <ReadOnlyInput label={fields.COMUNE_ESTERO_NASCITA.label} value={data[fields.COMUNE_ESTERO_NASCITA.id]} />
            </Col>
          )}
          <Col s={4}>
            <ReadOnlyInput
              label={fields.DATA_NASCITA.label}
              value={moment(data[fields.DATA_NASCITA.id]).format('DD/MM/YYYY')}
            />
          </Col>
        </Row>
        <Row>
          <Col s={4}>
            <ReadOnlyInput
              label={fields.CITTADINANZA.label}
              value={getValueDominioFromKey(
                listaDomini,
                fields.CITTADINANZA.dominio,
                (data[fields.CITTADINANZA.id] || {}).chiave
              )}
            />
          </Col>
        </Row>
      </CollapsibleItem>
    )
  }
}
export default InformazioniPersonaliReview

InformazioniPersonaliReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object
}
