export default {
  SLIDER_ANNI_CONOSCENZA: {
    id: 'sliderAnniConoscenza',
    templateId: 70,
    defaultValue: 2,
    propRisposta: 'descRisposta'
  },
  RADIO_PRIMO_CONTATTO: {
    id: 'radioPrimoContatto',
    templateId: 71,
    defaultValue: '',
    propRisposta: 'idRisposta'
  },
  YES_NO_CONTESTO_FAMILIARE: {
    id: 'yesNoContestoFamiliare',
    templateId: 72,
    defaultValue: '',
    propRisposta: 'idRisposta',
    yesId: 90,
    noId: 91
  },
  SLIDER_NUMERO_COMPONENTI: {
    id: 'sliderNumeroComponenti',
    templateId: 73,
    defaultValue: 2,
    propRisposta: 'descRisposta'
  },
  RADIO_TENORE_VITA: {
    id: 'radioTenoreVita',
    templateId: 74,
    defaultValue: '',
    propRisposta: 'idRisposta'
  },
  RAGIONEVOLEZZA_ATTIVITA: {
    id: 'ragionevolezzaAttivita',
    templateId: 75,
    defaultValue: '95',
    propRisposta: 'idRisposta'
  },
  RAGIONEVOLEZZA_RESIDENZA: {
    id: 'ragionevolezzaResidenza',
    templateId: 76,
    defaultValue: '95',
    propRisposta: 'idRisposta'
  },
  COMPORTAMENTO: {
    id: 'comportamento',
    templateId: 77,
    defaultValue: '97',
    propRisposta: 'idRisposta'
  },
  YES_NO_SOGGETTI_TERZI: {
    id: 'yesNoSoggettiTerzi',
    templateId: 78,
    defaultValue: '91',
    propRisposta: 'idRisposta'
  },
  YES_NO_INFORMAZIONI_ATTENDIBILI: {
    id: 'yesNoInformazioniAttendibili',
    templateId: 79,
    defaultValue: '90',
    propRisposta: 'idRisposta'
  },
  YES_NO_ELEMENTI_SOSPETTOSITA: {
    id: 'yesNoElementiSospettosita',
    templateId: 80,
    defaultValue: '91',
    propRisposta: 'idRisposta'
  },
  RADIO_RISCHIO_RICICLAGGIO: {
    id: 'radioRischioRiciclaggio',
    templateId: 81,
    defaultValue: '94',
    propRisposta: 'idRisposta'
  },
  TEXTAREA_MOTIVAZIONI_RISCHIO: {
    id: 'textareaMotivazioniRischio',
    templateId: 82,
    defaultValue: '',
    propRisposta: 'descRisposta'
  }
}
