import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { RESIDENZA } from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'
import SezioneResidenza from './SezioneResidenza'

const fields = allFields[1][RESIDENZA]

class ResidenzaReview extends Component {
  render() {
    const {
      data,
      domini: { listaDomini }
    } = this.props

    return (
      <CollapsibleItem
        header={dataSB.censimento_anagrafica.residenza.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={RESIDENZA}
        labelInfo={this.props.accordionHeader}>
        <SezioneResidenza fields={fields} data={data} listaDomini={listaDomini} />
      </CollapsibleItem>
    )
  }
}
export default ResidenzaReview

ResidenzaReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object
}
