export const GET_PROSPECT_COUNT_START = 'GET_PROSPECT_COUNT_START'
export const GET_PROSPECT_COUNT_SUCCESS = 'GET_PROSPECT_COUNT_SUCCESS'
export const GET_PROSPECT_COUNT_ERROR = 'GET_PROSPECT_COUNT_ERROR'

export const GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_START = 'GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_START'
export const GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_SUCCESS = 'GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_SUCCESS'
export const GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_ERROR = 'GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_ERROR'

export const GET_PROSPECT_PERCENTAGE_START = 'GET_PROSPECT_PERCENTAGE_START'
export const GET_PROSPECT_PERCENTAGE_SUCCESS = 'GET_PROSPECT_PERCENTAGE_SUCCESS'
export const GET_PROSPECT_PERCENTAGE_ERROR = 'GET_PROSPECT_PERCENTAGE_ERROR'

export const GET_INVESTMENT_SERVICE_OVERALL_START = 'GET_INVESTMENT_SERVICE_OVERALL_START'
export const GET_INVESTMENT_SERVICE_OVERALL_SUCCESS = 'GET_INVESTMENT_SERVICE_OVERALL_SUCCESS'
export const GET_INVESTMENT_SERVICE_OVERALL_ERROR = 'GET_INVESTMENT_SERVICE_OVERALL_ERROR'

export const GET_PROSPECT_IP_OVERALL_START = 'GET_PROSPECT_IP_OVERALL_START'
export const GET_PROSPECT_IP_OVERALL_SUCCESS = 'GET_PROSPECT_IP_OVERALL_SUCCESS'
export const GET_PROSPECT_IP_OVERALL_ERROR = 'GET_PROSPECT_IP_OVERALL_ERROR'
