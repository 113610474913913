import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import { Input, Button } from 'react-materialize'
// import { KEYS } from '../../utils/index'

const initialState = {
  n0: '',
  n1: '',
  n2: '',
  n3: '',
  error: false
}

const regex = /^[0-9]?$/

class OtpVerify extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState
    this.invia = this.invia.bind(this)
    this.verifica = this.verifica.bind(this)
    // this.onKeyPressHandler = this.onKeyPressHandler.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.show && !this.props.show) {
      this.setState(initialState)
    }
    if (prevProps.api.fetching && !this.props.api.fetching) {
      const { otpStatus, callBack, label } = this.props
      if (otpStatus && otpStatus.verified) {
        callBack(label === 'sms' ? 'Cellulare' : 'Email')
      } else {
        this.setState({ error: true })
      }
    }
  }

  invia() {
    this.setState(initialState)
    this.props.invia()
  }

  verifica() {
    this.setState(initialState)
    /* (field, value) => {
      change(field, value)
      change(
        FLAG_CELLULARE.id,
        currentInitial[CELLULARE.id] === value && currentInitial[PREFISSO.id] === formValues[PREFISSO.id]
      )
    } */
    // let isPg = location.pathname.indexOf('-PG') >= 0
    this.props.verifica(`${this.state.n0}${this.state.n1}${this.state.n2}${this.state.n3}`)
  }
  /* 
  onKeyPressHandler(event) {
    if (!this.isInValidCode && event && event.key === KEYS.ENTER) {
      this.verifica()
    }
    // funzione rimossa a causa di un bug che coinvolgeva createreducer.js
  }
*/
  get isInValidCode() {
    return Object.keys(this.state).some(el => el !== 'error' && !this.state[el])
  }

  render() {
    const { label, verifyAndSaveFlag } = this.props

    return (
      <Fragment>
        {this.props.show && (
          <div className="modal-background">
            <div className="otpVerify-modal" /* onKeyPress={this.onKeyPressHandler} */>
              <div className="otpVerify-container">
                <div className="otpVerify-close">
                  <i className="icon-plus" onClick={() => this.props.callBack()} />
                </div>
                <div className="otpVerify-content">
                  <h6>Certifica {this.props.label === 'sms' ? 'cellulare' : 'e-mail'}</h6>
                  {this.props.label === 'sms' && <p>{this.props.cellulare}</p>}
                  <p>
                    Inserisci il codice PIN ricevuto al
                    {this.props.label === 'sms' ? ' numero di cellulare' : "l'indirizzo e-mail"} indicato
                  </p>
                  {this.state.error && (
                    <div className="pin-error">
                      Il codice risulta non essere inserito correttamente. Le chiediamo di verificare e procedere
                      nuovamente con l'operazione
                    </div>
                  )}
                  <div className="otpVerify-body">
                    {[0, 1, 2, 3].map(i => (
                      <Input
                        key={`otp-input-${i}`}
                        id={`otp-input-${i}`}
                        maxLength="1"
                        type="text"
                        value={this.state[`n${i}`]}
                        autoFocus={i === 0}
                        onChange={({ target: { value } }) => {
                          if (regex.test(value)) {
                            this.setState({ [`n${i}`]: value })
                            if (value && i < 3) {
                              $(`#otp-input-${i + 1}`).focus()
                            }
                          }
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="otpVerify-footer">
                  <Button
                    type="button"
                    className="invia"
                    flat
                    waves="light"
                    onClick={() =>
                      this.props.label === 'sms' ? this.props.otpSmsToggle() : this.props.otpEmailToggle()
                    }>
                    Invia di nuovo
                  </Button>
                  <Button
                    id="verify-btn"
                    waves="light"
                    type="button"
                    disabled={this.isInValidCode}
                    onClick={this.verifica}>
                    {label !== 'sms' ? 'Prosegui' : verifyAndSaveFlag ? 'Certifica e salva' : 'Certifica'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    )
  }
}
export default OtpVerify

OtpVerify.propTypes = {
  verifica: PropTypes.func,
  verifyAndSaveFlag: PropTypes.bool,
  // isProspectPGAvailable: PropTypes.bool,
  invia: PropTypes.func,
  api: PropTypes.object,
  show: PropTypes.bool,
  callBack: PropTypes.func,
  label: PropTypes.string,
  otpStatus: PropTypes.object,
  otpSmsToggle: PropTypes.func,
  otpEmailToggle: PropTypes.func,
  cellulare: PropTypes.any
}
