import React from 'react'
import PropTypes from 'prop-types'
import { CollapsibleContext } from './CollapsibleContext'

class CollapsibleItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true
    }
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {
    if (this.props.autoToggle) {
      this.setState({
        open: !this.state.open
      })
    } else {
      if (this.props.openItem === this.props.itemKey) {
        if (this.props.onChange) {
          this.props.onChange(null)
        }
      } else {
        if (this.props.onChange) {
          if (this.props.toggle) {
            // condizione per non danneggiare il resto dell'applicazione
            this.props.toggle() // parametro aggiunto per toggle in soggettiCorrelati
          }
          this.props.onChange(this.props.itemKey)
        }
      }
    }
  }

  render() {
    let childrenElements = null

    // controllo per inserire icona
    let iconElement = this.props.icon ? <i className="material-icons">{this.props.icon}</i> : null

    // controllo per inserire i childer nell accordion
    let classOpen = ''
    if (this.props.autoToggle === true && this.state.open === true) {
      childrenElements = this.props.children
      classOpen = 'open'
    } else if (this.props.openItem === this.props.itemKey && !this.props.disabled) {
      childrenElements = this.props.children
      classOpen = 'open'
    }

    return (
      <div className={`collapsible-item ${this.props.disabled ? 'disabled' : ''} ${classOpen}`}>
        <div className="collapsible-header" onClick={this.handleChange}>
          {this.props.header}
          {iconElement}
          {this.props.labelInfo}
        </div>
        <div className="collapsible-body">{childrenElements}</div>
      </div>
    )
  }
}
const CollapsibleWrapper = props => {
  // eslint-disable-next-line
  const content = ({ openItem, onChange, autoToggle }) => (
    <CollapsibleItem openItem={openItem} onChange={onChange} autoToggle={autoToggle} {...props} />
  )

  return <CollapsibleContext.Consumer>{content}</CollapsibleContext.Consumer>
}
export default CollapsibleWrapper

CollapsibleItem.propTypes = {
  openItem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  itemKey: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  header: PropTypes.string,
  icon: PropTypes.string,
  labelInfo: PropTypes.node,
  autoToggle: PropTypes.bool,
  toggle: PropTypes.func
}
