export const CUSTOMER_CREATE_START = 'CUSTOMER_CREATE_START'
export const CUSTOMER_CREATE_SUCCESS = 'CUSTOMER_CREATE_SUCCESS'
export const CUSTOMER_CREATE_ERROR = 'CUSTOMER_CREATE_ERROR'

export const CUSTOMER_COMPUTE_CF_START = 'CUSTOMER_COMPUTE_CF_START'
export const CUSTOMER_COMPUTE_CF_SUCCESS = 'CUSTOMER_COMPUTE_CF_SUCCESS'
export const CUSTOMER_COMPUTE_CF_ERROR = 'CUSTOMER_COMPUTE_CF_ERROR'

export const CUSTOMER_CHECK_CF_START = 'CUSTOMER_CHECK_CF_START'
export const CUSTOMER_CHECK_CF_SUCCESS = 'CUSTOMER_CHECK_CF_SUCCESS'
export const CUSTOMER_CHECK_CF_ERROR = 'CUSTOMER_CHECK_CF_ERROR'

export const BLACKLIST_CF_START = 'BLACKLIST_CF_START'
export const BLACKLIST_CF_SUCCESS = 'BLACKLIST_CF_SUCCESS'
export const BLACKLIST_CF_ERROR = 'BLACKLIST_CF_ERROR'

export const PROCURATOR_CHECK_CF_START = 'PROCURATOR_CHECK_CF_START'
export const PROCURATOR_CHECK_CF_SUCCESS = 'PROCURATOR_CHECK_CF_SUCCESS'
export const PROCURATOR_CHECK_CF_ERROR = 'PROCURATOR_CHECK_CF_ERROR'

export const POST_DATA_REGISTRY_START = 'POST_DATA_REGISTRY_START'
export const POST_DATA_REGISTRY_SUCCESS = 'POST_DATA_REGISTRY_SUCCESS'
export const POST_DATA_REGISTRY_ERROR = 'POST_DATA_REGISTRY_ERROR'

export const GET_DATA_GATHERING_START = 'GET_DATA_GATHERING_START'
export const GET_DATA_GATHERING_SUCCESS = 'GET_DATA_GATHERING_SUCCESS'
export const GET_DATA_GATHERING_ERROR = 'GET_DATA_GATHERING_ERROR'

export const POST_DATA_GATHERING_START = 'POST_DATA_GATHERING_START'
export const POST_DATA_GATHERING_SUCCESS = 'POST_DATA_GATHERING_SUCCESS'
export const POST_DATA_GATHERING_ERROR = 'POST_DATA_GATHERING_ERROR'

export const GET_DATA_REGISTRY_START = 'GET_DATA_REGISTRY_START'
export const GET_DATA_REGISTRY_SUCCESS = 'GET_DATA_REGISTRY_SUCCESS'
export const GET_DATA_REGISTRY_ERROR = 'GET_DATA_REGISTRY_ERROR'

export const GET_SUMMARY_DATA_START = 'GET_SUMMARY_DATA_START'
export const GET_SUMMARY_DATA_SUCCESS = 'GET_SUMMARY_DATA_SUCCESS'
export const GET_SUMMARY_DATA_ERROR = 'GET_SUMMARY_DATA_ERROR'

export const GET_SUMMARY_DATA_FULL_START = 'GET_SUMMARY_DATA_FULL_START'
export const GET_SUMMARY_DATA_FULL_SUCCESS = 'GET_SUMMARY_DATA_FULL_SUCCESS'
export const GET_SUMMARY_DATA_FULL_ERROR = 'GET_SUMMARY_DATA_FULL_ERROR'

export const GET_DOCUMENTS_DATA_START = 'GET_DOCUMENTS_DATA_START'
export const GET_DOCUMENTS_DATA_SUCCESS = 'GET_DOCUMENTS_DATA_SUCCESS'
export const GET_DOCUMENTS_DATA_ERROR = 'GET_DOCUMENTS_DATA_ERROR'

export const POST_DATA_ALLEGATI_CF_START = 'POST_DATA_ALLEGATI_CF_START'
export const POST_DATA_ALLEGATI_CF_SUCCESS = 'POST_DATA_ALLEGATI_CF_SUCCESS'
export const POST_DATA_ALLEGATI_CF_ERROR = 'POST_DATA_ALLEGATI_CF_ERROR'

export const POST_DATA_ALLEGATI_DOC_START = 'POST_DATA_ALLEGATI_DOC_START'
export const POST_DATA_ALLEGATI_DOC_SUCCESS = 'POST_DATA_ALLEGATI_DOC_SUCCESS'
export const POST_DATA_ALLEGATI_DOC_ERROR = 'POST_DATA_ALLEGATI_DOC_ERROR'

export const GET_SERVIZI_DIGITALI_START = 'GET_SERVIZI_DIGITALI_START'
export const GET_SERVIZI_DIGITALI_SUCCESS = 'GET_SERVIZI_DIGITALI_SUCCESS'
export const GET_SERVIZI_DIGITALI_ERROR = 'GET_SERVIZI_DIGITALI_ERROR'

export const POST_SERVIZI_DIGITALI_START = 'POST_SERVIZI_DIGITALI_START'
export const POST_SERVIZI_DIGITALI_SUCCESS = 'POST_SERVIZI_DIGITALI_SUCCESS'
export const POST_SERVIZI_DIGITALI_ERROR = 'POST_SERVIZI_DIGITALI_ERROR'

export const POST_FATCA_START = 'POST_FATCA_START'
export const POST_FATCA_SUCCESS = 'POST_FATCA_SUCCESS'
export const POST_FATCA_ERROR = 'POST_FATCA_ERROR'

export const GET_LISTA_CLIENTI_START = 'GET_LISTA_CLIENTI_START'
export const GET_LISTA_CLIENTI_SUCCESS = 'GET_LISTA_CLIENTI_SUCCESS'
export const GET_LISTA_CLIENTI_ERROR = 'GET_LISTA_CLIENTI_ERROR'

export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const RESET_CUSTOMER_CHECK_CF = 'RESET_CUSTOMER_CHECK_CF'

export const GET_LISTA_CLIENTI_CORRELATI_START = 'GET_LISTA_CLIENTI_CORRELATI_START'
export const GET_LISTA_CLIENTI_CORRELATI_SUCCESS = 'GET_LISTA_CLIENTI_CORRELATI_SUCCESS'
export const GET_LISTA_CLIENTI_CORRELATI_ERROR = 'GET_LISTA_CLIENTI_CORRELATI_ERROR'

export const DOCUMENTS_PROCURATOR_SAVE_START = 'DOCUMENTS_PROCURATOR_SAVE_START'
export const DOCUMENTS_PROCURATOR_SAVE_SUCCESS = 'DOCUMENTS_PROCURATOR_SAVE_SUCCESS'
export const DOCUMENTS_PROCURATOR_SAVE_ERROR = 'DOCUMENTS_PROCURATOR_SAVE_ERROR'

export const SAVE_PROFESSIONAL_SUBJECT_START = 'SAVE_PROFESSIONAL_SUBJECT_START'
export const SAVE_PROFESSIONAL_SUBJECT_SUCCESS = 'SAVE_PROFESSIONAL_SUBJECT_SUCCESS'
export const SAVE_PROFESSIONAL_SUBJECT_ERROR = 'SAVE_PROFESSIONAL_SUBJECT_ERROR'

export const GET_PROFESSIONAL_SUBJECT_START = 'GET_PROFESSIONAL_SUBJECT_START'
export const GET_PROFESSIONAL_SUBJECT_SUCCESS = 'GET_PROFESSIONAL_SUBJECT_SUCCESS'
export const GET_PROFESSIONAL_SUBJECT_ERROR = 'GET_PROFESSIONAL_SUBJECT_ERROR'

export const DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_START = 'DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_START'
export const DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_SUCCESS = 'DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_SUCCESS'
export const DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_ERROR = 'DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_ERROR'

export const GET_SOGGETTI_CORRELATI_START = 'GET_SOGGETTI_CORRELATI_START'
export const GET_SOGGETTI_CORRELATI_SUCCESS = 'GET_SOGGETTI_CORRELATI_SUCCESS'
export const GET_SOGGETTI_CORRELATI_ERROR = 'GET_SOGGETTI_CORRELATI_ERROR'

export const GET_SOGGETTI_CORRELATI_GIURIDICHE_START = 'GET_SOGGETTI_CORRELATI_GIURIDICHE_START'
export const GET_SOGGETTI_CORRELATI_GIURIDICHE_SUCCESS = 'GET_SOGGETTI_CORRELATI_GIURIDICHE_SUCCESS'
export const GET_SOGGETTI_CORRELATI_GIURIDICHE_ERROR = 'GET_SOGGETTI_CORRELATI_GIURIDICHE_ERROR'

export const INVIA_RICHIESTA_SOGGETTI_CORRELATI_START = 'INVIA_RICHIESTA_SOGGETTI_CORRELATI_START'
export const INVIA_RICHIESTA_SOGGETTI_CORRELATI_SUCCESS = 'INVIA_RICHIESTA_SOGGETTI_CORRELATI_SUCCESS'
export const INVIA_RICHIESTA_SOGGETTI_CORRELATI_ERROR = 'INVIA_RICHIESTA_SOGGETTI_CORRELATI_ERROR'

export const INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_START = 'INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_START'
export const INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_SUCCESS =
  'INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_SUCCESS'
export const INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_ERROR = 'INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_ERROR'

export const MODULO_SOGGETTI_CORRELATI_START = 'MODULO_SOGGETTI_CORRELATI_START'
export const MODULO_SOGGETTI_CORRELATI_SUCCESS = 'MODULO_SOGGETTI_CORRELATI_SUCCESS'
export const MODULO_SOGGETTI_CORRELATI_ERROR = 'MODULO_SOGGETTI_CORRELATI_ERROR'

export const MODULO_SOGGETTI_GIA_CORRELATI_START = 'MODULO_SOGGETTI_GIA_CORRELATI_START'
export const MODULO_SOGGETTI_GIA_CORRELATI_SUCCESS = 'MODULO_SOGGETTI_GIA_CORRELATI_SUCCESS'
export const MODULO_SOGGETTI_GIA_CORRELATI_ERROR = 'MODULO_SOGGETTI_GIA_CORRELATI_ERROR'

export const MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_START = 'MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_START'
export const MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_SUCCESS = 'MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_SUCCESS'
export const MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_ERROR = 'MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_ERROR'

export const CHECK_HAS_PROCURATORE_START = 'CHECK_HAS_PROCURATORE_START'
export const CHECK_HAS_PROCURATORE_SUCCESS = 'CHECK_HAS_PROCURATORE_SUCCESS'
export const CHECK_HAS_PROCURATORE_ERROR = 'CHECK_HAS_PROCURATORE_ERROR'

export const GET_SERVIZI_DIGITALI_PG_START = 'GET_SERVIZI_DIGITALI_PG_START'
export const GET_SERVIZI_DIGITALI_PG_SUCCESS = 'GET_SERVIZI_DIGITALI_PG_SUCCESS'
export const GET_SERVIZI_DIGITALI_PG_ERROR = 'GET_SERVIZI_DIGITALI_PG_ERROR'

export const POST_SERVIZI_DIGITALI_PG_START = 'POST_SERVIZI_DIGITALI_PG_START'
export const POST_SERVIZI_DIGITALI_PG_SUCCESS = 'POST_SERVIZI_DIGITALI_PG_SUCCESS'
export const POST_SERVIZI_DIGITALI_PG_ERROR = 'POST_SERVIZI_DIGITALI_PG_ERROR'

export const DELETE_PROCURATORE_DATA = 'DELETE_PROCURATORE_DATA'
export const SET_BLACKLIST_PROCURATORE = 'SET_BLACKLIST_PROCURATORE'

export const GET_LISTA_CLIENTI_OW_START = 'GET_LISTA_CLIENTI_OW_START'
export const GET_LISTA_CLIENTI_OW_SUCCESS = 'GET_LISTA_CLIENTI_OW_SUCCESS'
export const GET_LISTA_CLIENTI_OW_ERROR = 'GET_LISTA_CLIENTI_OW_ERROR'

export const POST_OW_CUSTOMER_START = 'POST_OW_CUSTOMER_START'
export const POST_OW_CUSTOMER_SUCCESS = 'POST_OW_CUSTOMER_SUCCESS'
export const POST_OW_CUSTOMER_ERROR = 'POST_OW_CUSTOMER_ERROR'

export const POST_OW_CUSTOMERS_START = 'POST_OW_CUSTOMERS_START'
export const POST_OW_CUSTOMERS_SUCCESS = 'POST_OW_CUSTOMERS_SUCCESS'
export const POST_OW_CUSTOMERS_ERROR = 'POST_OW_CUSTOMERS_ERROR'

export const GET_DATA_REGISTRY_EXT_START = 'GET_DATA_REGISTRY_EXT_START'
export const GET_DATA_REGISTRY_EXT_SUCCESS = 'GET_DATA_REGISTRY_EXT_SUCCESS'
export const GET_DATA_REGISTRY_EXT_ERROR = 'GET_DATA_REGISTRY_EXT_ERROR'

export const GET_LIST_AML_START = 'GET_LIST_AML_START'
export const GET_LIST_AML_SUCCESS = 'GET_LIST_AML_SUCCESS'
export const GET_LIST_AML_ERROR = 'GET_LIST_AML_ERROR'

export const SET_LIST_AML_PAGE_SIZE = 'SET_LIST_AML_PAGE_SIZE'

export const GET_CUSTOMERS_AML_START = 'GET_CUSTOMERS_AML_START'
export const GET_CUSTOMERS_AML_SUCCESS = 'GET_CUSTOMERS_AML_SUCCESS'
export const GET_CUSTOMERS_AML_ERROR = 'GET_CUSTOMERS_AML_ERROR'

export const GET_ID_CARD_DATA_START = 'GET_ID_CARD_DATA_START'
export const GET_ID_CARD_DATA_SUCCESS = 'GET_ID_CARD_DATA_SUCCESS'
export const GET_ID_CARD_DATA_ERROR = 'GET_ID_CARD_DATA_ERROR'

export const GET_FEQ_ABILITATA_START = 'GET_FEQ_ABILITATA_START'
export const GET_FEQ_ABILITATA_SUCCESS = 'GET_FEQ_ABILITATA_SUCCESS'
export const GET_FEQ_ABILITATA_ERROR = 'GET_FEQ_ABILITATA_ERROR'

export const GET_PROMOTER_CUSTOMER_HIMSELF_START = 'GET_PROMOTER_CUSTOMER_HIMSELF_START'
export const GET_PROMOTER_CUSTOMER_HIMSELF_SUCCESS = 'GET_PROMOTER_CUSTOMER_HIMSELF_SUCCESS'
export const GET_PROMOTER_CUSTOMER_HIMSELF_ERROR = 'GET_PROMOTER_CUSTOMER_HIMSELF_ERROR'
