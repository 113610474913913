import React, { Component } from 'react'
import { Row, Col, Button } from 'react-materialize'
import PropTypes from 'prop-types'

class ModalVerifica extends Component {
  render() {
    const { title, text, close, interventoInSede, icon } = this.props
    return (
      <div className="onboarding-modale">
        <div className="onboarding-modale-content" style={{ position: 'relative' }}>
          <i className="material-icons cancella onboarding-modale-text__blu" onClick={close}>
            cancel
          </i>
          <Row>
            <Col s={12} className="center-align" style={{ marginTop: '-20px' }}>
              {title}
            </Col>
            <Col s={12} className="center-align" style={{ marginTop: '30px' }}>
              {icon}
            </Col>
            <Col s={12} style={{ paddingLeft: '50px', paddingRight: '50px' }}>
              {text}
            </Col>
            {!!interventoInSede && (
              <Col s={12} className="center-align" style={{ marginTop: '30px' }}>
                <Button className="btn custom-btn primary" onClick={interventoInSede}>
                  Richiedi intervento di sede
                </Button>
              </Col>
            )}
            {!interventoInSede && (
              <Col s={12} className="center-align" style={{ marginTop: '30px' }}>
                <Button className="btn custom-btn primary" onClick={close}>
                  Chiudi
                </Button>
              </Col>
            )}
          </Row>
        </div>
      </div>
    )
  }
}
export default ModalVerifica

ModalVerifica.propTypes = {
  title: PropTypes.object,
  text: PropTypes.object,
  close: PropTypes.func,
  interventoInSede: PropTypes.func,
  icon: PropTypes.object
}
