import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { MIFID_CONOSCENZA_PF, MIFID_CONOSCENZA_PG, MIFID_CONOSCENZA } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import ReadOnlyYesNo from '../../../../components/Inputs/ReadOnly/YesNo'
import { getValueDominioFromKey, getMifidByCategory, MIFID_CONST } from '../../utils'
import allFields from '../../fields'
import Title from '../../../../components/Title'

let MIFID_ACTIVE = null
let fields = null
let descRisposta = []

class ConoscenzaEsperienzaReview extends Component {
  constructor(props) {
    super(props)

    this.renderSelect = this.renderSelect.bind(this)
    this.getRisposta = this.getRisposta.bind(this)
    this.mapRisposta = this.mapRisposta.bind(this)
    this.getLabel = this.getLabel.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.CONOSCENZA)
    fields = allFields[2][MIFID_ACTIVE]
    descRisposta = Object.keys(fields)
      .filter(k => fields[k].descRisposta)
      .map(k => fields[k].templateId)
  }

  getLabel({ templateId }) {
    const { template } = this.props
    if (template) {
      return template.listaDomande.find(d => d.idDomanda === templateId).testoDomanda
    } else {
      return null
    }
  }

  getRisposta({ templateId }) {
    const {
      data: { listaDomande }
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    if (descRisposta.indexOf(domanda.idDomanda) > -1) {
      return domanda.listaRisposte[0].descRisposta
    }
    return domanda.listaRisposte[0].idRisposta
  }

  mapRisposta({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    const idRisposta = domanda.listaRisposte[0].idRisposta
    return template.listaDomande
      .find(d => d.idDomanda === templateId)
      .listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
  }

  renderSelect(field) {
    const {
      domini: { listaDomini }
    } = this.props
    const { dominio } = field
    return getValueDominioFromKey(listaDomini, dominio, this.getRisposta(field))
  }

  render() {
    const {
      data: { listaDomande, flagProfilatura },
      template,
      titoloMifid
    } = this.props
    const { strutturaInternaDomande } =
      template && MIFID_ACTIVE === MIFID_CONOSCENZA
        ? template.listaDomande.find(t => t.idDomanda === fields.STRUMENTI.templateId)
        : []
    const strutturaInternaDomandeVeroFalso =
      template && [MIFID_CONOSCENZA_PF, MIFID_CONOSCENZA_PG, MIFID_CONOSCENZA].includes(MIFID_ACTIVE)
        ? template.listaDomande.find(t => t.idDomanda === fields.VERO_FALSO.templateId).strutturaInternaDomande
        : ''
    const strutturaInternaDomandeSiNo =
      template && [MIFID_CONOSCENZA_PF, MIFID_CONOSCENZA_PG].includes(MIFID_ACTIVE)
        ? template.listaDomande.find(t => t.idDomanda === fields.SI_NO.templateId).strutturaInternaDomande
        : ''

    let strumenti = {}
    let veroFalsoTrue = []
    let siNoTrue = []
    if (strutturaInternaDomande) {
      strumenti = {
        RISPOSTE_CONOSCENZA: [
          strutturaInternaDomande.listaDominiRisposte[0].risposte.find(d => d.descRisposta === 'SI'),
          strutturaInternaDomande.listaDominiRisposte[0].risposte.find(d => d.descRisposta === 'NO'),
          strutturaInternaDomande.listaDominiRisposte[0].risposte.find(d => d.descRisposta === 'PARZ.')
        ],
        RISPOSTE_OPERAZIONI: [
          strutturaInternaDomande.listaDominiRisposte[1].risposte[0],
          strutturaInternaDomande.listaDominiRisposte[1].risposte[1],
          strutturaInternaDomande.listaDominiRisposte[1].risposte[2],
          strutturaInternaDomande.listaDominiRisposte[1].risposte[3]
        ],
        RISPOSTE_CHECK: strutturaInternaDomande.listaDominiRisposte[1].risposte[4]
      }
      veroFalsoTrue = strutturaInternaDomandeVeroFalso.listaDominiRisposte[0].risposte[0]
    }

    if (strutturaInternaDomandeVeroFalso) {
      veroFalsoTrue = strutturaInternaDomandeVeroFalso.listaDominiRisposte[0].risposte[0]
    }

    if (strutturaInternaDomandeSiNo) {
      siNoTrue = strutturaInternaDomandeSiNo.listaDominiRisposte[0].risposte[0]
    }
    // console.log(strumenti);
    // return (null)
    return (
      <CollapsibleItem
        header={template ? template.descSezione : ''}
        icon="keyboard_arrow_down"
        itemKey={MIFID_ACTIVE}
        labelInfo={this.props.accordionHeader}>
        <Row>
          <Col s={12}>
            <ReadOnlyYesNo label={titoloMifid} value={flagProfilatura ? 'SI' : 'NO'} />
          </Col>
        </Row>
        {/* VECCHIA MIFID CONOSCENZA */}
        {flagProfilatura && MIFID_ACTIVE === MIFID_CONOSCENZA && (
          <Fragment>
            <Row>
              <Col s={4}>
                <ReadOnlyInput
                  label={this.getLabel(fields.TITOLO_STUDIO)}
                  value={this.renderSelect(fields.TITOLO_STUDIO)}
                />
              </Col>
              <Col s={4}>
                <ReadOnlyInput
                  label={this.getLabel(fields.SETTORE_OCCUPAZIONE)}
                  value={this.renderSelect(fields.SETTORE_OCCUPAZIONE)}
                />
              </Col>
              <Col s={4}>
                <ReadOnlyInput
                  label={this.getLabel(fields.SETTORE_PROFESSIONE)}
                  value={this.renderSelect(fields.SETTORE_PROFESSIONE)}
                />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.ALTRI_CONTRATTI_CONSULENZA)}
                  value={this.mapRisposta(fields.ALTRI_CONTRATTI_CONSULENZA)}
                />
              </Col>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.ALTRI_CONTRATTI_COLLOCAMENTO)}
                  value={this.mapRisposta(fields.ALTRI_CONTRATTI_COLLOCAMENTO)}
                />
              </Col>
            </Row>
            <h2 className="collapsible-section-title">Strumenti</h2>
            <Row>
              <div className="instruments-table">
                <div className="instruments-header">
                  <div>
                    <div className="instruments-col-border" />
                  </div>
                  <div>
                    <div className="instruments-col-border">CONOSCENZA</div>
                  </div>
                  <div>
                    <div className="instruments-col-border">OPERAZIONI PER TRIMESTRE (ultimi 12/18 mesi)</div>
                  </div>
                </div>
                <div className="instruments-sub-header">
                  <div className="instruments-row-col-1">
                    <div className="instruments-col-border" />
                  </div>
                  <div className="instruments-row-col-2">
                    <div className="instruments-col-border">
                      <div>
                        <div>Si</div>
                        <div>No</div>
                        <div>Parz.</div>
                      </div>
                    </div>
                  </div>
                  <div className="instruments-row-col-3">
                    <div className="instruments-col-border">
                      <div>
                        <div>Più di 10</div>
                        <div>Tra 5 e 10</div>
                        <div>Meno di 5</div>
                        <div>No</div>
                        <div>&gt; 50%</div>
                      </div>
                    </div>
                  </div>
                </div>
                {strutturaInternaDomande &&
                  strutturaInternaDomande.domande.map(domanda => {
                    const listaRisp =
                      (listaDomande.find(d => d.idDomanda === domanda.idDomanda) || {}).listaRisposte || []
                    return (
                      <div className="instruments-row" key={`row_${domanda.idDomanda}`}>
                        <div className="instruments-row-col-1">
                          <div className="instruments-col-border">
                            <b>{domanda.testoDomanda}</b>
                            <div>{domanda.subTestoDomanda}</div>
                          </div>
                        </div>
                        <div className="instruments-row-col-2">
                          <div className="radio-horizontal instruments-col-border">
                            {strumenti.RISPOSTE_CONOSCENZA.map(el => (
                              <div
                                className="radio"
                                key={`strumenti-row-conoscenza-${el.idRisposta}-${domanda.idDomanda}`}>
                                <input
                                  disabled
                                  className="with-gap"
                                  type="radio"
                                  id={`risposte-conoscenza-${el.descRisposta.toLowerCase()}-${domanda.idDomanda}`}
                                  checked={listaRisp.some(r => r.idRisposta === el.idRisposta)}
                                />
                                <label
                                  htmlFor={`risposte-conoscenza-${el.descRisposta.toLowerCase()}-${domanda.idDomanda}`}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="instruments-row-col-3">
                          <div className="radio-horizontal instruments-col-border">
                            {strumenti.RISPOSTE_OPERAZIONI.map(el => (
                              <div
                                className="radio"
                                key={`strumenti-row-operazioni-${el.idRisposta}-${domanda.idDomanda}`}>
                                <input
                                  disabled
                                  className="with-gap"
                                  type="radio"
                                  id={`risposte-operazioni-${el.descRisposta.toLowerCase()}-${domanda.idDomanda}`}
                                  checked={listaRisp.some(r => r.idRisposta === el.idRisposta)}
                                />
                                <label
                                  htmlFor={`risposte-operazioni-${el.descRisposta.toLowerCase()}-${domanda.idDomanda}`}
                                />
                              </div>
                            ))}
                            <div className="check">
                              <input
                                className="filled-in vertical"
                                type="checkbox"
                                disabled
                                id={`risposte-operazioni-gt50-${domanda.idDomanda}`}
                                checked={listaRisp.some(r => r.idRisposta === strumenti.RISPOSTE_CHECK.idRisposta)}
                              />
                              <label htmlFor={`risposte-operazioni-gt50-${domanda.idDomanda}`} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </Row>
            <h2 className="collapsible-section-title">Vero o falso?</h2>
            <Row>
              {strutturaInternaDomandeVeroFalso &&
                strutturaInternaDomandeVeroFalso.domande &&
                strutturaInternaDomandeVeroFalso.domande.map(domanda => {
                  const listaRisp =
                    (listaDomande.find(d => d.idDomanda === domanda.idDomanda) || {}).listaRisposte || []
                  const yes = listaRisp.some(r => r.idRisposta === veroFalsoTrue.idRisposta)
                  return (
                    <Col s={12} className="true-false-row" key={`col_${domanda.idDomanda}`}>
                      <div className="true-false-question disabled">
                        <p className="title">{domanda.testoDomanda}</p>
                        <p className="subtitle">{domanda.subTestoDomanda}</p>
                        {listaRisp.length > 0 && yes && (
                          <div className="box-input yes-selected">
                            <button type="button" className="yes-button" disabled>
                              V
                            </button>
                          </div>
                        )}
                        {listaRisp.length > 0 && !yes && (
                          <div className="box-input no-selected">
                            <button type="button" className="no-button" disabled>
                              F
                            </button>
                          </div>
                        )}
                        {listaRisp.length === 0 && <div className="box-input" />}
                      </div>
                    </Col>
                  )
                })}
            </Row>
          </Fragment>
        )}
        {/* NUOVA MIFID CONOSCENZA */}
        {flagProfilatura && [MIFID_CONOSCENZA_PF, MIFID_CONOSCENZA_PG].includes(MIFID_ACTIVE) && (
          <Fragment>
            <Row>
              <Col s={4}>
                <ReadOnlyInput
                  label={this.getLabel(fields.TITOLO_STUDIO)}
                  value={this.renderSelect(fields.TITOLO_STUDIO)}
                />
              </Col>
              <Col s={4}>
                <ReadOnlyInput
                  label={this.getLabel(fields.SETTORE_OCCUPAZIONE)}
                  value={this.renderSelect(fields.SETTORE_OCCUPAZIONE)}
                />
              </Col>
              {MIFID_ACTIVE === MIFID_CONOSCENZA_PF && (
                <Col s={4}>
                  <ReadOnlyInput
                    label={this.getLabel(fields.SETTORE_PROFESSIONE)}
                    value={this.renderSelect(fields.SETTORE_PROFESSIONE)}
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.ALTRI_CONTRATTI_CONSULENZA)}
                  value={this.mapRisposta(fields.ALTRI_CONTRATTI_CONSULENZA)}
                />
              </Col>
              <Col s={12}>
                <ReadOnlyYesNo
                  label={this.getLabel(fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE)}
                  value={this.mapRisposta(fields.ALTRI_CONTRATTI_COMMERCIALIZZAZIONE)}
                />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.RISCHIO_RENDIMENTO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.RISCHIO_RENDIMENTO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.DIVERSIFICAZIONE_RISCHIO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.DIVERSIFICAZIONE_RISCHIO)} />
              </Col>
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.ORIZZONTE_TEMPORALE)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.ORIZZONTE_TEMPORALE)} />
              </Col>
            </Row>
            <h2 className="collapsible-section-title">Vero o falso?</h2>
            <Row>
              {strutturaInternaDomandeVeroFalso &&
                strutturaInternaDomandeVeroFalso.domande &&
                strutturaInternaDomandeVeroFalso.domande.map(domanda => {
                  const listaRisp =
                    (listaDomande.find(d => d.idDomanda === domanda.idDomanda) || {}).listaRisposte || []
                  const yes = listaRisp.some(r => r.idRisposta === veroFalsoTrue.idRisposta)
                  return (
                    <Col s={12} className="true-false-row" key={`col_${domanda.idDomanda}`}>
                      <div className="true-false-question disabled">
                        <p className="title">{domanda.testoDomanda}</p>
                        <p className="subtitle">{domanda.subTestoDomanda}</p>
                        {listaRisp.length > 0 && yes && (
                          <div className="box-input yes-selected">
                            <button type="button" className="yes-button" disabled>
                              V
                            </button>
                          </div>
                        )}
                        {listaRisp.length > 0 && !yes && (
                          <div className="box-input no-selected">
                            <button type="button" className="no-button" disabled>
                              F
                            </button>
                          </div>
                        )}
                        {listaRisp.length === 0 && <div className="box-input" />}
                      </div>
                    </Col>
                  )
                })}
            </Row>
            <h2 className="collapsible-section-title">Si o no?</h2>
            <div>
              <Title>{this.getLabel(fields.SI_NO)}</Title>
            </div>
            <Row>
              {strutturaInternaDomandeSiNo &&
                strutturaInternaDomandeSiNo.domande &&
                strutturaInternaDomandeSiNo.domande.map(domanda => {
                  const listaRisp =
                    (listaDomande.find(d => d.idDomanda === domanda.idDomanda) || {}).listaRisposte || []
                  const yes = listaRisp.some(r => r.idRisposta === siNoTrue.idRisposta)
                  return (
                    <Col s={12} className="true-false-row" key={`col_${domanda.idDomanda}`}>
                      <div className="true-false-question disabled">
                        <p className="title">{domanda.testoDomanda}</p>
                        <p className="subtitle">{domanda.subTestoDomanda}</p>
                        {listaRisp.length > 0 && yes && (
                          <div className="box-input yes-selected">
                            <button type="button" className="yes-button si-btn" disabled>
                              SI
                            </button>
                          </div>
                        )}
                        {listaRisp.length > 0 && !yes && (
                          <div className="box-input no-selected">
                            <button type="button" className="no-button no-btn" disabled>
                              NO
                            </button>
                          </div>
                        )}
                        {listaRisp.length === 0 && <div className="box-input" />}
                      </div>
                    </Col>
                  )
                })}
            </Row>
            <Row>
              <Col s={12}>
                <Title>{this.getLabel(fields.INVESTIMENTO)}</Title>
              </Col>
              <Col s={12}>
                <ReadOnlyInput value={this.mapRisposta(fields.INVESTIMENTO)} />
              </Col>
            </Row>
          </Fragment>
        )}
      </CollapsibleItem>
    )
  }
}

ConoscenzaEsperienzaReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  domini: PropTypes.object,
  /* template: PropTypes.shape({
    descSezione: PropTypes.object,
    listaDomande: PropTypes.array
  }), */
  template: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  titoloMifid: PropTypes.string
}

export default ConoscenzaEsperienzaReview
