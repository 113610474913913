import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import { bindActionCreators } from 'redux'
import { CONTATTI, RESIDENZA } from '../../../../store/modules/forms/constants'
import Contatti from './ContattiComponent'
import { loadDefaultFields, notBelongToDominio, loadContatti } from '../../utils'
import allFields from '../../fields'
import { getDataRegistry } from '../../../../store/modules/customers/actions'
import { validationVerifyOtp } from '../../../../store/modules/validation/actions'

const fields = allFields[1][CONTATTI]
const fieldsResidenza = allFields[1][RESIDENZA]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const {
    NOME,
    COGNOME,
    NAZIONE,
    PROVINCIA,
    COMUNE,
    CAP,
    FLAG_INDIRIZZO,
    PREFISSO,
    FLAG_CELLULARE,
    CELLULARE,
    FLAG_EMAIL,
    EMAIL
  } = fields
  const { domini = {} } = props
  const errors = {}
  if (values[FLAG_INDIRIZZO.id]) {
    if (!!values[NOME.id] && !new RegExp(NOME.validazione).test(values[NOME.id])) {
      errors[NOME.id] = 'Il nome può contenere solo caratteri alfabetici'
    }
    if (!!values[COGNOME.id] && !new RegExp(COGNOME.validazione).test(values[COGNOME.id])) {
      errors[COGNOME.id] = 'Il cognome può contenere solo caratteri alfabetici'
    }
    if (!!values[NAZIONE.id] && notBelongToDominio(domini, NAZIONE.dominio, values[NAZIONE.id])) {
      errors[NAZIONE.id] = 'Nessun risultato trovato'
    }
    if (!!values[PROVINCIA.id] && notBelongToDominio(domini, PROVINCIA.dominio, values[PROVINCIA.id])) {
      errors[PROVINCIA.id] = 'Nessun risultato trovato'
    }
    if (
      !!values[PROVINCIA.id] &&
      !!values[COMUNE.id] &&
      notBelongToDominio(domini, COMUNE.dominio, values[COMUNE.id])
    ) {
      errors[COMUNE.id] = 'Nessun risultato trovato'
    }
    if (!!values[CAP.id] && notBelongToDominio(domini, CAP.dominio, values[CAP.id])) {
      errors[CAP.id] = 'Nessun risultato trovato'
    }
  }
  if (!!values[PREFISSO.id] && notBelongToDominio(domini, PREFISSO.dominio, values[PREFISSO.id])) {
    errors[PREFISSO.id] = 'Nessun risultato trovato'
  }
  if (!!values[CELLULARE.id] && !new RegExp(CELLULARE.validazione).test(values[CELLULARE.id])) {
    errors[CELLULARE.id] = 'Numero di cellulare non valido'
  } else if (values[CELLULARE.id] && !values[FLAG_CELLULARE.id]) {
    errors[FLAG_CELLULARE.id] = 'La certificazione del campo Cellulare è obbligatoria'
  }
  if (!!values[EMAIL.id] && !new RegExp(EMAIL.validazione).test(values[EMAIL.id])) {
    errors[EMAIL.id] = "L'indirizzo e-mail non è valido"
  } else if (values[EMAIL.id] && !values[FLAG_EMAIL.id]) {
    errors[FLAG_EMAIL.id] = 'La certificazione della campo e-mail è obbligatoria'
  }

  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataRegistry,
      validationVerifyOtp
    },
    dispatch
  )

const mapStateToProps = state => {
  return {
    initialValues: {
      ...defaultFields,
      ...loadContatti(state.customers.dataRegistry, fields)
    },
    customerData: state.customers.customer,
    formValues: getFormValues(CONTATTI)(state),
    formValuesResidenza: getFormValues(RESIDENZA)(state),
    formErrors: getFormSyncErrors(CONTATTI)(state),
    fields,
    fieldsResidenza,
    currentInitial: loadContatti(state.customers.dataRegistry, fields),
    isDirty: isDirty(CONTATTI)(state),
    createOtpRequest: state.validation.createOtpRequest,
    verifyOtpRequest: state.validation.verifyOtpRequest,
    otpStatus: state.validation.otpStatus,
    isFetching: state.customers.postDataRegistryRequest.fetching,
    apiError: state.customers.postDataRegistryRequest.error,
    dominioRequest: state.dominio.dominioRequest
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: CONTATTI,
    validate,
    enableReinitialize: true, // you need to add this property
    shouldError: () => isDirty(CONTATTI)
  })(Contatti)
)
