import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import {
  MIFID_OBIETTIVI_PF,
  MIFID_OBIETTIVI_PG,
  MIFID_OBIETTIVI_LIGHT
} from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import AccordionHeader from '../../../../components/AccordionHeader'
import SurveyHr from '../../../../components/SurveyHr'
import SurveyRadio from '../../../../components/SurveyRadio'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import { setValidators, setRisposte, isSaveDisabled, getFormValues } from '../mifidValidationUtils'
import SubRadioCheckbox from '../SubRadioCheckbox'

let MIFID_ACTIVE = null
let NEW_MIFID = true

let fields = null
const fieldsLight = allFields[2][MIFID_OBIETTIVI_LIGHT]

class ObiettiviInvestimento extends React.Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, values: {}, subRadioCheckboxError: false }
    this.submit = this.submit.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.OBIETTIVI)
    fields = allFields[2][MIFID_ACTIVE]
    // Imposto i validators in modo da poter fare i controlli (sul padre) tra sezioni diverse
    setValidators(this.props.template.listaDomande)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.state.values)
      this.props.resetForm(this.props.clienteProfessionale && !NEW_MIFID ? MIFID_OBIETTIVI_LIGHT : MIFID_ACTIVE)
      this.setState({ saved: true, values: {} })
    }
    setRisposte(getFormValues(this.props.currentFormValues))
  }

  submit(values) {
    const { onSave } = this.props
    const submittedValues = Object.keys(values).reduce(
      (res, cur) => ({ ...res, [cur.replace('field_', '')]: values[cur] }),
      {}
    )
    this.setState({ values })
    onSave(
      Object.keys(submittedValues).reduce((res, cur) => {
        if (cur.includes('_')) {
          const [idDomanda, idRisposta] = cur.split('_')
          const curRisp = res.find(r => r.idDomanda === idDomanda)
          let listaRisposte = []
          if (submittedValues[cur]) {
            listaRisposte = [{ idRisposta }]
          }
          if (curRisp) {
            listaRisposte = [...curRisp.listaRisposte, ...listaRisposte]
            return [...res.filter(r => r.idDomanda !== idDomanda), { idDomanda, listaRisposte }]
          }
          return [...res, { idDomanda, listaRisposte }]
        } else {
          return [...res, { idDomanda: cur, listaRisposte: [{ idRisposta: submittedValues[cur] }] }]
        }
      }, [])
    )
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      change,
      invalid,
      status,
      formValues,
      template: { descSezione, listaDomande },
      isFetching,
      clienteProfessionale,
      currentFormValues
    } = this.props
    const { OBIETTIVI, RISCHIO, ORIZZONTE_TEMPORALE } = clienteProfessionale && !NEW_MIFID ? fieldsLight : fields
    if (listaDomande === undefined) {
      return
    }

    return (
      <CollapsibleItem
        header={descSezione}
        itemKey={clienteProfessionale && !NEW_MIFID ? MIFID_OBIETTIVI_LIGHT : MIFID_ACTIVE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {/* VECCHIA MIFID OBIETTIVI */}
          {!NEW_MIFID && (
            <React.Fragment>
              <Row>
                <Col s={12}>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === OBIETTIVI.templateId)}
                    onChangeCallback={() => {
                      change(`field_${RISCHIO.templateId}`, '')
                      change(`field_${ORIZZONTE_TEMPORALE.templateId}`, '')
                    }}
                    formValues={formValues}
                  />
                </Col>
              </Row>
              <SurveyHr />
              <Row>
                <Col s={12}>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === RISCHIO.templateId)}
                    disabled={!formValues[`field_${OBIETTIVI.templateId}`]}
                    onChangeCallback={() => change(`field_${ORIZZONTE_TEMPORALE.templateId}`, '')}
                    formValues={formValues}
                  />
                </Col>
              </Row>
              <SurveyHr />
              <Row>
                <Col s={12}>
                  <SurveyRadio
                    disabled={!formValues[`field_${RISCHIO.templateId}`]}
                    template={listaDomande.find(d => d.idDomanda === ORIZZONTE_TEMPORALE.templateId)}
                    radioClass="big-radio row"
                    formValues={formValues}
                  />
                </Col>
              </Row>
              <SurveyHr hideHr />
            </React.Fragment>
          )}
          {/* NUOVA MIFID PF/PG/PD */}
          {NEW_MIFID && (
            <Row>
              <Col s={12}>
                <Row>
                  <Col
                    s={6}
                    className={
                      this.props.fieldsWithErrors.includes(fields.OBIETTIVI_INVESTIMENTI.templateId)
                        ? 'fieldHasErrors'
                        : ''
                    }>
                    <SurveyRadio
                      template={listaDomande.find(d => d.idDomanda === fields.OBIETTIVI_INVESTIMENTI.templateId)}
                      formValues={formValues}
                    />
                  </Col>
                  <Col s={6} className="center">
                    <img
                      className="responsive-img marginTop20"
                      src={listaDomande.find(d => d.idDomanda === fields.OBIETTIVI_INVESTIMENTI.templateId).pathImage}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.ESIGENZE_LIQUIDITA.templateId) ? 'fieldHasErrors' : ''
                }>
                <SurveyRadio
                  template={listaDomande.find(d => d.idDomanda === fields.ESIGENZE_LIQUIDITA.templateId)}
                  formValues={formValues}
                  onChangeCallback={event => {
                    // controllo aggiuntivo dato che dobbiamo aspettare il rendering del campo e non possiamo fare il trigger da SurveyRadio
                    if (event.currentTarget && event.currentTarget.value) {
                      let template = listaDomande.find(
                        d => d.idDomanda === fields.LIMITAZIONI_DISINVESTIMENTO.templateId
                      )
                      if (template.flagDipendenza && Array.isArray(template.listaDipendenze)) {
                        const res = template.listaDipendenze.some(
                          dip =>
                            event.currentTarget.value === '' + dip.idRispostaDipendenza &&
                            dip.tipoDipendenza === 'disable'
                        )
                        if (res) {
                          change(`field_${fields.LIMITAZIONI_DISINVESTIMENTO.templateId}`, '')
                        }
                      }
                    }
                  }}
                />
              </Col>
              <Col
                s={12}
                className={
                  this.props.fieldsWithErrors.includes(fields.LIMITAZIONI_DISINVESTIMENTO.templateId)
                    ? 'fieldHasErrors'
                    : ''
                }>
                <SurveyRadio
                  template={listaDomande.find(d => d.idDomanda === fields.LIMITAZIONI_DISINVESTIMENTO.templateId)}
                  formValues={formValues}
                  showSubTesto
                />
              </Col>
              {/* ASSENTE SU PD */}
              {[MIFID_OBIETTIVI_PF, MIFID_OBIETTIVI_PG].includes(MIFID_ACTIVE) && (
                <React.Fragment>
                  <Col
                    s={12}
                    className={
                      this.props.fieldsWithErrors.includes(fields.PERCENTUALE_DISINVESTIMENTO.templateId)
                        ? 'fieldHasErrors'
                        : ''
                    }>
                    <SurveyRadio
                      template={listaDomande.find(d => d.idDomanda === fields.PERCENTUALE_DISINVESTIMENTO.templateId)}
                      formValues={formValues}
                      showSubTesto
                    />
                  </Col>
                </React.Fragment>
              )}
              {/* SOLO PER PF */}
              {MIFID_ACTIVE === MIFID_OBIETTIVI_PF && (
                <React.Fragment>
                  <Col
                    s={12}
                    className={
                      this.props.fieldsWithErrors.includes(fields.ESTENSIONI_SUCCESSORIE.templateId)
                        ? 'fieldHasErrors'
                        : ''
                    }>
                    <SurveyRadio
                      template={listaDomande.find(d => d.idDomanda === fields.ESTENSIONI_SUCCESSORIE.templateId)}
                      formValues={formValues}
                    />
                  </Col>
                </React.Fragment>
              )}
              {/* ASSENTE SULLA PD */}
              {[MIFID_OBIETTIVI_PF, MIFID_OBIETTIVI_PG].includes(MIFID_ACTIVE) && (
                <Col
                  s={12}
                  className={
                    this.props.fieldsWithErrors.includes(fields.OBBLIGAZIONI_PERPETUE.templateId)
                      ? 'fieldHasErrors'
                      : ''
                  }>
                  <SurveyRadio
                    template={listaDomande.find(d => d.idDomanda === fields.OBBLIGAZIONI_PERPETUE.templateId)}
                    formValues={formValues}
                    showSubTesto
                  />
                </Col>
              )}

              <Col s={12}>
                <SubRadioCheckbox
                  template={listaDomande.find(d => d.idDomanda === fields.ESIGENZE_ASSICURATIVE.templateId)}
                  formValues={{ ...currentFormValues, ...formValues, ...this.state.values }}
                  changeCallback={this.props.change}
                  validate={hasError => this.setState({ subRadioCheckboxError: hasError })}
                />
                {this.state.subRadioCheckboxError && (
                  <Row className="subRadioCheckboxError">
                    <Col s={12}>
                      <small>Selezionare almeno una esigenza assicurativa</small>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          )}
          <ButtonSubmit
            disabled={
              submitting ||
              !isDirty ||
              invalid ||
              isFetching ||
              isSaveDisabled(listaDomande) ||
              this.state.subRadioCheckboxError
            }
          />
        </form>
      </CollapsibleItem>
    )
  }
}

ObiettiviInvestimento.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  template: PropTypes.object,
  onSave: PropTypes.func,
  change: PropTypes.func,
  clienteProfessionale: PropTypes.bool,
  currentFormValues: PropTypes.object,
  fieldsWithErrors: PropTypes.array
}
export default ObiettiviInvestimento
