import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, getFormSyncErrors, getFormInitialValues, isDirty } from 'redux-form'
import { postDataGathering, inBlacklistCF } from '../../../store/modules/customers/actions'
import {
  postDataGatheringPGActionCreator,
  postPFAnagrafica
} from '../../../store/modules/personaGiuridica/prospect/actions'
import { postAnagraficaPFActionCreator } from '../../../store/modules/personaGiuridica/client/actions'
import { DATA_GATHERING } from '../../../store/modules/forms/constants'
import { dominio } from '../../../store/modules/dominio/actions'
import { validationCreateOtp, validationVerifyOtp } from '../../../store/modules/validation/actions'
import { getPratica } from '../../../store/modules/pratica/actions'
import allFields from '../fields'
import { notBelongToDominio, getInitialValues } from '../utils'
import DataGathering from './DataGatheringComponent'
import { goToWhenSaved } from '../../../store/modules/dirtyForm/actions'
import { getIdProspectByIdProcess } from '../../../store/modules/utility/actions'

const fieldsPF = allFields[4][DATA_GATHERING]
const fieldsPG = allFields[5][DATA_GATHERING]
const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const { EMAIL, FLAG_EMAIL, PREFISSO, CELLULARE, FLAG_CELLULARE } = fieldsPF

  if (!!values[PREFISSO.id] && notBelongToDominio(domini, PREFISSO.dominio, values[PREFISSO.id])) {
    errors[PREFISSO.id] = 'Nessun risultato trovato'
  }
  if (!!values[CELLULARE.id] && !new RegExp(CELLULARE.validazione).test(values[CELLULARE.id])) {
    errors[CELLULARE.id] = 'Numero di cellulare non valido'
  } else if (values[CELLULARE.id] && !values[FLAG_CELLULARE.id]) {
    errors[FLAG_CELLULARE.id] = 'La certificazione del campo Cellulare è obbligatoria'
  } else if (!values[CELLULARE.id]) {
    errors[CELLULARE.id] = 'Numero di cellulare obbligatorio'
  }
  if (!!values[EMAIL.id] && !new RegExp(EMAIL.validazione).test(values[EMAIL.id])) {
    errors[EMAIL.id] = "L'indirizzo e-mail non è valido"
  } else if (values[EMAIL.id] && !values[FLAG_EMAIL.id]) {
    errors[FLAG_EMAIL.id] = 'La certificazione della campo e-mail è obbligatoria'
  } else if (!values[EMAIL.id]) {
    errors[EMAIL.id] = 'Indirizzo e-mail obbligatorio'
  }
  if (props.isProspectPersonaGiuridica) {
    const {
      ATTIVITA_ESERCITATA_PG,
      // CODICE_FISCALE_PG,
      // PARTITA_IVA_PG,
      RAGIONE_SOCIALE_PG,
      NAZIONE_PG,
      PROVINCIA_PG,
      CITTA_PG,
      SEDE_LEGALE_PG,
      DATA_COSTITUZIONE_PG,
      CODICE_ATECO_PG,
      FORMA_ASSOCIATIVA_PG,
      CAP_PG,
      CITTA_ESTERO_PG,
      PROVINCIA_PG_COSTITUZIONE,
      CITTA_PG_COSTITUZIONE,
      CELLULARE_PG,
      EMAIL_PG
    } = fieldsPG
    if (!values[ATTIVITA_ESERCITATA_PG.id]) {
      errors[ATTIVITA_ESERCITATA_PG.id] = 'Nessuna attività esercitata selezionata'
    }
    /* if (
      !values[PARTITA_IVA_PG.id] &&
      (!values[CODICE_FISCALE_PG.id] || !new RegExp(CODICE_FISCALE_PG.validazione).test(values[CODICE_FISCALE_PG.id]))
    ) {
      errors[CODICE_FISCALE_PG.id] = 'Codice fiscale non valido'
    }
    if (
      !values[CODICE_FISCALE_PG.id] &&
      (!values[PARTITA_IVA_PG.id] || !new RegExp(PARTITA_IVA_PG.validazione).test(values[PARTITA_IVA_PG.id]))
    ) {
      errors[PARTITA_IVA_PG.id] = 'Partita iva non è valida'
    } */
    if (
      !values[RAGIONE_SOCIALE_PG.id] ||
      !new RegExp(RAGIONE_SOCIALE_PG.validazione).test(values[RAGIONE_SOCIALE_PG.id])
    ) {
      errors[RAGIONE_SOCIALE_PG.id] = 'Ragione sociale non valida'
    }
    if (!values[CELLULARE_PG.id]) {
      errors[CELLULARE_PG.id] = 'Il campo è obbligatorio'
    }
    if (!values[EMAIL_PG.id]) {
      errors[EMAIL_PG.id] = 'Il campo è obbligatorio'
    }

    if (!values[SEDE_LEGALE_PG.id]) {
      errors[SEDE_LEGALE_PG.id] = 'Il campo è obbligatorio'
    } else if (!new RegExp(SEDE_LEGALE_PG.validazione).test(values[SEDE_LEGALE_PG.id])) {
      errors[SEDE_LEGALE_PG.id] = 'Il campo sede legale può contenere solo caratteri alfanumerici'
    }
    if (!values[DATA_COSTITUZIONE_PG.id]) {
      errors[DATA_COSTITUZIONE_PG.id] = 'Il campo è obbligatorio'
    }
    if (!values[CODICE_ATECO_PG.id]) {
      errors[CODICE_ATECO_PG.id] = 'Il campo è obbligatorio'
    }
    if (!values[ATTIVITA_ESERCITATA_PG.id]) {
      errors[ATTIVITA_ESERCITATA_PG.id] = 'Il campo è obbligatorio'
    }
    if (!values[FORMA_ASSOCIATIVA_PG.id]) {
      errors[FORMA_ASSOCIATIVA_PG.id] = 'Il campo è obbligatorio'
    }
    if (!values[PROVINCIA_PG_COSTITUZIONE.id]) {
      errors[PROVINCIA_PG_COSTITUZIONE.id] = 'Il campo è obbligatorio'
    }
    if (!values[CITTA_PG_COSTITUZIONE.id]) {
      errors[CITTA_PG_COSTITUZIONE.id] = 'Il campo è obbligatorio'
    }
    if (!values[NAZIONE_PG.id]) {
      errors[NAZIONE_PG.id] = 'Il campo è obbligatorio'
    }
    if (values[NAZIONE_PG.id] === 'ITA') {
      if (!values[PROVINCIA_PG.id]) {
        errors[PROVINCIA_PG.id] = 'Il campo è obbligatorio'
      }
      if (!values[CITTA_PG.id]) {
        errors[CITTA_PG.id] = 'Il campo è obbligatorio'
      }
      if (!values[CAP_PG.id]) {
        errors[CAP_PG.id] = 'Il campo è obbligatorio'
      }
    } else if (values[NAZIONE_PG.id] !== 'ITA') {
      if (!values[CITTA_ESTERO_PG.id]) {
        errors[CITTA_ESTERO_PG.id] = 'Il campo è obbligatorio'
      }
    }
  }

  return errors
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const bindedActionCreators = {
    dominio,
    validationCreateOtp,
    validationVerifyOtp,
    getPratica,
    goToWhenSaved,
    inBlacklistCF,
    getIdProspectByIdProcess,
    postPFAnagrafica,
    postAnagraficaPFActionCreator
  }
  // Override post action
  if (ownProps.isProspectPersonaGiuridica) {
    bindedActionCreators.postDataGathering = postDataGatheringPGActionCreator
  } else {
    bindedActionCreators.postDataGathering = postDataGathering
  }
  return bindActionCreators(bindedActionCreators, dispatch)
}
const mapStateToProps = (state, ownProps) => {
  let commonBindedState = {
    customer: state.customers.customer,
    summaryData: state.customers.summaryData,
    domini: state.dominio.domini,
    dominioRequest: state.dominio.dominioRequest,
    praticaRequest: state.pratica.praticaRequest,
    pratica: state.pratica,
    pDob: state.utility.pDob,
    getIdProspectByIdProcessRequest: state.utility.getIdProspectByIdProcessRequest,

    currentInitial: getFormInitialValues(DATA_GATHERING)(state),
    formValues: getFormValues(DATA_GATHERING)(state),
    formErrors: getFormSyncErrors(DATA_GATHERING)(state),
    createOtpRequest: state.validation.createOtpRequest,
    verifyOtpRequest: state.validation.verifyOtpRequest,
    otpStatus: state.validation.otpStatus,
    flagCustomerIsBlacklist: state.customers.flagCustomerIsBlacklist,
    blacklistCFRequest: state.customers.blacklistCFRequest,
    isProspectPGAvailable: state.prospectPersonaGiuridica.isProspectPGAvailable
  }
  if (ownProps.isProspectPersonaGiuridica) {
    let HA_DOMICILIO = {
      id: state.prospectPersonaGiuridica.dataGathering.flagDomicile,
      defaultValue: null,
      label: null
    }
    let PGData = {
      ...state.prospectPersonaGiuridica.dataGathering,
      mobileNumber: state.anagraficaPersonaGiuridicaCliente.anagraficaPF.phoneNumber,
      emailAddress: state.anagraficaPersonaGiuridicaCliente.anagraficaPF.mail,
      internationalPrefixCellNumberCode: state.anagraficaPersonaGiuridicaCliente.anagraficaPF.phonePrefixCode,
      flagEmailCertified: state.anagraficaPersonaGiuridicaCliente.anagraficaPF.isMailVerified,
      flagMobileNumberCertified: state.anagraficaPersonaGiuridicaCliente.anagraficaPF.isPhoneVerified
    }
    commonBindedState = {
      ...commonBindedState,
      fields: { ...fieldsPG, HA_DOMICILIO: HA_DOMICILIO },
      dataGathering: state.prospectPersonaGiuridica.dataGathering,
      getDataGatheringRequest: state.prospectPersonaGiuridica.getDataGatheringRequest,
      postDataGatheringRequest: state.prospectPersonaGiuridica.postDataGatheringRequest,
      initialValues: getInitialValues(fieldsPG, PGData),
      postPFAnagraficaRequest: state.anagraficaPersonaGiuridicaCliente.postPFAnagraficaRequest,
      idPhysicalPersonReference: state.prospectPersonaGiuridica.summaryData.idPhysicalPersonReference
    }
  } else {
    commonBindedState = {
      ...commonBindedState,
      fields: fieldsPF,
      dataGathering: state.customers.dataGathering,
      postDataGatheringRequest: state.customers.postDataGatheringRequest,
      initialValues: getInitialValues(fieldsPF, state.customers.dataGathering)
    }
  }
  return commonBindedState
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: DATA_GATHERING,
    validate,
    shouldError: () => isDirty(DATA_GATHERING)
  })(DataGathering)
)
