import * as actionTypes from './constants'
import { SURVEY_AML_SAVE_SUCCESS, SURVEY_MIFID_SAVE_SUCCESS, SURVEY_MIFID_SAVE_ERROR } from '../survey/constants'
import { PRATICA_GET_SUCCESS } from '../pratica/constants'
import { VALIDATION_VERIFY_OTP_SUCCESS } from '../validation/constants'
import * as dataSC from '../../../static-data/data-soggetti-correlati.json'

const apiRequests = {
  customerCreateRequest: {
    fetching: false,
    error: null
  },
  getDataSummaryRequest: {
    fetching: false,
    error: null
  },
  getDataSummaryFullRequest: {
    fetching: false,
    error: null
  },
  getDataRegistryRequest: {
    fetching: false,
    error: null
  },
  getDataGatheringRequest: {
    fetching: false,
    error: null
  },
  getDocumentsDataRequest: {
    fetching: false,
    error: null
  },
  postDataAllegatiCFRequest: {
    fetching: false,
    query: null,
    error: null
  },
  postDataAllegatiDOCRequest: {
    fetching: false,
    dataSuccessFlags: null,
    error: null
  },
  postDataRegistryRequest: {
    fetching: false,
    query: null,
    error: null
  },
  postDataGatheringRequest: {
    fetching: false,
    query: null,
    error: null
  },
  postDataDocumentoRequest: {
    fetching: false,
    error: null
  },
  getServiziDigitaliRequest: {
    fetching: false,
    error: null
  },
  postServiziDigitaliRequest: {
    fetching: false,
    error: null
  },
  postFatcaRequest: {
    fetching: false,
    error: null
  },
  getClientiListRequest: {
    fetching: false,
    error: null
  },
  soggettiCorrelatiRequest: {
    fetching: false,
    error: null
  },
  inviaRichiestaRequest: {
    fetching: false,
    error: null,
    success: false
  },
  inviaRichiestaGiuridicheRequest: {
    fetching: false,
    error: null,
    success: false
  },
  moduloRequest: {
    fetching: false,
    error: null
  },
  soggettiGiaCorrelatiRequest: {
    fetching: false,
    error: null
  },
  getClientiCorrelatiRequest: {
    fetching: false,
    error: null
  },
  blacklistCFRequest: {
    fetching: false,
    error: null
  },
  checkCFRequest: {
    fetching: false,
    error: null
  },
  computeCFRequest: {
    fetching: false,
    error: null
  },
  checkHasProcuratoreRequest: {
    fetching: false,
    error: null
  },
  getClientiOWListRequest: {
    fetching: false,
    error: null
  },
  postOWCustomerRequest: {
    fetching: false,
    error: null
  },
  postOWCustomersRequest: {
    fetching: false,
    error: null
  },
  getDataRegistryExtRequest: {
    fetching: false,
    error: null
  },
  getListAMLRequest: {
    fetching: false,
    error: null
  },
  getCustomersAMLRequest: {
    fetching: false,
    error: null
  },
  getIdCardDataRequest: {
    fetching: false,
    error: null
  },
  getFeqAbilitataRequest: {
    fetching: false,
    error: null
  },
  getPromoterCustomerHimselfRequest: {
    fetching: false,
    error: null
  }
}

const initialState = {
  temporaneoCodiceFiscale: '',
  codiceFiscale: '',
  customers: [],
  hasProcuratore: false,
  customer: {},
  dataGathering: {},
  dataRegistry: {},
  dataFatca: {},
  dataFromCF: {},
  documentsData: null,
  summaryData: null,
  summaryDataFull: {},
  serviziData: {},
  dataRegistryStart: false,
  fatcaSent: false,
  isUserAvailable: false,
  formUlterioriDati: false,
  flagCustomerIsBlacklist: '',
  // statusBlack:'',
  flagIsBlacklist: '',
  flagDocAttorney: '',
  downloadDocument: '',
  documentsDataProcuratore: {
    flagIsBlacklist: ''
  },
  professionalSubject: {
    isProfessionalSubjectDone: '',
    professionalSubjectType: ''
  },
  isEmail: false,
  clientiCorrelatiList: [],
  soggettiCorrelatiListData: [],
  soggettiGiaCorrelatiListData: [],
  totalpage: '',
  soggettiCorrelatiListDataGiuridiche: [],
  soggettiGiaCorrelatiListDataGiuridiche: [],
  listAML: [],
  totListAML: '',
  queryListAML: {
    querySearch: '',
    amlStatus: [0, 1],
    itemInPage: 20,
    pageNum: 1
  },
  listAMLPageSize: 20,
  OWcustomers0: [],
  OWcustomers1: [],
  OWcustomers2: [],
  customersAML: [],
  idCardData: {},
  feqAbilitata: false,
  isPromoterCustomerHimself: false
}

export default function(state = { ...initialState, ...apiRequests }, action) {
  switch (action.type) {
    case actionTypes.RESET_CUSTOMER_CHECK_CF:
      return {
        ...state,
        isUserAvailable: false
      }
    case actionTypes.CUSTOMER_COMPUTE_CF_START:
      return {
        ...state,
        computeCFRequest: {
          fetching: true
        }
      }
    case actionTypes.CUSTOMER_COMPUTE_CF_SUCCESS:
      return {
        ...state,
        codiceFiscale: action.cf,
        computeCFRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.CUSTOMER_COMPUTE_CF_ERROR:
      return {
        ...state,
        computeCFRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.CUSTOMER_CHECK_CF_START:
      return {
        ...state,
        idPhysicalPersonReference: action.idPhysicalPersonReference,
        checkCFRequest: {
          fetching: true
        }
      }
    case actionTypes.CUSTOMER_CHECK_CF_SUCCESS:
      return {
        ...state,
        isUserAvailable: action.isUserAvailable === '0',
        formUlterioriDati: action.formUlterioriDati,
        checkCFRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.CUSTOMER_CHECK_CF_ERROR:
      return {
        ...state,
        isUserAvailable: ((action.error || {}).data || {}).code === 'CUSTOMER_230',
        checkCFRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.PROCURATOR_CHECK_CF_START:
      return {
        ...state,
        flagIsBlacklist: '',
        checkCFRequest: {
          fetching: true
        }
      }
    case actionTypes.PROCURATOR_CHECK_CF_SUCCESS:
      return {
        ...state,
        flagIsBlacklist: action.flagIsBlacklist,
        checkCFRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PROCURATOR_CHECK_CF_ERROR:
      return {
        ...state,
        flagIsBlacklist: '',
        checkCFRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.BLACKLIST_CF_START:
      return {
        ...state,
        blacklistCFRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.BLACKLIST_CF_SUCCESS:
      return {
        ...state,
        statusBlack: action.statusBlack,
        dataRegistry: {
          ...state.dataRegistry,
          flagCustomerIsBlacklist: action.flagCustomerIsBlacklist
        },
        temporaneoCodiceFiscale: action.taxCode,
        blacklistCFRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.BLACKLIST_CF_ERROR:
      return {
        ...state,
        flagCustomerIsBlacklist: '',
        blacklistCFRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_LISTA_CLIENTI_CORRELATI_START:
      return {
        ...state,
        clientiCorrelatiList: [],
        getClientiCorrelatiRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_LISTA_CLIENTI_CORRELATI_SUCCESS:
      return {
        ...state,
        clientiCorrelatiList: action.idCliente !== 4 ? dataSC.searchResult : [],
        getClientiCorrelatiRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_LISTA_CLIENTI_CORRELATI_ERROR:
      return {
        ...state,
        getClientiCorrelatiRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.RESET_CUSTOMER:
      return {
        ...state,
        ...initialState,
        customer: state.customer
      }
    case actionTypes.CUSTOMER_CREATE_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.data
        },
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        },
        customerCreateRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.CUSTOMER_CREATE_START:
      return Object.assign({}, state, {
        customerCreateRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.CUSTOMER_CREATE_ERROR:
      return Object.assign({}, state, {
        customerCreateRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.GET_DATA_REGISTRY_SUCCESS:
      delete action.dataFatca.idIncarico
      const customerDataCF = Object.assign(action.dataCF, action.dataRegistry, action.dataFatca)
      const customerDataRegistry = Object.assign({}, state.customer, customerDataCF)
      return Object.assign({}, state, {
        customer: { ...customerDataRegistry },
        dataRegistry: {
          ...(action.dataCF.dataRegistry || {})
        },
        dataFatca: action.dataFatca,
        getDataRegistryRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.GET_DATA_GATHERING_START:
      return {
        ...state,
        getDataGatheringRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DATA_GATHERING_SUCCESS:
      return {
        ...state,
        dataGathering: {
          ...action.dataGathering,
          // flagMobileNumberCertified:true
          flagCertificazioneCellulare: true,
          flagCertificazioneEmail: true
        },
        getDataGatheringRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DATA_GATHERING_ERROR:
      return {
        ...state,
        getDataGatheringRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.GET_DATA_REGISTRY_START:
      return Object.assign({}, state, {
        getDataRegistryRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_DATA_REGISTRY_ERROR:
      return Object.assign({}, state, {
        getDataRegistryRequest: {
          fetching: false,
          error: action.error
        }
      })

    case PRATICA_GET_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          codiceFiscale: action.data.pratica.intestatari[0].codiceFiscale,
          idCliente: action.data.pratica.intestatari[0].id,
          idIncarico: action.idPratica,
          idProcurator: action.data.pratica.idProcurator
        }
      }
    case actionTypes.GET_SUMMARY_DATA_START:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_SUMMARY_DATA_SUCCESS:
      return {
        ...state,
        summaryData: {
          ...state.summaryData,
          ...action.data
        },
        getDataSummaryRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_SUMMARY_DATA_ERROR:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: false,
          error: action.error
        }
      }
    case VALIDATION_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        }
      }
    case actionTypes.GET_SUMMARY_DATA_FULL_SUCCESS:
      const summaryDataFull = Object.assign({}, state.summaryDataFull, action.data)
      return Object.assign({}, state, {
        summaryDataFull: { ...summaryDataFull },
        getDataSummaryFullRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.GET_SUMMARY_DATA_FULL_START:
      return Object.assign({}, state, {
        getDataSummaryFullRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_SUMMARY_DATA_FULL_ERROR:
      return Object.assign({}, state, {
        getDataSummaryFullRequest: {
          fetching: false,
          error: action.error
        }
      })

    case actionTypes.DELETE_PROCURATORE_DATA:
      return {
        ...state,
        documentsDataProcuratore: null
      }
    case actionTypes.SET_BLACKLIST_PROCURATORE:
      return {
        ...state,
        documentsDataProcuratore: { flagIsBlacklist: action.flagIsBlacklist }
      }
    case actionTypes.GET_DOCUMENTS_DATA_SUCCESS:
      const customerDocumentsData = Object.assign({}, state.documentsData, action.dataIdCard)
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.dataCF
        },
        documentsData: { ...customerDocumentsData },
        getDocumentsDataRequest: {
          fetching: false,
          error: null
        },
        dataFromCF: action.dataCF !== null,
        documentsDataProcuratore: { ...action.dataProcuratore }
      }
    case actionTypes.GET_DOCUMENTS_DATA_START:
      return Object.assign({}, state, {
        getDocumentsDataRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_DOCUMENTS_DATA_ERROR:
      return Object.assign({}, state, {
        getDocumentsDataRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.POST_DATA_ALLEGATI_CF_START:
      return Object.assign({}, state, {
        postDataAllegatiCFRequest: {
          fetching: true,
          query: action.query,
          error: null
        }
      })
    case actionTypes.POST_DATA_ALLEGATI_CF_SUCCESS:
      return {
        ...state,
        postDataAllegatiCFRequest: {
          ...state.postDataAllegatiCFRequest,
          query: null,
          fetching: false,
          error: null,
          dataPreviousPosted: action.dataPreviousPosted
        },
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        },
        documentsData: {
          ...state.documentsData,
          ...(state.postDataAllegatiCFRequest.query && state.postDataAllegatiCFRequest.query.listaAllegati
            ? state.postDataAllegatiCFRequest.query.listaAllegati.reduce((res, cur) => {
                const split = cur.tipoAllegato.split('_')
                return {
                  ...res,
                  ['img' + split[0] + split[1].charAt(0) + split[1].slice(1).toLowerCase()]: cur.file
                }
              }, {})
            : {})
        }
      }
    case actionTypes.POST_DATA_ALLEGATI_CF_ERROR:
      return Object.assign({}, state, {
        postDataAllegatiCFRequest: {
          fetching: false,
          query: null,
          error: action.error
        }
      })
    case actionTypes.POST_DATA_ALLEGATI_DOC_START:
      return Object.assign({}, state, {
        postDataAllegatiDOCRequest: {
          fetching: true,
          query: action.query,
          error: null
        }
      })
    case actionTypes.POST_DATA_ALLEGATI_DOC_SUCCESS:
      return {
        ...state,
        postDataAllegatiDOCRequest: {
          ...state.postDataAllegatiDOCRequest,
          query: null,
          fetching: false,
          error: null,
          dataPreviousPosted: action.dataPreviousPosted
        },
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        },
        documentsData: {
          ...state.documentsData,
          ...(state.postDataAllegatiDOCRequest.query && state.postDataAllegatiDOCRequest.query.listaAllegati
            ? state.postDataAllegatiDOCRequest.query.listaAllegati.reduce((res, cur) => {
                const split = cur.tipoAllegato.split('_')
                return {
                  ...res,
                  ['img' + split[0] + split[1].charAt(0) + split[1].slice(1).toLowerCase()]: cur.file
                }
              }, {})
            : {})
        }
      }
    case actionTypes.POST_DATA_ALLEGATI_DOC_ERROR:
      return Object.assign({}, state, {
        postDataAllegatiDOCRequest: {
          fetching: false,
          query: null,
          error: action.error
        }
      })
    case actionTypes.GET_SERVIZI_DIGITALI_SUCCESS:
      const serviziData = Object.assign({}, state.serviziData, action.data)
      return Object.assign({}, state, {
        serviziData: { ...serviziData },
        getServiziDigitaliRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.GET_SERVIZI_DIGITALI_START:
      return Object.assign({}, state, {
        getServiziDigitaliRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_SERVIZI_DIGITALI_ERROR:
      return Object.assign({}, state, {
        getServiziDigitaliRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.POST_SERVIZI_DIGITALI_START:
      return Object.assign({}, state, {
        postServiziDigitaliRequest: {
          fetching: true,
          error: null
        },
        dataRegistryStart: false
      })
    case actionTypes.POST_SERVIZI_DIGITALI_SUCCESS:
      return {
        ...state,
        postServiziDigitaliRequest: {
          fetching: false,
          error: null
        },
        dataRegistryStart: true,
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        }
      }
    case actionTypes.POST_SERVIZI_DIGITALI_ERROR:
      return Object.assign({}, state, {
        postServiziDigitaliRequest: {
          fetching: false,
          error: action.error
        }
      })

    // servizi digitali pg
    case actionTypes.GET_SERVIZI_DIGITALI_PG_SUCCESS:
      const serviziDataPg = Object.assign({}, state.serviziData, action.data)
      return Object.assign({}, state, {
        serviziData: { ...serviziDataPg },
        getServiziDigitaliRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.GET_SERVIZI_DIGITALI_PG_START:
      return Object.assign({}, state, {
        getServiziDigitaliRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_SERVIZI_DIGITALI_PG_ERROR:
      return Object.assign({}, state, {
        getServiziDigitaliRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.POST_SERVIZI_DIGITALI_PG_START:
      return Object.assign({}, state, {
        postServiziDigitaliRequest: {
          fetching: true,
          error: null
        },
        dataRegistryStart: false
      })
    case actionTypes.POST_SERVIZI_DIGITALI_PG_SUCCESS:
      return {
        ...state,
        postServiziDigitaliRequest: {
          fetching: false,
          error: null
        },
        dataRegistryStart: true,
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        }
      }
    case actionTypes.POST_SERVIZI_DIGITALI_PG_ERROR:
      return Object.assign({}, state, {
        postServiziDigitaliRequest: {
          fetching: false,
          error: action.error
        }
      })
    // servizi digitali pg

    case actionTypes.POST_DATA_REGISTRY_START: {
      return {
        ...state,
        postDataRegistryRequest: {
          ...state.postDataRegistryRequest,
          fetching: true,
          query: action.query,
          error: null
        }
      }
    }
    case actionTypes.POST_DATA_REGISTRY_SUCCESS: {
      return {
        ...state,
        postDataRegistryRequest: {
          ...state.postDataRegistryRequest,
          fetching: false
        },
        dataRegistry: {
          ...state.dataRegistry,
          ...state.postDataRegistryRequest.query
        },
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        }
      }
    }
    case actionTypes.POST_DATA_REGISTRY_ERROR: {
      if (action.error.data.code.indexOf('CONSISTENCY_') !== -1) {
        return {
          ...state,
          dataRegistry: {
            ...state.dataRegistry,
            ...action.dataRegistry
          },
          summaryData: {
            ...state.summaryData,
            ...action.summaryLight
          },
          postDataRegistryRequest: {
            ...state.postDataRegistryRequest,
            fetching: false,
            error: null
          }
        }
      } else {
        return {
          ...state,
          postDataRegistryRequest: {
            ...state.postDataRegistryRequest,
            fetching: false,
            error: action.error
          }
        }
      }
    }

    case actionTypes.POST_DATA_GATHERING_START: {
      return {
        ...state,
        postDataGatheringRequest: {
          ...state.postDataGatheringRequest,
          fetching: true,
          query: action.query,
          error: null
        }
      }
    }
    case actionTypes.POST_DATA_GATHERING_SUCCESS: {
      return {
        ...state,
        postDataGatheringRequest: {
          ...state.postDataGatheringRequest,
          fetching: false
        },
        dataGathering: {
          ...state.dataGathering,
          ...state.postDataGatheringRequest.query
        }
        // summaryData: {
        //   ...state.summaryData,
        //   ...action.summaryLight
        // }
      }
    }
    case actionTypes.POST_DATA_GATHERING_ERROR: {
      return {
        ...state,
        postDataGatheringRequest: {
          ...state.postDataGatheringRequest,
          fetching: false,
          error: action.error
        }
      }
    }
    case actionTypes.POST_FATCA_START:
      return Object.assign({}, state, {
        postFatcaRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.POST_FATCA_SUCCESS:
      return {
        ...state,
        postFatcaRequest: {
          ...state.postFatcaRequest,
          fetching: false,
          error: null
        },
        fatcaSent: true,
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        }
      }
    case actionTypes.POST_FATCA_ERROR:
      return Object.assign({}, state, {
        postFatcaRequest: {
          fetching: false,
          error: action.error
        }
      })
    case SURVEY_AML_SAVE_SUCCESS:
    case SURVEY_MIFID_SAVE_SUCCESS:
      return {
        ...state,
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        }
      }
    case SURVEY_MIFID_SAVE_ERROR:
      return {
        ...state,
        summaryData: {
          ...state.summaryData,
          ...action.summaryLight
        }
      }
    case actionTypes.GET_LISTA_CLIENTI_START:
      return Object.assign({}, state, {
        getClientiListRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_LISTA_CLIENTI_ERROR:
      return Object.assign({}, state, {
        getClientiListRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.GET_LISTA_CLIENTI_SUCCESS:
      const customers = action.customerListData
      // return {
      //   ...state,

      // }
      return Object.assign({}, state, {
        customers,
        getClientiListRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.GET_PROFESSIONAL_SUBJECT_START:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_PROFESSIONAL_SUBJECT_SUCCESS:
      return {
        ...state,
        professionalSubject: action.professionalSubject,
        getDataSummaryRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_PROFESSIONAL_SUBJECT_ERROR:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.SAVE_PROFESSIONAL_SUBJECT_START:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SAVE_PROFESSIONAL_SUBJECT_SUCCESS:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: false,
          error: null
        },
        summaryData: action.summaryLight
      }
    case actionTypes.SAVE_PROFESSIONAL_SUBJECT_ERROR:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_START:
      return Object.assign({}, state, {
        procuratorRequest: {
          fetching: true,
          error: null,
          success: false
        }
      })
    case actionTypes.DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_SUCCESS:
      return {
        ...state,
        downloadDocument: action.downloadDocument,
        procuratorRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_ERROR:
      return Object.assign({}, state, {
        procuratorRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.DOCUMENTS_PROCURATOR_SAVE_START:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.DOCUMENTS_PROCURATOR_SAVE_SUCCESS:
      return {
        ...state,
        flagDocAttorney: action.flagDocAttorney,
        getDataSummaryRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.DOCUMENTS_PROCURATOR_SAVE_ERROR:
      return {
        ...state,
        getDataSummaryRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.GET_SOGGETTI_CORRELATI_START:
      return Object.assign({}, state, {
        soggettiCorrelatiListData: [],
        soggettiCorrelatiRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_SOGGETTI_CORRELATI_ERROR:
      return Object.assign({}, state, {
        soggettiCorrelatiRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.GET_SOGGETTI_CORRELATI_SUCCESS:
      return Object.assign({}, state, {
        soggettiCorrelatiListData: action.soggettiCorrelatiListData,
        totalpage: action.soggettiCorrelatiListData,
        soggettiCorrelatiRequest: {
          fetching: false,
          error: null
        }
      })

    case actionTypes.GET_SOGGETTI_CORRELATI_GIURIDICHE_START:
      return Object.assign({}, state, {
        soggettiCorrelatiListDataGiuridiche: [],
        soggettiCorrelatiRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_SOGGETTI_CORRELATI_GIURIDICHE_ERROR:
      return Object.assign({}, state, {
        soggettiCorrelatiRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.GET_SOGGETTI_CORRELATI_GIURIDICHE_SUCCESS:
      return Object.assign({}, state, {
        // soggettiCorrelatiListDataGiuridiche: action.soggettiCorrelatiListData,   // WARNING! DATI MOCKATI!
        soggettiCorrelatiListDataGiuridiche: action.soggettiCorrelatiListDataGiuridiche,
        totalpage: action.soggettiCorrelatiListDataGiuridiche,

        soggettiCorrelatiRequest: {
          fetching: false,
          error: null
        }
      })

    case actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_START:
      return Object.assign({}, state, {
        inviaRichiestaRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_ERROR:
      return Object.assign({}, state, {
        inviaRichiestaRequest: {
          fetching: false,
          error: action.error,
          success: false
        }
      })
    case actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_SUCCESS:
      return Object.assign({}, state, {
        isEmail: action.isEmail,
        inviaRichiestaRequest: {
          fetching: false,
          error: null,
          success: true
        }
      })

    case actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_START:
      return Object.assign({}, state, {
        inviaRichiestaGiuridicheRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_ERROR:
      return Object.assign({}, state, {
        inviaRichiestaGiuridicheRequest: {
          fetching: false,
          error: action.error,
          success: false
        }
      })
    case actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_SUCCESS:
      return Object.assign({}, state, {
        isEmail: action.isEmail,
        inviaRichiestaGiuridicheRequest: {
          fetching: false,
          error: null,
          success: true
        }
      })

    case actionTypes.MODULO_SOGGETTI_CORRELATI_START:
      return Object.assign({}, state, {
        moduloRequest: {
          fetching: true,
          error: null,
          success: false
        }
      })
    case actionTypes.MODULO_SOGGETTI_CORRELATI_ERROR:
      return Object.assign({}, state, {
        moduloRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.MODULO_SOGGETTI_CORRELATI_SUCCESS:
      return Object.assign({}, state, {
        file: action.file,
        moduloRequest: {
          fetching: false,
          error: null
        }
      })

    case actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_START:
      return Object.assign({}, state, {
        soggettiGiaCorrelatiListData: [],
        soggettiGiaCorrelatiRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_ERROR:
      return Object.assign({}, state, {
        soggettiGiaCorrelatiRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_SUCCESS:
      return Object.assign({}, state, {
        soggettiGiaCorrelatiListData: action.soggettiGiaCorrelatiListData,
        soggettiGiaCorrelatiRequest: {
          fetching: false,
          error: null
        }
      })

    case actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_START:
      return Object.assign({}, state, {
        soggettiGiaCorrelatiListDataGiuridiche: [],
        soggettiGiaCorrelatiRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_ERROR:
      return Object.assign({}, state, {
        soggettiGiaCorrelatiRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_SUCCESS:
      return Object.assign({}, state, {
        soggettiGiaCorrelatiListDataGiuridiche: action.soggettiGiaCorrelatiListDataGiuridiche,
        // soggettiCorrelatiListDataGiuridiche: [],                                                   // WARNING! DATI MOCKATI!
        soggettiGiaCorrelatiRequest: {
          fetching: false,
          error: null
        }
      })

    case actionTypes.CHECK_HAS_PROCURATORE_SUCCESS:
      return Object.assign({}, state, {
        checkHasProcuratoreRequest: {
          fetching: false,
          error: null
        },
        hasProcuratore: action.hasProcuratore
      })

    case actionTypes.CHECK_HAS_PROCURATORE_START:
      return Object.assign({}, state, {
        checkHasProcuratoreRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.CHECK_HAS_PROCURATORE_ERROR:
      return Object.assign({}, state, {
        checkHasProcuratoreRequest: {
          fetching: false,
          error: action.error,
          success: false
        }
      })
    case actionTypes.GET_LISTA_CLIENTI_OW_START:
      return Object.assign({}, state, {
        getClientiOWListRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_LISTA_CLIENTI_OW_ERROR:
      return Object.assign({}, state, {
        getClientiOWListRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.GET_LISTA_CLIENTI_OW_SUCCESS:
      return Object.assign({}, state, {
        [action.customerType]: action.customerOWListData,
        getClientiOWListRequest: {
          fetching: false,
          error: null,
          [action.customerType]: true,
          dataLength: action.customerOWListData.length
        }
      })
    case actionTypes.POST_OW_CUSTOMER_START:
      return Object.assign({}, state, {
        postOWCustomerRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.POST_OW_CUSTOMER_ERROR:
      return Object.assign({}, state, {
        postOWCustomerRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.POST_OW_CUSTOMER_SUCCESS:
      return Object.assign({}, state, {
        postOWCustomerRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.POST_OW_CUSTOMERS_START:
      return Object.assign({}, state, {
        postOWCustomersRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.POST_OW_CUSTOMERS_ERROR:
      return Object.assign({}, state, {
        postOWCustomersRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.POST_OW_CUSTOMERS_SUCCESS:
      return Object.assign({}, state, {
        postOWCustomersRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.GET_DATA_REGISTRY_EXT_START:
      return Object.assign({}, state, {
        getDataRegistryExtRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_DATA_REGISTRY_EXT_SUCCESS:
      const customerDataRegistryExt = Object.assign({}, state.customer, action.dataRegistry)
      return Object.assign({}, state, {
        customer: { ...customerDataRegistryExt },
        dataRegistry: {
          ...(customerDataRegistryExt.dataRegistry || {})
        },
        dataFatca: {},
        getDataRegistryExtRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.GET_DATA_REGISTRY_EXT_ERROR:
      return Object.assign({}, state, {
        getDataRegistryExtRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.GET_LIST_AML_START:
      return {
        ...state,
        getListAMLRequest: {
          fetching: true,
          error: null
        },
        queryListAML: {
          ...state.queryListAML,
          ...action.query
        }
      }
    case actionTypes.GET_LIST_AML_SUCCESS:
      return {
        ...state,
        totListAML: action.totListAML,
        listAML: action.listAML,
        getListAMLRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_LIST_AML_ERROR:
      return {
        ...state,
        getListAMLRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.SET_LIST_AML_PAGE_SIZE:
      return {
        ...state,
        listAMLPageSize: action.listAMLPageSize
      }
    case actionTypes.GET_CUSTOMERS_AML_START:
      return {
        ...state,
        getCustomersAMLRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_CUSTOMERS_AML_SUCCESS:
      return {
        ...state,
        customersAML: action.data,
        getCustomersAMLRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_CUSTOMERS_AML_ERROR:
      return {
        ...state,
        getCustomersAMLRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_ID_CARD_DATA_START:
      return Object.assign({}, state, {
        getIdCardDataRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.GET_ID_CARD_DATA_SUCCESS:
      return Object.assign({}, state, {
        idCardData: action.idCardData,
        getIdCardDataRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.GET_ID_CARD_DATA_ERROR:
      return Object.assign({}, state, {
        getIdCardDataRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.GET_FEQ_ABILITATA_START:
      return {
        ...state,
        feqAbilitata: false,
        getFeqAbilitataRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_FEQ_ABILITATA_SUCCESS:
      return {
        ...state,
        feqAbilitata: action.feqAbilitata,
        getFeqAbilitataRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_FEQ_ABILITATA_ERROR:
      return {
        ...state,
        getFeqAbilitataRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_PROMOTER_CUSTOMER_HIMSELF_START:
      return {
        ...state,
        isPromoterCustomerHimself: false,
        getPromoterCustomerHimselfRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_PROMOTER_CUSTOMER_HIMSELF_SUCCESS:
      return {
        ...state,
        isPromoterCustomerHimself: action.data,
        getPromoterCustomerHimselfRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_PROMOTER_CUSTOMER_HIMSELF_ERROR:
      return {
        ...state,
        getPromoterCustomerHimselfRequest: {
          fetching: false,
          error: action.error
        }
      }
  }
  return state
}
