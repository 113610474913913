import '../../../api/apiConfig'
import axios from 'axios'
import * as actionTypes from './constants'

const reqDeroghe = idCustomer => {
  return axios.get(`/v2/deroga/${idCustomer}`)
}

export const getDeroghe = idCustomer => {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DEROGHE_START
    })
    try {
      const [listaDeroghe] = await Promise.all([reqDeroghe(idCustomer)])
      dispatch({
        type: actionTypes.GET_DEROGHE_SUCCESS,
        listaDeroghe: listaDeroghe.data.listaDeroghe
      })
    } catch (errorApi) {
      dispatch({
        type: actionTypes.GET_DEROGHE_ERROR,
        errorApi
      })
    }
  }
}

const reqPostDeroga = body => {
  return axios.post(`/v2/deroga/`, body)
}

export const postDeroga = body => {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_DEROGA_START
    })
    try {
      const [respPostDeroga] = await Promise.all([reqPostDeroga(body)])
      dispatch({
        type: actionTypes.SET_DEROGA_SUCCESS,
        respPostDeroga
      })
    } catch (errorApi) {
      dispatch({
        type: actionTypes.SET_DEROGA_ERROR,
        errorApi
      })
    }
  }
}

export const saveIntestatari = payload => {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_DEROGA_INTESTATARI_START
    })
    try {
      dispatch({
        type: actionTypes.SET_DEROGA_INTESTATARI_SUCCESS,
        intestatari: payload
      })
    } catch (errorApi) {
      dispatch({
        type: actionTypes.SET_DEROGA_INTESTATARI_ERROR,
        errorApi
      })
    }
  }
}
