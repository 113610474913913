import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery'
import {
  checkStatusService,
  getDominioRequest,
  getSectionStatus,
  getSubSectionToBeCompleted,
  loadAmlData,
  parseDataRegistry
} from '../utils'
import dataSB from '../../../static-data/data-servizio-base.json'
import { Collapsible, CollapsibleItem } from '../../../components/Collapsible'
import { Row } from 'react-materialize'
import AntiRiciclaggio from './AntiRiciclaggio'
import AntiRiciclaggioGiuridiche from './AntiRiciclaggioGiuridiche'
import InformazioniPersonali from './InformazioniPersonali'
import InformazioniPersonaliGiuridiche from './InformazioniPersonaliGiuridiche'
import Residenza from './Residenza'
import Contatti from './Contatti'
import SoggettiCorrelati from './SoggettiCorrelati/index'
import SoggettiCorrelatiGiuridiche from './SoggettiCorrelatiGiuridiche/index'
import Fatca from './Fatca'
import FatcaGiuridiche from './FatcaGiuridiche'
import ConsensiPrivacy from './ConsensiPrivacy'
import ConsensiPrivacyGiuridiche from './ConsensiPrivacyGiuridiche'
import * as forms from '../../../store/modules/forms/constants'
import fields from '../fields'

const infoSoggettiCorrelati = dataSB.censimento_anagrafica.soggettiCorrelati

const getOpenCollapsible = (summaryData, current, reduxForms) => {
  const next = getSubSectionToBeCompleted(summaryData, 1, current, reduxForms)
  return forms[Object.keys(forms).find(f => forms[f] === next)]
}

export class CensimentoAnagrafico extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openCollapsible: props.accordionKey || getOpenCollapsible(props.summaryData),
      loadingComplete: false,
      waitingDomini: false,
      fetchingData: true,
      isAntiriciclaggio: false
    }

    this.handlePostDataRegistry = this.handlePostDataRegistry.bind(this)
    this.handleSaveFatca = this.handleSaveFatca.bind(this)
    this.handleSaveAml = this.handleSaveAml.bind(this)
    this.handleSaveAmlPg = this.handleSaveAmlPg.bind(this)
    this.handleOpenCollapsibleChange = this.handleOpenCollapsibleChange.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
    this.updateDomini = this.updateDomini.bind(this)
    this.getSectionStatus = this.getSectionStatus.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.soggettiCorrelatiToggleHandler = this.soggettiCorrelatiToggleHandler.bind(this)
    this.soggettiCorrelatiGiuridicheToggleHandler = this.soggettiCorrelatiGiuridicheToggleHandler.bind(this)
    this.handleSaveFatcaPG = this.handleSaveFatcaPG.bind(this)
  }

  componentDidMount() {
    const {
      customer: { idCliente, idIncarico, codiceFiscale },
      summaryData: { statusEndPoint },
      getDataRegistry,
      fetchSurveyAml,
      fetchSurveyAmlPg,
      getDocumentsData,
      isProspectPersonaGiuridica,
      getDataGatheringPGActionCreator,
      getDocumentIdPGActionCreator,
      getFatcaPG
    } = this.props

    const { getDataSummaryLightPGActionCreator, customer, idPratica } = this.props

    if (isProspectPersonaGiuridica) {
      getDataSummaryLightPGActionCreator(customer.idCliente, idPratica)
    }

    if (statusEndPoint && !isProspectPersonaGiuridica) {
      getDataRegistry(
        codiceFiscale,
        idCliente,
        idIncarico,
        checkStatusService(statusEndPoint, 'dataRegistry'),
        checkStatusService(statusEndPoint, 'fatcacrs')
      )
      fetchSurveyAml(idCliente, idIncarico, checkStatusService(statusEndPoint, 'aml'))
    } else if (isProspectPersonaGiuridica) {
      // checkStatusService(statusEndPoint, 'fatcacrs')
      // fetchSurveyAmlPg(idCliente, idIncarico, checkStatusService(statusEndPoint, 'aml'))
      fetchSurveyAmlPg(idCliente, idIncarico, checkStatusService(statusEndPoint, 'aml'))
      getDataGatheringPGActionCreator(idCliente)
      getDocumentIdPGActionCreator(idCliente)
      // this.setState({ loadingComplete: true })
    } else {
      this.setState({ loadingComplete: true })
    }

    if (isProspectPersonaGiuridica) {
      getFatcaPG(idCliente)
    }

    getDocumentsData(codiceFiscale, idCliente, idIncarico, checkStatusService(statusEndPoint, 'idCardData'))
  }

  componentDidUpdate(prevProps, prevState) {
    const { postFatcaPGRequest, getDataSummaryLightPGActionCreator, customer, idPratica, amlPgSaveRequest } = this.props

    if (
      postFatcaPGRequest &&
      prevProps.postFatcaPGRequest &&
      prevProps.postFatcaPGRequest.fetching === true &&
      postFatcaPGRequest.fetching === false &&
      postFatcaPGRequest.error === null
    ) {
      let _this = this
      getDataSummaryLightPGActionCreator(customer.idCliente, idPratica).then(function() {
        // _this.props.initialize(_this.props.formValuesFatca)
        _this.resetForm(forms.FATCA_GIURIDICHE)
      })
    }

    if (
      amlPgSaveRequest &&
      prevProps.amlPgSaveRequest &&
      prevProps.amlPgSaveRequest.fetching === true &&
      amlPgSaveRequest.fetching === false &&
      amlPgSaveRequest.error === null
    ) {
      getDataSummaryLightPGActionCreator(customer.idCliente, idPratica)
    }

    if (
      this.props.getFatcaPGRequest &&
      prevProps.getFatcaPGRequest &&
      prevProps.getFatcaPGRequest.fetching === true &&
      this.props.getFatcaPGRequest.fetching === false &&
      this.props.getFatcaPGRequest.error === null
    ) {
      this.setState({ loadingComplete: true })
    }

    if (
      prevProps.getDataRegistryRequest.fetching === true &&
      this.props.getDataRegistryRequest.fetching === false &&
      this.props.getDataRegistryRequest.error === null
    ) {
      const {
        dataRegistry,
        retrieveDominio,
        summaryData: { statusEndPoint },
        customer
      } = this.props
      let listaDep = []
      if (checkStatusService(statusEndPoint, 'dataRegistry')) {
        const comuni = ['Nascita', 'Residenza', 'Domicilio', 'InvioComunicazioni']
        comuni.map(el => {
          if (dataRegistry[`codProvincia${el}`] && dataRegistry[`codProvincia${el}`].chiave) {
            listaDep.push({
              id: `codProvincia${el}`,
              filtro: { codice: dataRegistry[`codProvincia${el}`].chiave }
            })
          }
        })
        if (dataRegistry.codComuneResidenza && dataRegistry.codComuneResidenza.chiave) {
          listaDep.push({
            id: 'codComuneResidenza',
            filtro: { codice: dataRegistry.codComuneResidenza.chiave }
          })
        }

        if (!dataRegistry[fields[1][forms.CONTATTI].FLAG_INDIRIZZO.id]) {
          if (dataRegistry.codProvinciaResidenza && dataRegistry.codProvinciaResidenza.chiave) {
            listaDep.push({
              id: 'codProvinciaInvioComunicazioni',
              filtro: { codice: dataRegistry.codProvinciaResidenza.chiave }
            })
          }
          if (dataRegistry.codComuneResidenza && dataRegistry.codComuneResidenza.chiave) {
            listaDep.push({
              id: 'codComuneInvioComunicazioni',
              filtro: { codice: dataRegistry.codComuneResidenza.chiave }
            })
          }
        } else {
          if (dataRegistry.codProvinciaInvioComunicazioni && dataRegistry.codProvinciaInvioComunicazioni.chiave) {
            listaDep.push({
              id: 'codProvinciaInvioComunicazioni',
              filtro: {
                codice: dataRegistry.codProvinciaInvioComunicazioni.chiave
              }
            })
          }
          if (dataRegistry.codComuneInvioComunicazioni && dataRegistry.codComuneInvioComunicazioni.chiave) {
            listaDep.push({
              id: 'codComuneInvioComunicazioni',
              filtro: {
                codice: dataRegistry.codComuneInvioComunicazioni.chiave
              }
            })
          }
        }
      } else if (customer.provinciaNascita) {
        listaDep.push({
          id: 'provinceBirthCode',
          filtro: { codice: customer.provinciaNascita.chiave }
        })
      } else if (customer.provinciaNascita) {
        listaDep.push({
          id: 'cityBirthCode',
          filtro: { codice: customer.comuneNascita.chiave }
        })
      }

      retrieveDominio(getDominioRequest(fields[1], this.state.openCollapsible, listaDep))
      retrieveDominio(
        Object.keys(fields[1]).reduce(
          (res, cur) => {
            if (cur !== this.state.openCollapsible) {
              return {
                listaDomini: [...res.listaDomini, ...getDominioRequest(fields[1], cur, listaDep).listaDomini]
              }
            }
            return res
          },
          { listaDomini: [] }
        ),
        true
      )
      this.setState({ waitingDomini: true })
    }
    // amlPgRequest
    if (
      this.props.amlPgRequest &&
      prevProps.amlPgRequest.fetching &&
      !this.props.amlPgRequest.fetching &&
      this.props.amlPgRequest.error === null
    ) {
      this.setState({ waitingDomini: false, loadingComplete: true })
    }
    if (this.props.dataGathering && this.props.dataGathering.province && this.state.fetchingData) {
      this.updateDominio('DOM_COMUNE_RILASCIO', this.props.dataGathering.province)
      this.setState({ fetchingData: false })
    }

    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && this.state.waitingDomini) {
      this.setState({ waitingDomini: false, loadingComplete: true })
    }

    if (prevState.openCollapsible !== this.state.openCollapsible && this.state.openCollapsible) {
      setTimeout(
        () =>
          $('.container-home-left').scrollTop(
            $('.container-home-left').scrollTop() +
              ($('.open').offset() !== undefined ? $('.open').offset().top - 50 : 0)
          ),
        100
      )
    }
  }

  soggettiCorrelatiToggleHandler() {
    const {
      getSoggettiGiaCorrelati,
      customer: { idCliente }
    } = this.props
    getSoggettiGiaCorrelati(idCliente)
  }

  soggettiCorrelatiGiuridicheToggleHandler() {
    const {
      getSoggettiGiaCorrelatiGiuridiche,
      customer: { idCliente }
    } = this.props
    getSoggettiGiaCorrelatiGiuridiche(idCliente)
  }

  updateDominio(idDominio, codice) {
    this.props.retrieveDominio({
      listaDomini: [{ idDominio, filtro: { codice } }]
    })
  }

  updateDomini(listaDomini) {
    this.props.retrieveDominio({ listaDomini })
  }

  handleOpenCollapsibleChange(openCollapsible) {
    this.setState({ openCollapsible })
  }

  handlePostDataRegistry(questions) {
    const {
      customer: { idCliente, idIncarico },
      dataRegistry,
      initialFormDataRegistry,
      postDataRegistry
    } = this.props
    let objContact = parseDataRegistry(fields[1][forms.CONTATTI], initialFormDataRegistry)

    postDataRegistry(idCliente, idIncarico, {
      ...parseDataRegistry(fields[1][forms.INFORMAZIONI_PERSONALI], initialFormDataRegistry),
      ...parseDataRegistry(fields[1][forms.RESIDENZA], initialFormDataRegistry),
      ...objContact,
      ...dataRegistry,
      flagCustomerIsBlacklist: dataRegistry.flagCustomerIsBlacklist,
      tipoAccettazionePrivacy: dataRegistry.tipoAccettazionePrivacy,
      internationalPrefixCellNumberCode: objContact['internationalPrefixCellNumberCode']['chiave'],
      // ...parseDataRegistry(fields[1][forms.CONTATTI], initialFormDataRegistry),
      ...questions,
      codiceFiscale: dataRegistry.codiceFiscale ? dataRegistry.codiceFiscale : null
    })
  }

  handleSaveFatca(payload) {
    const {
      customer: { idCliente, idIncarico },
      saveFatca
    } = this.props
    saveFatca(idCliente, { ...payload, idIncarico })
  }

  handleSaveFatcaPG(payload) {
    return this.props.postFatcaPG(payload)
  }

  handleSaveAml(payload, isAntiriciclaggio) {
    const {
      customer: { idCliente, idIncarico },
      amlPreviousForm,
      saveAml,
      summaryData: { statusEndPoint }
    } = this.props
    let listaDomande = [...payload]
    let previousLista = [...loadAmlData(amlPreviousForm, fields[1][forms.CONSENSI_PRIVACY])]
    if (!isAntiriciclaggio) {
      previousLista = [...loadAmlData(amlPreviousForm, fields[1][forms.ANTIRICICLAGGIO])]
    }
    previousLista.reduce((res, cur) => {
      const domanda = listaDomande.find(d => d.idDomanda === cur.idDomanda)
      if (domanda) {
        const index = listaDomande.findIndex(d => d.idDomanda === cur.idDomanda)
        listaDomande = [...listaDomande.slice(0, index), ...listaDomande.slice(index + 1)]
        return [...res, domanda]
      }
      return [...res, cur]
    }, [])
    saveAml(idCliente, idIncarico, [...previousLista, ...listaDomande], checkStatusService(statusEndPoint, 'aml'))
  }

  handleSaveAmlPg(payload, isAntiriciclaggio) {
    const {
      customer: { idCliente, idIncarico },
      amlPgPreviousForm,
      saveAmlPg
    } = this.props
    let listaDomande = [...payload]
    let previousLista = [...loadAmlData(amlPgPreviousForm, fields[1][forms.CONSENSI_PRIVACY_GIURIDICHE])]
    if (!isAntiriciclaggio) {
      previousLista = [...loadAmlData(amlPgPreviousForm, fields[1][forms.ANTIRICICLAGGIO_GIURIDICHE])]
    }
    previousLista.reduce((res, cur) => {
      const domanda = listaDomande.find(d => d.idDomanda === cur.idDomanda)
      if (domanda) {
        const index = listaDomande.findIndex(d => d.idDomanda === cur.idDomanda)
        listaDomande = [...listaDomande.slice(0, index), ...listaDomande.slice(index + 1)]
        return [...res, domanda]
      }
      return [...res, cur]
    }, [])
    saveAmlPg(idCliente, idIncarico, [...previousLista, ...listaDomande], false)
    this.setState({ isAntiriciclaggio })
  }

  getSectionStatus(index) {
    return getSectionStatus(this.props.summaryData, 1, index)
  }

  resetForm(form) {
    const { summaryData, goToWhenSaved, reduxForms } = this.props
    const openCollapsible = getOpenCollapsible(summaryData, form, reduxForms)
    this.setState({ openCollapsible })
    if (!openCollapsible) {
      goToWhenSaved()
    }
  }

  render() {
    const {
      customer,
      templateAml,
      templateAmlPg,
      domini,
      dataRegistry,
      goToWhenSaved,
      clientiCorrelatiList,
      getClientiCorrelatiRequest,
      soggettiCorrelatiRequest,
      inviaRichiestaRequest,
      inviaRichiestaGiuridicheRequest,
      getClientiCorrelatiList,
      searchSoggettiCorrelati,
      searchSoggettiCorrelatiGiuridiche,
      inviaRichiestaSoggettiCorrelati,
      inviaRichiestaSoggettiCorrelatiGiuridiche,
      getSoggettiGiaCorrelati,
      getSoggettiGiaCorrelatiGiuridiche,
      dataFatcaPG
    } = this.props

    if (!customer) {
      return null
    }

    if (!Object.keys(domini).length) {
      return null
    }

    return (
      <section className="main-body consulenza-base">
        <article className="page-left-content">
          <div className="background_pattern" />
          <h2 className="title-sezione">{dataSB.censimento_anagrafica.title_section}</h2>
          <Collapsible open={this.state.openCollapsible} onChange={this.handleOpenCollapsibleChange}>
            {this.state.loadingComplete && (
              <Fragment>
                {!this.props.isProspectPersonaGiuridica && (
                  <Fragment>
                    <InformazioniPersonali
                      status={this.getSectionStatus(0)}
                      domini={domini}
                      updateDominio={this.updateDominio}
                      resetForm={this.resetForm}
                      onSave={this.handlePostDataRegistry}
                      getDataRegistry={this.props.getDataRegistry}
                      idCliente={customer.idCliente}
                      idIncarico={customer.idIncarico}
                      statusEndPoint={this.props.summaryData.statusEndPoint}
                    />
                    <Residenza
                      status={this.getSectionStatus(1)}
                      domini={domini}
                      updateDominio={this.updateDominio}
                      resetForm={this.resetForm}
                      onSave={this.handlePostDataRegistry}
                    />
                    <Contatti
                      status={this.getSectionStatus(2)}
                      domini={domini}
                      updateDominio={this.updateDominio}
                      updateDomini={this.updateDomini}
                      resetForm={this.resetForm}
                      dataRegistry={dataRegistry}
                      onSave={this.handlePostDataRegistry}
                      validationCreateOtp={this.props.validationCreateOtp}
                      idCliente={customer.idCliente}
                      idIncarico={customer.idIncarico}
                    />

                    <CollapsibleItem
                      itemKey="SOGGETTI_CORRELATI"
                      header={infoSoggettiCorrelati.title_accordion}
                      icon="keyboard_arrow_down"
                      toggle={this.soggettiCorrelatiToggleHandler}>
                      <SoggettiCorrelati
                        clientiCorrelatiList={clientiCorrelatiList}
                        getClientiCorrelatiRequest={getClientiCorrelatiRequest}
                        soggettiCorrelatiRequest={soggettiCorrelatiRequest}
                        inviaRichiestaRequest={inviaRichiestaRequest}
                        getClientiCorrelatiList={getClientiCorrelatiList}
                        getSoggettiGiaCorrelati={getSoggettiGiaCorrelati}
                        searchSoggettiCorrelati={searchSoggettiCorrelati}
                        inviaRichiestaSoggettiCorrelati={inviaRichiestaSoggettiCorrelati}
                      />
                    </CollapsibleItem>
                  </Fragment>
                )}

                {this.props.dataGathering &&
                  this.props.dataGathering !== undefined &&
                  this.props.isProspectPersonaGiuridica &&
                  !!Object.keys(this.props.dataGathering).length && (
                    <InformazioniPersonaliGiuridiche
                      status={this.getSectionStatus(0)}
                      dataSection={this.props.dataGathering}
                      domini={domini}
                    />
                  )}

                {this.props.isProspectPersonaGiuridica && (
                  <Fragment>
                    <CollapsibleItem
                      itemKey="SOGGETTI_CORRELATI_PG"
                      header={infoSoggettiCorrelati.title_accordion}
                      icon="keyboard_arrow_down"
                      // labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
                      toggle={this.soggettiCorrelatiGiuridicheToggleHandler}>
                      <SoggettiCorrelatiGiuridiche
                        clientiCorrelatiList={clientiCorrelatiList}
                        getClientiCorrelatiRequest={getClientiCorrelatiRequest}
                        soggettiCorrelatiRequest={soggettiCorrelatiRequest}
                        inviaRichiestaGiuridicheRequest={inviaRichiestaGiuridicheRequest}
                        getClientiCorrelatiList={getClientiCorrelatiList}
                        getSoggettiGiaCorrelati={getSoggettiGiaCorrelatiGiuridiche}
                        searchSoggettiCorrelati={searchSoggettiCorrelatiGiuridiche}
                        inviaRichiestaSoggettiCorrelati={inviaRichiestaSoggettiCorrelatiGiuridiche}
                      />
                    </CollapsibleItem>
                  </Fragment>
                )}

                {this.props.isProspectPersonaGiuridica && (
                  <FatcaGiuridiche
                    status={this.getSectionStatus(2)}
                    domini={domini}
                    updateDominio={this.updateDominio}
                    resetForm={this.resetForm}
                    onSave={this.handleSaveFatcaPG}
                    dataFatcaPG={dataFatcaPG}
                    retrieveDominio={this.props.retrieveDominio}
                  />
                )}

                {!this.props.isProspectPersonaGiuridica && (
                  <Fatca
                    status={this.getSectionStatus(4)}
                    domini={domini}
                    updateDominio={this.updateDominio}
                    resetForm={this.resetForm}
                    onSave={this.handleSaveFatca}
                  />
                )}

                {templateAmlPg !== undefined &&
                  this.props.isProspectPersonaGiuridica &&
                  !!Object.keys(templateAmlPg).length && (
                    <Fragment>
                      <AntiRiciclaggioGiuridiche
                        status={this.getSectionStatus(3)}
                        template={templateAmlPg.sezioni[0]}
                        domini={domini}
                        dominioRequest={this.props.dominioRequest}
                        onSave={this.handleSaveAmlPg}
                        previousForm={this.props.amlPgPreviousForm}
                        resetForm={this.resetForm}
                        untouch={this.props.untouch}
                        requestFromAntiriciclaggio={this.state.isAntiriciclaggio}
                      />
                      <ConsensiPrivacyGiuridiche
                        status={this.getSectionStatus(4)}
                        template={templateAmlPg.sezioni[1]}
                        onSave={this.handleSaveAmlPg}
                        previousForm={this.props.amlPgPreviousForm}
                        resetForm={this.resetForm}
                        requestFromAntiriciclaggio={this.state.isAntiriciclaggio}
                      />
                    </Fragment>
                  )}

                {!!Object.keys(templateAml).length && !this.props.isProspectPersonaGiuridica && (
                  <Fragment>
                    <AntiRiciclaggio
                      status={this.getSectionStatus(5)}
                      template={templateAml.sezioni[0]}
                      domini={domini}
                      dominioRequest={this.props.dominioRequest}
                      onSave={this.handleSaveAml}
                      previousForm={this.props.amlPreviousForm}
                      resetForm={this.resetForm}
                    />
                    <ConsensiPrivacy
                      status={this.getSectionStatus(6)}
                      template={templateAml.sezioni[1]}
                      onSave={this.handleSaveAml}
                      previousForm={this.props.amlPreviousForm}
                      resetForm={this.resetForm}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
          </Collapsible>
          <Row className="right-align padding-r-20 margin-t-20">
            {this.state.loadingComplete && (
              <a onClick={() => goToWhenSaved()} to="#">
                {dataSB.link_continue} <i className="icon-arrow" />
              </a>
            )}
          </Row>
        </article>
      </section>
    )
  }
}

CensimentoAnagrafico.propTypes = {
  getClientiCorrelatiList: PropTypes.func,
  retrieveDominio: PropTypes.func,
  clientiCorrelatiList: PropTypes.array,
  getClientiCorrelatiRequest: PropTypes.object,
  dominioRequest: PropTypes.object,
  customer: PropTypes.object,
  getDataRegistry: PropTypes.func,
  fetchSurveyAml: PropTypes.func,
  templateAml: PropTypes.object,
  templateAmlPg: PropTypes.object,
  domini: PropTypes.object,
  validationCreateOtp: PropTypes.func,
  postDataRegistry: PropTypes.func,
  saveFatca: PropTypes.func,
  amlPreviousForm: PropTypes.object,
  amlPgPreviousForm: PropTypes.object,
  saveAml: PropTypes.func,
  saveAmlPg: PropTypes.func,
  summaryData: PropTypes.object,
  dataRegistry: PropTypes.object,
  getDataRegistryRequest: PropTypes.object,
  goToWhenSaved: PropTypes.func.isRequired,
  accordionKey: PropTypes.string,
  initialFormDataRegistry: PropTypes.object,
  reduxForms: PropTypes.object,
  soggettiCorrelatiRequest: PropTypes.object,
  inviaRichiestaRequest: PropTypes.object,
  inviaRichiestaGiuridicheRequest: PropTypes.object,
  searchSoggettiCorrelati: PropTypes.func,
  searchSoggettiCorrelatiGiuridiche: PropTypes.func,
  inviaRichiestaSoggettiCorrelati: PropTypes.func,
  inviaRichiestaSoggettiCorrelatiGiuridiche: PropTypes.func,
  getSoggettiGiaCorrelati: PropTypes.func,
  // url: PropTypes.string,
  // idPratica: PropTypes.string,
  // match: PropTypes.any,
  getDocumentsData: PropTypes.any,
  isProspectPersonaGiuridica: PropTypes.any,
  fetchSurveyAmlPg: PropTypes.func,
  postFatcaPG: PropTypes.func,
  amlPgRequest: PropTypes.object,
  dataFatcaPG: PropTypes.object,
  getFatcaPG: PropTypes.any,
  getFatcaPGRequest: PropTypes.any,
  postFatcaPGRequest: PropTypes.func,
  getDataSummaryLightPGActionCreator: PropTypes.func,
  idPratica: PropTypes.any,
  untouch: PropTypes.func,
  getDataGatheringPGActionCreator: PropTypes.func,
  getDocumentIdPGActionCreator: PropTypes.func,
  dataGathering: PropTypes.object,
  getSoggettiGiaCorrelatiGiuridiche: PropTypes.func,
  amlPgSaveRequest: PropTypes.func
}

export default CensimentoAnagrafico
