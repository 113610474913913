import axios from 'axios'
import * as actionTypes from './constants'
import * as actionTypesCustomers from '../customers/constants'

const BASE_API = 'v2/privacy-acceptance'

export function privacySendProcuratore(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRIVACY_PROCURATORE_SEND_START
    })
    axios
      .post(`${BASE_API}/send`, payload)
      .then(response => {
        dispatch({
          type: actionTypes.PRIVACY_PROCURATORE_SEND_SUCCESS,
          payload: {
            // processId: null,
            processId: response.data.processId,
            duplicatedEmail: response.data.duplicatedEmail // || true
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.PRIVACY_PROCURATORE_SEND_ERROR,
          error
        })
      })
  }
}

export function procuratorCheckCF(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PROCURATOR_CHECK_CF_START
    })
    axios
      .post('/v2/customer/check-customer-blacklist', payload)
      .then(response => {
        dispatch({
          type: actionTypesCustomers.SET_BLACKLIST_PROCURATORE,
          flagIsBlacklist: response.data.flagIsBlacklist
        })

        dispatch({
          type: actionTypes.PROCURATOR_CHECK_CF_SUCCESS,
          flagIsBlacklist: response.data.flagIsBlacklist
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.PROCURATOR_CHECK_CF_ERROR,
          error
        })
      })
  }
}

export function overrideProcuratorCheckCF(payload) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.OVERRIDE_PROCURATOR_CHECK_CF_START })
    setTimeout(() => {
      dispatch({
        type: actionTypes.OVERRIDE_PROCURATOR_CHECK_CF_SUCCESS,
        flagIsBlacklist: payload
      })
    }, 500)
  }
}

export function deleteProcuratore() {
  return async function(dispatch) {
    dispatch({ type: actionTypes.DELETE_PROCURATORE })
  }
}

export function privacyCheckProcuratore(processId) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PRIVACY_PROCURATORE_CHECK_START
    })
    axios
      .get(`${BASE_API}/check?processId=${processId}`)
      .then(response => {
        const { isPrivacyAcceptanceDone, newCustomer, idCustomer } = response.data
        dispatch({
          type: actionTypes.PRIVACY_PROCURATORE_CHECK_SUCCESS,
          isPrivacyAcceptanceDone,
          idPratica: (newCustomer || {}).idIncarico,
          idCustomer: idCustomer
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.PRIVACY_PROCURATORE_CHECK_ERROR,
          error
        })
      })
  }
}

export function moduleSendProcuratore(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_START
    })
    axios
      .post(`${BASE_API}/confirm-paper-privacy`, payload)
      .then(response => {
        const { isPrivacyAcceptanceDone, idPractice, idProcurator } = response.data
        dispatch({
          type: actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_SUCCESS,
          isPrivacyAcceptanceDone,
          idPratica: idPractice,
          idProcurator
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_ERROR,
          error
        })
      })
  }
}

export function saveDataProcurator(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SAVE_DATA_PROCURATOR_START
    })
    axios
      .post('/v2/procurator/save', payload)
      .then(response => {
        const { idPractice, idCustomer, isPrivacyAcceptanceDone } = response.data
        dispatch({
          type: actionTypes.SAVE_DATA_PROCURATOR_SUCCESS,
          isPrivacyAcceptanceDone,
          idPratica: idPractice,
          idCustomer: idCustomer
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SAVE_DATA_PROCURATOR_ERROR,
          error
        })
      })
  }
}

export function getProcuratore(idProcurator, idIncarico) {
  try {
    return axios.get(`/v2/procurator/read/${idProcurator}?idIncarico=${idIncarico}`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'getProcuratore'
    throw newError
  }
}

export function getModuloProcura(data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.MODULO_PROCURA_GET_START
    })
    try {
      const response = await axios.post(`/v2/documents/get`, data)
      dispatch({
        type: actionTypes.MODULO_PROCURA_GET_SUCCESS,
        documentoProcura: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.MODULO_PROCURA_GET_ERROR,
        error: error
      })
    }
  }
}
