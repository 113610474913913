import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ModaleError from './ModaleErrorComponent'
import { getDataSummaryLight } from '../../../store/modules/customers'

const mapDispatchToProps = dispatch => bindActionCreators({ getDataSummaryLight }, dispatch)

const mapStateToProps = state => {
  return {
    customer: state.customers.customer
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModaleError)
