import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty, getFormInitialValues } from 'redux-form'
import { DOCUMENTO_IDENTITA_ALLEGATO } from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import { loadDefaultFields, loadDocumentsData, notBelongToDominio } from '../../utils'
import Documenti from './DocumentiComponent'

const fields = allFields[0][DOCUMENTO_IDENTITA_ALLEGATO]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const { NAZIONE_RILASCIO, PROVINCIA_RILASCIO, COMUNE_RILASCIO, NUMERO_DOCUMENTO, TIPO_DOCUMENTO } = fields
  if (
    !!values[NAZIONE_RILASCIO.id] &&
    notBelongToDominio(domini, NAZIONE_RILASCIO.dominio, values[NAZIONE_RILASCIO.id])
  ) {
    errors[NAZIONE_RILASCIO.id] = 'Nazione non riconosciuta'
  }
  if (
    !!values[PROVINCIA_RILASCIO.id] &&
    notBelongToDominio(domini, PROVINCIA_RILASCIO.dominio, values[PROVINCIA_RILASCIO.id])
  ) {
    errors[PROVINCIA_RILASCIO.id] = 'Provincia non riconosciuta'
  }
  if (
    !!values[PROVINCIA_RILASCIO.id] &&
    !!values[COMUNE_RILASCIO.id] &&
    notBelongToDominio(domini, COMUNE_RILASCIO.dominio, values[COMUNE_RILASCIO.id])
  ) {
    errors[COMUNE_RILASCIO.id] = 'Comune non riconosciuto'
  }

  if (
    !!values[NUMERO_DOCUMENTO.id] &&
    !new RegExp(NUMERO_DOCUMENTO.validazione.default).test(values[NUMERO_DOCUMENTO.id])
  ) {
    errors[NUMERO_DOCUMENTO.id] = 'Sono ammessi solo caratteri alfanumerici'
  }

  if (values[TIPO_DOCUMENTO.id] === TIPO_DOCUMENTO.cid && values[NAZIONE_RILASCIO.id] === NAZIONE_RILASCIO.italiaId) {
    if (
      !NUMERO_DOCUMENTO.validazione.ita1.test(values[NUMERO_DOCUMENTO.id]) &&
      !NUMERO_DOCUMENTO.validazione.ita2.test(values[NUMERO_DOCUMENTO.id])
    ) {
      errors[NUMERO_DOCUMENTO.id] = 'Il formato del numero documento è errato'
    }
  }

  return errors
}

const mapStateToProps = (state, ownProps) => {
  let commonStateToProps = {
    formValues: getFormValues(DOCUMENTO_IDENTITA_ALLEGATO)(state),
    formErrors: getFormSyncErrors(DOCUMENTO_IDENTITA_ALLEGATO)(state),
    dataNascitaCF: state.customers.dataRegistry.dataNascita || state.customers.customer.dataNascita,
    currentInitial: getFormInitialValues(DOCUMENTO_IDENTITA_ALLEGATO)(state),
    fields,
    isDirty: isDirty(DOCUMENTO_IDENTITA_ALLEGATO)(state),
    isFetching: state.customers.postDataAllegatiDOCRequest.fetching,
    apiError: state.customers.postDataAllegatiDOCRequest.error
  }
  if (ownProps.isProspectPersonaGiuridica) {
    commonStateToProps = {
      ...commonStateToProps,
      initialValues: {
        ...defaultFields,
        ...loadDocumentsData(fields, state.anagraficaPersonaGiuridicaCliente.docIDPF)
      }
    }
  } else {
    commonStateToProps = {
      ...commonStateToProps,
      initialValues: {
        ...defaultFields,
        ...loadDocumentsData(fields, state.customers.documentsData)
        // [fields.HA_PROCURATORE.id]: state.customers.summaryData.idProcurator !== null
      },
      flagCustomerIsBlacklist:
        state.customers.documentsData.flagCustomerIsBlacklist === false ||
        state.customers.documentsData.flagCustomerIsBlacklist === true
          ? state.customers.documentsData.flagCustomerIsBlacklist
          : state.customers.flagCustomerIsBlacklist
    }
  }
  return commonStateToProps
}

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    form: DOCUMENTO_IDENTITA_ALLEGATO,
    validate,
    shouldError: () => isDirty(DOCUMENTO_IDENTITA_ALLEGATO)
  })(Documenti)
)
