import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { checkStatusService } from '../utils'
import Sottoscrizione from './Sottoscrizione'
import allFields from '../fields'
import { SERVIZI_DIGITALI } from '../../../store/modules/forms/constants'

const fields = allFields[3][SERVIZI_DIGITALI]

class SottoscrizioneServizi extends Component {
  constructor(props) {
    super(props)
    this.state = { loadingComplete: false, loadingDominiComplete: false }
  }

  componentDidMount() {
    const { COMUNE_FIRMA } = fields
    const {
      summaryData: { statusEndPoint },
      customer: { codiceFiscale, idCliente, idIncarico },
      dataRegistry,
      retrieveDominio,
      getDataRegistry,
      getServiziDigitali,
      getServiziDigitaliPG
    } = this.props
    let active
    if (!this.props.isProspectPersonaGiuridica) {
      active = statusEndPoint && checkStatusService(statusEndPoint, 'servdig')
    } else {
      this.setState({ loadingComplete: true }) // mock temporaneo per PG
    }

    const { getDataSummaryLightPGActionCreator, customer, idPratica, isProspectPersonaGiuridica } = this.props

    if (isProspectPersonaGiuridica) {
      getDataSummaryLightPGActionCreator(customer.idCliente, idPratica)
    }

    retrieveDominio({
      listaDomini: [{ idDominio: COMUNE_FIRMA.dominio }]
    })
    if (!this.props.isProspectPersonaGiuridica) {
      if (active) {
        getServiziDigitali(idCliente, idIncarico, active)
      } else if (Object.keys(dataRegistry).length === 0) {
        getDataRegistry(idCliente, idIncarico, checkStatusService(statusEndPoint, 'dataRegistry'))
      } else {
        this.setState({ loadingComplete: true })
      }
      this.props.getDocumentsData(
        codiceFiscale,
        idCliente,
        idIncarico,
        checkStatusService(statusEndPoint, 'idCardData')
      )
    } else {
      getServiziDigitaliPG(idCliente, idIncarico)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.getServiziDigitaliRequest.fetching &&
      !this.props.getServiziDigitaliRequest.fetching &&
      this.props.getServiziDigitaliRequest.error === null
    ) {
      this.setState({ loadingComplete: true })
    }
    if (
      prevProps.getDataRegistryRequest.fetching &&
      !this.props.getDataRegistryRequest.fetching &&
      this.props.getDataRegistryRequest.error === null
    ) {
      this.setState({ loadingComplete: true })
    }
    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching) {
      this.setState({ loadingDominiComplete: true })
    }
  }

  render() {
    if (!(this.state.loadingComplete && this.state.loadingDominiComplete)) return null

    return <Sottoscrizione {...this.props} url={this.props.url} idPratica={this.props.idPratica} />
  }
}

SottoscrizioneServizi.propTypes = {
  customer: PropTypes.object,
  summaryData: PropTypes.object,
  dataRegistry: PropTypes.object,
  retrieveDominio: PropTypes.func,
  dominioRequest: PropTypes.object,
  getServiziDigitali: PropTypes.func,
  getServiziDigitaliPG: PropTypes.func,
  getDocumentsData: PropTypes.func,
  getServiziDigitaliRequest: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.error
  }),
  getDataRegistry: PropTypes.func,
  getDataRegistryRequest: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.error
  }),
  url: PropTypes.any,
  idPratica: PropTypes.any,
  isProspectPersonaGiuridica: PropTypes.bool,
  getDataSummaryLightPGActionCreator: PropTypes.func,
  downloadSignModule: PropTypes.func
}

export default SottoscrizioneServizi
