import React from 'react'
import { PropTypes } from 'prop-types'
import { Button } from 'react-materialize'
import './SpeseButton.scss'

const SpeseButton = ({ expanded, handler, label = 'ALTRE OPZIONI' }) => (
  <div className="spese-button">
    {!expanded && <Button floating large waves="light" icon="add" type="button" onClick={handler} />}
    {expanded && (
      <Button floating large waves="light" className="remove" icon="remove" type="button" onClick={handler} />
    )}
    <span>{label}</span>
  </div>
)

SpeseButton.propTypes = {
  label: PropTypes.string,
  expanded: PropTypes.bool,
  handler: PropTypes.func.isRequired
}

export default SpeseButton
