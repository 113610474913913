import * as actionTypes from './constants'
import { GET_DOCUMENTS_DATA_START, GET_DOCUMENTS_DATA_SUCCESS, GET_DOCUMENTS_DATA_ERROR } from '../customers/constants'

const apiRequests = {
  privacySendRequest: {
    fetching: false,
    error: null
  },
  privacyCheckRequest: {
    fetching: false,
    error: null
  },
  sendDataProcuratore: {
    fetching: false,
    error: null
  },
  procuratorRequest: {
    fetching: false,
    error: null
  },
  moduleSendRequest: {
    fetching: false,
    error: null
  },
  overrideCheckCFRequest: {
    fetching: false,
    error: null
  },
  checkCFRequest: {
    fetching: false,
    error: null
  }
}

/* WARNING */
/* DATI MOCKATI */
const initialState = {
  processId: null,
  procuratore: {
    isPrivacyAcceptanceDone: false
  },
  customers: {
    customers: [],
    customer: {
      codiceFiscale: '',
      idCliente: '',
      idIncarico: '',
      dataNascita: '',
      sesso: '',
      comuneNascita: {
        codiceDominio: 'DOM_COMUNE_NASCITA',
        chiave: ''
      },
      provinciaNascita: {
        codiceDominio: 'DOM_PROVINCE_NASCITA',
        chiave: ''
      },
      nazioneNascita: {
        codiceDominio: 'DOM_NAZIONE_NASCITA',
        chiave: ''
      }
    },
    dataRegistry: {},
    dataFatca: {},
    documentsData: {
      idIncarico: null,
      tipoDocumento: {
        codiceDominio: 'DOM_TIPO_DOCUMENTO',
        chiave: ''
      },
      numeroDocumenti: 'AO987654',
      nazioneRilascio: null,
      provinciaRilascio: {
        codiceDominio: 'DOM_PROVINCE_RILASCIO',
        chiave: ''
      }
    }
  }
}

/* releaseProvince: { codiceDominio: 'DOM_PROVINCE_RILASCIO_PROC', chiave: '' }, */

export default function(state = { ...initialState, ...apiRequests }, action) {
  switch (action.type) {
    case actionTypes.OVERRIDE_PROCURATOR_CHECK_CF_START:
      return {
        ...state,
        flagIsBlacklist: '',
        overrideCheckCFRequest: {
          fetching: true
        }
      }
    case actionTypes.OVERRIDE_PROCURATOR_CHECK_CF_SUCCESS:
      return {
        ...state,
        flagIsBlacklist: action.flagIsBlacklist,
        overrideCheckCFRequest: {
          fetching: false,
          error: null
        }
      }

    case actionTypes.DELETE_PROCURATORE:
      return {
        ...initialState
      }
    case actionTypes.SAVE_DATA_PROCURATOR_START:
      return {
        ...state,
        sendDataProcuratore: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SAVE_DATA_PROCURATOR_SUCCESS:
      return {
        ...state,
        sendDataProcuratore: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.SAVE_DATA_PROCURATOR_ERROR:
      return {
        ...state,
        sendDataProcuratore: {
          fetching: false,
          error: action.error
        }
      }
    case GET_DOCUMENTS_DATA_START:
      return {
        ...state,
        DataProcuratore: {
          fetching: true,
          error: null
        }
      }
    case GET_DOCUMENTS_DATA_SUCCESS:
      return {
        ...state,

        ...action.dataProcuratore
      }
    case GET_DOCUMENTS_DATA_ERROR:
      return {
        ...state,
        DataProcuratore: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.PROCURATOR_CHECK_CF_START:
      return {
        ...state,
        flagIsBlacklist: '',
        checkCFRequest: {
          fetching: true
        }
      }
    case actionTypes.PROCURATOR_CHECK_CF_SUCCESS:
      return {
        ...state,
        flagIsBlacklist: action.flagIsBlacklist,
        checkCFRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PROCURATOR_CHECK_CF_ERROR:
      return {
        ...state,
        flagIsBlacklist: '',
        checkCFRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.MODULO_PROCURA_GET_START:
      return Object.assign({}, state, {
        procuratorRequest: {
          fetching: true,
          error: null,
          success: false
        }
      })
    case actionTypes.MODULO_PROCURA_GET_SUCCESS:
      return {
        ...state,
        documentoProcura: action.documentoProcura,
        procuratorRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.MODULO_PROCURA_GET_ERROR:
      return Object.assign({}, state, {
        procuratorRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.PRIVACY_PROCURATORE_SEND_START:
      return {
        ...state,
        processId: null,
        privacySendRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRIVACY_RESET:
      return {
        ...state,
        processId: null,
        procuratore: { ...state.procuratore, isPrivacyAcceptanceDone: false },
        privacyCheckRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRIVACY_PROCURATORE_SEND_SUCCESS:
      return {
        ...state,
        processId: action.payload.processId,
        duplicatedEmail: action.payload.duplicatedEmail,
        privacySendRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRIVACY_PROCURATORE_SEND_ERROR:
      return {
        ...state,
        privacySendRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.PRIVACY_PROCURATORE_CHECK_START:
      return {
        ...state,
        procuratore: { ...state.procuratore, isPrivacyAcceptanceDone: false },
        privacyCheckRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRIVACY_PROCURATORE_CHECK_SUCCESS:
      return {
        ...state,
        procuratore: { ...state.procuratore, isPrivacyAcceptanceDone: action.isPrivacyAcceptanceDone },
        isPrivacyAcceptanceDone: action.isPrivacyAcceptanceDone,
        idProcurator: action.idProcurator,
        privacyCheckRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRIVACY_PROCURATORE_CHECK_ERROR:
      return {
        ...state,
        privacyCheckRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_START:
      return {
        ...state,
        procuratore: { ...state.procuratore, isPrivacyAcceptanecDone: false },
        moduleSendRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_SUCCESS:
      return {
        ...state,
        procuratore: { ...state.procuratore, isPrivacyAcceptanceDone: action.isPrivacyAcceptanceDone },
        isPrivacyAcceptanceDone: action.isPrivacyAcceptanceDone,
        idProcurator: action.idProcurator,
        moduleSendRequest: {
          fetching: false,
          error: null
        }
      }

    case actionTypes.CONFIRM_PAPER_PRIVACY_PROCURATORE_ERROR:
      return {
        ...state,
        moduleSendRequest: {
          fetching: false,
          error: action.error
        }
      }
    default:
      return state
  }
}
