import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
// import moment from 'moment'
import { Field } from 'redux-form'
import { Row, Col } from 'react-materialize'

import { getValueDominioFromKey } from '../../utils'
import Title from '../../../../components/Title'

import { renderHtmlInput } from '../../../../components/Forms/renderFields'
import InputText from '../../../../components/Inputs/Text'

// const documenti = dataSB.acquisizione.documento_identita

class SchedaDatiPepItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      test: true,
      personaEsposta: null,
      pepEstero: null,
      pepNazionale: null,
      unicoPep: null,
      unPep: null,
      titolareeffettivo: false,
      counter: 0,
      titolareEsposto: false,
      partecipazioni: false,
      procedimenti: true
    }
    // this.submit = this.submit.bind(this)
    // this.updateDominio = this.updateDominio.bind(this)
    // this.checkNazione = this.checkNazione.bind(this)
  }

  componentDidMount() {
    // let index = this.props.position
    // const { SETTORE_OCCUPAZIONE, NAZIONE_SCHEDA_DATI } = this.props.fields
    // const payload = {
    //   listaDomini: [
    //     {
    //       idDominio: SETTORE_OCCUPAZIONE.dominio
    //     },
    //     {
    //       idDominio: NAZIONE_SCHEDA_DATI.dominio
    //     }
    //   ]
    // }
    // this.props.dominioCustomRequest(payload)
  }

  updateDominio(idDominio, codice) {
    this.props.dominioCustomRequest(idDominio, codice)
    // { idDominio: 'DOM_COMUNE_RESIDENZA_PROC', filtro: { codice: sezioneProcuratore.residenceProvince } }
  }

  checkNazione(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let NAZIONE_SCHEDA_DATI = fields[`NAZIONE_SCHEDA_DATI_${counter}`]
    if (isItalia) {
      return formValues[NAZIONE_SCHEDA_DATI.id] === NAZIONE_SCHEDA_DATI.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, NAZIONE_SCHEDA_DATI.dominio, formValues[NAZIONE_SCHEDA_DATI.id])
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let PROVINCIA_SCHEDA_DATI = fields[`PROVINCIA_SCHEDA_DATI_${counter}`]
    return !!getValueDominioFromKey(listaDomini, PROVINCIA_SCHEDA_DATI.dominio, value)
  }

  checkComune(value) {
    const {
      domini: { listaDomini },
      counter,
      fields
    } = this.props
    let COMUNE_SCHEDA_DATI = fields[`COMUNE_SCHEDA_DATI_${counter}`]
    return !!getValueDominioFromKey(listaDomini, COMUNE_SCHEDA_DATI.dominio, value)
  }

  checkCap() {
    const {
      domini: { listaDomini },
      counter,
      fields,
      formValues
    } = this.props
    let COMUNE_SCHEDA_DATI = fields[`COMUNE_SCHEDA_DATI_${counter}`]
    return !!getValueDominioFromKey(listaDomini, COMUNE_SCHEDA_DATI.dominio, formValues[COMUNE_SCHEDA_DATI.id])
  }

  submit(values) {
    //   const { submitHandler, currentInitial, fields, flagCustomerIsBlacklist } = this.props
    //   const { values, listaAllegati } = parseDocumentsData(fields, submittedValues, currentInitial)
    //   Object.assign(values, { flagCustomerIsBlacklist: !!flagCustomerIsBlacklist })
    //   submitHandler(values, listaAllegati)
    // }
    console.log('Salva')

    let obj
    Object.keys(values).reduce((res, cur) => {
      obj = { ...res, [cur]: values[cur] }
      return obj
    }, {})
    console.log(obj)
  }

  isCompiled(v) {
    const { formValues = {} } = this.props
    return typeof formValues[v.id] !== 'undefined' && formValues[v.id] !== null && formValues[v.id] !== ''
  }

  render() {
    // this.props.counter
    const { fields, counter, formValues, formErrors, change, touch } = this.props

    let RADIO_NO_PEP = fields[`RADIO_NO_PEP_${counter}`]
    let RADIO_PEP_NAZIONALE = fields[`RADIO_PEP_NAZIONALE_${counter}`]
    let RADIO_PEP_ESTERO = fields[`RADIO_PEP_ESTERO_${counter}`]

    let NOME = fields[`NOME_${counter}`]
    let COGNOME = fields[`COGNOME_${counter}`]
    let CODICE_FISCALE = fields[`CODICE_FISCALE_${counter}`]
    let PEP_IN_QUANTO = fields[`PEP_IN_QUANTO_${counter}`]
    // let PEP_FAMILIARE = fields[`PEP_FAMILIARE_${counter}`]
    // let PEP_REDDITO = fields[`PEP_REDDITO_${counter}`]
    // let PEP_PARTECIPAZIONI = fields[`PEP_PARTECIPAZIONI_${counter}`]
    // let PEP_PROCEDIMENTI = fields[`PEP_PROCEDIMENTI_${counter}`]

    let scarto = counter
    for (let i = 0; i < counter; i++) {
      scarto++
    }

    return (
      <Fragment>
        <div className="pep-section schedaDati">
          <Row s={12}>
            <Col s={12}>
              <Title>Il cliente è un soggetto PEP (Politically Exposed Person)?*</Title>
              <div className="big-radio">
                <div key={0}>
                  <Field
                    name={RADIO_NO_PEP.id}
                    className="with-gap"
                    type="radio"
                    id={`type-${counter + scarto + 1}`}
                    value={'NO_PEP'}
                    component={renderHtmlInput}
                  />
                  <label htmlFor={`type-${counter + scarto + 1}`}>{RADIO_NO_PEP.label}</label>
                </div>
                <div key={1}>
                  <Field
                    name={RADIO_NO_PEP.id}
                    className="with-gap"
                    type="radio"
                    id={`type-${counter + scarto + 2}`}
                    value={'PEP_NAZIONALE'}
                    component={renderHtmlInput}
                  />
                  <label htmlFor={`type-${counter + scarto + 2}`}>{RADIO_PEP_NAZIONALE.label}</label>
                </div>
                <div key={2}>
                  <Field
                    name={RADIO_NO_PEP.id}
                    className="with-gap"
                    type="radio"
                    id={`type-${counter + scarto + 3}`}
                    value={'PEP_ESTERO'}
                    component={renderHtmlInput}
                  />
                  <label htmlFor={`type-${counter + scarto + 3}`}>{RADIO_PEP_ESTERO.label}</label>
                </div>
              </div>
            </Col>
          </Row>

          {formValues[RADIO_NO_PEP.id] !== 'NO_PEP' &&
            formValues[RADIO_NO_PEP.id] !== 'PEP_RELAZIONI' &&
            formValues[RADIO_NO_PEP.id] !== undefined &&
            formValues[RADIO_NO_PEP.id] !== '' &&
            formValues[RADIO_NO_PEP.id] !== null && (
              <Fragment>
                {/* <Row>
                  <Col s={4}>
                    <Field
                      name={TITOLARE_EFFETTIVO_PEP.id}
                      label={TITOLARE_EFFETTIVO_PEP.label}
                      handlerFn={value => {
                        change(TITOLARE_EFFETTIVO_PEP.id, value)
                      }}
                      options={[{ idRisposta: true }, { idRisposta: false }]}
                      component={renderYesNoQuestion}
                    />
                  </Col>
                </Row> */}
                <br />
                <Fragment>
                  <Row>
                    <Col s={4}>
                      <Field
                        name={NOME.id}
                        s={12}
                        disabled
                        maxLength={10}
                        error={formErrors[NOME.id]}
                        label={NOME.label}
                        value={formValues[NOME.id]}
                        change={change}
                        touch={touch}
                        component={InputText}
                      />
                    </Col>
                    <Col s={4}>
                      <Field
                        name={COGNOME.id}
                        s={12}
                        disabled
                        maxLength={10}
                        error={formErrors[COGNOME.id]}
                        label={COGNOME.label}
                        value={formValues[COGNOME.id]}
                        change={change}
                        touch={touch}
                        component={InputText}
                      />
                    </Col>

                    <Col s={4}>
                      <Field
                        name={CODICE_FISCALE.id}
                        s={12}
                        disabled
                        error={formErrors[CODICE_FISCALE.id]}
                        label={CODICE_FISCALE.label}
                        value={formValues[CODICE_FISCALE.id]}
                        change={change}
                        touch={touch}
                        component={InputText}
                        formatter={value => (value ? value.toUpperCase() : '')}
                      />
                    </Col>
                  </Row>
                  {formValues[RADIO_NO_PEP.id] !== 'NO_PEP' && formValues[RADIO_NO_PEP.id] !== 'PEP_RELAZIONI' && (
                    <Row className="radio-vertical extraMarginLeftRadioPerent">
                      <br />
                      <br />
                      <div
                        style={{
                          fontSize: 'medium'
                        }}>
                        {' '}
                        Conferma di essere PEP in quanto
                      </div>

                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-0`}
                          value="PRESIDENT"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-0`}>
                          Presidente della Repubblica, Presidente del Consiglio, Ministro, vice ministro o
                          Sottosegretario, Presidente di Regione, assessore regionale, Sindaco di capoluogo di provincia
                          o città metropolitana, Sindaco di comune con popolazione non inferiore a 15.000 abitanti
                          nonché cariche analoghe in Stati esteri
                        </label>
                      </Row>
                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-1`}
                          value="DEPUTY"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-1`}>
                          Deputato, senatore, parlamentare europeo, consigliere regionale nonché cariche analoghe in
                          Stati esteri
                        </label>
                      </Row>
                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-2`}
                          value="PARTY"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-2`}>
                          Membro degli organi direttivi centrali di partito politico
                        </label>
                      </Row>
                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-3`}
                          value="JUDICIARY"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-3`}>
                          Giudice della Corte Costituzionale, magistrato della Corte di Cassazione della Corte dei
                          Conti, consigliere di Stato o altro componente del Consiglio di Giustizia Amministrativa per
                          la Regione siciliana nonché carica analoga in Stati esteri
                        </label>
                      </Row>
                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-4`}
                          value="CENTRAL_BANK"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-4`}>
                          Membro degli organi direttivi delle banche centrali e delle autorità indipendenti
                        </label>
                      </Row>

                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-5`}
                          value="AMBASSADOR"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-5`}>
                          Ambasciatore, incaricato d’affari ovvero cariche equivalenti in stati esteri, ufficiale di
                          grado apicale delle forze armate ovvero carica analoga in Stati ester
                        </label>
                      </Row>
                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-6`}
                          value="ADMINISTRATION_ORGANS"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-6`}>
                          Componente degli organi di amministrazione, direzione o controllo delle imprese controllate,
                          anche indirettamente, dallo Stato italiano o da altro Stato estero ovvero partecipate, in
                          misura prevalente o totalitaria, dalle Regioni, da comuni capoluoghi di provincia e città
                          metropolitane e da comuni con popolazione complessivamente non inferiore a 15.000 abitanti
                        </label>
                      </Row>
                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-7`}
                          value="HOSPITAL_AGENCY"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-7`}>
                          Direttore generale di ASL e di azienda ospedaliera, di azienda ospedaliera universitaria e
                          degli altri enti del servizio sanitario nazionale
                        </label>
                      </Row>
                      <Row className="extraMarginLeftRadio">
                        <Field
                          name={PEP_IN_QUANTO.id}
                          className="with-gap"
                          type="radio"
                          id={`${PEP_IN_QUANTO.id}-8`}
                          value="DIRECTOR"
                          component="input"
                        />
                        <label className="radio-vertical" htmlFor={`${PEP_IN_QUANTO.id}-8`}>
                          Direttore, vicedirettore e membro dell’organo di gestione o soggetto svolgente funzioni
                          equivalenti in organizzazioni internazionali
                        </label>
                      </Row>
                      {/* <Row className="radio-vertical extraMarginLeftRadioPerent">
                        <br />
                        <br />
                        <div
                          style={{
                            fontSize: 'medium'
                          }}>
                          LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*
                        </div>
                        <Row className="extraMarginLeftRadio2">
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id={`${PEP_REDDITO.id}-0`}
                            value="INCOME_RATE_1"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor={`${PEP_REDDITO.id}-0`}>
                            {'< 50.000 EURO'}
                          </label>
                        </Row>

                        <Row className="extraMarginLeftRadio2">
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id={`${PEP_REDDITO.id}-1`}
                            value="INCOME_RATE_2"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor={`${PEP_REDDITO.id}-1`}>
                            {'50.000 < 100.000 EURO'}
                          </label>
                        </Row>

                        <Row className="extraMarginLeftRadio2">
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id={`${PEP_REDDITO.id}-2`}
                            value="INCOME_RATE_3"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor={`${PEP_REDDITO.id}-2`}>
                            {'100.000 < 250.000 EURO'}
                          </label>
                        </Row>

                        <Row className="extraMarginLeftRadio2">
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id={`${PEP_REDDITO.id}-3`}
                            value="INCOME_RATE_4"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor={`${PEP_REDDITO.id}-3`}>
                            {'250.000 < 1.000.000 EURO'}
                          </label>
                        </Row>

                        <Row className="extraMarginLeftRadio2">
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id={`${PEP_REDDITO.id}-4`}
                            value="INCOME_RATE_5"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor={`${PEP_REDDITO.id}-4`}>
                            {'1.000.000 EUR'}
                          </label>
                        </Row>
                      </Row>
                      <Row>
                        <Col s={6}>
                          <Field
                            name={PEP_PARTECIPAZIONI.id}
                            label={PEP_PARTECIPAZIONI.label}
                            handlerFn={value => {
                              change(PEP_PARTECIPAZIONI.id, value)
                            }}
                            options={[{ idRisposta: true }, { idRisposta: false }]}
                            component={renderYesNoQuestion}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col s={6}>
                          <Field
                            name={PEP_PROCEDIMENTI.id}
                            label={PEP_PROCEDIMENTI.label}
                            handlerFn={value => {
                              change(PEP_PROCEDIMENTI.id, value)
                            }}
                            options={[{ idRisposta: true }, { idRisposta: false }]}
                            component={renderYesNoQuestion}
                          />
                        </Col>
                      </Row> */}
                    </Row>
                  )}

                  {/* {formValues[RADIO_NO_PEP.id] === 'PEP_RELAZIONI' && (
                    <Fragment>
                      <Row className="radio-vertical">
                        <br />
                        <br />
                        <div
                          style={{
                            fontSize: 'medium'
                          }}>
                          FAMILIARE DI PEP O SOGGETTO CHE INTRATTIENE NOTORIAMENTE STRETTI LEGAMI CON PEP IN QUANTO
                        </div>
                        <Row>
                          <Field
                            name={PEP_FAMILIARE.id}
                            className="with-gap"
                            type="radio"
                            id="pep-familiare-0"
                            value="RELATIVE"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor="pep-familiare-0">
                            Genitore coniuge o persona legata in unione civile o convivenza di fatto o istituti
                            assimilabili alla persona politicamente esposta, figlio/a o loro coniuge e persona legata ai
                            figli in unione civile o convivenza di fatto o istituti assimilabili
                          </label>
                        </Row>
                        <Row>
                          <Field
                            name={PEP_FAMILIARE.id}
                            className="with-gap"
                            type="radio"
                            id="pep-familiare-1"
                            value="JOINT_EFFECTIVE"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor="pep-familiare-1">
                            Persona fisica legata alla persona esposta per via della titolarità effettiva congiunta, di
                            fatto, nell’interesse e a beneficio di una persona politicamente esposta
                          </label>
                        </Row>
                      </Row>
                      <Row className="radio-vertical">
                        <br />
                        <br />
                        <div
                          style={{
                            fontSize: 'medium'
                          }}>
                          LA MIA FASCIA DI REDDITO IMPONIBILE MEDIO DICHIARATO NEGLI ULTIMI 3 ANNI È PARI A:*
                        </div>
                        <Row>
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id="pep-reddito-0"
                            value="INCOME_RATE_1"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor="pep-reddito-0">
                            {'< 50.000 EURO'}
                          </label>
                        </Row>

                        <Row>
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id="pep-reddito-1"
                            value="INCOME_RATE_2"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor="pep-reddito-1">
                            {'50.000 < 100.000 EURO'}
                          </label>
                        </Row>

                        <Row>
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id="pep-reddito-3"
                            value="INCOME_RATE_3"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor="pep-reddito-3">
                            {'100.000 < 250.000 EURO'}
                          </label>
                        </Row>

                        <Row>
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id="pep-reddito-4"
                            value="INCOME_RATE_4"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor="pep-reddito-4">
                            {'250.000 < 1.000.000 EURO'}
                          </label>
                        </Row>

                        <Row>
                          <Field
                            name={PEP_REDDITO.id}
                            className="with-gap"
                            type="radio"
                            id="pep-reddito-5"
                            value="INCOME_RATE_5"
                            component="input"
                          />
                          <label className="radio-vertical" htmlFor="pep-reddito-5">
                            {'1.000.000 EUR'}
                          </label>
                        </Row>
                      </Row>
                      <Row>
                        <Col s={4}>
                          <Field
                            name={PEP_PARTECIPAZIONI.id}
                            label={PEP_PARTECIPAZIONI.label}
                            handlerFn={value => {
                              change(PEP_PARTECIPAZIONI.id, value)
                            }}
                            options={[{ idRisposta: true }, { idRisposta: false }]}
                            component={renderYesNoQuestion}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col s={4}>
                          <Field
                            name={PEP_PROCEDIMENTI.id}
                            label={PEP_PROCEDIMENTI.label}
                            handlerFn={value => {
                              change(PEP_PROCEDIMENTI.id, value)
                            }}
                            options={[{ idRisposta: true }, { idRisposta: false }]}
                            component={renderYesNoQuestion}
                          />
                        </Col>
                      </Row>
                    </Fragment>
                  )} */}
                </Fragment>
              </Fragment>
            )}
        </div>
      </Fragment>
    )
  }
}

SchedaDatiPepItem.propTypes = {
  change: PropTypes.func,
  touch: PropTypes.func,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  domini: PropTypes.object,
  fields: PropTypes.object,
  counter: PropTypes.number,
  dominioCustomRequest: PropTypes.func
}

SchedaDatiPepItem.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default SchedaDatiPepItem
