import * as actionTypes from './constants'
import axios from 'axios'

export function getProspectCountRequest(query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_PROSPECT_COUNT_START,
      getProspectCount: {
        fetching: true,
        error: null
      }
    })
    axios
      .get('/dashboard/getProspectCount')
      .then(res => {
        dispatch({
          type: actionTypes.GET_PROSPECT_COUNT_SUCCESS,
          getProspectCount: {
            fetching: false,
            error: null,
            payload: res.data
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_PROSPECT_COUNT_ERROR,
          getProspectCount: {
            fetching: false,
            error
          }
        })
      })
  }
}

export function getProspectToCustomerPercentageRequest(query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_START,
      getProspectToCustomerPercentage: {
        fetching: true,
        error: null
      }
    })
    axios
      .get('/dashboard/getProspectToCustomerPercentage')
      .then(res => {
        dispatch({
          type: actionTypes.GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_SUCCESS,
          getProspectToCustomerPercentage: {
            fetching: false,
            error: null,
            payload: res.data
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_ERROR,
          getProspectToCustomerPercentage: {
            fetching: false,
            error
          }
        })
      })
  }
}

export function getProspectPercentageRequest(query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_PROSPECT_PERCENTAGE_START,
      getProspectPercentage: {
        fetching: true,
        error: null
      }
    })
    axios
      .get('/dashboard/getProspectPercentage')
      .then(res => {
        dispatch({
          type: actionTypes.GET_PROSPECT_PERCENTAGE_SUCCESS,
          getProspectPercentage: {
            fetching: false,
            error: null,
            payload: res.data
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_PROSPECT_PERCENTAGE_ERROR,
          getProspectPercentage: {
            fetching: false,
            error
          }
        })
      })
  }
}

export function getInvestmentServiceOverallRequest(query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_INVESTMENT_SERVICE_OVERALL_START,
      getInvestmentServiceOverall: {
        fetching: true,
        error: null
      }
    })
    axios
      .get('/dashboard/getInvestmentServiceOverall')
      .then(res => {
        dispatch({
          type: actionTypes.GET_INVESTMENT_SERVICE_OVERALL_SUCCESS,
          getInvestmentServiceOverall: {
            fetching: false,
            error: null,
            payload: res.data
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_INVESTMENT_SERVICE_OVERALL_ERROR,
          getInvestmentServiceOverall: {
            fetching: false,
            error
          }
        })
      })
  }
}

export function getProspectIpOverallRequest(query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_PROSPECT_IP_OVERALL_START,
      getProspectIpOverall: {
        fetching: true,
        error: null
      }
    })
    axios
      .get('/dashboard/getProspectIpOverall')
      .then(res => {
        dispatch({
          type: actionTypes.GET_PROSPECT_IP_OVERALL_SUCCESS,
          getProspectIpOverall: {
            fetching: false,
            error: null,
            payload: res.data
          }
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_PROSPECT_IP_OVERALL_ERROR,
          getProspectIpOverall: {
            fetching: false,
            error
          }
        })
      })
  }
}
