import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-materialize'
import Attesa from '../../Attesa'
import { Green, Orange } from '../../Colors'
import { getValueDominioFromKey } from '../../../routes/ConsulenzaBaseContainerLayout/utils'
import moment from 'moment'

const SoggettiGiaCorrelatiTable = ({ list, domini: { listaDomini } }) => {
  if (!list || !listaDomini) {
    return null
  }
  return (
    <Fragment>
      <Table bordered>
        <thead>
          <tr
            style={{
              borderRight: '1px solid #C9C9C9',
              borderLeft: '1px solid #C9C9C9',
              borderTop: '1px solid #C9C9C9'
            }}>
            <th data-field="name" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Nome Cognome
            </th>
            <th data-field="bornDate" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Data di nascita
            </th>
            <th data-field="bornCity" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Luogo di nascita
            </th>
            <th data-field="taxCode" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Codice Fiscale
            </th>
            <th data-field="relation" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Relazione
            </th>
            <th data-field="status" className="center-align">
              Stato
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => (
            <tr key={i} style={{ borderLeft: '1px solid #C9C9C9', borderRight: '1px solid #C9C9C9' }}>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {item.nome + ' ' + item.cognome}
              </td>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {moment(item.dataNascita).format('DD/MM/YYYY')}
              </td>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {item.luogoNascita}
              </td>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {item.codiceFiscale}
              </td>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {getValueDominioFromKey(listaDomini, 'DOM_TIPO_RELAZIONE', item.relationType)}
              </td>
              <td className="center-align">
                {item.status === 'IN_ATTESA_DI_CONFERMA' && (
                  <Attesa text="In attesa di conferma">
                    <Orange>
                      <i className="material-icons">access_time</i>
                    </Orange>
                  </Attesa>
                )}
                {item.status === 'CONFERMATO' && (
                  <Attesa text="Correlato">
                    <Green>
                      <i className="material-icons">check_circle</i>
                    </Green>
                  </Attesa>
                )}
              </td>
            </tr>
          ))}
          {list.length === 0 && (
            <tr style={{ borderRight: '1px solid #C9C9C9', borderLeft: '1px solid #C9C9C9' }}>
              <td className="center-align" colSpan="6">
                Non ci sono soggetti correlati.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {/* list.map((item, i) => (
            <tr s={5} key={i}>
              <td>
                <ReadOnlyInput
                  label="Nome Cognome, Data di nascita, Luogo, Codice Fiscale e Relazione"
                  value={`${item.name + ' ' + item.surname} ${item.birthDate}, ${item.birthPlace}, ${
                    item.taxcode
                  }, Figlio`}
                />
              </td>
              <td>
                <Attesa className="m-top20" text="In attesa di conferma">
                  <Orange>
                    <i className="material-icons">access_time</i>
                  </Orange>
                </Attesa>

                <Attesa className="m-top20" text="Correlato">
                  <Green>
                    <i className="material-icons">check_circle</i>
                  </Green>
                </Attesa>
              </td>
            </tr>
          )) */}
    </Fragment>
  )
}

SoggettiGiaCorrelatiTable.propTypes = {
  list: PropTypes.array,
  domini: PropTypes.object
}

export default SoggettiGiaCorrelatiTable
