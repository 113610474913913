import * as actionTypes from './constants'
import { CUSTOMER_CREATE_SUCCESS, POST_DATA_REGISTRY_SUCCESS } from '../customers/constants'
import { SERVICE_SAVE_SUCCESS } from '../services/constants'
import {
  PRIVACY_CHECK_SUCCESS,
  MODULE_CHECK_SUCCESS,
  CONFIRM_PAPER_PRIVACY_SUCCESS,
  PRIVACY_CHECK_START,
  CONFIRM_PAPER_PRIVACY_START,
  PRIVACY_CHECK_ERROR,
  CONFIRM_PAPER_PRIVACY_ERROR
} from '../privacy/constants'

const initialState = {
  idPratica: null,
  tmpServizi: [],
  pratica: {
    servizi: [],
    intestatari: [],
    corrispondenza: {},
    contatti: {},
    tipoFirma: null
  },
  statoPratica: {},
  praticaRequest: {
    fetching: false,
    error: null
  },
  getPraticaPGRequest: {
    fetching: false,
    error: null
  },
  savePraticaRequest: {
    fetching: false,
    error: null
  },
  updateStatoPraticaRequest: {
    fetching: false,
    error: null
  },
  updateTipologiaClienteRequest: {
    fetching: false,
    error: null
  },
  updatePraticaRequest: {
    fetching: false,
    error: null
  },
  putPraticaPGRequest: {
    fetching: false,
    error: null
  },
  deletePraticaRequest: {
    fetching: false,
    error: null
  },
  praticaIntestatariRequest: {
    fetching: false,
    error: null
  },
  checkFirmaIntestatariRequest: {
    fetching: false,
    error: null
  },
  confirmPraticaRequest: {
    fetching: false,
    error: null
  },
  getStatoPraticaRequest: {
    fetching: false,
    error: null
  },
  updatePraticaDraftRequest: {
    fetching: false,
    error: null
  }
}

export default function(state = initialState, action) {
  let index
  let servizi
  switch (action.type) {
    case actionTypes.RESET_PRATICA:
      return {
        ...state,
        pratica: initialState.pratica
      }
    case SERVICE_SAVE_SUCCESS:
      servizi = state.pratica.servizi || []
      index = servizi.findIndex(s => s.idTipoServizio === parseInt(action.payload.idTipoServizio.chiave))
      if (servizi.length === 0 || index === -1) return state
      return {
        ...state,
        pratica: {
          ...state.pratica,
          servizi: [
            ...servizi.slice(0, index),
            {
              ...servizi[index],
              flagDatiServizioSalvati: true
            },
            ...servizi.slice(index + 1)
          ]
        }
      }
    case CUSTOMER_CREATE_SUCCESS:
      return {
        ...state,
        pratica: {
          ...state.pratica,
          ...action.pratica
        }
      }
    case actionTypes.PRATICA_GET_SUCCESS:
      return {
        ...state,
        pratica: {
          ...state.pratica,
          ...action.data.pratica,
          idPratica: action.idPratica
        },
        idPratica: action.idPratica,
        praticaRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRATICA_GET_START:
      return {
        ...state,
        praticaRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRATICA_GET_ERROR:
      return Object.assign({}, state, {
        praticaRequest: {
          fetching: false,
          error: action.error
        }
      })

    // get pratica pg
    case actionTypes.PRATICA_PG_GET_SUCCESS:
      return {
        ...state,
        pratica: {
          ...state.pratica,
          corrispondenzaPG: action.data.corrispondenzaPG,
          idPratica: action.idPratica
        },
        idPratica: action.idPratica,
        getPraticaPGRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRATICA_PG_GET_START:
      return {
        ...state,
        getPraticaPGRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRATICA_PG_GET_ERROR:
      return Object.assign({}, state, {
        getPraticaPGRequest: {
          fetching: false,
          error: action.error
        }
      })
    // end get pratica pg

    case actionTypes.PRATICA_INTESTATARI_GET_SUCCESS:
      return {
        ...state,
        flagFirmaPraticaInvestimento: action.data.flagFirmaPraticaInvestimento,
        praticaIntestatariRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRATICA_INTESTATARI_GET_START:
      return {
        ...state,
        flagFirmaPraticaInvestimento: false,
        praticaIntestatariRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRATICA_INTESTATARI_GET_ERROR:
      return {
        ...state,
        praticaIntestatariRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.PRATICA_DELETE_START:
      return Object.assign({}, state, {
        deletePraticaRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.PRATICA_DELETE_SUCCESS:
      return Object.assign({}, state, {
        deletePraticaRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.PRATICA_DELETE_ERROR:
      return Object.assign({}, state, {
        deletePraticaRequest: {
          fetching: false,
          error: action.error
        }
      })

    case actionTypes.PRATICA_SAVE_START:
      return Object.assign({}, state, {
        savePraticaRequest: {
          fetching: true,
          error: null
        }
      })
    case actionTypes.PRATICA_SAVE_SUCCESS:
      return {
        ...state,
        pratica: action.data,
        idPratica: action.data.idPratica,
        tmpServizi: action.services,
        savePraticaRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRATICA_SAVE_ERROR:
      return Object.assign({}, state, {
        savePraticaRequest: {
          fetching: false,
          error: action.error
        }
      })
    case actionTypes.UPDATE_TIPOLOGIA_CLIENTE_START: {
      return {
        ...state,
        updateTipologiaClienteRequest: {
          fetching: true,
          error: null
        }
      }
    }
    case actionTypes.UPDATE_TIPOLOGIA_CLIENTE_SUCCESS: {
      return {
        ...state,
        updateTipologiaClienteRequest: {
          fetching: false,
          error: null
        }
      }
    }
    case actionTypes.UPDATE_TIPOLOGIA_CLIENTE_ERROR: {
      return {
        ...state,
        updateTipologiaClienteRequest: {
          fetching: false,
          error: action.error
        }
      }
    }

    case actionTypes.PRATICA_CHECK_FIRMA_INTESTATARI_START:
      return {
        ...state,
        checkFirmaIntestatariRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRATICA_CHECK_FIRMA_INTESTATARI_SUCCESS:
      return {
        ...state,
        checkFirmaIntestatariRequest: {
          fetching: false,
          error: null
        },

        pratica: {
          ...state.pratica,
          ...action.flagDigitalSignatureEnabled
        }
      }
    case actionTypes.PRATICA_CHECK_FIRMA_INTESTATARI_ERROR:
      return {
        ...state,
        checkFirmaIntestatariRequest: {
          fetching: false,
          error: action.error
        }
      }
    // put pratica pg
    case actionTypes.PRATICA_PG_UPDATE_START:
      return {
        ...state,
        putPraticaPGRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRATICA_PG_UPDATE_SUCCESS:
      return {
        ...state,
        putPraticaPGRequest: {
          fetching: false,
          error: null
        },
        confirmPraticaRequest: {
          fetching: false,
          erro: null
        },
        pratica: {
          ...state.pratica,
          ...action.query
        }
      }
    case actionTypes.PRATICA_PG_UPDATE_ERROR:
      return {
        ...state,
        putPraticaPGRequest: {
          fetching: false,
          error: action.error
        }
      }
    // end put pratica pg

    case actionTypes.PRATICA_UPDATE_START:
      return {
        ...state,
        updatePraticaRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRATICA_UPDATE_SUCCESS:
      return {
        ...state,
        updatePraticaRequest: {
          fetching: false,
          error: null
        },
        confirmPraticaRequest: {
          fetching: false,
          erro: null
        },
        pratica: {
          ...state.pratica,
          ...action.query
        }
      }
    case actionTypes.PRATICA_UPDATE_ERROR:
      return {
        ...state,
        updatePraticaRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.SET_ID_PRATICA:
      return {
        ...state,
        pratica: {
          ...state.pratica,
          idPratica: action.idPratica
        }
      }
    case PRIVACY_CHECK_START:
    case CONFIRM_PAPER_PRIVACY_START:
      return {
        confirmPraticaRequest: {
          fetching: true,
          error: null
        }
      }

    case PRIVACY_CHECK_ERROR:
    case CONFIRM_PAPER_PRIVACY_ERROR:
      return {
        confirmPraticaRequest: {
          fetching: false,
          error: action.error
        }
      }

    case PRIVACY_CHECK_SUCCESS:
    case CONFIRM_PAPER_PRIVACY_SUCCESS:
      return {
        ...state,
        idLegalPerson: action.idLegalPerson,
        idPratica: action.idPratica,

        confirmPraticaRequest: {
          fetching: false,
          error: null
        }
      }
    case MODULE_CHECK_SUCCESS:
      return {
        ...state,
        idPratica: action.idPratica
      }

    case actionTypes.UPDATE_TIPO_FIRMA:
      return {
        ...state,
        pratica: {
          ...state.pratica,
          tipoFirma: action.tipoFirma
        }
      }

    case actionTypes.SET_LEGAL_PERSON:
      return {
        ...state,
        idLegalPerson: action.idLegalPerson,
        idPratica: action.idIncarico
      }

    case POST_DATA_REGISTRY_SUCCESS:
      if (state.pratica.intestatari.length > 0 && action.query) {
        return {
          ...state,
          pratica: {
            ...state.pratica,
            intestatari: [
              {
                ...state.pratica.intestatari[0],
                nome: action.query.nome,
                cognome: action.query.cognome
              },
              ...state.pratica.intestatari.slice(1)
            ]
          }
        }
      }
      return state
    case actionTypes.GET_STATO_PRATICA_START:
      return {
        ...state,
        getStatoPraticaRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_STATO_PRATICA_SUCCESS:
      return {
        ...state,
        statoPratica: {
          ...state.statoPratica,
          ...action.data
        },
        getStatoPraticaRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_STATO_PRATICA_ERROR:
      return {
        ...state,
        getStatoPraticaRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.PRATICA_UPDATE_DRAFT_START:
      return {
        ...state,
        updatePraticaDraftRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PRATICA_UPDATE_DRAFT_SUCCESS:
      return {
        ...state,
        pratica: {
          ...state.pratica,
          ...action.data
        },
        updatePraticaDraftRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PRATICA_UPDATE_DRAFT_ERROR:
      return {
        ...state,
        updatePraticaDraftRequest: {
          fetching: false,
          error: action.error
        }
      }
  }
  return state
}
