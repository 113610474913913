import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import '../_barchart.scss'

/* Da capire come gestire le percentuali */
export const Legend = props => (
  <div>
    {props.list.map((item, index) => (
      <Fragment key={index}>
        {/* LINE 1 */}
        <div style={{ marginTop: '-15px', alignItems: 'center' }}>
          {/* Colore di riferimento nel grafico */}
          <div style={{ display: 'inline-block' }}>
            <span
              style={{
                width: '25px',
                height: '11px',
                backgroundColor: `${item.color}`,
                display: 'block'
              }}
            />
          </div>
          {/* Titolo sezione */}
          <div style={{ display: 'inline-block', marginLeft: '30px' }}>
            <p style={{ fontSize: '15px' }}>{item.label}</p>
          </div>
        </div>
        {/* LINE 2 */}
        <div style={{ marginTop: '-30px', alignItems: 'center' }}>
          {/* Totale sezione */}
          <div style={{ display: 'inline-block', marginLeft: '52px', width: '310px' }}>
            <p className="donut donut__subtitle">{item.percentage}</p>
          </div>
        </div>
        {/* Separatore riga TODO DA RIFARE */}
        <hr
          className="donut donut__hr"
          style={props.list.length === index + 1 ? { display: 'none' } : { marginTop: '-10px', width: '500px' }}
        />
      </Fragment>
    ))}
  </div>
)
Legend.propTypes = {
  list: PropTypes.array
}
