import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ModalePopup from '../Popup'
import { resetValidations as resetMifidValidations } from '../../../routes/ConsulenzaBaseContainerLayout/QuestionarioProfilatura/mifidValidationUtils'

const dirtyMsg = 'Le modifiche non sono state salvate. Se prosegui perderai i dati inseriti.'
const previousMsg =
  'Nelle sezioni precedenti sono presenti dei campi obbligatori non compilati. Prosegui per completare i dati mancanti.'

class ModaleDirtyForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      isPrevious: false,
      isDirty: false,
      nextPath: '/'
    }
    this.isGoingHome = this.isGoingHome.bind(this)
    this.goTo = this.goTo.bind(this)
    this.checkPrevious = this.checkPrevious.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.counter !== this.props.counter) {
      const {
        checkForms,
        nextPath,
        history: {
          location: { pathname },
          push
        },
        isPrevious
      } = this.props
      // if (checkForms() && pathname !== nextPath) {
      //   this.setState({ nextPath, isPrevious, isDirty: true, show: true })
      // } else if ((isPrevious && !this.isGoingHome()) || (!isPrevious && pathname !== nextPath)) {
      //   this.setState({ nextPath, isPrevious, show: false })
      //   push(nextPath)
      // } else {
      //   push(nextPath)
      // }
      if (checkForms()) {
        this.setState({ nextPath, isPrevious, isDirty: true, show: true })
      } else if ((isPrevious && !this.isGoingHome()) || (!isPrevious && pathname === nextPath)) {
        this.setState({ nextPath, isPrevious, show: true })
      } else {
        push(nextPath)
      }
    }
  }

  isGoingHome() {
    return this.props.nextPath === '/'
  }

  checkPrevious() {
    if (this.state.isPrevious && this.state.isDirty && !this.isGoingHome()) {
      this.setState({ isDirty: false })
    } else {
      this.goTo()
    }
  }

  goTo() {
    resetMifidValidations()
    this.setState({ show: false, isDirty: false, isPrevious: false })
    this.props.history.push(this.state.nextPath)
  }

  render() {
    return (
      <ModalePopup
        visible={this.state.show}
        title="Attenzione!"
        text={this.state.isDirty ? dirtyMsg : previousMsg}
        primaryActionLabel="Continua"
        secondaryActionLabel="Annulla"
        secondaryAction={() => this.setState({ show: false })}
        primaryAction={this.checkPrevious}
      />
    )
  }
}
export default ModaleDirtyForm

ModaleDirtyForm.propTypes = {
  history: PropTypes.object,
  checkForms: PropTypes.func,
  isPrevious: PropTypes.bool,
  nextPath: PropTypes.string,
  counter: PropTypes.number
}
