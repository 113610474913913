import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { Field } from 'redux-form'
import {
  mapDominiToAutocomplete,
  getAutocompleteDefaultOption,
  getDominio,
  getValueDominioFromKey
} from '../../../routes/ConsulenzaBaseContainerLayout/utils'
import { renderAutocomplete, renderCheckbox } from '../renderFields'
import InputText from '../../Inputs/Text'

class DomicilioForm extends Component {
  constructor(props) {
    super(props)
    this.state = { waitingCap: false }
  }

  componentDidUpdate(prevProps) {
    const {
      fields: { CAP_DOMICILIO },
      change,
      dominioRequest,
      domini
    } = this.props
    if (prevProps.dominioRequest.fetching && !dominioRequest.fetching && this.state.waitingCap) {
      const capDom = domini.listaDomini.find(d => d.idDominio === CAP_DOMICILIO.dominio)
      if (capDom && Object.keys(capDom.valoriDominio).length === 1) {
        change(CAP_DOMICILIO.id, Object.keys(capDom.valoriDominio)[0])
      }
      this.setState({ waitingCap: false })
    }
  }

  checkNazione(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { NAZIONE_DOMICILIO }
    } = this.props
    if (isItalia) {
      return formValues[NAZIONE_DOMICILIO.id] === NAZIONE_DOMICILIO.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, NAZIONE_DOMICILIO.dominio, formValues[NAZIONE_DOMICILIO.id])
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA_DOMICILIO }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, PROVINCIA_DOMICILIO.dominio, value)
  }

  checkComune(value) {
    const {
      domini: { listaDomini },
      fields: { COMUNE_DOMICILIO }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, COMUNE_DOMICILIO.dominio, value)
  }

  checkCap() {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA_DOMICILIO, COMUNE_DOMICILIO, CAP_DOMICILIO },
      formValues
    } = this.props
    return (
      !this.checkNazione(true) ||
      !this.checkProvincia(formValues[PROVINCIA_DOMICILIO.id]) ||
      !this.checkComune(formValues[COMUNE_DOMICILIO.id]) ||
      Object.keys((listaDomini.find(d => d.idDominio === CAP_DOMICILIO.dominio) || {}).valoriDominio || {}).length === 1
    )
  }

  render() {
    const {
      domini,
      updateDominio,
      formValues,
      formErrors = {},
      // nameDisabled = false,
      fields,
      change,
      touch
    } = this.props

    const {
      RAGIONE_SOCIALE_DOMICILIO,
      NAZIONE_DOMICILIO,
      PROVINCIA_DOMICILIO,
      COMUNE_DOMICILIO,
      INDIRIZZO_DOMICILIO,
      CAP_DOMICILIO,
      COMUNE_ESTERO_DOMICILIO,
      CIVICO,
      HA_DOMICILIO
    } = fields

    return (
      <Fragment>
        {HA_DOMICILIO.id && (
          <Row>
            <Col s={12} className="m-top20">
              <Field name={HA_DOMICILIO.id} label={HA_DOMICILIO.label} component={renderCheckbox} />
            </Col>
          </Row>
        )}

        {formValues[HA_DOMICILIO.id] ? (
          <Fragment>
            <Row>
              <Field
                name={RAGIONE_SOCIALE_DOMICILIO.id}
                s={6}
                maxLength={50}
                label={RAGIONE_SOCIALE_DOMICILIO.label}
                error={formErrors[RAGIONE_SOCIALE_DOMICILIO.id]}
                value={formValues[RAGIONE_SOCIALE_DOMICILIO.id]}
                change={change}
                touch={touch}
                component={InputText}
              />
            </Row>
            <Row>
              <Field
                name={INDIRIZZO_DOMICILIO.id}
                s={6}
                maxLength={40}
                label={INDIRIZZO_DOMICILIO.label}
                error={formErrors[INDIRIZZO_DOMICILIO.id]}
                value={formValues[INDIRIZZO_DOMICILIO.id]}
                change={change}
                touch={touch}
                component={InputText}
              />
              {CIVICO && (
                <Field
                  name={CIVICO.id}
                  s={6}
                  maxLength={40}
                  label={CIVICO.label}
                  error={formErrors[CIVICO.id]}
                  value={formValues[CIVICO.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              )}
            </Row>
            <Row>
              <Col s={6}>
                <Field
                  name={NAZIONE_DOMICILIO.id}
                  s={12}
                  label={NAZIONE_DOMICILIO.label}
                  threshold={0}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    NAZIONE_DOMICILIO.dominio,
                    formValues[NAZIONE_DOMICILIO.id]
                  )}
                  options={mapDominiToAutocomplete(getDominio(domini, NAZIONE_DOMICILIO.dominio))}
                  handleChange={(field, value) => {
                    change(field, value)
                    if (formValues[PROVINCIA_DOMICILIO.id]) {
                      change(PROVINCIA_DOMICILIO.id, '')
                    }
                    if (formValues[COMUNE_DOMICILIO.id]) {
                      change(COMUNE_DOMICILIO.id, '')
                    }
                    if (formValues[COMUNE_ESTERO_DOMICILIO.id]) {
                      change(COMUNE_ESTERO_DOMICILIO.id, '')
                    }
                    if (formValues[CAP_DOMICILIO.id]) {
                      change(CAP_DOMICILIO.id, '')
                    }
                  }}
                  component={renderAutocomplete}
                />
              </Col>
              <Col s={6}>
                <Field
                  name={PROVINCIA_DOMICILIO.id}
                  s={12}
                  label={PROVINCIA_DOMICILIO.label}
                  threshold={0}
                  disabled={!this.checkNazione(true)}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    PROVINCIA_DOMICILIO.dominio,
                    formValues[PROVINCIA_DOMICILIO.id]
                  )}
                  options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_DOMICILIO.dominio))}
                  handleChange={(field, value) => {
                    change(field, value)
                    if (formValues[COMUNE_DOMICILIO.id]) {
                      change(COMUNE_DOMICILIO.id, '')
                    }
                    if (formValues[CAP_DOMICILIO.id]) {
                      change(CAP_DOMICILIO.id, '')
                    }
                    if (this.checkProvincia(value)) {
                      updateDominio(COMUNE_DOMICILIO.dominio, value)
                    }
                  }}
                  component={renderAutocomplete}
                />
              </Col>
            </Row>
            <Row>
              {this.checkNazione(true) && (
                <Col s={6}>
                  <Field
                    name={COMUNE_DOMICILIO.id}
                    s={12}
                    threshold={0}
                    label={COMUNE_DOMICILIO.label}
                    disabled={!this.checkProvincia(formValues[PROVINCIA_DOMICILIO.id])}
                    defaultValue={getAutocompleteDefaultOption(
                      domini.listaDomini,
                      COMUNE_DOMICILIO.dominio,
                      formValues[COMUNE_DOMICILIO.id]
                    )}
                    options={
                      formValues[PROVINCIA_DOMICILIO.id]
                        ? mapDominiToAutocomplete(getDominio(domini, COMUNE_DOMICILIO.dominio))
                        : []
                    }
                    handleChange={(field, value) => {
                      change(field, value)
                      if (formValues[CAP_DOMICILIO.id]) {
                        change(CAP_DOMICILIO.id, '')
                      }
                      if (this.checkComune(value)) {
                        this.setState({ waitingCap: true })
                        updateDominio(CAP_DOMICILIO.dominio, value)
                      }
                    }}
                    component={renderAutocomplete}
                  />
                </Col>
              )}
              {!this.checkNazione(true) && (
                <Field
                  name={COMUNE_ESTERO_DOMICILIO.id}
                  s={6}
                  maxLength={50}
                  disabled={!this.checkNazione()}
                  label={COMUNE_ESTERO_DOMICILIO.label}
                  error={formErrors[COMUNE_ESTERO_DOMICILIO.id]}
                  value={formValues[COMUNE_ESTERO_DOMICILIO.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              )}

              <Col s={3} offset="s3">
                <Field
                  name={CAP_DOMICILIO.id}
                  s={12}
                  threshold={0}
                  label={CAP_DOMICILIO.label}
                  disabled={this.checkCap()}
                  defaultValue={getAutocompleteDefaultOption(
                    domini.listaDomini,
                    CAP_DOMICILIO.dominio,
                    formValues[CAP_DOMICILIO.id]
                  )}
                  options={
                    formValues[COMUNE_DOMICILIO.id]
                      ? mapDominiToAutocomplete(getDominio(domini, CAP_DOMICILIO.dominio))
                      : []
                  }
                  handleChange={change}
                  component={renderAutocomplete}
                />
              </Col>
            </Row>
          </Fragment>
        ) : null}
      </Fragment>
    )
  }
}
export default DomicilioForm

DomicilioForm.propTypes = {
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  // nameDisabled: PropTypes.bool,
  domini: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  updateDominio: PropTypes.func,
  dominioRequest: PropTypes.object,
  fields: PropTypes.shape({
    NOME: PropTypes.object,
    COGNOME: PropTypes.object,
    INDIRIZZO: PropTypes.object.isRequired,
    NAZIONE: PropTypes.object.isRequired,
    PROVINCIA: PropTypes.object.isRequired,
    COMUNE: PropTypes.object.isRequired,
    CAP: PropTypes.object.isRequired
  })
}
