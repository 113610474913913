import React from 'react'

const getValoriDominio = (dominio = {}) => dominio.valoriDominio || {}

export const getDominio = ({ listaDomini = [] }, key) => {
  return getValoriDominio(listaDomini.find(({ idDominio }) => idDominio === key))
}

export const mapDominiToOptions = (dominiList = {}, defaultOption) => {
  const array = []
  if (defaultOption) {
    array.push(
      <option key={-1} value="" disabled>
        {' '}
        {'-'}{' '}
      </option>
    )
  }
  return Object.keys(dominiList).reduce((res, key) => {
    return [
      ...res,
      <option key={`${key}`} value={key}>
        {dominiList[key]}
      </option>
    ]
  }, array)
}

/**
 * Return the value of the key passed for the specific Domain
 */
export const getValueDominioFromKey = (dominiList, dominioId, dominioItemKey) => {
  let valueMatch = ''
  dominiList.forEach(function(element) {
    if (element.idDominio === dominioId) {
      valueMatch = element.valoriDominio[dominioItemKey]
    }
  })
  return valueMatch
}

export function isStatusResponseInvalid(response = {}) {
  const { data = {} } = response
  return data.statusResponse && data.statusResponse.code && data.statusResponse.code !== '0'
}

/**
 *
 * @param {object} fields - a classic field object
 * @param {array} list - the list to get the item from
 * @param {object} index - index of the item
 */
export const populateForm = (fields, list = [], index) => {
  const item = list[index]
  return Object.keys(fields).reduce(
    (res, cur) => ({
      ...res,
      [fields[cur].id]: item ? item[fields[cur].id] : fields[cur].defaultValue
    }),
    {}
  )
}

export const getTotal = (list, field) => {
  let sum = 0.0
  if (list.length > 0) {
    list.map(item => (sum += parseFloat(convertNumber(item[field]))))
  }
  return sum
}

export const convertNumber = input => {
  let integers = input.split(',')[0]
  let decimals = input.split(',')[1]
  let replaced = integers.replace(/\./g, '')
  let result = parseInt(replaced) + '.' + parseInt(decimals)
  return result
}

// utility per validazione importi
export const validateNumber = input => {
  if (input.match(/[0-9]/g)) {
    let integers = input.split(',')[0]
    let decimals = input.split(',')[1]
    let replacedIntegers = integers.replace(/\./g, '')
    let replacedDecimals = decimals.replace(/\./g, '')
    return isNaN(replacedIntegers) || isNaN(replacedDecimals) || !checkIntegersAndDecimals(integers, decimals)
  } else {
    return true
  }
}

// utility per controllare se sono presenti '.' consecutivi o formattazioni incomplete
export const checkIntegersAndDecimals = (integers, decimals) => {
  // verifico la NON presenza di '.' consecutivi e la corretta formattazione dell'importo
  let integersCondition =
    integers.split('.').filter(item => item === '').length === 0 &&
    integers.split('.').filter((item, index) => {
      if (index === 0 && integers.split('.').length > 1) {
        return item.length > 3
      } else if (index !== 0 && integers.split('.').length > 1) {
        return item.length !== 3
      }
    }).length === 0
  let decimalsCondition = decimals.split('.').filter(item => item === '').length === 0
  return integersCondition && decimalsCondition
}

// formatter per IMPORTI e VALUTE
export const printNumber = input => Number(input).toLocaleString('it', { minimumFractionDigits: 2 })

// utility per reducer PATRIMONIO
export const getSection = input => input.split('_')[1].toLowerCase()

// utility per barra di avanzamento in PATRIMONIO
export const calculatePercentage = (totalField, totalList) => parseInt(Math.ceil((totalField * 100) / totalList))
