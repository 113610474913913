import React from 'react'
import PropTypes from 'prop-types'
import { Section } from '../Section'

export const Chart = props => (
  <g>
    {props.list.map((item, index) => (
      <Section {...props} item={item} key={index} />
    ))}
  </g>
)
Chart.propTypes = {
  list: PropTypes.array
}
