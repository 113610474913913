import * as actionTypes from './constants'
import { GET_SUMMARY_DATA_FULL_SUCCESS, RESET_CUSTOMER } from '../customers/constants'

const apiRequests = {
  amlRequest: {
    fetching: false,
    error: null
  },
  amlPgRequest: {
    fetching: false,
    error: null
  },
  amlSaveRequest: {
    fetching: false,
    error: null
  },
  amlPgSaveRequest: {
    fetching: false,
    error: null
  },
  mifidRequest: {
    fetching: false,
    error: null
  },
  mifidSaveRequest: {
    fetching: false,
    error: null
  },
  adegVerTemplateRequest: {
    fetching: false,
    error: null
  },
  adegVerTemplatePGRequest: {
    fetching: false,
    error: null
  },
  adegVerSaveRequest: {
    fetching: false,
    error: null
  },
  professionaleRequest: {
    fetching: false,
    error: null
  },
  postProfessionaleRequest: {
    fetching: false,
    error: null
  },
  getProfessionaleRequest: {
    fetching: false,
    error: null
  },
  getSurveyExtRequest: {
    fetching: false,
    error: null
  },
  importSurveyExtRequest: {
    fetching: false,
    error: null
  },
  importSurveyExtAVRequest: {
    fetching: false,
    error: null
  }
}

const initialState = {
  template: {},
  templateAml: {},
  templateAV: null,
  templatePGAV: null,
  previousForm: { flagProfilatura: true },
  amlPreviousForm: {},
  amlPgPreviousForm: {},
  amlSent: false,
  amlPgSent: false,
  isMifidInconsistent: false,
  isProfessionale: false
}

export default function(state = { ...initialState, ...apiRequests }, action) {
  switch (action.type) {
    case RESET_CUSTOMER:
      return {
        ...state,
        ...initialState
      }
    case actionTypes.SURVEY_MIFID_FETCH_START:
      return {
        ...state,
        mifidRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SURVEY_MIFID_FETCH_SUCCESS:
      return {
        ...state,
        previousForm: {
          ...state.previousForm,
          ...action.dataMifid
        },
        template: action.dataTemplateMifid,
        mifidRequest: {
          fetching: false,
          error: null
        },
        isMifidInconsistent: action.isMifidInconsistent
      }
    case actionTypes.SURVEY_MIFID_FETCH_ERROR:
      return {
        ...state,
        mifidRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.SURVEY_MIFID_SAVE_START:
      return {
        ...state,
        mifidSaveRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SURVEY_MIFID_SAVE_SUCCESS:
      return {
        ...state,
        previousForm: {
          ...state.previousForm,
          flagProfilatura: action.template.flagProfilatura
        },
        mifidSaveRequest: {
          fetching: false,
          error: null
        },
        isMifidInconsistent: action.isMifidInconsistent
      }
    case actionTypes.SURVEY_MIFID_SAVE_ERROR:
      return {
        ...state,
        mifidSaveRequest: {
          fetching: false,
          error: action.error
        }
      }

    case GET_SUMMARY_DATA_FULL_SUCCESS:
      return {
        ...state,
        templateAml: action.templateAml,
        template: action.template
      }

    case actionTypes.SURVEY_AML_FETCH_START:
      return {
        ...state,
        amlRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SURVEY_AML_FETCH_SUCCESS:
      const surveryDataTemplateAml = Object.assign(action.dataTemplateAml)

      return Object.assign({}, state, {
        amlPreviousForm: action.dataAml,
        templateAml: { ...surveryDataTemplateAml },
        amlRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.SURVEY_AML_FETCH_ERROR:
      return {
        ...state,
        amlRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.SURVEY_AML_PG_FETCH_START:
      return {
        ...state,
        amlPgRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SURVEY_AML_PG_FETCH_SUCCESS:
      const surveryDataTemplateAmlPg = Object.assign(action.dataTemplateAml)

      return Object.assign({}, state, {
        amlPgPreviousForm: action.dataAml,
        templateAmlPg: { ...surveryDataTemplateAmlPg },
        amlPgRequest: {
          fetching: false,
          error: null
        }
      })
    case actionTypes.SURVEY_AML_PG_FETCH_ERROR:
      return {
        ...state,
        amlPgRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.SURVEY_AGG_AML_SAVE_START:
    case actionTypes.SURVEY_AML_SAVE_START:
      return {
        ...state,
        amlSaveRequest: {
          fetching: true,
          error: null
        },
        amlSent: false
      }
    case actionTypes.SURVEY_AGG_AML_SAVE_SUCCESS:
    case actionTypes.SURVEY_AML_SAVE_SUCCESS:
      return {
        ...state,
        amlPreviousForm: action.payload,
        amlSaveRequest: {
          fetching: false,
          error: null
        },
        amlSent: true
      }
    case actionTypes.SURVEY_AGG_AML_SAVE_ERROR:
    case actionTypes.SURVEY_AML_SAVE_ERROR:
      return {
        ...state,
        amlSaveRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.SURVEY_AGG_AML_PG_SAVE_START:
    case actionTypes.SURVEY_AML_PG_SAVE_START:
      return {
        ...state,
        amlPgSaveRequest: {
          fetching: true,
          error: null
        },
        amlPgSent: false
      }
    case actionTypes.SURVEY_AGG_AML_PG_SAVE_SUCCESS:
    case actionTypes.SURVEY_AML_PG_SAVE_SUCCESS:
      return {
        ...state,
        amlPgPreviousForm: action.payload,
        amlPgSaveRequest: {
          fetching: false,
          error: null
        },
        amlPgSent: true
      }
    case actionTypes.SURVEY_AGG_AML_PG_SAVE_ERROR:
    case actionTypes.SURVEY_AML_PG_SAVE_ERROR:
      return {
        ...state,
        amlPgSaveRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_START:
      return {
        ...state,
        adegVerTemplateRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_SUCCESS:
      return {
        ...state,
        templateAV: action.template,
        adegVerTemplateRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_ERROR:
      return {
        ...state,
        adegVerTemplateRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_PG_START:
      return {
        ...state,
        adegVerTemplatePGRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_PG_SUCCESS:
      return {
        ...state,
        templatePGAV: action.template,
        adegVerTemplatePGRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_PG_ERROR:
      return {
        ...state,
        adegVerTemplatePGRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.SAVE_ADEGUATA_VERIFICA_START:
      return {
        ...state,
        adegVerSaveRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.SAVE_ADEGUATA_VERIFICA_SUCCESS:
      return {
        ...state,
        adegVerSaveRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.SAVE_ADEGUATA_VERIFICA_ERROR:
      return {
        ...state,
        adegVerSaveRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.PROFESSIONALE_START:
      return {
        ...state,
        professionaleRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.PROFESSIONALE_SUCCESS:
      return {
        ...state,
        isProfessionale: action.isProfessionale,
        professionaleRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.PROFESSIONALE_ERROR:
      return {
        ...state,
        professionaleRequest: {
          fetching: false,
          error: action.error
        }
      }
    //
    case actionTypes.POST_PROFESSIONALE_PG_START:
      return {
        ...state,
        postProfessionaleRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.POST_PROFESSIONALE_PG_SUCCESS:
      return {
        ...state,
        professionalSubjectType: action.professionalSubjectType,
        postProfessionaleRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.POST_PROFESSIONALE_PG_ERROR:
      return {
        ...state,
        postProfessionaleRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_PROFESSIONALE_PG_START:
      return {
        ...state,
        getProfessionaleRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_PROFESSIONALE_PG_SUCCESS:
      return {
        ...state,
        professionalSubjectType: action.professionalSubjectType,
        getProfessionaleRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_PROFESSIONALE_PG_ERROR:
      return {
        ...state,
        getProfessionaleRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_SURVEY_EXT_START:
      return {
        ...state,
        getSurveyExtRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_SURVEY_EXT_SUCCESS:
      return Object.assign({}, state, {
        templateAml: { ...action.data },
        getSurveyExtRequest: {
          fetching: false,
          error: null
        }
      })

    case actionTypes.GET_SURVEY_EXT_ERROR:
      return {
        ...state,
        getSurveyExtRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.IMPORT_SURVEY_EXT_START:
      return {
        ...state,
        importSurveyExtRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.IMPORT_SURVEY_EXT_SUCCESS:
      return {
        ...state,
        importSurveyExtRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.IMPORT_SURVEY_EXT_ERROR:
      return {
        ...state,
        importSurveyExtRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.IMPORT_SURVEY_EXT_AV_START:
      return {
        ...state,
        importSurveyExtRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.IMPORT_SURVEY_EXT_AV_SUCCESS:
      return {
        ...state,
        amlPreviousForm: action.data,
        importSurveyExtRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.IMPORT_SURVEY_EXT_AV_ERROR:
      return {
        ...state,
        importSurveyExtRequest: {
          fetching: false,
          error: action.error
        }
      }
  }
  return state
}
