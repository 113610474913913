import { connect } from 'react-redux'
import { reduxForm, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import Fatca from './FatcaComponent'
import { FATCA } from '../../../../store/modules/forms/constants'
import { notBelongToDominio, loadDataFatca, loadDefaultFields } from '../../utils'
import allFields from '../../fields'

const fields = allFields[1][FATCA]
const defaultFields = loadDefaultFields(fields)

const validate = (values, props = {}) => {
  const errors = {}
  const { domini = {} } = props
  const {
    TIN,
    LISTA: {
      id,
      dominio,
      lista: { STATO, CODICE_FISCALE }
    }
  } = fields
  const tinCF = new RegExp(TIN.validazione)

  if (!!values[TIN.id] && !tinCF.test(values[TIN.id])) {
    errors[TIN.id] = 'Il campo TIN può contenere solo caratteri alfanumerici'
  }
  if (values[id] && values[id].length) {
    let array = []
    values[id].map((el, index) => {
      const error = {}
      if (el && el[CODICE_FISCALE.id] && !tinCF.test(el[CODICE_FISCALE.id])) {
        error[CODICE_FISCALE.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
        array[index] = error
      }
      if (el && el[STATO.id] && notBelongToDominio(domini, dominio, el[STATO.id])) {
        error[STATO.id] = 'Nessun risultato trovato'
        array[index] = error
      }
    })
    if (array.length > 0) {
      errors[id] = array
    }
  }

  return errors
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadDataFatca(state.customers.dataFatca, fields)
  },
  formValues: getFormValues(FATCA)(state),
  formErrors: getFormSyncErrors(FATCA)(state),
  fields,
  isDirty: isDirty(FATCA)(state),
  isFetching: state.customers.postFatcaRequest.fetching,
  apiError: state.customers.postFatcaRequest.error
}))(
  reduxForm({
    form: FATCA,
    validate,
    shouldError: () => isDirty(FATCA)
  })(Fatca)
)
