import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { arc as d3Arc } from 'd3-shape'

export class Section extends Component {
  constructor(props) {
    super(props)
    this.state = { isHovered: false }
    this.arc = null
    this.onMouseOver = this.onMouseOver.bind(this)
    this.onMouseOut = this.onMouseOut.bind(this)
  }

  onMouseOver() {
    this.setState({ isHovered: true })
  }

  onMouseOut() {
    this.setState({ isHovered: false })
  }

  render() {
    const { width, height, item, radius, padAngle } = this.props
    /* Animazione sul hover della sezione */
    if (this.state.isHovered) {
      const outerRadius = radius * 1.08
      this.arc = d3Arc()
        .outerRadius(outerRadius - 35)
        .innerRadius(radius - 70)
        .padAngle(padAngle)
    } else {
      /* Arco di default */
      this.arc = d3Arc()
        .outerRadius(this.props.radius - 35)
        .innerRadius(this.props.radius - 70)
        .padAngle(this.props.padAngle)
    }
    return (
      <g transform={`translate(${width / 4}, ${height / 2})`} className="arc" key={`a${item.data.id}`}>
        <path d={this.arc(item)} fill={item.data.color} onMouseOver={this.onMouseOver} onMouseOut={this.onMouseOut} />
      </g>
    )
  }
}
Section.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  item: PropTypes.object,
  radius: PropTypes.number,
  padAngle: PropTypes.number
}
