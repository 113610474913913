import React from 'react'
import { PropTypes } from 'prop-types'
import { Button, Row } from 'react-materialize'

const ButtonSubmit = ({ disabled, label = 'Salva' }) => (
  <Row className="right-align submit-collapsible-item">
    <Button flat type="submit" disabled={disabled} className="custom-btn primary-reverse">
      {label}
    </Button>
  </Row>
)
export default ButtonSubmit

ButtonSubmit.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string
}
