import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FirmaCartacea from '../components/FirmaCartaceaComponent'
import { updateStatoPratica, updatePraticaDraft, updatePratica } from '../../../store/modules/pratica/actions'
import {
  getFirma,
  saveDocument,
  getNamirialUrl,
  downloadZip,
  checkDownloadZip
} from '../../../store/modules/utility/actions'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveDocument,
      getFirma,
      getNamirialUrl,
      updateStatoPratica,
      downloadZip,
      checkDownloadZip,
      updatePraticaDraft,
      updatePratica
    },
    dispatch
  )

const mapStateToProps = state => ({
  listaClienti: state.utility.listaClienti,
  listaDocumenti: state.utility.listaDocumenti,
  utilityGetFirmaRequest: state.utility.utilityGetFirmaRequest,
  downloadZipRequest: state.utility.downloadZipRequest,
  utilitySaveDocumentRequest: state.utility.utilitySaveDocumentRequest,
  utilityGetNamirialUrlRequest: state.utility.utilityGetNamirialUrlRequest,
  checkDownloadZipRequest: state.utility.checkDownloadZipRequest,
  checkDownload: state.utility.checkDownload,
  namirialUrl: state.utility.namirialUrl,
  idIncarico: state.pratica.idPratica,
  file: state.utility.file,
  updatePraticaDraftRequest: state.pratica.updatePraticaDraftRequest,
  pratica: state.pratica.pratica,
  updatePraticaRequest: state.pratica.updatePraticaRequest
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirmaCartacea)
