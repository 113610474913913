import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'react-materialize'
import ToggleBlurText from '../../../components/ToggleBlurText'
import moment from 'moment'
import { debounce } from 'lodash'
// import HomeFiltersChips from './HomeFiltersChips'
import DatePicker from '../../../components/DatePicker/DatePicker'
import Autocomplete from '../../../components/Autocomplete'
import $ from 'jquery'

const statusOptions = [
  { desc: 'Bozza', value: 1 },
  { desc: 'Da Firmare', value: 2 },
  { desc: 'AV da compilare', value: 3 },
  { desc: 'Completata', value: 4 },
  { desc: 'In lavorazione', value: 11 },
  { desc: 'Sospesa', value: 13 },
  { desc: 'Elaborata', value: 14 },
  { desc: 'Condivisa', value: 17 },
  { desc: 'Richiamata', value: 18 },
  { desc: 'Scaduta', value: 19 }
]

const serviceOptions = [
  { desc: 'Onboarding Persona Fisica', value: 0 },
  { desc: 'MAX', value: 1 },
  { desc: 'MAX Fund', value: 2 },
  { desc: 'RTO Banco BPM S.P.A.', value: 3 },
  { desc: 'RTO UBS Europe SE', value: 4 },
  { desc: 'Collocamento', value: 6 },
  { desc: 'RTO Banca IMI', value: 7 },
  { desc: 'Onboarding Persona Giuridica', value: 8 },
  { desc: 'Conto Deposito Liquidità', value: 9 },
  { desc: 'Deposito PIR Alternative', value: 10 },
  { desc: 'Antiriciclaggio', value: 11 }
]

const signOptions = [{ desc: 'Firma Digitale', value: 0 }, { desc: 'Firma Cartacea', value: 1 }]

class HomeFilters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchDrawerCollapse: false,
      descComposed: '',
      startDate: null,
      endDate: null,
      status: [],
      service: [],
      sign: [],
      customerSelected: null,
      customerSelectedValue: '',
      observer: null
    }

    this.resetFields = this.resetFields.bind(this)
    this.handleStatusChange = this.handleStatusChange.bind(this)
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this)
    this.handleStartDateChange = this.handleStartDateChange.bind(this)
    this.handleEndDateChange = this.handleEndDateChange.bind(this)
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this)
    this.handleRemoveStatus = this.handleRemoveStatus.bind(this)
    this.debouncedUpdateCondition = debounce(this.props.updateCondition, 500)
    this.getSearchBoxHeight = this.getSearchBoxHeight.bind(this)
    this.getSearchBoxTop = this.getSearchBoxTop.bind(this)
    this.handleServiceChange = this.handleServiceChange.bind(this)
    this.handleSignChange = this.handleSignChange.bind(this)
  }

  componentDidMount() {
    this.props.servicesSearch({
      conditions: [],
      pageBy: {
        pageRequest: 1,
        pageSize: 20
      }
    })
  }

  componentDidUpdate(prevProps) {
    const {
      query: { conditions },
      clienti
    } = this.props
    if (
      conditions.length > prevProps.query.conditions.length ||
      (conditions.length === prevProps.query.conditions.length &&
        (conditions.find(c => c.field === 'idCustomer') || {}).value !==
          (prevProps.query.conditions.find(c => c.field === 'idCustomer') || {}).value)
    ) {
      const idCliente = (conditions.find(el => el.field === 'idCustomer') || {}).value
      if (idCliente) {
        let customerSelectedValue = (this.mapClientiToAutocomplete(clienti).find(c => c.key === idCliente) || {}).label
        if (customerSelectedValue) {
          this.setState({ customerSelectedValue })
        }
      }
    }

    if (
      conditions.length < prevProps.query.conditions.length &&
      prevProps.query.conditions.find(c => c.field === 'idCustomer') &&
      !conditions.find(c => c.field === 'idCustomer')
    ) {
      this.setState({ customerSelected: null, customerSelectedValue: '' })
    }
  }

  handleCustomerSelect(customerSelectedValue, customerSelected) {
    this.setState({ customerSelected, customerSelectedValue })
    if (customerSelected) {
      this.debouncedUpdateCondition('idCustomer', customerSelected.key)
    } else if (customerSelectedValue === '') {
      this.debouncedUpdateCondition('idCustomer', '')
    } else {
      this.debouncedUpdateCondition('idCustomer', null, true)
    }
  }

  resetFields() {
    const { query, servicesSearch } = this.props

    this.setState({
      customerSelected: null,
      customerSelectedValue: '',
      descComposed: '',
      startDate: null,
      endDate: null,
      status: []
    })
    servicesSearch({ ...query, conditions: [] })
  }

  // funzione eseguita ricercando per cliente nella HomePage
  handleSearchTextChange(value) {
    this.props.updateCondition('idCustomer', '')
    this.setState({ customerSelectedValue: '' })
  }

  // funzione eseguita ricercando per data iniziale nella HomePage
  handleStartDateChange(startDate) {
    this.setState({ startDate })
    this.props.updateCondition('startDate', startDate)
  }

  // funzione eseguita ricercando per data finale nella HomePage
  handleEndDateChange(endDate) {
    this.setState({ endDate })
    this.props.updateCondition('endDate', endDate)
  }

  handleRemoveStatus(toBeRemoved) {
    const status = this.state.status.filter(s => s !== toBeRemoved)
    this.setState({ status })
    this.props.updateCondition('idStatus', status)
  }

  // funzione eseguita ricercando per stato nella HomePage
  handleStatusChange(array) {
    let status = array.map(s => parseFloat(s))
    if (status.find(s => s === -1)) {
      status = []
    }
    this.setState({ status })
    this.props.updateCondition('idStatus', status)
  }

  // funzione eseguita ricercando per tipologia di servizio nella HomePage
  handleServiceChange(array) {
    // se seleziono onboarding persona fisica devo aggiungere anche id 5
    let _array = array.includes('0') ? [...array, '5'] : [...array]
    let service = _array.map(s => parseFloat(s))
    if (service.find(s => s === -1)) {
      service = []
    }
    this.setState({ service })
    this.props.updateCondition('idTipoServizio', service)
  }

  // funzione eseguita ricercando per modalità di firma nella HomePage
  handleSignChange(array) {
    let sign = array.map(s => parseFloat(s))
    if (sign.find(s => s === -1)) {
      sign = []
    }
    this.setState({ sign })
    this.props.updateCondition('tipoFirmaCartacea', sign)
  }

  // funzione che crea un'array con gli utenti
  mapClientiToAutocomplete(arrayUsers) {
    let usersForAutocomplete = []
    arrayUsers.forEach(function(user) {
      let valueToSearch, label
      // PF
      if (user.nome && user.cognome) {
        valueToSearch = user.nome + ' ' + user.cognome + ' ' + user.nome + ' ' + user.codiceFiscale
        label = user.nome + ' ' + user.cognome
      }
      // PG/PD
      if (!user.nome && user.cognome) {
        valueToSearch = user.cognome + ' ' + user.codiceFiscale
        label = user.cognome
      }
      // Fallback
      if (!user.nome && !user.cognome) {
        valueToSearch = user.codiceFiscale
        label = valueToSearch
      }
      usersForAutocomplete.push({
        key: user.id,
        value: user.id,
        valueToSearch,
        label: label
      })
    })
    return usersForAutocomplete
  }

  getSearchBoxHeight() {
    if (this.state.searchDrawerCollapse) {
      const height = $('.chips-container').height()
      if (height > 26) {
        return `${40 + height}px`
      }
      return '40px'
    }
    return '50px'
  }

  getSearchBoxTop() {
    if (this.state.searchDrawerCollapse) {
      const height = $('.chips-container').height()
      if (height > 26) {
        return `${-40 - height / 2}px`
      }
      return '-40px'
    }
    return '0'
  }

  render() {
    let clienti = this.mapClientiToAutocomplete(this.props.clienti)
    return (
      <div className="search-box padding-r-10 padding-l-10">
        <div
          className={`search-box-content padding-10 ${this.state.searchDrawerCollapse ? 'closed' : ''}`}
          style={{ maxHeight: this.getSearchBoxHeight() }}>
          <Autocomplete
            id="customer"
            placeholder="Nome, Cognome o CF"
            threshold={0}
            suggestions={clienti}
            onChange={this.handleCustomerSelect}
            value={this.state.customerSelectedValue}
            searchByValue
          />
          <DatePicker
            placeholder="Data inizio"
            onDateChange={date => this.handleStartDateChange(date)}
            isOutsideRange={date => date.isAfter(moment(), 'day') || date.isAfter(moment(this.state.endDate))}
            defaultDate={this.state.startDate}
            readOnly
          />
          <DatePicker
            placeholder="Data fine"
            onDateChange={date => this.handleEndDateChange(date)}
            isOutsideRange={date => date.isAfter(moment(), 'day') || date.isBefore(moment(this.state.startDate))}
            defaultDate={this.state.endDate}
            readOnly
          />
          <Input
            type="select"
            className={this.state.status.length > 0 ? 'selected-active' : ''}
            multiple
            onChange={(ev, value) => this.handleStatusChange(value)}
            value={this.state.status}>
            <option value={-1} disabled>
              Seleziona stato
            </option>
            {statusOptions.map((el, index) => (
              <option
                key={`option-${el.value}`}
                className={this.state.status.find(i => i === index + 1) ? 'selected' : ''}
                value={el.value}>
                {el.desc}
              </option>
            ))}
          </Input>
        </div>
        <div
          className={`search-box-content padding-10 no-padding-left ${this.state.searchDrawerCollapse ? 'closed' : ''}`}
          style={{ maxHeight: this.getSearchBoxHeight() }}>
          <Input
            type="select"
            className={this.state.service.length > 0 ? 'selected-active' : ''}
            multiple
            onChange={(ev, value) => this.handleServiceChange(value)}
            value={this.state.service}>
            <option value={-1} disabled>
              Tipologia di servizio
            </option>
            {serviceOptions.map((el, index) => (
              <option
                key={`option-${el.value}`}
                className={this.state.service.find(i => i === index + 1) ? 'selected' : ''}
                value={el.value}>
                {el.desc}
              </option>
            ))}
          </Input>
          <Input
            type="select"
            className={this.state.sign.length > 0 ? 'selected-active' : ''}
            multiple
            onChange={(ev, value) => this.handleSignChange(value)}
            value={this.state.sign}>
            <option value={-1} disabled>
              Modalità di firma
            </option>
            {signOptions.map((el, index) => (
              <option
                key={`option-${el.value}`}
                className={this.state.sign.find(i => i === index + 1) ? 'selected' : ''}
                value={el.value}>
                {el.desc}
              </option>
            ))}
          </Input>
        </div>
        <div
          className={`search-box-handle padding-r-5 padding-l-5 ${this.state.searchDrawerCollapse ? 'closed' : ''}`}
          style={{ top: this.getSearchBoxTop() }}>
          <div>
            {/* <HomeFiltersChips
              query={this.props.query}
              resetFields={this.resetFields}
              handleCustomerSelect={this.handleCustomerSelect}
              handleSearchTextChange={this.handleSearchTextChange}
              handleStartDateChange={this.handleStartDateChange}
              handleEndDateChange={this.handleEndDateChange}
              handleStatusChange={this.handleRemoveStatus}
              customerSelected={this.state.customerSelectedValue}
              statusOptions={statusOptions}
            /> */}
            <ToggleBlurText />
            {/* <span
              className="search-toggle"
              onClick={() => this.setState({ searchDrawerCollapse: !this.state.searchDrawerCollapse })}>
              <span>Ricerca</span>
              <Icon className="search-icon">keyboard_arrow_down</Icon>
            </span> */}
          </div>
        </div>
      </div>
    )
  }
}

HomeFilters.propTypes = {
  servicesSearch: PropTypes.func,
  updateCondition: PropTypes.func,
  query: PropTypes.object,
  clienti: PropTypes.array
}

export default HomeFilters
