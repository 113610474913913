import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row } from 'react-materialize'
import InputText from '../../../../../components/InputText'

const IndirizzoComunicazioni = ({ fields, domini, formValues, formErrors, change }) => (
  <Fragment>
    <h2 className="collapsible-section-title">Indirizzo comunicazioni</h2>
    <Row className="m-top10">
      <InputText
        id={fields.CELLULARE.id}
        type="text"
        s={6}
        label={`${fields.CELLULARE.label}*`}
        value={formValues[fields.CELLULARE.id]}
        change={() => {}}
        error={formErrors[fields.CELLULARE.id]}
        onInput={ev => {
          const {
            target: { value }
          } = ev
          if (value != null) {
            change(fields.CELLULARE.id, value)
          }
        }}
      />
    </Row>
    <Row className="m-top10">
      <InputText
        id={fields.EMAIL.id}
        type="text"
        s={6}
        label={`${fields.EMAIL.label}*`}
        value={formValues[fields.EMAIL.id]}
        change={() => {}}
        error={formErrors[fields.EMAIL.id]}
        onInput={ev => {
          const {
            target: { value }
          } = ev
          if (value != null) {
            change(fields.EMAIL.id, value)
          }
        }}
      />
    </Row>
  </Fragment>
)

IndirizzoComunicazioni.propTypes = {
  fields: PropTypes.shape({
    CELLULARE: PropTypes.object,
    EMAIL: PropTypes.object
  }),
  domini: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  change: PropTypes.func.isRequired
}

export default IndirizzoComunicazioni
