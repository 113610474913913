import Loading from './LoadingComponent'
import { setDataLoading } from '../../store/modules/dataLoading/actions'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDataLoading
    },
    dispatch
  )

const mapStateToProps = state => {
  return {
    dataLoading: state.dataLoading.dataLoadingState
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading)
