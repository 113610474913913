import React, { Component } from 'react'
import PropTypes from 'prop-types'

class InputText extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && !this.props.value) {
      document.getElementById(`input_text_${this.props.id}`).value = this.props.value
    }
  }

  render() {
    const {
      s,
      m,
      l,
      id,
      value,
      error = '',
      className = '',
      onInput = () => {},
      label,
      placeholder,
      change,
      disabled,
      type = 'text',
      maxLength = 250
    } = this.props
    let divClassName = ''
    let labelClassName = ''
    if (s) divClassName += ` s${s}`
    if (m) divClassName += ` m${m}`
    if (l) divClassName += ` l${l}`
    if (error) divClassName += ' invalid'
    if (value) labelClassName += ' label-active'
    if (!value) labelClassName += ' label-force-inactive'

    return (
      <div className={`input-field input-text-field col${divClassName}${labelClassName}`}>
        <input
          id={`input_text_${id}`}
          type={type}
          onInput={onInput}
          className={className}
          placeholder={placeholder}
          defaultValue={value}
          maxLength={maxLength}
          onBlur={ev => change(id, ev.target.value)}
          disabled={disabled}
        />
        <label htmlFor={`input_text_${id}`} error={error}>
          {label}
        </label>
      </div>
    )
  }
}

InputText.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  change: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  s: PropTypes.number,
  m: PropTypes.number,
  l: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onInput: PropTypes.func
}

export default InputText
