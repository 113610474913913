import React from 'react'
// import { Link } from 'react-materialize'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-materialize'
import { getConsulenzaUrls } from '../../routes/ConsulenzaBaseContainerLayout/utils'
import { resetValidations as resetMifidValidations } from '../../routes/ConsulenzaBaseContainerLayout/QuestionarioProfilatura/mifidValidationUtils'

const consulenzaUrls = getConsulenzaUrls()
const NOME_SEZIONE_MIFID = 'QUESTIONARIO MIFID'
const STATO_COMPLETATO = 'COMPLETATA'

class Aside extends React.Component {
  getIconName(status) {
    switch (status) {
      case 'ATTIVA':
        return 'icon-in-compilazione'
      case 'BOZZA':
      case 'COMPLETATA':
        return 'icon-doc-spalla-dx'
      default:
        return 'icon-doc'
    }
  }

  getAdditionalIconName(status) {
    switch (status) {
      case 'BOZZA':
        return 'icon-alert-per-icona-non-completa-spalla-dx'
      case 'COMPLETATA':
        return 'icon-check-per-icona-doc-spalla-dx'
      default:
        return ''
    }
  }

  render() {
    const {
      name = '',
      surname = '',
      taxCode = '',
      sezioni = [],
      profiloCliente = '',
      percentuale = '',
      idPratica,
      history: {
        location: { pathname },
        push
      },
      isProspectPersonaGiuridica = false,
      goToWhenSaved,
      checkForms,
      businessName
    } = this.props

    const percentageValue = percentuale + '%'
    const mifidCompletata = (sezioni.find(s => s.nomeSezione === NOME_SEZIONE_MIFID) || {}).stato === STATO_COMPLETATO

    const percentualeAlpha = (percentuale / 100) * 0.75

    const goBack = () => {
      resetMifidValidations()
      push('/')
    }

    return (
      <aside className="aside navigation">
        <a onClick={() => goBack()} className="torna-pratiche">
          <i className="icon-arrow-back" /> Torna a elenco pratiche
        </a>
        {/* Titolo con ACQUISIZIONE CLIENTE, nome, cognome, cf */}
        <div className="aside-header">
          <div className="aside-header-title">ACQUISIZIONE CLIENTE</div>

          {!isProspectPersonaGiuridica && (
            <div className="info-cliente">
              <i className="icon-user" />
              <div>
                <p className="label-user">Nome Cognome</p>
                <h4 className="value-user">{`${name} ${surname}`}</h4>
                <h5 className="value-cf-small">{taxCode}</h5>
              </div>
            </div>
          )}

          {isProspectPersonaGiuridica && (
            <div className="info-cliente">
              <i className="icon-user" />
              <div>
                <p className="label-user">Ragione Sociale</p>
                <h4 className="value-cf-small">{businessName}</h4>
              </div>
            </div>
          )}
        </div>

        {/* Profilo Cliente */}
        {mifidCompletata && profiloCliente && (
          <div className="p-left15 ProfiloCliente">
            <Row>
              <Col>
                <Row>
                  <Col>Esperienza Conoscenza</Col>
                </Row>
                <Row>
                  <Col>{profiloCliente.profiloEsperienza}</Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>Orizzonte Temporale</Col>
                </Row>
                <Row>
                  <Col>{profiloCliente.profiloObiettivo}</Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>Micro Profilo di Rischio</Col>
                </Row>
                <Row>
                  <Col>{profiloCliente.profiloRischio}</Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}

        {/* STEP con categorie in response a chiamata */}
        <ul className="aside-steps__list">
          {consulenzaUrls.map((el, index) => {
            if (!sezioni || sezioni.length === 0) return
            let status = sezioni[index].stato
            if (pathname.includes(el) && (sezioni[index].stato !== 'COMPLETATA' || checkForms())) {
              status = 'ATTIVA'
            }
            return (
              <li
                className={`aside-steps__item ${el === 'riepilogo-cliente' && percentuale === 99 ? 'hidden' : ''} ${
                  el === 'riepilogo-cliente' && percentuale !== 99 ? 'hidden' : ''
                }`}
                key={index}>
                <a
                  onClick={() =>
                    !isProspectPersonaGiuridica
                      ? goToWhenSaved(`/consulenza-base/${idPratica}/${el}`)
                      : goToWhenSaved(`/consulenza-base-PG/${idPratica}/${el}`)
                  }
                  className={`navigation-item stato-${status}`}>
                  <div className="aside-steps__item-icon">
                    <i className={this.getIconName(status)} />
                    <i className={'overlay ' + this.getAdditionalIconName(status)} />
                  </div>
                  {sezioni[index].nomeSezione}
                </a>
              </li>
            )
          })}

          {/*
            PERCENTUALE DI COMPLETAMENTO
              se la percentuale e' minore del 99%, visualizzo box COMPLETATO, altrimenti RIEPILOGO CLIENTE
          */}
          {percentuale < 99 && (
            <li className="aside-steps__item navigation-item">
              <div
                className="aside-steps__item-perc"
                style={{
                  backgroundColor: `rgba(33, 206, 152, ${percentualeAlpha})`
                }}>
                {percentageValue}
              </div>
              COMPLETATO
            </li>
          )}
          {!(percentuale < 99) && !isProspectPersonaGiuridica && (
            <li className="aside-steps__item">
              <a
                className="navigation-item"
                onClick={() =>
                  !isProspectPersonaGiuridica
                    ? goToWhenSaved(`/consulenza-base/${idPratica}/riepilogo-cliente`)
                    : goToWhenSaved(`/consulenza-base-PG/${idPratica}/riepilogo-cliente`)
                }>
                <div
                  className="aside-steps__item-icon"
                  style={{
                    backgroundColor: `rgba(33, 206, 152, ${percentualeAlpha})`
                  }}>
                  <i className="icon-in-compilazione" />
                </div>
                RIEPILOGO CLIENTE
              </a>
            </li>
          )}
        </ul>

        {/* PROGRESS BAR */}

        <div className="progress-info">
          <div className="progress-bar-background">
            <div className="progress-bar-complete" style={{ width: percentageValue }} />
          </div>
        </div>
      </aside>
    )
  }
}
export default Aside

Aside.propTypes = {
  checkForms: PropTypes.func,
  goToWhenSaved: PropTypes.func.isRequired,
  history: PropTypes.object,
  idPratica: PropTypes.string,
  isProspectPersonaGiuridica: PropTypes.bool,
  name: PropTypes.string,
  percentuale: PropTypes.number,
  profiloCliente: PropTypes.object,
  sezioni: PropTypes.array,
  surname: PropTypes.string,
  taxCode: PropTypes.string,
  businessName: PropTypes.string
}
