import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { Field, FieldArray } from 'redux-form'
import { FATCA } from '../../../../store/modules/forms/constants'
import AccordionHeader from '../../../../components/AccordionHeader'
import InputText from '../../../../components/Inputs/Text'
import { mapDominiToAutocomplete, getAutocompleteDefaultOption, getDominio, getValueDominioFromKey } from '../../utils'
import renderResidenze from './renderResidenze'
import * as dataSB from '../../../../static-data/data-servizio-base.json'
import Title from '../../../../components/Title'
import ButtonSubmit from '../../../../components/Buttons/Submit'

const fatcaCrs = dataSB.censimento_anagrafica.fatca_crs

class Fatca extends Component {
  constructor(props) {
    super(props)
    this.state = { saved: false }

    this.submit = this.submit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(FATCA)
      this.setState({ saved: true })
    }
  }

  submit(values) {
    const { onSave, fields, formValues } = this.props
    const {
      LISTA: {
        lista: { STATO, CODICE_FISCALE }
      },
      TIN
    } = fields

    onSave(
      Object.keys(values).reduce((res, cur) => {
        if (Array.isArray(values[cur])) {
          return {
            ...res,
            [TIN.id]: formValues[TIN.id] || null,
            [cur]: values[cur].map(el => {
              return {
                [STATO.id]: {
                  chiave: el[STATO.id],
                  codiceDominio: STATO.dominio
                },
                [CODICE_FISCALE.id]: el[CODICE_FISCALE.id] ? el[CODICE_FISCALE.id].toUpperCase() : null
              }
            })
          }
        }
        return { ...res, [cur]: values[cur] }
      }, {})
    )
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      domini,
      formValues,
      formErrors,
      fields,
      change,
      touch,
      status,
      isFetching
    } = this.props
    const { RESIDENZA_USA, TIN, LISTA } = fields
    if (JSON.stringify(formValues) !== '{}') {
      return (
        <CollapsibleItem
          itemKey={FATCA}
          header={fatcaCrs.title_accordion}
          icon="keyboard_arrow_down"
          labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
          <Row>
            <Col s={12}>
              <Title>{fatcaCrs.label_dichiarazione_fatca}</Title>
            </Col>
          </Row>
          <form onSubmit={handleSubmit(this.submit)} noValidate>
            <Row className="residenza-first-row">
              <Col s={6}>
                <p key="si">
                  <Field
                    name={RESIDENZA_USA.id}
                    className="with-gap"
                    type="radio"
                    id="1"
                    value="true"
                    required
                    component="input"
                  />
                  <label htmlFor="1">FISCALMENTE RESIDENTE NEGLI USA</label>
                </p>
                <p key="no">
                  <Field
                    name={RESIDENZA_USA.id}
                    className="with-gap"
                    type="radio"
                    id="2"
                    value="false"
                    required
                    onChange={() => !!formValues[TIN.id] && change(TIN.id, '')}
                    component="input"
                  />
                  <label htmlFor="2">
                    FISCALMENTE <b>NON</b> RESIDENTE NEGLI USA
                  </label>
                </p>
              </Col>
              <Col s={6}>
                <Field
                  name={TIN.id}
                  s={12}
                  maxLength={9}
                  label={TIN.label}
                  error={formErrors[TIN.id]}
                  disabled={formValues[RESIDENZA_USA.id] !== 'true'}
                  value={formValues[TIN.id]}
                  change={change}
                  touch={touch}
                  component={InputText}
                />
              </Col>
            </Row>
            <Row className="m-bottom20">
              <Col s={12}>
                <Title>{fatcaCrs.label_dichiarazione_residenza}</Title>
                <div>{fatcaCrs.label_disclaimer_residenza}</div>
              </Col>
            </Row>
            <FieldArray
              name={LISTA.id}
              lista={LISTA}
              errori={formErrors[LISTA.id] || []}
              isDisabled={index =>
                !getValueDominioFromKey(
                  domini.listaDomini,
                  LISTA.lista.STATO.dominio,
                  formValues[LISTA.id][index][LISTA.lista.STATO.id]
                )
              }
              getDefaultAutocompleteValue={(field, index) =>
                getAutocompleteDefaultOption(
                  domini.listaDomini,
                  LISTA.lista.STATO.dominio,
                  formValues[LISTA.id][index][field]
                )
              }
              getDefaultValue={(field, index) => formValues[LISTA.id][index][field]}
              getOptions={index => {
                if (index === 0) {
                  return mapDominiToAutocomplete(getDominio(domini, LISTA.lista.STATO.dominio))
                }
                return mapDominiToAutocomplete(getDominio(domini, LISTA.lista.STATO.dominio)).filter(
                  d => !formValues[LISTA.id].some((el, i) => i !== index && el[LISTA.lista.STATO.id] === d.value)
                )
              }}
              change={change}
              touch={touch}
              component={renderResidenze}
            />
            <ButtonSubmit
              disabled={
                submitting || !isDirty || invalid || isFetching || Object.keys(formErrors).some(e => formErrors[e])
              }
            />
          </form>
        </CollapsibleItem>
      )
    }
    return null
  }
}

renderResidenze.propTypes = {
  fields: PropTypes.object,
  lista: PropTypes.object,
  getDefaultValue: PropTypes.func,
  getDefaultAutocompleteValue: PropTypes.func,
  isDisabled: PropTypes.func,
  getOptions: PropTypes.func,
  handleChange: PropTypes.func,
  errori: PropTypes.array
}

Fatca.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  fields: PropTypes.object,
  domini: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  change: PropTypes.func,
  touch: PropTypes.func,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  status: PropTypes.any
}

Fatca.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default Fatca
