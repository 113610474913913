import * as actionTypes from './constants'

const initialState = {
  path: '/',
  isPrevious: false,
  accordion: null,
  counter: 0
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DIRTY_FORM_GO_TO:
      return {
        ...state,
        path: action.path,
        isPrevious: action.isPrevious,
        counter: state.counter + 1
      }
    case actionTypes.DIRTY_FORM_FORCE_GO_TO:
      return { ...state, path: action.path, accordion: action.accordion }
    case actionTypes.DIRTY_FORM_RESET_FORCE_GO_TO: {
      return { ...state, accordion: null }
    }
  }
  return state
}
