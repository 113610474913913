import * as actionTypes from './constants'
import * as actionTypesProspect from '../personaGiuridica/prospect/constants'

import * as actionTypesProcuratore from '../procuratore/constants'
import { CREA_PRATICA } from '../forms/constants'
import fields from '../../../routes/ConsulenzaBase/fields'
import { getTemplateMifid, getTemplateAml } from '../survey/actions'
import { fetchPratica } from '../pratica/actions'
import moment from 'moment'
import axios from 'axios'
import { change } from 'redux-form'
import { getProcuratore } from '../procuratore/actions'
// import { TOKEN } from '../../constants'
import { retrieveDataSummaryLightPG } from '../personaGiuridica/prospect/actions'

const parseCFDate = (CF = {}) => {
  if (CF.dataNascita) {
    return {
      ...CF,
      dataNascita: moment(CF.dataNascita)
        .add(12, 'h')
        .format()
    }
  }
  return CF
}

export const resetConsulenza = () => ({ type: actionTypes.RESET_CUSTOMER })

export function getClientiCorrelatiList(idCliente) {
  return async function(dispatch) {
    dispatch({ type: actionTypes.GET_LISTA_CLIENTI_CORRELATI_START })
    setTimeout(() => {
      dispatch({
        type: actionTypes.GET_LISTA_CLIENTI_CORRELATI_SUCCESS,
        clientiCorrelatiList: [],
        idCliente
      })
    }, 500)
  }
}

export function setBlacklistProcuratore(bool) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_BLACKLIST_PROCURATORE,
      flagIsBlacklist: bool
    })
  }
}

export function customerCreate(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.CUSTOMER_CREATE_START
    })

    let responseApi = null
    let responsePratica = null
    axios
      .get(`/customer/create/?codFiscale=${value.toUpperCase()}`, {})
      .then(response => {
        responseApi = response.data
        return fetchPratica(responseApi.idIncarico)
      })
      .then(response => {
        responsePratica = { ...response.data.pratica, idPratica: responseApi.idIncarico }
        return retrieveDataSummaryLight(responseApi.idCliente, responseApi.idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.CUSTOMER_CREATE_SUCCESS,
          data: responseApi,
          summaryLight: response.data,
          pratica: responsePratica
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CUSTOMER_CREATE_ERROR,
          error
        })
      })
  }
}

export function computeCF(payload_cf) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.CUSTOMER_COMPUTE_CF_START
    })
    let codiceFiscale = null
    axios
      .post(`/v2/utility/calculate-tax-code`, payload_cf)
      .then(response => {
        codiceFiscale = response.data || ''
        dispatch({
          type: actionTypes.CUSTOMER_COMPUTE_CF_SUCCESS,
          cf: codiceFiscale
        })
        const { CODICE_FISCALE } = fields
        dispatch(change(CREA_PRATICA, CODICE_FISCALE.id, codiceFiscale))
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CUSTOMER_COMPUTE_CF_ERROR,
          error
        })
      })
  }
}

export const resetCheckCF = () => ({ type: actionTypes.RESET_CUSTOMER_CHECK_CF })

export function checkCF(objectBody) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.CUSTOMER_CHECK_CF_START,
      idPhysicalPersonReference: { ...objectBody }
    })
    axios
      .post('/v2/customer/check-customer', objectBody)
      .then(response => {
        dispatch({
          type: actionTypes.CUSTOMER_CHECK_CF_SUCCESS,
          isUserAvailable: response.data.status.code,
          formUlterioriDati: response.data.formUlterioriDati
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.CUSTOMER_CHECK_CF_ERROR,
          error
        })
      })
  }
}

export function procuratorCheckCF(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PROCURATOR_CHECK_CF_START
    })
    axios
      .post('/v2/customer/check-customer-blacklist', payload)
      .then(response => {
        dispatch({
          type: actionTypes.PROCURATOR_CHECK_CF_SUCCESS,
          flagIsBlacklist: response.data.flagIsBlacklist
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.PROCURATOR_CHECK_CF_ERROR,
          error
        })
      })
  }
}

export function inBlacklistCF(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.BLACKLIST_CF_START
    })
    try {
      const response = await axios.post('/v2/customer/check-customer-blacklist', payload)
      dispatch({
        type: actionTypes.BLACKLIST_CF_SUCCESS,
        statusBlack: response.data.code,
        flagCustomerIsBlacklist: response.data.flagIsBlacklist,
        taxCode: payload.taxCode
      })
    } catch (error) {
      dispatch({
        type: actionTypes.BLACKLIST_CF_ERROR,
        error
      })
    }
  }
}

function dataRegistryCF(codiceFiscale = '', active) {
  // if (active || !codiceFiscale) {
  return new Promise(function(resolve, reject) {
    resolve({ data: {} })
  })
  // }
  // try {
  //   return axios.get(`/v2/utility/get-data-from-tax-code?codiceFiscale=${codiceFiscale.toUpperCase()}`, {})
  // } catch (error) {
  //   const newError = new Error(error.message)
  //   newError.apiName = 'dataRegistryCF'
  //   throw newError
  // }
}

function dataRegistry(idCliente, idIncarico, active) {
  if (!active) {
    return new Promise(function(resolve, reject) {
      resolve({ data: {} })
    })
  }
  try {
    return axios.get(`/customer/${idCliente}/dataRegistry/?idIncarico=${idIncarico}`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'dataRegistry'
    throw newError
  }
}

function getFatcacrs(idCliente, idIncarico, active) {
  if (!active) {
    return new Promise(function(resolve, reject) {
      resolve({ data: {} })
    })
  }
  try {
    return axios.get(`/customer/${idCliente}/fatcacrs/?idIncarico=${idIncarico}`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'fatcacrs'
    throw newError
  }
}

export function getDataRegistry(codiceFiscale, idCliente, idIncarico, dataRegistryBool, fatcacrsBool) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DATA_REGISTRY_START
    })
    try {
      const [dataCF, dataDataRegistry, dataFatca] = await Promise.all([
        dataRegistryCF(codiceFiscale, dataRegistryBool),
        dataRegistry(idCliente, idIncarico, dataRegistryBool),
        getFatcacrs(idCliente, idIncarico, fatcacrsBool)
      ])
      dispatch({
        type: actionTypes.GET_DATA_REGISTRY_SUCCESS,
        dataCF: parseCFDate(dataCF.data),
        dataRegistry: dataDataRegistry.data,
        dataFatca: dataFatca.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DATA_REGISTRY_ERROR,
        error: error
      })
    }
  }
}

export function getDataGathering(idCliente) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DATA_GATHERING_START
    })
    try {
      const response = await readDataGathering(idCliente)
      dispatch({
        type: actionTypes.GET_DATA_GATHERING_SUCCESS,
        dataGathering: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DATA_GATHERING_ERROR,
        error: error
      })
    }
  }
}

export function postDataRegistry(idCliente, idIncarico, data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_DATA_REGISTRY_START,
      query: data
    })
    let responseApi = null
    axios
      .post(`/customer/${idCliente}/dataRegistry/?idIncarico=${idIncarico}`, data)
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLight(idCliente, idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.POST_DATA_REGISTRY_SUCCESS,
          data: responseApi,
          summaryLight: response.data,
          query: data
        })
      })
      .catch(error => {
        if (error.data.code.indexOf('CONSISTENCY_') !== -1) {
          retrieveDataSummaryLight(idCliente, idIncarico).then(response => {
            dispatch({
              type: actionTypes.POST_DATA_REGISTRY_ERROR,
              error,
              dataRegistry: data,
              summaryLight: response.data
            })
          })
        } else {
          dispatch({
            type: actionTypes.POST_DATA_REGISTRY_ERROR,
            error
          })
        }
      })
  }
}

export function postDataGathering(idCliente, data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_DATA_GATHERING_START,
      query: data
    })
    let responseApi = null
    axios({
      method: 'POST',
      url: `/customer/save/${idCliente}`,
      data
    })
      .then(response => {
        responseApi = response.data
        // return retrieveDataSummaryLight(idCliente)
      })
      .then(response => {
        dispatch({
          type: actionTypes.POST_DATA_GATHERING_SUCCESS,
          data: responseApi,
          // summaryLight: response.data,
          query: data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_DATA_GATHERING_ERROR,
          error
        })
      })
  }
}

function readDataGathering(idCliente) {
  try {
    return axios.get(`/v2/customer/read/${idCliente}`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'read'
    throw newError
  }
}

export function saveFatca(idCliente, data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_FATCA_START,
      query: data
    })
    let responseApi = null
    axios({
      method: 'POST',
      url: `/customer/${idCliente}/fatcacrs`,
      data
    })
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLight(idCliente, data.idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.POST_FATCA_SUCCESS,
          data: responseApi,
          summaryLight: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_FATCA_ERROR,
          error
        })
      })
  }
}

// let dataSL = {
//   percentuale: 89,
//   statusEndPoint: [
//     { name: 'idCardData', active: false },
//     { name: 'dataRegistry', active: false },
//     { name: 'fatcacrs', active: false },
//     { name: 'aml', active: false },
//     { name: 'servdig', active: false },
//     { name: 'mifid', active: false }
//   ],
//   sezioni: [
//     {
//       nomeSezione: 'ACQUISIZIONE DOCUMENTI',
//       stato: 'NUOVA',
//       codSezione: 'DOCUMENTI_IDENTITA',
//       sottoSezioni: [
//         { nomeSezione: 'CODICE_FISCALE', stato: 'NUOVA', codSezione: 'CODICE_FISCALE', sottoSezioni: null },
//         {
//           nomeSezione: 'DOCUMENTO_IDENTITA_ALLEGATO',
//           stato: 'NUOVA',
//           codSezione: 'DOCUMENTO_IDENTITA_ALLEGATO',
//           sottoSezioni: null
//         },
//         {
//           nomeSezione: 'CENSIMENTO_PROCURATORE',
//           stato: 'NUOVA',
//           codSezione: 'CENSIMENTO_PROCURATORE',
//           sottoSezioni: null
//         },
//         { nomeSezione: 'PROCURATORE_ID', stato: 'NUOVA', codSezione: 'PROCURATORE_ID', sottoSezioni: null },
//         { nomeSezione: 'DOCUMENTO_PROCURA', stato: 'NUOVA', codSezione: 'DOCUMENTO_PROCURA', sottoSezioni: null },
//         {
//           nomeSezione: 'CLIENTI_PROFESSIONALI',
//           stato: 'NUOVA',
//           codSezione: 'CLIENTI_PROFESSIONALI',
//           sottoSezioni: null
//         }
//       ]
//     },
//     {
//       nomeSezione: 'CENSIMENTO ANAGRAFICO',
//       stato: 'NUOVA',
//       codSezione: 'CENSIMENTO_ANAGRAFICO',
//       sottoSezioni: [
//         { nomeSezione: 'INFO_PERSONALI', stato: 'NUOVA', codSezione: 'INFO_PERSONALI', sottoSezioni: null },
//         { nomeSezione: 'RESIDENZA', stato: 'NUOVA', codSezione: 'RESIDENZA', sottoSezioni: null },
//         { nomeSezione: 'CONTATTI', stato: 'NUOVA', codSezione: 'CONTATTI', sottoSezioni: null },
//         { nomeSezione: 'SOGGETTI_CORRELATI', stato: 'NUOVA', codSezione: 'SOGGETTI_CORRELATI', sottoSezioni: null },
//         { nomeSezione: 'FATCA', stato: 'NUOVA', codSezione: 'FATCA', sottoSezioni: null },
//         { nomeSezione: 'ANTI_RICICLAGGIO', stato: 'NUOVA', codSezione: 'ANTI_RICICLAGGIO', sottoSezioni: null },
//         { nomeSezione: 'PRIVACY', stato: 'NUOVA', codSezione: 'PRIVACY', sottoSezioni: null }
//       ]
//     },
//     {
//       nomeSezione: 'QUESTIONARIO MIFID',
//       stato: 'NUOVA',
//       codSezione: 'MIFID',
//       sottoSezioni: [
//         { nomeSezione: 'INVESTIMENTO', stato: 'NUOVA', codSezione: 'INVESTIMENTO', sottoSezioni: null },
//         { nomeSezione: 'ALTRO', stato: 'NUOVA', codSezione: 'ALTRO', sottoSezioni: null }
//       ]
//     },
//     {
//       nomeSezione: 'SOTTOSCRIZIONE SERVIZI',
//       stato: 'COMPLETATA',
//       codSezione: 'SERVIZI_DIGITALI',
//       sottoSezioni: [
//         { nomeSezione: 'SERVIZI_DIGITALI', stato: 'COMPLETATA', codSezione: 'SERVIZI_DIGITALI', sottoSezioni: null }
//       ]
//     },
//     { nomeSezione: 'RIEPILOGO CLIENTE', stato: null, codSezione: 'RIEPILOGO', sottoSezioni: null }
//   ],
//   statusResponse: { code: '0', description: 'OK' },
//   profiloRischio: null,
//   admin: false,
//   idProcurator: null
// }

export function retrieveDataSummaryLight(idCliente, idIncarico) {
  try {
    return axios.get(`/customer/dataSummaryLite/${idCliente}?idIncarico=${idIncarico}`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'dataSummaryLite'
    throw newError
  }
}

export function getDataSummaryLight(idCliente, idIncarico) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_SUMMARY_DATA_START
    })
    retrieveDataSummaryLight(idCliente, idIncarico)
      .then(response => {
        // forza il riepilogo
        // response.data.sezioni[0].stato= 'COMPLETATA'
        // response.data.percentuale= 99
        dispatch({
          type: actionTypes.GET_SUMMARY_DATA_SUCCESS,
          data: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_SUMMARY_DATA_ERROR,
          error: error
        })
      })
  }
}

export function getDataSummaryFull(idCliente, idIncarico, isProfessionalSubjectDone = false) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_SUMMARY_DATA_FULL_START
    })
    let responseApi = null
    let responseAml = null
    axios
      .get(`/customer/dataSummaryFull/${idCliente}?idIncarico=${idIncarico}`, {})
      .then(response => {
        responseApi = response.data
        return getTemplateAml()
      })
      .then(response => {
        responseAml = response.data
        return getTemplateMifid(isProfessionalSubjectDone)
      })
      .then(response => {
        dispatch({
          type: actionTypes.GET_SUMMARY_DATA_FULL_SUCCESS,
          data: responseApi,
          template: response.data,
          templateAml: responseAml
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.GET_SUMMARY_DATA_FULL_ERROR,
          error
        })
      })
  }
}

function documentsData(idCliente, idIncarico, active) {
  if (!active) {
    return new Promise(function(resolve) {
      resolve({
        data: {
          idIncarico: idIncarico,
          flagCFRetro: false,
          flagCFFronte: false,
          flagDOCRetro: false,
          flagDOCFronte: false,
          numeroDocumenti: '',
          dataScadenza: '',
          dataRilascio: ''
        }
      })
    })
  }

  try {
    return axios.get(`/customer/${idCliente}/idCardData/?idIncarico=${idIncarico}`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'documentsData'
    throw newError
  }
}

export function getDocumentsData(codiceFiscale, idCliente, idIncarico, idCardDataStatus) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DOCUMENTS_DATA_START
    })
    try {
      const [dataCF, dataDOC] = await Promise.all([
        dataRegistryCF(codiceFiscale),
        documentsData(idCliente, idIncarico, idCardDataStatus)
      ])
      dispatch({
        type: actionTypes.GET_DOCUMENTS_DATA_SUCCESS,
        dataCF: Object.keys(dataCF.data).length > 0 ? parseCFDate(dataCF.data) : null,
        dataIdCard: dataDOC.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DOCUMENTS_DATA_ERROR,
        error: error
      })
    }
  }
}

export function getDocumentsDataProcurator(codiceFiscale, idCliente, idIncarico, idCardDataStatus, idProcurator) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DOCUMENTS_DATA_START
    })
    try {
      const [dataCF, dataDOC, dataProcuratore] = await Promise.all([
        dataRegistryCF(codiceFiscale),
        documentsData(idCliente, idIncarico, idCardDataStatus),
        getProcuratore(idProcurator, idIncarico)
      ])
      dispatch({
        type: actionTypes.GET_DOCUMENTS_DATA_SUCCESS,
        dataCF: Object.keys(dataCF.data).length > 0 ? parseCFDate(dataCF.data) : null,
        dataIdCard: dataDOC.data,
        dataProcuratore: dataProcuratore.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DOCUMENTS_DATA_ERROR,
        error: error
      })
    }
  }
}

export function saveCodiceFiscale(idCliente, idCardAllegati, idCardData) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_DATA_ALLEGATI_CF_START,
      query: {
        ...idCardAllegati.payload,
        ...idCardData.payload
      }
    })

    let responseApi = null
    postAllegati(idCliente, 'idCardDataAllegati', idCardAllegati.payload)
      .then(response => {
        return postAllegati(idCliente, 'idCardData', { ...idCardData.payload, ...response.data })
      })
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLight(idCliente, idCardData.payload.idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.POST_DATA_ALLEGATI_CF_SUCCESS,
          dataPreviousPosted: responseApi,
          summaryLight: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_DATA_ALLEGATI_CF_ERROR,
          error
        })
      })
  }
}

/* function saveDocumentiOnly(idCliente, idCardData, isProcurator) {
  return async function(dispatch) {
    const { payload } = idCardData
    dispatch({
      type: actionTypes.POST_DATA_ALLEGATI_DOC_START,
      query: payload
    })
    let responseApi = null
    postAllegati(idCliente, 'idCardData', payload)
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLight(idCliente, idCardData.payload.idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.POST_DATA_ALLEGATI_DOC_SUCCESS,
          dataPreviousPosted: responseApi,
          summaryLight: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_DATA_ALLEGATI_DOC_ERROR,
          error
        })
      })
  }
} */

export function saveDocumenti(id, idCardAllegati, idCardData, isProcurator = false, idCliente = false) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_DATA_ALLEGATI_DOC_START,
      query: {
        ...idCardAllegati.payload,
        ...idCardData.payload
      }
    })
    let responseApi = null
    postAllegati(id, 'idCardDataAllegati', idCardAllegati.payload, isProcurator)
      .then(response => {
        if (!isProcurator) return postAllegati(id, 'idCardData', { ...idCardData.payload, ...response.data })
      })
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLight(idCliente || id, idCardData.payload.idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.POST_DATA_ALLEGATI_DOC_SUCCESS,
          dataPreviousPosted: responseApi,
          summaryLight: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_DATA_ALLEGATI_DOC_ERROR,
          error
        })
      })
  }
}

function postAllegati(id, endpoint, query, isProcurator = false) {
  try {
    let url = isProcurator ? `/v2/procurator/${id}/${endpoint}` : `/v2/customer/${id}/${endpoint}`
    return axios.post(url, query)
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = endpoint
    throw newError
  }
}

export function documentsProcuratorSave(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.DOCUMENTS_PROCURATOR_SAVE_START
    })
    axios
      .post(`/v2/documents/save`, payload)
      .then(response => {
        dispatch({
          type: actionTypes.DOCUMENTS_PROCURATOR_SAVE_SUCCESS,
          flagDocAttorney: true
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.DOCUMENTS_PROCURATOR_SAVE_ERROR,
          error
        })
      })
  }
}

export function getServiziDigitali(idCliente, idIncarico, active) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_SERVIZI_DIGITALI_START
    })
    try {
      if (active === false) {
        const dataDefault = {
          idIncarico: idIncarico
        }
        // set timeout to intercept fetching
        setTimeout(() => {
          dispatch({
            type: actionTypes.GET_SERVIZI_DIGITALI_SUCCESS,
            data: dataDefault
          })
        }, 500)
        return
      }

      const response = await axios.get(`/customer/${idCliente}/servdig/?idIncarico=${idIncarico}`, {})
      dispatch({
        type: actionTypes.GET_SERVIZI_DIGITALI_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_SERVIZI_DIGITALI_ERROR,
        error: error
      })
    }
  }
}

export function postServiziDigitali(idCliente, query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_SERVIZI_DIGITALI_START,
      query
    })
    let responseApi = null
    axios({
      method: 'POST',
      url: `/customer/${idCliente}/servdig`,
      data: query
    })
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLight(idCliente, query.idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.POST_SERVIZI_DIGITALI_SUCCESS,
          data: responseApi,
          summaryLight: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_SERVIZI_DIGITALI_ERROR,
          error
        })
      })
  }
}

export function getServiziDigitaliPG(idCliente, idIncarico) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_SERVIZI_DIGITALI_PG_START
    })
    try {
      // if (active === false) {
      //   const dataDefault = {
      //     idIncarico: idIncarico
      //   }
      //   // set timeout to intercept fetching
      //   setTimeout(() => {
      //     dispatch({
      //       type: actionTypes.GET_SERVIZI_DIGITALI_PG_SUCCESS,
      //       data: dataDefault
      //     })
      //   }, 500)
      //   return
      // }

      const response = await axios.get(`/v2/legal-person/${idCliente}/servdig/?idIncarico=${idIncarico}`, {})
      dispatch({
        type: actionTypes.GET_SERVIZI_DIGITALI_PG_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_SERVIZI_DIGITALI_PG_ERROR,
        error: error
      })
    }
  }
}

export function postServiziDigitaliPG(idCliente, query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_SERVIZI_DIGITALI_PG_START,
      query
    })
    let responseApi = null
    axios({
      method: 'POST',
      url: `/v2/legal-person/${idCliente}/servdig`,
      data: query
    })
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLightPG(idCliente, query.idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypesProspect.GET_DATA_SUMMARY_LIGHT_PG_SUCCESS,
          responseApi: response.data
        })

        dispatch({
          type: actionTypes.POST_SERVIZI_DIGITALI_PG_SUCCESS,
          data: responseApi
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_SERVIZI_DIGITALI_PG_ERROR,
          error
        })
      })
  }
}

export function getCustomerList() {
  return async function(dispatch) {
    const query = {}
    dispatch({
      type: actionTypes.GET_LISTA_CLIENTI_START,
      query: query
    })
    try {
      const response = await axios.get(
        `/customer/list`,
        // {
        //   headers: { Authorization: 'Bearer ' + TOKEN }
        // },
        query
      )
      dispatch({
        type: actionTypes.GET_LISTA_CLIENTI_SUCCESS,
        customerListData: response.data.customers
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_LISTA_CLIENTI_ERROR,
        error: error
      })
    }
  }
}

export function getProfessionalSubject(idCliente, isPersonaGiuridica = false) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_PROFESSIONAL_SUBJECT_START
    })

    if (isPersonaGiuridica) {
      axios
        .get(`/v2/legal-person/professional-subject/read?idCliente=${idCliente}`)
        .then(response => {
          dispatch({
            type: actionTypes.GET_PROFESSIONAL_SUBJECT_SUCCESS,
            professionalSubject: response.data
          })
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROFESSIONAL_SUBJECT_ERROR,
            error
          })
        })
    } else {
      axios
        .get(`/v2/professional-subject/read?idCliente=${idCliente}`)
        .then(response => {
          dispatch({
            type: actionTypes.GET_PROFESSIONAL_SUBJECT_SUCCESS,
            professionalSubject: response.data
          })
        })
        .catch(error => {
          dispatch({
            type: actionTypes.GET_PROFESSIONAL_SUBJECT_ERROR,
            error
          })
        })
    }
  }
}

export function saveProfessionalSubject(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SAVE_PROFESSIONAL_SUBJECT_START
    })
    let responseApi = null
    axios
      .post(`/v2/professional-subject/save`, payload)
      .then(response => {
        responseApi = response
        return retrieveDataSummaryLight(payload.idCliente, payload.idPratica)
      })
      .then(response => {
        dispatch({
          type: actionTypes.SAVE_PROFESSIONAL_SUBJECT_SUCCESS,
          professionalSubject: responseApi.data,
          summaryLight: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SAVE_PROFESSIONAL_SUBJECT_ERROR,
          error
        })
      })
  }
}

export function downloadProfessionalSubjectDocument(data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_START
    })
    try {
      const response = await axios.post(`/v2/documents/get`, data)
      dispatch({
        type: actionTypes.DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_SUCCESS,
        downloadDocument: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.DOWNLOAD_PROFESSIONAL_SUBJECT_DOCUMENT_ERROR,
        error: error
      })
    }
  }
}
export function searchSoggettiCorrelati(data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_SOGGETTI_CORRELATI_START
    })
    try {
      const response = await axios.post(`/v2/related-subjects/search`, data)
      dispatch({
        type: actionTypes.GET_SOGGETTI_CORRELATI_SUCCESS,
        soggettiCorrelatiListData: response.data.result,
        totalpage: response.data.totalpage
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_SOGGETTI_CORRELATI_ERROR,
        error: error
      })
    }
  }
}

export function searchSoggettiCorrelatiGiuridiche(data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_SOGGETTI_CORRELATI_GIURIDICHE_START
    })
    try {
      const response = await axios.post(`/v2/legal-person/related-subjects/search`, data)
      dispatch({
        type: actionTypes.GET_SOGGETTI_CORRELATI_GIURIDICHE_SUCCESS,
        soggettiCorrelatiListDataGiuridiche: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_SOGGETTI_CORRELATI_GIURIDICHE_ERROR,
        error: error
      })
    }
  }
}

export function inviaRichiestaSoggettiCorrelati(data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_START
    })
    try {
      const response = await axios.post(`/v2/related-subjects/send-relation-request`, data)
      dispatch({
        type: actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_SUCCESS,
        isEmail: response.code
      })
    } catch (error) {
      dispatch({
        type: actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_ERROR,
        error: error
      })
    }
  }
}

export function inviaRichiestaSoggettiCorrelatiGiuridiche(data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_START
    })
    try {
      const response = await axios.post(`/v2/legal-person/related-subjects/send-relation-request`, data)
      dispatch({
        type: actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_SUCCESS,
        isEmail: response.code
      })
    } catch (error) {
      dispatch({
        type: actionTypes.INVIA_RICHIESTA_SOGGETTI_CORRELATI_GIURIDICHE_ERROR,
        error: error
      })
    }
  }
}

export function getModulo(data) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.MODULO_SOGGETTI_CORRELATI_START
    })
    try {
      const response = await axios.post(`/v2/related-subjects/get-related-document`, data)
      dispatch({
        type: actionTypes.MODULO_SOGGETTI_CORRELATI_SUCCESS,
        file: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.MODULO_SOGGETTI_CORRELATI_ERROR,
        error: error
      })
    }
  }
}

export function getSoggettiGiaCorrelati(idCustomer) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_START
    })
    try {
      const response = await axios.get(`/v2/related-subjects/get-related-subjects/${idCustomer}`)
      dispatch({
        type: actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_SUCCESS,
        soggettiGiaCorrelatiListData: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_ERROR,
        error: error
      })
    }
  }
}

export function getSoggettiGiaCorrelatiGiuridiche(idCustomer) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_START
    })
    try {
      const response = await axios.get(`/v2/legal-person/related-subjects/get-related-subjects/${idCustomer}`)
      dispatch({
        type: actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_SUCCESS,
        soggettiGiaCorrelatiListDataGiuridiche: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.MODULO_SOGGETTI_GIA_CORRELATI_GIURIDICHE_ERROR,
        error: error
      })
    }
  }
}

export function checkHasProcuratore(data) {
  return async function(dispatch) {
    if (!data.hasProcuratore) {
      dispatch({
        type: actionTypesProcuratore.DELETE_PROCURATORE
      })
      dispatch({
        type: actionTypes.DELETE_PROCURATORE_DATA
      })
    }
    dispatch({
      type: actionTypes.CHECK_HAS_PROCURATORE_START
    })
    try {
      const response = await axios.post(`/v2/procurator/hasProcuratore`, data)
      dispatch({
        type: actionTypes.CHECK_HAS_PROCURATORE_SUCCESS,
        status: response.data,
        hasProcuratore: data.hasProcuratore
      })
    } catch (error) {
      dispatch({
        type: actionTypes.CHECK_HAS_PROCURATORE_ERROR,
        error: error
      })
    }
  }
}

export function getOWCustomerList(query, customerType) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_LISTA_CLIENTI_OW_START,
      query: query
    })
    try {
      const response = await axios.get(`/v2/customer/listExternal`, { params: query })
      dispatch({
        type: actionTypes.GET_LISTA_CLIENTI_OW_SUCCESS,
        customerOWListData: response.data.customers,
        customerType
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_LISTA_CLIENTI_OW_ERROR,
        error: error
      })
    }
  }
}

export function saveOWCustomer(query) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_OW_CUSTOMER_START,
      query: query
    })
    try {
      await axios.post(`/v2/pratica/import`, query)
      dispatch({
        type: actionTypes.POST_OW_CUSTOMER_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_OW_CUSTOMER_ERROR,
        error: error
      })
    }
  }
}

export function saveOWCustomers(customerList) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_OW_CUSTOMERS_START,
      query: customerList
    })
    try {
      await Promise.all(customerList.map(customer => axios.post(`/v2/pratica/import`, customer)))
      dispatch({
        type: actionTypes.POST_OW_CUSTOMERS_SUCCESS
      })
    } catch (error) {
      dispatch({
        type: actionTypes.POST_OW_CUSTOMERS_ERROR,
        error: error
      })
    }
  }
}

export function getDataRegistryExt(idCustomerExt) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_DATA_REGISTRY_EXT_START
    })
    try {
      const dataRegistryExt = await axios.get(`/customer/${idCustomerExt}/dataRegistryExt`, {})
      dispatch({
        type: actionTypes.GET_DATA_REGISTRY_EXT_SUCCESS,
        dataRegistry: dataRegistryExt.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_DATA_REGISTRY_EXT_ERROR,
        error: error
      })
    }
  }
}

export function getListAML(queryParam) {
  return async function(dispatch) {
    const query = {
      querySearch: '',
      amlStatus: '',
      itemInPage: 20,
      pageNum: 1,
      ...queryParam
    }

    dispatch({
      type: actionTypes.GET_LIST_AML_START,
      query: query
    })
    try {
      const response = await axios.get('/v2/customer/listAML', {
        params: query
      })
      dispatch({
        type: actionTypes.GET_LIST_AML_SUCCESS,
        listAML: response.data.clientiAml,
        totListAML: response.data.totalItems
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_LIST_AML_ERROR,
        error: error
      })
    }
  }
}

export function getCustomersAML(queryParam) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_CUSTOMERS_AML_START
    })
    try {
      const response = await axios.get('/v2/customer/listAML', {
        params: queryParam
      })
      dispatch({
        type: actionTypes.GET_CUSTOMERS_AML_SUCCESS,
        data: response.data.clientiAml
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_CUSTOMERS_AML_ERROR,
        error: error
      })
    }
  }
}

export function setListAMLPageSize(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_LIST_AML_PAGE_SIZE,
      listAMLPageSize: value
    })
  }
}

export function getIdCardData(idCliente, idIncarico) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_ID_CARD_DATA_START
    })
    try {
      const response = await axios.get(`/customer/${idCliente}/idCardData?idIncarico=${idIncarico}`, {})
      dispatch({
        type: actionTypes.GET_ID_CARD_DATA_SUCCESS,
        idCardData: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ID_CARD_DATA_ERROR,
        error: error
      })
    }
  }
}

export function getFeqAbilitata(id) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_FEQ_ABILITATA_START
    })
    try {
      const response = await axios.get(`/v2/customer/listExternal`, { params: { querySearch: id } })
      dispatch({
        type: actionTypes.GET_FEQ_ABILITATA_SUCCESS,
        feqAbilitata: response.data.customers.length > 0 ? response.data.customers[0].feqAbilitata : false
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_FEQ_ABILITATA_ERROR,
        error: error
      })
    }
  }
}

export function getPromoterCustomerHimself(idPromoter, customerTaxCode) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_PROMOTER_CUSTOMER_HIMSELF_START
    })
    try {
      const response = await axios.get(`/utility/isPromoterCustomerHimself/${idPromoter}`, {
        params: { customerTaxCode }
      })
      dispatch({
        type: actionTypes.GET_PROMOTER_CUSTOMER_HIMSELF_SUCCESS,
        data: response.data.isPromoterCustomerHimself
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_PROMOTER_CUSTOMER_HIMSELF_ERROR,
        error: error
      })
    }
  }
}
