import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getFormValues, getFormSyncErrors, getFormSyncWarnings, getFormMeta, untouch, change } from 'redux-form'
import { CREA_PRATICA } from '../../store/modules/forms/constants'
import { customerCreate, resetCheckCF, computeCF, checkCF } from '../../store/modules/customers/actions'
import {
  checkProspectPGActionCreator,
  legalPersonCreatePF
} from '../../store/modules/personaGiuridica/prospect/actions'
import { interventionRequest } from '../../store/modules/utility/actions'
import { dominio } from '../../store/modules/dominio/actions'
import fields from './fields'
import ConsulenzaBase from './ConsulenzaBaseComponent'
import { goToWhenSaved } from '../../store/modules/dirtyForm/actions'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      customerCreate,
      resetCheckCF,
      computeCF,
      checkCF,
      checkProspectPGActionCreator,
      dominio,
      goToWhenSaved,
      interventionRequest,
      untouch,
      change,
      legalPersonCreatePF
    },
    dispatch
  )

const mapStateToProps = state => {
  const { TIPO_INSERIMENTO } = fields
  return {
    customer: state.customers.customer,
    isUserAvailable: state.customers.isUserAvailable,
    isProspectPGAvailable: state.prospectPersonaGiuridica.isProspectPGAvailable,
    formUlterioriDati: state.customers.formUlterioriDati,
    computeCFRequest: state.customers.computeCFRequest,
    checkCFRequest: state.customers.checkCFRequest,
    checkPGRequest: state.prospectPersonaGiuridica.checkPGRequest,
    legalPersonCreatePFRequest: state.prospectPersonaGiuridica.legalPersonCreatePFRequest,
    domini: state.dominio.domini,
    dominioRequest: state.dominio.dominioRequest,
    idPhysicalPersonReference: state.customers.idPhysicalPersonReference,
    idPratica: state.pratica.idPratica,
    idLegalPerson: state.pratica.idLegalPerson,
    formValues: getFormValues(CREA_PRATICA)(state),
    formErrors: getFormSyncErrors(CREA_PRATICA)(state),
    formWarnings: getFormSyncWarnings(CREA_PRATICA)(state),
    formMetas: getFormMeta(CREA_PRATICA)(state),
    moduleSendRequest: state.privacy.moduleSendRequest,
    privacySendRequest: state.privacy.privacySendRequest,
    initialValues: {
      nationBirthCode: fields.NAZIONE_NASCITA.italiaId,
      [TIPO_INSERIMENTO.id]: TIPO_INSERIMENTO.defaultValue
    },

    isSend: state.utility.isSend,
    RichiestaInSedeRequest: state.customers.RichiestaInSedeRequest
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsulenzaBase)
