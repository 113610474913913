import React, { Component, Fragment } from 'react'
import { Row, Col, Icon } from 'react-materialize'
import { PropTypes } from 'prop-types'
import { Collapsible } from '../../../components/Collapsible'
import ModalFirma from '../../../components/Modals/Firma'
import dataSB from '../../../static-data/data-servizio-base.json'
import ModalGeneric from '../../../components/ModalGeneric'
import {
  getDominioRequest,
  getUrlFromIndex,
  setRandomStr,
  checkStatusService,
  getActiveMifid,
  setActiveMifid,
  MIFID_CONST
} from '../utils'
import CodiceFiscaleReview from './AcquisizioneDocumenti/CodiceFiscale'
import DocumentiReview from './AcquisizioneDocumenti/Documenti'
import ProcuratorePreview from './AcquisizioneDocumenti/Procuratore'
import ProcuratoreDocumentiReview from './AcquisizioneDocumenti/ProcuratoreDocumenti'
import ClienteProfessionaleReview from './AcquisizioneDocumenti/ClienteProfessionale'
import DocumentoProcuraReview from './AcquisizioneDocumenti/DocumentoProcura'
import InformazioniPersonaliReview from './CensimentoAnagrafico/InformazioniPersonali'
import ResidenzaReview from './CensimentoAnagrafico/Residenza'
import ContattiReview from './CensimentoAnagrafico/Contatti'
import FatcaReview from './CensimentoAnagrafico/Fatca'
import SoggettiCorrelati from './CensimentoAnagrafico/SoggettiCorrelati'
import AntiriciclaggioReview from './CensimentoAnagrafico/Antiriciclaggio'
import ConsensiPrivacyReview from './CensimentoAnagrafico/ConsensiPrivacy'
import ConoscenzaEsperienza from './QuestionarioProfilatura/ConoscenzaEsperienza'
import ObiettiviInvestimentoReview from './QuestionarioProfilatura/ObiettiviInvestimento'
import InvestimentiESGReview from './QuestionarioProfilatura/InvestimentiESG'
import SituazioneFinanziariaReview from './QuestionarioProfilatura/SituazioneFinanziaria'
import AltreInformazioniReview from './QuestionarioProfilatura/AltreInformazioni'
import SottoscrizioneServiziReview from './SottoscrizioneServizi'
import * as Forms from '../../../store/modules/forms/constants'
import FirmaFooter from '../../../components/FirmaFooter'
import dataMain from '../../../static-data/data-main.json'
import AnagraficaReview from './QuestionarioProfilatura/Anagrafica'
import TolleranzaOscillazioneReview from './QuestionarioProfilatura/TolleranzaOscillazione'
import EducazioneFinanziariaReview from './QuestionarioProfilatura/EducazioneFinanziaria'
// import { setMaxListeners } from 'cluster';

let MIFID_ACTIVE = {}
let NEW_MIFID = true

const { check_intestatari } = dataMain
export class RiepilogoCliente extends Component {
  constructor(props) {
    super(props)

    this.state = {
      customer: {},
      loadingComplete: false,
      waitingDomini: false,
      firmaOra: false,
      showModal: false,
      typeFirma: '',
      later: false,
      isProspectPersonaGiuridica: false
    }

    this.renderHeader = this.renderHeader.bind(this)
    this.salva = this.salva.bind(this)
    this.modalHandler = this.modalHandler.bind(this)
    this.setLater = this.setLater.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }
  setLater(value) {
    this.setState({ later: value })
  }

  setMifidType() {
    if (this.state.isProspectPersonaGiuridica) {
      if (this.props.professionalSubject && this.props.professionalSubject.professionalSubjectType === 'DI_DIRITTO') {
        setActiveMifid(MIFID_CONST.PD)
      } else {
        setActiveMifid(MIFID_CONST.PG)
      }
    } else {
      setActiveMifid(MIFID_CONST.PF)
    }
  }

  componentWillMount() {
    // Imposto la mifid attiva
    this.setMifidType()
    MIFID_ACTIVE = getActiveMifid()
  }

  componentDidMount() {
    const {
      getProfessionalSubject,
      getDataRegistry,
      getDocumentsData,
      customer: { idCliente, idIncarico, codiceFiscale },
      summaryData: { statusEndPoint },
      match: { url },
      idPratica
    } = this.props
    getProfessionalSubject(idCliente === null || '' ? null : idCliente)
    getDataRegistry(codiceFiscale, idCliente, idIncarico, checkStatusService(statusEndPoint, 'dataRegistry'), false)
    getDocumentsData(codiceFiscale, idCliente, idIncarico, checkStatusService(statusEndPoint, 'idCardData'))

    if (url === `/consulenza-base-PG/${idPratica}/riepilogo-cliente`) {
      this.setState({ isProspectPersonaGiuridica: true })
    }
  }

  componentDidUpdate(prevProps) {
    const {
      history,
      // goToWhenSaved,
      getDataSummaryRequest,
      getDataSummaryFull,
      getDataSummaryFullRequest,
      dominioRequest,
      // updateStatoPraticaRequest,
      professionalSubject: { isProfessionalSubjectDone },
      customer: { idCliente, idIncarico }
    } = this.props

    if (prevProps.getDataSummaryRequest.fetching && !getDataSummaryRequest.fetching && !getDataSummaryRequest.error) {
      getDataSummaryFull(idCliente, idIncarico, isProfessionalSubjectDone)
    }

    if (
      prevProps.getDataSummaryFullRequest.fetching &&
      !getDataSummaryFullRequest.fetching &&
      !getDataSummaryFullRequest.error
    ) {
      const {
        summaryDataFull: {
          sezioneDocumentiIdentita,
          sezioneInformazioniPersonali,
          sezioneProcuratore,
          sezioneFatca = {}
        },
        retrieveDominio,
        fields
      } = this.props
      const currentFields = fields[1]
      const { NAZIONE_NASCITA, CITTADINANZA, CITTADINANZA_ALTRO } = currentFields[Forms.INFORMAZIONI_PERSONALI]
      const { RESIDENZA_USA } = currentFields[Forms.FATCA]
      let listaDep = []
      let showModal = false
      const comuni = ['Nascita', 'Residenza', 'InvioComunicazioni']
      const caps = ['Residenza', 'InvioComunicazioni']
      const provRilascio = sezioneDocumentiIdentita.provinciaRilascio
      const releaseProvince = sezioneProcuratore === null ? null : sezioneProcuratore.releaseProvince
      listaDep = [
        ...comuni.reduce((res, cur) => {
          const prov = sezioneInformazioniPersonali[`codProvincia${cur}`]
          if (!prov || !prov.chiave) return res
          return [...res, { id: `codProvincia${cur}`, filtro: { codice: prov.chiave } }]
        }, []),
        ...caps.reduce((res, cur) => {
          const com = sezioneInformazioniPersonali[`codComune${cur}`]
          if (!com || !com.chiave) return res
          return [...res, { id: `codComune${cur}`, filtro: { codice: com.chiave } }]
        }, [])
      ]
      if (provRilascio && provRilascio.chiave) {
        listaDep.push({
          id: 'provinciaRilascio',
          filtro: { codice: provRilascio.chiave }
        })
      }
      if (releaseProvince) {
        /* && releaseProvince.chiave */
        listaDep.push({
          id: 'releaseProvince',
          filtro: { codice: releaseProvince } /* .chiave */
        })
      }
      /* PROBLEMA - Da capire il perche nella logica gia esistente non vengono mappati queste 4 chiamate */
      if (sezioneProcuratore && sezioneProcuratore.residenceProvince) {
        retrieveDominio({
          listaDomini: [
            { idDominio: 'DOM_COMUNE_RESIDENZA_PROC', filtro: { codice: sezioneProcuratore.residenceProvince } }
          ]
        })
      }
      if (sezioneProcuratore && sezioneProcuratore.residencePlace) {
        retrieveDominio({
          listaDomini: [{ idDominio: 'DOM_CAP_RESIDENZA_PROC', filtro: { codice: sezioneProcuratore.residencePlace } }]
        })
      }
      if (
        sezioneInformazioniPersonali &&
        sezioneInformazioniPersonali.codProvinciaDomicilio &&
        sezioneInformazioniPersonali.codProvinciaDomicilio.chiave
      ) {
        retrieveDominio({
          listaDomini: [
            {
              idDominio: 'DOM_COMUNE_DOMICILIO',
              filtro: { codice: sezioneInformazioniPersonali.codProvinciaDomicilio.chiave }
            }
          ]
        })
      }
      if (
        sezioneInformazioniPersonali &&
        sezioneInformazioniPersonali.codComuneDomicilio &&
        sezioneInformazioniPersonali.codComuneDomicilio.chiave
      ) {
        retrieveDominio({
          listaDomini: [
            {
              idDominio: 'DOM_CAP_DOMICILIO',
              filtro: { codice: sezioneInformazioniPersonali.codComuneDomicilio.chiave }
            }
          ]
        })
      }

      retrieveDominio({
        listaDomini: fields.reduce((res, cur) => {
          return [
            ...res,
            ...Object.keys(cur).reduce((r, c) => {
              return [
                ...r,
                ...getDominioRequest(cur, c, listaDep).listaDomini.filter(el => el.filtro && el.filtro.codice)
              ]
            }, [])
          ]
        }, [])
      })

      if (
        (sezioneInformazioniPersonali[NAZIONE_NASCITA.id] || {}).chiave === NAZIONE_NASCITA.statiUnitiId &&
        (sezioneInformazioniPersonali[CITTADINANZA.id] || {}).chiave !== CITTADINANZA.statiUnitiId &&
        (sezioneInformazioniPersonali[CITTADINANZA_ALTRO.id] || {}).chiave !== CITTADINANZA_ALTRO.statiUnitiId &&
        !sezioneFatca[RESIDENZA_USA.id]
      ) {
        const { isAdmin } = this.props
        if (isAdmin) {
          this.setState({ textModal: 'firma', showModalGeneric: true })
        } else {
          showModal = true
        }
      }

      this.setState({ waitingDomini: true, showModal })
    }

    if (prevProps.dominioRequest.fetching && !dominioRequest.fetching && this.state.waitingDomini) {
      this.setState({ waitingDomini: false, loadingComplete: true })
    }

    if (
      prevProps.updatePraticaRequest.fetching &&
      !this.props.updatePraticaRequest.fetching &&
      this.props.updatePraticaRequest.error === null
    ) {
      if (this.state.firmaOra) {
        setRandomStr()
        // history.push(`/firma/${this.props.customer.idIncarico}`)
        if (this.props.flag) this.props.history.push(`/firma-cartacea/${this.props.customer.idIncarico}`)
        else this.props.history.push(`/firma-digitale/${this.props.customer.idIncarico}`)
      } else {
        history.push('/')
        // goToWhenSaved('/')
      }
    }
  }

  renderHeader(index, form, isEmpty) {
    return (
      <span
        className="accordion-info accordion-modifica"
        onClick={() => this.props.goToForced(getUrlFromIndex(index), form)}>
        {isEmpty ? 'Aggiungi' : 'Modifica'}
      </span>
    )
  }

  salva(firmaOra = false) {
    const {
      customer: { idIncarico },
      isAdmin,
      updateStatoPratica
    } = this.props
    if (isAdmin) {
      this.setState({ textModal: 'firma', showModalGeneric: true })
    } else {
      updateStatoPratica(idIncarico, 2, this.props.flag)
      this.setState({ firmaOra })
    }
  }

  nextStep() {
    // this.salva(true)
    this.setState({ firmaOra: true })
    // if (this.props.updatePraticaRequest.error === null) {
    //   if (this.props.flag) this.props.history.push(`/firma-cartacea/${this.props.idPratica}`)
    //   else this.props.history.push(`/firma-digitale/${this.props.idPratica}`)
    // }
  }

  modalHandler() {
    const { isAdmin } = this.props
    if (isAdmin) {
      this.setState({ textModal: 'firma', showModalGeneric: true })
    } else {
      this.setState({ typeFirma: '' })
      this.setState({ showModal: !this.state.showModal })
    }
  }
  closeModal() {
    this.setState({ showModalGeneric: false })
  }

  chooseFirma(selected, unselected) {
    this.setState({ typeFirma: selected })
    if (selected === 'firma') this.props.tipoFirma(false)
    else this.props.tipoFirma(true)

    // border box
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[0].style.border = '2px solid #083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[0].style.border = '2px solid #dadada'
    // icon
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[1].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[1].style.color = '#616166'
    // title
    document.getElementsByClassName(`onboarding-modale-icon__${selected}`)[2].style.color = '#083b67'
    document.getElementsByClassName(`onboarding-modale-icon__${unselected}`)[2].style.color = '#616166'
  }

  render() {
    const {
      customer,
      domini,
      summaryDataFull: {
        sezioneDocumentiIdentita = {},
        sezioneInformazioniPersonali = {},
        sezioneFatca = {},
        sezioneAntiriciclaggio = {},
        sezioneMifid = {},
        sezioneProcuratore = {},
        sezioneServiziDigitali = {},
        sezioneClientiProfessionali = {},
        sezioneSoggettiCorrelati = {}
      },
      templateAml,
      template,
      professionalSubject: { isProfessionalSubjectDone, professionalSubjectType }
    } = this.props
    return (
      <section className="main-body">
        {this.state.showModalGeneric && (
          <ModalGeneric
            show={this.state.showModalGeneric && this.state.loadingComplete}
            handler={this.closeModal}
            title="Attenzione!"
            text={check_intestatari[this.state.textModal]}
          />
        )}
        {this.state.showModal && (
          <ModalFirma
            customer={customer}
            later={this.state.later}
            handler={this.modalHandler}
            save={this.salva}
            updateStatoPratica={this.props.updateStatoPratica}
            nextStep={this.nextStep}
            title={<p className="onboarding-modale-content__title">Area di firma</p>}
            text={
              <p className="onboarding-modale-content__subtitle">
                Scegli tra <b>Firma Digitale</b> e <b>Firma Cartacea.</b> Seleziona la modalità desiderata.
              </p>
            }
            url={this.props.match.url}
            idPratica={this.props.idPratica}
            typeFirma={this.state.typeFirma}
            flag={this.props.flag}
            {...this.props}>
            <Row>
              <Col s={6} className="center-align">
                <div
                  className="onboarding-modale-icon onboarding-modale-icon__firma"
                  onClick={() => this.chooseFirma('firma', 'firma-cartacea')}>
                  <Icon className="onboarding-modale-icon__firma icon-icone-ips_sv-spesa" medium>
                    &#xe917;
                  </Icon>
                  <p className="onboarding-modale-icon__firma onboarding-modale-icon__title">FIRMA DIGITALE</p>
                </div>
              </Col>
              <Col s={6} className="center-align">
                <div
                  className="onboarding-modale-icon onboarding-modale-icon__firma-cartacea"
                  onClick={() => this.chooseFirma('firma-cartacea', 'firma')}>
                  <Icon className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa" medium>
                    &#xe918;
                  </Icon>
                  <p className="onboarding-modale-icon__firma-cartacea onboarding-modale-icon__title">FIRMA CARTACEA</p>
                </div>
              </Col>
            </Row>
          </ModalFirma>
        )}
        <article className="page-left-content sezione-riepilogo">
          <div className="background_pattern" />
          <h2 className="title-sezione">{dataSB.riepilogo.title_section}</h2>
          <Collapsible autoToggle>
            <CodiceFiscaleReview
              data={{
                ...sezioneDocumentiIdentita,
                codiceFiscale: sezioneInformazioniPersonali.codiceFiscale
              }}
              accordionHeader={this.renderHeader(0, Forms.CODICE_FISCALE)}
            />
            <DocumentiReview
              data={sezioneDocumentiIdentita}
              domini={domini}
              accordionHeader={this.renderHeader(0, Forms.DOCUMENTO_IDENTITA_ALLEGATO)}
            />
            {/* ZONA PROCURATORE */}
            {typeof sezioneProcuratore !== 'undefined' &&
              sezioneProcuratore !== null &&
              sezioneProcuratore !== '' &&
              sezioneProcuratore !== {} && (
                <ProcuratorePreview
                  data={sezioneProcuratore}
                  domini={domini}
                  accordionHeader={this.renderHeader(0, Forms.CENSIMENTO_PROCURATORE)}
                />
              )}
            {typeof sezioneProcuratore !== 'undefined' &&
              sezioneProcuratore !== null &&
              sezioneProcuratore !== '' &&
              sezioneProcuratore !== {} && (
                <ProcuratoreDocumentiReview
                  data={sezioneProcuratore}
                  domini={domini}
                  accordionHeader={this.renderHeader(0, Forms.PROCURATORE_ID)}
                />
              )}{' '}
            {typeof sezioneProcuratore !== 'undefined' &&
              sezioneProcuratore !== null &&
              sezioneProcuratore !== '' &&
              sezioneProcuratore !== {} && (
                <DocumentoProcuraReview
                  data={sezioneProcuratore}
                  domini={domini}
                  accordionHeader={this.renderHeader(0, Forms.DOCUMENTO_PROCURA)}
                />
              )}{' '}
            {isProfessionalSubjectDone && (
              <ClienteProfessionaleReview
                data={sezioneClientiProfessionali}
                domini={domini}
                accordionHeader={this.renderHeader(0, Forms.CLIENTI_PROFESSIONALI)}
              />
            )}
            <InformazioniPersonaliReview
              data={sezioneInformazioniPersonali}
              domini={domini}
              accordionHeader={this.renderHeader(1, Forms.INFORMAZIONI_PERSONALI)}
            />
            <ResidenzaReview
              data={sezioneInformazioniPersonali}
              domini={domini}
              accordionHeader={this.renderHeader(1, Forms.RESIDENZA)}
            />
            <ContattiReview
              data={sezioneInformazioniPersonali}
              domini={domini}
              accordionHeader={this.renderHeader(1, Forms.CONTATTI)}
            />
            <SoggettiCorrelati
              data={sezioneSoggettiCorrelati}
              domini={domini}
              accordionHeader={this.renderHeader(1, Forms.SOGGETTI_CORRELATI)}
            />
            <FatcaReview data={sezioneFatca} domini={domini} accordionHeader={this.renderHeader(1, Forms.FATCA)} />
            <AntiriciclaggioReview
              data={sezioneAntiriciclaggio}
              template={templateAml.sezioni ? templateAml.sezioni[0].listaDomande : []}
              domini={domini}
              accordionHeader={this.renderHeader(1, Forms.ANTIRICICLAGGIO)}
            />
            <ConsensiPrivacyReview
              data={sezioneAntiriciclaggio}
              template={templateAml.sezioni ? templateAml.sezioni[1].listaDomande : []}
              accordionHeader={this.renderHeader(1, Forms.CONSENSI_PRIVACY)}
              getDataSummaryFullRequest={this.props.getDataSummaryFullRequest}
            />
            {/* VECCHIA MIFID */}
            {!NEW_MIFID && sezioneMifid.flagProfilatura && (sezioneMifid || {}).listaDomande && (
              <Fragment>
                {typeof isProfessionalSubjectDone === 'boolean' && !isProfessionalSubjectDone && (
                  <ConoscenzaEsperienza
                    data={sezioneMifid}
                    template={template.sezioni.find(s => s.idSezione === Forms.MIFID_CONOSCENZA)}
                    titoloMifid={template.titoloSubSezione}
                    domini={domini}
                    accordionHeader={this.renderHeader(2, Forms.MIFID_CONOSCENZA)}
                  />
                )}

                {typeof isProfessionalSubjectDone === 'boolean' && (
                  <ObiettiviInvestimentoReview
                    data={sezioneMifid}
                    template={
                      isProfessionalSubjectDone
                        ? template.sezioni.find(s => s.idSezione === Forms.MIFID_OBIETTIVI_LIGHT)
                        : template.sezioni.find(s => s.idSezione === Forms.MIFID_OBIETTIVI)
                    }
                    accordionHeader={
                      isProfessionalSubjectDone
                        ? this.renderHeader(2, Forms.MIFID_OBIETTIVI_LIGHT)
                        : this.renderHeader(2, Forms.MIFID_OBIETTIVI)
                    }
                    isProfessionalSubjectDone={isProfessionalSubjectDone}
                  />
                )}

                {typeof isProfessionalSubjectDone === 'boolean' && !isProfessionalSubjectDone && (
                  <SituazioneFinanziariaReview
                    data={sezioneMifid}
                    template={template.sezioni.find(s => s.idSezione === Forms.MIFID_FINANZIARIA)}
                    accordionHeader={this.renderHeader(2, Forms.MIFID_FINANZIARIA)}
                  />
                )}

                {typeof isProfessionalSubjectDone === 'boolean' && (
                  <AltreInformazioniReview
                    data={sezioneMifid}
                    template={
                      isProfessionalSubjectDone
                        ? template.sezioni.find(s => s.idSezione === Forms.MIFID_ALTRO_LIGHT)
                        : template.sezioni.find(s => s.idSezione === Forms.MIFID_ALTRO)
                    }
                    accordionHeader={isEmpty =>
                      isProfessionalSubjectDone
                        ? this.renderHeader(2, Forms.MIFID_ALTRO_LIGHT, isEmpty)
                        : this.renderHeader(2, Forms.MIFID_ALTRO, isEmpty)
                    }
                    isProfessionalSubjectDone={isProfessionalSubjectDone}
                  />
                )}
              </Fragment>
            )}
            {!NEW_MIFID && !sezioneMifid.flagProfilatura && (
              <ConoscenzaEsperienza
                data={sezioneMifid}
                template={template.sezioni ? template.sezioni.find(s => s.idSezione === Forms.MIFID_CONOSCENZA) : ''}
                titoloMifid={template.titoloSubSezione}
                domini={domini}
                accordionHeader={this.renderHeader(2, Forms.MIFID_CONOSCENZA)}
              />
            )}
            {/* NUOVA MIFID */}
            {NEW_MIFID && sezioneMifid.flagProfilatura && (sezioneMifid || {}).listaDomande && (
              <Fragment>
                {professionalSubjectType !== 'DI_DIRITTO' && (
                  <Fragment>
                    {/* SOLO PF */}
                    {!isProfessionalSubjectDone && (
                      <AnagraficaReview
                        data={sezioneMifid}
                        template={template.sezioni.find(s => s.idSezione === Forms[MIFID_ACTIVE.ANAGRAFICA])}
                        accordionHeader={this.renderHeader(2, Forms[MIFID_ACTIVE.ANAGRAFICA])}
                      />
                    )}
                    <ConoscenzaEsperienza
                      data={sezioneMifid}
                      template={template.sezioni.find(s => s.idSezione === Forms[MIFID_ACTIVE.CONOSCENZA])}
                      titoloMifid={template.titoloSubSezione}
                      domini={domini}
                      accordionHeader={this.renderHeader(2, Forms[MIFID_ACTIVE.CONOSCENZA])}
                    />
                  </Fragment>
                )}
                {professionalSubjectType !== 'DI_DIRITTO' && (
                  <SituazioneFinanziariaReview
                    data={sezioneMifid}
                    template={template.sezioni.find(s => s.idSezione === Forms[MIFID_ACTIVE.FINANZIARIA])}
                    accordionHeader={this.renderHeader(2, Forms[MIFID_ACTIVE.FINANZIARIA])}
                  />
                )}
                <TolleranzaOscillazioneReview
                  data={sezioneMifid}
                  template={template.sezioni.find(s => s.idSezione === Forms[MIFID_ACTIVE.TOLLERANZA])}
                  accordionHeader={this.renderHeader(2, Forms[MIFID_ACTIVE.TOLLERANZA])}
                />
                <ObiettiviInvestimentoReview
                  data={sezioneMifid}
                  template={template.sezioni.find(s => s.idSezione === Forms[MIFID_ACTIVE.OBIETTIVI])}
                  accordionHeader={this.renderHeader(2, Forms[MIFID_ACTIVE.OBIETTIVI])}
                  isProfessionalSubjectDone={isProfessionalSubjectDone}
                />
                <InvestimentiESGReview
                  data={sezioneMifid}
                  template={template.sezioni.find(s => s.idSezione === Forms[MIFID_ACTIVE.INVESTIMENTI_ESG])}
                  accordionHeader={this.renderHeader(2, Forms[MIFID_ACTIVE.INVESTIMENTI_ESG])}
                  isProfessionalSubjectDone={isProfessionalSubjectDone}
                />
                {professionalSubjectType === 'DI_DIRITTO' && (
                  <SituazioneFinanziariaReview
                    data={sezioneMifid}
                    template={template.sezioni.find(s => s.idSezione === Forms[MIFID_ACTIVE.FINANZIARIA])}
                    accordionHeader={this.renderHeader(2, Forms[MIFID_ACTIVE.FINANZIARIA])}
                  />
                )}
                {professionalSubjectType !== 'DI_DIRITTO' && (
                  <EducazioneFinanziariaReview
                    data={sezioneMifid}
                    template={template.sezioni.find(s => s.idSezione === Forms[MIFID_ACTIVE.EDUCAZIONE])}
                    accordionHeader={this.renderHeader(2, Forms[MIFID_ACTIVE.EDUCAZIONE])}
                  />
                )}
              </Fragment>
            )}
            <SottoscrizioneServiziReview
              data={{
                ...sezioneInformazioniPersonali,
                ...sezioneServiziDigitali
              }}
              domini={domini}
              accordionHeader={this.renderHeader(3, Forms.SERVIZI_DIGITALI)}
            />
          </Collapsible>
          <FirmaFooter
            onSaveText="Sarà possibile firmare in modalità digitale o cartacea"
            save={this.salva}
            openModal={this.modalHandler}
            call_back={this.setLater}
            dinamicClass="colorBlue"
          />
        </article>
      </section>
    )
  }
}

RiepilogoCliente.propTypes = {
  customer: PropTypes.object,
  summaryDataFull: PropTypes.object,
  domini: PropTypes.object,
  getDataSummaryFull: PropTypes.func,
  getDataSummaryFullRequest: PropTypes.object,
  retrieveDominio: PropTypes.func,
  dominioRequest: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.any
  }),
  history: PropTypes.any,
  templateAml: PropTypes.object,
  template: PropTypes.object,
  goToWhenSaved: PropTypes.func.isRequired,
  goToForced: PropTypes.func,
  updateStatoPratica: PropTypes.func,
  updateStatoPraticaRequest: PropTypes.object,
  fields: PropTypes.array,
  getProfessionalSubject: PropTypes.func,
  professionalSubject: PropTypes.object,
  match: PropTypes.any,
  idPratica: PropTypes.any,
  getDataRegistry: PropTypes.any,
  summaryData: PropTypes.any,
  getDocumentsData: PropTypes.any,
  getDataSummaryRequest: PropTypes.object,
  tipoFirma: PropTypes.func,
  flag: PropTypes.any,
  updatePraticaRequest: PropTypes.any,
  confirmPraticaRequest: PropTypes.any,
  isAdmin: PropTypes.bool
}

export default RiepilogoCliente
