import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
// import dataSB from '../../../../static-data/data-servizio-base.json'
import SchedaDatiPepItem from './SchedaDatiPepItem'

// import {
//   getDominio,
//   getAutocompleteDefaultOption,
//   mapDominiToAutocomplete,
//   mapDominiToOptions,
//   getValueDominioFromKey

// } from '../../utils'

// const documenti = dataSB.acquisizione.documento_identita

class SchedaDatiPep extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      test: true,
      unicoPep: true
    }
    // this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    // let index = this.props.position
    // const { SETTORE_OCCUPAZIONE_0, NAZIONE_SCHEDA_DATI_0 } = this.props.fields
    // const payload = {
    //   listaDomini: [
    //     {
    //       idDominio: SETTORE_OCCUPAZIONE_0.dominio
    //     },
    //     {
    //       idDominio: NAZIONE_SCHEDA_DATI_0.dominio
    //     }
    //   ]
    // }
    // this.props.dominioCustomRequest(payload)
  }

  render() {
    return (
      <Row>
        <Col s={11} className="offset05">
          <SchedaDatiPepItem showComp={!this.state.unicoPep} counter={this.props.counter || 0} {...this.props} />
        </Col>
      </Row>
    )
  }
}

SchedaDatiPep.propTypes = {
  // submitHandler: PropTypes.func,
  titolare: PropTypes.bool,
  titolareMulti: PropTypes.bool,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  // apiError: PropTypes.object,
  // initialize: PropTypes.func,
  change: PropTypes.func,
  touch: PropTypes.func,
  // resetForm: PropTypes.func,
  // currentInitial: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  domini: PropTypes.object,
  // dominioCustomRequest: PropTypes.func,
  // submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  anyTouched: PropTypes.bool,
  // dataNascitaCF: PropTypes.any,
  fields: PropTypes.object,
  dominioCustomRequest: PropTypes.func,
  counter: PropTypes.number
  // flagCustomerIsBlacklist: PropTypes.any
}

SchedaDatiPep.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default SchedaDatiPep
