import React, { Component, Fragment } from 'react'
import { SingleDatePicker } from 'react-dates'
import { Icon } from 'react-materialize'
import { PropTypes } from 'prop-types'
import 'react-dates/lib/css/_datepicker.css'
import moment from 'moment'

/**
 * This replaces the monnth header to show a year selection menu
 * when the user clicks on the date. As f 31/05/18 there is no
 * way to do it simply using the react-dates API.
 */
class SelectYearMonthCaption extends Component {
  constructor(props) {
    super(props)
    this.state = {
      yearMenuOpen: false
    }

    this._handleYearSelect = this._handleYearSelect.bind(this)
  }

  _handleYearSelect(year) {
    this.props.changeYear(moment().set('year', year))
    this.setState({ yearMenuOpen: !this.state.yearMenuOpen })
  }

  render() {
    const { month } = this.props
    const { yearMenuOpen } = this.state
    const currentYear = month.year()

    let yearMenu = null

    // Year menu opens only if the range validation is defined (to only show valid years)
    if (this.state.yearMenuOpen && this.props.isOutsideRange) {
      const years = []

      for (let i = currentYear + 120; i > currentYear - 120; i--) {
        if (!this.props.isOutsideRange(moment().set('year', i))) {
          years.push(
            <div key={i} onClick={() => this._handleYearSelect(i)}>
              {i}
            </div>
          )
        }
      }
      yearMenu = <div className="years-panel">{years}</div>
    }

    return (
      <Fragment>
        <div className="CalendarMonth_caption CalendarMonth_caption_1 year-select-month-caption">
          <strong>{moment(month).format('MMMM ')}</strong>
          <strong>
            {moment(month).format('YYYY')}
            {yearMenu}
          </strong>
          <span
            className={`icon-expand${yearMenuOpen ? ' expanded' : ''}`}
            onClick={() => this.setState({ yearMenuOpen: !yearMenuOpen })}>
            {/* <Icon>{`expand_${yearMenuOpen ? 'less' : 'more'}`}</Icon> */}
            <Icon>expand_more</Icon>
          </span>
        </div>
        <div className="DayPicker_weekHeader DayPicker_weekHeader_custom">
          <ul className="DayPicker_weekHeader_ul DayPicker_weekHeader_ul_1">
            {['lu', 'ma', 'me', 'gi', 've', 'sa', 'do'].map(g => (
              <li key={g} className="DayPicker_weekHeader_li DayPicker_weekHeader_li_1">
                <small>{g}</small>
              </li>
            ))}
          </ul>
        </div>
      </Fragment>
    )
  }
}
SelectYearMonthCaption.propTypes = {
  changeYear: PropTypes.func,
  month: PropTypes.object,
  isOutsideRange: PropTypes.func
}

class DatePicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      focused: false,
      date: props.defaultDate ? moment(props.defaultDate) : null,
      initialVisibleMonth: props.defaultDate ? moment(props.defaultDate) : moment(),
      refocus: false
    }

    this._handleChangeYear = this._handleChangeYear.bind(this)
    this.initialVisibleMonth = this.initialVisibleMonth.bind(this)
    this._handleChange = this._handleChange.bind(this)
  }

  componentDidUpdate() {
    // To refresh the date in the menu this workaround is needed (as of 31/05/18:
    // the menu must be closed and then reopened
    let _this = this

    if (!moment(this.props.value).isValid() && moment(this.props.value).format() !== moment(this.state.date).format()) {
      this.setState({ date: null })
    }

    if (this.state.refocus) {
      this.setState({
        focused: true,
        refocus: false,
        date: moment(_this.props.defaultDate)
      })
    }
  }
  componentDidMount() {
    this.setState({ date: this.props.value ? moment(this.props.value) : null })
  }

  _handleChange(date) {
    this.setState({ date: date })
    if (this.props.onDateChange) {
      this.props.onDateChange(date)
    }
  }

  /**
   * Sets the year chosen in the year select menu
   */
  _handleChangeYear(year) {
    this.setState({
      initialVisibleMonth: year,
      focused: false,
      refocus: true
    })
  }

  /**
   * Returns the default month shown when the menu opens.
   * If a date is already selected in the text box, this is
   * the month to show. Otherwise, show the one passed from
   * the props
   */
  initialVisibleMonth() {
    if (this.props.initialVisibleMonth) {
      return this.props.initialVisibleMonth()
    }
    return this.state.initialVisibleMonth
  }

  render() {
    const {
      alignLeft,
      label,
      s,
      meta: { error, touched },
      touch,
      untouch, // se la fix non va bene cancellare questo
      name
    } = this.props

    const className =
      'date-picker ' +
      (touched && error ? 'invalid ' : '') +
      (s ? 'col input-field s' + s + ' ' : '') +
      (this.state.focused ? 'active ' : '') +
      (alignLeft ? 'align-left ' : '')

    return (
      <div className={`${className}${this.state.date ? ' with-date' : ''}`}>
        {label && (
          <div className="date-picker-label">
            <label error={error}>{label}</label>
          </div>
        )}
        <SingleDatePicker
          placeholder={this.props.placeholder}
          openDirection={this.props.openDirection}
          // anchorDirection={this.props.anchorDirection}
          date={this.state.date}
          onDateChange={date => this._handleChange(date.utc().startOf('day'))}
          focused={this.state.focused}
          onFocusChange={({ focused }) => {
            if (untouch) {
              // da qui
              if (focused) {
                untouch(name)
              } else {
                touch(name)
              }
            } else {
              touch(name)
            } // fino a qui work around per evitare che in precensimento ci sia una validate d'errore all'apertura del calentario
            this.setState({
              focused,
              initialVisibleMonth: this.state.date || moment()
            })
          }}
          numberOfMonths={1}
          showDefaultInputIcon
          hideKeyboardShortcutsPanel
          customInputIcon={<Icon className="prefix">date_range</Icon>}
          disabled={this.props.disabled || false}
          isOutsideRange={this.props.isOutsideRange}
          isDayBlocked={this.props.isDayBlocked}
          initialVisibleMonth={this.initialVisibleMonth}
          readOnly={this.props.readOnly}
          renderMonth={month => (
            <SelectYearMonthCaption
              month={month}
              changeYear={this._handleChangeYear}
              isOutsideRange={this.props.isOutsideRange}
            />
          )}
        />
      </div>
    )
  }
}
export default DatePicker

DatePicker.propTypes = {
  alignLeft: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onDateChange: PropTypes.func,
  openDirection: PropTypes.string,
  // anchorDirection: PropTypes.string,
  untouch: PropTypes.any,
  name: PropTypes.string,
  meta: PropTypes.object,
  touch: PropTypes.func,
  disabled: PropTypes.bool,
  s: PropTypes.number,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  initialVisibleMonth: PropTypes.func,
  readOnly: PropTypes.bool,
  defaultDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.string
}

DatePicker.defaultProps = {
  meta: {
    error: ''
  },
  touch: () => {}
}
