import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { Row, Col, Button } from 'react-materialize'
import InputText from '../../Inputs/Text'
import ModalPrivacy from '../../Modals/Privacy'
import CalcoloCodiceFiscale from '../CalcoloCodiceFiscale'
import {
  getAutocompleteDefaultOption,
  mapDominiToAutocomplete,
  getDominio
} from '../../../routes/ConsulenzaBaseContainerLayout/utils'
import { renderAutocomplete, renderDatePicker, renderSelect } from '../renderFields'
import Confirm from '../../Confirm'
import { checkInvalidBirthdate } from '../../../utils'
import Header from '../../Header'

class ProspectGiuridiche extends Component {
  constructor(props) {
    super(props)

    this.state = {
      codiceFiscale: ''
    }
    this.createBodyRequestForPrivacy = this.createBodyRequestForPrivacy.bind(this)
    this.createFisicalPerson = this.createFisicalPerson.bind(this)
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      codiceFiscale: ''
    })
    this.props.change('nationBirthCode', 'ITA')
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.legalPersonCreatePFRequest.fetching &&
      !this.props.legalPersonCreatePFRequest.fetching &&
      !this.props.legalPersonCreatePFRequest.error
    ) {
      this.props.history.push(`/consulenza-base-PG/${this.props.idPratica}/data-gathering`)
    }
  }

  createFisicalPerson() {
    let idPhysicalPersonReference = this.props.idPhysicalPersonReference
    let idLegalPerson = this.props.idLegalPerson
    this.props.legalPersonCreatePF({ ...idPhysicalPersonReference, idLegalPerson })
    // checkCFRequest
    // const {}
    //
  }

  createBodyRequestForPrivacy(fileUploadAcceptPrivacy, fileUploadAcceptPrivacyThumb) {
    const { formValues, fields } = this.props
    const {
      // NOME,
      // COGNOME,
      CODICE_FISCALE_PG,
      // CODICE_FISCALE,
      PARTITA_IVA_PG,
      // NAZIONALITA_ULTERIORI_DATI,
      // COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
      // RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
      // COMUNE_NASCITA_ULTERIORI_DATI,
      // DATA_NASCITA_ULTERIORI_DATI,
      // SESSO_ULTERIORI_DATI,
      RAGIONE_SOCIALE
      // CODICE_FISCALE_PG_PG,
      // PARTITA_IVA_PG
    } = fields
    return {
      businessName: formValues[RAGIONE_SOCIALE.id],
      isPrivacyAcceptanceDone: true,
      subjectType: 'LEGAL_PERSON',
      taxCode: formValues[CODICE_FISCALE_PG.id] == null ? null : formValues[CODICE_FISCALE_PG.id].toUpperCase(),
      vatCode: formValues[PARTITA_IVA_PG.id] == null ? null : formValues[PARTITA_IVA_PG.id]
    }
    // if (formValues[NAZIONALITA_ULTERIORI_DATI.id] === '1') {
    //   return {
    //     // name: formValues[NOME.id],
    //     // surname: formValues[COGNOME.id],
    //     taxCode: formValues[CODICE_FISCALE_PG.id] == null ? null : formValues[CODICE_FISCALE_PG.id].toUpperCase(),
    //     vatCode: formValues[PARTITA_IVA_PG.id] == null ? null : formValues[PARTITA_IVA_PG.id],
    //     isPrivacyAcceptanceDone: true,
    //     cityBirthDescription: null, // comune estero
    //     citizenshipCode:
    //       formValues[NAZIONALITA_ULTERIORI_DATI.id] == null
    //         ? null
    //         : parseInt(formValues[NAZIONALITA_ULTERIORI_DATI.id]), // Nazione
    //     cityBirthCode:
    //       formValues[COMUNE_NASCITA_ULTERIORI_DATI.id] == null
    //         ? null
    //         : parseInt(formValues[COMUNE_NASCITA_ULTERIORI_DATI.id]), // codice comune
    //     registeredResidenceCode:
    //       formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] == null
    //         ? null
    //         : parseInt(formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id]), // residenza
    //     fileUploadAcceptPrivacy: fileUploadAcceptPrivacy,
    //     fileUploadAcceptPrivacyThumb: fileUploadAcceptPrivacyThumb,
    //     subjectType: 'LEGAL_PERSON',
    //     birthDate: formValues[DATA_NASCITA_ULTERIORI_DATI.id],
    //     emailAddress: null,
    //     sex: formValues[SESSO_ULTERIORI_DATI.id],
    //     businessName: formValues[RAGIONE_SOCIALE.id]
    //   }
    // } else {
    //   return {
    //     name: formValues[NOME.id],
    //     surname: formValues[COGNOME.id],
    //     taxCode: formValues[CODICE_FISCALE.id] == null ? null : formValues[CODICE_FISCALE.id].toUpperCase(),
    //     isPrivacyAcceptanceDone: true,
    //     cityBirthCode: null, // codice comune
    //     cityBirthDescription: formValues[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id], // comune estero
    //     citizenshipCode:
    //       formValues[NAZIONALITA_ULTERIORI_DATI.id] == null
    //         ? null
    //         : parseInt(formValues[NAZIONALITA_ULTERIORI_DATI.id]), // Nazione
    //     registeredResidenceCode:
    //       formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id] == null
    //         ? null
    //         : parseInt(formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id]), // residenza
    //     fileUploadAcceptPrivacy: fileUploadAcceptPrivacy,
    //     fileUploadAcceptPrivacyThumb: fileUploadAcceptPrivacyThumb,
    //     subjectType: 'LEGAL_PERSON',
    //     birthDate: formValues[DATA_NASCITA_ULTERIORI_DATI.id],
    //     emailAddress: null,
    //     sex: formValues[SESSO_ULTERIORI_DATI.id],
    //     businessName: formValues[RAGIONE_SOCIALE.id]
    //   }
    // }
  }

  render() {
    const {
      handleSubmit,
      change,
      domini,

      isUserAvailable,
      isProspectPGAvailable,
      history: { push },
      formValues = {},
      formErrors = {},
      formWarnings = {},
      touch,
      untouch,
      computeCFRequest,
      showModal,
      handleModal,
      tabs,
      fields,
      checkNazione,
      checkProvincia,
      checkNazionalitaUlterioriDati,
      computeCFstate,
      handleComputeCFstate,
      handleGetCF,
      updateDominio,
      verified,
      verifiedPG,
      handleVerified,
      handleVerifiedPG,
      imageupload,
      openCamera,
      handleSubmitPG,
      handleSubmitPF,
      innerRef,
      dirty,
      invalid
    } = this.props

    const {
      TIPO_INSERIMENTO,
      RAGIONE_SOCIALE,
      NOME,
      COGNOME,
      CODICE_FISCALE,
      ULTERIORI_DATI,
      NAZIONALITA_ULTERIORI_DATI,
      RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
      COMUNE_NASCITA_ULTERIORI_DATI,
      COMUNE_NASCITA_ESTERO_ULTERIORI_DATI,
      DATA_NASCITA_ULTERIORI_DATI,
      CODICE_FISCALE_PG,
      PARTITA_IVA_PG,
      SESSO_ULTERIORI_DATI
    } = fields

    return (
      <Fragment>
        <Row>
          <ModalPrivacy
            show={showModal}
            fields={fields}
            parentFormValues={formValues}
            createBodyRequestForPrivacy={this.createBodyRequestForPrivacy}
            isProspectPG
            closeHandler={handleModal}
            history={this.props.history.push}
            continueHandler={() => {
              this.createFisicalPerson()
            }}
            modalHandler={handleModal}
          />
          <Col s={12} className="paddingless">
            <section className="main-body no-aside consulenza-base-codice-fiscale">
              <Header tabs={tabs} title={'Censimento Prospect'} activeTabIndex={1} />
              <article className="page-left-content">
                <div className="box-container box-codice-fiscale">
                  <h6
                    style={{
                      color: '#003399'
                    }}>
                    Persona giuridica
                  </h6>
                  <Row s={12}>
                    <Col>
                      <Field
                        name={TIPO_INSERIMENTO.id}
                        className="with-gap"
                        type="radio"
                        id="partita-iva"
                        value="partita-iva"
                        component="input"
                        onChange={() => {
                          handleVerifiedPG(false)
                          change(CODICE_FISCALE_PG.id, '')
                          untouch(CODICE_FISCALE_PG.id)
                        }}
                      />
                      <label htmlFor="partita-iva">Partita IVA</label>
                    </Col>
                    <Col>
                      <Field
                        name={TIPO_INSERIMENTO.id}
                        className="with-gap"
                        type="radio"
                        id="codice-fiscale"
                        value="codice-fiscale"
                        component="input"
                        onChange={() => {
                          handleVerifiedPG(false)
                          change(PARTITA_IVA_PG.id, '')
                          untouch(PARTITA_IVA_PG.id)
                        }}
                      />

                      <label htmlFor="codice-fiscale">Codice Fiscale</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col s={6}>
                      {formValues[TIPO_INSERIMENTO.id] === 'codice-fiscale' || !formValues[TIPO_INSERIMENTO.id] ? (
                        <Field
                          name={CODICE_FISCALE_PG.id}
                          s={12}
                          disabled={formValues[TIPO_INSERIMENTO.id] !== 'codice-fiscale'}
                          maxLength={CODICE_FISCALE_PG.maxLength}
                          label={formValues[TIPO_INSERIMENTO.id] !== 'codice-fiscale' ? '' : CODICE_FISCALE_PG.label}
                          change={(field, value) => {
                            handleVerifiedPG(false)
                            change(field, value)
                          }}
                          touch={touch}
                          component={InputText}
                          formatter={value => (value ? value.toUpperCase() : '')}
                        />
                      ) : (
                        <Field
                          name={PARTITA_IVA_PG.id}
                          s={12}
                          disabled={formValues[TIPO_INSERIMENTO.id] !== 'partita-iva'}
                          maxLength={PARTITA_IVA_PG.maxLength}
                          label={formValues[TIPO_INSERIMENTO.id] !== 'partita-iva' ? '' : PARTITA_IVA_PG.label}
                          change={(field, value) => {
                            handleVerifiedPG(false)
                            change(field, value)
                          }}
                          touch={touch}
                          component={InputText}
                        />
                      )}
                    </Col>
                    <Col s={6}>
                      <Field
                        name={RAGIONE_SOCIALE.id}
                        s={12}
                        maxLength={RAGIONE_SOCIALE.maxLength}
                        label={RAGIONE_SOCIALE.label}
                        change={(field, value) => {
                          handleVerifiedPG(false)
                          change(field, value)
                        }}
                        touch={touch}
                        component={InputText}
                      />
                    </Col>
                  </Row>
                  {(!verifiedPG || !isProspectPGAvailable) && (
                    <Row className="center-button m-top20">
                      <Button onClick={handleSubmitPG} disabled={!dirty || invalid}>
                        Verifica se già cliente
                      </Button>
                    </Row>
                  )}
                  {verifiedPG && isProspectPGAvailable && (
                    <Confirm
                      className="m-top20"
                      text={`${
                        formValues[TIPO_INSERIMENTO.id] === 'codice-fiscale' ? 'Codice fiscale' : 'Partita IVA'
                      } non presente nel database`}>
                      <i className="material-icons">check_circle_outline</i>
                    </Confirm>
                  )}
                  {verifiedPG && !isProspectPGAvailable && (
                    <Confirm
                      className="m-top20"
                      text={`${
                        formValues[TIPO_INSERIMENTO.id] === 'codice-fiscale' ? 'Codice fiscale' : 'Partita IVA'
                      } presente nel database`}>
                      <i className="material-icons">error_outline</i>
                    </Confirm>
                  )}
                </div>
              </article>
            </section>
          </Col>
        </Row>

        <Col s={12} className="paddingless">
          <section className="main-body no-aside consulenza-base-codice-fiscale">
            <article className="page-left-content">
              <form onSubmit={handleSubmit(handleSubmitPF)} encType="multipart/form-data">
                <div className="box-container box-codice-fiscale">
                  <h6
                    style={{
                      color: '#003399'
                    }}>
                    Persona fisica di riferimento
                  </h6>
                  <Row>
                    <Col s={12}>
                      <Field
                        name={NOME.id}
                        s={6}
                        maxLength={NOME.maxLength}
                        label={NOME.label}
                        change={change}
                        touch={touch}
                        component={InputText}
                      />
                      <Field
                        name={COGNOME.id}
                        s={6}
                        maxLength={COGNOME.maxLength}
                        label={COGNOME.label}
                        change={change}
                        touch={touch}
                        component={InputText}
                      />
                    </Col>
                  </Row>
                  <CalcoloCodiceFiscale
                    computeCFstate={computeCFstate}
                    onClick={handleComputeCFstate}
                    fields={fields}
                    formValues={formValues}
                    domini={domini}
                    change={change}
                    checkNazione={checkNazione}
                    checkProvincia={checkProvincia}
                    computeCFRequest={computeCFRequest.fetching}
                    formWarnings={formWarnings}
                    handleGetCF={handleGetCF}
                    updateDominio={updateDominio}
                    notVerified={handleVerified}
                    formErrors={formErrors}
                    ulterioriDatiChange={() => {
                      if (formValues[ULTERIORI_DATI.id]) change(ULTERIORI_DATI.id, false)
                    }}
                  />
                  <Row className="m-top20">
                    <Col s={11}>
                      <Field
                        name={CODICE_FISCALE.id}
                        s={12}
                        minLength={CODICE_FISCALE.minLength}
                        maxLength={CODICE_FISCALE.maxLength}
                        label={`${formValues[ULTERIORI_DATI.id] ? '' : CODICE_FISCALE.label}`}
                        disabled={formValues[ULTERIORI_DATI.id]}
                        change={(field, value) => {
                          handleVerified(false)
                          change(field, value)
                        }}
                        touch={touch}
                        component={InputText}
                        formatter={value => (value ? value.toUpperCase() : '')}
                      />
                    </Col>
                    <Col s={1} className="col-barcode">
                      <input
                        name="image"
                        type="file"
                        ref={input => (innerRef.current = input)}
                        accept=".jpg, .png, .jpeg, .bmp, .tif, .tiff|images/*"
                        capture
                      />
                      <i className="icon-barcode" onClick={openCamera} />
                    </Col>
                  </Row>
                  <Row className={`m-top10${formValues[ULTERIORI_DATI.id] ? '' : ' m-bottom50'}`}>
                    <Col s={12} className="hr">
                      <hr />
                      <a
                        onClick={() => {
                          handleVerified(false)
                          // this.setState({ verified: false, verificaModale: true }) // !this.state.verified
                          change(ULTERIORI_DATI.id, !formValues[ULTERIORI_DATI.id])
                          if (computeCFstate) {
                            handleComputeCFstate()
                          }
                          if (formValues[CODICE_FISCALE.id] && formValues[CODICE_FISCALE.id] !== '') {
                            this.state.codiceFiscale = formValues[CODICE_FISCALE.id]
                            change(CODICE_FISCALE.id, '')
                          }
                          if (!formValues[ULTERIORI_DATI.id] === true) {
                            untouch(
                              NAZIONALITA_ULTERIORI_DATI.id,
                              RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id,
                              COMUNE_NASCITA_ULTERIORI_DATI.id,
                              COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id,
                              DATA_NASCITA_ULTERIORI_DATI.id,
                              SESSO_ULTERIORI_DATI.id
                            )
                          } else if (this.state.codiceFiscale !== '') {
                            change(CODICE_FISCALE.id, this.state.codiceFiscale)
                          }
                        }}
                        className="indirizzo-alternativo-button">
                        {`${formValues[ULTERIORI_DATI.id] ? 'Nascondi Ulteriori Dati' : 'Ulteriori Dati'}`}
                        <i className="material-icons">{`expand_${formValues[ULTERIORI_DATI.id] ? 'less' : 'more'}`}</i>
                      </a>
                    </Col>
                  </Row>
                  <div
                    className={`box-ulteriori-dati calcolo-cf${
                      formValues[ULTERIORI_DATI.id] ? ' calcolo-cf-active' : ''
                    }`}>
                    <div>
                      <Row>
                        <Col s={6}>
                          <Field
                            name={NAZIONALITA_ULTERIORI_DATI.id}
                            size={12}
                            label={NAZIONALITA_ULTERIORI_DATI.label}
                            threshold={0}
                            defaultValue={getAutocompleteDefaultOption(
                              domini.listaDomini,
                              NAZIONALITA_ULTERIORI_DATI.dominio,
                              formValues[NAZIONALITA_ULTERIORI_DATI.id]
                            )}
                            options={mapDominiToAutocomplete(getDominio(domini, NAZIONALITA_ULTERIORI_DATI.dominio))}
                            handleChange={(field, value) => {
                              change(field, value)
                              if (formValues[COMUNE_NASCITA_ULTERIORI_DATI.id]) {
                                change(COMUNE_NASCITA_ULTERIORI_DATI.id, '')
                              }
                              if (formValues[COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id]) {
                                change(COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id, '')
                              }
                              handleVerified(false)
                              untouch(RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id)
                            }}
                            component={renderAutocomplete}
                          />
                        </Col>
                        <Col s={6}>
                          <Field
                            name={RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id}
                            s={12}
                            label={RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.label}
                            threshold={0}
                            defaultValue={getAutocompleteDefaultOption(
                              domini.listaDomini,
                              RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.dominio,
                              formValues[RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id]
                            )}
                            options={mapDominiToAutocomplete(
                              getDominio(domini, RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.dominio)
                            )}
                            handleChange={(field, value) => {
                              change(field, value)
                              handleVerified(false)
                              untouch(RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id)
                            }}
                            component={renderAutocomplete}
                          />
                        </Col>
                      </Row>
                      <Row>
                        {(checkNazionalitaUlterioriDati(true) || !formValues[NAZIONALITA_ULTERIORI_DATI.id]) && (
                          <Col s={6}>
                            <Field
                              name={COMUNE_NASCITA_ULTERIORI_DATI.id}
                              s={12}
                              threshold={0}
                              label={COMUNE_NASCITA_ULTERIORI_DATI.label}
                              /* disabled={!this.checkProvincia(formValues[PROVINCIA_NASCITA.id])} */
                              defaultValue={getAutocompleteDefaultOption(
                                domini.listaDomini,
                                COMUNE_NASCITA_ULTERIORI_DATI.dominio,
                                formValues[COMUNE_NASCITA_ULTERIORI_DATI.id]
                              )}
                              options={mapDominiToAutocomplete(
                                getDominio(domini, COMUNE_NASCITA_ULTERIORI_DATI.dominio)
                              )}
                              handleChange={(field, value) => {
                                change(field, value)
                                handleVerified(false)
                                untouch(COMUNE_NASCITA_ULTERIORI_DATI.id)
                              }}
                              component={renderAutocomplete}
                            />
                          </Col>
                        )}
                        {!checkNazionalitaUlterioriDati(true) && formValues[NAZIONALITA_ULTERIORI_DATI.id] && (
                          <Col s={6} className="comune-nascita-estero">
                            <Field
                              name={COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id}
                              s={12}
                              threshold={0}
                              label={COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.label}
                              change={(field, value) => {
                                change(field, value)
                                handleVerified(false)
                              }}
                              component={InputText}
                              touch={touch}
                            />
                          </Col>
                        )}
                        <Col s={6}>
                          <Field
                            name={DATA_NASCITA_ULTERIORI_DATI.id}
                            touch={touch}
                            untouch={untouch}
                            s={12}
                            openDirection="down"
                            anchorDirection="right"
                            label={DATA_NASCITA_ULTERIORI_DATI.label}
                            readOnly
                            placeholder=""
                            isOutsideRange={date => checkInvalidBirthdate(date)}
                            defaultDate={formValues[DATA_NASCITA_ULTERIORI_DATI.id]}
                            onDateChange={date => {
                              change(DATA_NASCITA_ULTERIORI_DATI.id, date.format())
                              handleVerified(false)
                              untouch(DATA_NASCITA_ULTERIORI_DATI.id)
                            }}
                            component={renderDatePicker}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Field
                          name={SESSO_ULTERIORI_DATI.id}
                          size={6}
                          label={SESSO_ULTERIORI_DATI.label}
                          options={[
                            <option key={-1} value="" disabled>
                              -
                            </option>,
                            <option key={1} value="M">
                              Maschio
                            </option>,
                            <option key={2} value="F">
                              Femmina
                            </option>
                          ]}
                          onChange={(field, value) => {
                            change(field, value)
                            handleVerified(false)
                          }}
                          component={renderSelect}
                        />
                      </Row>
                    </div>
                  </div>
                  {!verified && !isUserAvailable && (
                    <Row className="center-button m-top20">
                      <Button type="submit" disabled={invalid || !verifiedPG}>
                        Verifica se già cliente
                      </Button>
                    </Row>
                  )}
                  {verified && isUserAvailable && (
                    <Confirm className="m-top20" text="Codice fiscale non presente nel database.">
                      <i className="material-icons">check_circle_outline</i>
                    </Confirm>
                  )}
                  {verified && !isUserAvailable && (
                    <Confirm className="m-top20" text={`Codice fiscale presente nel database`}>
                      <i className="material-icons">error_outline</i>
                    </Confirm>
                  )}
                </div>
              </form>
              <div className="final-note">* Campi obbligatori</div>
              <Row className="footer-buttons">
                <Button className="back-button" onClick={() => push(`/`)}>
                  Esci
                </Button>
                <Button
                  className="accetta"
                  disabled={!verified || !verifiedPG || !isUserAvailable || invalid}
                  onClick={handleModal}>
                  Accetta Privacy
                </Button>
              </Row>
            </article>
            <img id="preview" src={imageupload} />
          </section>
        </Col>
      </Fragment>
    )
  }
}

ProspectGiuridiche.propTypes = {
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  domini: PropTypes.object,
  idPratica: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUserAvailable: PropTypes.bool,
  isProspectPGAvailable: PropTypes.bool,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  formWarnings: PropTypes.object,
  touch: PropTypes.func,
  untouch: PropTypes.func,
  computeCFRequest: PropTypes.object,
  showModal: PropTypes.bool,
  handleModal: PropTypes.func,
  tabs: PropTypes.array,
  handleSubmitPG: PropTypes.func,
  handleSubmitPF: PropTypes.func,
  fields: PropTypes.object,
  checkNazione: PropTypes.func,
  checkProvincia: PropTypes.func,
  checkNazionalitaUlterioriDati: PropTypes.func,
  computeCFstate: PropTypes.bool,
  handleComputeCFstate: PropTypes.func,
  handleGetCF: PropTypes.func,
  updateDominio: PropTypes.func,
  verified: PropTypes.bool,
  verifiedPG: PropTypes.bool,
  handleVerified: PropTypes.func,
  handleVerifiedPG: PropTypes.func,
  imageupload: PropTypes.string,
  openCamera: PropTypes.func,
  innerRef: PropTypes.object,
  dirty: PropTypes.bool,
  invalid: PropTypes.bool,
  legalPersonCreatePF: PropTypes.func,
  idPhysicalPersonReference: PropTypes.object,
  legalPersonCreatePFRequest: PropTypes.object,
  idLegalPerson: PropTypes.any
}

export default ProspectGiuridiche
