import React from 'react'
import PropTypes from 'prop-types'

export class ModalePopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.handlePrimaryClick = this.handlePrimaryClick.bind(this)
    this.handleSecondaryClick = this.handleSecondaryClick.bind(this)
  }

  handlePrimaryClick(e) {
    if (this.props.primaryAction) {
      this.props.primaryAction()
    }
  }

  handleSecondaryClick(e) {
    if (this.props.secondaryAction) {
      this.props.secondaryAction()
    }
  }

  render() {
    if (this.props.visible === false) {
      return null
    }

    return (
      <div className="modal-background">
        <div className="modal-box-container">
          <div className="modal-box-content">
            <h1 className="modal-title">{this.props.title}</h1>
            <p className="modal-text">{this.props.text}</p>
            {this.props.subText && <p className="modal-text">{this.props.subText}</p>}
          </div>
          <div className="modal-box-actions">
            <button type="button" className="modal-action secondary" onClick={this.handleSecondaryClick}>
              {this.props.secondaryActionLabel}
            </button>
            <button type="button" className="modal-action primary" onClick={this.handlePrimaryClick}>
              {this.props.primaryActionLabel}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

ModalePopup.propTypes = {
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  secondaryActionLabel: PropTypes.string,
  primaryActionLabel: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  subText: PropTypes.string,
  visible: PropTypes.bool
}

export default ModalePopup
