import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { Field } from 'redux-form'
import {
  mapDominiToAutocomplete,
  getAutocompleteDefaultOption,
  getDominio,
  getValueDominioFromKey
} from '../../../routes/ConsulenzaBaseContainerLayout/utils'
import { renderAutocomplete } from '../renderFields'
import InputText from '../../Inputs/Text'

class ResidenzaForm extends Component {
  constructor(props) {
    super(props)
    this.state = { waitingCap: false }
  }

  componentDidUpdate(prevProps) {
    const {
      fields: { CAP },
      change,
      dominioRequest,
      domini
    } = this.props
    if (prevProps.dominioRequest.fetching && !dominioRequest.fetching && this.state.waitingCap) {
      const capDom = domini.listaDomini.find(d => d.idDominio === CAP.dominio)
      if (capDom && Object.keys(capDom.valoriDominio).length === 1) {
        change(CAP.id, Object.keys(capDom.valoriDominio)[0])
      }
      this.setState({ waitingCap: false })
    }
  }

  checkNazione(isItalia) {
    const {
      formValues,
      domini: { listaDomini },
      fields: { NAZIONE }
    } = this.props
    if (isItalia) {
      return formValues[NAZIONE.id] === NAZIONE.italiaId
    }
    return !!getValueDominioFromKey(listaDomini, NAZIONE.dominio, formValues[NAZIONE.id])
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, PROVINCIA.dominio, value)
  }

  checkComune(value) {
    const {
      domini: { listaDomini },
      fields: { COMUNE }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, COMUNE.dominio, value)
  }

  checkCap() {
    const {
      domini: { listaDomini },
      fields: { PROVINCIA, COMUNE, CAP },
      formValues
    } = this.props
    return (
      !this.checkNazione(true) ||
      !this.checkProvincia(formValues[PROVINCIA.id]) ||
      !this.checkComune(formValues[COMUNE.id]) ||
      Object.keys((listaDomini.find(d => d.idDominio === CAP.dominio) || {}).valoriDominio || {}).length === 1
    )
  }

  render() {
    const {
      domini,
      updateDominio,
      formValues,
      formErrors = {},
      nameDisabled = false,
      fields: { NAZIONE, PROVINCIA, COMUNE, INDIRIZZO, CAP, COMUNE_ESTERO, CIVICO, NOME, COGNOME },
      change,
      touch
    } = this.props
    return (
      <Fragment>
        {NOME && COGNOME && (
          <Row>
            <Field
              name={NOME.id}
              s={6}
              disabled={nameDisabled}
              maxLength={20}
              label={NOME.label}
              error={formErrors[NOME.id]}
              value={formValues[NOME.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
            <Field
              name={COGNOME.id}
              s={6}
              disabled={nameDisabled}
              maxLength={20}
              label={COGNOME.label}
              error={formErrors[COGNOME.id]}
              value={formValues[COGNOME.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          </Row>
        )}
        <Row>
          <Field
            name={INDIRIZZO.id}
            s={6}
            maxLength={35}
            label={INDIRIZZO.label}
            error={formErrors[INDIRIZZO.id]}
            value={formValues[INDIRIZZO.id]}
            change={change}
            touch={touch}
            component={InputText}
          />
          {CIVICO && (
            <Field
              name={CIVICO.id}
              s={6}
              maxLength={40}
              label={CIVICO.label}
              error={formErrors[CIVICO.id]}
              value={formValues[CIVICO.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          )}
        </Row>
        <Row>
          <Col s={6}>
            <Field
              name={NAZIONE.id}
              s={12}
              label={NAZIONE.label}
              threshold={0}
              defaultValue={getAutocompleteDefaultOption(domini.listaDomini, NAZIONE.dominio, formValues[NAZIONE.id])}
              options={mapDominiToAutocomplete(getDominio(domini, NAZIONE.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[PROVINCIA.id]) {
                  change(PROVINCIA.id, '')
                }
                if (formValues[COMUNE.id]) {
                  change(COMUNE.id, '')
                }
                if (formValues[COMUNE_ESTERO.id]) {
                  change(COMUNE_ESTERO.id, '')
                }
                if (formValues[CAP.id]) {
                  change(CAP.id, '')
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
          <Col s={6}>
            <Field
              name={PROVINCIA.id}
              s={12}
              label={PROVINCIA.label}
              threshold={0}
              disabled={!this.checkNazione(true)}
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                PROVINCIA.dominio,
                formValues[PROVINCIA.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[COMUNE.id]) {
                  change(COMUNE.id, '')
                }
                if (formValues[CAP.id]) {
                  change(CAP.id, '')
                }
                if (this.checkProvincia(value)) {
                  updateDominio(COMUNE.dominio, value)
                }
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
        <Row>
          {this.checkNazione(true) && (
            <Col s={6}>
              <Field
                name={COMUNE.id}
                s={12}
                threshold={0}
                label={COMUNE.label}
                disabled={!this.checkProvincia(formValues[PROVINCIA.id])}
                defaultValue={getAutocompleteDefaultOption(domini.listaDomini, COMUNE.dominio, formValues[COMUNE.id])}
                options={formValues[PROVINCIA.id] ? mapDominiToAutocomplete(getDominio(domini, COMUNE.dominio)) : []}
                handleChange={(field, value) => {
                  change(field, value)
                  if (formValues[CAP.id]) {
                    change(CAP.id, '')
                  }
                  if (this.checkComune(value)) {
                    this.setState({ waitingCap: true })
                    updateDominio(CAP.dominio, value)
                  }
                }}
                component={renderAutocomplete}
              />
            </Col>
          )}
          {!this.checkNazione(true) && (
            <Field
              name={COMUNE_ESTERO.id}
              s={6}
              maxLength={50}
              disabled={!this.checkNazione()}
              label={COMUNE_ESTERO.label}
              error={formErrors[COMUNE_ESTERO.id]}
              value={formValues[COMUNE_ESTERO.id]}
              change={change}
              touch={touch}
              component={InputText}
            />
          )}

          <Col s={3} offset="s3">
            <Field
              name={CAP.id}
              s={12}
              threshold={0}
              label={CAP.label}
              disabled={this.checkCap()}
              defaultValue={getAutocompleteDefaultOption(domini.listaDomini, CAP.dominio, formValues[CAP.id])}
              options={formValues[COMUNE.id] ? mapDominiToAutocomplete(getDominio(domini, CAP.dominio)) : []}
              handleChange={change}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
      </Fragment>
    )
  }
}
export default ResidenzaForm

ResidenzaForm.propTypes = {
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  nameDisabled: PropTypes.bool,
  domini: PropTypes.object,
  change: PropTypes.func,
  touch: PropTypes.func,
  updateDominio: PropTypes.func,
  dominioRequest: PropTypes.object,
  fields: PropTypes.shape({
    NOME: PropTypes.object,
    COGNOME: PropTypes.object,
    INDIRIZZO: PropTypes.object.isRequired,
    NAZIONE: PropTypes.object.isRequired,
    PROVINCIA: PropTypes.object.isRequired,
    COMUNE: PropTypes.object.isRequired,
    CAP: PropTypes.object.isRequired
  })
}
