import React, { Component } from 'react'
import PropTypes from 'prop-types'

class InputText extends Component {
  constructor(props) {
    super(props)
    this.state = { value: props.input.value || '' }

    this.onChange = this.onChange.bind(this)
    this.onBlur = this.onBlur.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState({ value: this.props.input.value })
    }
  }

  onChange(ev) {
    const { formatter } = this.props
    let value = formatter ? formatter(ev.target.value) : ev.target.value
    this.setState({ value })
  }

  onBlur() {
    const {
      change,
      input: { name },
      touch
    } = this.props
    touch(name)
    change(name, this.state.value)
  }

  render() {
    const {
      s,
      m,
      l,
      input: { name, value },
      meta: { touched, error: errorMeta },
      error = '',
      className = '',
      label,
      placeholder,
      disabled,
      type = 'text',
      errorBehaviorStandard = true,
      maxLength = 250
    } = this.props
    let divClassName = ''
    let labelClassName = ''
    if (s) divClassName += ` s${s}`
    if (m) divClassName += ` m${m}`
    if (l) divClassName += ` l${l}`

    let errorMessage = error || errorMeta || ''
    let showError = false
    if (errorMessage) {
      if (errorBehaviorStandard) {
        if (touched) {
          divClassName += ' invalid'
          showError = true
        }
      } else {
        divClassName += ' invalid'
        showError = true
      }
    }
    if (value || value) labelClassName += ' label-active'
    if (!value && !value) labelClassName += ' label-force-inactive'

    return (
      <div className={`input-field input-text-field col${divClassName}${labelClassName}`}>
        <input
          id={`input_text_${name}`}
          type={type}
          className={className}
          placeholder={placeholder}
          value={this.state.value}
          maxLength={maxLength}
          onChange={this.onChange}
          onBlur={this.onBlur}
          disabled={disabled}
        />
        <label
          htmlFor={`input_text_${name}`}
          error={showError ? errorMessage : ''}
          className={showError ? 'active' : ''}>
          {label}
        </label>
      </div>
    )
  }
}
export default InputText

InputText.propTypes = {
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  label: PropTypes.string,
  change: PropTypes.func.isRequired,
  touch: PropTypes.func.isRequired,
  errorBehaviorStandard: PropTypes.bool,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  s: PropTypes.number,
  m: PropTypes.number,
  l: PropTypes.number,
  input: PropTypes.object,
  meta: PropTypes.object,
  formatter: PropTypes.func
}

InputText.defaultProps = {
  input: {
    name: 'defaultName',
    value: ''
  },
  meta: {
    touched: false
  },
  errorBehaviorStandard: true,
  touch: () => true
}
