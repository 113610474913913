import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, isDirty, Field } from 'redux-form'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../../components/Collapsible'
import AccordionHeader from '../../../../../components/AccordionHeader'
import ModalitaPagamento from '../common/ModalitaPagamento'
import InputPlusMinus from '../../../../../components/InputPlusMinus'
import SpeseButton from '../common/SpeseButton'
import TransferTitoli from '../common/TransferTitoli'
import { APERTURA_RTO_IMI } from '../../../../../store/modules/forms/constants'
import allFields from '../../../fields'
import { getDominio, loadDefaultFields, mapDominiToOptions } from '../../../../ConsulenzaBaseContainerLayout/utils'
import { createPayload, loadServiceData, getIdServizio } from '../../utils'
import dataSB from '../../../../../static-data/data-servizio-complesso.json'
import ButtonSubmit from '../../../../../components/ButtonSubmit'
import TipologiaContoForm from '../common/TipologiaContoForm'
import { renderSelect } from '../../../../../components/Forms/renderFields'

const fields = allFields[APERTURA_RTO_IMI]
const defaultFields = loadDefaultFields(fields)
const selector = formValueSelector(APERTURA_RTO_IMI)
const idServizio = getIdServizio(APERTURA_RTO_IMI)

const validate = () => ({})

class RtoImi extends Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, expanded: false }
    this.isDisabled = this.isDisabled.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && !this.props.apiError && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(APERTURA_RTO_IMI)
      this.setState({ saved: true })
    }
  }

  isDisabled() {
    const { FLAG_BONIFICO } = fields
    const { formValues } = this.props
    return !formValues[FLAG_BONIFICO.id]
  }

  submit(_values) {
    const { serviceData, onSave } = this.props

    const {
      COMM_CLUSTER_STANDARD_OBBLIGAZIONARI,
      COMM_CLUSTER_STANDARD_AZIONI_ITA,
      COMM_CLUSTER_STANDARD_AZIONI_INTER,
      COMM_CLUSTER_STANDARD_ALTRO,
      COMM_CLUSTER1_OBBLIGAZIONARI,
      COMM_CLUSTER1_AZIONI_ITA,
      COMM_CLUSTER1_AZIONI_INTER,
      COMM_CLUSTER1_ALTRO,
      COMM_CLUSTER2_OBBLIGAZIONARI,
      COMM_CLUSTER2_AZIONI_ITA,
      COMM_CLUSTER2_AZIONI_INTER,
      COMM_CLUSTER2_ALTRO,
      COMM_CLUSTER3_OBBLIGAZIONARI,
      COMM_CLUSTER3_AZIONI_ITA,
      COMM_CLUSTER3_AZIONI_INTER,
      COMM_CLUSTER3_ALTRO,
      COMM_CLUSTER4_OBBLIGAZIONARI,
      COMM_CLUSTER4_AZIONI_ITA,
      COMM_CLUSTER4_AZIONI_INTER,
      COMM_CLUSTER4_ALTRO
    } = fields
    const values = { ..._values }
    Object.keys(values).forEach(k => {
      if (k.startsWith('percComm')) delete values[k]
    })
    switch (values.idCluster) {
      case '000':
        values[COMM_CLUSTER_STANDARD_OBBLIGAZIONARI.id] = _values[COMM_CLUSTER_STANDARD_OBBLIGAZIONARI.id]
        values[COMM_CLUSTER_STANDARD_AZIONI_ITA.id] = _values[COMM_CLUSTER_STANDARD_AZIONI_ITA.id]
        values[COMM_CLUSTER_STANDARD_AZIONI_INTER.id] = _values[COMM_CLUSTER_STANDARD_AZIONI_INTER.id]
        values[COMM_CLUSTER_STANDARD_ALTRO.id] = _values[COMM_CLUSTER_STANDARD_ALTRO.id]
        break
      case '001':
        values[COMM_CLUSTER1_OBBLIGAZIONARI.id] = _values[COMM_CLUSTER1_OBBLIGAZIONARI.id]
        values[COMM_CLUSTER1_AZIONI_ITA.id] = _values[COMM_CLUSTER1_AZIONI_ITA.id]
        values[COMM_CLUSTER1_AZIONI_INTER.id] = _values[COMM_CLUSTER1_AZIONI_INTER.id]
        values[COMM_CLUSTER1_ALTRO.id] = _values[COMM_CLUSTER1_ALTRO.id]
        break
      case '002':
        values[COMM_CLUSTER2_OBBLIGAZIONARI.id] = _values[COMM_CLUSTER2_OBBLIGAZIONARI.id]
        values[COMM_CLUSTER2_AZIONI_ITA.id] = _values[COMM_CLUSTER2_AZIONI_ITA.id]
        values[COMM_CLUSTER2_AZIONI_INTER.id] = _values[COMM_CLUSTER2_AZIONI_INTER.id]
        values[COMM_CLUSTER2_ALTRO.id] = _values[COMM_CLUSTER2_ALTRO.id]
        break
      case '003':
        values[COMM_CLUSTER3_OBBLIGAZIONARI.id] = _values[COMM_CLUSTER3_OBBLIGAZIONARI.id]
        values[COMM_CLUSTER3_AZIONI_ITA.id] = _values[COMM_CLUSTER3_AZIONI_ITA.id]
        values[COMM_CLUSTER3_AZIONI_INTER.id] = _values[COMM_CLUSTER3_AZIONI_INTER.id]
        values[COMM_CLUSTER3_ALTRO.id] = _values[COMM_CLUSTER3_ALTRO.id]
        break
      case '004':
        values[COMM_CLUSTER4_OBBLIGAZIONARI.id] = _values[COMM_CLUSTER4_OBBLIGAZIONARI.id]
        values[COMM_CLUSTER4_AZIONI_ITA.id] = _values[COMM_CLUSTER4_AZIONI_ITA.id]
        values[COMM_CLUSTER4_AZIONI_INTER.id] = _values[COMM_CLUSTER4_AZIONI_INTER.id]
        values[COMM_CLUSTER4_ALTRO.id] = _values[COMM_CLUSTER4_ALTRO.id]
        break
    }
    let obTosend = createPayload(fields, values, serviceData, this.state.expanded)
    if (obTosend.idTipologiaConto === null) {
      obTosend.idTipologiaConto = '1'
    }
    onSave(serviceData.idServizio, obTosend)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      getStatus,
      isDirty,
      invalid,
      domini,
      formValues,
      percentualiCommissionali,
      change,
      isFetching,
      serviceInformation
    } = this.props
    const percComms = percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    let obb
    let azioniIta
    let azioniInter
    let altro
    switch (formValues.idCluster) {
      case '001':
        obb = fields.COMM_CLUSTER1_OBBLIGAZIONARI
        azioniIta = fields.COMM_CLUSTER1_AZIONI_ITA
        azioniInter = fields.COMM_CLUSTER1_AZIONI_INTER
        altro = fields.COMM_CLUSTER1_ALTRO
        break
      case '002':
        obb = fields.COMM_CLUSTER2_OBBLIGAZIONARI
        azioniIta = fields.COMM_CLUSTER2_AZIONI_ITA
        azioniInter = fields.COMM_CLUSTER2_AZIONI_INTER
        altro = fields.COMM_CLUSTER2_ALTRO
        break
      case '003':
        obb = fields.COMM_CLUSTER3_OBBLIGAZIONARI
        azioniIta = fields.COMM_CLUSTER3_AZIONI_ITA
        azioniInter = fields.COMM_CLUSTER3_AZIONI_INTER
        altro = fields.COMM_CLUSTER3_ALTRO
        break
      case '004':
        obb = fields.COMM_CLUSTER4_OBBLIGAZIONARI
        azioniIta = fields.COMM_CLUSTER4_AZIONI_ITA
        azioniInter = fields.COMM_CLUSTER4_AZIONI_INTER
        altro = fields.COMM_CLUSTER4_ALTRO
        break
      case '005':
        obb = fields.COMM_CLUSTER_STANDARD_OBBLIGAZIONARI
        azioniIta = fields.COMM_CLUSTER_STANDARD_AZIONI_ITA
        azioniInter = fields.COMM_CLUSTER_STANDARD_AZIONI_INTER
        altro = fields.COMM_CLUSTER_STANDARD_ALTRO
        break
      default:
        obb = fields.COMM_CLUSTER_STANDARD_OBBLIGAZIONARI
        azioniIta = fields.COMM_CLUSTER_STANDARD_AZIONI_ITA
        azioniInter = fields.COMM_CLUSTER_STANDARD_AZIONI_INTER
        altro = fields.COMM_CLUSTER_STANDARD_ALTRO
        break
    }

    const percCommClusterSelected = [
      { value: percComms.find(p => p.codiceCommissione === obb.codiceCommissione), form: obb },
      { value: percComms.find(p => p.codiceCommissione === azioniIta.codiceCommissione), form: azioniIta },
      { value: percComms.find(p => p.codiceCommissione === azioniInter.codiceCommissione), form: azioniInter },
      { value: percComms.find(p => p.codiceCommissione === altro.codiceCommissione), form: altro }
    ]

    return (
      <CollapsibleItem
        itemKey={APERTURA_RTO_IMI}
        header={serviceInformation[0].descTipoServizio}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, getStatus(true))}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalitaPagamento
            fields={fields}
            domini={domini}
            formValues={formValues}
            change={change}
            origineRequired={!this.isDisabled()}
            importoRequired={!this.isDisabled()}
            isDisabled={this.isDisabled}
          />
          <TransferTitoli fields={fields} label={dataSB.configurazione.mezzi_pagamento.transfer_titoli_imi} />
          <Row className="m-top10">
            <Col s={12} className="spese-button">
              <SpeseButton
                label={'CLUSTER CONDIZIONI ECONOMICHE'}
                expanded={this.state.expanded}
                handler={() => this.setState({ expanded: !this.state.expanded })}
              />
            </Col>
          </Row>
          {this.state.expanded && (
            <React.Fragment>
              <Row className="m-top10">
                <Col s={12}>
                  <Field
                    name={fields.CLUSTER.id}
                    size={6}
                    label={fields.CLUSTER.label}
                    options={mapDominiToOptions(getDominio(domini, fields.CLUSTER.dominio), true)}
                    component={renderSelect}
                  />
                </Col>
              </Row>
              <Row>
                {percCommClusterSelected.map(p => (
                  <Col s={6} key={p.form.id}>
                    <InputPlusMinus
                      label={p.form.label}
                      value={formValues[p.form.id]}
                      stepValue={p.value.percentualeIntervallo}
                      minValue={p.value.percentualeMinima || p.value.percentualeDefault}
                      maxValue={p.value.percentualeMassima || p.value.percentualeDefault}
                      onChange={value => change(p.form.id, value)}
                    />
                  </Col>
                ))}
              </Row>
            </React.Fragment>
          )}
          <TipologiaContoForm fields={fields} tipoServizio="IMI" formValues={formValues} {...this.props} isDisabled />
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching} />
        </form>
      </CollapsibleItem>
    )
  }
}

RtoImi.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  formValues: PropTypes.object,
  domini: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  serviceData: PropTypes.object,
  percentualiCommissionali: PropTypes.array,
  getStatus: PropTypes.func,
  serviceInformation: PropTypes.array
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData.servizio_7,
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    )
  },
  formValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData.servizio_7,
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    ),
    ...selector(state, ...Object.keys(defaultFields).reduce((res, cur) => [...res, cur], []))
  },
  isDirty: isDirty(APERTURA_RTO_IMI)(state),
  isFetching: state.services.postServizioInfoRequest.fetching,
  apiError: state.services.postServizioInfoRequest.error,
  serviceInformation: state.pratica.pratica.servizi.filter(item => item.idTipoServizio === 7)
}))(
  reduxForm({
    form: APERTURA_RTO_IMI,
    validate
  })(RtoImi)
)
