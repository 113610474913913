import * as actionTypes from './constants'

const apiRequests = {
  currentForm: null,
  dataVerificaClienti: {
    pepInfo: null
  },
  getAnagraficaPFRequest: {
    fetching: false,
    error: null
  },
  getAnagraficaPGRequest: {
    fetching: false,
    error: null
  },
  getDocIDPGRequest: {
    fetching: false,
    error: null
  },
  postDocPGIDPGRequest: {
    fetching: false,
    error: null
  },
  postDocIDPGRequest: {
    fetching: false,
    error: null
  },
  getDocIDPFRequest: {
    fetching: false,
    error: null
  },
  postCFPFRequest: {
    fetching: false,
    error: null
  },
  getFatcaPGRequest: {
    fetching: false,
    error: null
  },
  postFatcaPGReques: {
    fetching: false,
    error: null
  },
  getDataVerificaClientiRequest: {
    fetching: false,
    error: null
  },
  postDataVerificaClientiRequest: {
    fetching: false,
    error: null
  },
  getPFAnagraficaRequest: {
    fetching: false,
    error: null
  },
  postPFAnagraficaRequest: {
    fetching: false,
    error: null
  },
  getDataRegistryPGExtRequest: {
    fetching: false,
    error: null
  }
}

const initialState = {
  anagraficaPF: {},
  anagraficaPG: {},
  docIDPF: {},
  docIDPG: {}
}

export default (state = { ...initialState, ...apiRequests }, action) => {
  switch (action.type) {
    /** INIZIO POST DOC ID PER PG */

    case actionTypes.POST_DOC_ID_PERSONE_G_START: {
      return {
        ...state,
        postDocPGIDPGRequest: {
          fetching: true,
          error: null
        }
      }
    }
    case actionTypes.POST_DOC_ID_PERSONE_G_SUCCESS: {
      return {
        ...state,
        postDocPGIDPGRequest: {
          fetching: false,
          error: null
        },
        dataGathering: action.requestApi
      }
    }
    case actionTypes.POST_DOC_ID_PERSONE_G_ERROR: {
      return {
        ...state,
        postDocPGIDPGRequest: {
          fetching: false,
          error: action.error
        }
      }
    }

    case actionTypes.POST_DOC_ID_PG_START: {
      return {
        ...state,
        postDocIDPGRequest: {
          fetching: true,
          error: null
        }
      }
    }
    case actionTypes.POST_DOC_ID_PG_SUCCESS: {
      return {
        ...state,
        postDocIDPGRequest: {
          fetching: false,
          error: null
        },
        dataGathering: action.requestApi
      }
    }
    case actionTypes.POST_DOC_ID_PG_ERROR: {
      return {
        ...state,
        postDocIDPGRequest: {
          fetching: false,
          error: action.error
        }
      }
    }
    /** FINE POST DOC ID PER PG */
    /** INIZIO GET ANAGRAFICA PER PF */
    case actionTypes.GET_ANAGRAFICA_PF_START:
      return {
        ...state,
        getPFAnagraficaRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_ANAGRAFICA_PF_SUCCESS:
      return {
        ...state,
        anagraficaPF: action.responseApi,
        getPFAnagraficaRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_ANAGRAFICA_PF_ERROR:
      return {
        ...state,
        getPFAnagraficaRequest: {
          fetching: false,
          error: action.error
        }
      }

    case actionTypes.POST_ANAGRAFICA_PF_START:
      return {
        ...state,
        postPFAnagraficaRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.POST_ANAGRAFICA_PF_SUCCESS:
      return {
        ...state,
        postPFAnagraficaRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.POST_ANAGRAFICA_PF_ERROR:
      return {
        ...state,
        postPFAnagraficaRequest: {
          fetching: false,
          error: action.error
        }
      }
    /** FINE GET ANAGRAFICA PER PF */
    /** INIZIO GET DOC ID PER PF */
    case actionTypes.GET_DOC_ID_PF_START:
      return {
        ...state,
        getDocIDPFRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DOC_ID_PF_SUCCESS:
      return {
        ...state,
        docIDPF: action.responseApi,
        getDocIDPFRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DOC_ID_PF_ERROR:
      return {
        ...state,
        getDocIDPFRequest: {
          fetching: false,
          error: action.error
        }
      }

    /** FINE GET DOC_ID PER PF */
    case actionTypes.SET_CURRENT_FORM:
      return {
        ...state,
        currentForm: action.currentForm
      }
    /** INIZIO GET DOC ID PER PG */
    case actionTypes.GET_DOC_ID_PG_START:
      return {
        ...state,
        getDocIDPGRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DOC_ID_PG_SUCCESS:
      return {
        ...state,
        docIDPG: action.responseApi,
        getDocIDPGRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DOC_ID_PG_ERROR:
      return {
        ...state,
        getDocIDPGRequest: {
          fetching: false,
          error: action.error
        }
      }
    /** FINE GET DOC ID PER PG */
    /** INIZIO POST CF PER PF */
    case actionTypes.POST_CF_PF_START:
      return {
        ...state,
        postCFPFRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.POST_CF_PF_SUCCESS:
      return {
        ...state,
        docIDPG: action.responseApi,
        postCFPFRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.POST_CF_PF_ERROR:
      return {
        ...state,
        postCFPFRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.GET_DATA_VERIFICA_CLIENTI_START:
      return {
        ...state,
        getDataVerificaClientiRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DATA_VERIFICA_CLIENTI_SUCCESS:
      return {
        ...state,
        dataVerificaClienti: action.dataVerificaClienti,
        getDataVerificaClientiRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DATA_VERIFICA_CLIENTI_ERROR:
      return {
        ...state,
        getDataVerificaClientiRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.POST_DATA_VERIFICA_CLIENTI_START:
      return {
        ...state,
        postDataVerificaClientiRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.POST_DATA_VERIFICA_CLIENTI_SUCCESS:
      return {
        ...state,
        postDataVerificaClientiRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.POST_DATA_VERIFICA_CLIENTI_ERROR:
      if (action.error.data.code === 'CONSISTENCY_001') {
        return {
          ...state,
          postDataVerificaClientiRequest: {
            fetching: false,
            error: null
          }
        }
      } else {
        return {
          ...state,
          postDataVerificaClientiRequest: {
            fetching: false,
            error: action.error
          }
        }
      }
    /** FINE POST CF PER PF */

    /** FATCA PG */
    case actionTypes.POST_FATCA_START: {
      return {
        ...state,
        postFatcaPGReques: {
          fetching: true,
          error: null
        }
      }
    }
    case actionTypes.POST_FATCA_SUCCESS: {
      return {
        ...state,
        postFatcaPGReques: {
          fetching: false,
          error: null
        }
      }
    }
    case actionTypes.POST_FATCA_ERROR: {
      return {
        ...state,
        postFatcaPGReques: {
          fetching: false,
          error: action.error
        }
      }
    }

    case actionTypes.GET_FATCA_START: {
      return {
        ...state,
        getFatcaPGRequest: {
          fetching: true,
          error: null
        }
      }
    }
    case actionTypes.GET_FATCA_SUCCESS: {
      return {
        ...state,
        getFatcaPGRequest: {
          fetching: false,
          error: null
        },
        dataFatcaPG: {
          ...action.dataFatcaPG,
          fatca_isIstFinNoUS: String(action.dataFatcaPG.fatca_isIstFinNoUS),
          /* potrebbero avere bisogno del metodo String(), da verificare l'impatto sulla GET */
          crs_defPos_istFinCrsDet_isSenzaUnaPiuContrPers:
            action.dataFatcaPG.crs_defPos_istFinCrsDet_isSenzaUnaPiuContrPers,
          crs_defPos_entitaNoFinDet_passiveNFE_isSenzaUnaPiuContrPers:
            action.dataFatcaPG.crs_defPos_entitaNoFinDet_passiveNFE_isSenzaUnaPiuContrPers,
          contrPers1_isResFiscUS: String(action.dataFatcaPG.contrPers1_isResFiscUS),
          contrPers2_isResFiscUS: String(action.dataFatcaPG.contrPers2_isResFiscUS)
        }
      }
    }
    case actionTypes.GET_FATCA_ERROR: {
      return {
        ...state,
        getFatcaPGRequest: {
          fetching: false,
          error: action.error
        }
      }
    }
    /** FINE FATCA */
    case actionTypes.GET_DATA_REGISTRY_PG_START:
      return {
        ...state,
        getDataRegistryPGExtRequest: {
          fetching: true,
          error: null
        }
      }
    case actionTypes.GET_DATA_REGISTRY_PG_SUCCESS:
      return {
        ...state,
        docIDPG: action.responseApi,
        getDataRegistryPGExtRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.GET_DATA_REGISTRY_PG_ERROR:
      return {
        ...state,
        getDataRegistryPGExtRequest: {
          fetching: false,
          error: action.error
        }
      }
    default:
      return state
  }
}
