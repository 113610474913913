import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AdeguataVerificaComponent from './AdeguataVerificaComponent'
import { getAdegVerTemplate, saveAdegVer, importSurveyExtAV } from '../../store/modules/survey/actions'
import { getPratica } from '../../store/modules/pratica/actions'
import { getPromoterCustomerHimself } from '../../store/modules/customers/actions'
import { downloadPDF } from '../../store/modules/utility/actions'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAdegVerTemplate,
      saveAdegVer,
      getPratica,
      importSurveyExtAV,
      getPromoterCustomerHimself,
      downloadPDF
    },
    dispatch
  )

const mapStateToProps = state => {
  return {
    customer: state.customers.customer,
    template: state.survey.templateAV,
    adegVerSaveRequest: state.survey.adegVerSaveRequest,
    intestatari: state.pratica.pratica.intestatari,
    servizi: state.pratica.pratica.servizi,
    praticaRequest: state.pratica.praticaRequest,
    idPratica: state.pratica.idPratica,
    codicePromoter: state.pratica.pratica.codicePromoter,
    isPromoterCustomerHimself: state.customers.isPromoterCustomerHimself,
    getPromoterCustomerHimselfRequest: state.customers.getPromoterCustomerHimselfRequest,
    downloadZipRequest: state.utility.downloadZipRequest,
    importSurveyExtRequest: state.survey.importSurveyExtRequest,
    amlPreviousForm: state.survey.amlPreviousForm
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdeguataVerificaComponent)
