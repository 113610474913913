import React, { Component } from 'react'
import { Field } from 'redux-form'
import { CLIENTI_PROFESSIONALI as CLIENTI_PROFESSIONALI_FORM_NAME } from '../../../../store/modules/forms/constants'
import { CollapsibleItem } from '../../../../components/Collapsible/index'
import AccordionHeader from '../../../../components/AccordionHeader'
import { PropTypes } from 'prop-types'
import { Row, Col, Icon, Button } from 'react-materialize'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import ModalWarn from '../../../../components/Modals/ModalWarn'
import { renderCheckboxRadio, renderHtmlInput } from '../../../../components/Forms/renderFields'
import { checkStatusService } from '../../utils'

class ClientiProfessionali extends Component {
  constructor(props) {
    super(props)

    this.state = {
      saved: false,
      alertPop: false,
      professionalSubjectType: '',
      isCompleated: false
    }

    this.submit = this.submit.bind(this)
    this.flagProfessionalSubjectHandler = this.flagProfessionalSubjectHandler.bind(this)
    this.mifidModalHandle = this.mifidModalHandle.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { submitSucceeded, apiError, isFetching, formValues, initialize, resetForm } = this.props
    if (prevProps.isFetching && !isFetching && submitSucceeded && !apiError) {
      initialize(formValues)
      resetForm(CLIENTI_PROFESSIONALI_FORM_NAME)

      const {
        summaryData: { sezioni, sections }
      } = this.props
      let sez = sezioni !== undefined ? sezioni : sections
      if (sez[2].stato !== 'NUOVA' && this.props.isProspectPersonaGiuridica) {
        this.mifidModalHandle()
      }
    }
    if (
      prevProps.postProfessionaleRequest &&
      this.props.postProfessionaleRequest &&
      prevProps.postProfessionaleRequest.fetching &&
      !this.props.postProfessionaleRequest.fetching &&
      !this.props.postProfessionaleRequest.error
    ) {
      initialize(formValues)

      if (formValues.professionalClients !== null) {
        this.setState({ isCompleated: true })
      }

      resetForm(CLIENTI_PROFESSIONALI_FORM_NAME)

      const {
        summaryData: { sezioni, sections }
      } = this.props
      let sez = sezioni !== undefined ? sezioni : sections
      if (sez[2].stato !== 'NUOVA' && this.props.isProspectPersonaGiuridica) {
        this.mifidModalHandle()
      }
    }
  }

  mifidModalHandle() {
    this.setState({ alertPop: !this.state.alertPop })
  }

  submit() {
    const {
      saveProfessionalSubject,
      checkProfessionale,
      postProfessionale,
      idPhysicalPersonReference,
      formValues,
      fields: { CLIENTI_PROFESSIONALI_DI_DIRITTO },
      customer,
      summaryData: { statusEndPoint },
      fields: { CLIENTI_PROFESSIONALI_SU_RICHIESTA }
    } = this.props
    if (!this.props.isProspectPersonaGiuridica) {
      this.setState({ saved: true })
      checkProfessionale({
        idCustomer: customer.idCliente,
        checkProfessionale: formValues[CLIENTI_PROFESSIONALI_SU_RICHIESTA.id]
      })
      saveProfessionalSubject({
        idCliente: customer.idCliente,
        idPratica: customer.idIncarico,
        isProfessionalSubjectDone: !!formValues[CLIENTI_PROFESSIONALI_SU_RICHIESTA.id],
        professionalSubjectType: formValues[CLIENTI_PROFESSIONALI_SU_RICHIESTA.id]
          ? this.state.professionalSubjectType
          : null
      })
    } else {
      if (checkStatusService(statusEndPoint, 'mifid') && !this.state.alertPop) {
        this.mifidModalHandle()
      }
      if (this.state.alertPop || !checkStatusService(statusEndPoint, 'mifid')) {
        // postProfessionale(idCliente, idIncarico, payload)

        postProfessionale(customer.idCliente, customer.idIncarico, {
          idPhysicalPerson: idPhysicalPersonReference,
          professionalSubjectType: formValues[CLIENTI_PROFESSIONALI_DI_DIRITTO.id],
          idPratica: customer.idIncarico
        })
        this.setState({ saved: true })
      }
    }
  }

  flagProfessionalSubjectHandler(type) {
    this.setState({ professionalSubjectType: type })
  }

  render() {
    const {
      isDirty,
      handleSubmit,
      fields: { CLIENTI_PROFESSIONALI_SU_RICHIESTA, CLIENTI_PROFESSIONALI_DI_DIRITTO },
      change,
      status,
      formValues,
      touch
    } = this.props

    return (
      <CollapsibleItem
        header={
          this.props.url === `/consulenza-base/${this.props.idPratica}/acquisizione-documenti`
            ? 'Clienti Professionali'
            : 'Clienti professionali persona giuridica'
        }
        itemKey={CLIENTI_PROFESSIONALI_FORM_NAME}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, this.state.isCompleated ? 'COMPLETATA' : status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {this.state.alertPop === true && (
            <ModalWarn scssClass={'onboarding-modale-text__blu'} closeModal={this.mifidModalHandle}>
              <Row>
                <Col s={12} className="center-align" style={{ marginTop: '-20px' }}>
                  <p className="onboarding-modale-content__title center-align onboarding-modale-text__blu">
                    Hai già compilato il questionario MIFID
                  </p>
                </Col>
                <Col s={12} className="center-align">
                  <Icon className="onboarding-modale-text__blu" large>
                    warning
                  </Icon>
                </Col>
                <Col s={12}>
                  <p className="onboarding-modale-content__subtitle center-align onboarding-modale-text__blu">
                    La scelta comporterà la ricompilazione del questionario MIFID
                  </p>
                </Col>
                <Row>
                  <Col className="s6 left-align" style={{ marginTop: '30px' }}>
                    <Button
                      className="custom-btn primary-reverse transparent"
                      onClick={e => {
                        e.preventDefault()
                        this.mifidModalHandle()
                      }}>
                      ANNULLA
                    </Button>
                  </Col>
                  <Col className="s6 right-align" style={{ marginTop: '30px' }}>
                    <Button className="btn custom-btn primary" type="submit">
                      PROSEGUI
                    </Button>
                  </Col>
                </Row>
              </Row>
            </ModalWarn>
          )}
          <Row id={'checkboxRow'}>
            <Col s={12}>Il Cliente è un Cliente professionale:</Col>
          </Row>
          <Row className="m-top20">
            {!this.props.isProspectPersonaGiuridica ? (
              <Col s={12}>
                <Field
                  name={CLIENTI_PROFESSIONALI_SU_RICHIESTA.id}
                  checked={formValues[CLIENTI_PROFESSIONALI_SU_RICHIESTA.id]}
                  value={formValues[CLIENTI_PROFESSIONALI_SU_RICHIESTA.id]}
                  label={CLIENTI_PROFESSIONALI_SU_RICHIESTA.label}
                  onClick={() => this.flagProfessionalSubjectHandler('TRAMITE_RICHIESTA')}
                  change={change}
                  touch={touch}
                  type="checkbox"
                  component={renderCheckboxRadio}
                />
              </Col>
            ) : (
              <div>
                <Col s={2}>
                  <div key={6}>
                    <Field
                      name={CLIENTI_PROFESSIONALI_DI_DIRITTO.id}
                      className="with-gap"
                      type="radio"
                      id={'radio-2'}
                      value={'NO_PROFESSIONALE'}
                      component={renderHtmlInput}
                    />
                    <label className="whitespaceNo" htmlFor="radio-2">
                      No{' '}
                    </label>
                  </div>
                </Col>
                <Col s={2}>
                  <div key={6}>
                    <Field
                      name={CLIENTI_PROFESSIONALI_DI_DIRITTO.id}
                      className="with-gap"
                      type="radio"
                      id={'radio-0'}
                      value={'DI_DIRITTO'}
                      component={renderHtmlInput}
                    />
                    <label className="whitespaceNo" htmlFor="radio-0">
                      Sì, di diritto{' '}
                    </label>
                  </div>
                </Col>
                <Col s={2}>
                  <div key={6}>
                    <Field
                      name={CLIENTI_PROFESSIONALI_DI_DIRITTO.id}
                      className="with-gap"
                      type="radio"
                      id={'radio-1'}
                      value={'TRAMITE_RICHIESTA'}
                      component={renderHtmlInput}
                    />
                    <label className="whitespaceNo" htmlFor="radio-1">
                      Sì, su richiesta{' '}
                    </label>
                  </div>
                </Col>
              </div>
            )}
          </Row>

          <ButtonSubmit disabled={!isDirty} />
        </form>
      </CollapsibleItem>
    )
  }
}

ClientiProfessionali.propTypes = {
  handleSubmit: PropTypes.func,
  isDirty: PropTypes.bool,
  fields: PropTypes.object,
  initialize: PropTypes.func,
  isFetching: PropTypes.bool,
  formValues: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  resetForm: PropTypes.func,
  status: PropTypes.any,
  apiError: PropTypes.object,
  saveProfessionalSubject: PropTypes.func,
  customer: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  change: PropTypes.func,
  touch: PropTypes.func,
  // downloadProfessionalSubjectDocument: PropTypes.func,
  // downloadDocument: PropTypes.object,
  url: PropTypes.string,
  idPratica: PropTypes.any,
  // professionalSubject: PropTypes.object,
  checkProfessionale: PropTypes.func,
  summaryData: PropTypes.object,
  isProspectPersonaGiuridica: PropTypes.bool,
  postProfessionale: PropTypes.func,
  idPhysicalPersonReference: PropTypes.any,
  postProfessionaleRequest: PropTypes.object
}

ClientiProfessionali.defaultProps = {
  formValues: {},
  formErrors: {},
  currentInitial: {}
}

export default ClientiProfessionali
