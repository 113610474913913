import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import { Row, Col, Button } from 'react-materialize'
import { CODICE_FISCALE_PF as CODICE_FISCALE_FORM_NAME } from '../../../../store/modules/forms/constants'
import AccordionHeader from '../../../../components/AccordionHeader'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { renderInputImage, renderCheckboxRadio } from '../../../../components/Forms/renderFields'
import InputText from '../../../../components/Inputs/Text'
import { parseDocumentsData } from '../../utils'
import ButtonSubmit from '../../../../components/Buttons/Submit'

class CodiceFiscaleGiuridiche extends Component {
  constructor(props) {
    super(props)
    this.state = { saved: false, CfType: false, notChecked: true }

    this.toggleChange = this.toggleChange.bind(this)
    this.submit = this.submit.bind(this)
    this.switchBlacklist = this.switchBlacklist.bind(this)
    this.flagCfHandler = this.flagCfHandler.bind(this)
  }

  componentDidUpdate(prevProps) {
    const { formValues, isFetching, apiError, initialize, resetForm } = this.props

    if (prevProps.isFetching && !isFetching && !apiError && this.props.currentForm === CODICE_FISCALE_FORM_NAME) {
      initialize(formValues)
      resetForm(CODICE_FISCALE_FORM_NAME)
      this.setState({ saved: true })
    }
  }

  submit(submittedValues) {
    const {
      customer: { flagCustomerIsBlacklist },
      submitHandler,
      currentInitial,
      fields,
      isProspectPersonaGiuridica,
      dataVerificaClienti
    } = this.props
    if (isProspectPersonaGiuridica) {
      const { values } = parseDocumentsData(fields, submittedValues, currentInitial)
      values.isTaxCodeFrontUploaded = submittedValues.isTaxCodeFrontUploaded
      values.isTaxCodeBackUploaded = submittedValues.isTaxCodeBackUploaded
      values.name = dataVerificaClienti.name
      values.surname = dataVerificaClienti.surname
      submitHandler(values, null, CODICE_FISCALE_FORM_NAME)
    } else {
      const { values, listaAllegati } = parseDocumentsData(fields, submittedValues, currentInitial)
      Object.assign(values, { flagCustomerIsBlacklist: !!flagCustomerIsBlacklist })
      submitHandler(values, listaAllegati)
    }
  }

  switchBlacklist(flag) {
    let nameStored
    let surnameStored
    const {
      inBlacklistCF,
      formValues,
      fields: { CODICE_FISCALE },
      customer: { idCliente, nome, cognome },
      isProspectPersonaGiuridica
    } = this.props
    if (isProspectPersonaGiuridica) {
      const {
        dataVerificaClienti: { name, surname }
      } = this.props
      surnameStored = surname
      nameStored = name
    } else {
      surnameStored = cognome
      nameStored = nome
    }
    switch (flag) {
      case '':
      case null:
      case undefined:
        return (
          <Button
            s={2}
            type="button"
            className="right btnMargin"
            onClick={() => {
              inBlacklistCF({
                name: nameStored,
                surname: surnameStored,
                taxCode: formValues[CODICE_FISCALE.id] ? formValues[CODICE_FISCALE.id].toUpperCase() : '',
                idCustomer: idCliente
              })
              this.submit(formValues)
            }}
            disabled={!formValues[CODICE_FISCALE.id] || formValues[CODICE_FISCALE.id].length !== 16}>
            Verifica
          </Button>
        )
      case false:
        return
      // return (
      //   <span className="privacyAccepted blacklist-align">
      //     <i className="icon-check-codice-fiscale-success" />
      //     CF non presente in Blacklist
      //   </span>
      // )
      case true:
        return
      // return (
      //   <span className="privacyAccepted blacklist-align">
      //     <Icon className="blacklist-alert">warning</Icon>
      //     CF presente in Blacklist
      //   </span>
      // )
      default:
        return null
    }
  }
  flagCfHandler(type) {
    this.setState({ CfType: type })
  }

  toggleChange() {
    this.setState({
      notChecked: !this.state.notChecked
    })
  }
  get canProceed() {
    const { notChecked } = this.state
    return !notChecked
  }

  flagCheckModuleHandler() {
    this.setState({ flagModuloFirmato: !this.state.flagModuloFirmato })
  }

  render() {
    const {
      handleSubmit,
      isDirty,
      status,
      submitting,
      anyTouched,
      invalid,
      isFetching,
      change,
      formValues,
      formErrors,
      // isProspectPersonaGiuridica,
      // customer: { codiceFiscale },
      flagCustomerIsBlacklist,
      fields: { CF_FRONTE, CF_FRONTE_PG, CF_FRONTE_THUMB, CF_RETRO, CF_RETRO_PG, CF_RETRO_THUMB, CODICE_FISCALE },
      isEnabledCF,
      touch
    } = this.props

    let bool = location.pathname.indexOf('-PG') >= 0

    return (
      <CollapsibleItem
        header={
          this.props.url === `/consulenza-base/${this.props.idPratica}/acquisizione-documenti`
            ? 'Codice fiscale'
            : 'Codice fiscale persona fisica di riferimento'
        }
        icon="keyboard_arrow_down"
        itemKey={CODICE_FISCALE_FORM_NAME}
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <Row className="valign-wrapper">
            <Col s={this.props.isProspectPersonaGiuridica ? 12 : 10} className="no-padding">
              <Field
                name={CODICE_FISCALE.id}
                s={10}
                change={change}
                touch={touch}
                label={CODICE_FISCALE.label}
                minLength={CODICE_FISCALE.minLength}
                maxLength={CODICE_FISCALE.maxLength}
                error={formErrors[CODICE_FISCALE.id]}
                value={formValues[CODICE_FISCALE.id]}
                disabled={isEnabledCF !== true}
                component={InputText}
                formatter={value => (value ? value.toUpperCase() : '')}
              />
            </Col>
            {!this.props.isProspectPersonaGiuridica && (
              <Col s={2} className="no-padding" style={{ marginBottom: '30px' }}>
                {this.switchBlacklist(flagCustomerIsBlacklist)}
              </Col>
            )}
          </Row>

          {/* {isProspectPersonaGiuridica && (
            <Row className="m-top20">
              <Col s={12}>
                <Field
                  name={CF_CHECK.id}
                  checked={formValues[CF_CHECK.id]}
                  value={formValues[CF_CHECK.id]}
                  label={CF_CHECK.label}
                  onClick={() => this.flagCfHandler(true)}
                  change={change}
                  touch={touch}
                  type="checkbox"
                  component={renderCheckboxRadio}
                />
              </Col>
            </Row>
          )} */}

          {
            /* !isProspectPersonaGiuridica ||
            (isProspectPersonaGiuridica && ( */
            <Row>
              <Col s={4} offset={'s1'}>
                {!bool && (
                  <Field
                    name={CF_FRONTE.id}
                    idImg={CF_FRONTE.id}
                    label={
                      <span>
                        <b>FRONTE</b> documento*
                      </span>
                    }
                    fileIn={formValues[CF_FRONTE.id] === formValues[CODICE_FISCALE.id] ? '' : formValues[CF_FRONTE.id]}
                    onChangeHandler={(file, fileThumb) => {
                      change(CF_FRONTE.id, file)
                      change(CF_FRONTE_THUMB.id, fileThumb)
                    }}
                    component={renderInputImage}
                  />
                )}
                {bool && (
                  <Field
                    name={CF_FRONTE_PG.id}
                    checked={formValues[CF_FRONTE_PG.id]}
                    value={formValues[CF_FRONTE_PG.id]}
                    label="Copia del Codice Fiscale Fronte acquisita"
                    onClick={() => this.flagCheckModuleHandler()}
                    change={change}
                    touch={touch}
                    type="checkbox"
                    component={renderCheckboxRadio}
                  />
                )}
              </Col>
              <Col s={4} offset={'s2'}>
                {!bool && (
                  <Field
                    name={CF_RETRO.id}
                    idImg={CF_RETRO.id}
                    label={
                      <span>
                        <b>RETRO</b> documento*
                      </span>
                    }
                    fileIn={formValues[CF_RETRO.id] === formValues[CODICE_FISCALE.id] ? '' : formValues[CF_RETRO.id]}
                    onChangeHandler={(file, fileThumb) => {
                      change(CF_RETRO.id, file)
                      change(CF_RETRO_THUMB.id, fileThumb)
                    }}
                    component={renderInputImage}
                  />
                )}
                {bool && (
                  <Field
                    name={CF_RETRO_PG.id}
                    checked={formValues[CF_RETRO_PG.id]}
                    value={formValues[CF_RETRO_PG.id]}
                    label="Copia del Codice Fiscale Retro acquisita"
                    onClick={() => this.flagCheckModuleHandler()}
                    change={change}
                    touch={touch}
                    type="checkbox"
                    component={renderCheckboxRadio}
                  />
                )}
              </Col>
            </Row>
            /* )) */
          }
          {bool && <ButtonSubmit disabled={submitting || (!isDirty && !anyTouched) || invalid} />}
          {!bool && (
            <ButtonSubmit
              disabled={
                (flagCustomerIsBlacklist !== true && flagCustomerIsBlacklist !== false) ||
                submitting ||
                (!isDirty && !anyTouched) ||
                invalid ||
                isFetching
              }
            />
          )}
        </form>
      </CollapsibleItem>
    )
  }
}
export default CodiceFiscaleGiuridiche

CodiceFiscaleGiuridiche.propTypes = {
  submitHandler: PropTypes.func,
  handleSubmit: PropTypes.func,
  isDirty: PropTypes.bool,
  isFetching: PropTypes.bool,
  initialize: PropTypes.func,
  resetForm: PropTypes.func,
  change: PropTypes.func,
  apiError: PropTypes.any,
  currentInitial: PropTypes.object,
  formValues: PropTypes.object,
  currentForm: PropTypes.string,
  status: PropTypes.any,
  fields: PropTypes.object,
  touch: PropTypes.func,
  customer: PropTypes.object,
  inBlacklistCF: PropTypes.func,
  submitting: PropTypes.bool,
  anyTouched: PropTypes.bool,
  invalid: PropTypes.bool,
  url: PropTypes.string,
  idPratica: PropTypes.any,
  flagCustomerIsBlacklist: PropTypes.bool,
  isProspectPersonaGiuridica: PropTypes.bool,
  dataVerificaClienti: PropTypes.object,
  isEnabledCF: PropTypes.bool,
  formErrors: PropTypes.object
}

CodiceFiscaleGiuridiche.defaultProps = {
  formValues: {},
  formErrors: {}
}
