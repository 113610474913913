import React from 'react'
import { Table, Row, Col, Icon } from 'react-materialize'
import PropTypes from 'prop-types'
import moment from 'moment'

const SoggettiCorrelatiTable = ({
  list,
  contatore,
  searchSoggettiCorrelati,
  fields,
  formValues,
  incrementa,
  decrementa,
  numPage,
  totalpage,
  domini: { listaDomini }
}) => {
  if (!list || !listaDomini) {
    return null
  }
  return (
    <Row>
      <Table bordered>
        <thead>
          <tr
            style={{
              borderRight: '1px solid #C9C9C9',
              borderLeft: '1px solid #C9C9C9',
              borderTop: '1px solid #C9C9C9'
            }}>
            <th data-field="name" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Nome Cognome
            </th>
            <th data-field="bornDate" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Data di nascita
            </th>
            <th data-field="bornCity" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Luogo di nascita
            </th>
            <th data-field="taxCode" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              Codice Fiscale
            </th>
            <th data-field="relation" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              NDG
            </th>
            <th data-field="status" className="center-align">
              Collega
            </th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, i) => (
            <tr key={i} style={{ borderLeft: '1px solid #C9C9C9', borderRight: '1px solid #C9C9C9' }}>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {item.name + ' ' + item.surname}
              </td>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {moment(item.birthdate).format('DD/MM/YYYY')}
              </td>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {item.birthcity}
              </td>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {item.taxcode}
              </td>
              <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
                {item.idCustomer}
              </td>
              <td className="center-align">
                <i
                  className="material-icons"
                  value={i}
                  onClick={() => {
                    contatore(i)
                  }}>
                  person_add
                </i>
              </td>
            </tr>
          ))}
          {list.length === 0 && (
            <tr style={{ borderRight: '1px solid #C9C9C9', borderLeft: '1px solid #C9C9C9' }}>
              <td className="center-align">Non ci sono clienti da correlare.</td>
            </tr>
          )}
        </tbody>
      </Table>
      <Row style={{ marginLeft: '700px' }}>
        {numPage > 1 && (
          <Col
            onClick={() => {
              decrementa()
              searchSoggettiCorrelati({
                querySearch: formValues[fields.RICERCA.id],
                customerSubjectType: 'PF',
                itemxpage: 10,
                page: numPage - 1
              })
            }}>
            <Icon className="material-icons" medium>
              keyboard_arrow_left
            </Icon>
          </Col>
        )}
        {numPage < totalpage && (
          <Col
            onClick={() => {
              incrementa()
              searchSoggettiCorrelati({
                querySearch: formValues[fields.RICERCA.id],
                customerSubjectType: 'PF',
                itemxpage: 10,
                page: numPage + 1
              })
            }}>
            <Icon className="material-icons" medium>
              keyboard_arrow_right
            </Icon>
          </Col>
        )}
      </Row>
    </Row>
  )
}

SoggettiCorrelatiTable.propTypes = {
  list: PropTypes.array,
  contatore: PropTypes.func,
  domini: PropTypes.object,
  searchSoggettiCorrelati: PropTypes.any,
  fields: PropTypes.any,
  formValues: PropTypes.any,
  incrementa: PropTypes.any,
  decrementa: PropTypes.any,
  numPage: PropTypes.any,
  totalpage: PropTypes.any
}

export default SoggettiCorrelatiTable
