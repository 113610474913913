import React from 'react'
import { Table } from 'react-materialize'
import PropTypes from 'prop-types'
// import { getValueDominioFromKey } from '../../../routes/ConsulenzaBaseContainerLayout/utils'

const SoggettiCorrelatiTableGiuridiche = ({ list, contatore, domini: { listaDomini } }) => {
  if (!list || !listaDomini) {
    return null
  }
  return (
    <Table bordered className="marginTable">
      <thead>
        <tr
          style={{
            borderRight: '1px solid #C9C9C9',
            borderLeft: '1px solid #C9C9C9',
            borderTop: '1px solid #C9C9C9'
          }}>
          <th data-field="name" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
            Ragione Sociale
          </th>
          <th data-field="bornDate" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
            Codice Fiscale
          </th>
          <th data-field="bornCity" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
            Sede Legale
          </th>
          <th data-field="taxCode" className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
            NDG
          </th>
          <th data-field="status" className="center-align">
            Collega
          </th>
        </tr>
      </thead>
      <tbody>
        {list.map((item, i) => (
          <tr key={i} style={{ borderLeft: '1px solid #C9C9C9', borderRight: '1px solid #C9C9C9' }}>
            <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              {item.businessName}
            </td>
            <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              {item.taxCode}
            </td>
            <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              {item.registeredOfficeAddress}
            </td>
            <td className="center-align" style={{ borderRight: '1px solid #C9C9C9' }}>
              {item.idLegalPersonRelated}
            </td>
            <td className="center-align">
              <i
                className="material-icons"
                value={i}
                onClick={() => {
                  contatore(i)
                }}>
                person_add
              </i>
            </td>
          </tr>
        ))}
        {list.length === 0 && (
          <tr style={{ borderRight: '1px solid #C9C9C9', borderLeft: '1px solid #C9C9C9' }}>
            <td className="center-align" colSpan="5">
              Non ci sono clienti da correlare.
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

SoggettiCorrelatiTableGiuridiche.propTypes = {
  list: PropTypes.array,
  contatore: PropTypes.func,
  domini: PropTypes.object
}

export default SoggettiCorrelatiTableGiuridiche
