import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import Quagga from 'quagga'
import ImageTools from '../../components/Inputs/utils'
import fields from './fields'
import { getValueDominioFromKey } from '../ConsulenzaBaseContainerLayout/utils'
import { ProspectFisiche } from '../../components/Forms/ProspectFisiche'
import { ProspectGiuridiche } from '../../components/Forms/ProspectGiuridiche'
import { CREA_PRATICA } from '../../store/modules/forms/constants'

const tabs = [
  { label: 'Persone Fisiche', route: '/consulenza-base/create' },
  { label: 'Persone Giuridiche', route: '/consulenza-base-PG/create' },
  { label: 'Persone Fisiche con Figure Accessorie', route: '/consulenza-base/create' }
]
export class ConsulenzaBase extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verified: false,
      verifiedPG: false,
      created: false,
      error: false,
      imageupload: '',
      active: false,
      initialLoading: false,
      showModal: false,
      computeCFstate: false,
      sended: false,
      inviata: false,
      codiceFiscale: '',
      nazione: '',
      residenza: '',
      comune: '',
      comuneEstero: '',
      disabilitaBottone: false,
      isProspectPersonaGiuridica: false
    }

    this.imageFile = React.createRef()

    this.getQuagga = this.getQuagga.bind(this)
    this.handleGetCF = this.handleGetCF.bind(this)
    this.handleSubmitPF = this.handleSubmitPF.bind(this)
    this.handleSubmitPG = this.handleSubmitPG.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
    this.checkNazione = this.checkNazione.bind(this)
    this.checkNazionalitaUlterioriDati = this.checkNazionalitaUlterioriDati.bind(this)
    this.checkProvincia = this.checkProvincia.bind(this)
    this.handleModal = this.handleModal.bind(this)
    this.interventoInSede = this.interventoInSede.bind(this)
    this.openCamera = this.openCamera.bind(this)
    this.close = this.close.bind(this)
    this.handleCamera = this.handleCamera.bind(this)
    this.handleVerified = this.handleVerified.bind(this)
    this.handleVerifiedPG = this.handleVerifiedPG.bind(this)
  }

  componentDidMount() {
    const {
      dominio,
      resetCheckCF,
      location: { pathname }
    } = this.props
    const {
      NAZIONE_NASCITA,
      PROVINCIA_NASCITA,
      NAZIONALITA_ULTERIORI_DATI,
      RESIDENZA_ANAGRAFICA_ULTERIORI_DATI,
      COMUNE_NASCITA_ULTERIORI_DATI
    } = fields

    dominio({
      listaDomini: [
        {
          idDominio: NAZIONE_NASCITA.dominio
        },
        {
          idDominio: PROVINCIA_NASCITA.dominio
        },
        {
          idDominio: NAZIONALITA_ULTERIORI_DATI.dominio
        },
        {
          idDominio: RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.dominio
        },
        {
          idDominio: COMUNE_NASCITA_ULTERIORI_DATI.dominio
        }
      ]
    })
    resetCheckCF()
    this.setState({ isProspectPersonaGiuridica: pathname.indexOf('-PG') >= 0 })
  }

  componentDidUpdate(prevProps) {
    const {
      checkCFRequest,
      checkPGRequest,
      dominioRequest,
      match,
      change,
      location: { pathname }
    } = this.props
    if (prevProps.checkCFRequest.fetching && !checkCFRequest.fetching) {
      if (((checkCFRequest.error || {}).data || {}).code === 'CUSTOMER_230') {
        this.setState({ verified: true, verificaModale: false })
      } else if (checkCFRequest.error) {
        this.setState({ error: checkCFRequest.error })
      } else {
        this.setState({ verified: true, verificaModale: false })
      }
    }
    // Aggiornamento sulla verifica già cliente sez PG
    if (prevProps.checkPGRequest.fetching && !checkPGRequest.fetching) {
      if (checkPGRequest.error) {
        this.setState({ error: checkPGRequest.error })
      } else {
        this.setState({ verifiedPG: true })
      }
    }

    if (prevProps.dominioRequest.fetching && !dominioRequest.fetching) {
      this.setState({ initialLoading: true })
    }
    if (prevProps.match.url !== match.url) {
      this.setState({ computeCFstate: false, isProspectPersonaGiuridica: pathname.indexOf('-PG') >= 0 })
      change(CREA_PRATICA, 'ulterioriDati', false)
      /* change('privacyDoc', null) */
    }
  }

  updateDominio(idDominio, codice) {
    this.props.dominio({ listaDomini: [{ idDominio, filtro: { codice } }] })
  }

  checkNazione(isItalia) {
    const {
      formValues = {},
      domini: { listaDomini }
    } = this.props
    const {
      NAZIONE_NASCITA: { dominio, id, italiaId }
    } = fields
    if (isItalia) {
      return formValues[id] === italiaId
    }
    let bool = !!getValueDominioFromKey(listaDomini, dominio, formValues[id])
    return bool
  }

  checkNazionalitaUlterioriDati(isItalia) {
    const {
      formValues = {},
      domini: { listaDomini }
    } = this.props
    const {
      NAZIONALITA_ULTERIORI_DATI: { dominio, id, italiaId }
    } = fields
    if (isItalia) {
      this.props.untouch(fields.COMUNE_NASCITA_ULTERIORI_DATI)
      return formValues[id] === italiaId
    }
    return !!getValueDominioFromKey(listaDomini, dominio, formValues[id])
  }

  checkProvincia(value) {
    const {
      domini: { listaDomini }
    } = this.props
    return !!getValueDominioFromKey(listaDomini, fields.PROVINCIA_NASCITA.dominio, value)
  }

  handleGetCF() {
    const { NOME, COGNOME, SESSO, DATA_NASCITA, NAZIONE_NASCITA, PROVINCIA_NASCITA, COMUNE_NASCITA } = fields
    const { computeCF, formValues } = this.props
    const payload_cf = {
      name: formValues[NOME.id],
      surname: formValues[COGNOME.id],
      gender: formValues[SESSO.id],
      birthDate: formValues[DATA_NASCITA.id],
      nationBirthCode: formValues[NAZIONE_NASCITA.id],
      provinceBirthCode: formValues[PROVINCIA_NASCITA.id],
      cityBirthCode: formValues[COMUNE_NASCITA.id]
    }
    computeCF(payload_cf)
  }

  handleFiles(event) {
    this.setState({
      ...this.state,
      [name]: event.target.value
    })
  }

  interventoInSede() {
    const { formValues, interventionRequest } = this.props
    const data = {
      name: formValues[fields.NOME.id],
      surname: formValues[fields.COGNOME.id],
      taxCode: formValues[fields.CODICE_FISCALE.id] ? formValues[fields.CODICE_FISCALE.id].toUpperCase() : '',
      citizenshipCode: formValues[fields.NAZIONALITA_ULTERIORI_DATI.id],
      registeredResidenceCode: formValues[fields.RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id],
      cityBirthCode: formValues[fields.COMUNE_NASCITA_ULTERIORI_DATI.id],
      cityBirthDescription: formValues[fields.COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id] || null,
      birthDate: formValues[fields.DATA_NASCITA_ULTERIORI_DATI.id]
    }
    interventionRequest(data)
    this.setState({
      verified: !this.state.verified,
      verificaModale: !this.state.verificaModale,
      inviata: !this.state.inviata
    })
  }

  close() {
    this.setState({ inviata: !this.state.inviata, verificaModale: !this.state.verificaModale })
  }

  iconClose() {
    this.setState({
      inviata: false,
      verified: !this.state.verified,
      verificaModale: !this.state.verificaModale
    })
  }

  handleSubmitPG() {
    const { checkProspectPGActionCreator, formValues, change } = this.props

    const { RAGIONE_SOCIALE, TIPO_INSERIMENTO, PARTITA_IVA_PG, CODICE_FISCALE_PG, SAVED_PG_FLAG } = fields

    let data = {
      businessName: formValues[RAGIONE_SOCIALE.id],
      isVatSelected: formValues[TIPO_INSERIMENTO.id] === 'partita-iva',
      isTaxCodeSelected: formValues[TIPO_INSERIMENTO.id] === 'codice-fiscale',
      taxCodeOrVat:
        formValues[TIPO_INSERIMENTO.id] === 'partita-iva'
          ? formValues[PARTITA_IVA_PG.id]
          : formValues[CODICE_FISCALE_PG.id].toUpperCase()
    } // oggetto da passare all'action da inviare nel body del post
    change(CREA_PRATICA, SAVED_PG_FLAG.id, true)
    checkProspectPGActionCreator(data)
  }

  handleSubmitPF() {
    const { checkCF, formValues, untouch } = this.props

    let data = {
      name: formValues[fields.NOME.id],
      surname: formValues[fields.COGNOME.id],
      taxCode: formValues[fields.CODICE_FISCALE.id] ? formValues[fields.CODICE_FISCALE.id].toUpperCase() : ''
    } // oggetto da passare all'action da inviare nel body del post

    if (formValues[fields.ULTERIORI_DATI.id]) {
      data = {
        ...data,
        citizenshipCode: formValues[fields.NAZIONALITA_ULTERIORI_DATI.id],
        registeredResidenceCode: formValues[fields.RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id],
        cityBirthCode: formValues[fields.COMUNE_NASCITA_ULTERIORI_DATI.id],
        cityBirthDescription: formValues[fields.COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id] || null,
        birthDate: formValues[fields.DATA_NASCITA_ULTERIORI_DATI.id],
        sex: formValues[fields.SESSO_ULTERIORI_DATI.id]
      }
    }
    if (this.state.isProspectPersonaGiuridica) {
      data = {
        ...data,
        subjectType: 'PHYSICAL_SUBJECT_REFERENCE'
      }
    } else {
      data = {
        ...data,
        subjectType: 'PHYSICAL_SUBJECT'
      }
    }
    checkCF(data)

    untouch(
      fields.CODICE_FISCALE.id,
      fields.NAZIONALITA_ULTERIORI_DATI.id,
      fields.RESIDENZA_ANAGRAFICA_ULTERIORI_DATI.id,
      fields.COMUNE_NASCITA_ULTERIORI_DATI.id,
      fields.COMUNE_NASCITA_ESTERO_ULTERIORI_DATI.id,
      fields.DATA_NASCITA_ULTERIORI_DATI.id,
      fields.SESSO_ULTERIORI_DATI.id
    )
  }

  getQuagga(arrConfig, fileSrc) {
    const context = this
    const { change } = context.props
    Quagga.decodeSingle(
      {
        decoder: {
          readers: ['code_39_reader', 'code_128_reader'] // List of active readers
        },
        patchSize: arrConfig.patchSize,
        numOfWorkers: 1,
        inputStream: {
          size: arrConfig.size // restrict input-size to be 800px in width (long-side)
        },
        locate: arrConfig.locate, // try to locate the barcode in the image
        src: fileSrc // or 'data:image/jpg;base64,' + data
      },
      function(result) {
        if (result.codeResult) {
          change(CREA_PRATICA, fields.CODICE_FISCALE.id, result.codeResult.code)
          context.setState({ active: true })
        } else {
          change(CREA_PRATICA, fields.CODICE_FISCALE.id, 'Codice fiscale non valido')
        }
      }
    )
  }

  getCodiceFiscale(fileSrc) {
    const quaggaConfig = {
      size: '1600',
      locate: false,
      patchSize: 'small'
    }
    this.getQuagga(quaggaConfig, fileSrc)
  }

  handleCamera() {
    let context = this
    const file = this.imageFile.current.files[0]
    this.imageFile.current.value = ''
    ImageTools.resize(
      file,
      {
        width: 1000, // maximum width
        height: 750 // maximum height
      },
      function(blob, didItResize) {
        // didItResize will be true if it managed to resize it, otherwise false (and
        // will return the original file as 'blob')
        let data = (document.getElementById('preview').src = window.URL.createObjectURL(blob))

        context.getCodiceFiscale(data)
        context.setState({ imageupload: data })
        // you can also now upload this blob using an XHR.
      }
    )
  }

  openCamera() {
    this.imageFile.current.click()
    this.imageFile.current.onchange = () => this.handleCamera()
  }

  handleModal() {
    this.setState({ selected: '' })
    this.setState({ showModal: !this.state.showModal })
  }

  handleVerifiedPG(verifiedPG) {
    const { change } = this.props
    const { SAVED_PG_FLAG } = fields
    this.setState({ verifiedPG })
    // save in form for dynamic validation
    change(CREA_PRATICA, SAVED_PG_FLAG.id, verifiedPG)
  }

  handleVerified(verified) {
    this.setState({ verified })
  }

  render() {
    const { isUserAvailable, isProspectPGAvailable, isSend } = this.props
    return this.state.isProspectPersonaGiuridica ? (
      <ProspectGiuridiche
        {...this.props}
        showModal={this.state.showModal}
        handleModal={this.handleModal}
        tabs={tabs}
        handleSubmitPF={this.handleSubmitPF}
        handleSubmitPG={this.handleSubmitPG}
        fields={fields}
        checkProvincia={this.checkProvincia}
        checkNazione={this.checkNazione}
        checkNazionalitaUlterioriDati={this.checkNazionalitaUlterioriDati}
        computeCFstate={this.state.computeCFstate}
        handleComputeCFstate={() => this.setState({ computeCFstate: !this.state.computeCFstate })}
        handleGetCF={this.handleGetCF}
        updateDominio={this.updateDominio}
        verified={this.state.verified}
        verifiedPG={this.state.verifiedPG}
        verificaModale={this.state.verificaModale}
        handleVerified={this.handleVerified}
        handleVerifiedPG={this.handleVerifiedPG}
        sended={this.state.sended}
        imageupload={this.state.imageupload}
        close={this.close}
        openCamera={this.openCamera}
        ref={this.imageFile}
        isUserAvailable={!this.state.verified ? false : isUserAvailable}
        isProspectPGAvailable={isProspectPGAvailable}
        legalPersonCreatePF={this.props.legalPersonCreatePF}
        idPhysicalPersonReference={this.props.idPhysicalPersonReference}
        legalPersonCreatePFRequest={this.props.legalPersonCreatePFRequest}
      />
    ) : (
      <ProspectFisiche
        {...this.props}
        initialLoading={this.state.initialLoading}
        showModal={this.state.showModal}
        handleModal={this.handleModal}
        tabs={tabs}
        handleSubmitPF={this.handleSubmitPF}
        fields={fields}
        checkProvincia={this.checkProvincia}
        checkNazione={this.checkNazione}
        checkNazionalitaUlterioriDati={this.checkNazionalitaUlterioriDati}
        computeCFstate={this.state.computeCFstate}
        handleComputeCFstate={() => this.setState({ computeCFstate: !this.state.computeCFstate })}
        handleGetCF={this.handleGetCF}
        updateDominio={this.updateDominio}
        verified={this.state.verified}
        verificaModale={this.state.verificaModale}
        handleVerified={this.handleVerified}
        sended={this.state.sended}
        imageupload={this.state.imageupload}
        close={this.close}
        interventoInSede={this.interventoInSede}
        openCamera={this.openCamera}
        ref={this.imageFile}
        isUserAvailable={isUserAvailable}
        isSend={isSend}
      />
    )
  }
}
export default ConsulenzaBase

ConsulenzaBase.propTypes = {
  idPratica: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUserAvailable: PropTypes.bool,
  isProspectPGAvailable: PropTypes.bool,
  computeCF: PropTypes.func,
  checkCFRequest: PropTypes.object,
  checkPGRequest: PropTypes.object,
  domini: PropTypes.object,
  dominio: PropTypes.func,
  dominioRequest: PropTypes.object,
  handleSubmit: PropTypes.func,
  formValues: PropTypes.object,
  change: PropTypes.func,
  resetCheckCF: PropTypes.func,
  checkCF: PropTypes.func,
  history: PropTypes.object,
  interventionRequest: PropTypes.func,
  match: PropTypes.any,
  isSend: PropTypes.string,
  location: PropTypes.object,
  untouch: PropTypes.func,
  checkProspectPGActionCreator: PropTypes.func,
  legalPersonCreatePF: PropTypes.func,
  idPhysicalPersonReference: PropTypes.object,
  legalPersonCreatePFRequest: PropTypes.object,
  idLegalPerson: PropTypes.any
}
