import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isDirty, getFormValues } from 'redux-form'
import * as forms from '../../../store/modules/forms/constants'
import AcquisizioneDocumentiComponent from './AcquisizioneDocumentiComponent'
// import {
//   CODICE_FISCALE_PF,
//   DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO,
//   forms
// } from '../../../store/modules/forms/constants'
import {
  getDocumentsData,
  getDocumentsDataProcurator,
  getProfessionalSubject,
  saveCodiceFiscale,
  saveDocumenti,
  getDataRegistry,
  checkHasProcuratore,
  getDataSummaryLight
} from '../../../store/modules/customers/actions'
import {
  saveCodiceFiscalePFActionCreator,
  getAnagraficaPFActionCreator,
  getDocumentIdPFActionCreator,
  getDocumentIdPGActionCreator,
  postDocumentIdPGActionCreator,
  getDataVerificaClienti,
  postDataVerificaClienti,
  setCurrentForm
} from '../../../store/modules/personaGiuridica/client/actions'
import { getDataSummaryLightPGActionCreator } from '../../../store/modules/personaGiuridica/prospect/actions'
import { dominio } from '../../../store/modules/dominio/actions'
import { getProfessionale } from '../../../store/modules/survey/actions'
import {
  getAddictionalOwner,
  setEnabledTitolare,
  setMultidTitolare,
  getDataFromTaxCode
} from '../../../store/modules/utility/actions'

const mapDispatchToProps = (dispatch, ownProps) => {
  const bindendActionCreator = {
    getDataRegistry,
    getAnagraficaPFActionCreator,
    getDocumentsData,
    getDocumentIdPFActionCreator,
    getDocumentIdPGActionCreator,
    postDocumentIdPGActionCreator,
    getDocumentsDataProcurator,
    getProfessionalSubject,
    saveCodiceFiscale,
    saveCodiceFiscalePFActionCreator,
    saveDocumenti,
    checkHasProcuratore,
    getDataSummaryLight,
    retrieveDominio: (...params) => dominio(...params),
    getDataVerificaClienti,
    postDataVerificaClienti,
    getProfessionale,
    getAddictionalOwner,
    setEnabledTitolare,
    setMultidTitolare,
    getDataSummaryLightPGActionCreator,
    getDataFromTaxCode,
    setCurrentForm
  }
  return bindActionCreators(bindendActionCreator, dispatch)
}

const setTitolare = (obj, state) => {
  // if (obj.isOwner!==undefined && obj.isOwner!==null){
  //   setEnabledTitolare( obj.isOwner )
  // }
  // if (obj.isUniqueOwner!==undefined && obj.isUniqueOwner!==null){
  //   setMultidTitolare( obj.isUniqueOwner )
  // }
  return true
}

const mapStateToProps = (state, ownProps) => {
  let commonstateToProps = {
    customer: state.customers.customer,
    documentsData: state.customers.documentsData,
    documentsDataProcuratore: state.customers.documentsDataProcuratore,
    getDocumentsDataRequest: state.customers.getDocumentsDataRequest,
    domini: state.dominio.domini,
    hasProcuratore: state.customers.hasProcuratore,
    dominioRequest: state.dominio.dominioRequest,
    docIDPF: state.anagraficaPersonaGiuridicaCliente.docIDPF,
    docIDPG: state.anagraficaPersonaGiuridicaCliente.docIDPG,
    getDataFromTaxCodeRequest: state.utility.getDataFromTaxCodeRequest,

    // documentiInitialPG: (state.form[forms.DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO] || {}).initial,
    procuratoreInitial: (state.form[forms.CENSIMENTO_PROCURATORE] || {}).initial,
    procuratoreDocumentiInitial: (state.form[forms.PROCURATORE_ID] || {}).initial,
    reduxForms: Object.keys(forms).reduce((res, form) => ({ ...res, [form]: isDirty(forms[form])(state) }), {}),
    isProspectPersonaGiuridica: ownProps.isProspectPersonaGiuridica,
    getDocIDPFRequest: state.anagraficaPersonaGiuridicaCliente.getDocIDPFRequest,
    getDocIDPGRequest: state.anagraficaPersonaGiuridicaCliente.getDocIDPGRequest
  }
  if (ownProps.isProspectPersonaGiuridica) {
    commonstateToProps = {
      ...commonstateToProps,
      summaryData: state.prospectPersonaGiuridica.summaryData,
      idPhysicalPersonReference: state.prospectPersonaGiuridica.summaryData.idPhysicalPersonReference,
      idProcurator: state.prospectPersonaGiuridica.summaryData.idProcurator || state.procuratore.idProcurator,
      codiceFiscaleInitial: (state.form[forms.CODICE_FISCALE_PF] || {}).initial || '',
      docFiscalRiferimentoInitial:
        (state.form[forms.DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO] || {}).initial || '',
      dataVerificaClienti: state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti,
      additionalOwnerList: state.utility.additionalOwnerList,
      setDataVer: setTitolare(state, state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti),
      getDataVerificaClientiRequest: state.anagraficaPersonaGiuridicaCliente.getDataVerificaClientiRequest,
      postDataVerificaClientiRequest: state.anagraficaPersonaGiuridicaCliente.postDataVerificaClientiRequest,
      postAdditionalOwnerRequest: state.utility.postAdditionalOwnerRequest,
      deleteAdditionalOwnerRequest: state.utility.deleteAdditionalOwnerList,
      getAdditionalOwnerRequest: state.utility.getAdditionalOwnerList,
      postDocPGIDPGRequest: state.anagraficaPersonaGiuridicaCliente.postDocPGIDPGRequest,
      postDocIDPGRequest: state.anagraficaPersonaGiuridicaCliente.postDocIDPGRequest,
      titolare: state.utility.titolare,
      titolareMulti: state.utility.titolareMulti,
      idPratica: state.pratica.idPratica,
      getDocIDPFRequest: state.anagraficaPersonaGiuridicaCliente.getDocIDPFRequest,
      getDocIDPGRequest: state.anagraficaPersonaGiuridicaCliente.getDocIDPGRequest,
      formValuesCF_PG: getFormValues(forms.CODICE_FISCALE_PF)(state),
      formValuesDOC_RIF_PG: getFormValues(forms.DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO)(state)
    }
  } else {
    commonstateToProps = {
      ...commonstateToProps,
      summaryData: state.customers.summaryData,
      idProcurator: state.customers.summaryData.idProcurator,
      sendDataProcuratore: state.procuratore.sendDataProcuratore,
      moduleSendRequest: state.procuratore.moduleSendRequest,
      codiceFiscaleInitial: (state.form[forms.CODICE_FISCALE] || {}).initial,
      documentiInitial: (state.form[forms.DOCUMENTO_IDENTITA_ALLEGATO] || {}).initial
    }
  }
  return commonstateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcquisizioneDocumentiComponent)
