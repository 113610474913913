import React from 'react'
import PropTypes from 'prop-types'

const ReadOnlyYesNo = ({ label, value }) => (
  <div className="read-only-yes-no-question">
    <p>
      <b>{label}</b>
    </p>
    <div className={`box-input ${value === 'SI' ? 'yes' : 'no'}-selected`}>
      <div className={`${value === 'SI' ? 'yes' : 'no'}-button`}>{value}</div>
    </div>
  </div>
)
export default ReadOnlyYesNo

ReadOnlyYesNo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
}
