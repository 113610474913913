import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import moment from 'moment'
import { Row, Col } from 'react-materialize'
import { getDominio, getAutocompleteDefaultOption, mapDominiToAutocomplete, mapDominiToOptions } from '../../utils'
import { CollapsibleItem } from '../../../../components/Collapsible'
import InputText from '../../../../components/Inputs/Text'
import { INFORMAZIONI_PERSONALI_GIURIDICHE } from '../../../../store/modules/forms/constants'
import {
  renderAutocomplete,
  renderDatePicker,
  // renderHtmlInput,
  renderSelect
} from '../../../../components/Forms/renderFields'
import AccordionHeader from '../../../../components/AccordionHeader'

// import * as dataSB from '../../../../static-data/data-servizio-base.json'
// import Title from '../../../../components/Title'

class InformazioniPersonaliGiuridiche extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (!prevProps.apiSuccess && this.props.apiSuccess) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(INFORMAZIONI_PERSONALI_GIURIDICHE)
      this.setState({ saved: true })
    }
  }

  render() {
    const { formValues, change, domini, status, isDirty } = this.props
    const {
      fields: {
        CODICE_FISCALE_PG,
        PARTITA_IVA_PG,
        FORMA_ASSOCIATIVA_PG,
        RAGIONE_SOCIALE_PG,
        PROVINCIA_PG,
        CITTA_PG,
        SEDE_LEGALE_PG,
        DATA_COSTITUZIONE_PG,
        CODICE_ATECO_PG,
        ATTIVITA_ESERCITATA_PG
      }
    } = this.props

    return (
      <CollapsibleItem
        header={'Informazioni Personali Persona Giuridica'}
        icon="keyboard_arrow_down"
        itemKey={INFORMAZIONI_PERSONALI_GIURIDICHE}
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <Row className="m-top20">
          <Field
            name={CODICE_FISCALE_PG.id}
            s={6}
            maxLength={CODICE_FISCALE_PG.maxLength}
            label={CODICE_FISCALE_PG.label}
            change={change}
            disabled
            component={InputText}
            formatter={value => (value ? value.toUpperCase() : '')}
          />
          <Field
            name={PARTITA_IVA_PG.id}
            s={6}
            maxLength={PARTITA_IVA_PG.maxLength}
            label={PARTITA_IVA_PG.label}
            change={change}
            disabled
            component={InputText}
          />
        </Row>
        <Row>
          <Field
            name={FORMA_ASSOCIATIVA_PG.id}
            size={6}
            label={FORMA_ASSOCIATIVA_PG.label}
            options={mapDominiToOptions(getDominio(domini, FORMA_ASSOCIATIVA_PG.dominio), true)}
            disabled
            component={renderSelect}
          />

          <Field
            name={RAGIONE_SOCIALE_PG.id}
            s={6}
            maxLength={RAGIONE_SOCIALE_PG.maxLength}
            label={RAGIONE_SOCIALE_PG.label}
            change={change}
            disabled
            component={InputText}
          />
        </Row>
        <Row>
          <Col s={6}>
            <Field
              name={PROVINCIA_PG.id}
              s={12}
              label={PROVINCIA_PG.label}
              threshold={0}
              disabled
              defaultValue={getAutocompleteDefaultOption(
                domini.listaDomini,
                PROVINCIA_PG.dominio,
                formValues[PROVINCIA_PG.id]
              )}
              options={mapDominiToAutocomplete(getDominio(domini, PROVINCIA_PG.dominio))}
              handleChange={(field, value) => {
                change(field, value)
                if (formValues[CITTA_PG.id]) {
                  change(CITTA_PG.id, '')
                }
              }}
              component={renderAutocomplete}
            />
          </Col>

          <Col s={6}>
            <Field
              name={CITTA_PG.id}
              s={12}
              threshold={0}
              disabled
              label={CITTA_PG.label}
              defaultValue={getAutocompleteDefaultOption(domini.listaDomini, CITTA_PG.dominio, formValues[CITTA_PG.id])}
              options={formValues[PROVINCIA_PG.id] ? mapDominiToAutocomplete(getDominio(domini, CITTA_PG.dominio)) : []}
              handleChange={(field, value) => {
                change(field, value)
              }}
              component={renderAutocomplete}
            />
          </Col>
        </Row>
        <Row>
          <Field
            name={SEDE_LEGALE_PG.id}
            s={6}
            maxLength={SEDE_LEGALE_PG.maxLength}
            label={SEDE_LEGALE_PG.label}
            change={change}
            disabled
            component={InputText}
          />
          <Field
            name={DATA_COSTITUZIONE_PG.id}
            s={6}
            openDirection="down"
            anchorDirection="right"
            label={DATA_COSTITUZIONE_PG.label}
            readOnly
            disabled
            placeholder=""
            isOutsideRange={date => date.isAfter(moment().subtract(0, 'y'), 'day')}
            defaultDate={formValues[DATA_COSTITUZIONE_PG.id]}
            onDateChange={date => change(DATA_COSTITUZIONE_PG.id, date.format())}
            component={renderDatePicker}
          />
        </Row>
        <Row>
          <Field
            name={CODICE_ATECO_PG.id}
            s={6}
            maxLength={CODICE_ATECO_PG.maxLength}
            label={CODICE_ATECO_PG.label}
            change={change}
            component={InputText}
            value={formValues[CODICE_ATECO_PG.id]}
            disabled
          />
          <Field
            name={ATTIVITA_ESERCITATA_PG.id}
            size={6}
            label={ATTIVITA_ESERCITATA_PG.label}
            options={mapDominiToOptions(getDominio(domini, ATTIVITA_ESERCITATA_PG.dominio), true)}
            disabled
            component={renderSelect}
          />
        </Row>
      </CollapsibleItem>
    )
  }
}

InformazioniPersonaliGiuridiche.propTypes = {
  domini: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  formValues: PropTypes.object,
  fields: PropTypes.object,
  resetForm: PropTypes.func,
  apiSuccess: PropTypes.any,
  status: PropTypes.any,
  isDirty: PropTypes.bool
}

InformazioniPersonaliGiuridiche.defaultProps = {
  formValues: {}
}

export default InformazioniPersonaliGiuridiche
