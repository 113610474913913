import React, { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import { Col, Row } from 'react-materialize'
import ReadOnlyInput from '../../../../../components/ReadOnlyInput'
import { renderCheckbox } from '../../../../../components/Forms/renderFields'

const TransferTitoli = ({ fields: { FLAG_TRANSFER }, label }) => (
  <Fragment>
    <Row>
      <Col s={12}>
        <ReadOnlyInput label="Mezzo di pagamento" />
      </Col>
    </Row>
    <Row className="m-top10">
      <Field
        name={FLAG_TRANSFER.id}
        size={4}
        label={FLAG_TRANSFER.label}
        className="filled-in"
        component={renderCheckbox}
      />
      <Col s={8}>
        <label>{label}</label>
      </Col>
    </Row>
  </Fragment>
)
TransferTitoli.propTypes = {
  fields: PropTypes.shape({
    FLAG_TRANSFER: PropTypes.object
  }),
  label: PropTypes.string
}

export default TransferTitoli
