import * as actionTypes from './constants'

import { unionBy } from 'lodash'

const initialState = {
  filtri: {},
  domini: { listaDomini: [] },
  dominioRequest: {
    fetching: false,
    error: null
  },
  dominioBgRequest: {
    fetching: false,
    error: null
  }
}

const iteree = function(dominio) {
  if (dominio) {
    return dominio.idDominio
  }
  return null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.DOMINIO_START:
      const filtri = {}

      action.query.listaDomini.forEach(dominio => {
        if (dominio.filtro) {
          filtri[dominio.idDominio] = dominio.filtro.codice
        }
      })

      return Object.assign({}, state, {
        dominioRequest: {
          fetching: true,
          error: null
        },
        filtri: Object.assign({}, state.filtri, filtri)
      })
    case actionTypes.DOMINIO_SUCCESS:
      return {
        ...state,
        domini: {
          listaDomini: unionBy(action.dominio.listaDomini, state.domini.listaDomini || [], iteree)
        },
        dominioRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.DOMINIO_ERROR:
      return {
        ...state,
        dominioRequest: {
          fetching: false,
          error: action.error
        }
      }
    case actionTypes.DOMINIO_BG_START:
      return {
        ...state,
        dominioBgRequest: {
          fetching: true,
          error: null
        },
        filtri: {
          ...state.filtri,
          ...action.query.listaDomini.reduce((res, dominio) => {
            if (dominio.filtro) {
              return { ...res, [dominio.idDominio]: dominio.filtro.codice }
            }
            return res
          }, {})
        }
      }
    case actionTypes.DOMINIO_BG_SUCCESS:
      return {
        ...state,
        domini: {
          listaDomini: unionBy(action.dominio.listaDomini, state.domini.listaDomini || [], iteree)
        },
        dominioBgRequest: {
          fetching: false,
          error: null
        }
      }
    case actionTypes.DOMINIO_BG_ERROR:
      return {
        ...state,
        dominioBgRequest: {
          fetching: false,
          error: action.error
        }
      }
  }
  return state
}
