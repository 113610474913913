import React, { Component } from 'react'
import { Row, Col } from 'react-materialize'
import PropTypes from 'prop-types'

class ModalWarn extends Component {
  render() {
    const { title, text, children, closeModal } = this.props
    if (this.props.scssClass) {
      return (
        <div className="onboarding-modale">
          <div className="onboarding-modale-content" style={{ position: 'relative' }}>
            <i
              className="material-icons cancella onboarding-modale-text__blu"
              onClick={e => {
                e.preventDefault()
                closeModal()
              }}>
              cancel
            </i>
            {children}
          </div>
        </div>
      )
    } else {
      return (
        <div className="onboarding-modale">
          <div className="onboarding-modale-content" style={{ position: 'relative' }}>
            {/* MODALE IS BLACKLIST */}
            <i className="material-icons cancella" onClick={() => this.props.closePopUp()}>
              cancel
            </i>
            <Row>
              <Col s={12} className="center-align" style={{ marginTop: '-20px' }}>
                {title}
              </Col>
              <Col s={12} className="center-align">
                {children}
              </Col>
              <Col s={12}>{text}</Col>
            </Row>
          </div>
        </div>
      )
    }
  }
}
export default ModalWarn

ModalWarn.propTypes = {
  scssClass: PropTypes.any,
  title: PropTypes.object,
  text: PropTypes.object,
  children: PropTypes.node,
  closePopUp: PropTypes.func,
  closeModal: PropTypes.func
}
