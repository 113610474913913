import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Icon } from 'react-materialize'
import ImageTools from '../utils'

class InputPdf extends Component {
  constructor(props) {
    super(props)
    this.state = {
      image_uploaded: false,
      fileOriginal: null,
      fileSmall: null
    }
    this.handleFilesInput = this.handleFilesInput.bind(this)
    this.resizeImage = this.resizeImage.bind(this)
    this.checkResizeCompleted = this.checkResizeCompleted.bind(this)
    this.addRemoveIcon = this.addRemoveIcon.bind(this)
    this.remove = this.remove.bind(this)
  }

  componentDidMount() {
    if (this.props.fileIn) {
      this.setState({ image_uploaded: true })
      this.setState({ fileOriginal: this.props.fileIn })
    }
  }

  resizeImage(fileIn, maxWidth, maxHeight, stateProps) {
    const context = this
    ImageTools.resize(
      fileIn,
      {
        width: maxWidth, // maximum width original file
        height: maxHeight // maximum height original file
      },
      function(blob, didItResize) {
        // didItResize will be true if it managed to resize it, otherwise false (and
        // will return the original file as 'blob')
        let data = (document.getElementById(context.props.idImg).src = window.URL.createObjectURL(blob))
        context.getBase64FromBlobUrl(data, function(dataUrl) {
          const newProps = {}
          newProps[stateProps] = dataUrl
          const newState = Object.assign({}, context.state, newProps)
          context.setState({ ...newState })
          context.checkResizeCompleted()
        })
        // you can also now upload this blob using an XHR.
      }
    )
  }

  checkResizeCompleted() {
    if (this.state.fileOriginal && this.state.fileSmall && this.props.onChange) {
      this.setState({ image_uploaded: true })
      this.props.onChange(this.state.fileOriginal, this.state.fileSmall)
    }
  }

  handleFilesInput() {
    const file = this.fileInput.files[0]
    this.fileInput.value = ''
    this.resizeImage(file, 1000, 750, 'fileOriginal')
    this.resizeImage(file, 200, 150, 'fileSmall')
  }

  getBase64FromBlobUrl(url, callback) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function() {
      var reader = new FileReader()
      reader.onloadend = function() {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  // Delete documento firmato
  addRemoveIcon() {
    let context = this
    if (this.state.image_uploaded) {
      this.remove()
    } else {
      this.fileInput.click()
      this.fileInput.onchange = () => context.handleFilesInput()
    }
  }

  remove() {
    this.setState({ image_uploaded: false, fileOriginal: '', fileSmall: '' })
    if (this.props.onChange) {
      this.props.onChange('', '')
    }
  }

  render() {
    const { readOnly = false, label, idImg } = this.props
    return (
      <Fragment>
        {!this.props.flagImage && !this.state.image_uploaded ? (
          <Row className="box-image-container center-align">
            <div s={12} className={!this.state.image_uploaded ? `box-image-privacy` : `img-loaded`}>
              {!readOnly && !this.state.image_uploaded && (
                <Row className="center-align" onClick={this.addRemoveIcon}>
                  <Col s={12}>
                    <Icon
                      className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa box-image-privacy-text"
                      medium>
                      &#xe91e;
                    </Icon>
                  </Col>
                  <Col s={12}>
                    <p className="box-image-privacy-text">Carica il {label}</p>
                  </Col>
                </Row>
              )}
              <div id="form1" className="former-form" encType="multipart/form-data">
                <input
                  disabled={this.props.disabled}
                  type="file"
                  ref={input => {
                    this.fileInput = input
                  }}
                  accept=".jpg, .png, .jpeg, .bmp, .tif, .tiff|images/*"
                  capture="camera"
                />
              </div>
              <img className="img-document" src={this.state.fileOriginal} />
            </div>
            {/* <p className="box-image-suggestion-label">{label}</p> */}
            <img id={idImg} className="img-for-create-url" />
          </Row>
        ) : (
          <Row className="m-bottom50 valign-wrapper onboarding-privacy-signed">
            <Col s={1}>
              <Icon
                className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa onboarding-modale-text"
                small>
                &#xe915;
              </Icon>
            </Col>
            <Col s={8}>
              <p className="left-align onboarding-modale-text">{`${label} Caricato`}</p>
            </Col>
            <Col s={2}>
              <button onClick={this.addRemoveIcon} style={{ border: 'none' }}>
                <Icon
                  className="onboarding-modale-icon__firma-cartacea icon-icone-ips_sv-spesa onboarding-modale-text right-align"
                  small>
                  &#xe91c;
                </Icon>
              </button>
            </Col>
          </Row>
        )}
      </Fragment>
    )
  }
}
export default InputPdf

InputPdf.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  fileIn: PropTypes.string,
  idImg: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  flagImage: PropTypes.bool,
  disabled: PropTypes.bool
}
