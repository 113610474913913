import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { CONSENSI_PRIVACY } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import allFields from '../../fields'
import dataSB from '../../../../static-data/data-servizio-base.json'
import Title from '../../../../components/Title'

const fields = allFields[1][CONSENSI_PRIVACY]

class ConsensiPrivacyReview extends Component {
  constructor(props) {
    super(props)
    this.mapRisposta = this.mapRisposta.bind(this)
    this.getLabel = this.getLabel.bind(this)
  }

  getLabel({ templateId }) {
    return Object.keys(this.props.template).length > 0
      ? this.props.template.find(d => d.idDomanda === templateId).testoDomanda
      : ''
  }

  mapRisposta({ templateId }) {
    const {
      data: { listaDomande },
      template
    } = this.props
    let listaRisposte = listaDomande ? listaDomande.find(d => d.idDomanda === templateId) : []
    const idRisposta = listaRisposte && listaRisposte.length > 0 ? listaRisposte[0].idRisposta : 0
    return listaRisposte && listaRisposte.length > 0
      ? template.find(d => d.idDomanda === templateId).listaRisposte.find(r => r.idRisposta === idRisposta).descRisposta
      : []
  }

  render() {
    return (
      <CollapsibleItem
        header={dataSB.censimento_anagrafica.consensi_privacy.title_accordion}
        icon="keyboard_arrow_down"
        itemKey={CONSENSI_PRIVACY}
        labelInfo={this.props.accordionHeader}>
        {/* {this.props.data.listaDomande !== undefined && */}
        {/* this.props.data.listaDomande[10].listaRisposte[0].idRisposta === 85 && ( */}
        <Row>
          <Col s={12}>
            <Title>{this.getLabel(fields.ATTIVITA_MARKETING)}</Title>
          </Col>
          <Col s={12}>
            <ReadOnlyInput value={this.mapRisposta(fields.ATTIVITA_MARKETING)} />{' '}
          </Col>
        </Row>
        {/* )}
        {this.props.data.listaDomande !== undefined &&
          this.props.data.listaDomande[11].listaRisposte[0].idRisposta === 85 && ( */}
        <Row>
          <Col s={12}>
            <Title>{this.getLabel(fields.ATTIVITA_ALTRO)}</Title>
          </Col>
          <Col s={12}>
            <ReadOnlyInput value={this.mapRisposta(fields.ATTIVITA_ALTRO)} />
          </Col>
        </Row>
        {/* )} */}
      </CollapsibleItem>
    )
  }
}
export default ConsensiPrivacyReview

ConsensiPrivacyReview.propTypes = {
  accordionHeader: PropTypes.object,
  data: PropTypes.object,
  template: PropTypes.array
}
