import * as actionTypes from './constants'

const initialState = {
  toggleBlur: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_DATA_TOGGLE_BLUR:
      return Object.assign({}, state, { toggleBlur: action.toggleBlur })
  }
  return state
}
