import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row } from 'react-materialize'

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.forceToggle !== this.props.forceToggle) {
      this.setState({ active: false })
    }
  }

  toggle() {
    this.setState({ active: !this.state.active })
  }

  render() {
    return (
      <footer className={`footer-bar${this.state.active ? ' active' : ''}`}>
        <div className="nav-links-box">
          <Link
            to="/consulenza-base/create"
            className={`nav-link-item${this.state.active ? ' page-layout__nav-item--active' : ''}`}>
            <i className="icon-login" />
            <p>Onboarding Persona Fisica</p>
          </Link>
          <Link
            to="/consulenza-base-PG/create"
            className={`nav-link-item${this.state.active ? ' page-layout__nav-item--active' : ''}`}>
            <i className="material-icons">business_center</i>
            <p>Onboarding Persona Giuridica</p>
          </Link>
          {this.props.flagAperturaServizi && (
            <Link
              to="/apertura-servizi/intestatari"
              disabled
              className={`nav-link-item${this.state.active ? ' page-layout__nav-item--active' : ''}`}>
              <i className="icon-prodotti-action-bar" />
              <p>Apertura Servizi</p>
            </Link>
          )}
          {!this.props.flagAperturaServizi && (
            <a className={`disabled nav-link-item${this.state.active ? ' page-layout__nav-item--active' : ''}`}>
              <i className="icon-prodotti-action-bar" />
              <p>Apertura Servizi</p>
            </a>
          )}
          <Link
            to="/aggiornamento-modulistica"
            className={`nav-link-item${this.state.active ? ' page-layout__nav-item--active' : ''}`}>
            <i className="material-icons">cached</i>
            <p>Aggiornamento Modulistica</p>
          </Link>
        </div>
        <Row className="button-container">
          <button className="button-toogle" onClick={this.toggle}>
            <i className="icon-plus center-align" />
          </button>
        </Row>
      </footer>
    )
  }
}
export default Footer

Footer.propTypes = {
  flagAperturaServizi: PropTypes.bool,
  forceToggle: PropTypes.bool
}
