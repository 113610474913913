import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, isDirty, getFormSyncErrors } from 'redux-form'
import { CollapsibleItem } from '../../../../../components/Collapsible'
import AccordionHeader from '../../../../../components/AccordionHeader'
import { APERTURA_GOAL_BASED_PLAN } from '../../../../../store/modules/forms/constants'
import allFields from '../../../fields'
import { loadDefaultFields } from '../../../../ConsulenzaBaseContainerLayout/utils'
import { createPayload, loadServiceData, getIdServizio } from '../../utils'
import ButtonSubmit from '../../../../../components/ButtonSubmit'
import IndirizzoComunicazioni from '../common/IndirizzoComunicazioni'

const fields = allFields[APERTURA_GOAL_BASED_PLAN]
const defaultFields = loadDefaultFields(fields)
const selector = formValueSelector(APERTURA_GOAL_BASED_PLAN)
const idServizio = getIdServizio(APERTURA_GOAL_BASED_PLAN)

const validate = (values, props = {}) => {
  const errors = {}
  const { EMAIL, CELLULARE } = fields

  if (!!values[CELLULARE.id] && !CELLULARE.validazione.test(values[CELLULARE.id])) {
    errors[CELLULARE.id] = 'Numero di cellulare non valido'
  }
  if (!!values[EMAIL.id] && !EMAIL.validazione.test(values[EMAIL.id])) {
    errors[EMAIL.id] = "L'indirizzo e-mail non è valido"
  }

  return errors
}

class GoalBasedPlan extends Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, expanded: false }
    this.isDisabled = this.isDisabled.bind(this)
    this.submit = this.submit.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && !this.props.apiError && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(APERTURA_GOAL_BASED_PLAN)
      this.setState({ saved: true })
    }
  }

  isDisabled(id) {
    const { IMPORTO, ORIGINE } = fields
    const { formValues } = this.props
    switch (id) {
      case ORIGINE.id:
        return !formValues[IMPORTO.id]
      default:
        return false
    }
  }

  submit(values) {
    const { serviceData, onSave } = this.props

    let obTosend = createPayload(fields, values, serviceData, this.state.expanded)
    if (obTosend.idTipologiaConto === null) {
      obTosend.idTipologiaConto = '2'
    }
    onSave(serviceData.idServizio, obTosend)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      getStatus,
      isDirty,
      anyTouched,
      invalid,
      domini,
      formValues,
      formErrors,
      // s,
      change,
      isFetching,
      serviceInformation
    } = this.props
    // const { FASCIA, COMM_ADVISORY } = fields
    // const percComm = percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    // const selectedComm = percComm.find(p => p.codiceCommissione === parseInt(formValues[FASCIA.id])) || {}

    return (
      <CollapsibleItem
        itemKey={APERTURA_GOAL_BASED_PLAN}
        header={serviceInformation[0].descTipoServizio}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, getStatus(this.state.saved))}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <IndirizzoComunicazioni
            fields={fields}
            domini={domini}
            formValues={formValues}
            formErrors={formErrors}
            change={change}
          />
          <ButtonSubmit disabled={submitting || (!isDirty && !anyTouched) || invalid || isFetching} />
        </form>
      </CollapsibleItem>
    )
  }
}

GoalBasedPlan.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  anyTouched: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  domini: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  serviceData: PropTypes.object,
  onSave: PropTypes.func,
  getStatus: PropTypes.func,
  serviceInformation: PropTypes.array
}
console.log(defaultFields)
export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData[`servizio_${idServizio}`],
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    )
    // ...{ [fields.TIPOLOGIA_CONTO.id]: '2' }
  },
  formValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData[`servizio_${idServizio}`],
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    ),
    ...selector(state, ...Object.keys(defaultFields).reduce((res, cur) => [...res, cur], []))
  },
  formErrors: getFormSyncErrors(APERTURA_GOAL_BASED_PLAN)(state),
  isDirty: isDirty(APERTURA_GOAL_BASED_PLAN)(state),
  isFetching: state.services.postServizioInfoRequest.fetching,
  apiError: state.services.postServizioInfoRequest.error,
  serviceInformation: state.pratica.pratica.servizi.filter(item => item.idTipoServizio === idServizio)
}))(
  reduxForm({
    form: APERTURA_GOAL_BASED_PLAN,
    validate,
    shouldError: () => isDirty(APERTURA_GOAL_BASED_PLAN)
  })(GoalBasedPlan)
)
