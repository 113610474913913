import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import {
  MIFID_ANAGRAFICA_PF,
  MIFID_CONOSCENZA,
  MIFID_CONOSCENZA_PF,
  MIFID_CONOSCENZA_PG,
  MIFID_OBIETTIVI,
  MIFID_OBIETTIVI_PF,
  MIFID_OBIETTIVI_PG,
  MIFID_OBIETTIVI_PD,
  MIFID_FINANZIARIA,
  MIFID_FINANZIARIA_PF,
  MIFID_FINANZIARIA_PG,
  MIFID_FINANZIARIA_PD,
  MIFID_TOLLERANZA,
  MIFID_TOLLERANZA_PF,
  MIFID_TOLLERANZA_PG,
  MIFID_TOLLERANZA_PD,
  MIFID_EDUCAZIONE,
  MIFID_EDUCAZIONE_PF,
  MIFID_EDUCAZIONE_PG,
  ANAGRAFICA,
  CONOSCENZA,
  OBIETTIVI,
  FINANZIARIA,
  TOLLERANZA,
  EDUCAZIONE,
  INVESTIMENTI_ESG,
  MIFID_INVESTIMENTI_ESG_PF,
  MIFID_INVESTIMENTI_ESG_PG,
  MIFID_INVESTIMENTI_ESG_PD
} from '../../store/modules/forms/constants'

const routes = [
  {
    index: 0,
    url: 'acquisizione-documenti'
  },
  {
    index: 1,
    url: 'censimento-anagrafico'
  },
  {
    index: 2,
    url: 'questionario-profilatura'
  },
  {
    index: 3,
    url: 'sottoscrizione-servizi'
  },
  {
    index: 4,
    url: 'riepilogo-cliente'
  }
]

export const module_type = {
  ar: 'ar'
}

export const module_flow = {
  sign_room: 'sign_room',
  modifica: 'modifica',
  richiama: 'richiama',
  firma: 'firma'
}

const getValoriDominio = (dominio = {}) => dominio.valoriDominio || {}

export const getDominio = ({ listaDomini = [] }, key) => {
  return getValoriDominio(listaDomini.find(({ idDominio }) => idDominio === key))
}

export const notBelongToDominio = ({ listaDomini = [] }, idDominio, key) => {
  const dominio = listaDomini.find(d => d.idDominio === idDominio)
  return dominio && !getValoriDominio(dominio)[key]
}

export const capitalizeString = (string = '') => {
  return string
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.slice(1))
    .join(' ')
}

/**
 * Check the status of specific serviceName
 */
export const checkStatusService = (services, serviceName) => {
  let servizioAttivo = false
  services.forEach(function(servizio) {
    if (servizio !== null && servizio.name === serviceName) {
      servizioAttivo = servizio.active
    }
  })
  return servizioAttivo
}

/**
 * Maps an array of values to an <options /> array
 */
export const mapDominiToOptions = (dominiList = {}, defaultOption) => {
  const array = []
  if (defaultOption) {
    array.push(
      <option key={-1} value="">
        {' '}
        {'-'}{' '}
      </option>
    )
  }
  return Object.keys(dominiList)
    .sort()
    .reduce((res, key) => {
      return [
        ...res,
        <option key={`${key}`} value={key}>
          {dominiList[key]}
        </option>
      ]
    }, array)
}

/**
 * Maps an array of values to an Object like {Italia:null, "Svizzera:null, "Svezia:null}
 */
export const mapDominiToAutocomplete = dominiList => {
  let dominioAutocompleteList = []
  for (var key in dominiList) {
    if (dominiList.hasOwnProperty(key)) {
      dominioAutocompleteList.push({
        key: dominiList[key],
        value: key,
        label: dominiList[key]
      })
    }
  }
  return dominioAutocompleteList
}

export const mapOrderedDominiToAutocomplete = dominiList => {
  return Object.keys(dominiList)
    .reduce((res, value) => {
      return [
        ...res,
        {
          key: dominiList[value],
          value,
          label: dominiList[value]
        }
      ]
    }, [])
    .sort((a, b) => {
      if (a.key < b.key) return -1
      if (a.key > b.key) return 1
      return 0
    })
}

/**
 * Return the key of the value passed for the specific Domain
 */
export const getKeyDominioFromValue = (dominiList, dominioId, dominioItemValue) => {
  let keyMatch = ''
  dominiList.forEach(function(element) {
    if (element.idDominio === dominioId) {
      for (var key in element.valoriDominio) {
        if (element.valoriDominio.hasOwnProperty(key)) {
          if (element.valoriDominio[key] === dominioItemValue) {
            keyMatch = key
          }
        }
      }
    }
  })
  return keyMatch
}

/**
 * Return the value of the key passed for the specific Domain
 */
export const getValueDominioFromKey = (dominiList, dominioId, dominioItemKey) => {
  let valueMatch = ''
  dominiList.forEach(function(element) {
    if (element.idDominio === dominioId) {
      valueMatch = element.valoriDominio[dominioItemKey]
    }
  })
  return valueMatch
}

export const getAutocompleteDefaultOption = (dominiList, dominioId, dominioItemKey) => {
  return dominiList.reduce(
    (res, cur) => (cur.idDominio === dominioId ? cur.valoriDominio[dominioItemKey] || dominioItemKey : res),
    dominioItemKey
  )
}

/**
 * Maps a boolean value to an arbitrary value
 * @param {*} trueValue the arbitrary value for true
 * @param {*} falseValue the arbitrary value for false
 */
export const booleanToFieldId = (trueValue, falseValue) => {
  return value => {
    if (value === true) {
      return trueValue
    } else if (value === false) {
      return falseValue
    }
  }
}

/**
 * Maps an arbitrary value to a boolean
 * @param {*} trueValue the arbitrary value for true
 * @param {*} falseValue the arbitrary value for false
 */
export const fieldIdToBoolean = (trueValue, falseValue) => {
  return id => {
    if (id === trueValue) {
      return true
    } else if (id === falseValue) {
      return false
    }
  }
}

export const loadQuestions = (previousForm, listaDomande) => {
  if (!previousForm || !previousForm.listaDomande) {
    return []
  }

  return previousForm.listaDomande.reduce((o, domanda) => {
    if (listaDomande) {
      // In the the question can't be found in the template, do not add the question state
      if (!listaDomande.find(domandaTemplate => domandaTemplate.idDomanda === domanda.idDomanda)) {
        return o
      }
    }

    o[domanda.idDomanda] = domanda.listaRisposte.map(risposta => risposta.idRisposta)
    return o
  }, {})
}

// Cleans a state object from all the properties not rearding the questions
export const filterQuestions = formState => {
  return Object.keys(formState)
    .filter(key => !isNaN(parseInt(key)))
    .reduce((o, key) => Object.assign(o, { [key]: formState[key] }), {})
}

export const fieldSpreader = fields => Object.keys(fields).reduce((r, c) => [...r, { fieldName: c, ...fields[c] }], [])

export const getInFormErrors = (errors, fields) => {
  return Object.keys(fields).reduce(
    (r, c) => (fields[c].validazione ? { ...r, [fields[c].id]: errors[fields[c].id] } : r),
    {}
  )
}

/**
 * Recupera gli id delle domande dalla mappa dei fields, e forma un oggetto chiave-valore
 */
export const reduceQuestions = (fields, values) => {
  const spreadFields = fieldSpreader(fields)
  return Object.keys(values).reduce((res, cur) => {
    const spreadField = spreadFields.find(el => el.id === cur)
    let listaRisposte = []
    if (spreadField.isCheckbox) {
      let domandaFound = res.find(domanda => domanda.idDomanda === spreadField.templateId)
      if (domandaFound != null) {
        if (values[cur]) {
          let idRisposta = cur.split('_')[2]
          domandaFound.listaRisposte.push({ [spreadField.propRisposta]: idRisposta })
        }
        return res
      }
    } else {
      listaRisposte.push({ [spreadField.propRisposta]: values[cur] })
    }
    return [
      ...res,
      {
        idDomanda: spreadField.templateId,
        listaRisposte
      }
    ]
  }, [])
}

export const reduceQuestionsAdeguata = (fields, values) => {
  const spreadFields = fieldSpreader(fields)
  return Object.keys(values).reduce((res, cur) => {
    if (values[cur] || values[cur] === 0) {
      const { templateId, propRisposta } = spreadFields.find(el => el.id === cur)
      return [
        ...res,
        {
          idDomanda: templateId,
          listaRisposte: [{ [propRisposta]: values[cur] }]
        }
      ]
    }
    return res
  }, [])
}

export const saveQuestions = listaDomande => {
  const questions = Object.keys(listaDomande).map(idDomanda => {
    const domanda = {
      idDomanda: idDomanda
    }

    const answers = listaDomande[idDomanda]

    if (answers && Array.isArray(answers)) {
      domanda.listaRisposte = answers.filter(a => a).map(a => ({ idRisposta: a }))
    } else {
      domanda.listaRisposte = [
        {
          idRisposta: answers
        }
      ]
    }

    return domanda
  })

  return questions
}

export const updateField = (idDomanda, value) => {
  if (Array.isArray(value)) {
    return {
      dirty: true,
      [idDomanda]: value
    }
  }

  return {
    dirty: true,
    [idDomanda]: [value]
  }
}

export const getFieldValue = (idDomanda, multiValue, state) => {
  if (idDomanda && state[idDomanda] !== undefined) {
    if (multiValue) {
      return state[idDomanda]
    }

    return state[idDomanda][0]
  }

  return undefined
}

export const fieldPropTypes = {
  listaDomande: PropTypes.array,
  field: PropTypes.object,
  domini: PropTypes.object,
  getFieldValue: PropTypes.func,
  updateField: PropTypes.func
}

export const loadDataFatca = (previousForm, fields) => {
  const spreadFields = fieldSpreader(fields)

  if (!previousForm || Object.keys(previousForm).length === 0) {
    return {}
  }
  return spreadFields.reduce((res, cur) => {
    if (cur.lista) {
      if (previousForm[cur.id] && previousForm[cur.id].length > 0) {
        return {
          ...res,
          [cur.id]: previousForm[cur.id].map(el => ({
            codiceNazione: el.codiceNazione.chiave,
            codiceFiscaleEstero: el.codiceFiscaleEstero
          }))
        }
      }
      return res
    }
    return { ...res, [cur.id]: '' + (previousForm[cur.id] === null ? '' : previousForm[cur.id]) }
  }, {})
}

export const loadDataRegistry = (previousForm, fields) => {
  const spreadFields = fieldSpreader(fields)

  if (previousForm && Object.keys(previousForm).length > 0) {
    return spreadFields.reduce((res, cur) => {
      if (cur.fieldName === 'PREFISSO') {
        return {
          ...res,
          [cur.id]: previousForm[cur.id]
        }
      } else {
        return {
          ...res,
          [cur.id]: cur.dominio && previousForm[cur.id] ? previousForm[cur.id].chiave : previousForm[cur.id]
        }
      }
    }, {})
  }
  return {}
}

export const loadContatti = (dataRegistry, fields) => {
  const initialDataRegistry = loadDataRegistry(dataRegistry, fields)
  if (dataRegistry[fields.FLAG_INDIRIZZO.id]) {
    return initialDataRegistry
  } else {
    const fieldKeys = Object.keys(fields)
    return Object.keys(initialDataRegistry).reduce((res, cur) => {
      const k = fieldKeys.find(k => fields[k].id === cur)
      if (fields[k] && fields[k].daResidenza) {
        const residenzaValue = dataRegistry[cur.replace('InvioComunicazioni', 'Residenza')]
        if (residenzaValue && fields[k].dominio) {
          return { ...res, [cur]: residenzaValue.chiave }
        }
        return { ...res, [cur]: residenzaValue }
      } else if (fields[k] && fields[k].daInformazioni) {
        return { ...res, [cur]: dataRegistry[fields[k].dataRegistryId] }
      }
      return { ...res, [cur]: initialDataRegistry[cur] }
    }, {})
  }
}

export const loadSoggettiCorrelati = (dataRegistry, fields) => {
  const initialDataRegistry = loadDataRegistry(dataRegistry, fields)
  if (dataRegistry[fields.FLAG_INDIRIZZO.id]) {
    return initialDataRegistry
  } else {
    const fieldKeys = Object.keys(fields)
    return Object.keys(initialDataRegistry).reduce((res, cur) => {
      const k = fieldKeys.find(k => fields[k].id === cur)
      if (fields[k] && fields[k].daResidenza) {
        const residenzaValue = dataRegistry[cur.replace('InvioComunicazioni', 'Residenza')]
        if (residenzaValue && fields[k].dominio) {
          return { ...res, [cur]: residenzaValue.chiave }
        }
        return { ...res, [cur]: residenzaValue }
      } else if (fields[k] && fields[k].daInformazioni) {
        return { ...res, [cur]: dataRegistry[fields[k].dataRegistryId] }
      }
      return { ...res, [cur]: initialDataRegistry[cur] }
    }, {})
  }
}

export const loadDocumentsData = (fields, documentsData, taxCode = '') => {
  return Object.keys(fields).reduce((res, cur) => {
    const field = fields[cur]
    if (field.isThumb) return res
    if (field.dominio) {
      return {
        ...res,
        [field.id]: documentsData[field.id] ? documentsData[field.id].chiave : field.defaultValue
      }
    } else {
      return {
        ...res,
        [field.id]: documentsData[field.id] || field.defaultValue || taxCode
      }
    }
  }, {})
}
export const loadDocumentsDataPep = (fields, list) => {
  if (list) {
    let obj
    list.forEach(function(owner, index) {
      obj = Object.keys(owner).reduce((res, cur) => {
        return {
          ...res,
          ...obj,
          [`${cur}_${index}`]: list[index][cur] || null
        }
      }, {})
    })
    return obj
  }
}

const parseField = (res = {}, field, values) => {
  if (field.dominio) {
    return {
      ...res,
      [field.id]: {
        codiceDominio: field.dominio,
        chiave: values[field.id]
      }
    }
  }
  return {
    ...res,
    [field.id]: values[field.id]
  }
}

const parseFieldNew = (res = {}, field, values) => {
  return {
    ...res,
    [field.id]: values[field.id]
  }
}

export const retrieveDocumentsDataWithoutImage = (fields, initial, isPG = false) => {
  return Object.keys(fields).reduce((res, cur) => {
    const field = fields[cur]

    if (field.isThumb || field.isImage || (!initial[field.id] || ('' && isPG === false))) return res

    return parseField(res, field, initial) || ''
  }, {})
}

export const retrieveDocumentsDataWithoutDomain = (fields, initial, isPG = false) => {
  return Object.keys(fields).reduce((res, cur) => {
    const field = fields[cur]
    return parseFieldNew(res, field, initial) || ''
  }, {})
}

export const parseDocumentsData = (fields, submittedValues, initialValues) => {
  const listaAllegati = []
  const values = Object.keys(fields).reduce((res, cur) => {
    const field = fields[cur]
    if (field.isThumb) return res
    if (field.isImage) {
      if (submittedValues[field.id] !== initialValues[field.id]) {
        listaAllegati.push({
          file: submittedValues[field.id],
          fileThumb: submittedValues[fields[`${cur}_THUMB`].id],
          tipoAllegato: field.tipoAllegato
        })
      }
      return res
    }
    return parseField(res, field, submittedValues)
  }, {})
  return { values, listaAllegati }
}

export const parseDataRegistry = (fields, dataRegistry) => {
  return Object.keys(fields).reduce((res, cur) => {
    const field = fields[cur]
    if (field.daResidenza || field.daInformazioni) return res
    let tempfield = parseField(res, field, dataRegistry)
    return tempfield
  }, {})
}

export const loadData = (previousForm, fields) => {
  const { listaDomande } = previousForm
  const spreadFields = fieldSpreader(fields)

  if (!listaDomande || !Array.isArray(listaDomande)) {
    return {}
  }
  return listaDomande.reduce((res, cur) => {
    const field = spreadFields.find(el => el.templateId === cur.idDomanda)
    if (field && cur.listaRisposte && Array.isArray(cur.listaRisposte)) {
      if (field.isCheckbox) {
        let ress = cur.listaRisposte.reduce((campo, r) => {
          campo[field.id + '_' + r.idRisposta] = true
          return campo
        }, {})
        return { ...res, ...ress }
      }
      return { ...res, [field.id]: '' + cur.listaRisposte[0][field.propRisposta] }
    }
    return res
  }, {})
}

export const loadAmlData = (previousForm, fields) => {
  const { listaDomande } = previousForm
  const spreadFields = fieldSpreader(fields)

  if (!listaDomande || !Array.isArray(listaDomande)) {
    return []
  }
  return listaDomande.reduce((res, cur) => {
    const field = spreadFields.find(el => el.templateId === cur.idDomanda)
    if (field && cur.listaRisposte && Array.isArray(cur.listaRisposte)) {
      if (field.isCheckbox) {
        return [
          ...res,
          {
            idDomanda: cur.idDomanda,
            listaRisposte: cur.listaRisposte.map(r => {
              return { [field.propRisposta]: r[field.propRisposta] }
            })
          }
        ]
      }
      return [
        ...res,
        {
          idDomanda: cur.idDomanda,
          listaRisposte: [{ [field.propRisposta]: cur.listaRisposte[0][field.propRisposta] }]
        }
      ]
    }
    return res
  }, [])
}

export const loadDefaultFields = fields => {
  return Object.keys(fields).reduce((res, cur) => ({ ...res, [fields[cur].id]: fields[cur].defaultValue }), {})
}

export const loadMifidFields = (fields, template) => {
  return Object.keys(fields).reduce((res, cur) => {
    if (fields[cur].fromTemplate) {
      return {
        ...res,
        ...template.listaDomande
          .find(el => el.idDomanda === fields[cur].templateId)
          .strutturaInternaDomande.domande.reduce((r, c) => {
            if (fields[cur].desinenze) {
              return {
                ...r,
                ...fields[cur].desinenze.map(d => `field_${c.idDomanda}${d}`).reduce((g, h) => ({ ...g, [h]: '' }), {})
              }
            }
            return { ...r, [`field_${c.idDomanda}`]: '' }
          }, {})
      }
    }
    if (fields[cur].innerRisposte) {
      return {
        ...res,
        ...template.listaDomande
          .find(el => el.idDomanda === fields[cur].templateId)
          .listaRisposte.reduce((r, c) => ({ ...r, [`field_${c.idRisposta}`]: '' }), {})
      }
    }
    if (fields[cur].isCheckbox) return res
    return { ...res, [`field_${fields[cur].templateId}`]: fields[cur].defaultValue }
  }, {})
}

export const getSectionStatus = (summaryData = {}, sezione = 0, sottoSezione = 0) => {
  const { sezioni } = summaryData
  if (Array.isArray(sezioni) && typeof sezione === 'string' && typeof sottoSezione === 'string') {
    const sezioneFound = sezioni.find(_sezione => _sezione.codSezione === sezione)
    if (!sezioneFound) {
      return null
    }
    const sottoSezioneFound = sezioneFound.sottoSezioni.find(_sottoSezione => _sottoSezione.codSezione === sottoSezione)
    if (!sottoSezioneFound) {
      return
    }
    return sottoSezioneFound.stato
  }
  if (Array.isArray(sezioni) && sezioni[sezione]) {
    if (Array.isArray(sezioni[sezione].sottoSezioni)) {
      return (sezioni[sezione].sottoSezioni[sottoSezione] || {}).stato
    }
    return sezioni[sezione].stato
  }
  return null
}

export const getSubSectionToBeCompleted = (summaryData = {}, sezione = 0, current = null, reduxForms = {}) => {
  const { sezioni } = summaryData
  /* Da lasciare fino a risoluzione problema navigazione tra accordions */
  // console.log('SEZIONI', sezioni)
  if (Array.isArray(sezioni) && sezioni[sezione] && Array.isArray(sezioni[sezione].sottoSezioni)) {
    const { sottoSezioni } = sezioni[sezione]
    const currentIndex = sottoSezioni.findIndex(s => s.codSezione === current)

    if (currentIndex >= 0 && sottoSezioni[currentIndex].stato !== 'COMPLETATA') {
      return sottoSezioni[currentIndex].codSezione
    } else {
      return (
        sottoSezioni.slice(currentIndex + 1).find(s => s.stato !== 'COMPLETATA' || reduxForms[s.codSezione]) || {}
      ).codSezione
    }
  }
  return null
}

export const getSectionToBeCompleted = (summaryData = {}, currentPath = '', isPG = false) => {
  if (summaryData === null) return routes[0]
  const { percentuale = 0, sezioni = [] } = summaryData
  let isPrevious = false
  const currentIndex = sezioni.slice(0, 4).findIndex((s, index) => currentPath.includes(routes[index].url))
  let index = sezioni.slice(0, 4).reduceRight((res, cur, index) => {
    if (cur.stato !== 'COMPLETATA') {
      if (index > currentIndex) {
        return index
      } else if (index < currentIndex && (!res || isPrevious)) {
        isPrevious = true
        return index
      }
    }
    return res
  }, null)
  if (index === null || index === -1) {
    if (percentuale === 99) {
      if (isPG) {
        index = 3
      } else {
        index = 4
      }
    } else {
      index = currentIndex > -1 ? currentIndex : 0
    }
  }
  return { route: routes[index], isPrevious }
}

export const getUrlFromIndex = index => {
  return (routes.find(r => r.index === index) || {}).url || '/'
}

export const getDominioRequest = (fields, form, deps = []) => {
  return {
    listaDomini: Object.keys(fields[form] || {}).reduce((res, cur) => {
      const filtro = (deps.find(dep => dep.id === fields[form][cur].dominioDep) || {}).filtro
      if (fields[form][cur].dominioDep === null) {
        return [...res, { idDominio: fields[form][cur].dominio }]
      } else if (filtro) {
        return [...res, { idDominio: fields[form][cur].dominio, filtro }]
      }
      return res
    }, [])
  }
}

export const getConsulenzaUrls = () => routes.map(r => r.url)

const generateString = () => {
  let text = ''
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < 10; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return text
}

export const getRandomStr = () => {
  return (localStorage.getItem('randomStr') || '').split(',')[0]
}
export const getToken = () => {
  return (localStorage.getItem('randomToken') || '').split(',')[0]
}

export const setRandomStr = () => {
  localStorage.randomStr = [generateString()].concat((localStorage.randomStr || '').split(',').slice(0, 4))
  localStorage.randomToken = axios.defaults.headers.common['Authorization'].replace('Bearer ', '')
}
export const setPromoterId = id => {
  localStorage.promoterId = id
}
export const getPromoterId = () => {
  return (localStorage.getItem('promoterId') || '').split(',')[0]
}

export const checkRandomStr = str => {
  return (localStorage.promoterId || '').split(',').some(r => r === str)
}

export const getPromotore = () => {
  return localStorage.getItem('promotore')
}

export const setPromotore = idPromotore => {
  localStorage.promotore = idPromotore
  localStorage.randomToken = axios.defaults.headers.common['Authorization'].replace('Bearer ', '')
  localStorage.randomS = axios.defaults.headers.common['s']
  localStorage.randomP = axios.defaults.headers.common['p']
}

export const checkPromotore = idPromotore => {
  return localStorage.promotore === idPromotore
}

export const getInitialValues = (fields = {}, data = {}) => {
  return Object.keys(fields).reduce((res, cur) => {
    const field = fields[cur]
    // if((field.id === 'isOwner' ||field.id === 'isUniqueOwner' ) && data[field.id]!== null && data[field.id]!== undefined ){
    //   return {
    //     ...res,
    //     [field.id]: data[field.id] || field.defaultValue
    //     // [field.id]: { idRisposta: data[field.id] }
    //   }
    // } else {

    // }
    return {
      ...res,
      [field.id]: data[field.id] || field.defaultValue
    }
  }, {})
}

export const getInitialValuesPep = (fields = {}, data = {}) => {
  if (data == null) {
    return
  }
  return Object.keys(fields).reduce((res, cur) => {
    const field = fields[cur]
    let fieldTempId = field.id.split('_')
    if (data[fieldTempId[0]] !== null && data[fieldTempId[0]] !== undefined) {
      // console.log([field.id], data[fieldTempId[0]])
      return {
        ...res,
        [field.id]: data[fieldTempId[0]]
      }
      //  console.log(objToSend)
      // return {
      //  ...objToSend
      // }
    }
  }, {})
}

export const mapGroupBy = (arr, prop) => {
  const map = new Map(Array.from(arr, obj => [obj[prop], []]))
  arr.forEach(obj => map.get(obj[prop]).push(obj))
  return Array.from(map.values())
}

export const objGroupBy = (arr, prop) =>
  arr.reduce((objectsByKeyValue, obj) => {
    const value = obj[prop]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

// Default tipo MIFID
let MIFID_TYPE = 'PF'

// Costanti MIFID
export const MIFID_CONST = {
  ANAGRAFICA,
  CONOSCENZA,
  OBIETTIVI,
  FINANZIARIA,
  TOLLERANZA,
  EDUCAZIONE,
  INVESTIMENTI_ESG,
  PF: 'PF',
  PG: 'PG',
  PD: 'PD'
}

// utility per impostare il tipo di MIFID
export const setActiveMifid = type => (MIFID_TYPE = type)

// utility per ottenere gli ID MIFID a seconda del tipo
export const getActiveMifid = () => {
  switch (MIFID_TYPE) {
    // Nuova MIFID PF
    case 'PF':
      return {
        ANAGRAFICA: 'MIFID_ANAGRAFICA_PF',
        CONOSCENZA: 'MIFID_CONOSCENZA_PF',
        OBIETTIVI: 'MIFID_OBIETTIVI_PF',
        FINANZIARIA: 'MIFID_FINANZIARIA_PF',
        TOLLERANZA: 'MIFID_TOLLERANZA_PF',
        EDUCAZIONE: 'MIFID_EDUCAZIONE_PF',
        INVESTIMENTI_ESG: 'MIFID_INVESTIMENTI_ESG_PF'
      }
    // Nuova MIFID PG
    case 'PG':
      return {
        ANAGRAFICA: 'MIFID_ANAGRAFICA_PF',
        CONOSCENZA: 'MIFID_CONOSCENZA_PG',
        OBIETTIVI: 'MIFID_OBIETTIVI_PG',
        FINANZIARIA: 'MIFID_FINANZIARIA_PG',
        TOLLERANZA: 'MIFID_TOLLERANZA_PG',
        EDUCAZIONE: 'MIFID_EDUCAZIONE_PG',
        INVESTIMENTI_ESG: 'MIFID_INVESTIMENTI_ESG_PG'
      }
    // Nuova MIFID PD
    case 'PD':
      return {
        ANAGRAFICA: 'MIFID_ANAGRAFICA_PF',
        CONOSCENZA: 'MIFID_CONOSCENZA_PF',
        OBIETTIVI: 'MIFID_OBIETTIVI_PD',
        FINANZIARIA: 'MIFID_FINANZIARIA_PD',
        TOLLERANZA: 'MIFID_TOLLERANZA_PD',
        EDUCAZIONE: 'MIFID_EDUCAZIONE_PF',
        INVESTIMENTI_ESG: 'MIFID_INVESTIMENTI_ESG_PD'
      }
    // Vecchia MIFID
    default:
      return {
        ANAGRAFICA: 'MIFID_ANAGRAFICA_PF',
        CONOSCENZA: 'MIFID_CONOSCENZA',
        OBIETTIVI: 'MIFID_OBIETTIVI',
        FINANZIARIA: 'MIFID_FINANZIARIA',
        TOLLERANZA: 'MIFID_TOLLERANZA',
        EDUCAZIONE: 'MIFID_EDUCAZIONE'
      }
  }
}

export const getActiveMifidSection = () => {
  switch (MIFID_TYPE) {
    case 'PF':
      return 'MIFID_PF'
    case 'PG':
      return 'MIFID_PG'
    case 'PD':
      return 'MIFID_PD'
    default:
      return 'MIFID'
  }
}

export const getActiveMifidSectionSummaryData = () => {
  switch (MIFID_TYPE) {
    case 'PF':
      return 'MIFID_PF'
    case 'PG':
    case 'PD':
      return 'MIFID_PG'
    default:
      return 'MIFID'
  }
}

// Utility per ottenere la variabile contenente l'ID della MIFID attiva
export const getMifidByCategory = category => {
  let _map = {
    MIFID_ANAGRAFICA_PF,
    MIFID_CONOSCENZA,
    MIFID_CONOSCENZA_PF,
    MIFID_CONOSCENZA_PG,
    MIFID_OBIETTIVI,
    MIFID_OBIETTIVI_PF,
    MIFID_OBIETTIVI_PG,
    MIFID_OBIETTIVI_PD,
    MIFID_FINANZIARIA,
    MIFID_FINANZIARIA_PF,
    MIFID_FINANZIARIA_PG,
    MIFID_FINANZIARIA_PD,
    MIFID_TOLLERANZA,
    MIFID_TOLLERANZA_PF,
    MIFID_TOLLERANZA_PG,
    MIFID_TOLLERANZA_PD,
    MIFID_EDUCAZIONE,
    MIFID_EDUCAZIONE_PF,
    MIFID_EDUCAZIONE_PG,
    MIFID_INVESTIMENTI_ESG_PF,
    MIFID_INVESTIMENTI_ESG_PG,
    MIFID_INVESTIMENTI_ESG_PD
  }
  let mifid = getActiveMifid()
  return _map[mifid[category]]
}

export const getQueryParams = search => {
  return new URLSearchParams(search)
}
