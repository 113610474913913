import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { bindActionCreators } from 'redux'
import { Field, reduxForm, getFormValues, getFormSyncErrors } from 'redux-form'
import { connect } from 'react-redux'
import { Col, Icon } from 'react-materialize'
import { FORM_ADD_DEROGA } from '../../../store/modules/forms/constants'
import { DropdownField } from '../../Field/renderFieldsComponents'
import { mapDominiToOptions, getDominio } from '../../../services/utils'
import derogaFields from '../../../config/fields'

const fields = derogaFields[0][FORM_ADD_DEROGA]

const validate = (values, props = {}) => {
  const errors = {}
  const { TIPOLOGIA_SERVIZIO, FASCIA_INVESTIMENTO, FASCIA_DEROGA, CLUSTER } = fields
  if (values[TIPOLOGIA_SERVIZIO.id] === '-' || !values[TIPOLOGIA_SERVIZIO.id]) {
    errors[TIPOLOGIA_SERVIZIO.id] = 'Seleziona un servizio'
  }
  if (values[TIPOLOGIA_SERVIZIO.id] === '1') {
    if (values[FASCIA_INVESTIMENTO.id] === '-' || !values[FASCIA_INVESTIMENTO.id]) {
      errors[FASCIA_INVESTIMENTO.id] = 'Seleziona una fascia di investimento'
    }
    if (values[FASCIA_INVESTIMENTO.id] === '-' || !values[FASCIA_DEROGA.id]) {
      errors[FASCIA_DEROGA.id] = 'Seleziona una fascia di deroga'
    }
  }
  if (values[TIPOLOGIA_SERVIZIO.id] === '4') {
    if (values[CLUSTER.id] === '-' || !values[CLUSTER.id]) {
      errors[CLUSTER.id] = 'Seleziona una cluster'
    }
  }
  return errors
}

class DerogaCommissionaleForm extends Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  componentDidMount() {
    const { initialize, forms } = this.props
    if (forms && forms.FORM_ADD_DEROGA && Object.keys(forms.FORM_ADD_DEROGA).length > 0) {
      initialize(forms.FORM_ADD_DEROGA)
    }
  }

  onChange() {
    const { change } = this.props
    change(fields.FASCIA_INVESTIMENTO.id, null)
    change(fields.FASCIA_DEROGA.id, null)
    change(fields.CLUSTER.id, null)
    change(fields.TIPOLOGIA_STRUM_FINANZIARIO.id, null)
  }

  renderCaseMax() {
    const { FASCIA_INVESTIMENTO, FASCIA_DEROGA } = fields
    const { formValues, domini } = this.props
    return (
      <Fragment>
        <Field
          name={FASCIA_INVESTIMENTO.id}
          label={FASCIA_INVESTIMENTO.label}
          component={DropdownField}
          options={mapDominiToOptions(getDominio(domini, FASCIA_INVESTIMENTO.dominio), true)}
        />
        <Field
          name={FASCIA_DEROGA.id}
          label={FASCIA_DEROGA.label}
          component={DropdownField}
          options={mapDominiToOptions(getDominio(domini, FASCIA_DEROGA.dominio), true)}
        />
        <Col s={12} className="left-align deroga-alert">
          {formValues[FASCIA_DEROGA.id] === '1' ? (
            <span>
              <Icon>warning</Icon>LA RICHIESTA VERRA' INVIATA AL MANAGER
            </span>
          ) : null}
          {formValues[FASCIA_DEROGA.id] === '2' ? (
            <span>
              <Icon>warning</Icon>LA RICHIESTA VERRA' INVIATA AL AD/RESP. DIVISIONE
            </span>
          ) : null}
        </Col>
      </Fragment>
    )
  }

  renderCaseRto() {
    const { CLUSTER, TIPOLOGIA_STRUM_FINANZIARIO } = fields
    const { domini } = this.props
    return (
      <Fragment>
        <Field
          name={CLUSTER.id}
          label={CLUSTER.label}
          component={DropdownField}
          options={mapDominiToOptions(getDominio(domini, CLUSTER.dominio), true)}
        />
        <Field
          name={TIPOLOGIA_STRUM_FINANZIARIO.id}
          label={TIPOLOGIA_STRUM_FINANZIARIO.label}
          component={DropdownField}
          options={mapDominiToOptions(getDominio(domini, TIPOLOGIA_STRUM_FINANZIARIO.dominio), true)}
        />
      </Fragment>
    )
  }

  render() {
    const { formValues, domini } = this.props
    const { TIPOLOGIA_SERVIZIO } = fields

    return (
      <Fragment>
        <form noValidate>
          <Field
            name={TIPOLOGIA_SERVIZIO.id}
            label={TIPOLOGIA_SERVIZIO.label}
            component={DropdownField}
            options={mapDominiToOptions(getDominio(domini, TIPOLOGIA_SERVIZIO.dominio), true)}
            onChange={() => {
              this.onChange()
            }}
          />
          {formValues && formValues[TIPOLOGIA_SERVIZIO.id] === '1' && this.renderCaseMax()}
          {formValues && formValues[TIPOLOGIA_SERVIZIO.id] === '4' && this.renderCaseRto()}
        </form>
      </Fragment>
    )
  }
}
DerogaCommissionaleForm.propTypes = {
  formValues: PropTypes.object,
  initialize: PropTypes.func,
  forms: PropTypes.object,
  change: PropTypes.func.isRequired,
  domini: PropTypes.object
}

const mapStateToProps = state => {
  return {
    initialValues: {},
    formValues: getFormValues(FORM_ADD_DEROGA)(state),
    formErrors: getFormSyncErrors(FORM_ADD_DEROGA)(state),
    forms: state.forms,
    domini: state.dominio.domini
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // TODO
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: FORM_ADD_DEROGA,
    validate
  })(DerogaCommissionaleForm)
)
