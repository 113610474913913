import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AdeguataVerificaPgComponent from './AdeguataVerificaPgComponent'
import { getAdegVerTemplatePg, saveAdegVer, importSurveyExtAV } from '../../store/modules/survey/actions'
import { getPratica } from '../../store/modules/pratica/actions'
import { downloadPDF } from '../../store/modules/utility/actions'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAdegVerTemplatePg,
      saveAdegVer,
      getPratica,
      downloadPDF,
      importSurveyExtAV
    },
    dispatch
  )

const mapStateToProps = state => {
  return {
    customer: state.customers.customer,
    template: state.survey.templatePGAV,
    adegVerSaveRequest: state.survey.adegVerSaveRequest,
    intestatari: state.pratica.pratica.intestatari,
    servizi: state.pratica.pratica.servizi,
    praticaRequest: state.pratica.praticaRequest,
    idPratica: state.pratica.idPratica
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdeguataVerificaPgComponent)
