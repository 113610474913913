import * as actionTypes from './constants'

import axios from 'axios'

export function dominio(queryParam, background) {
  return async function(dispatch, getState) {
    const query = {
      ...queryParam
    }

    if (!query || !query.listaDomini) {
      return
    }

    let { listaDomini } = queryParam

    const stateDominio = getState().dominio
    const stateDomini = stateDominio.domini
    const stateFiltri = stateDominio.filtri

    // There are some domini on the state
    if (stateDomini) {
      if (stateDomini.listaDomini) {
        const stateListaDomini = stateDomini.listaDomini

        listaDomini = listaDomini.filter(dominio => {
          if (dominio.filtro && !dominio.filtro.codice) {
            return false
          }
          if (stateListaDomini.find(stateDominio => stateDominio.idDominio === dominio.idDominio)) {
            // The same dominio is already on the state, don't fetch it again unless the filter is different

            if (dominio.filtro) {
              // The dominio is already on the state, but the query is different
              if (stateFiltri[dominio.idDominio] !== dominio.filtro.codice) {
                return true
              }
            }

            // The dominio hasn't a filter, it must not be fetched again
            return false
          }

          // This domain is not already present in state
          return true
        })
      }
    }

    dispatch({
      type: background ? actionTypes.DOMINIO_BG_START : actionTypes.DOMINIO_START,
      query: {
        listaDomini
      }
    })
    if (listaDomini.length === 0) {
      setTimeout(() => {
        dispatch({
          type: background ? actionTypes.DOMINIO_BG_SUCCESS : actionTypes.DOMINIO_SUCCESS,
          dominio: { listaDomini: [] }
        })
      }, 0)
    } else {
      try {
        const response = await axios.post('/utility/dominio', {
          listaDomini
        })
        dispatch({
          type: background ? actionTypes.DOMINIO_BG_SUCCESS : actionTypes.DOMINIO_SUCCESS,
          dominio: response.data
        })
      } catch (error) {
        dispatch({
          type: background ? actionTypes.DOMINIO_BG_ERROR : actionTypes.DOMINIO_ERROR,
          error
        })
      }
    }
  }
}

export const dominioActionCreator = (queryParam, background) => {
  return async function(dispatch, getState) {
    const query = {
      ...queryParam
    }

    if (!query || !query.listaDomini) {
      return
    }

    let { listaDomini } = queryParam
    const stateDominio = getState().dominio
    const stateDomini = stateDominio.domini
    const stateFiltri = stateDominio.filtri

    // There are some domini on the state
    if (stateDomini) {
      if (stateDomini.listaDomini) {
        const stateListaDomini = stateDomini.listaDomini

        listaDomini = listaDomini.filter(dominio => {
          if (dominio.filtro && !dominio.filtro.codice) {
            return false
          }
          if (stateListaDomini.find(stateDominio => stateDominio.idDominio === dominio.idDominio)) {
            // The same dominio is already on the state, don't fetch it again unless the filter is different

            if (dominio.filtro) {
              // The dominio is already on the state, but the query is different
              if (stateFiltri[dominio.idDominio] !== dominio.filtro.codice) {
                return true
              }
            }

            // The dominio hasn't a filter, it must not be fetched again
            return false
          }

          // This domain is not already present in state
          return true
        })
      }
    }

    dispatch({
      type: background ? actionTypes.DOMINIO_BG_START : actionTypes.DOMINIO_START,
      query: {
        listaDomini
      }
    })
    if (listaDomini.length === 0) {
      setTimeout(() => {
        dispatch({
          type: background ? actionTypes.DOMINIO_BG_SUCCESS : actionTypes.DOMINIO_SUCCESS,
          dominio: { listaDomini: [] }
        })
      }, 0)
    } else {
      try {
        const response = await axios.post('/utility/dominio/', {
          listaDomini
        })
        dispatch({
          type: background ? actionTypes.DOMINIO_BG_SUCCESS : actionTypes.DOMINIO_SUCCESS,
          dominio: response.data
        })
      } catch (error) {
        dispatch({
          type: background ? actionTypes.DOMINIO_BG_ERROR : actionTypes.DOMINIO_ERROR,
          error
        })
      }
    }
  }
}
