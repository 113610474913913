import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import PropTypes from 'prop-types'
import ConsulenzaBaseContainerLayout from '../routes/ConsulenzaBaseContainerLayout/ConsulenzaBaseContainerLayout'
import { Home } from '../routes/Home'
import AperturaServiziContainerLayout from '../routes/AperturaServiziContainerLayout/AperturaServiziContainerLayout'
import { AdeguataVerifica } from '../routes/AdeguataVerifica'
import { AdeguataVerificaPg } from '../routes/AdeguataVerificaPg'
import { ConsulenzaBase } from '../routes/ConsulenzaBase'
import { Firma } from '../routes/Firma'
import { FirmaCartacea } from '../routes/FirmaCartacea'
import { FirmaWrapper } from '../routes/FirmaWrapper'
import { Dashboard } from '../routes/Dashboard'
import Loading from '../components/Loading'
import ModalError from '../components/Modals/Errors'
import ConsulenzaBaseContainerLayoutPersonaGiuridica from '../routes/ConsulenzaBaseContainerLayout/ConsulenzaBaseContainerLayoutPersonaGiuridica'
import DerogaProcess from '../routes/DerogaProcess'
import AggiornamentoModulisticaContainerLayout from '../routes/AggiornamentoModulisticaContainerLayout/AggiornamentoModulisticaContainerLayout'

class App extends React.Component {
  shouldComponentUpdate() {
    return false
  }
  render() {
    return (
      <Provider store={this.props.store}>
        <div style={{ height: '100%' }}>
          <Loading />
          <ModalError />
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/consulenza-base/create" component={ConsulenzaBase} />
              <Route path="/consulenza-base-PG/create" component={ConsulenzaBase} />
              <Route path="/consulenza-base/:idPratica" component={ConsulenzaBaseContainerLayout} />
              <Route path="/consulenza-base-PG/:idPratica" component={ConsulenzaBaseContainerLayoutPersonaGiuridica} />
              <Route path="/apertura-servizi" component={AperturaServiziContainerLayout} />
              <Route path="/adeguata-verifica" component={AdeguataVerifica} />
              <Route path="/adeguata-verifica-PG" component={AdeguataVerificaPg} />
              <Route path="/firma-digitale/:id" component={Firma} />
              <Route path="/firma-cartacea/:id" component={FirmaCartacea} />
              <Route path="/firma-namiral" component={FirmaWrapper} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/deroga-process/:idCustomer" component={DerogaProcess} />
              <Route path="/aggiornamento-modulistica" component={AggiornamentoModulisticaContainerLayout} />
            </Switch>
          </Router>
        </div>
      </Provider>
    )
  }
}
App.propTypes = {
  store: PropTypes.object.isRequired
}
export default App
