import * as actionTypes from './constants'

export function setToggleBlur(value) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SET_DATA_TOGGLE_BLUR,
      toggleBlur: value
    })
  }
}
