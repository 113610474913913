import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Field } from 'redux-form'
import { Input, Col, Row } from 'react-materialize'
import AccordionHeader from '../../../components/AccordionHeader'
import { renderInputImage, renderAutocomplete, renderCheckboxRadio } from '../../../components/Forms/renderFields'
import dataSB from '../../../static-data/data-servizio-base.json'
import ModalGeneric from '../../../components/ModalGeneric'
import {
  checkStatusService,
  getSectionStatus,
  getAutocompleteDefaultOption,
  mapOrderedDominiToAutocomplete,
  getDominio
} from '../utils'
import ButtonSubmit from '../../../components/Buttons/Submit'
import FirmaFooter from '../../../components/FirmaFooter'
import dataMain from '../../../static-data/data-main.json'

const { check_intestatari } = dataMain

class Sottoscrizione extends Component {
  constructor(props) {
    super(props)

    const {
      summaryData: { statusEndPoint },
      touch,
      fields
    } = props
    if (!checkStatusService(statusEndPoint, 'servdig')) {
      touch(fields.SERVIZIO.id)
    }
    this.state = {
      saved: false,
      flagModuloFirmato: false,
      firmaOra: false,
      firmaOptions: mapOrderedDominiToAutocomplete(getDominio(this.props.domini, fields.COMUNE_FIRMA.dominio))
    }

    this.submit = this.submit.bind(this)
    this.checkFirma = this.checkFirma.bind(this)
    this.updateStatus = this.updateStatus.bind(this)
    this.isValideKey = this.isValideKey.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }
  componentDidMount() {
    this.setState({ flagModuloFirmato: this.props.serviziData.flagModuloFirmato })
    this.props.change(this.props.fields.CHECK_MODULO.id, this.props.serviziData.flagModuloFirmato)
    this.props.untouch(this.props.fields.CHECK_MODULO.id)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      const {
        formValues,
        goToWhenSaved,
        initialize,
        summaryData: { sezioni }
      } = this.props
      initialize(formValues)
      this.setState({ saved: true })
      if (sezioni[3].stato === 'COMPLETATA') {
        if (location.pathname.indexOf('-PG') === -1) {
          goToWhenSaved()
        }
      }
    }
    if (
      prevProps.updatePraticaRequest.fetching &&
      !this.props.updatePraticaRequest.fetching &&
      this.props.updatePraticaRequest.error === null
    ) {
      if (this.state.firmaOra) {
        // TODO
        // history.push(`/firma/${this.props.customer.idIncarico}`)
        this.props.history.push(`/firma-cartacea/${this.props.customer.idIncarico}`)
      } else {
        this.props.history.push('/')
      }
    }
  }

  isValideKey(value) {
    let bool = this.state.firmaOptions.find(el => el.value === value)
    return bool
  }
  checkFirma(value) {
    this.setState({ firmaOra: !value })
  }
  closeModal() {
    this.setState({ showModalGeneric: false })
  }
  updateStatus() {
    const {
      customer: { idIncarico },
      updateStatoPratica,
      isAdmin,
      isProspectPersonaGiuridica
    } = this.props

    if (isAdmin && isProspectPersonaGiuridica) {
      this.setState({ textModal: 'firma', showModalGeneric: true })
    } else {
      updateStatoPratica(idIncarico, 2, true)
    }
  }

  submit(values) {
    const {
      customer: { idCliente, idIncarico },
      postServiziDigitali,
      postServiziDigitaliPG,
      // summaryData: { statusEndPoint },
      fields,
      formValues,
      change
    } = this.props
    const {
      COMUNE_FIRMA,
      SERVIZIO: { id, hiddenId },
      CHECK_MODULO
    } = fields

    if (!this.props.isProspectPersonaGiuridica) {
      postServiziDigitali(
        idCliente,
        {
          ...Object.keys(formValues).reduce((res, cur) => {
            if (cur === COMUNE_FIRMA.id) {
              if (this.isValideKey(formValues[cur])) {
                return {
                  ...res,
                  [cur]: {
                    codiceDominio: COMUNE_FIRMA.dominio,
                    chiave: formValues[cur] || ''
                  }
                }
              } else {
                change(fields.COMUNE_FIRMA.id, '')
                return { ...res, [cur]: null }
              }
            }
            return { ...res, [cur]: formValues[cur] }
          }, {}),
          idIncarico,
          [id]: values[id] === 'true',
          [hiddenId]: values[id] !== 'true'
        }
        // checkStatusService(statusEndPoint, 'servdig')
      )
    } else {
      postServiziDigitaliPG(
        idCliente,
        {
          ...Object.keys(formValues).reduce((res, cur) => {
            if (cur === COMUNE_FIRMA.id) {
              return {
                ...res,
                [cur]: {
                  codiceDominio: COMUNE_FIRMA.dominio,
                  chiave: formValues[cur] || ''
                }
              }
            }
            return { ...res, [cur]: formValues[cur] }
          }, {}),
          idIncarico,
          // flagModuloFirmato: formValues.flagModuloFirmato,
          flagModuloFirmato: formValues[CHECK_MODULO.id],
          // flagModuloFirmato: this.state.flagModuloFirmato,
          [id]: formValues[id] === 'true',
          [hiddenId]: formValues[id] !== 'true'
        }

        // checkStatusService(statusEndPoint, 'servdig')
      )
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      anyTouched,
      invalid,
      fields,
      change,
      summaryData,
      touch,
      formErrors,
      serviziData,
      dataRegistry = {},
      domini,
      isFetching,
      formValues,
      downloadSignModule,
      idPratica
    } = this.props
    const { SERVIZIO, MODULO, MODULO_THUMB, COMUNE_FIRMA, CHECK_MODULO } = fields
    const { servizi } = dataSB
    const getCellFromDataRegistry = () => {
      const { internationalPrefixCellNumberCode, numeroCellulare = '' } = dataRegistry
      if (numeroCellulare && internationalPrefixCellNumberCode) {
        return `${numeroCellulare}`
      }
      return ''
    }
    return (
      <section className="main-body">
        {this.state.showModalGeneric && (
          <ModalGeneric
            show={this.state.showModalGeneric}
            handler={this.closeModal}
            title="Attenzione!"
            text={check_intestatari[this.state.textModal]}
          />
        )}
        <article className="page-left-content">
          <div className="background_pattern" />
          <h2 className="title-sezione">{servizi.title_section}</h2>
          <form onSubmit={handleSubmit(this.submit)} noValidate>
            <div className="box-container sottoscrizione-servizi">
              <div className="section-status">
                {AccordionHeader(isDirty, this.state.saved, getSectionStatus(summaryData, 3))}
              </div>
              <h2 className="collapsible-section-title">{servizi.servizi_investimento.title_subsection}</h2>
              <Row>
                <Col s={12}>
                  <div className="sezione-servizi-investimento">
                    <p>{servizi.servizi_investimento.radio_tipologia_servizio.title}</p>
                    <div className="radio-horizontal">
                      <p>
                        <Field
                          name={SERVIZIO.id}
                          className="with-gap"
                          type="radio"
                          id="consulenza"
                          value="true"
                          component="input"
                        />
                        <label htmlFor="consulenza">
                          {servizi.servizi_investimento.radio_tipologia_servizio.options[0].label}
                        </label>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <span>
                {!this.props.isProspectPersonaGiuridica && (
                  <h2 className="collapsible-section-title">{servizi.servizi_digitali.title_subsection}</h2>
                )}
                {this.props.isProspectPersonaGiuridica && (
                  <h2 className="collapsible-section-title">{servizi.servizi_digitali.title_subsection_pg}</h2>
                )}
                {!this.props.isProspectPersonaGiuridica && (
                  <span
                    style={{
                      color: '#003366'
                    }}>
                    {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'} {'\u00A0'}
                    {'\u00A0'} Il cliente dichiara di aderire ai <b>Servizi Digitali</b>
                  </span>
                )}
              </span>
              <div className="sezione-servizi-digitali">
                <Row>
                  <Col s={6} className="no-padding-left">
                    <p className="padding-l-10">{dataSB.servizi.servizi_digitali.label_contatti}</p>

                    {!this.props.isProspectPersonaGiuridica && (
                      <Fragment>
                        <Input
                          s={12}
                          label="Cellulare"
                          disabled
                          value={serviziData.cellulare || getCellFromDataRegistry()}
                          defaultValue={serviziData.cellulare || getCellFromDataRegistry()}
                        />

                        <Input
                          s={12}
                          label="Email"
                          disabled
                          defaultValue={serviziData.mail || dataRegistry.email}
                          value={serviziData.mail || dataRegistry.email}
                        />
                      </Fragment>
                    )}
                    {this.props.isProspectPersonaGiuridica && (
                      <Fragment>
                        {!serviziData.cellulare && (
                          <Input
                            s={12}
                            label="Cellulare"
                            disabled
                            value={serviziData.cellulare || getCellFromDataRegistry()}
                            defaultValue={serviziData.cellulare || getCellFromDataRegistry()}
                          />
                        )}

                        {!!serviziData.cellulare && (
                          <div>
                            <label htmlFor={'cellulare'} className="label-left">
                              Cellulare
                            </label>
                            <Input
                              s={12}
                              id={'cellulare'}
                              disabled
                              value={serviziData.cellulare || getCellFromDataRegistry()}
                              defaultValue={serviziData.cellulare || getCellFromDataRegistry()}
                            />
                          </div>
                        )}

                        {!serviziData.mail && (
                          <Input
                            s={12}
                            label="Email"
                            disabled
                            defaultValue={serviziData.mail || dataRegistry.email}
                            value={serviziData.mail || dataRegistry.email}
                          />
                        )}

                        {!!serviziData.mail && (
                          <div>
                            <label htmlFor={'Email'} className="label-left">
                              Email
                            </label>
                            <Input
                              s={12}
                              id={'Email'}
                              disabled
                              defaultValue={serviziData.mail || dataRegistry.email}
                              value={serviziData.mail || dataRegistry.email}
                            />
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Col>
                  {!this.props.isProspectPersonaGiuridica && (
                    <Col s={6}>
                      <Fragment>
                        <p className="margin-b-none">{dataSB.servizi.servizi_digitali.label_modulo}</p>
                        <p className="suggestion-text">{dataSB.servizi.servizi_digitali.suggestion_modulo}</p>
                        <Field
                          name={MODULO.id}
                          idImg={MODULO.id}
                          fileIn={formValues[MODULO_THUMB.id]}
                          onChangeHandler={(file, fileThumb) => {
                            change(MODULO.id, file)
                            change(MODULO_THUMB.id, fileThumb)
                          }}
                          component={renderInputImage}
                        />
                        <p className="margin-t-none text-center">
                          <a onClick={() => downloadSignModule(idPratica)}>
                            {dataSB.servizi.servizi_digitali.downloadSignModule} <i className="icon-printer" />
                          </a>
                        </p>
                      </Fragment>
                    </Col>
                  )}
                  {this.props.isProspectPersonaGiuridica && (
                    <Col s={5} className={'paddingTop86'}>
                      <p className="margin-b-none textModuloFirma">Modulo di firma*</p>
                      <Field
                        name={CHECK_MODULO.id}
                        checked={formValues[CHECK_MODULO.id]}
                        value={formValues[CHECK_MODULO.id]}
                        label={CHECK_MODULO.label}
                        change={change}
                        touch={touch}
                        type="checkbox"
                        component={renderCheckboxRadio}
                      />
                      <p className="margin-t-none text-center">
                        <a onClick={() => downloadSignModule(idPratica)}>
                          {dataSB.servizi.servizi_digitali.downloadSignModule} <i className="icon-printer" />
                        </a>
                      </p>
                    </Col>
                  )}
                </Row>

                <Row>
                  <Col s={6}>
                    {/* // {this.props.url === `/consulenza-base/${this.props.idPratica}` && ( */}
                    <Field
                      name={COMUNE_FIRMA.id}
                      s={12}
                      threshold={0}
                      label={COMUNE_FIRMA.label}
                      error={formErrors[COMUNE_FIRMA.id]}
                      defaultValue={getAutocompleteDefaultOption(
                        domini.listaDomini,
                        COMUNE_FIRMA.dominio,
                        formValues[COMUNE_FIRMA.id]
                      )}
                      options={this.state.firmaOptions}
                      handleChange={(field, value) => change(field, value)}
                      component={renderAutocomplete}
                    />
                    {/* )} */}
                  </Col>
                </Row>
              </div>
              <div className="form-footer">
                <ButtonSubmit disabled={submitting || (!isDirty && !anyTouched) || invalid || isFetching} />
              </div>
            </div>
          </form>
          {this.props.summaryData.percentuale === 99 && this.props.isProspectPersonaGiuridica && (
            <FirmaFooter
              dinamicClass="firmaSottoscrizione"
              openModal={this.updateStatus}
              call_back={this.checkFirma}
              onSaveText="Sarà possibile firmare in modalità cartacea"
            />
          )}

          <Row className="right-align padding-r-20 margin-t-20">
            <a onClick={() => this.props.goToWhenSaved()}>
              {dataSB.link_continue} <i className="icon-arrow" />
            </a>
          </Row>
        </article>
      </section>
    )
  }
}

Sottoscrizione.propTypes = {
  customer: PropTypes.object,
  serviziData: PropTypes.object,
  dataRegistry: PropTypes.object,
  summaryData: PropTypes.object,
  postServiziDigitali: PropTypes.func,
  postServiziDigitaliPG: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  formValues: PropTypes.object,
  fields: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  change: PropTypes.func,
  goToWhenSaved: PropTypes.func.isRequired,
  touch: PropTypes.func,
  anyTouched: PropTypes.bool,
  isAdmin: PropTypes.bool,
  domini: PropTypes.object,
  // url: PropTypes.any,
  idPratica: PropTypes.any,
  isProspectPersonaGiuridica: PropTypes.bool,
  untouch: PropTypes.func,
  updateStatoPratica: PropTypes.func,
  history: PropTypes.object,
  updatePraticaRequest: PropTypes.func,
  formErrors: PropTypes.any,
  downloadSignModule: PropTypes.func
}

export default Sottoscrizione
