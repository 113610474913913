export const UTILITY_GET_FIRMA_START = 'UTILITY_GET_FIRMA_START'
export const UTILITY_GET_FIRMA_SUCCESS = 'UTILITY_GET_FIRMA_SUCCESS'
export const UTILITY_GET_FIRMA_ERROR = 'UTILITY_GET_FIRMA_ERROR'

export const UTILITY_SAVE_DOC_START = 'UTILITY_SAVE_DOC_START'
export const UTILITY_SAVE_DOC_SUCCESS = 'UTILITY_SAVE_DOC_SUCCESS'
export const UTILITY_SAVE_DOC_ERROR = 'UTILITY_SAVE_DOC_ERROR'

export const UTILITY_GET_NAMIRIAL_URL_START = 'UTILITY_GET_NAMIRIAL_URL_START'
export const UTILITY_GET_NAMIRIAL_URL_SUCCESS = 'UTILITY_GET_NAMIRIAL_URL_SUCCESS'
export const UTILITY_GET_NAMIRIAL_URL_ERROR = 'UTILITY_GET_NAMIRIAL_URL_ERROR'

export const UTILITY_SET_SIGNED_START = 'UTILITY_SET_SIGNED_START'
export const UTILITY_SET_SIGNED_SUCCESS = 'UTILITY_SET_SIGNED_SUCCESS'
export const UTILITY_SET_SIGNED_ERROR = 'UTILITY_SET_SIGNED_ERROR'

export const RICHIESTA_IN_SEDE_START = 'RICHIESTA_IN_SEDE_START'
export const RICHIESTA_IN_SEDE_SUCCESS = 'RICHIESTA_IN_SEDE_SUCCESS'
export const RICHIESTA_IN_SEDE_ERROR = 'RICHIESTA_IN_SEDE_ERROR'

export const SET_TITOLARE = 'SET_TITOLARE'
export const SET_TITOLARE_MULTI = 'SET_TITOLARE_MULTI'

export const SET_PEP = 'SET_PEP'
export const SET_PEP_MULTI = 'SET_PEP_MULTI'

export const PRATICA_CORRENTE_FIRMA = 'PRATICA_CORRENTE_FIRMA'

export const DOWNLOAD_ZIP_START = 'DOWNLOAD_ZIP_START'
export const DOWNLOAD_ZIP_SUCCESS = 'DOWNLOAD_ZIP_SUCCESS'
export const DOWNLOAD_ZIP_ERROR = 'DOWNLOAD_ZIP_ERROR'

export const CHECK_DOWNLOAD_ZIP_START = 'CHECK_DOWNLOAD_ZIP_START'
export const CHECK_DOWNLOAD_ZIP_SUCCESS = 'CHECK_DOWNLOAD_ZIP_SUCCESS'
export const CHECK_DOWNLOAD_ZIP_ERROR = 'CHECK_DOWNLOAD_ZIP_ERROR'

export const RESET_DOWNLOADED = 'RESET_DOWNLOADED'

export const GET_ADDITIONAL_OWNER_START = 'GET_ADDITIONAL_OWNER_START'
export const GET_ADDITIONAL_OWNER_SUCCESS = 'GET_ADDITIONAL_OWNER_SUCCESS'
export const GET_ADDITIONAL_OWNER_ERROR = 'GET_ADDITIONAL_OWNER_ERROR'

export const POST_ADDITIONAL_OWNER_START = 'POST_ADDITIONAL_OWNER_START'
export const POST_ADDITIONAL_OWNER_SUCCESS = 'POST_ADDITIONAL_OWNER_SUCCESS'
export const POST_ADDITIONAL_OWNER_ERROR = 'POST_ADDITIONAL_OWNER_ERROR'

export const DELETE_ADDITIONAL_OWNER_START = 'DELETE_ADDITIONAL_OWNER_START'
export const DELETE_ADDITIONAL_OWNER_SUCCESS = 'DELETE_ADDITIONAL_OWNER_SUCCESS'
export const DELETE_ADDITIONAL_OWNER_ERROR = 'DELETE_ADDITIONAL_OWNER_ERROR'

export const GET_ID_PROSPECT_BY_ID_PROCESS_START = 'GET_ID_PROSPECT_BY_ID_PROCESS_START'
export const GET_ID_PROSPECT_BY_ID_PROCESS_SUCCESS = 'GET_ID_PROSPECT_BY_ID_PROCESS_SUCCESS'
export const GET_ID_PROSPECT_BY_ID_PROCESS_ERROR = 'GET_ID_PROSPECT_BY_ID_PROCESS_ERROR'

export const GET_DATA_FROM_TAX_CODE_START = 'GET_DATA_FROM_TAX_CODE_START'
export const GET_DATA_FROM_TAX_CODE_SUCCESS = 'GET_DATA_FROM_TAX_CODE_SUCCESS'
export const GET_DATA_FROM_TAX_CODE_ERROR = 'GET_DATA_FROM_TAX_CODE_ERROR'

export const DOWNLOAD_DOCS_START = 'DOWNLOAD_DOCS_START'
export const DOWNLOAD_DOCS_SUCCESS = 'DOWNLOAD_DOCS_SUCCESS'
export const DOWNLOAD_DOCS_ERROR = 'DOWNLOAD_DOCS_ERROR'

export const RICHIAMA_PRATICA_START = 'RICHIAMA_PRATICA_START'
export const RICHIAMA_PRATICA_SUCCESS = 'RICHIAMA_PRATICA_SUCCESS'
export const RICHIAMA_PRATICA_ERROR = 'RICHIAMA_PRATICA_ERROR'

export const PRATICA_TO_SIGN_ROOM_START = 'PRATICA_TO_SIGN_ROOM_START'
export const PRATICA_TO_SIGN_ROOM_SUCCESS = 'PRATICA_TO_SIGN_ROOM_SUCCESS'
export const PRATICA_TO_SIGN_ROOM_ERROR = 'PRATICA_TO_SIGN_ROOM_ERROR'

export const DOWNLOAD_SIGN_MODULE_START = 'DOWNLOAD_SIGN_MODULE_START'
export const DOWNLOAD_SIGN_MODULE_SUCCESS = 'DOWNLOAD_SIGN_MODULE_SUCCESS'
export const DOWNLOAD_SIGN_MODULE_ERROR = 'DOWNLOAD_SIGN_MODULE_ERROR'
