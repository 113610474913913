import * as actionTypes from './constants'
import { retrieveDataSummaryLight } from '../customers/actions'
import { retrieveDataSummaryLightPG } from '../personaGiuridica/prospect/actions'
import * as actionTypesProspectGiuridica from '../personaGiuridica/prospect/constants'

import axios from 'axios'

export function isStatusResponseInvalid(response = {}) {
  const { data = {} } = response
  return data.statusResponse && data.statusResponse.code && data.statusResponse.code !== '0'
}

export function isMifidApi({ config: { url } }) {
  return url.indexOf('survey/mifid/save') !== -1 || url.indexOf('survey/mifid/get') !== -1
}

function getMifid(idCliente, flagRiepilogo, active, mifidLight, isPersonaGiuridica, idPratica) {
  if (!active) {
    return new Promise(function(resolve, reject) {
      resolve({ data: {} })
    })
  }
  if (isPersonaGiuridica) {
    if (mifidLight) {
      try {
        return axios.get(
          `/v2/survey/mifidv2/get/${idCliente}?flagRiepilogo=${flagRiepilogo}&tipoMifid=MIFID_PD&idPratica=${idPratica}`
        )
      } catch (error) {
        const newError = new Error(error.message)
        newError.apiName = 'getMifidLight'
        throw newError
      }
    } else {
      try {
        return axios.get(
          `/v2/survey/mifidv2/get/${idCliente}?flagRiepilogo=${flagRiepilogo}&tipoMifid=MIFID_PG&idPratica=${idPratica}`
        )
      } catch (error) {
        const newError = new Error(error.message)
        newError.apiName = 'getMifid'
        throw newError
      }
    }
  } else {
    try {
      return axios.get(
        `/survey/mifidv2/get/${idCliente}?flagRiepilogo=${flagRiepilogo}&tipoMifid=MIFID_PF&idPratica=${idPratica}`
      )
    } catch (error) {
      const newError = new Error(error.message)
      newError.apiName = 'getMifid'
      throw newError
    }
  }
}

export function getTemplateMifid(mifidLight, isPersonaGiuridica) {
  if (isPersonaGiuridica) {
    // PG
    if (!mifidLight) {
      try {
        return axios.get(`/v2/survey/mifidv2/template?tipoMifid=MIFID_PG`)
      } catch (error) {
        const newError = new Error(error.message)
        newError.apiName = 'getTemplateMifid'
        throw newError
      }
      // PD
    } else {
      try {
        return axios.get(`/v2/survey/mifidv2/template?tipoMifid=MIFID_PD`)
      } catch (error) {
        const newError = new Error(error.message)
        newError.apiName = 'getTemplateMifidLight'
        throw newError
      }
    }
  }
  // PF
  else {
    try {
      return axios.get(`/survey/mifidv2/template?tipoMifid=MIFID_PF`)
    } catch (error) {
      const newError = new Error(error.message)
      newError.apiName = 'getTemplateMifidLight'
      throw newError
    }
  }
}

export function fetchSurveyMifid(idCliente, flagRiepilogo, active, mifidLight, isPersonaGiuridica, idPratica) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SURVEY_MIFID_FETCH_START
    })

    try {
      const [dataTemplateMifid, dataMifid] = await Promise.all([
        getTemplateMifid(mifidLight, isPersonaGiuridica),
        getMifid(idCliente, flagRiepilogo, active, mifidLight, isPersonaGiuridica, idPratica)
      ])
      dispatch({
        type: actionTypes.SURVEY_MIFID_FETCH_SUCCESS,
        dataTemplateMifid: dataTemplateMifid.data,
        dataMifid: dataMifid.data,
        isMifidInconsistent: isStatusResponseInvalid(dataMifid)
      })
    } catch (error) {
      dispatch({
        type: actionTypes.SURVEY_MIFID_FETCH_ERROR,
        error
      })
    }
  }
}

export function saveSurvey(
  idCliente,
  idIncarico,
  idServizio,
  listaDomande,
  flagProfilatura,
  mifidLight,
  isPersonaGiuridica
) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SURVEY_MIFID_SAVE_START
    })

    let responseApi = null
    let subjectType = null
    let _data = null
    if (isPersonaGiuridica) {
      subjectType = 'LEGAL_PERSON'
    } else {
      subjectType = 'PHYSICAL_SUBJECT'
    }
    if (mifidLight) {
      axios
        .post(`/survey/mifidv2/save?tipoMifid=MIFID_PD`, {
          idCliente,
          idIncarico,
          idServizio,
          flagProfilatura,
          listaDomande,
          subjectType
        })
        .then(response => {
          responseApi = response
          if (isPersonaGiuridica) {
            return retrieveDataSummaryLightPG(idCliente, idIncarico)
          } else {
            return retrieveDataSummaryLight(idCliente, idIncarico)
          }
        })
        .then(response => {
          if (isPersonaGiuridica) {
            dispatch({
              type: actionTypesProspectGiuridica.GET_DATA_SUMMARY_LIGHT_PG_SUCCESS,
              responseApi: response.data
            })
          }

          dispatch({
            type: actionTypes.SURVEY_MIFID_SAVE_SUCCESS,
            template: responseApi.data,
            summaryLight: response.data,
            isMifidInconsistent: isStatusResponseInvalid(responseApi)
          })
        })
        .catch(error => {
          dispatch({
            type: actionTypes.SURVEY_MIFID_SAVE_ERROR,
            error
          })
        })
    } else {
      let url = isPersonaGiuridica
        ? '/survey/mifidv2/save?tipoMifid=MIFID_PG'
        : '/survey/mifidv2/save?tipoMifid=MIFID_PF'
      axios
        .post(url, {
          idCliente,
          idIncarico,
          idServizio,
          flagProfilatura,
          listaDomande,
          subjectType
        })
        .then(response => {
          responseApi = response
          if (isPersonaGiuridica) {
            return retrieveDataSummaryLightPG(idCliente, idIncarico)
          } else {
            return retrieveDataSummaryLight(idCliente, idIncarico)
          }
        })
        .then(response => {
          if (isPersonaGiuridica) {
            dispatch({
              type: actionTypesProspectGiuridica.GET_DATA_SUMMARY_LIGHT_PG_SUCCESS,
              responseApi: response.data
            })
          }
          _data = response.data
          dispatch({
            type: actionTypes.SURVEY_MIFID_SAVE_SUCCESS,
            template: responseApi.data,
            summaryLight: response.data,
            isMifidInconsistent: isStatusResponseInvalid(responseApi)
          })
        })
        .catch(error => {
          dispatch({
            type: actionTypes.SURVEY_MIFID_SAVE_ERROR,
            error,
            summaryLight: _data
          })
        })
    }
  }
}

function getAml(idCliente, idIncarico, active) {
  if (!active) {
    return new Promise(function(resolve, reject) {
      resolve({ data: {} })
    })
  }
  try {
    return axios.get(`/survey/aml/${idCliente}/get/?idIncarico=${idIncarico}&flagRiepilogo=false`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'getAml'
    throw newError
  }
}

export function getTemplateAml() {
  try {
    return axios.get(`/survey/aml/template`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'getTemplateAml'
    throw newError
  }
}

export function fetchSurveyAml(idCliente, idIncarico, active) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SURVEY_AML_FETCH_START
    })
    try {
      const [dataTemplateAml, dataAml] = await Promise.all([getTemplateAml(), getAml(idCliente, idIncarico, active)])
      dispatch({
        type: actionTypes.SURVEY_AML_FETCH_SUCCESS,
        dataTemplateAml: dataTemplateAml.data,
        dataAml: dataAml.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.SURVEY_AML_FETCH_ERROR,
        error
      })
    }
  }
}

function getAmlPg(idCliente, idIncarico, active) {
  if (!active) {
    return new Promise(function(resolve, reject) {
      resolve({ data: {} })
    })
  }
  try {
    return axios.get(`/survey/aml-pg/${idCliente}/get/?idIncarico=${idIncarico}&flagRiepilogo=false`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'getAml-pg'
    throw newError
  }
}

export function getTemplateAmlPg() {
  try {
    return axios.get(`/survey/aml-pg/template`, {})
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'getTemplateAml-pg'
    throw newError
  }
}

export function fetchSurveyAmlPg(idCliente, idIncarico, active) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SURVEY_AML_PG_FETCH_START
    })
    try {
      const [dataTemplateAml, dataAml] = await Promise.all([
        getTemplateAmlPg(),
        getAmlPg(idCliente, idIncarico, active)
      ])
      dispatch({
        type: actionTypes.SURVEY_AML_PG_FETCH_SUCCESS,
        dataTemplateAml: dataTemplateAml.data,
        dataAml: dataAml.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.SURVEY_AML_PG_FETCH_ERROR,
        error
      })
    }
  }
}

export function saveAml(idCliente, idIncarico, listaDomande, active) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SURVEY_AML_SAVE_START
    })

    let responseApi = null
    axios({
      method: active ? 'PUT' : 'POST',
      url: `/survey/aml/save`,
      data: {
        idCliente,
        idIncarico,
        listaDomande
      }
    })
      .then(response => {
        responseApi = response.data
        return retrieveDataSummaryLight(idCliente, idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.SURVEY_AML_SAVE_SUCCESS,
          payload: responseApi,
          summaryLight: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SURVEY_AML_SAVE_ERROR,
          error
        })
      })
  }
}
export function saveAmlPg(idCliente, idIncarico, listaDomande, active) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SURVEY_AML_PG_SAVE_START
    })

    axios({
      method: active ? 'PUT' : 'POST',
      url: `/survey/aml-pg/save`,
      data: {
        idCliente,
        idIncarico,
        listaDomande
      }
    })
      .then(response => {
        dispatch({
          type: actionTypes.SURVEY_AML_PG_SAVE_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SURVEY_AML_PG_SAVE_ERROR,
          error
        })
      })
  }
}

export function getAdegVerTemplate() {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_START
    })

    try {
      const response = await axios.get('/survey/adegver/template', {
        idConsultant: 'idConsultant',
        idCustomer: 'idCustomer'
      })
      dispatch({
        type: actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_SUCCESS,
        template: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_ERROR,
        error: error
      })
    }
  }
}

export function getAdegVerTemplatePg() {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_PG_START
    })

    try {
      const response = await axios.get('/survey/adegver-pg/template', {
        idConsultant: 'idConsultant',
        idCustomer: 'idCustomer'
      })
      dispatch({
        type: actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_PG_SUCCESS,
        template: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_ADEGUATA_VERIFICA_TEMPLATE_PG_ERROR,
        error: error
      })
    }
  }
}

export function saveAdegVer(idCliente, idIncarico, listaDomande) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SAVE_ADEGUATA_VERIFICA_START
    })

    try {
      const response = await axios.post(`/survey/adegver/save`, {
        idCliente,
        idIncarico,
        listaDomande
      })
      dispatch({
        type: actionTypes.SAVE_ADEGUATA_VERIFICA_SUCCESS,
        dataPost: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.SAVE_ADEGUATA_VERIFICA_ERROR,
        error: error
      })
    }
  }
}

export function checkProfessionale(payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.PROFESSIONALE_START
    })

    try {
      const response = await axios.post(`/survey/checkProfessionale`, payload)
      dispatch({
        type: actionTypes.PROFESSIONALE_SUCCESS,
        isProfessionale: response.data.professionale
      })
    } catch (error) {
      dispatch({
        type: actionTypes.PROFESSIONALE_ERROR,
        error: error
      })
    }
  }
}

// export function postProfessionale(payload) {
//   return async function(dispatch) {
//     dispatch({
//       type: actionTypes.POST_PROFESSIONALE_PG_START
//     })

//     try {
//       const response = await axios.post(`/v2/legal-person/professional-subject/save`, payload)
//       dispatch({
//         type: actionTypes.POST_PROFESSIONALE_PG_SUCCESS,
//         professionalSubjectType: payload.professionalSubjectType,
//         dataPost: response.data
//       })
//     } catch (error) {
//       dispatch({
//         type: actionTypes.POST_PROFESSIONALE_PG_ERROR,
//         error: error
//       })
//     }
//   }
// }

export function postProfessionale(idCliente, idIncarico, payload) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.POST_PROFESSIONALE_PG_START
    })

    // responseApi = null
    axios({
      method: 'POST',
      url: `/v2/legal-person/professional-subject/save`,
      data: payload
    })
      .then(response => {
        // let responseApi = response.data
        return retrieveDataSummaryLightPG(idCliente, idIncarico)
      })
      .then(response => {
        dispatch({
          type: actionTypes.POST_PROFESSIONALE_PG_SUCCESS,
          // dataPost: responseApi,
          professionalSubjectType: payload.professionalSubjectType,
          summaryLight: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.POST_PROFESSIONALE_PG_ERROR,
          error
        })
      })
  }
}

// professionalSubjectType: payload.professionalSubjectType,
// dataPost: response.data

/// /

export function getProfessionale(idPhysicalPerson) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.GET_PROFESSIONALE_PG_START
    })

    try {
      const response = await axios.get(`/v2/legal-person/professional-subject/read/${idPhysicalPerson}`)
      dispatch({
        type: actionTypes.GET_PROFESSIONALE_PG_SUCCESS,
        professionalSubjectType: response.data.professionalSubjectType
      })
    } catch (error) {
      dispatch({
        type: actionTypes.GET_PROFESSIONALE_PG_ERROR,
        error: error
      })
    }
  }
}

export function listExternal(queryParam) {
  try {
    return axios.get(`/v2/customer/listExternal`, {
      params: { querySearch: queryParam.codClienteExt }
    })
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'listExternal'
    throw newError
  }
}

export function saveOWCustomer(customerData) {
  try {
    return axios.post(`/v2/pratica/import`, {
      ...customerData,
      tipoPraticaOB: customerData.isPG ? 2 : 1,
      profilaturaMifid: customerData.flagProfilatura
    })
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'saveOWCustomer'
    throw newError
  }
}

export function savePratica(customerData) {
  try {
    return axios.post('/v2/pratica', {
      intestatari: [{ idCliente: customerData.idCliente, idRuolo: 1 }],
      servizi: [],
      codiciTipoServizio: [11],
      idRegimeAppartenenza: null,
      idDominioTipoPratica: 4
    })
  } catch (error) {
    const newError = new Error(error.message)
    newError.apiName = 'savePratica'
    throw newError
  }
}

export function importSurveyExt(queryParam) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.IMPORT_SURVEY_EXT_START
    })
    try {
      const _listExternal = await listExternal(queryParam)
      const _customerData = _listExternal.data.customers[0]

      await saveOWCustomer(_customerData)

      let _pratica = await savePratica(_customerData)

      await axios.post('/v2/survey/importSurveyExt', {
        idPratica: _pratica.data.idPratica,
        idCliente: _customerData.idCliente,
        idCustomerExt: _customerData.codClienteOW,
        surveyType: 'AML',
        subjectType: _customerData.isPG ? 'LEGAL_PERSON' : 'PHYSICAL_SUBJECT'
      })
      dispatch({
        type: actionTypes.IMPORT_SURVEY_EXT_SUCCESS
      })
      return _pratica.data
    } catch (error) {
      dispatch({
        type: actionTypes.IMPORT_SURVEY_EXT_ERROR,
        error: error
      })
    }
  }
}

export function saveAggAml(idCliente, idIncarico, listaDomande, active) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SURVEY_AGG_AML_SAVE_START
    })
    axios({
      method: active ? 'PUT' : 'POST',
      url: `/survey/agg-aml/save`,
      data: {
        idCliente,
        idIncarico,
        listaDomande,
        subjectType: 'PHYSICAL_SUBJECT'
      }
    })
      .then(response => {
        dispatch({
          type: actionTypes.SURVEY_AGG_AML_SAVE_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SURVEY_AGG_AML_SAVE_ERROR,
          error
        })
      })
  }
}

export function saveAggAmlPg(idCliente, idIncarico, listaDomande, active) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.SURVEY_AGG_AML_PG_SAVE_START
    })

    axios({
      method: active ? 'PUT' : 'POST',
      url: `/survey/agg-aml/save`,
      data: {
        idCliente,
        idIncarico,
        listaDomande,
        subjectType: 'LEGAL_PERSON'
      }
    })
      .then(response => {
        dispatch({
          type: actionTypes.SURVEY_AGG_AML_PG_SAVE_SUCCESS,
          payload: response.data
        })
      })
      .catch(error => {
        dispatch({
          type: actionTypes.SURVEY_AGG_AML_PG_SAVE_ERROR,
          error
        })
      })
  }
}

export function importSurveyExtAV(queryParam) {
  return async function(dispatch) {
    dispatch({
      type: actionTypes.IMPORT_SURVEY_EXT_AV_START
    })
    try {
      const response = await axios.post('/v2/survey/importSurveyExt', {
        ...queryParam,
        surveyType: 'AV'
      })
      dispatch({
        type: actionTypes.IMPORT_SURVEY_EXT_AV_SUCCESS,
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: actionTypes.IMPORT_SURVEY_EXT_AV_ERROR,
        error: error
      })
    }
  }
}
