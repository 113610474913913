import React from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues, getFormSyncErrors, isDirty } from 'redux-form'
import { Button, Row, Col } from 'react-materialize'
import { APERTURA_INTESTATARI } from '../../../../store/modules/forms/constants'
import { renderAutocomplete } from '../../../../components/Forms/renderFields'

// Disabled temporaneo

const validate = (values, props = {}) => {
  const errors = {}
  const { users = [] } = props
  if (values.cointestatario1) {
    const user = users.find(u => u.idCliente === values.cointestatario1)
    if (!user) {
      errors.cointestatario1 = 'Intestatario non valido.'
    } else if (!user.flagResidenzaItalia) {
      errors.cointestatario1 = 'Non è possibile selezionare come intestatario un cliente non residente in Italia.'
    } else if (user.flagUsaPerson) {
      errors.cointestatario1 =
        'Non è possibile selezionare come intestatario una US Person ai sensi della normativa FATCA.'
    }
  }
  if (values.cointestatario2) {
    const user = users
      .filter(u => u.idCliente !== values.cointestatario1)
      .find(u => u.idCliente === values.cointestatario2)
    if (!user) {
      errors.cointestatario2 = 'Cointestatario 1 non valido.'
    } else if (!user.flagResidenzaItalia) {
      errors.cointestatario2 = 'Non è possibile selezionare come intestatario un cliente non residente in Italia.'
    } else if (user.flagUsaPerson) {
      errors.cointestatario2 =
        'Non è possibile selezionare come intestatario una US Person ai sensi della normativa FATCA.'
    }
  }
  if (values.cointestatario3) {
    const user = users
      .filter(u => u.idCliente !== values.cointestatario1 && u.idCliente !== values.cointestatario2)
      .find(u => u.idCliente === values.cointestatario3)
    if (!user) {
      errors.cointestatario3 = 'Cointestatario 2 non valido.'
    } else if (!user.flagResidenzaItalia) {
      errors.cointestatario3 = 'Non è possibile selezionare come intestatario un cliente non residente in Italia.'
    } else if (user.flagUsaPerson) {
      errors.cointestatario3 =
        'Non è possibile selezionare come intestatario una US Person ai sensi della normativa FATCA.'
    }
  }
  if (
    (!values.check1 && !values.check2 && !values.check3 && !values.check4 && !values.check7 && !values.check9) ||
    !values.cointestatario1
  ) {
    errors.check1 = 'Almeno un servizio e un intestatario sono richiesti.'
  }
  return errors
}

export class DerogaIntestatariView extends React.Component {
  constructor(props) {
    super(props)
    this.state = { loadingComplete: false }

    this.mapClientiToAutocomplete = this.mapClientiToAutocomplete.bind(this)
    this.savePratica = this.savePratica.bind(this)
    this.checkIntestatario = this.checkIntestatario.bind(this)
  }

  componentDidMount() {
    this.props.getCustomerList()
    this.props.resetPratica()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.getClientiListRequest.fetching && !this.props.getClientiListRequest.fetching) {
      this.setState({ loadingComplete: true })
    }

    if (
      prevProps.savePraticaRequest.fetching &&
      !this.props.savePraticaRequest.fetching &&
      !this.props.savePraticaRequest.error
    ) {
      // goto to deroga add
      // this.props.//history.push(`configurazione/${this.props.//idPratica}`)
    }
  }

  mapClientiToAutocomplete(altriIntestatari, checkProfilatura) {
    const { users, formValues = {} } = this.props
    return users.reduce((res, user) => {
      if (altriIntestatari.some(i => formValues[i] === user.idCliente)) {
        return res
      }
      if (checkProfilatura && !user.flagProfilatura) {
        return res
      }
      if (altriIntestatari[0] === 'cointestatario1') {
        if (user.isPG) {
          return res
        }
      }
      return [
        ...res,
        {
          ...user,
          key: user.idCliente,
          value: user.idCliente,
          label: (user.nome ? user.nome : '') + ' ' + user.cognome
        }
      ]
    }, [])
  }

  savePratica(values) {
    // this.props.saveAndProceed({
    //   codiciTipoServizio: [1, 2, 3, 4, 7, 9].reduce((res, cur) => {
    //     if (values[`check${cur}`]) {
    //       return [...res, cur]
    //     }
    //     return res
    //   }, []),
    //  , [])
    // })
    let arrayIntestatari = [0, 1, 2, 3]
    let intestatari = arrayIntestatari.reduce((res, cur) => {
      if (values[`cointestatario${cur}`]) {
        return [
          ...res,
          {
            idCliente: values[`cointestatario${cur}`],
            idRuolo: cur
          }
        ]
      }
      return res
    })

    this.props.users.forEach(function(cur) {
      Object.keys(intestatari).forEach(function(key) {
        // console.log(key, intestatari[key]);

        if (cur.idCliente === intestatari[key].idCliente) {
          intestatari[key] = {
            ...intestatari[key],
            ...cur
          }
        }
      })
    })

    console.log(intestatari)

    this.props.saveIntestatari(intestatari)

    let pathurl = `/deroga-process/${this.props.match.params.idCustomer}/commissionale`

    this.props.history.push(pathurl)
  }
  checkIntestatario(intestatario) {
    const { users = {} } = this.props
    let disabled = false

    return users.reduce((res, user) => {
      if (intestatario === '') {
        disabled = true
      } else {
        if (intestatario === user.idCliente && user.isPG) {
          disabled = true
        }
      }

      return disabled
    }, [])
  }

  render() {
    if (!this.state.loadingComplete) return null
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      errors,
      formValues = {},
      change,
      savePraticaRequest: { fetching }
    } = this.props
    const intestatari = this.mapClientiToAutocomplete(['cointestatario2', 'cointestatario3'], true)
    const cointestatari1 = this.mapClientiToAutocomplete(['cointestatario1', 'cointestatario3'])
    const cointestatari2 = this.mapClientiToAutocomplete(['cointestatario1', 'cointestatario2'])

    return (
      <div>
        <h2 className="title-sezione-config">CONFIGURAZIONE</h2>
        <div className="box-container box-intestatari">
          <form onSubmit={handleSubmit(this.savePratica)} noValidate>
            <Row className="collapsible-section-title">
              <h2>Intestatari</h2>
            </Row>
            <Row>
              <Col s={6}>
                <Field
                  name="cointestatario1"
                  size={6}
                  label="Intestatario"
                  threshold={0}
                  options={intestatari}
                  handleChange={(field, value) => {
                    change(field, value)
                    change('cointestatario2', '')
                    change('cointestatario3', '')
                  }}
                  component={renderAutocomplete}
                />
              </Col>
            </Row>
            <Row>
              <Col s={6}>
                <Field
                  name="cointestatario2"
                  label="Cointestatario 1"
                  threshold={0}
                  options={cointestatari1}
                  handleChange={(field, value) => {
                    change(field, value)
                    change('cointestatario3', '')
                  }}
                  disabled={
                    this.checkIntestatario(formValues.cointestatario1) ||
                    (!formValues.cointestatario1 || errors.cointestatario1 || cointestatari1.length === 0)
                  }
                  component={renderAutocomplete}
                />
              </Col>
              <Col s={6}>
                <Field
                  name="cointestatario3"
                  label="Cointestatario 2"
                  threshold={0}
                  options={cointestatari2}
                  handleChange={change}
                  disabled={!formValues.cointestatario2 || errors.cointestatario2 || cointestatari2.length === 0}
                  component={renderAutocomplete}
                />
              </Col>
            </Row>

            <Row className="center-align m-top30">
              <Col s={12}>
                <Button
                  waves="light"
                  type="submit"
                  disabled={submitting || !isDirty || invalid || fetching}
                  className="margin-20">
                  Prosegui
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </div>
    )
  }
}

DerogaIntestatariView.propTypes = {
  saveIntestatari: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  users: PropTypes.array,
  getCustomerList: PropTypes.func,
  getClientiListRequest: PropTypes.object,
  // saveAndProceed: PropTypes.func,
  savePraticaRequest: PropTypes.object,
  resetPratica: PropTypes.func,
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  errors: PropTypes.object,
  formValues: PropTypes.object,
  change: PropTypes.func
  // idPratica: PropTypes.any
}

export default connect(state => ({
  initialValues: {
    cointestatario1: ''
  },
  formValues: getFormValues(APERTURA_INTESTATARI)(state),
  isDirty: isDirty(APERTURA_INTESTATARI)(state),
  errors: getFormSyncErrors(APERTURA_INTESTATARI)(state)
}))(
  reduxForm({
    form: APERTURA_INTESTATARI,
    validate
  })(DerogaIntestatariView)
)
