import * as actionTypes from './constants'

const initialState = {}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_PROSPECT_COUNT_START:
    case actionTypes.GET_PROSPECT_COUNT_ERROR:
      return {
        ...state,
        getProspectCount: action.getProspectCount
      }
    case actionTypes.GET_PROSPECT_COUNT_SUCCESS:
      return {
        ...state,
        getProspectCount: action.getProspectCount,
        prospectCount: action.getProspectCount.payload
      }
    case actionTypes.GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_START:
    case actionTypes.GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_ERROR:
      return {
        ...state,
        getProspectToCustomerPercentage: action.getProspectToCustomerPercentage
      }
    case actionTypes.GET_PROSPECT_TO_CUSTOMER_PERCENTAGE_SUCCESS:
      return {
        ...state,
        getProspectToCustomerPercentage: action.getProspectToCustomerPercentage,
        prospectToCustomerPercentage: action.getProspectToCustomerPercentage.payload
      }
    case actionTypes.GET_PROSPECT_PERCENTAGE_START:
    case actionTypes.GET_PROSPECT_PERCENTAGE_ERROR:
      return {
        ...state,
        getProspectPercentage: action.getProspectPercentage
      }
    case actionTypes.GET_PROSPECT_PERCENTAGE_SUCCESS:
      return {
        ...state,
        getProspectPercentage: action.getProspectPercentage,
        prospectPercentage: action.getProspectPercentage.payload
      }
    case actionTypes.GET_INVESTMENT_SERVICE_OVERALL_START:
    case actionTypes.GET_INVESTMENT_SERVICE_OVERALL_ERROR:
      return {
        ...state,
        getInvestmentServiceOverall: action.getInvestmentServiceOverall
      }
    case actionTypes.GET_INVESTMENT_SERVICE_OVERALL_SUCCESS:
      return {
        ...state,
        getInvestmentServiceOverall: action.getInvestmentServiceOverall,
        investmentServiceOverall: action.getInvestmentServiceOverall.payload
      }
    case actionTypes.GET_PROSPECT_IP_OVERALL_START:
    case actionTypes.GET_PROSPECT_IP_OVERALL_ERROR:
      return {
        ...state,
        getProspectIpOverall: action.getProspectIpOverall
      }
    case actionTypes.GET_PROSPECT_IP_OVERALL_SUCCESS:
      return {
        ...state,
        getProspectIpOverall: action.getProspectIpOverall,
        prospectIpOverall: action.getProspectIpOverall.payload
      }
  }
  return state
}
