import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import Title from '../Title'

const SurveyRadio = ({
  template,
  disabled,
  hideNumber = false,
  showSubTesto,
  onChangeCallback = () => {},
  formValues = {},
  radioClass = 'radio-vertical'
}) => {
  const { idDomanda, subTestoDomanda, listaRisposte = [], flagDipendenza, listaDipendenze } = template
  const testoDomanda = !hideNumber
    ? template.testoDomanda
    : template.testoDomanda.replace(template.numDomanda + ' - ', '')
  const isDisabled = risposta => {
    if (disabled) return true
    if (risposta.flagDipendenza && Array.isArray(risposta.listaDipendenze)) {
      return !risposta.listaDipendenze.some(
        dip => formValues[`field_${dip.idDomandaDipendenza}`] === '' + dip.idRispostaDipendenza
      )
    }
    // Check su padre (se true sbianca la riposta dipendente, deve essere impostata la onChangeCallback)
    if (flagDipendenza && Array.isArray(listaDipendenze)) {
      const res = listaDipendenze.some(
        dip =>
          dip.tipoDipendenza === 'disable' &&
          (formValues[`field_${dip.idDomandaDipendenza}`] == null ||
            formValues[`field_${dip.idDomandaDipendenza}`] === '' ||
            formValues[`field_${dip.idDomandaDipendenza}`] === '' + dip.idRispostaDipendenza)
      )
      return res
    }
    return false
  }

  return (
    <Fragment>
      <Title cssClass="no-margin-bottom">{testoDomanda}</Title>
      {showSubTesto && <Title cssClass="no-margin">{subTestoDomanda}</Title>}
      <div className={radioClass}>
        {listaRisposte.map(risposta => (
          <p key={risposta.idRisposta}>
            <Field
              name={`field_${idDomanda}`}
              className="with-gap"
              type="radio"
              disabled={isDisabled(risposta)}
              onChange={onChangeCallback}
              id={`${idDomanda}-${risposta.idRisposta}`}
              value={'' + risposta.idRisposta}
              component="input"
            />
            <label htmlFor={`${idDomanda}-${risposta.idRisposta}`}>
              {risposta.descRisposta}
              {risposta.descSubRisposta && <span className="subtitle">{risposta.descSubRisposta}</span>}
            </label>
          </p>
        ))}
      </div>
    </Fragment>
  )
}
export default SurveyRadio

SurveyRadio.propTypes = {
  template: PropTypes.shape({
    idDomanda: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    testoDomanda: PropTypes.string,
    listaRisposte: PropTypes.array,
    numDomanda: PropTypes.oneOfType([PropTypes.string, PropTypes.any])
  }).isRequired,
  radioClass: PropTypes.string,
  formValues: PropTypes.object,
  showSubTesto: PropTypes.bool,
  onChangeCallback: PropTypes.func,
  disabled: PropTypes.bool,
  hideNumber: PropTypes.bool
}
