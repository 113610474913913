export const GET_DEROGHE_START = 'GET_DEROGHE_START'
export const GET_DEROGHE_SUCCESS = 'GET_DEROGHE_SUCCESS'
export const GET_DEROGHE_ERROR = 'GET_DEROGHE_ERROR'

export const SET_DEROGA_START = 'SET_DEROGA_START'
export const SET_DEROGA_SUCCESS = 'SET_DEROGA_SUCCESS'
export const SET_DEROGA_ERROR = 'SET_DEROGA_ERROR'

export const SET_DEROGA_INTESTATARI_START = 'SET_DEROGA_INTESTATARI_START'
export const SET_DEROGA_INTESTATARI_SUCCESS = 'SET_DEROGA_INTESTATARI_SUCCESS'
export const SET_DEROGA_INTESTATARI_ERROR = 'SET_DEROGA_INTESTATARI_ERROR'
