import React from 'react'
import { PropTypes } from 'prop-types'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { MIFID_ALTRO, MIFID_ALTRO_LIGHT } from '../../../../store/modules/forms/constants'
import allFields from '../../fields'
import AccordionHeader from '../../../../components/AccordionHeader'
import SurveyRadio from '../../../../components/SurveyRadio'
import SurveyHr from '../../../../components/SurveyHr'
import RicchezzaNonInvestita from './RicchezzaNonInvestita'
import ButtonSubmit from '../../../../components/Buttons/Submit'

const fields = allFields[2][MIFID_ALTRO]
const fieldsLight = allFields[2][MIFID_ALTRO_LIGHT]

class AltreInformazioni extends React.Component {
  constructor(props) {
    super(props)

    this.state = { saved: false }
    this.submit = this.submit.bind(this)
  }
  //

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(MIFID_ALTRO)
      this.setState({ saved: true })
    }
  }

  submit(values) {
    const { onSave } = this.props
    const submittedValues = Object.keys(values).reduce(
      (res, cur) => ({ ...res, [cur.replace('field_', '')]: values[cur] }),
      {}
    )

    if (!this.props.clienteProfessionale) {
      onSave(
        Object.keys(submittedValues).reduce((res, cur) => {
          const value = res.find(d => d.idDomanda === '43')

          if (cur === '41' || cur === '42') {
            return [...res, { idDomanda: cur, listaRisposte: [{ idRisposta: submittedValues[cur] }] }]
          } else if (!value) {
            return [
              ...res,
              {
                idDomanda: '43',
                listaRisposte: [
                  { idRisposta: 57, descRisposta: submittedValues[57] },
                  { idRisposta: 58, descRisposta: submittedValues[58] },
                  { idRisposta: 59, descRisposta: submittedValues[59] },
                  { idRisposta: 60, descRisposta: submittedValues[60] },
                  { idRisposta: 61, descRisposta: submittedValues[61] }
                ]
              }
            ]
          } else {
            return [...res]
          }
        }, [])
      )
    } else {
      onSave(
        Object.keys(submittedValues).reduce((res, cur) => {
          const value = res.find(d => d.idDomanda === '89')

          if (cur === '87' || cur === '88') {
            return [...res, { idDomanda: cur, listaRisposte: [{ idRisposta: submittedValues[cur] }] }]
          } else if (!value) {
            return [
              ...res,
              {
                idDomanda: '89',
                listaRisposte: [
                  { idRisposta: 57, descRisposta: submittedValues[57] },
                  { idRisposta: 58, descRisposta: submittedValues[58] },
                  { idRisposta: 59, descRisposta: submittedValues[59] },
                  { idRisposta: 60, descRisposta: submittedValues[60] },
                  { idRisposta: 61, descRisposta: submittedValues[61] }
                ]
              }
            ]
          } else {
            return [...res]
          }
        }, [])
      )
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      anyTouched,
      invalid,
      formValues,
      change,
      status,
      template: { descSezione, listaDomande },
      isFetching,
      clienteProfessionale
    } = this.props

    const template = listaDomande.find(
      this.props.clienteProfessionale
        ? d => d.idDomanda === fieldsLight.RICCHEZZA_NON_INVESTITA.templateId
        : d => d.idDomanda === fields.RICCHEZZA_NON_INVESTITA.templateId
    )
    let barTotal = 0
    if (template !== undefined) {
      barTotal = template.listaRisposte.reduce(
        (t, { idRisposta }) => t + parseInt(formValues[`field_${idRisposta}`] || 0),
        0
      )
    }

    return (
      <CollapsibleItem
        header={descSezione}
        itemKey={clienteProfessionale ? MIFID_ALTRO_LIGHT : MIFID_ALTRO}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <SurveyRadio
            template={listaDomande.find(
              d =>
                d.idDomanda === fields.RICCHEZZA_FINANZIARIA.templateId ||
                d.idDomanda === fieldsLight.RICCHEZZA_FINANZIARIA.templateId
            )}
          />
          <SurveyHr />
          <SurveyRadio
            template={listaDomande.find(
              d =>
                d.idDomanda === fields.INVESTIMENTO_STRUMENTI_AZIONARI.templateId ||
                d.idDomanda === fieldsLight.INVESTIMENTO_STRUMENTI_AZIONARI.templateId
            )}
          />
          <SurveyHr />
          <RicchezzaNonInvestita formValues={formValues} change={change} barTotal={barTotal} template={template} />
          <SurveyHr hideHr />
          <ButtonSubmit
            disabled={
              !(barTotal === 100 || barTotal === 0) ||
              (submitting || (!isDirty && !anyTouched) || invalid || isFetching)
            }
          />
        </form>
      </CollapsibleItem>
    )
  }
}

AltreInformazioni.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  anyTouched: PropTypes.bool,
  template: PropTypes.object,
  onSave: PropTypes.func,
  change: PropTypes.func,
  clienteProfessionale: PropTypes.bool
}

export default AltreInformazioni
