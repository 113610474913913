import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col, Input } from 'react-materialize'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import { getValueDominioFromKey } from '../../utils'

class SezioneResidenza extends Component {
  render() {
    const { data, listaDomini, fields } = this.props
    return (
      <Fragment>
        {fields.NOME && (
          <Row>
            <Col s={6}>
              <ReadOnlyInput label={fields.NOME.label} value={data[fields.NOME.id]} />
            </Col>
            <Col s={6}>
              <ReadOnlyInput label={fields.COGNOME.label} value={data[fields.COGNOME.id]} />
            </Col>
          </Row>
        )}
        <Row>
          <Col s={4}>
            <ReadOnlyInput label={fields.INDIRIZZO.label} value={data[fields.INDIRIZZO.id]} />
          </Col>
          {(data[fields.PROVINCIA.id] || {}).chiave && (
            <Col s={4}>
              <ReadOnlyInput
                label={fields.CAP.label}
                value={getValueDominioFromKey(listaDomini, fields.CAP.dominio, (data[fields.CAP.id] || {}).chiave)}
              />
            </Col>
          )}
        </Row>
        <Row>
          <Col s={4}>
            <ReadOnlyInput
              label={fields.NAZIONE.label}
              value={getValueDominioFromKey(
                listaDomini,
                fields.NAZIONE.dominio,
                (data[fields.NAZIONE.id] || {}).chiave
              )}
            />
          </Col>
          {(data[fields.PROVINCIA.id] || {}).chiave && (
            <Fragment>
              <Col s={4}>
                <ReadOnlyInput
                  label={fields.PROVINCIA.label}
                  value={getValueDominioFromKey(
                    listaDomini,
                    fields.PROVINCIA.dominio,
                    (data[fields.PROVINCIA.id] || {}).chiave
                  )}
                />
              </Col>
              <Col s={4}>
                <ReadOnlyInput
                  label={fields.COMUNE.label}
                  value={getValueDominioFromKey(
                    listaDomini,
                    fields.COMUNE.dominio,
                    (data[fields.COMUNE.id] || {}).chiave
                  )}
                />
              </Col>
            </Fragment>
          )}
          {!(data[fields.PROVINCIA.id] || {}).chiave && (
            <Col s={4}>
              <ReadOnlyInput label={fields.COMUNE_ESTERO.label} value={data[fields.COMUNE_ESTERO.id]} />
            </Col>
          )}
        </Row>

        {fields.HA_DOMICILIO &&
          data[fields.HA_DOMICILIO.id] && (
            <Fragment>
              <Row>
                <Col s={12}>
                  {/* typeof data[fields.HA_DOMICILIO.id] !== 'undefined' && (
                  <Input
                    name={fields.HA_DOMICILIO.id}
                    checked
                    type={'checkbox'}
                    label={fields.HA_DOMICILIO.label}
                    disabled
                  />
                ) */}
                  <Input
                    name={fields.HA_DOMICILIO.id}
                    checked
                    type={'checkbox'}
                    label={fields.HA_DOMICILIO.label}
                    disabled
                  />
                </Col>
              </Row>

              <Row>
                <Col s={4}>
                  <ReadOnlyInput label={fields.INDIRIZZO_DOMICILIO.label} value={data[fields.INDIRIZZO_DOMICILIO.id]} />
                </Col>
                {(data[fields.PROVINCIA_DOMICILIO.id] || {}).chiave && (
                  <Col s={4}>
                    <ReadOnlyInput
                      label={fields.CAP_DOMICILIO.label}
                      value={getValueDominioFromKey(
                        listaDomini,
                        fields.CAP_DOMICILIO.dominio,
                        (data[fields.CAP_DOMICILIO.id] || {}).chiave
                      )}
                    />
                  </Col>
                )}
              </Row>

              <Row>
                <Col s={4}>
                  <ReadOnlyInput
                    label={fields.NAZIONE_DOMICILIO.label}
                    value={getValueDominioFromKey(
                      listaDomini,
                      fields.NAZIONE_DOMICILIO.dominio,
                      (data[fields.NAZIONE_DOMICILIO.id] || {}).chiave
                    )}
                  />
                </Col>
                {(data[fields.PROVINCIA_DOMICILIO.id] || {}).chiave && (
                  <Fragment>
                    <Col s={4}>
                      <ReadOnlyInput
                        label={fields.PROVINCIA_DOMICILIO.label}
                        value={getValueDominioFromKey(
                          listaDomini,
                          fields.PROVINCIA_DOMICILIO.dominio,
                          (data[fields.PROVINCIA_DOMICILIO.id] || {}).chiave
                        )}
                      />
                    </Col>
                    <Col s={4}>
                      <ReadOnlyInput
                        label={fields.COMUNE_DOMICILIO.label}
                        value={getValueDominioFromKey(
                          listaDomini,
                          fields.COMUNE_DOMICILIO.dominio,
                          (data[fields.COMUNE_DOMICILIO.id] || {}).chiave
                        )}
                      />
                    </Col>
                  </Fragment>
                )}
              </Row>
            </Fragment>
          )}
      </Fragment>
    )
  }
}
export default SezioneResidenza

SezioneResidenza.propTypes = {
  fields: PropTypes.object,
  data: PropTypes.object,
  listaDomini: PropTypes.array
}

// NAZIONE_DOMICILIO,
//         PROVINCIA_DOMICILIO,
//         COMUNE_DOMICILIO,
//         INDIRIZZO_DOMICILIO,
//         CAP_DOMICILIO,
//         COMUNE_ESTERO_DOMICILIO,
