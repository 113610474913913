import React from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import allFields from '../../fields'
import AccordionHeader from '../../../../components/AccordionHeader'
import SurveyRadio from '../../../../components/SurveyRadio'
import ButtonSubmit from '../../../../components/Buttons/Submit'
import { getMifidByCategory, MIFID_CONST } from '../../utils'
import { setValidators, setRisposte, isSaveDisabled, getFormValues } from '../mifidValidationUtils'

let MIFID_ACTIVE = null
let fields = null

class TolleranzaOscillazione extends React.Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, values: {} }
    this.submit = this.submit.bind(this)
  }

  componentWillMount() {
    MIFID_ACTIVE = getMifidByCategory(MIFID_CONST.TOLLERANZA)
    fields = allFields[2][MIFID_ACTIVE]
    // Imposto i validators in modo da poter fare i controlli (sul padre) tra sezioni diverse
    setValidators(this.props.template.listaDomande)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && this.props.submitSucceeded && !this.props.apiError) {
      this.props.initialize(this.state.values)
      this.props.resetForm(MIFID_ACTIVE)
      this.setState({ saved: true, values: {} })
    }
    setRisposte(getFormValues(this.props.currentFormValues))
  }

  submit(values) {
    const { onSave } = this.props
    const submittedValues = Object.keys(values).reduce((res, cur) => {
      return { ...res, [cur.replace('field_', '')]: values[cur] }
    }, {})
    this.setState({ values })
    onSave(
      Object.keys(submittedValues).reduce((res, cur) => {
        if (cur.includes('_')) {
          const [idDomanda, idRisposta] = cur.split('_')
          const curRisp = res.find(r => r.idDomanda === idDomanda)
          let listaRisposte = []
          if (submittedValues[cur]) {
            listaRisposte = [{ idRisposta }]
          }
          if (curRisp) {
            listaRisposte = [...curRisp.listaRisposte, ...listaRisposte]
            return [...res.filter(r => r.idDomanda !== idDomanda), { idDomanda, listaRisposte }]
          }
          return [...res, { idDomanda, listaRisposte }]
        } else {
          return [...res, { idDomanda: cur, listaRisposte: [{ idRisposta: submittedValues[cur] }] }]
        }
      }, [])
    )
  }

  render() {
    const {
      handleSubmit,
      submitting,
      isDirty,
      invalid,
      formValues,
      status,
      template: { descSezione, listaDomande },
      isFetching
    } = this.props

    return (
      <CollapsibleItem
        header={descSezione}
        itemKey={MIFID_ACTIVE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          {/* NUOVA MIFID PF/PG/PD */}
          <React.Fragment>
            <Row>
              <Col
                s={6}
                className={this.props.fieldsWithErrors.includes(fields.PERDITA.templateId) ? 'fieldHasErrors' : ''}>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.PERDITA.templateId)}
                />
              </Col>
              <Col s={6} className="center">
                <img
                  className="responsive-img marginTop20"
                  src={listaDomande.find(d => d.idDomanda === fields.PERDITA.templateId).pathImage}
                />
              </Col>
            </Row>
            <Row>
              <Col
                s={12}
                className={this.props.fieldsWithErrors.includes(fields.RISCHIO.templateId) ? 'fieldHasErrors' : ''}>
                <SurveyRadio
                  formValues={formValues}
                  template={listaDomande.find(d => d.idDomanda === fields.RISCHIO.templateId)}
                />
              </Col>
            </Row>
          </React.Fragment>
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching || isSaveDisabled(listaDomande)} />
        </form>
      </CollapsibleItem>
    )
  }
}

TolleranzaOscillazione.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  initialize: PropTypes.func,
  resetForm: PropTypes.func,
  formValues: PropTypes.object,
  submitSucceeded: PropTypes.bool,
  status: PropTypes.any,
  template: PropTypes.object,
  onSave: PropTypes.func,
  currentFormValues: PropTypes.object,
  fieldsWithErrors: PropTypes.array
}

export default TolleranzaOscillazione
