import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row } from 'react-materialize'
import { Collapsible } from '../../../components/Collapsible'
import * as forms from '../../../store/modules/forms/constants'
import dataSB from '../../../static-data/data-servizio-base.json'
// import dataPGRadio from '../../../static-data/data-PG-radio-pep.json'
import {
  getKeyDominioFromValue,
  checkStatusService,
  getSectionStatus,
  retrieveDocumentsDataWithoutImage,
  retrieveDocumentsDataWithoutDomain,
  getSubSectionToBeCompleted
} from '../utils'
import allFields from '../fields'
import CodiceFiscale from './CodiceFiscale'
import CodiceFiscaleGiuridiche from './CodiceFiscaleGiuridiche'
import Documenti from './Documenti'
import DocumentiGiuridiche from './DocumentiGiuridiche'
import SchedaDati from './SchedaDati'
import SchedaDatiTitolare from './SchedaDatiTitolare'
// import Procuratore from './Procuratore'
// import ProcuratoreID from './ProcuratoreIdentita'
// import DocumentoProcura from './DocumentoProcura'
import ClientiProfessionali from './ClientiProfessionali'
// import { checkInvalidDocumentDate } from '../../../utils'
import { ProspectContextConsumer } from '../ProspectContext'
import DocumentiFisicaRiferimento from './Documenti_fisicaRiferimento'

const fields = allFields[0][forms.DOCUMENTO_IDENTITA_ALLEGATO]
const fieldsPFR = allFields[0][forms.DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO]
const fieldsDOCPG = allFields[0][forms.DOCUMENTI_GIURIDICHE]
const procResFields = allFields[0][forms.CENSIMENTO_PROCURATORE]
// const titolareFields = allFields[0][forms.SCHEDA_DATI_TITOLARE]
const procFields = allFields[0][forms.PROCURATORE_ID]
const schedaDatiFields = allFields[0][forms.SCHEDA_DATI]
const schedaDatiTitolareFields = allFields[0][forms.SCHEDA_DATI_TITOLARE]

const getOpenCollapsible = (summaryData, current, reduxForms) => {
  const next = getSubSectionToBeCompleted(summaryData, 0, current, reduxForms)
  return forms[Object.keys(forms).find(f => forms[f] === next)]
}

export class AcquisizioneDocumentiWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingComplete: false,
      waitingDomini: false
    }
    this.dominioCustomRequest = this.dominioCustomRequest.bind(this)
    this.updateDominio = this.updateDominio.bind(this)
  }
  componentDidMount() {
    const {
      // idProcurator,
      getDocumentsData,
      // getDocumentsDataProcurator,
      getProfessionalSubject,
      customer: { codiceFiscale, idCliente, idIncarico },
      summaryData: { statusEndPoint, idPhysicalPersonReference },
      getDataRegistry,
      getAnagraficaPFActionCreator,
      isProspectPersonaGiuridica,
      getDocumentIdPFActionCreator,
      getDataVerificaClienti,
      getProfessionale,
      getAddictionalOwner,
      getDataSummaryLightPGActionCreator,
      getDocumentIdPGActionCreator,
      customer,
      idPratica
    } = this.props

    if (isProspectPersonaGiuridica) {
      getProfessionale(this.props.idPhysicalPersonReference)
      getAddictionalOwner(this.props.idPhysicalPersonReference)
      getDataSummaryLightPGActionCreator(customer.idCliente, idPratica)
      getDocumentIdPGActionCreator(customer.idCliente)
      // const { listaDomande } = this.dataPGRadio
      // const radioPepRisposte = listaDomande
      //   .find(d => d.idDomanda === fields.RADIO_PEP.templateId)
      //   .listaRisposte.map(r => '' + r.idRisposta)
      if (isProspectPersonaGiuridica) {
        getAnagraficaPFActionCreator(idPhysicalPersonReference)

        getDataVerificaClienti(idPhysicalPersonReference)
      } else {
        getAnagraficaPFActionCreator(idCliente)
      }

      getDocumentIdPFActionCreator(idPhysicalPersonReference, idIncarico)
      this.setState({ loadingComplete: true })
    } else {
      getDataRegistry('', idCliente, idIncarico, true, false)
      // if (idProcurator) {
      //   if (statusEndPoint) {
      //     getDocumentsDataProcurator(
      //       codiceFiscale,
      //       idCliente,
      //       idIncarico,
      //       checkStatusService(statusEndPoint, 'idCardData'),
      //       idProcurator
      //     )
      //   } else {
      //     this.setState({ loadingComplete: true })
      //   }
      // } else {
      if (statusEndPoint) {
        getDocumentsData(codiceFiscale, idCliente, idIncarico, checkStatusService(statusEndPoint, 'idCardData'))
      } else {
        this.setState({ loadingComplete: true })
      }
      // }
      getProfessionalSubject(idCliente === null || '' ? null : idCliente)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.moduleSendRequest &&
      prevProps.moduleSendRequest &&
      prevProps.moduleSendRequest.fetching &&
      !this.props.moduleSendRequest.fetching &&
      !this.props.moduleSendRequest.error
    ) {
      this.props.getDataSummaryLight(this.props.customer.idCliente, this.props.idPratica)
    }

    // if (
    //   prevProps.postDataVerificaClientiRequest &&
    //   this.props.postDataVerificaClientiRequest &&
    //   prevProps.postDataVerificaClientiRequest.fetching &&
    //   !this.props.postDataVerificaClientiRequest.fetching &&
    //   !this.props.postDataVerificaClientiRequest.error
    // ) {

    //   this.props
    //     .getDataSummaryLightPGActionCreator(this.props.customer.idCliente, this.props.idPratica)
    //     .then(function() {
    //      // _this.resetForm('SCHEDA_RACCOLTA_DATI_PF_RIF')
    //       _this.props.getDataVerificaClienti(_this.props.idPhysicalPersonReference)

    //     })
    // }
    // if (
    //   prevProps.postDocPGIDPGRequest &&
    //   this.props.postDocPGIDPGRequest &&
    //   prevProps.postDocPGIDPGRequest.fetching &&
    //   !this.props.postDocPGIDPGRequest.fetching &&
    //   !this.props.postDocPGIDPGRequest.error
    // ) {
    //   this.props.getDataSummaryLightPGActionCreator(this.props.customer.idCliente, this.props.idPratica)
    // }
    // if (
    //   prevProps.postDocIDPGRequest &&
    //   this.props.postDocIDPGRequest &&
    //   prevProps.postDocIDPGRequest.fetching &&
    //   !this.props.postDocIDPGRequest.fetching &&
    //   !this.props.postDocIDPGRequest.error
    // ) {
    //   this.props.getDataSummaryLightPGActionCreator(this.props.customer.idCliente, this.props.idPratica)
    // }

    if (
      prevProps.deleteAdditionalOwnerRequest &&
      this.props.deleteAdditionalOwnerRequest &&
      prevProps.deleteAdditionalOwnerRequest.fetching &&
      !this.props.deleteAdditionalOwnerRequest.fetching &&
      !this.props.deleteAdditionalOwnerRequest.error
    ) {
      this.props.getAddictionalOwner(this.props.idPhysicalPersonReference)
      // this.props.getDataSummaryLightPGActionCreator(this.props.customer.idCliente, this.props.idPratica)
    }

    if (
      prevProps.postAdditionalOwnerRequest &&
      this.props.postAdditionalOwnerRequest &&
      prevProps.postAdditionalOwnerRequest.fetching &&
      !this.props.postAdditionalOwnerRequest.fetching &&
      !this.props.postAdditionalOwnerRequest.error
    ) {
      this.props.getAddictionalOwner(this.props.idPhysicalPersonReference)
      // this.props.getDataSummaryLightPGActionCreator(this.props.customer.idCliente, this.props.idPratica)
    }
    if (
      prevProps.getDocumentsDataRequest.fetching &&
      !this.props.getDocumentsDataRequest.fetching &&
      !this.props.getDocumentsDataRequest.error
    ) {
      const {
        documentsData: { provinciaRilascio },
        idProcurator
      } = this.props
      const { PROVINCIA_RILASCIO, COMUNE_RILASCIO } = fields
      const { PROVINCIA_RILASCIO_PROC, COMUNE_RILASCIO_PROC } = procFields
      const { PROVINCIA_RESIDENZA, COMUNE_RESIDENZA } = procResFields

      if (provinciaRilascio) {
        this.dominioCustomRequest(COMUNE_RILASCIO.dominio, PROVINCIA_RILASCIO.dominio, provinciaRilascio.chiave, true)
        this.setState({ waitingDomini: true })
      } else {
        this.setState({ loadingComplete: true })
      }
      if (idProcurator) {
        this.dominioCustomRequest(
          COMUNE_RILASCIO_PROC.dominio,
          PROVINCIA_RILASCIO_PROC.dominio,
          this.props.documentsDataProcuratore.releaseProvince,
          true
        )
        this.dominioCustomRequest(
          COMUNE_RESIDENZA.dominio,
          PROVINCIA_RESIDENZA.dominio,
          this.props.documentsDataProcuratore.residenceProvince,
          true
        )
        this.setState({ waitingDomini: true })
      } else {
        this.setState({ loadingComplete: true })
      }
    }
    if (
      prevProps.getDocIDPFRequest.fetching &&
      !this.props.getDocIDPFRequest.fetching &&
      !this.props.getDocIDPFRequest.error
    ) {
      const { PROVINCIA_RILASCIO, COMUNE_RILASCIO } = fieldsPFR
      const {
        docIDPF: { provinceReleaseCode }
      } = this.props
      if (provinceReleaseCode) {
        this.dominioCustomRequest(COMUNE_RILASCIO.dominio, PROVINCIA_RILASCIO.dominio, provinceReleaseCode, true)
      }
    }
    if (
      prevProps.getDocIDPGRequest.fetching &&
      !this.props.getDocIDPGRequest.fetching &&
      !this.props.getDocIDPGRequest.error
    ) {
      const { PROVINCIA_PG, CITTA_PG } = fieldsDOCPG
      const {
        docIDPG: { province }
      } = this.props
      if (province) {
        this.dominioCustomRequest(CITTA_PG.dominio, PROVINCIA_PG.dominio, province, true)
      }
    }

    if (
      this.props.getAdditionalOwnerRequest !== undefined &&
      this.props.getAdditionalOwnerRequest.fetching !== undefined &&
      prevProps.getAdditionalOwnerRequest.fetching &&
      !this.props.getAdditionalOwnerRequest.fetching &&
      !this.props.getAdditionalOwnerRequest.error
    ) {
      const {
        additionalOwnerList
        // additionalOwnerList: { provinceResidenceCode }
      } = this.props

      if (additionalOwnerList) {
        for (var i = 0; i < additionalOwnerList.length; i++) {
          const COMUNE_NASCITA_SCHEDA_DATI_TIT = `COMUNE_NASCITA_SCHEDA_DATI_${i}`
          const PROVINCIA_NASCITA_SCHEDA_DATI_TIT = `PROVINCIA_NASCITA_SCHEDA_DATI_${i}`
          // const CAP_SCHEDA_DATI_TIT = `CAP_SCHEDA_DATI_${i}`
          const COMUNE_SCHEDA_DATI_TIT = `COMUNE_SCHEDA_DATI_${i}`
          const PROVINCIA_SCHEDA_DATI_TIT = `PROVINCIA_SCHEDA_DATI_${i}`

          if (additionalOwnerList[i].provinceBirthCode) {
            this.dominioCustomRequest(
              schedaDatiTitolareFields[COMUNE_NASCITA_SCHEDA_DATI_TIT].dominio,
              schedaDatiTitolareFields[PROVINCIA_NASCITA_SCHEDA_DATI_TIT].dominio,
              additionalOwnerList[i].provinceBirthCode,
              true
            )
          }
          // if (additionalOwnerList[i].cityBirthCode) {
          //  // const COMUNE_NASCITA_SCHEDA_DATI_TIT = `COMUNE_NASCITA_SCHEDA_DATI_${i}`

          //   this.dominioCustomRequest(
          //     schedaDatiTitolareFields[CAP_SCHEDA_DATI_TIT].dominio,
          //     schedaDatiTitolareFields[COMUNE_NASCITA_SCHEDA_DATI_TIT].dominio,
          //     additionalOwnerList[i].cityBirthCode,
          //     true
          //   )
          // }
          if (additionalOwnerList[i].provinceResidenceCode) {
            this.dominioCustomRequest(
              schedaDatiTitolareFields[COMUNE_SCHEDA_DATI_TIT].dominio,
              schedaDatiTitolareFields[PROVINCIA_SCHEDA_DATI_TIT].dominio,
              additionalOwnerList[i].provinceResidenceCode,
              true
            )
          }
        }
      }
    }
    if (
      this.props.getDataVerificaClientiRequest !== undefined &&
      this.props.getDataVerificaClientiRequest.fetching !== undefined &&
      prevProps.getDataVerificaClientiRequest.fetching &&
      !this.props.getDataVerificaClientiRequest.fetching &&
      !this.props.getDataVerificaClientiRequest.error
    ) {
      this.props.setEnabledTitolare(this.props.dataVerificaClienti.isUniqueOwner)
      this.props.setMultidTitolare(this.props.dataVerificaClienti.isOwner)
      const { COMUNE_SCHEDA_DATI, PROVINCIA_SCHEDA_DATI } = schedaDatiFields
      const {
        dataVerificaClienti: { provinceResidenceCode }
      } = this.props

      if (this.props.dataVerificaClienti && provinceResidenceCode) {
        this.dominioCustomRequest(
          COMUNE_SCHEDA_DATI.dominio,
          PROVINCIA_SCHEDA_DATI.dominio,
          provinceResidenceCode,
          true
        )
      }
    }
    // const {
    //   additionalOwnerList
    // } = this.props
    // const { COMUNE_NASCITA_SCHEDA_DATI_0, COMUNE_NASCITA_SCHEDA_DATI_1 }= titolareFields
    // if (additionalOwnerList ) {

    // }

    if (prevProps.dominioRequest.fetching && !this.props.dominioRequest.fetching && this.state.waitingDomini) {
      this.setState({ waitingDomini: false, loadingComplete: true })
    }
  }

  updateDominio(idDominio, codice) {
    this.props.retrieveDominio({
      listaDomini: [{ idDominio, filtro: { codice } }]
    })
  }

  dominioCustomRequest(codDominio, codDominioFilter, value, isAlreadyKey) {
    const { domini, retrieveDominio } = this.props
    retrieveDominio({
      listaDomini: [
        {
          idDominio: codDominio,
          filtro: {
            codice: isAlreadyKey ? value : getKeyDominioFromValue(domini.listaDomini, codDominioFilter, value)
          }
        }
      ]
    })
  }

  render() {
    if (!this.state.loadingComplete) {
      return null
    }
    return (
      <AcquisizioneDocumenti
        {...this.props}
        dominioCustomRequest={this.dominioCustomRequest}
        updateDominio={this.updateDominio}
      />
    )
  }
}

class AcquisizioneDocumenti extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openCollapsible: props.accordionKey || getOpenCollapsible(props.summaryData)
    }

    this.handleOpenCollapsibleChange = this.handleOpenCollapsibleChange.bind(this)
    this.saveDocumenti = this.saveDocumenti.bind(this)
    this.getSectionStatus = this.getSectionStatus.bind(this)
    this.resetForm = this.resetForm.bind(this)
  }

  handleOpenCollapsibleChange(openCollapsible) {
    this.setState({ openCollapsible })
  }
  componentDidUpdate(prevProps) {
    let _this = this
    if (
      prevProps.postDataVerificaClientiRequest &&
      this.props.postDataVerificaClientiRequest &&
      prevProps.postDataVerificaClientiRequest.fetching &&
      !this.props.postDataVerificaClientiRequest.fetching &&
      !this.props.postDataVerificaClientiRequest.error
    ) {
      this.props
        .getDataSummaryLightPGActionCreator(this.props.customer.idCliente, this.props.idPratica)
        .then(function() {
          _this.resetForm('SCHEDA_RACCOLTA_DATI_PF_RIF')
          _this.props.getDataVerificaClienti(_this.props.idPhysicalPersonReference)
        })
    }
    if (
      prevProps.postAdditionalOwnerRequest &&
      this.props.postAdditionalOwnerRequest &&
      prevProps.postAdditionalOwnerRequest.fetching &&
      !this.props.postAdditionalOwnerRequest.fetching &&
      !this.props.postAdditionalOwnerRequest.error
    ) {
      this.props
        .getDataSummaryLightPGActionCreator(this.props.customer.idCliente, this.props.idPratica)
        .then(function() {
          _this.resetForm('SCHEDA_RACCOLTA_DATI_TITOLARI')
          _this.props.getDataVerificaClienti(_this.props.idPhysicalPersonReference)
        })
    }
  }

  getSectionStatus(index) {
    return getSectionStatus(this.props.summaryData, 0, index)
  }

  saveDocumentiPG(values, listaAllegati, isCodiceFiscale, isProcurator = false, isProspectPersonaGiuridica) {}

  saveDocumenti(values, listaAllegati, isCodiceFiscale, isProcurator = false, isProspectPersonaGiuridica, form) {
    const {
      customer: { idCliente, idIncarico },
      summaryData: { statusEndPoint, idPhysicalPersonReference },
      codiceFiscaleInitial,
      // docFiscalRiferimentoInitial,
      documentiInitial,
      // documentiInitialPG,
      postDocumentIdPGActionCreator,
      saveDocumenti,
      saveCodiceFiscale,
      // saveCodiceFiscalePFActionCreator,
      idProcurator,
      formValuesCF_PG,
      formValuesDOC_RIF_PG
    } = this.props
    if (isProspectPersonaGiuridica) {
      const idCardData = {
        // taxCode:  { ...retrieveDocumentsDataWithoutImage(allFields[0][forms.CODICE_FISCALE], codiceFiscaleInitial) },
        idPhysicalPerson: idPhysicalPersonReference,
        idPractice: parseInt(idIncarico),
        flagCustomerIsBlacklist: false,
        ...values,
        ...retrieveDocumentsDataWithoutDomain(allFields[6][forms.CODICE_FISCALE_PF], formValuesCF_PG),
        ...retrieveDocumentsDataWithoutDomain(
          allFields[0][forms.DOCUMENTO_IDENTITA_ALLEGATO_FISICA_RIFERIMENTO],
          formValuesDOC_RIF_PG
        )
      }
      let _this = this
      this.props.setCurrentForm(form).then(function() {
        postDocumentIdPGActionCreator(
          _this.props.customer.idCliente,
          _this.props.idPratica,
          { ...idCardData },
          idPhysicalPersonReference
        )
      })

      // let idTemp = isProcurator ? idProcurator : idCliente
      // saveDocumenti(idTemp, idCardAllegati, idCardData, isProcurator, idCliente)
    } else {
      if (!isProspectPersonaGiuridica) {
        const idCardAllegati = {
          payload: { idIncarico, listaAllegati, idProcurator },
          status: checkStatusService(statusEndPoint, 'idCardAllegati')
        }
        const idCardData = {
          payload: {
            idIncarico,
            ...retrieveDocumentsDataWithoutImage(allFields[0][forms.CODICE_FISCALE], codiceFiscaleInitial),
            ...retrieveDocumentsDataWithoutImage(allFields[0][forms.DOCUMENTO_IDENTITA_ALLEGATO], documentiInitial),
            ...values
          },
          status: checkStatusService(statusEndPoint, 'idCardData')
        }

        if (isCodiceFiscale) {
          saveCodiceFiscale(idCliente, idCardAllegati, idCardData)
        } else {
          let idTemp = isProcurator ? idProcurator : idCliente
          saveDocumenti(idTemp, idCardAllegati, idCardData, isProcurator, idCliente)
        }
      }
    }
  }

  resetForm(form) {
    const { summaryData, goToWhenSaved, reduxForms } = this.props

    // if (!isProspectPersonaGiuridica) {
    let openCollapsible = getOpenCollapsible(summaryData, form, reduxForms)

    // SCHEDA_RACCOLTA_DATI_TITOLARI

    if (
      openCollapsible === 'SCHEDA_RACCOLTA_DATI_TITOLARI' &&
      !(
        (this.props.titolareMulti === false && this.props.titolare === null) ||
        (this.props.titolareMulti === true && this.props.titolare === false)
      )
    ) {
      openCollapsible = 'CLIENTE_PROFESSIONALE'
    }

    this.setState({ openCollapsible })
    if (!openCollapsible) {
      goToWhenSaved()
    }
    // }
  }

  render() {
    return (
      <ProspectContextConsumer>
        {({ isProspectPersonaGiuridica }) => (
          <section className="main-body">
            <article className="page-left-content">
              <div className="background_pattern" />
              <h2 className="title-sezione">{dataSB.acquisizione.title_section}</h2>
              <Collapsible open={this.state.openCollapsible} onChange={this.handleOpenCollapsibleChange}>
                {!isProspectPersonaGiuridica && (
                  <CodiceFiscale
                    status={this.getSectionStatus(0)}
                    resetForm={this.resetForm}
                    isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                    submitHandler={(values, listaAllegati) =>
                      this.saveDocumenti(values, listaAllegati, true, false, isProspectPersonaGiuridica)
                    }
                    idPratica={this.props.idPratica}
                    url={this.props.match.url}
                  />
                )}
                {isProspectPersonaGiuridica && (
                  <CodiceFiscaleGiuridiche
                    status={this.getSectionStatus(0)}
                    resetForm={this.resetForm}
                    isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                    submitHandler={(values, listaAllegati, form) =>
                      this.saveDocumenti(values, listaAllegati, true, false, isProspectPersonaGiuridica, form)
                    }
                    idPratica={this.props.idPratica}
                    url={this.props.match.url}
                  />
                )}

                {!isProspectPersonaGiuridica && (
                  <Documenti
                    status={this.getSectionStatus(1)}
                    domini={this.props.domini}
                    isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                    dominioCustomRequest={this.props.dominioCustomRequest}
                    submitHandler={(values, listaAllegati) =>
                      this.saveDocumenti(values, listaAllegati, false, false, isProspectPersonaGiuridica)
                    }
                    resetForm={this.resetForm}
                    customer={this.props.customer}
                    checkHasProcuratore={this.props.checkHasProcuratore}
                    idPratica={this.props.idPratica}
                    url={this.props.match.url}
                  />
                )}
                {isProspectPersonaGiuridica && (
                  <DocumentiFisicaRiferimento
                    status={this.getSectionStatus(1)}
                    domini={this.props.domini}
                    isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                    dominioCustomRequest={this.props.dominioCustomRequest}
                    submitHandler={(values, listaAllegati, form) =>
                      this.saveDocumenti(values, listaAllegati, false, false, isProspectPersonaGiuridica, form)
                    }
                    idPhysicalPersonReference={this.props.summaryData.idPhysicalPersonReference}
                    resetForm={this.resetForm}
                    customer={this.props.customer}
                    checkHasProcuratore={this.props.checkHasProcuratore}
                    idPratica={this.props.idPratica}
                    url={this.props.match.url}
                  />
                )}
                {isProspectPersonaGiuridica && (
                  <DocumentiGiuridiche
                    status={this.getSectionStatus(2)} // n boh
                    domini={this.props.domini}
                    dominioCustomRequest={this.props.updateDominio}
                    // updateDominio={this.updateDominio}
                    submitHandler={this.saveDocumenti}
                    resetForm={this.resetForm}
                    idPratica={this.props.idPratica}
                    idPhysicalPersonReference={this.props.idPhysicalPersonReference}
                  />
                )}
                {/* {!isProspectPersonaGiuridica && (
                  <Fragment> */}
                {/* <Procuratore
                      status={this.getSectionStatus(3)}
                      domini={this.props.domini}
                      isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                      dominioCustomRequest={this.props.dominioCustomRequest}
                      updateDominio={this.props.updateDominio}
                      submitHandler={(values, listaAllegati) => this.saveDocumenti(values, listaAllegati, false, true)}
                      resetForm={this.resetForm}
                      idPratica={this.props.idPratica}
                      url={this.props.match.url}
                      history={this.props.history}
                    />
                    <ProcuratoreID
                      status={this.getSectionStatus(4)}
                      domini={this.props.domini}
                      isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                      dominioCustomRequest={this.props.dominioCustomRequest}
                      submitHandler={(values, listaAllegati) => this.saveDocumenti(values, listaAllegati, false, true)}
                      resetForm={this.resetForm}
                      idPratica={this.props.idPratica}
                      url={this.props.match.url}
                      checkInvalidDocumentDate={checkInvalidDocumentDate}
                    /> */}
                {/* <DocumentoProcura
                      status={this.getSectionStatus(5)}
                      resetForm={this.resetForm}
                      isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                      submitHandler={(values, listaAllegati) => this.saveDocumenti(values, listaAllegati, false, true)}
                      idPratica={this.props.idPratica}
                      url={this.props.match.url}
                    /> */}
                {/* </Fragment>
                )} */}
                {isProspectPersonaGiuridica && this.props.dataVerificaClienti && (
                  <Fragment>
                    <SchedaDati
                      status={this.getSectionStatus(3)} // n boh
                      submitHandler={this.saveDocumenti}
                      resetForm={this.resetForm}
                      dominioCustomRequest={this.props.updateDominio}
                      idPhysicalPersonReference={this.props.idPhysicalPersonReference}
                    />
                    <SchedaDatiTitolare
                      status={this.getSectionStatus(4)} // n boh
                      submitHandler={this.saveDocumenti}
                      resetForm={this.resetForm}
                      dominioCustomRequest={this.props.updateDominio}
                    />
                  </Fragment>
                )}
                {!isProspectPersonaGiuridica && (
                  <ClientiProfessionali
                    status={this.getSectionStatus(2)}
                    submitHandler={this.saveDocumenti}
                    isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                    resetForm={this.resetForm}
                    getProfessionalSubject={this.props.getProfessionalSubject}
                    idPratica={this.props.idPratica}
                    url={this.props.match.url}
                  />
                )}
                {isProspectPersonaGiuridica && (
                  <ClientiProfessionali
                    status={this.getSectionStatus(5)}
                    submitHandler={this.saveDocumenti}
                    isProspectPersonaGiuridica={isProspectPersonaGiuridica}
                    resetForm={this.resetForm}
                    getProfessionalSubject={this.props.getProfessionalSubject}
                    idPratica={this.props.idPratica}
                    url={this.props.match.url}
                  />
                )}
              </Collapsible>
              <Row className="right-align padding-r-20 margin-t-20">
                <a onClick={() => this.props.goToWhenSaved()}>
                  {dataSB.link_continue} <i className="icon-arrow" />
                </a>
              </Row>
            </article>
          </section>
        )}
      </ProspectContextConsumer>
    )
  }
}

AcquisizioneDocumenti.propTypes = {
  titolareMulti: PropTypes.bool,
  titolare: PropTypes.bool,
  customer: PropTypes.object,
  domini: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  summaryData: PropTypes.object,
  saveCodiceFiscale: PropTypes.func,

  // saveCodiceFiscalePFActionCreator: PropTypes.func,
  // docFiscalRiferimentoInitial: PropTypes.object,
  saveDocumenti: PropTypes.func,
  dominioCustomRequest: PropTypes.func,
  updateDominio: PropTypes.func,
  goToWhenSaved: PropTypes.func.isRequired,
  accordionKey: PropTypes.string,
  codiceFiscaleInitial: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  documentiInitial: PropTypes.object,
  reduxForms: PropTypes.object,
  idProcurator: PropTypes.number,
  checkHasProcuratore: PropTypes.func,
  getProfessionalSubject: PropTypes.func,
  idPratica: PropTypes.any,
  match: PropTypes.any,
  postDocumentIdPGActionCreator: PropTypes.any,
  idPhysicalPersonReference: PropTypes.any,
  dataVerificaClienti: PropTypes.object,
  // history: PropTypes.any,
  formValuesCF_PG: PropTypes.object,
  formValuesDOC_RIF_PG: PropTypes.object,
  postDataVerificaClientiRequest: PropTypes.object,
  postAdditionalOwnerRequest: PropTypes.object,
  setCurrentForm: PropTypes.func,
  getDataSummaryLightPGActionCreator: PropTypes.func
}

AcquisizioneDocumentiWrapper.propTypes = {
  deleteAdditionalOwnerRequest: PropTypes.object,
  getDocumentIdPGActionCreator: PropTypes.func,
  customer: PropTypes.object,
  documentsData: PropTypes.object,
  documentsDataProcuratore: PropTypes.object,
  domini: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  dominioRequest: PropTypes.object,
  getAnagraficaPFActionCreator: PropTypes.func,
  getDataRegistry: PropTypes.func,
  getDocumentIdPFActionCreator: PropTypes.func,
  getDocumentsData: PropTypes.func,
  getDocumentsDataProcurator: PropTypes.func,
  getDocumentsDataRequest: PropTypes.object,
  getDocIDPFRequest: PropTypes.object,
  getDocIDPGRequest: PropTypes.object,
  docIDPG: PropTypes.object,
  getProfessionalSubject: PropTypes.func,
  goToWhenSaved: PropTypes.func.isRequired,
  idProcurator: PropTypes.number,
  isProspectPersonaGiuridica: PropTypes.bool,
  retrieveDominio: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  summaryData: PropTypes.object,
  docIDPF: PropTypes.object,
  getDataVerificaClienti: PropTypes.func,
  dataVerificaClienti: PropTypes.object,
  getDataVerificaClientiRequest: PropTypes.object,
  getAdditionalOwnerRequest: PropTypes.object,
  getProfessionale: PropTypes.func,
  idPhysicalPersonReference: PropTypes.any,
  getAddictionalOwner: PropTypes.func,
  postDataVerificaClientiRequest: PropTypes.object,
  postAdditionalOwnerRequest: PropTypes.object,
  setEnabledTitolare: PropTypes.func,
  setMultidTitolare: PropTypes.func,
  additionalOwnerList: PropTypes.any,
  getDataSummaryLightPGActionCreator: PropTypes.func,
  idPratica: PropTypes.any,
  postDocPGIDPGRequest: PropTypes.any,
  postDocIDPGRequest: PropTypes.any,
  sendDataProcuratore: PropTypes.any,
  moduleSendRequest: PropTypes.any,
  getDataSummaryLight: PropTypes.any
}

export default AcquisizioneDocumentiWrapper
