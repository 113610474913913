import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FirmaComponent from '../components/FirmaComponent'
import { updateStatoPratica, updatePraticaDraft } from '../../../store/modules/pratica/actions'
import {
  getFirma,
  saveDocument,
  getNamirialUrl,
  downloadDraftDocs,
  richiamaPratica,
  praticaToSignRoom
} from '../../../store/modules/utility/actions'

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveDocument,
      getFirma,
      getNamirialUrl,
      updateStatoPratica,
      downloadDraftDocs,
      updatePraticaDraft,
      richiamaPratica,
      praticaToSignRoom
    },
    dispatch
  )

const mapStateToProps = state => ({
  listaClienti: state.utility.listaClienti,
  listaDocumenti: state.utility.listaDocumenti,
  utilityGetFirmaRequest: state.utility.utilityGetFirmaRequest,
  utilitySaveDocumentRequest: state.utility.utilitySaveDocumentRequest,
  utilityGetNamirialUrlRequest: state.utility.utilityGetNamirialUrlRequest,
  namirialUrl: state.utility.namirialUrl,
  tipoFirma: state.pratica.pratica.tipoFirma,
  updatePraticaDraftRequest: state.pratica.updatePraticaDraftRequest,
  pratica: state.pratica.pratica
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FirmaComponent)
