import { connect } from 'react-redux'
import { reduxForm, getFormValues, isDirty, getFormInitialValues } from 'redux-form'
import { CLIENTI_PROFESSIONALI, DOCUMENTO_IDENTITA_ALLEGATO } from '../../../../store/modules/forms/constants'
import { bindActionCreators } from 'redux'
import ClientiProfessionali from './ClientiProfessionaliComponent'
import allFields from '../../fields'
import {
  saveProfessionalSubject,
  downloadProfessionalSubjectDocument
} from '../../../../store/modules/customers/actions'
import { checkProfessionale, postProfessionale, getProfessionale } from '../../../../store/modules/survey/actions'

const fields = allFields[0][CLIENTI_PROFESSIONALI]

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveProfessionalSubject,
      downloadProfessionalSubjectDocument,
      checkProfessionale,
      postProfessionale,
      getProfessionale
    },
    dispatch
  )

const mapStateToProps = (state, ownProps) => {
  let commonStateToProps = {
    summaryData: ownProps.isProspectPersonaGiuridica
      ? state.prospectPersonaGiuridica.summaryData
      : state.customers.summaryData,
    customer: state.customers.customer,
    idPhysicalPersonReference: state.prospectPersonaGiuridica.summaryData.idPhysicalPersonReference,
    downloadDocument: state.customers.downloadDocument,
    professionalSubject: state.customers.professionalSubject,
    documentiFormValues: getFormValues(DOCUMENTO_IDENTITA_ALLEGATO)(state),
    formValues: getFormValues(CLIENTI_PROFESSIONALI)(state),
    currentInitial: getFormInitialValues(CLIENTI_PROFESSIONALI)(state),
    fields,
    isDirty: isDirty(CLIENTI_PROFESSIONALI)(state),
    isFetching: state.customers.getDataSummaryRequest.fetching,
    apiError: state.customers.getDataSummaryRequest.error,
    isProfessionale: state.survey.isProfessionale,
    professionaleRequest: state.survey.professionaleRequest
  }
  if (state.customers.professionalSubject.professionalSubjectType === 'TRAMITE_RICHIESTA') {
    commonStateToProps = {
      ...commonStateToProps,
      initialValues: { [fields.CLIENTI_PROFESSIONALI_SU_RICHIESTA.id]: true }
    }
  } else if (ownProps.isProspectPersonaGiuridica) {
    commonStateToProps = {
      ...commonStateToProps,
      currentInitial: getFormInitialValues(CLIENTI_PROFESSIONALI)(state),
      initialValues: { [fields.CLIENTI_PROFESSIONALI_DI_DIRITTO.id]: state.survey.professionalSubjectType },
      postProfessionaleRequest: state.survey.postProfessionaleRequest
    }
  }
  return commonStateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: CLIENTI_PROFESSIONALI,
    enableReinitialize: true,
    shouldError: () => isDirty(CLIENTI_PROFESSIONALI)
  })(ClientiProfessionali)
)
