import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'react-materialize'

const Attesa = ({ text = '', className = '', error = false, children }) => (
  <Col className={`attesa ${className}${error ? ' error' : ''}`}>
    {children}
    {text}
  </Col>
)
export default Attesa

Attesa.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  error: PropTypes.bool,
  children: PropTypes.object
}
