import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
// import { Col, Icon } from 'react-materialize'
// import { isMifidApi, isStatusResponseInvalid } from '../../../store/modules/survey/actions'
import { isStatusResponseInvalid } from '../../../store/modules/survey/actions'

class ModaleError extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: '',
      error2: '',
      error3: '',
      visible: false,
      errorCustom: false,
      errorCustomComplete: false
    }

    this.handlePrimaryClick = this.handlePrimaryClick.bind(this)
    this.handleSecondaryClick = this.handleSecondaryClick.bind(this)
    this.handleCallbackClick = this.handleCallbackClick.bind(this)
  }

  componentDidMount() {
    let context = this
    axios.interceptors.request.use(
      function(config) {
        return config
      },
      function(error) {
        let errorMessage = ''
        let errorMessage2 = ''
        let errorMessage3 = ''
        if (error.response) {
          errorMessage += error.response.status ? `${error.response.status}: ` : ''
          errorMessage += error.response.statusText ? `${error.response.statusText}` : ''
          errorMessage2 += error.response.data.description
            ? error.response.data.description.replace('[', '').replace(']', '')
            : ''
        }

        errorMessage3 += error.message ? `\n${error.message}\n` : ''

        context.setState({ error: errorMessage, error2: errorMessage2, error3: errorMessage3, visible: true })
        return error
      }
    )
    // Add a response interceptor
    axios.interceptors.response.use(
      response => {
        const { data } = response
        let error = ''
        context.setState({ errorCustom: false })
        if (data.code && data.code === 'CUSTOMER_230') {
          error += data.description.replace('[', '').replace(']', '')
          context.setState({ error, errorCustom: true, visible: true })
          return Promise.reject(response)
        } else if (data.code && data.code !== '0') {
          error += data.description.replace('[', '').replace(']', '')
          context.setState({ error, error2: '', error3: '', visible: true })
          return Promise.reject(response)
        } else if (isStatusResponseInvalid(response)) {
          error += (data.statusResponse.description || '').replace('[', '').replace(']', '')
          context.setState({ error, error2: '', error3: '', visible: true })
          // if (isMifidApi(response)) {
          //   // just for MIFID
          //   return Promise.resolve(response)
          // } else {
          //   return Promise.reject(response)
          // }
        } else {
          return response
        }
      },
      function(error) {
        let errorMessage = ''
        let errorMessage2 = ''
        let errorMessage3 = ''
        if (error.response) {
          errorMessage += error.response.status ? `${error.response.status}: ` : ''
          errorMessage += error.response.statusText ? `${error.response.statusText}` : ''
          errorMessage2 += error.response.data.description
            ? error.response.data.description.replace('[', '').replace(']', '')
            : ''
        }

        errorMessage3 += error.message ? `\n${error.message}\n` : ''

        context.setState({ error: errorMessage, error2: errorMessage2, error3: errorMessage3, visible: true })
        return Promise.reject(error)
      }
    )
  }

  handlePrimaryClick(e) {
    let context = this
    context.setState({ visible: false, errorCustomComplete: false, errorCustom: false })
  }
  handleCallbackClick(e) {
    let context = this
    context.setState({ errorCustomComplete: true, errorCustom: false })
  }

  handleSecondaryClick(e) {
    if (this.props.secondaryAction) {
      this.props.secondaryAction()
    }
  }

  render() {
    if (this.state.visible === false) {
      return null
    }
    if (this.state.errorCustom) {
      return (
        <div className="modal-background">
          <div className="modal-box-container">
            <div className="modal-box-content modal-box-content-error">
              <h1 className="modal-title">Attenzione</h1>
              <p className="modal-text" dangerouslySetInnerHTML={{ __html: this.state.error }} />
            </div>
            <div className="modal-box-actions">
              <button className="modal-action primary btncenter" onClick={this.handlePrimaryClick}>
                CHIUDI
              </button>
              <button className="modal-action primary btncenter" onClick={this.handleCallbackClick}>
                RECUPERA DATI
              </button>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.errorCustomComplete) {
      return (
        <div className="modal-background">
          <div className="modal-box-container">
            <div className="modal-box-content modal-box-content-error">
              <p className="modal-text">Recupero dati effettuato con successo.</p>
            </div>

            {/* <Col s={12} className="center-align" style={{ marginTop: '40px' }}>
              <Icon className="onboarding-modale-icon__confirm icon-icone-ips_sv-spesa" large>
                &#xe90c;
              </Icon>
            </Col> */}
            <div className="modal-box-actions">
              <button className="modal-action primary btncenter" onClick={this.handlePrimaryClick}>
                CHIUDI
              </button>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.error2.indexOf('La data di scadenza non può essere antecedente o uguale a quella odierna') !== -1) {
      return (
        <div className="modal-background">
          <div className="modal-box-container">
            <div className="modal-box-content modal-box-content-error">
              <h1 className="modal-title">Attenzione</h1>
              <p className="modal-text" dangerouslySetInnerHTML={{ __html: this.state.error2 }} />
            </div>
            <div className="modal-box-actions">
              <button className="modal-action primary btncenter" onClick={this.handlePrimaryClick}>
                CHIUDI
              </button>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="modal-background">
          <div className="modal-box-container">
            <div className="modal-box-content modal-box-content-error">
              <h1 className="modal-title">Attenzione</h1>
              <p className="modal-text" dangerouslySetInnerHTML={{ __html: this.state.error }} />
              <p className="modal-text" dangerouslySetInnerHTML={{ __html: this.state.error2 }} />
              <p className="modal-text" dangerouslySetInnerHTML={{ __html: this.state.error3 }} />
            </div>
            <div className="modal-box-actions">
              <button className="modal-action primary btncenter" onClick={this.handlePrimaryClick}>
                CHIUDI
              </button>
            </div>
          </div>
        </div>
      )
    }
  }
}
export default ModaleError

ModaleError.propTypes = {
  secondaryAction: PropTypes.func
}
