import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, isDirty } from 'redux-form'
import { Col, Row } from 'react-materialize'
import { CollapsibleItem } from '../../../../../components/Collapsible'
import AccordionHeader from '../../../../../components/AccordionHeader'
import ModalitaPagamento from '../common/ModalitaPagamento'
import InputPlusMinus from '../../../../../components/InputPlusMinus'
import SpeseButton from '../common/SpeseButton'
import { APERTURA_MAX_FUND } from '../../../../../store/modules/forms/constants'
import allFields from '../../../fields'
import { loadDefaultFields } from '../../../../ConsulenzaBaseContainerLayout/utils'
import { createPayload, loadServiceData, getIdServizio } from '../../utils'
import ButtonSubmit from '../../../../../components/ButtonSubmit'
import TipologiaContoForm from '../common/TipologiaContoForm'

const fields = allFields[APERTURA_MAX_FUND]
const defaultFields = loadDefaultFields(fields)
const selector = formValueSelector(APERTURA_MAX_FUND)
const idServizio = getIdServizio(APERTURA_MAX_FUND)

const validate = () => ({})

class MaxFund extends Component {
  constructor(props) {
    super(props)

    this.state = { saved: false, expanded: false }
    this.isDisabled = this.isDisabled.bind(this)
    this.submit = this.submit.bind(this)
    this.isImportoValid = this.isImportoValid.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching && !this.props.apiError && this.props.submitSucceeded) {
      this.props.initialize(this.props.formValues)
      this.props.resetForm(APERTURA_MAX_FUND)
      this.setState({ saved: true })
    }
  }

  isDisabled(id) {
    const { IMPORTO, ORIGINE } = fields
    const { formValues } = this.props
    switch (id) {
      case ORIGINE.id:
        return !formValues[IMPORTO.id]
      default:
        return false
    }
  }

  isImportoValid() {
    const { IMPORTO } = fields
    const { formValues } = this.props
    if (formValues[IMPORTO.id] && formValues[IMPORTO.id] < 100000) {
      return false
    }
    return true
  }

  submit(values) {
    const { serviceData, onSave } = this.props
    let obTosend = createPayload(fields, values, serviceData)
    if (obTosend.idTipologiaConto === null) {
      obTosend.idTipologiaConto = '1'
    }
    onSave(serviceData.idServizio, obTosend)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      getStatus,
      isDirty,
      invalid,
      domini,
      formValues,
      change,
      percentualiCommissionali,
      isFetching,
      serviceInformation
    } = this.props
    const { COMM_ADVISORY } = fields
    const percComm = percentualiCommissionali.find(p => p.codiceTipoServizio === idServizio)

    return (
      <CollapsibleItem
        itemKey={APERTURA_MAX_FUND}
        header={serviceInformation[0].descTipoServizio}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(isDirty, this.state.saved, getStatus(true))}>
        <form onSubmit={handleSubmit(this.submit)} noValidate>
          <ModalitaPagamento
            fields={fields}
            domini={domini}
            formValues={formValues}
            change={change}
            origineRequired
            importoRequired
            isDisabled={this.isDisabled}
          />
          {!this.isImportoValid() && (
            <div>
              <p className="error">L’importo minimo di apertura del Servizio MAX Fund è pari a 100.000€</p>
            </div>
          )}
          <Row className="m-top10">
            <Col s={12} className="spese-button">
              <SpeseButton
                expanded={this.state.expanded}
                handler={() => this.setState({ expanded: !this.state.expanded })}
              />
            </Col>
          </Row>
          {this.state.expanded && (
            <Row>
              <Col s={6}>
                <InputPlusMinus
                  label={COMM_ADVISORY.label}
                  value={formValues[COMM_ADVISORY.id]}
                  stepValue={percComm.percentualeIntervallo}
                  minValue={percComm.percentualeMinima || percComm.percentualeDefault}
                  maxValue={percComm.percentualeMassima || percComm.percentualeDefault}
                  onChange={value => change(COMM_ADVISORY.id, value)}
                />
              </Col>
            </Row>
          )}
          <TipologiaContoForm fields={fields} tipoServizio="FUND" formValues={formValues} {...this.props} isDisabled />
          <ButtonSubmit disabled={submitting || !isDirty || invalid || isFetching || !this.isImportoValid()} />
        </form>
      </CollapsibleItem>
    )
  }
}

MaxFund.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  isDirty: PropTypes.bool,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  apiError: PropTypes.object,
  resetForm: PropTypes.func,
  submitSucceeded: PropTypes.bool,
  formValues: PropTypes.object,
  domini: PropTypes.object,
  change: PropTypes.func,
  initialize: PropTypes.func,
  onSave: PropTypes.func,
  serviceData: PropTypes.object,
  percentualiCommissionali: PropTypes.array,
  getStatus: PropTypes.func,
  serviceInformation: PropTypes.array
}

export default connect(state => ({
  initialValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData.servizio_2,
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    )
  },
  formValues: {
    ...defaultFields,
    ...loadServiceData(
      state.services.serviceData.servizio_2,
      fields,
      state.services.percentualiCommissionali.filter(p => p.codiceTipoServizio === idServizio)
    ),
    ...selector(state, ...Object.keys(defaultFields).reduce((res, cur) => [...res, cur], []))
  },
  isDirty: isDirty(APERTURA_MAX_FUND)(state),
  isFetching: state.services.postServizioInfoRequest.fetching,
  apiError: state.services.postServizioInfoRequest.error,
  serviceInformation: state.pratica.pratica.servizi.filter(item => item.idTipoServizio === 2)
}))(
  reduxForm({
    form: APERTURA_MAX_FUND,
    validate
  })(MaxFund)
)
