import { connect } from 'react-redux'
import { isDirty, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import * as forms from '../../../store/modules/forms/constants'
import QuestionarioProfilatura from './QuestionarioProfilaturaComponent'
import { dominio } from '../../../store/modules/dominio/actions'
import { fetchSurveyMifid, saveSurvey, getProfessionale } from '../../../store/modules/survey/actions'
import { getDataRegistry, getDocumentsData } from '../../../store/modules/customers/actions'

import { getDataSummaryLightPGActionCreator } from '../../../store/modules/personaGiuridica/prospect/actions'

// Andrebbe messo il primo accordion? ANAGRAFICA quindi?
const MIFID_ACTIVE = {
  CONOSCENZA: 'MIFID_CONOSCENZA_PF'
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchSurveyMifid,
      saveSurvey,
      dominio,
      getProfessionale,
      getDocumentsData,
      getDataRegistry,
      getDataSummaryLightPGActionCreator
    },
    dispatch
  )
const mapStateToProps = (state, ownProps) => {
  let commonstateToProps = {
    customer: state.customers.customer,
    domini: state.dominio.domini,
    dominioRequest: state.dominio.dominioRequest,
    template: state.survey.template,
    previousForm: state.survey.previousForm,
    mifidRequest: state.survey.mifidRequest,
    mifidSaveRequest: state.survey.mifidSaveRequest,
    isMifidInconsistent: state.survey.isMifidInconsistent,
    summaryData: state.customers.summaryData,
    checkForms: () => Object.keys(forms).some(form => isDirty(forms[form])(state)),
    reduxForms: Object.keys(forms).reduce((res, form) => ({ ...res, [form]: isDirty(forms[form])(state) }), {}),
    idPratica: state.pratica.idPratica,
    isProfessionalSubjectDone: false
  }
  if (ownProps.isProspectPersonaGiuridica) {
    commonstateToProps = {
      ...commonstateToProps,
      summaryData: state.prospectPersonaGiuridica.summaryData,
      getDataSummaryLight: state.prospectPersonaGiuridica.getDataSummaryLight,
      professionalSubjectType: state.survey.professionalSubjectType,
      idPhysicalPersonReference: state.prospectPersonaGiuridica.summaryData.idPhysicalPersonReference
    }
  } else {
    commonstateToProps = {
      ...commonstateToProps,
      summaryData: state.customers.summaryData
      // initialFormDataRegistry: {
      //   ...getFormInitialValues(forms.INFORMAZIONI_PERSONALI)(state),
      //   ...getFormInitialValues(forms.RESIDENZA)(state),
      //   ...getFormInitialValues(forms.CONTATTI)(state)
      // }
    }
  }

  return commonstateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: forms[MIFID_ACTIVE.CONOSCENZA]
  })(QuestionarioProfilatura)
)
