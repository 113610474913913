import React, { Component, Fragment } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { MIFID_ALTRO, MIFID_ALTRO_LIGHT } from '../../../../store/modules/forms/constants'
import ReadOnlyInput from '../../../../components/Inputs/ReadOnly'
import allFields from '../../fields'
import Title from '../../../../components/Title'

class AltreInformazioniReview extends Component {
  constructor(props) {
    super(props)

    this.mapRisposta = this.mapRisposta.bind(this)
    this.getLabel = this.getLabel.bind(this)
  }

  getLabel(templateId) {
    const { template } = this.props
    return template ? template.listaDomande.find(d => d.idDomanda === templateId).testoDomanda : ''
  }

  mapRisposta(templateId) {
    const {
      data: { listaDomande },
      template
    } = this.props
    const domanda = listaDomande.find(d => d.idDomanda === templateId)
    if (!domanda || !Array.isArray(domanda.listaRisposte)) return null
    const idRisposta = domanda.listaRisposte[0].idRisposta
    return template
      ? template.listaDomande.find(d => d.idDomanda === templateId).listaRisposte.find(r => r.idRisposta === idRisposta)
          .descRisposta
      : []
  }

  render() {
    const { template, accordionHeader, data, isProfessionalSubjectDone } = this.props
    const fields = isProfessionalSubjectDone ? allFields[2][MIFID_ALTRO_LIGHT] : allFields[2][MIFID_ALTRO]

    const { testoDomanda, listaRisposte } = template
      ? template.listaDomande.find(d => d.idDomanda === fields.RICCHEZZA_NON_INVESTITA.templateId)
      : { testoDomanda: '', listaRisposte: [] }

    const hasResponses = Object.keys(fields).some(key =>
      data.listaDomande.some(d => d.idDomanda === fields[key].templateId)
    )

    const ricchezzaRisposte = (
      data.listaDomande.find(d => d.idDomanda === fields.RICCHEZZA_NON_INVESTITA.templateId) || {}
    ).listaRisposte

    return (
      <CollapsibleItem
        header={template ? template.descSezione : ''}
        icon="keyboard_arrow_down"
        itemKey={isProfessionalSubjectDone ? MIFID_ALTRO_LIGHT : MIFID_ALTRO}
        labelInfo={accordionHeader(!hasResponses)}>
        {hasResponses && (
          <Fragment>
            {data.listaDomande.find(d => d.idDomanda === fields.RICCHEZZA_FINANZIARIA.templateId) && (
              <Row>
                <Col s={12}>
                  <Title>{this.getLabel(fields.RICCHEZZA_FINANZIARIA.templateId)}</Title>
                </Col>
                <Col s={12}>
                  <ReadOnlyInput value={this.mapRisposta(fields.RICCHEZZA_FINANZIARIA.templateId)} />
                </Col>
              </Row>
            )}
            {data.listaDomande.find(d => d.idDomanda === fields.INVESTIMENTO_STRUMENTI_AZIONARI.templateId) && (
              <Row>
                <Col s={12}>
                  <Title>{this.getLabel(fields.INVESTIMENTO_STRUMENTI_AZIONARI.templateId)}</Title>
                </Col>
                <Col s={12}>
                  <ReadOnlyInput value={this.mapRisposta(fields.INVESTIMENTO_STRUMENTI_AZIONARI.templateId)} />
                </Col>
              </Row>
            )}
            {ricchezzaRisposte && (
              <Row className="ricchezza-non-investita">
                <Col s={12}>
                  <div>
                    <Title>{testoDomanda}</Title>
                    {listaRisposte.map(({ idRisposta, descRisposta }, i) => (
                      <Row key={idRisposta} className="input-row">
                        <Col s={10}>
                          <div className={`color-key color-key-line color-${i}`} />
                          <div className="desc-risposta-percentuale p-left25">{descRisposta}</div>
                        </Col>
                        <Col s={2} className="input-wrapper">
                          {(ricchezzaRisposte.find(r => r.idRisposta === idRisposta) || {}).descRisposta || 0} %
                        </Col>
                      </Row>
                    ))}
                    <div className="bar-container">
                      <div className="bar">
                        {listaRisposte.map(({ idRisposta }, i) => {
                          const width = (ricchezzaRisposte.find(r => r.idRisposta === idRisposta) || {}).descRisposta
                          if (!width) {
                            return null
                          }
                          return <div key={i} className={`color-key color-${i}`} style={{ width: `${width}%` }} />
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </Fragment>
        )}
      </CollapsibleItem>
    )
  }
}
export default AltreInformazioniReview

AltreInformazioniReview.propTypes = {
  accordionHeader: PropTypes.func,
  data: PropTypes.object,
  template: PropTypes.shape({
    descSezione: PropTypes.string,
    listaDomande: PropTypes.array
  }),
  isProfessionalSubjectDone: PropTypes.bool
}
