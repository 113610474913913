import React, { Component } from 'react'
import { PropTypes } from 'prop-types'
import { Row, Col } from 'react-materialize'
import AccordionHeader from '../../../../components/AccordionHeader'
import { CollapsibleItem } from '../../../../components/Collapsible'
import { DOCUMENTI_GIURIDICHE } from '../../../../store/modules/forms/constants'

import ButtonSubmit from '../../../../components/Buttons/Submit'
import DatiPersonaGiuridica from '../../../../components/Forms/DatiPersonaGiuridica'

// const documenti = dataSB.acquisizione.documento_identita

class DocumentiGiuridiche extends Component {
  constructor(props) {
    super(props)

    this.state = { saved: false }
    this.handleSubmit = this.handleSubmit.bind(this)
    // this.checkNazione = this.checkNazione.bind(this)
    // this.checkItalia = this.checkItalia.bind(this)
    // this.checkProvincia = this.checkProvincia.bind(this)
    // this.isOutsideRangeRilascio = this.isOutsideRangeRilascio.bind(this)
  }
  componentDidMount() {
    const {
      getDocumentIdPGActionCreator,
      customer: { idCliente }
      // idPhysicalPersonReference,
    } = this.props
    if (idCliente) getDocumentIdPGActionCreator(idCliente)
  }

  componentDidUpdate(prevProps) {
    const {
      getDocumentIdPGActionCreator,
      customer: { idCliente },
      // idPhysicalPersonReference,
      praticaRequest,
      initialize,
      formValues,
      resetForm,
      postDocPGIDPGRequest
    } = this.props
    // if (prevProps.praticaRequest.fetching && !praticaRequest.fetching) {
    if (this.state.saved && !praticaRequest.fetching) {
      if (idCliente) getDocumentIdPGActionCreator(idCliente)
    }
    if (!this.props.isDirty && this.state.saved) {
      this.setState({ saved: false })
    }
    if (
      prevProps.postDocPGIDPGRequest &&
      prevProps.postDocPGIDPGRequest.fetching &&
      !postDocPGIDPGRequest.fetching &&
      !postDocPGIDPGRequest.error
    ) {
      initialize(formValues)
      resetForm(DOCUMENTI_GIURIDICHE)
      this.setState({ saved: true })
    }
  }

  // checkNazione() {
  //   const {
  //     domini: { listaDomini },
  //     formValues,
  //     fields
  //   } = this.props
  //   return !!getValueDominioFromKey(
  //     listaDomini,
  //     fields.NAZIONE_RILASCIO.dominio,
  //     formValues[fields.NAZIONE_RILASCIO.id]
  //   )
  // }

  // checkItalia() {
  //   const { formValues, fields } = this.props
  //   return '' + formValues[fields.NAZIONE_RILASCIO.id] === fields.NAZIONE_RILASCIO.italiaId
  // }

  // checkProvincia(value) {
  //   const {
  //     domini: { listaDomini },
  //     fields
  //   } = this.props
  //   return !!getValueDominioFromKey(listaDomini, fields.PROVINCIA_RILASCIO.dominio, value)
  // }

  // isOutsideRangeRilascio(date) {
  //   const { dataNascitaCF } = this.props
  //   if (!dataNascitaCF) return date.isAfter(moment(), 'day')
  //   return date.isAfter(moment(), 'day') || date.isBefore(moment(dataNascitaCF), 'day')
  // }

  handleSubmit(values) {
    const {
      postDocumentIdPersoneGActionCreator,
      fields,
      customer: { idCliente },
      idPratica
    } = this.props
    const {
      CODICE_FISCALE_PG,
      RAGIONE_SOCIALE_PG,
      FORMA_ASSOCIATIVA_PG,
      SEDE_LEGALE_PG,
      NAZIONE_PG,
      PROVINCIA_PG,
      CITTA_PG,
      CITTA_ESTERO_PG,
      CAP_PG,
      DATA_COSTITUZIONE_PG,
      // NAZIONE_PG_COSTITUZIONE,
      PROVINCIA_PG_COSTITUZIONE,
      CITTA_PG_COSTITUZIONE,
      ATTIVITA_ESERCITATA_PG,
      PARTITA_IVA_PG,
      CODICE_ATECO_PG,
      // CODICE_ATECO_PG,
      // PARTITA_IVA_PG
      STATUTO,
      ATTO_COSTITUTIVO,
      VISURA_CAMERALE,
      RAGIONE_SOCIALE_DOMICILIO,
      INDIRIZZO_DOMICILIO,
      NAZIONE_DOMICILIO,
      PROVINCIA_DOMICILIO,
      COMUNE_DOMICILIO,
      COMUNE_ESTERO_DOMICILIO,
      CAP_DOMICILIO,
      HA_DOMICILIO,
      CELLULARE_PG,
      EMAIL_PG
    } = fields
    const { initialize, formValues } = this.props
    //     : null
    // : "SRL"
    // : "131"
    // : "2019-04-06T00:00:00Z"
    // : "1"
    // : true
    // : true
    // : true
    // : "PR"
    // registeredOffice: null
    // : "oooo"
    // : "CRTSFN15D04A089P"
    // vatCode: null

    const body = {
      vatCode: formValues[PARTITA_IVA_PG.id],
      atecoCode: formValues[CODICE_ATECO_PG.id],
      taxCode: formValues[CODICE_FISCALE_PG.id] ? formValues[CODICE_FISCALE_PG.id].toUpperCase() : '',
      businessName: formValues[RAGIONE_SOCIALE_PG.id],
      formCorporationCode: formValues[FORMA_ASSOCIATIVA_PG.id],
      nation: formValues[NAZIONE_PG.id],
      province: formValues[PROVINCIA_PG.id],
      city: formValues[CITTA_PG.id],
      place: formValues[CITTA_ESTERO_PG.id],
      zipCode: formValues[CAP_PG.id],
      creationDate: formValues[DATA_COSTITUZIONE_PG.id],
      nationCreation: fields.NAZIONE_PG_COSTITUZIONE.italiaId,
      // nationCreation: formValues[NAZIONE_PG_COSTITUZIONE.id],
      provinceCreation: formValues[PROVINCIA_PG_COSTITUZIONE.id],
      cityCreation: formValues[CITTA_PG_COSTITUZIONE.id],
      registeredOfficeAddress: formValues[SEDE_LEGALE_PG.id],
      activitySectorCode: formValues[ATTIVITA_ESERCITATA_PG.id],
      // atecoCode: values[CODICE_ATECO_PG.id],
      isStatutoEurocapitalUploaded: formValues[STATUTO.id],
      isAttoCostitutivoUploaded: formValues[ATTO_COSTITUTIVO.id],
      isVisuraCameraleUploaded: formValues[VISURA_CAMERALE.id],
      idLegalPerson: idCliente,
      flagDomicile: formValues[HA_DOMICILIO.id],
      businessNameDomicile: formValues[RAGIONE_SOCIALE_DOMICILIO.id],
      addressDomicile: formValues[INDIRIZZO_DOMICILIO.id],
      nationalDomicileCode: formValues[NAZIONE_DOMICILIO.id],
      provinceDomicileCode: formValues[PROVINCIA_DOMICILIO.id],
      cityDomicileCode: formValues[COMUNE_DOMICILIO.id],
      placeDomicileCode: formValues[COMUNE_ESTERO_DOMICILIO.id],
      zipDomicile: formValues[CAP_DOMICILIO.id],
      email: formValues[EMAIL_PG.id],
      mobilePhone: formValues[CELLULARE_PG.id]
    }

    postDocumentIdPersoneGActionCreator(idCliente, idPratica, body)

    initialize(values || formValues)
    this.setState({ saved: true })
  }

  // isCompiled(v) {
  //   const { formValues = {} } = this.props
  //   return typeof formValues[v.id] !== 'undefined' && formValues[v.id] !== null && formValues[v.id] !== ''
  // }

  render() {
    const {
      handleSubmit,
      submitting,
      dirty,
      anyTouched,
      invalid,
      status,
      formValues,
      change,
      touch,
      fields,
      isFetching,
      domini
    } = this.props

    return (
      <CollapsibleItem
        header={'Documenti persona giuridica'}
        itemKey={DOCUMENTI_GIURIDICHE}
        icon="keyboard_arrow_down"
        labelInfo={AccordionHeader(dirty, this.state.saved, status)}>
        <form onSubmit={handleSubmit(this.handleSubmit)} encType="multipart/form-data">
          <DatiPersonaGiuridica
            fields={fields}
            change={change}
            touch={touch}
            formValues={formValues}
            domini={domini}
            dominioRequest={this.props.dominioRequest}
            updateDominio={this.props.dominioCustomRequest}
          />

          <Row className="footer-buttons">
            <Col s={12}>
              <div className="footer-buttons__right">
                <ButtonSubmit disabled={submitting || (!dirty && !anyTouched) || invalid || isFetching} />
              </div>
            </Col>
          </Row>
        </form>
      </CollapsibleItem>
    )
  }
}

DocumentiGiuridiche.propTypes = {
  dominioRequest: PropTypes.any,
  anyTouched: PropTypes.bool,
  change: PropTypes.func,
  customer: PropTypes.object,
  dirty: PropTypes.bool,
  fields: PropTypes.object,
  formValues: PropTypes.object,
  getDocumentIdPGActionCreator: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialize: PropTypes.func,
  invalid: PropTypes.bool,
  isFetching: PropTypes.bool,
  postDocPGIDPGRequest: PropTypes.object,
  postDocumentIdPersoneGActionCreator: PropTypes.func,
  praticaRequest: PropTypes.object,
  resetForm: PropTypes.func,
  status: PropTypes.string,
  submitting: PropTypes.bool,
  touch: PropTypes.func,
  domini: PropTypes.any,
  dominioCustomRequest: PropTypes.func,
  isDirty: PropTypes.any,
  idPratica: PropTypes.any
  // idPhysicalPersonReference: PropTypes.string
}

DocumentiGiuridiche.defaultProps = {
  formValues: {},
  formErrors: {}
}

export default DocumentiGiuridiche
