import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, getFormValues, isDirty, getFormInitialValues, getFormSyncErrors } from 'redux-form'
import { CODICE_FISCALE } from '../../../../store/modules/forms/constants'
import { loadDefaultFields, loadDocumentsData } from '../../utils'
import allFields from '../../fields'
import CodiceFiscale from './CodiceFiscaleComponent'
import { inBlacklistCF, getDataRegistry } from '../../../../store/modules/customers/actions'

const fieldsPF = allFields[0][CODICE_FISCALE]
const fieldsPG = allFields[6][CODICE_FISCALE]
const defaultFields = loadDefaultFields(fieldsPF)

const validate = (values, props = {}) => {
  const errors = {}

  if (values[fieldsPF.CODICE_FISCALE.id]) {
    if (values[fieldsPF.CODICE_FISCALE.id].length < fieldsPF.CODICE_FISCALE.minLength) {
      errors[fieldsPF.CODICE_FISCALE.id] = 'Il codice fiscale deve avere 16 caratteri'
    } else if (!new RegExp(fieldsPF.CODICE_FISCALE.validazione).test(values[fieldsPF.CODICE_FISCALE.id])) {
      errors[fieldsPF.CODICE_FISCALE.id] = 'Il codice fiscale può contenere solo caratteri alfanumerici'
    }
  }

  return errors
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      inBlacklistCF,
      getDataRegistry
    },
    dispatch
  )

const mapStateToProps = (state, ownProps) => {
  const commonStateToProps = {
    customer: { ...state.customers.customer.dataRegistry, ...state.customers.customer },
    formValues: getFormValues(CODICE_FISCALE)(state),
    currentInitial: getFormInitialValues(CODICE_FISCALE)(state),
    isDirty: isDirty(CODICE_FISCALE)(state)
  }
  if (ownProps.isProspectPersonaGiuridica) {
    commonStateToProps.isFetching = state.anagraficaPersonaGiuridicaCliente.postDocIDPGRequest.fetching
    commonStateToProps.apiError = state.anagraficaPersonaGiuridicaCliente.postDocIDPGRequest.error
    commonStateToProps.flagCustomerIsBlacklist = state.anagraficaPersonaGiuridicaCliente.docIDPF.flagCustomerIsBlacklist
    commonStateToProps.dataVerificaClienti = state.anagraficaPersonaGiuridicaCliente.dataVerificaClienti
    commonStateToProps.formErrors = getFormSyncErrors(CODICE_FISCALE)(state)
    commonStateToProps.initialValues = {
      ...loadDocumentsData(fieldsPG, state.anagraficaPersonaGiuridicaCliente.docIDPF),
      taxCode: state.anagraficaPersonaGiuridicaCliente.docIDPF.taxCode
    }
    commonStateToProps.fields = fieldsPG
  } else {
    commonStateToProps.isFetching = state.customers.postDataAllegatiCFRequest.fetching
    commonStateToProps.apiError = state.customers.postDataAllegatiCFRequest.error
    commonStateToProps.flagCustomerIsBlacklist = state.customers.dataRegistry.flagCustomerIsBlacklist
    commonStateToProps.initialValues = {
      ...defaultFields,
      ...loadDocumentsData(fieldsPF, state.customers.documentsData),
      [fieldsPF.CODICE_FISCALE.id]:
        state.customers.dataRegistry.codiceFiscale ||
        state.customers.customer.codiceFiscale ||
        state.customers.documentsData.taxCode
    }
    commonStateToProps.fields = fieldsPF
  }
  return commonStateToProps
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: CODICE_FISCALE,
    enableReinitialize: true,
    validate,
    shouldError: () => isDirty(CODICE_FISCALE)
  })(CodiceFiscale)
)
